/* tslint:disable: max-line-length */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber, XpoLtlServiceCentersService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardState, XpoBoardStateSources, XpoBoardStateViewer } from '@xpo-ltl/ngx-ltl-board';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardState } from '@xpo-ltl/ngx-ltl-board-grid';
import { MoveModeCd } from '@xpo-ltl/sdk-common';
import { AgGridEvent, ColumnApi, GridApi, GridOptions, RowGroupOpenedEvent } from 'ag-grid-community';
import * as _ from 'lodash';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, shareReplay, take, takeUntil } from 'rxjs/operators';
import { SidePanelOpts } from '../../../shared/components/side-panel-container/image-gallery-container/enums/options.model';
import { AppRoutes } from '../../../shared/enums/app-routes.enum';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { IconsKeys } from '../../../shared/enums/icons.enum';
import { SidePanelStatus } from '../../../shared/enums/side-panel-status';
import { AddLoadFormatter } from '../../../shared/formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { MoveToFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/status-formatter';
import { SidePanelData, SidePanelDataStructures } from '../../../shared/models/side-panel-data.model';
import { ExternalUrlsService } from '../../../shared/services/external-urls.service';
import { ShiftService } from '../../../shared/services/shift.service';
import { SidePanelVisibilityService } from '../../../shared/services/side-panel-visibility.service';
import { TrailerDetailsData } from '../models/trailer-details-data.model';
import { AllowableLoadsService } from '../services/allowable-loads.service';
import { GlobalFilters } from './../../../shared/models/global-filters';
import { AuthService } from './../../../shared/services/auth.service';
import { InteractionService } from './../../../shared/services/interaction.service';
import { LanesViewBoardTemplate } from './lanes-view-board-template';
import { LanesViewDataSourceService } from './lanes-view-data-source.service';

@Component({
  selector: 'app-lanes-view',
  templateUrl: './lanes-view.component.html',
  styleUrls: ['./lanes-view.component.scss'],
})
export class LanesViewComponent implements XpoBoardStateViewer, OnInit, OnDestroy {
  @Input() readOnlyMode: boolean = false;

  private gridApi: GridApi;
  private colApi: ColumnApi;
  private unsubscriber = new Unsubscriber();
  readonly AppRoutes = AppRoutes;
  stateChange$ = new ReplaySubject<XpoBoardState>(1);

  aggFunctions;
  detailLanesNodes: any[] = [];
  dateAndShiftValid$: Observable<boolean>;
  expandedCloseToNode: any[] = [];
  frameworkComponents = {
    IconsFormatterComponent: IconsFormatterComponent,
    SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
    StatusFormatterComponent: StatusFormatterComponent,
    BypassFormatterComponent: BypassFormatterComponent,
    LoadedWeightFormatterComponent: LoadedWeightFormatterComponent,
    ExceptionFormatterComponent: ExceptionFormatterComponent,
    CloseToFormatterComponent: CloseToFormatterComponent,
    MoveToFormatterComponent: MoveToFormatterComponent,
    AddLoadFormatter: AddLoadFormatter,
  };
  gridOptions: GridOptions;
  heightHelper: any = {};
  iframeUrl: string;
  interactionSubscription: Subscription;
  isResetRowHeights = false;
  laneData;
  lastReqGlobalFilter: GlobalFilters = new GlobalFilters();
  selectedLane: string;
  selectedSic: string;
  sicLoadRequestAccess = false;
  sizeToFit: boolean = true;
  subscription: Subscription;
  trailerDetailsData: TrailerDetailsData;
  trailerNbr: string;
  views: XpoBoardView[];
  viewTemplates: LanesViewBoardTemplate[];
  selectedTrailer: String = '';
  loadReqAccess: boolean = false;
  showTrailerDetailsPanel = false;
  sidePanelData: SidePanelData;
  sidePanelOpts: SidePanelOpts;
  sidePanelId: string;

  constructor(
    private externalUrlsService: ExternalUrlsService,
    public datasource: LanesViewDataSourceService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private interactionService: InteractionService,
    private authService: AuthService,
    private shiftService: ShiftService,
    private allowableLoadsService: AllowableLoadsService,
    private sidePanelVisibilityService: SidePanelVisibilityService
  ) {}

  ngOnInit() {
    this.setGridOptions();
  }

  initialSubscriptions(): void {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        map((resp) => resp.data),
        filter((data: GlobalFilters) => !!data.shift && !!data.planDate),
        takeUntil(this.unsubscriber.done$)
      )
      .subscribe((data: GlobalFilters) => {
        this.clearSidePanelData();
        this.selectedSic = data.sic;
        this.handleLoadReqAccess(data);
        this.colApi.setColumnVisible('Actions', !this.readOnlyMode);
      });

    this.interactionService
      .subscribeToComponent(ComponentsEnum.SELECTED_TRAILER)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((selectedTrailer) => {
        this.selectedTrailer = selectedTrailer;
      });

    this.datasource
      .connect(this)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((state: XpoAgGridBoardState) => {
        if (this.selectedTrailer && state.source === XpoBoardStateSources.BoardDataFetcher) {
          this.expandSelectedTrailer();
        }
      });
  }

  /**
   * Clear side-panel data to hide side panel
   */
  clearSidePanelData(): void {
    const message = new SidePanelDataStructures(null, null, SidePanelStatus.CLOSED);
    this.interactionService.sendDataSidePanel(message);
  }

  /**
   * Initialize grid
   */
  setGridOptions(): void {
    this.gridOptions = {
      groupMultiAutoColumn: true,
      groupSuppressAutoColumn: true,
      suppressAggFuncInHeader: true,
      frameworkComponents: this.frameworkComponents,
      icons: {
        groupExpanded: `<img src="./assets/images/expanded.svg"
          style="width: 22px; position: absolute; left: 10px; top: 4px;" data=test"expandButton">`,
        groupContracted: `<img src="./assets/images/contracted.svg"
          style="width: 22px; position: absolute; left: 10px; top: 4px;" data=test"contractButton">`,
      },
      // level 1 grid options
      columnDefs: [
        {
          headerName: ' ',
          headerClass: 'main-table-header',
          children: [
            {
              width: 45,
              minWidth: 45,
              maxWidth: 45,
              headerName: '',
              showRowGroup: true,
              field: 'lane',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerName: 'Actions',
              colId: 'Actions',
              minWidth: 72,
              width: 72,
              maxWidth: 72,
              cellRenderer: 'AddLoadFormatter',
              valueGetter: (params) => {
                return this.checkLaneLoadReqAccess(params);
              },
              hide: true,
            },
          ],
        },
        {
          headerName: 'Trip Summary',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Move to',
              showRowGroup: 'lane',
              width: this.readOnlyMode ? 76 : 90,
              cellRenderer: 'MoveToFormatterComponent',
              field: 'lane',
              valueGetter: (params) => {
                return this.getMoveModeCD(params.data);
              },
              cellClass: 'laneColumn',
              cellRendererParams: {
                suppressCount: true,
              },
              comparator: function(valueA, valueB, nodeA, nodeB, isInverted): number {
                return valueA.label > valueB.label ? 1 : -1;
              },
            },
            {
              headerName: 'Close To',
              field: 'closeToSicCd',
              width: this.readOnlyMode ? 100 : 120,
              cellClass: 'ellipsis',
              valueGetter: (params) => {
                return this.formatCloseTo(params);
              },
            },
            {
              headerName: 'Special Services',
              width: 120,
              resizable: true,
              cellClass: 'specialServicesColumn',
              valueGetter: (params) => {
                return this.getSpecialServices(params.data);
              },
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
          ],
        },
        {
          headerName: 'Loads',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Bypass',
              width: this.readOnlyMode ? 70 : 104,
              field: 'bypassLoadCount',
              valueGetter: function(params): any {
                return params.data.bypassLoadCount ? params.data.bypassLoadCount : '';
              },
              type: 'numericColumn',
            },
            {
              headerName: '',
              width: this.readOnlyMode ? 76 : 106,
              field: 'planned',
              valueGetter: (params) => {
                const dataPlannedLoadCount = _.get(params, 'data.planned.loadCount', '');
                return this.handlePlannedColVisibility(dataPlannedLoadCount);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },

            {
              headerName: 'Actual',
              width: this.readOnlyMode ? 66 : 106,
              field: 'loaded',
              type: 'numericColumn',
              valueGetter: function(params): any {
                return params.data.loaded && params.data.loaded.loadCount ? params.data.loaded.loadCount : '';
              },
            },
          ],
        },
        {
          headerName: 'Cube',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: '',
              field: 'planned',
              width: this.readOnlyMode ? 76 : 125,
              valueGetter: (params) => {
                const dataPlannedCube = _.get(params, 'data.planned.cube', '');
                return this.handlePlannedColVisibility(dataPlannedCube);
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              width: this.readOnlyMode ? 72 : 120,
              valueGetter: function getLoadedCube(params): any {
                let cubeLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((closeTo) => {
                    closeTo.children.forEach((trailer) => {
                      if (!trailer.bypassInd) {
                        cubeLoadSum += trailer.loadedCube;
                      }
                    });
                  });
                }
                return cubeLoadSum || '';
              },
              type: 'numericColumn',
            },
            {
              headerName: 'Remaining',
              width: this.readOnlyMode ? 90 : 120,
              valueGetter: function getRemainingCube(params): any {
                return params.data && params.data.remaining && params.data.remaining.cube
                  ? params.data.remaining.cube
                  : '';
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'Weight',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: '',
              field: 'planned',
              width: this.readOnlyMode ? 76 : 120,
              valueGetter: (params) => {
                const dataPlannedWeight = _.get(params, 'data.planned.weight', '');
                return this.handlePlannedColVisibility(dataPlannedWeight);
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
              headerValueGetter: (params) => `${this.allowableLoadsService.isBypassModel ? 'BYP' : ''} Planned`,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              width: this.readOnlyMode ? 72 : 120,
              valueGetter: function getLoadedWeight(params): any {
                let weightLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((closeTo) => {
                    closeTo.children.forEach((trailer) => {
                      if (!trailer.bypassInd) {
                        weightLoadSum += trailer.loadedWeight;
                      }
                    });
                  });
                }
                return weightLoadSum || '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
            {
              headerName: 'Remaining',
              field: 'remaining',
              width: this.readOnlyMode ? 90 : 120,
              valueGetter: function getRemainingWeight(params): any {
                return params.data && params.data.remaining && params.data.remaining.weight
                  ? params.data.remaining.weight
                  : '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'Load Average',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              width: this.readOnlyMode ? 76 : 120,
              valueGetter: function getPlannedLoadAvg(params): any {
                return params.data && params.data.planned && params.data.planned.loadAverage
                  ? params.data.planned.loadAverage
                  : '';
              },
              valueFormatter: (params) => this.formatDecimal(params),
              type: 'numericColumn',
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              width: this.readOnlyMode ? 66 : 120,
              valueGetter: function getPlannedWeight(params): any {
                return params.data && params.data.loaded && params.data.loaded.loadAverage
                  ? params.data.loaded.loadAverage
                  : '';
              },
              valueFormatter: (params) => {
                return this.formatDecimal(params);
              },
              type: 'numericColumn',
            },
          ],
        },
        {
          headerName: 'HSS',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'hssLoadCount',
              width: this.readOnlyMode ? 76 : 120,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.plannedHss && params.data.plannedHss.loadCount
                  ? params.data.plannedHss.loadCount
                  : '';
              },
            },
            {
              headerName: 'Actual',
              field: 'hssLoadCount',
              width: this.readOnlyMode ? 66 : 120,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.loadedHss && params.data.loadedHss.loadCount
                  ? params.data.loadedHss.loadCount
                  : '';
              },
            },
          ],
        },
        {
          headerName: 'Others',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'TUC (%)',
              field: 'trailerUtilizationCapability',
              width: this.readOnlyMode ? 76 : 120,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.trailerUtilizationCapability
                  ? params.data.trailerUtilizationCapability
                  : '';
              },
            },
            {
              headerName: 'TU (%)',
              field: 'closedTrailerUtilization',
              width: this.readOnlyMode ? 66 : 120,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.closedTrailerUtilization ? params.data.closedTrailerUtilization : '';
              },
            },
          ],
        },
      ],
      defaultColDef: {
        suppressMenu: true,
        sortable: true,
        resizable: true,
      },
      groupDefaultExpanded: 0,
      masterDetail: true,
      // Collapse all child nodes when main group is collapsed
      onRowGroupOpened: (event: RowGroupOpenedEvent) => {
        if (event.expanded) {
          event.api.expandAll();
          event.api.resetRowHeights();
        }
      },
      getRowHeight: (params) => {
        const isDetailRow = params.node.detail;
        if (isDetailRow) {
          let detailPanelHeight = params.data.children.length * 30 + 16;
          params.data.children.map((node) => {
            detailPanelHeight += node.children.length * 27 + 35 + 5;
          });
          // saves the lane node to recalculate its height if necessary
          this.detailLanesNodes.push({ lane: params.data.lane, node: params.node, originalHeight: detailPanelHeight });
          return detailPanelHeight;
        } else {
          return 30;
        }
      },
      detailCellRendererParams: {
        // level 2 grid options
        detailGridOptions: {
          headerHeight: 0,
          rowHeight: 27,
          suppressPropertyNamesCheck:true,
          frameworkComponents: this.frameworkComponents,
          icons: {
            groupExpanded: `<img class="group-expanded-icon" src="./assets/images/expanded.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"expandButton">`,
            groupContracted: `<img src="./assets/images/contracted.svg"
              style="width: 22px; position: absolute; left: 20px; top: 4px;" data=test"contractButton">`,
          },
          onRowGroupOpened: (event: RowGroupOpenedEvent) => {
            const groupRows = event.node.parent.allLeafChildren;
            // TODO : Replace arbitrary numbers with auto height properties
            let detailPanelHeight = groupRows.length * 30 + 16;
            groupRows.map((node) => {
              const nodeHeight = node.expanded ? node.data.children.length * 27 + 35 + 5 : 0;

              detailPanelHeight += nodeHeight;
            });

            const groupToResize = event.node.data.groupId;
            const detailNodeToResize = this.gridApi.getRowNode(groupToResize).detailNode;
            detailNodeToResize.setRowHeight(detailPanelHeight);
            this.gridApi.onRowHeightChanged();
          },
          columnDefs: [
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              width: 45,
              minWidth: 45,
              maxWidth: 45,
              suppressMenu: true,
              showRowGroup: true,
              field: 'closeToSicCd',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell secondary-table-cell-caption',
              width: this.readOnlyMode ? 100 : 120,
              suppressMenu: true,
              minWidth: this.readOnlyMode ? null : 72,
              maxWidth: this.readOnlyMode ? null : 72,
              valueGetter: (params) => {
                return params.data && params.data.closeToSicCd === this.datasource.sic ? 'Hold to' : 'Close to';
              },
            },
            {
              width: 210,
              headerClass: 'secondary-table-header',
              showRowGroup: 'closeToSicCd',
              suppressMenu: true,
              // width: this.readOnlyMode ? 100 : 184,
              field: 'closeToSicCd',
              cellClass: 'secondary-table-cell close-to-cell',
            },
            {
              width: 140,
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell',
              suppressMenu: true,
              headerName: 'Special Services',
              valueGetter: (params) => {
                return this.getSpecialServicesDetail(params.data);
              },
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
            // ByPass
            {
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 90 : 85,
              suppressMenu: true,
              cellRenderer: (params) => {
                let bypassCounter = 0;
                const paramObj = params.data.children;
                paramObj.forEach((item) => {
                  if (item.bypassInd || item.ptlTrlrInd) {
                    bypassCounter += 1;
                  }
                });
                return this.handlePlannedColVisibility(bypassCounter ? bypassCounter : '');
              },
            },
            {
              // Loads Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 70 : 110,
              suppressMenu: true,
              type: 'numericColumn',
              field: 'planned',
              cellRenderer: (params) => {
                const dataPlannedLoadCount = _.get(params, 'data.planned.loadCount', '');
                return this.handlePlannedColVisibility(dataPlannedLoadCount);
              },
            },
            {
              // Loads Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 66 : 106,
              suppressMenu: true,
              type: 'numericColumn',
              field: 'loaded',
              valueGetter: function getLoadCount(params): any {
                return params.data && params.data.loaded && params.data.loaded.loadCount
                  ? params.data.loaded.loadCount
                  : '';
              },
            },
            {
              // Cube Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 76 : 120,
              suppressMenu: true,
              valueGetter: (params) => {
                const dataPlannedCube = _.get(params, 'data.planned.cube', '');
                return this.handlePlannedColVisibility(dataPlannedCube);
              },
              type: 'numericColumn',
            },
            {
              // Cube Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 72 : 120,
              suppressMenu: true,
              type: 'numericColumn',
              valueGetter: function getLoadedCube(params): any {
                let cubeLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((trailer) => {
                    if (!trailer.bypassInd) {
                      cubeLoadSum += trailer.loadedCube;
                    }
                  });
                }
                return cubeLoadSum || '';
              },
            },
            {
              // Cube Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 90 : 120,
              suppressMenu: true,
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining ? params.data.remaining.cube : '';
              },
              type: 'numericColumn',
            },
            {
              // Weight Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 76 : 120,
              suppressMenu: true,
              valueGetter: (params) => {
                const dataPlannedWeight = _.get(params, 'data.planned.weight', '');
                return this.handlePlannedColVisibility(dataPlannedWeight);
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Loaded
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 72 : 120,
              suppressMenu: true,
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  params.data.children.forEach((trailer) => {
                    if (!trailer.bypassInd) {
                      weightLoadSum += trailer.loadedWeight;
                    }
                  });
                }
                return weightLoadSum || '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Weight Remaining
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 90 : 120,
              suppressMenu: true,
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining ? params.data.remaining.weight : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 76 : 120,
              suppressMenu: true,
              valueGetter: function getLoadAvgPlanned(params) {
                return params.data && params.data.planned ? params.data.planned.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // Load Average Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 66 : 120,
              suppressMenu: true,
              valueGetter: function getLoadAvgActual(params) {
                return params.data && params.data.loaded ? params.data.loaded.loadAverage : '';
              },
              type: 'numericColumn',
              valueFormatter: this.formatDecimal,
            },
            {
              // HSS Planned
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 76 : 120,
              suppressMenu: true,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.plannedHss && params.data.plannedHss.loadCount
                  ? params.data.plannedHss.loadCount
                  : '';
              },
            },
            {
              // HSS Actual
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 66 : 120,
              suppressMenu: true,
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data && params.data.loadedHss && params.data.loadedHss.loadCount
                  ? params.data.loadedHss.loadCount
                  : '';
              },
            },
            {
              // TUC (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 76 : 120,
              suppressMenu: true,
              valueGetter: function(params) {
                return params.data ? params.data.trailerUtilizationCapability : '';
              },
              type: 'numericColumn',
            },
            {
              // TU (%)
              headerClass: 'secondary-table-header',
              cellClass: 'secondary-table-cell align-numeric-cell',
              width: this.readOnlyMode ? 66 : 120,
              suppressMenu: true,
              valueGetter: function(params) {
                return params.data ? params.data.closedTrailerUtilization : '';
              },
              type: 'numericColumn',
            },
          ],
          groupDefaultExpanded: 1,
          masterDetail: true,
          getRowHeight: (params) => {
            const isDetailRow = params.node.detail;
            if (isDetailRow) {
              return params.data.children.length * 27 + 35 + 5;
            } else {
              return 30;
            }
          },
          detailCellRendererParams: {
            // level 3 grid options
            detailGridOptions: { 
              suppressMenu: true,	              
              suppressPropertyNamesCheck: true,
              getRowClass: () => {
                return 'deep-table-row';
              },
              headerHeight: 35,
              rowHeight: 27,
              frameworkComponents: this.frameworkComponents,
              columnDefs: [
                {
                  headerName: '',
                  width: 140,
                  maxWidth: 140,
                  pinned: 'left',
                  lockPinned: true,
                  valueGetter: ({ data: { hasNoChildrens } }) => {
                    return !hasNoChildrens ? IconsKeys.description : '';
                  },
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  sortable: false,
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'camera-icon'],
                  headerClass: 'deep-table-cell-header',
                },
                {
                  headerName: '',
                  width: 40,
                  maxWidth: 40,
                  lockPinned: true,
                  pinned: 'left',
                  valueGetter: ({ data: { hasNoChildrens } }) => {
                    return !hasNoChildrens ? IconsKeys.photoCamera : '';
                  },
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  sortable: false,
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'camera-icon'],
                  headerClass: 'deep-table-cell-header',
                  // Cell event triggers and provides a new value to the Behavior Subject
                  onCellClicked: (params) => {
                    if (params.data.filterSic) {
                      LanesViewBoardTemplate._clickSubject.next(params.data);
                    }
                  },
                },
                {
                  headerName: 'Loads',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  lockPinned: true,
                  pinned: 'left',
                  field: 'load',
                  cellClass: 'deep-table-cell',
                  headerClass: 'deep-table-cell-header',
                  width: 160,
                  maxWidth: 162,
                  cellClassRules: {
                    'no-trailer-message': function(params): any {
                      return params.data.hasNoChildrens;
                    },
                  },
                  valueGetter: (params) => {
                    if (params.data.hasNoChildrens) {
                      return 'No Trailers Loaded in this Lane';
                    }
                    return params.data.load;
                  },
                },
                {
                  field: 'Special Services',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  lockPinned: true,
                  pinned: 'left',
                  sortable: false,
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  width: 130,
                  maxWidth: 130,
                  valueGetter: (params) => {
                    const { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd } = params.data;
                    return { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd };
                  },
                  cellRenderer: 'SpecialServiceFormatterComponent',
                  minWidth: 200,
                },
                {
                  headerName: 'Loaded Weight (lbs)',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedWeight',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 145,
                  maxWidth: 145,
                  valueGetter: ({ data: { loadedWeight, hasNoChildrens } }) => {
                    loadedWeight = `${loadedWeight}`;
                    const chars = loadedWeight.length - 3;
                    if (!hasNoChildrens) {
                      if (chars > 0) {
                        return loadedWeight.substring(0, chars) + ',' + loadedWeight.substring(chars, 6);
                      } else {
                        return loadedWeight;
                      }
                    } else {
                      return null;
                    }
                  },
                },
                {
                  headerName: 'Loaded Cube',
                  headerClass: 'deep-table-cell-header',
                  cellClass: ['deep-table-cell', 'cell-aling-right'],
                  lockPinned: true,
                  pinned: 'left',
                  field: 'loadedCube',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 115,
                  maxWidth: 115,
                  valueGetter: (params) => {
                    return params.data.loadedCube ? params.data.loadedCube.toFixed(2) : null;
                  },
                },
                {
                  headerName: 'Source',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'sourceCd',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 120,
                  maxWidth: 120,
                },
                {
                  headerName: 'Door #',
                  headerClass: 'deep-table-cell-header',
                  cellClass: 'deep-table-cell',
                  lockPinned: true,
                  pinned: 'left',
                  field: 'eventDoor',
                  lockPosition: true,
                  suppressMovable: true, 
                  suppressMenu: true, 
                  width: 120,
                  maxWidth: 120,
                  valueGetter: (params) => {
                    const convert = parseInt(params.data.eventDoor, 10);
                    return !isNaN(convert) ? convert : '';
                  },
                },
                {
                  headerName: 'Status',
                  field: 'statusCd',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'StatusFormatterComponent',
                  cellClass: ['statusColumn', 'deep-table-cell'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 120,
                  maxWidth: 120,
                },
                {
                  headerName: '',
                  headerClass: 'deep-table-cell-header',
                  cellRenderer: 'IconsFormatterComponent',
                  cellClass: ['iconColumn', 'deep-table-cell', 'bypassColumn'],
                  lockPinned: true,
                  pinned: 'left',
                  lockPosition: true,
                  suppressMovable: true,
                  suppressMenu: true,
                  width: 40,
                  maxWidth: 40,
                  valueGetter: (params) => {
                    return params.data.bypassInd ? IconsKeys.bypass : params.data.ptlTrlrInd ? IconsKeys.ptl : '';
                  },
                },
              ],
            },
            getDetailRowData: function(params): void {
              params.successCallback(params.data.children);
            },
          },
        },
        getDetailRowData: function(params): void {
          params.successCallback(params.data.children);
        },
      },
      onModelUpdated(params): void {
        params.api.refreshHeader();
      },
    };

    this.viewTemplates = [new LanesViewBoardTemplate(this.serviceCentersService)];
    this.views = [this.viewTemplates[0].createLanesView()];
    this.viewTemplates[0].clickSubject$.pipe(takeUntil(this.unsubscriber.done$)).subscribe((params) => {
      if (this.readOnlyMode) {
        this.showTrailerDetails(params);
      } else {
        this.setupSidePanel(params);
      }
    });

    this.sidePanelOpts = { allowFullScreen: false, isDashboard: false };
    this.showTrailerDetailsPanel = false;
  }

  ngOnDestroy(): void {
    this.clearDataGlobal();
    this.unsubscriber.complete();
  }

  clearDataGlobal(): void {
    this.selectedTrailer = null;
    this.unsubscriber.complete();
    this.gridApi = null;
    this.colApi = null;
    this.interactionSubscription.unsubscribe();
  }

  onLanesGridLoad(gridEvent: AgGridEvent): void {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
    this.initialSubscriptions();
  }

  // returns the list as a string
  getValuesAsString(values): string {
    return values.filter(Boolean).toString();
  }

  // just returns the list of values
  getValues(values): any {
    return values;
  }

  getBypAgg(values): any {
    return values.filter(Boolean).length;
  }

  getGuaranteedInd(values): any {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.guaranteed : '';
  }

  getExceptionInd(values): any {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.circleFilled : '';
  }

  getCountAgg(values): any {
    return values.filter(Boolean).length;
  }

  getSumAgg(values): number {
    let sum = 0;
    values.forEach((val) => {
      sum += val ? val : 0;
    });
    return sum;
  }

  formatDecimal(number): string {
    return number.value
      ? Math.floor(number.value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '';
  }

  getSpecialServicesDetail(params: any): any {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      params.children.forEach((children2) => {
        _.forEach(total, (value, key) => {
          if (!!children2[key]) {
            total[key] = children2[key];
          }
        });
      });
    }
    return total;
  }

  getSpecialServices(params: any): any {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      params.children.forEach((children2) => {
        children2.children.forEach((children3) => {
          _.forEach(total, (value, key) => {
            if (!!children3[key]) {
              total[key] = children3[key];
            }
          });
        });
      });
    }
    return total;
  }

  /**
   * Show trailer-details component instead of side-panel
   */
  showTrailerDetails(params): void {
    if (params) {
      this.trailerDetailsData = {
        closeTmst: params.closeTmst,
        statusCd: params.statusCd,
        filterSic: this.selectedSic,
        load: params.load,
        loadedTimestamp: params.loadedTimestamp,
        currentEventTmst: params.currentEventTmst,
      };

      this.showTrailerDetailsPanel = true;
    }
  }

  setupSidePanel(params): void {
    if (params) {
      this.iframeUrl = this.externalUrlsService.parseUrl('linehaul') + params.load;
      this.laneData = {
        filterSic: this.selectedSic,
        selectedLane: params.lane,
        sidePanelId: params.load,
        iframeUrl: this.iframeUrl,
        ...params,
      };
      this.sidePanelData = {
        currentEventTmst: this.laneData.currentEventTmst,
        closeTmst: this.laneData.closeTmst,
        statusCd: this.laneData.statusCd,
        sidePanelId: this.laneData.sidePanelId,
        filterSic: this.selectedSic,
        laneData: this.laneData,
      };
    }
    this.openSidePanelDisplay();
  }

  openSidePanelDisplay(): void {
    if (this.sidePanelData) {
      const message = new SidePanelDataStructures(this.sidePanelData, this.sidePanelOpts, SidePanelStatus.OPENED);
      this.interactionService.sendDataSidePanel(message);
      this.sidePanelVisibilityService.closePanels();
    }
  }

  getMoveModeCD(params: any): any {
    const total = {
      exclusiveInd: false,
      supplementalInd: false,
      label: params.lane,
    };

    if (params.children) {
      params.children.forEach((children) => {
        total.exclusiveInd = total.exclusiveInd || children.moveMode === MoveModeCd.EXCLUSIVE;
        total.supplementalInd = total.supplementalInd || children.moveMode === MoveModeCd.SUPPLEMENTAL;
      });
    }
    return total;
  }

  setTrailerNbr(trailerNumber: string): void {
    this.trailerNbr = trailerNumber;
  }

  formatCloseTo(params: any): any {
    let closeTo = params.data.closeToSicCd;
    if (params.node.rowIndex === 0 && this.selectedSic === params.data.lane) {
      closeTo = '';
    } else {
      if (closeTo.length > 11) {
        closeTo = closeTo.slice(0, 12) + '...';
      }
    }
    return closeTo;
  }

  checkDateandShift(sic, shift, planDate): Observable<boolean> {
    const selectedShift = shift.toUpperCase();
    const correctShift = shift && (selectedShift === 'O' || selectedShift === 'F'); // only enable add load icon for 'F' and 'O'
    if (!correctShift) {
      return of(false);
    }

    let correctDate: Observable<boolean>;
    if (selectedShift === 'F') {
      correctDate = this.checkDateforFAC(sic, planDate);
    } else {
      correctDate = this.checkDateforOutbound(sic, planDate);
    }

    return correctDate.pipe(shareReplay());
  }

  checkDateforOutbound(sic, planDate): Observable<boolean> {
    if (planDate instanceof Date) {
      return this.shiftService.isInOutboundShiftRange(planDate, sic, true);
    } else {
      const formatDate = new Date(planDate.replace(/-/g, '/').replace(/T.+/, ''));
      return this.shiftService.isInOutboundShiftRange(new Date(formatDate), sic, true);
    }
  }

  checkDateforFAC(sic, planDate): Observable<boolean> {
    if (planDate instanceof Date) {
      return this.shiftService.isInFacShiftRange(planDate, sic, true);
    } else {
      const formatDate = new Date(planDate.replace(/-/g, '/').replace(/T.+/, ''));
      return this.shiftService.isInFacShiftRange(new Date(formatDate), sic, true);
    }
  }

  checkLaneLoadReqAccess(params): Observable<boolean> {
    const canSeePlannedData =
      this.authService.allowedToSeePlannedAlways() ||
      (this.authService.allowedToSeePlannedOnRelease() && this.allowableLoadsService.releaseStatus);
    const hasPlannedData =
      params.data.lane.toUpperCase() !== 'UNKNOWN' && params.data.planned && params.data.planned.loadCount > 0;
    const allowedToAddLR = true;
    return this.loadReqAccess === true
      ? this.dateAndShiftValid$.pipe(
          map(
            (valid) =>
              valid &&
              hasPlannedData &&
              allowedToAddLR &&
              !this.allowableLoadsService.isBypassModel &&
              canSeePlannedData
          )
        )
      : of(false);
  }

  handleLoadReqAccess(currentGlobalFilter: GlobalFilters): void {
    if (!currentGlobalFilter.shift) {
      currentGlobalFilter.shift = 'O';
    }
    const differentDate =
      new Date(currentGlobalFilter.planDate).getTime() !== new Date(this.lastReqGlobalFilter.planDate).getTime();
    const differentShift = currentGlobalFilter.shift !== this.lastReqGlobalFilter.shift;
    const differentSic = currentGlobalFilter.sic !== this.lastReqGlobalFilter.sic;

    if (differentDate) {
      this.lastReqGlobalFilter.planDate = currentGlobalFilter.planDate;
    }
    if (differentShift) {
      this.lastReqGlobalFilter.shift = currentGlobalFilter.shift;
    }
    if (differentSic) {
      this.lastReqGlobalFilter.sic = currentGlobalFilter.sic;
    }
    this.dateAndShiftValid$ = this.checkDateandShift(
      currentGlobalFilter.sic,
      currentGlobalFilter.shift,
      currentGlobalFilter.planDate
    );
    this.loadReqAccess = true;
  }

  handlePlannedColVisibility(colValue): any {
    const showPlannedLoadCount = this.authService.allowedToSeePlannedAlways();
    const checkReleaseStatus =
      (this.authService.allowedToSeePlannedOnRelease() || !this.authService.isAuthorizedUser()) &&
      this.allowableLoadsService.releaseStatus;
    return checkReleaseStatus || showPlannedLoadCount || this.allowableLoadsService.isBypassModel ? colValue : '';
  }

  expandSelectedTrailer(): void {
    this.gridApi.forEachNode((node) => {
      if (node.data.lane === this.selectedTrailer) {
        node.setExpanded(true);
        this.interactionService.setSelectedTrailer(null);
      }
    });
  }
}
