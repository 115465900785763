import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { get as _get } from 'lodash';
import { Subject } from 'rxjs';
@Component({
  selector: 'shipment-details-remarks',
  templateUrl: './shipment-details-remarks.component.html',
  styleUrls: ['./shipment-details-remarks.component.scss'],
})
export class ShipmentDetailsRemarksComponent implements OnInit, OnDestroy {
  @Input() shipment: any;
  data: string;

  constructor() {}

  ngOnInit() {
    this.updateFormFromShipment(this.shipment);
  }

  ngOnDestroy() {}

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    this.data = _get(shipment, 'remark[0].remark', 'None');
  }
}
