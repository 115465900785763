import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  GetServiceCenterDetailsBySicPath,
  ListLocationsQuery,
  ListLocationsResp,
  ListLocationsRqst,
  ListOperationalServiceCentersResp,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ActionCd, DriverTurnTypeCdHelper, Envelope, ShiftCd, ShiftCdHelper } from '@xpo-ltl/sdk-common';

import {
  DsrJspBidByLane,
  GetPlanningDriverMetricsPath,
  GetPlanningDriverMetricsQuery,
  GetPlanningDriverMetricsResp,
  GetPlanningDriverPath,
  GetPlanningDriverQuery,
  GetPlanningDriverResp,
  LinehaulOperationsApiService,
  ListPlanningDriversQuery,
  ListPlanningDriversResp,
  PlanningDriver,
  PlanningDriverSummary,
  UpsertDriversRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import {
  OperationsDetailLnh,
  OperationsSummary,
  UpsertLinehaulDriversRqst,
  WfmOperationsProfile,
  WorkForceMgmtApiService,
} from '@xpo-ltl/sdk-workforcemgmt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

import { BidDetails, Driver } from '../models/drivers.model';

@Injectable()
export class DriversService {
  private readonly _periodSubject = new BehaviorSubject<Date>(new Date());
  private readonly _shiftSubject = new BehaviorSubject<string>(ShiftCd.OUTBOUND);
  private readonly _sicSubject = new BehaviorSubject<string>(null);

  driver: Driver;
  period$ = this._periodSubject.asObservable();
  shift$ = this._shiftSubject.asObservable();
  sic$ = this._sicSubject.asObservable();
  bidDetails: DsrJspBidByLane[];
  isSearchingPlanningDrivers$ = new BehaviorSubject<boolean>(false);

  private listPlanningDriversSubject = new BehaviorSubject<ListPlanningDriversResp>(undefined);
  listPlanningDrivers$: Observable<ListPlanningDriversResp> = this.listPlanningDriversSubject.asObservable();

  private listPlanningDriversForAddDriversSubject = new BehaviorSubject<ListPlanningDriversResp>(undefined);
  listPlanningDriversForAddDrivers$ = this.listPlanningDriversForAddDriversSubject.asObservable();

  private getHeaderDetailsSubject = new BehaviorSubject<GetPlanningDriverMetricsResp>(undefined);
  getHeaderDetails$ = this.getHeaderDetailsSubject.asObservable();

  constructor(
    private linehaulService: LinehaulOperationsApiService,
    private locationApiService: LocationApiService,
    private workforcemgmtService: WorkForceMgmtApiService
  ) {}

  set sic(value: string) {
    this._sicSubject.next(value);
  }

  set period(value: Date) {
    this._periodSubject.next(value);
  }

  set shift(value: string) {
    this._shiftSubject.next(value);
  }

  get sic(): string {
    return this._sicSubject.value;
  }

  get period(): Date {
    return this._periodSubject.value;
  }

  get shift(): string {
    return this._shiftSubject.value;
  }

  getAllSICs(): Observable<ListOperationalServiceCentersResp> {
    // TODO: return type is incorrect
    return <any>this.locationApiService.listOperationalServiceCenters(<any>{
      listInfo: null,
    });
  }

  getHeaderDetails(): Observable<GetPlanningDriverMetricsResp> {
    if (this.sic && this._periodSubject?.value && this._shiftSubject?.value) {
      const path = new GetPlanningDriverMetricsPath();
      path.sicCd = this.sic;
      const query = new GetPlanningDriverMetricsQuery();
      query.shiftCd = this._shiftSubject?.value.slice(0, 1);
      let month: string;
      if (this._periodSubject.value.getMonth() < 9) {
        month = '0' + (this._periodSubject.value.getMonth() + 1);
      } else {
        month = (this._periodSubject.value.getMonth() + 1).toString();
      }
      const planDate =
        this._periodSubject.value.getFullYear() + '-' + month + '-' + this._periodSubject.value.getDate() + '-00.00.00';
      query.plannedDate = planDate;

      return this.linehaulService.getPlanningDriverMetrics(path, query, { loadingOverlayEnabled: false }).pipe(
        map((resp: GetPlanningDriverMetricsResp) => {
          this.getHeaderDetailsSubject.next(resp);
          return resp;
        })
      );
    } else {
      return of({} as GetPlanningDriverMetricsResp);
    }
  }

  getBidsDetails(state: XpoBoardState): Observable<XpoBoardData> {
    const details = this.bidDetails.map((item: any) => new BidDetails(item));
    return of(new XpoBoardData(state, details, details.length, 10));
  }

  getPlanningDrivers(empId?: string): Observable<PlanningDriverSummary> {
    if (this.sic && this._periodSubject?.value && this._shiftSubject?.value) {
      const query = new GetPlanningDriverQuery();
      query.sicCd = this.sic;
      query.planDate = this.getPlanDate();
      query.shiftCd = this._shiftSubject.value?.toUpperCase() === 'OUTBOUND' ? 'O' : 'F';
      const params = new GetPlanningDriverPath();
      params.employeeId = empId?.toUpperCase() || this.driver?.employeeId?.toUpperCase();

      return this.linehaulService.getPlanningDriver(params, query, { loadingOverlayEnabled: false }).pipe(
        tap(() => this.isSearchingPlanningDrivers$.next(true)),
        map((resp: GetPlanningDriverResp) => {
          this.isSearchingPlanningDrivers$.next(false);
          // TODO need a refactor when the API is fully working
          // Assigning bidDetails only when existing
          if (resp?.planningDriverSummary?.planningDriver?.lnhDsrJspBids) {
            this.bidDetails = resp.planningDriverSummary.planningDriver.lnhDsrJspBids;
          } else {
            this.bidDetails = [];
          }

          return resp.planningDriverSummary;
        })
      );
    } else {
      return of({} as PlanningDriverSummary);
    }
  }

  listPlanningDrivers(): Observable<ListPlanningDriversResp> {
    if (this.sic && this.period && this.shift) {
      const query = new ListPlanningDriversQuery();
      query.sicCd = this.sic.toUpperCase();
      query.planDate = this.getPlanDate();
      query.shiftCd = this._shiftSubject.value.slice(0, 1);
      query.dsrInLnhInd = true;

      return this.linehaulService.listPlanningDrivers(query, { loadingOverlayEnabled: false }).pipe(
        map((resp: ListPlanningDriversResp) => {
          this.listPlanningDriversSubject.next(resp);
          return resp;
        })
      );
    } else {
      return of({} as ListPlanningDriversResp);
    }
  }

  /** This is to get drivers for a sic other than the plan sic for the purposes of adding new driver(s) */
  listPlanningDriversForAddDriver(sic?): Observable<any> {
    if (sic) {
      const query = new ListPlanningDriversQuery();
      query.sicCd = sic || this.sic;
      query.planDate = this.getPlanDate();
      query.shiftCd = this._shiftSubject?.value?.slice(0, 1);
      query.dsrInLnhInd = false;

      return this.linehaulService.listPlanningDrivers(query, { loadingOverlayEnabled: false }).pipe(
        map((resp: ListPlanningDriversResp) => {
          this.listPlanningDriversForAddDriversSubject.next(resp);
          return resp;
        }),
        catchError(() => {
          this.listPlanningDriversForAddDriversSubject.next(undefined);
          return of(undefined);
        })
      );
    }
  }

  upsertPlanningDrivers(empIds, sic?): Observable<any> {
    const upsertDriversRqst = new UpsertDriversRqst();
    const planDate =
      this._periodSubject.value.getMonth() +
      1 +
      '/' +
      this._periodSubject.value.getDate() +
      '/' +
      this._periodSubject.value.getFullYear();
    upsertDriversRqst.planDate = planDate;
    upsertDriversRqst.sicCd = sic || this.sic;
    upsertDriversRqst.shiftCd = this._shiftSubject.value;
    upsertDriversRqst.sicCd = sic || this._sicSubject?.value;
    upsertDriversRqst.shiftCd = this._shiftSubject?.value?.slice(0, 1);
    upsertDriversRqst.employeeIds = empIds;
    upsertDriversRqst.planningDriverSummaries = this.listPlanningDriversSubject?.value?.planningDriverSummaries;
    return this.linehaulService.upsertDrivers(upsertDriversRqst).pipe(
      map((resp) => {
        return {};
      })
    );
  }

  upsertLinehaulDrivers(inputSic, drivers): Observable<void> {
    const request = new UpsertLinehaulDriversRqst();
    request.loadLnhDriversInd = false;
    request.planDate = this.getPlanDate();
    request.sicCd = this._sicSubject?.value;
    request.shiftCd = ShiftCdHelper.toEnum(this._shiftSubject?.value);
    request.operationsSummaries = new Array(drivers.length);

    drivers.forEach(({ data }, index) => {
      const driver: Driver = data;
      request.operationsSummaries[index] = new OperationsSummary();
      request.operationsSummaries[index].wfmOperationsProfile = new WfmOperationsProfile();

      const opDetailLnh = new OperationsDetailLnh();
      opDetailLnh.employeeId = driver.employeeId;
      opDetailLnh.planDate = this.getPlanDate();
      opDetailLnh.shiftCd = this._shiftSubject?.value.slice(0, 1);
      opDetailLnh.turnTypeCd = DriverTurnTypeCdHelper.toEnum(driver.turnTypeCd);
      opDetailLnh.listActionCd = ActionCd.ADD;
      request.operationsSummaries[index].operationsDetailLnhs = [opDetailLnh];

      const operationProfile = request.operationsSummaries[index].wfmOperationsProfile;

      operationProfile.employeeId = driver.employeeId;

      if (this.sic === inputSic) {
        if (driver.assignSicCd && driver.assignSicCd !== this.sic && driver.domicileSicCd !== this.sic) {
          operationProfile.assignedSicCd = this.sic;
          operationProfile.lnhCurrentSic = this.sic;
        }
      } else {
        const sicValue =
          driver.domicileSicCd === this.sic && driver.assignSicCd && driver.assignSicCd !== this.sic ? '' : this.sic;

        operationProfile.assignedSicCd = sicValue;
        operationProfile.lnhCurrentSic = sicValue;
      }
    });
    return this.workforcemgmtService.upsertLinehaulDrivers(request);
  }

  changeTurnType(empId, isChecked): void {
    let planningDriverSummary: any;
    const planningDriverSummaries = this.listPlanningDriversSubject?.value?.planningDriverSummaries;
    for (let i = 0; i < planningDriverSummaries.length; i++) {
      if (planningDriverSummaries[i].planningDriver.employeeId === empId) {
        planningDriverSummary = planningDriverSummaries[i];
      }
    }
    if (isChecked && !planningDriverSummary.planningDriver.turnTypeCd) {
      planningDriverSummary.planningDriver.turnTypeCd = 'CityTurn';
      const upsertDriversRqst = new UpsertDriversRqst();
      upsertDriversRqst.sicCd = this.sic;
      upsertDriversRqst.planDate = this.getPlanDate();
      upsertDriversRqst.shiftCd = this._shiftSubject.value.slice(0, 1);
      upsertDriversRqst.planningDriverSummaries = [planningDriverSummary];
      upsertDriversRqst.employeeIds = [empId];
      this.linehaulService
        .upsertDrivers(upsertDriversRqst, { toastOnError: true })
        .pipe(take(1))
        .subscribe();
    }
    if (!isChecked && planningDriverSummary.planningDriver.turnTypeCd) {
      delete planningDriverSummary.planningDriver.turnTypeCd;
      const upsertDriversRqst = new UpsertDriversRqst();
      upsertDriversRqst.sicCd = this._sicSubject.value;
      upsertDriversRqst.planDate = this.getPlanDate();
      upsertDriversRqst.shiftCd = this._shiftSubject.value.slice(0, 1);
      upsertDriversRqst.planningDriverSummaries = [planningDriverSummary];
      upsertDriversRqst.employeeIds = [empId];
      this.linehaulService
        .upsertDrivers(upsertDriversRqst, { toastOnError: true })
        .pipe(take(1))
        .subscribe();
    }
  }

  getPlanDate(): string {
    return formatDate(this._periodSubject.value, 'yyyy-MM-dd', 'en-US');
  }

  getDomicileSic(sic: string): Observable<Envelope<ListLocationsResp>> {
    const params = new GetServiceCenterDetailsBySicPath();
    params.sicCd = sic;

    const listLocationsRqst = new ListLocationsRqst();
    const listLocationsQuery = new ListLocationsQuery();
    listLocationsRqst.refSicCd = [sic];
    listLocationsRqst.groupCategoryCd = [];
    listLocationsQuery.activeInd = true;
    listLocationsQuery.meetAndTurnDesiredInd = true;
    listLocationsQuery.satelliteDesiredInd = true;
    listLocationsQuery.zoneDesiredInd = true;
    return this.locationApiService.listLocations(listLocationsRqst, listLocationsQuery, {
      loadingOverlayEnabled: false,
    });
  }

  changeDriverAvailability(status: boolean, employeeId: string): void {
    const upsertDriversRqst = new UpsertDriversRqst();
    upsertDriversRqst.sicCd = this.sic;
    upsertDriversRqst.shiftCd = this._shiftSubject.value?.toUpperCase() === 'OUTBOUND' ? 'O' : 'F';
    upsertDriversRqst.planDate = this.getPlanDate();
    upsertDriversRqst.employeeIds = [];
    const planningDriverSummary = new PlanningDriverSummary();
    const planningDriver = new PlanningDriver();
    planningDriver.employeeId = employeeId;
    planningDriver.availableInd = status;
    planningDriverSummary.planningDriver = planningDriver;
    upsertDriversRqst.planningDriverSummaries = [planningDriverSummary];

    this.linehaulService
      .upsertDrivers(upsertDriversRqst)
      .pipe(take(1))
      .subscribe();
  }
}
