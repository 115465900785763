import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { InteractionService } from '../../shared/services/interaction.service';
import { AppRoutes } from './../../shared/enums/app-routes.enum';
import { ChangeViewService } from './services/change-view.service';

@Component({
  selector: 'app-allowable-loads',
  templateUrl: './allowable-loads.component.html',
  styleUrls: ['./allowable-loads.component.scss'],
})
export class AllowableLoadsComponent implements OnInit, OnDestroy {
  @Input() readOnlyMode: boolean = false;
  readonly AppRoutes = AppRoutes;
  selectedView: string;
  data: string;
  interactionSubscription: Subscription;

  constructor(private changeViewService: ChangeViewService, private interactionService: InteractionService) {
    this.changeViewService.getCurrentView().subscribe((view) => {
      this.selectedView = view;
    });
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.METRICS)
      .subscribe(({ data: { metrics } }) => {
        this.data = metrics ? `FromMetrics ${metrics}` : 'ByDefault';
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.interactionSubscription.unsubscribe();
  }
}
