<div class="filter-wrap">
  <label for="shift">Shift</label>
  <mat-select id="shift"
      data-test="shiftFilter"
      #shiftVal
      [(ngModel)]="shift"
      (ngModelChange)="valueChange.emit(shiftVal.value)">
    <mat-option *ngFor="let shift of shiftOptions"
        [value]="shift.code"
        [disabled]="shift.disabled">
      {{ shift.title }}
    </mat-option>
  </mat-select>
</div>
