<div class="trailersbyLane-header">
  <div class="toogle-input"
      *ngIf="type === ExceptionTypeCd.LOAD_REQUEST">
    <span class="toogle-input__title">Show only bypass</span>
    <div class="toogle-input__switch"
        (click)="showOnlyBypass()">
      <input [checked]="toggleBypassButton"
          type="checkbox" />
      <span class="slider round"></span>
    </div>
  </div>
  <div class="toogle-input"
      *ngIf="type === ExceptionTypeCd.PROGRESSIVE_LOAD">
    <span class="toogle-input__title">Show only underutilized trailers</span>
    <div class="toogle-input__switch"
        (click)="showOnlyUndeutilizedTrailers()">
      <input [checked]="toggleUnderUtilizedButton"
          type="checkbox" />
      <span class="slider round"></span>
    </div>
  </div>
</div>

<div class="box">
  <div class="box-body">
    <div class="box-aside">
      <div *ngFor="let laneInfo of filteredLaneData; let i = index"
          class="aside-item">
        <div class="item-header">
          <span class="action-button"
              (click)="toogleItem(i)">
            <ng-container *ngIf="!expandedItem[i]; else iconMinus">
              <mat-icon [svgIcon]="'icon_plus'"></mat-icon>
            </ng-container>
            <ng-template #iconMinus>
              <mat-icon class="minus-icon"
                  [svgIcon]="'icon_minus'"></mat-icon>
            </ng-template>
          </span>
          <span class="title">Close To - {{ laneInfo.loadLaneInstructionSummary.closeToSicCd }} </span>
          <span class="counter-chip">
            <span class="count">
              <ng-container *ngIf="laneInfo.trailerLoadInfo; else noResultCount">
                {{ laneInfo.trailerLoadInfo.length }}
              </ng-container>
              <ng-template #noResultCount>0</ng-template>
            </span>
          </span>
          <span class="warning-check"
              *ngIf="laneInfo.showWarning && type === ExceptionTypeCd.PROGRESSIVE_LOAD">
            <mat-error>
              <mat-icon>warning</mat-icon>
            </mat-error>
          </span>
        </div>

        <ng-container *ngIf="expandedItem[i]">
          <div *ngFor="let trailer of laneInfo.trailerLoadInfo"
              class="item-info"
              [ngClass]="{ trailerBypass: !trailer?.bypassTrlrInd && toggleBypassButton }"
              (click)="showTrailer(laneInfo.loadLaneInstructionSummary.closeToSicCd, trailer)">
            <span class="item-content">
              <span class="row">
                <a class="trailer-number">
                  {{ getPrefix(trailer.trailerLoadHist.equipmentIdPrefix) }}-{{
                    trailer.trailerLoadHist.equipmentIdSuffixNbr
                  }}
                </a>
              </span>
              <span class="row">
                <span [ngClass]="{
                    'red-Ind': this.checkLoadedWeight(trailer)
                  }">Weight: {{ trailer.trailerLoadHist.loadedWeight }} lb
                </span>
                &nbsp;-&nbsp;

                <span [ngClass]="{
                    'red-Ind': this.checkLoadedCube(trailer)
                  }">
                  Cube: {{ trailer.trailerLoadHist.loadedCbePercentage }}%
                </span>
                &nbsp;
                <special-services [trailerHist]="trailer.trailerLoadHist"
                    *ngIf="this.type === ExceptionTypeCd.LOAD_REQUEST"></special-services>
                <span *ngIf="trailer.bypassTrlrInd && this.type === ExceptionTypeCd.LOAD_REQUEST"
                    class="bypassIcon">
                  <mat-icon svgIcon="SS_Bypass"
                      class="SS_Bypass"></mat-icon>
                </span>
              </span>
            </span>
          </div>
          <div *ngIf="!laneInfo.trailerLoadInfo || !laneInfo.trailerLoadInfo.length"
              class="no-results">
            No trailers loaded in this lane
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="selectedTrailer"
        class="box-content">
      <span class="row">
        <span class="field">
          <span class="field-title">STATUS</span>
          <span class="field-value">{{ selectedTrailer.currentStatus | dashFormatter }}</span>
        </span>
        <span class="field">
          <span class="field-title">SHIPMENTS</span>
          <span class="field-value">{{ selectedTrailer.shipments | dashFormatter }}</span>
        </span>
        <span class="field">
          <span class="field-title">DOOR</span>
          <span class="field-value">{{ selectedTrailer.door | dashFormatter }}</span>
        </span>
        <span class="field">
          <span class="field-title">CLOSED BY</span>
          <span class="field-value">{{ selectedTrailer.closedBy | dashFormatter }}</span>
        </span>
        <span class="field">
          <span class="field-title">CLOSE TIMESTAMP</span>
          <span class="field-value">{{ closeTimestamp | dashFormatter }}</span>
        </span>
      </span>

      <div *ngIf="notImagesFound; else gallery"
          class="no-images">
        <span>No images found</span>
      </div>
      <ng-template #gallery>
        <image-slideshow [images]="loadedTrailerImages"></image-slideshow>
      </ng-template>
    </div>
  </div>
</div>
