import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { merge, Observable, Subscription } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { LinehaulStateService } from '../../new-dashboard/services/linehaul-state.service';
import { AppRoutes } from '../../shared/enums/app-routes.enum';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { AuthService } from '../../shared/services/auth.service';
import { ChangeFeedService } from '../../shared/services/change-feed.service';
import { InteractionService } from '../../shared/services/interaction.service';
@Component({
  selector: 'refresh-data',
  templateUrl: './refresh-data.component.html',
  styleUrls: ['./refresh-data.component.scss'],
})
export class RefreshDataComponent implements OnInit, OnDestroy {
  @Input() route;
  private subscriptions: Subscription[] = [];

  AppRoutes = AppRoutes;
  showAlerts: boolean;
  sicQueueSelected: boolean;
  isSicUser: boolean;
  counter: number = 0;
  private unsubscriber = new Unsubscriber();

  constructor(
    private authService: AuthService,
    private interactionService: InteractionService,
    private changeFeedService: ChangeFeedService,
    private linehaulStateService: LinehaulStateService
  ) {}

  ngOnInit() {
    const refreshData$ = this.interactionService.subscribeToComponent(ComponentsEnum.REFRESH_DATA);
    const performanceDashboard$ = this.interactionService
      .subscribeToComponent(ComponentsEnum.PERFORMANCE_DASHBOARD)
      .pipe(map((resp) => resp.name !== 'initPerformanceFilter'));

    merge(refreshData$, performanceDashboard$)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((resp) => {
        if (resp) {
          this.counter = 0;
        }
      });
    this.subscriptions.push(this.changeFeedService.subscribeToChangeFeed().subscribe((resp) => this.counter++));

    this.subscriptions.push(
      this.authService.sicHSSToggle$.subscribe((isSicUser) => {
        this.sicQueueSelected = this.isSicUser = isSicUser;
      })
    );
    this.subscriptions.push(
      this.interactionService
        .subscribeToComponent(ComponentsEnum.SHOW_ALL_ALERTS)
        .subscribe((show) => (this.showAlerts = show))
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get isPerformanceDashboard(): boolean {
    if (this.route === AppRoutes.LOAD_MANAGEMENT && this.showAlerts) {
      this.changeShowAlerts();
    }
    return this.route === AppRoutes.PERFORMANCE_DASHBOARD;
  }

  refresh() {
    this.linehaulStateService.refreshData();
    this.interactionService.refreshData(true);
  }

  changeShowAlerts(): void {
    this.interactionService.updateShowAlerts(!this.showAlerts);
  }

  /**
   * Toggle to set default Queue (LH/SIC) HSS exceptions
   */
  sicQueueToggle(): void {
    this.sicQueueSelected = this.authService.sicHSSToggle = !this.sicQueueSelected;
    this.refresh();
  }
}
