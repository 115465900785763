import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DriversService } from '../../services/drivers.service';

@Component({
  selector: 'app-driver-shift-filter',
  templateUrl: './shift-filter.component.html',
  styleUrls: ['./shift-filter.component.scss'],
})
export class ShiftFilterComponent implements OnInit {
  label = 'SHIFT';
  shift$: Observable<string>;

  constructor(private driversService: DriversService) {}

  changeShift(code: string): void {
    this.driversService.shift = code;
  }

  ngOnInit(): void {
    this.shift$ = this.driversService.shift$.pipe(distinctUntilChanged());
  }
}
