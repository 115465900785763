import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import {
  GetLocationReferenceDetailsQuery,
  ListLocationFeaturesQuery,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { FeatureFlag } from '../../enums/feature-flags.enum';
import { FeatureFlagResponse } from '../../enums/feature-flags.enum copy';

@Injectable({
  providedIn: 'root',
})
export class SunsetGuard implements CanActivate {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private locationApiService: LocationApiService,
    private config: ConfigManagerService,
    private configManagerService: ConfigManagerService
  ) {}

  get fosUrl() {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.fosProd);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.loggedInUserService
      .getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        switchMap((user: User) =>
          this.checkLocationFeature().pipe(
            mergeMap((isFeatureEnabled) => (isFeatureEnabled ? this.getLocationReferenceDetails(user) : of(true)))
          )
        ),
        catchError(() => of(true))
      );
  }

  private getLocationReferenceDetails(user: User) {
    const query = new GetLocationReferenceDetailsQuery();
    query.sicCd = user.department;

    return this.locationApiService.getLocationReferenceDetails(query).pipe(
      take(1),
      map((res) => {
        // If user.department is a valid Service Center, the user can not use this app and is navigated to FOS
        if(typeof res === 'object' && res !== null && Object.keys(res).length > 0) {
          window.location.href = `${this.fosUrl}?sicCode=${user.department}`;
          return false;
        }
        return true
      }),
      catchError(() => of(true))
    );
  }

  private checkLocationFeature() {
    const query = new ListLocationFeaturesQuery();
    query.featureCd = FeatureFlag.LNH_OPS_SUNSET;

    return this.locationApiService.listLocationFeatures(query).pipe(
      take(1),
      map((res: any) => res?.locationFeatures?.[0]?.settingValue?.trim()?.toUpperCase() === FeatureFlagResponse.Yes),
      catchError(() => of(false))
    );
  }
}
