import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { XpoLtlShipmentDetailsDialogComponent, XpoLtlShipmentDetailsModule } from '@xpo-ltl/ngx-ltl';
import { XpoBoardModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoShellModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { CarrierManagementApiService } from '@xpo-ltl/sdk-carriermanagement';
import { LinehaulOperationsApiService } from '@xpo-ltl/sdk-linehauloperations';
import { PricingApiService } from '@xpo-ltl/sdk-pricing';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { ClickOutsideDirective } from '../utils/click-outside.directive';
import { AddDriverRendererComponent } from './components/add-driver-renderer/add-driver-renderer.component';
import { BufiAlertTableComponent } from './components/bufi-alert-table/bufi-alert-table.component';
import { DriverIconRendererComponent } from './components/driver-icon-renderer/driver-icon-renderer.component';
import { DriverNameRendererComponent } from './components/driver-name-renderer/driver-name-renderer.component';
import { DriversDetailsComponent } from './components/drivers-details/drivers-details.component';
import { GMisloadTableComponent } from './components/g-misload-table/g-misload-table.component';
import { GridActionIconComponent } from './components/grid-action-icon/grid-action-icon.component';
// tslint:disable-next-line:max-line-length
import { HssTableHeaderComponent } from './components/hss-underweight-table/hss-table-header/hss-table-header.component';
import { HssUnderweightTableComponent } from './components/hss-underweight-table/hss-underweight-table.component';
import { LoadRequestDetails } from './components/load-request-panel/components/load-request-details/load-request-details.component';
import { LoadRequestPanelTable } from './components/load-request-panel/components/load-request-panel-table/load-request-panel-table.component';
import { LoadRequestPanel } from './components/load-request-panel/load-request-panel.component';
import { LoadRequestTableComponent } from './components/load-request-table/load-request-table.component';
import { MeterComponent } from './components/meter/meter.component';
import { PlanDateFilterComponent } from './components/plan-date-filter/plan-date-filter.component';
import { ProgressiveLoadTableComponent } from './components/progressive-load-table/progressive-load-table.component';
import { RegionFilterComponent } from './components/region-filter/region-filter.component';
import { ShellBannerComponent } from './components/shell-banner';
import { ShiftFilterComponent } from './components/shift-filter/shift-filter.component';
import { SicFilterComponent } from './components/sic-filter/sic-filter.component';
import { SicFilterService } from './components/sic-filter/sic-filter.service';
import { SidePanelContainerModule } from './components/side-panel-container/side-panel-container.module';
import { SnackbarPermissionMessageComponent } from './components/snackbar-permission-message/snackbar-permission-message.component';
import { SpecialServiceGroupModule } from './components/special-service-group/special-service-group.module';
import { SpecialServiceIconModule } from './components/special-service-icon/special-service-icon.module';
import { TablesWrapperComponent } from './components/tables-wrapper/tables-wrapper.component';
import { AddLoadFormatter } from './formatters/ag-grid-cell-formatters/add-load-formatter';
import { AutocompleteDriverComponent } from './formatters/ag-grid-cell-formatters/auto-complete-driver.component';
import { BypassFormatterComponent } from './formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { ClosedReasonFormatterComponent } from './formatters/ag-grid-cell-formatters/closed-reason-formatter';
import { CloseToFormatterComponent } from './formatters/ag-grid-cell-formatters/closeto-formatter';
import { DispatchHeaderFormatterComponent } from './formatters/ag-grid-cell-formatters/dispatch-header-formatter';
import { ExceptionFormatterComponent } from './formatters/ag-grid-cell-formatters/exception-formatter';
import { HSSWeightFormatterComponent } from './formatters/ag-grid-cell-formatters/hss-weight-formatter';
// tslint:disable-next-line:max-line-length
import { IconsFormatterComponent } from './formatters/ag-grid-cell-formatters/icons-formatter';
// tslint:disable-next-line:max-line-length
import { LoadRequestStatusFormatterComponent } from './formatters/ag-grid-cell-formatters/load-request-status-formatter';
import { LoadedWeightFormatterComponent } from './formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { ModifiedByFormatterComponent } from './formatters/ag-grid-cell-formatters/modified-by-formatter';
import { MoveToFormatterComponent } from './formatters/ag-grid-cell-formatters/move-to-formatter';
import { PomaTrailerFormatterComponent } from './formatters/ag-grid-cell-formatters/poma-trailer-formatter';
import { RemainingValueFormatterComponent } from './formatters/ag-grid-cell-formatters/remaining-value-formatter';
import { RequesterCommentFormatterComponent } from './formatters/ag-grid-cell-formatters/requester-comment-formatter';
import { SpecialServiceFormatterComponent } from './formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusChangeFormatterComponent } from './formatters/ag-grid-cell-formatters/status-chage-formatter';
import { StatusFormatterComponent } from './formatters/ag-grid-cell-formatters/status-formatter';
import { DateFormatterComponent } from './formatters/date-formatter';
import { PipesModule } from './formatters/pipes.module';
import { ProNbrFormatPipe } from './formatters/proNbr-pipe';
import { SafePipe } from './formatters/safe-pipe.pipe';

const agGridFormatters = [
  IconsFormatterComponent,
  SpecialServiceFormatterComponent,
  StatusFormatterComponent,
  ModifiedByFormatterComponent,
  RemainingValueFormatterComponent,
  BypassFormatterComponent,
  StatusChangeFormatterComponent,
  DateFormatterComponent,
  LoadedWeightFormatterComponent,
  LoadRequestStatusFormatterComponent,
  ExceptionFormatterComponent,
  CloseToFormatterComponent,
  MoveToFormatterComponent,
  AddLoadFormatter,
  HSSWeightFormatterComponent,
  RequesterCommentFormatterComponent,
  ClosedReasonFormatterComponent,
  HssTableHeaderComponent,
  DispatchHeaderFormatterComponent,
  PomaTrailerFormatterComponent,
  RequesterCommentFormatterComponent,
];

@NgModule({
  declarations: [
    SicFilterComponent,
    PlanDateFilterComponent,
    RegionFilterComponent,
    ShiftFilterComponent,
    ProNbrFormatPipe,
    HssUnderweightTableComponent,
    GMisloadTableComponent,
    LoadRequestTableComponent,
    ProgressiveLoadTableComponent,
    TablesWrapperComponent,
    LoadRequestPanel,
    LoadRequestPanelTable,
    LoadRequestDetails,
    MeterComponent,
    ...agGridFormatters,
    ClickOutsideDirective,
    HssTableHeaderComponent,
    DriversDetailsComponent,
    AddDriverRendererComponent,
    DriverNameRendererComponent,
    DriverIconRendererComponent,
    BufiAlertTableComponent,
    SafePipe,
    GridActionIconComponent,
    AutocompleteDriverComponent,
    SnackbarPermissionMessageComponent,
    ShellBannerComponent,
  ],
  imports: [
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoTabsModule,
    XpoPopoverModule,
    XpoChipsModule,
    XpoFiltersModule,
    CommonModule,
    FormsModule,
    PipesModule,
    XpoLtlPipeModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    NgxGalleryModule,
    MatTableModule,
    XpoPopoverModule,
    XpoIconModule,
    MatTooltipModule,
    MatSelectModule,
    XpoButtonModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    AgGridModule.withComponents([...agGridFormatters]),
    SidePanelContainerModule,
    MatProgressSpinnerModule,
    XpoLtlShipmentDetailsModule,
    MatSlideToggleModule,
    MatCardModule,
    XpoChipsModule,
    XpoIconModule,
    SpecialServiceIconModule,
    SpecialServiceGroupModule,
  ],
  exports: [
    SicFilterComponent,
    ShiftFilterComponent,
    RegionFilterComponent,
    PlanDateFilterComponent,
    GridActionIconComponent,
    ProNbrFormatPipe,
    HssUnderweightTableComponent,
    GMisloadTableComponent,
    LoadRequestTableComponent,
    ProgressiveLoadTableComponent,
    TablesWrapperComponent,
    LoadRequestPanel,
    LoadRequestPanelTable,
    LoadRequestDetails,
    MeterComponent,
    ClickOutsideDirective,
    DriversDetailsComponent,
    PipesModule,
    SafePipe,
    SnackbarPermissionMessageComponent,
    ShellBannerComponent,
  ],
  providers: [
    SicFilterService,
    LinehaulOperationsApiService,
    ClickOutsideDirective,
    ShipmentOdsApiService,
    ShipmentApiService,
    PricingApiService,
    CarrierManagementApiService,
  ],
  entryComponents: [XpoLtlShipmentDetailsDialogComponent],
})
export class SharedModule {}
