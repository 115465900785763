import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { SidePanelModelData } from '../../../side-panel-model-data';
import { AuthService } from './../../../../services/auth.service';

@Component({
  selector: 'lr-panel',
  templateUrl: './lr-panel.component.html',
  styleUrls: ['./lr-panel.component.scss'],
})
export class LrPanelComponent implements OnInit, OnChanges {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() modelData: SidePanelModelData;

  editedReason = null;
  canApprove = false;
  headerCount = null;
  showReason: boolean = false;
  statusNotNew: boolean = false;
  searching: boolean = true;
  satellite: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    if (this.authService.isAllowedToApprove()) {
      this.canApprove = true;
      if (this.status === ExceptionStatusCd.RESPONDED) {
        this.statusNotNew = true;
      }
      if (this.status === ExceptionStatusCd.NEW) {
        this.showReason = true;
      }
    }

    if (!this.modelData) {
      this.searching = false;
      this.satellite = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modelData) {
      this.setHeaderCount();
      this.searching = this.modelData.searching;
    }
  }

  editReason(reasonCd: string) {
    this.editedReason = reasonCd;
  }

  canCancelRequest() {
    return this.authService.isAllowedToCancelLR() && this.status === ExceptionStatusCd.NEW;
  }

  canCloseRequest() {
    return (
      this.authService.isAllowedToCloseLr() &&
      (this.status === ExceptionStatusCd.APPROVED || this.status === ExceptionStatusCd.DECLINED)
    );
  }

  private setHeaderCount() {
    if (!this.modelData.topTable) {
      return;
    }

    this.headerCount = {
      bypassLoadCount: this.modelData.topTable.bypassLoadCount || 'NONE',
      plannedEmpties:
        this.modelData.topTable.planned && this.modelData.topTable.planned.empties
          ? this.modelData.topTable.planned.empties
          : 'NONE',
      remainingCube:
        this.modelData.topTable.remaining && this.modelData.topTable.remaining.cube
          ? this.modelData.topTable.remaining.cube
          : 'NONE',
      specialServices: this.modelData.topTable.indicators,
    };
  }
}
