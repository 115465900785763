import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlSerializer, UrlTree } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { Attachment, User } from '@xpo-ltl/sdk-common';
import {
  DocumentManagementApiService,
  GetArchivedDocumentPath,
  GetArchivedDocumentQuery,
  RetrieveDmsAuthTokenRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { LoadedTrailerSearchRecord } from '@xpo-ltl/sdk-linehauloperations';
import * as moment from 'moment-timezone';
import { NgxGalleryImage } from 'ngx-gallery-9';
import { BehaviorSubject, Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { SidePanelConstants } from '../side-panel-container-constants.component';
import { ConfigManagerPropertiesEnum } from './enums/config-manager-properties.enum';
import { EnumUtil } from './enums/enum-utils';
import { EmailConfig } from './utils/send-email/email-config';
import { SendEmailComponent } from './utils/send-email/send-email.component';
import { ZipUtils } from './utils/zip-utils';

@Injectable({ providedIn: 'root' })
export class ImageGalleryService implements OnDestroy {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private router: Router,
    private serializer: UrlSerializer,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private dmsService: DocumentManagementApiService,
    private sidePanelConstants: SidePanelConstants,
    private timeService: XpoLtlTimeService
  ) {}
  zoomed: boolean = false;
  trailerData: LoadedTrailerSearchRecord;
  loadingDMSImages: boolean = false;
  selectedIndex = new BehaviorSubject<number>(0);
  showGallery: boolean = false;
  selectedImage: any;
  sicCdTimeZone: any;
  sidePanelStatus$: EventEmitter<any> = new EventEmitter();
  loginSubscription: Subscription;

  readonly dataUriRegex = new RegExp('data:image/(jpeg|png|tiff);base64');

  subscribeToIndex() {
    return this.selectedIndex.asObservable();
  }

  setIndex(index) {
    this.selectedIndex.next(index);
  }

  getRotate(hiddenImg) {
    const rotateStyle = getComputedStyle(hiddenImg);
    switch (rotateStyle.transform) {
      default:
      case 'none':
      case 'matrix(1, 2.44929e-16, -2.44929e-16, 1, 0, 0)':
      case 'matrix(1, -2.44929e-16, 2.44929e-16, 1, 0, 0)':
        return 0;
        break;
      case 'matrix(6.12323e-17, 1, -1, 6.12323e-17, 0, 0)':
        return 90;
        break;
      case 'matrix(-1, 1.22465e-16, -1.22465e-16, -1, 0, 0)':
        return 180;
        break;
      case 'matrix(-1.83697e-16, -1, 1, -1.83697e-16, 0, 0)':
        return 270;
        break;
      case 'matrix(6.12323e-17, -1, 1, 6.12323e-17, 0, 0)':
        return -90;
        break;
      case 'matrix(-1, -1.22465e-16, 1.22465e-16, -1, 0, 0)':
        return -180;
        break;
      case 'matrix(-1.83697e-16, 1, -1, -1.83697e-16, 0, 0)':
        return -270;
        break;
    }
  }

  getCurrentImgRef(galleryElement, indx) {
    const imgIndex = indx ? 1 : 0;
    return galleryElement.querySelectorAll('.ngx-gallery-image')[imgIndex];
  }

  getHiddenWidth(hiddenDiv) {
    const hiddenStyle = getComputedStyle(hiddenDiv);
    return parseInt(hiddenStyle.width.substr(0, hiddenStyle.width.length - 2), 10);
  }

  getBackground(galleryEl, renderer, hiddenDiv, indx) {
    const style = getComputedStyle(this.getCurrentImgRef(galleryEl, indx));
    const backStyle = style.backgroundImage.substr(0, style.backgroundImage.length - 2).substr(5);
    renderer.setStyle(hiddenDiv, 'display', 'block');
    renderer.setStyle(this.getCurrentImgRef(galleryEl, indx), 'background-size', '0');
    return backStyle;
  }

  zoomImg(renderer, hiddenImg, galleryElement, hiddenDiv, expand, indx) {
    if (this.zoomed === false) {
      this.zoomed = true;
      renderer.setAttribute(hiddenImg, 'src', this.getBackground(galleryElement, renderer, hiddenDiv, indx));
      if (expand === 'in') {
        renderer.setStyle(hiddenDiv, 'width', '500px');
      } else {
        renderer.setStyle(hiddenDiv, 'width', '365px');
      }
    } else {
      const hiddenStyleWidth =
        expand === 'in' ? this.getHiddenWidth(hiddenDiv) + 100 : this.getHiddenWidth(hiddenDiv) - 100;
      renderer.setStyle(hiddenDiv, 'width', hiddenStyleWidth + 'px');
    }
  }

  resetZoom() {
    this.zoomed = false;
  }

  /**
   * Generate image file from base64.
   * @param url
   * @param filename
   * @param mimeType
   */

  downloadAll(galleryImages) {
    const _this = this;
    const imagesFiles = [];
    galleryImages.forEach(function(image, index) {
      _this.base64toFile(image['big'], `${index}.jpg`, 'text/plain').then(function(file) {
        imagesFiles.push(file);
        if (imagesFiles.length === galleryImages.length) {
          ZipUtils.downloadFiles(imagesFiles);
        }
      });
    });
  }

  private base64toFile(url: any, filename: string, mimeType: string) {
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  downloadThis(selectedImage, mode) {
    if (mode === 'preview') {
      this.base64toFile(selectedImage['image']['big'], `${selectedImage['index']}.jpg`, 'text/plain').then(function(
        file
      ) {
        const imageFile = [file];
        ZipUtils.downloadFiles(imageFile);
      });
    } else {
      this.base64toFile(selectedImage['image'], `${selectedImage['index']}.jpg`, 'text/plain').then(function(file) {
        const imageFile = [file];
        ZipUtils.downloadFiles(imageFile);
      });
    }
  }

  handleEmail(trailerData, indx) {
    const emailConfig = new EmailConfig();
    this.loginSubscription = this.loggedInUserService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerPropertiesEnum.loggedInUserRoot))
      .subscribe((user: User) => {
        if (user) {
          emailConfig.hasFromEmailInput = true;
          emailConfig.disableFromEmailInput = true;
          emailConfig.fromEmail = user.emailAddress;
          emailConfig.hasToEmailInput = true;
          emailConfig.disableToEmailInput = false;
          emailConfig.toEmail = '';
          emailConfig.trailerNbr = trailerData.trailerNbr;

          const trailerSearchUrl: UrlTree = this.router.createUrlTree([], {
            queryParams: {
              searchKey: trailerData.trailerNbr,
              beginDate: moment(trailerData.loadingDate).format('MM/DD/YYYY'),
              endDate: moment(trailerData.loadingDate).format('MM/DD/YYYY'),
            },
          });
          emailConfig.trailerLink = this.document.location.origin + this.serializer.serialize(trailerSearchUrl);

          const imageType = trailerData.images[indx].imageType;
          emailConfig.subject = `${EnumUtil.getPhotoType(imageType)} for Trailer # ${trailerData.trailerNbr}`;

          const imgAttachment = new Attachment();
          // tslint:disable-next-line: max-line-length
          imgAttachment.fileName = `${trailerData.trailerNbr}-${trailerData.loadingSic}-${indx}.jpg`;
          imgAttachment.contentType = 'text/plain';
          const emailImg = trailerData.images[indx].thumbnail.replace(this.dataUriRegex, '');
          imgAttachment.base64Data = 'data:image/jpeg;base64,' + emailImg.replace(/[\r\n]/g, '');
          emailConfig.attachment = imgAttachment;

          this.dialog.open(SendEmailComponent, { data: emailConfig });
        }
      });
  }

  loadImagesfromDMS(dmsIds, dmsIdImageTypes, galleryImages, indx, trailerData, sicCdTimeZone) {
    const req = new RetrieveDmsAuthTokenRqst();
    return this.dmsService
      .retrieveDmsAuthToken(req)
      .toPromise()
      .then((resp) => {
        if (resp) {
          const token = JSON.parse(JSON.stringify(resp)).access_token;
          const path = new GetArchivedDocumentPath();
          const query = new GetArchivedDocumentQuery();
          path.corpCode = this.configManagerService.getSetting<string>(ConfigManagerPropertiesEnum.dmsCorpCode);
          path.docClass = 'TPFT';
          const httpParams = { headers: { dmsAuth: token } };
          const dmsUrls = dmsIds;
          if (dmsUrls) {
            dmsUrls.forEach((url, index) => {
              path.docClass = dmsIdImageTypes[index];
              const tmst = url.replace(/"/g, '');
              path.docArchiveTimestamp = tmst as any;
              this.dmsService
                .getArchivedDocument(path, query, {}, httpParams)
                .pipe(timeout(15000))
                .subscribe(
                  (img) => {
                    const obj = JSON.parse(JSON.stringify(img));
                    const localImage = obj.document.binary.replace(this.dataUriRegex, '');
                    const decodedImg = 'data:image/jpeg;base64,' + localImage.replace(/[\r\n]/g, '');
                    const decodedImgGallery: NgxGalleryImage = {
                      description: this.timeService.formatDate(
                        trailerData.images[index].imageTakenTimestamp,
                        'HH:mm, MM/DD/YYYY',
                        trailerData.loadingSic
                      ),
                      small: decodedImg,
                      medium: decodedImg,
                      big: decodedImg,
                      label: EnumUtil.getPhotoType(trailerData.images[index].imageType),
                    };
                    galleryImages.splice(index, 0, decodedImgGallery);
                  },
                  () => {
                    const notAvailable = this.sidePanelConstants.not_available_image;
                    const notAvailableImgGallery = {
                      description: this.datePipe.transform(
                        trailerData.images[index].imageTakenTimestamp,
                        'HH:mm, MM/dd/yyyy',
                        sicCdTimeZone
                      ),
                      small: notAvailable,
                      medium: notAvailable,
                      big: notAvailable,
                    };
                    galleryImages.splice(index, 0, notAvailableImgGallery);
                  }
                );
            });
            // This should return a different value to prevent images being changed one at a time
            return galleryImages;
          }
        }
      });
  }

  emitSidePanelStatus(status: boolean) {
    this.sidePanelStatus$.emit(status);
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
  }
}
