import { Injectable } from '@angular/core';
import { ActionCd } from '@xpo-ltl/sdk-common';
import { LaneDataType } from '../models/lane-data-type';

@Injectable()
export class LaneDataTypesService {
  getNoDataStructure(): LaneDataType {
    return {
      filterSic: '',
      selectedLane: '',
      sidePanelId: '',
      iframeUrl: '',
      originSic: '',
      lane: '',
      closeToSicCd: '',
      load: '',
      equipmentInstId: 0,
      guaranteedInd: false,
      hazmatInd: false,
      frzblInd: false,
      ptlTrlrInd: false,
      bypassInd: false,
      hssLoadCount: 0,
      bypassLoadCount: 0,
      loadedWeight: 0,
      loadedCube: 0,
      exception: false,
      sourceCd: '',
      statusCd: '',
      headLoadInd: false,
      headLoadDetail: {
        destinationSic: '',
        headLoadedLbs: 0,
        lseOrSkdCd: '',
        cbePercentage: 0,
        cbeProfitability: '',
        nbrOfFeet: 0,
        lastUpdateDeviceId: '',
        equipmentId: 0,
        correlationId: '',
        equipmentEventId: 0,
        auditInfo: {
          createdById: '',
          createdTimestamp: new Date(),
          updateById: '',
          updatedTimestamp: new Date(),
          createByPgmId: '',
          updateByPgmId: '',
          correlationId: '',
          createdApplicationId: '',
          lastUpdateApplicationId: '',
        },
      },
      loadedShipment: [
        {
          equipmentId: 0,
          sequenceNbr: 0,
          equipmentIdPrefix: '',
          equipmentIdSuffixNbr: 0,
          proNbr: '',
          dckLrgMarks: '',
          lastUpdateDeviceId: '',
          pickupRequestInstId: 0,
          pickupRequestSequenceNbr: 0,
          headLoadInd: false,
          trailerSectn1Ind: false,
          trailerSectn2Ind: false,
          trailerSectn3Ind: false,
          trailerSectn4Ind: false,
          trailerSectn5Ind: false,
          trailerSectn6Ind: false,
          billDestinationSic: '',
          misloadInd: false,
          auditInfo: {
            createdById: '',
            createdTimestamp: new Date(),
            updateById: '',
            updatedTimestamp: new Date(),
            createByPgmId: '',
            updateByPgmId: '',
            correlationId: '',
            createdApplicationId: '',
            lastUpdateApplicationId: '',
          },
          listActionCd: ActionCd.NO_ACTION,
        },
      ],
      misloadInd: false,
      eventDoor: '',
      hasNoChildrens: true,
    };
  }
}
