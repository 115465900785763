export interface FeedbackConfigInterface {
  disableFromNameInput?: boolean;
  disableSubjectInput?: boolean;
  fromEmail?: string;
  fromName?: string;
  invalidCharacterPattern?: string;
  message?: string;
  subject?: string;
  title?: string;
  toEmail?: string;
}
export class FeedbackConfig implements FeedbackConfigInterface {
  disableFromNameInput?: boolean;
  disableSubjectInput?: boolean;
  fromEmail?: string;
  fromName?: string;
  invalidCharacterPattern?: string;
  message?: string;
  subject?: string;
  title?: string;
  toEmail?: string;
}
