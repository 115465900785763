import { Injectable, Type } from '@angular/core';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import {
  Exception,
  ExceptionManagementApiService,
  ListLinehaulExceptionsQuery,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { XrtInMemoryService, XrtInMemorySource, XrtResponseCaching } from '@xpo/ngx-xrt';
import { map } from 'rxjs/operators';

export interface ExceptionGridModel {
  exceptionInstId?: number;
  shiftCd?: string;
  value?: string;
  createdTimestamp?: Date | number | string;
  loggedSic?: string;
  linehaulRegion?: string;
  operationalRegion: string;
  district?: string;
  destinationSicCd?: string;
  proNbr?: string;
  doorNbr?: string;
  trailerNbr?: string;
  employeeName?: string;
  controlNbr?: string;
  statusCd?: ExceptionStatusCd;
  resolution?: string;
  moveToSicCd?: string;
  loadRequestedQuantity?: number;
  loadToSicCd?: string;
  actualValue?: string;
  loadApprovedQuantity?: number | string;
  loadRejectedQuantity?: number | string;
  exceptionRaw: Exception;
}

@Injectable()
export class AlertGridXrtInMemoryService extends XrtInMemoryService {
  cachedData: XrtResponseCaching<ExceptionGridModel>;

  constructor(private exceptionManagementApiService: ExceptionManagementApiService) {
    super(
      new Map<Type<any>, XrtInMemorySource>([
        [
          null,
          (query: ListLinehaulExceptionsQuery) => {
            return this.cachedData.cachedResponse$(query, { loadingOverlayEnabled: false });
          },
        ],
      ])
    );

    this.cachedData = new XrtResponseCaching((query: ListLinehaulExceptionsQuery) =>
      this.exceptionManagementApiService
        .listLinehaulExceptions(query)
        .pipe(map((v) => this.mapToGridModel(v.exceptions)))
    );
  }

  private mapToGridModel(data: Exception[]): ExceptionGridModel[] {
    return data.map((exception) => {
      const { operationException } = exception;
      const { auditInfo } = operationException;

      return <ExceptionGridModel>{
        exceptionInstId: exception.exceptionInstId,
        createdTimestamp: auditInfo.createdTimestamp,
        loggedSic: operationException.loggedSic,
        destinationSicCd: operationException.destinationSicCode,
        shiftCd: operationException.shiftCd,
        value: operationException.value,
        proNbr: operationException.proNbr,
        doorNbr: operationException.doorNbr,
        trailerNbr: operationException.trailerNbr,
        employeeName: operationException.employeeName,
        controlNbr: operationException.controlNumber,
        operationalRegion: operationException.operationalRegion,
        statusCd: exception.statusCd,
        resolution: exception.resolution,
        moveToSicCd: operationException.moveToSicCode,
        loadRequestedQuantity: operationException.loadRequestedQuantity,
        loadToSicCd: operationException.loadToSicCode,
        actualValue: operationException.actualValue,
        loadApprovedQuantity: operationException.loadApprovedQuantity,
        loadRejectedQuantity: operationException.loadRejectedQuantity,
        district: operationException.district,
        linehaulRegion: operationException.linehaulRegion,
        exceptionRaw: exception,
      };
    });
  }
}
