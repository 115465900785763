<mat-form-field>
  <mat-label>HIERARCHY</mat-label>

  <mat-select #hierarchySelect
      [disableOptionCentering]="true"
      [ngModel]="hierarchy"
      (ngModelChange)="handleHierarchyChange($event)">
    <mat-option *ngFor="let hierarchy of hierarchyOptions"
        [value]="hierarchy">
      {{ locationLabel[hierarchy] }}
    </mat-option>
  </mat-select>
</mat-form-field>
