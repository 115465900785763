import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../shared/shared.module';
import { FiltersComponent } from './filters.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [FiltersComponent],
  imports: [CommonModule, ReactiveFormsModule, MaterialModule, MatDatepickerModule, SharedModule],
  exports: [FiltersComponent],
})
export class FiltersModule {}
