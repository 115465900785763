import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LinehaulFormatters } from '../../../shared/formatters/formatters';
import {
  SchedulesGridItemDetailTrailer,
  SchedulesGridItemDetailTrailerCurrentStatus,
} from '../../schedules-grid.model';

@Component({
  selector: 'app-trailer-cell-renderer',
  templateUrl: './trailer-cell-renderer.component.html',
  styleUrls: ['./trailer-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrailerCellRendererComponent implements ICellRendererAngularComp {
  name: string;
  cuLoaded: number;
  currentStatus: { text: string; color: string };
  indicators: Array<{ icon: string } | string>;
  loadedWeight: number;
  params: ICellRendererParams;
  data: SchedulesGridItemDetailTrailer;

  private indicatorToIconMap = {
    G: { icon: 'ss_guaranteed' },
    HM: { icon: 'ss_hazmat' },
    FRZ: { icon: 'ss_freezable' },
    EMTY: { icon: 'ss_empty' },
    HL: { icon: 'ss_headload' },
  };

  private statusMap: Record<SchedulesGridItemDetailTrailerCurrentStatus, { color: string; text: string }> = {
    CLOS: { color: 'green', text: 'Closed' },
    LDDK: { color: 'blue', text: 'Loading' },
    OVHD: { color: 'green', text: 'Bypass' },
  };

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.params = params;
    this.data = this.params.value ?? {};
    this.name = LinehaulFormatters.EquipmentName(this.data?.name);
    this.cuLoaded = this.data.loadedCubePct;
    this.loadedWeight = this.data.loadedWgtLbs;
    this.indicators = this.data.indicators?.map((ind) => this.indicatorToIconMap[ind] ?? ind);
    this.currentStatus = this.data.currentStatus
      ? this.statusMap[this.data.currentStatus] ?? { text: this.data.currentStatus, color: 'grey' }
      : null;
  }
}
