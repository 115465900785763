<div class="filter-wrap">
  <label for="planDate">Period</label>
  <input data-test="planDateFilter"
      [max]="maxDate"
      matInput
      [matDatepicker]="picker"
      placeholder=""
      (dateChange)="valueChanged($event)"
      [(ngModel)]="planDate" />
  <mat-icon (click)="picker.open()">calendar_today</mat-icon>
  <mat-datepicker #picker></mat-datepicker>
</div>
