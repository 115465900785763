import { AfterViewInit, Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';

@Component({
  selector: 'app-time-editor',
  templateUrl: 'time-editor.component.html',
})
export class TimeEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  params: ICellEditorParams;
  inputFormControl = new FormControl();

  private incomingValue: string;

  @ViewChild('input', { read: ViewContainerRef }) input: ViewContainerRef;

  ngAfterViewInit() {
    setTimeout(() => this.input.element.nativeElement.focus(), 0);
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;

    this.incomingValue = this.params.value;
    this.inputFormControl.setValue(this.incomingValue);
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isCancelAfterEnd(): boolean {
    // Ignore sending up value if no value is entered when blank, or existing value did not change
    return (
      (!this.inputFormControl.value && !this.incomingValue) ||
      (this.incomingValue && this.incomingValue === this.inputFormControl.value)
    );
  }

  getValue(): string {
    return this.inputFormControl.value;
  }
}
