import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

export function proNumberFormatter(value: string): string {
  const eraseFirstCero = value ? value.replace(/^0/, '') : '';
  const end = eraseFirstCero.slice(3);

  // proNbr format is XXX-YYYYYY
  const proNbrWithSpace = !!value ? `${eraseFirstCero.slice(0, 3)}-${end.substring(end.length - 6)}` : '';
  return proNbrWithSpace;
}

@Pipe({ name: 'proNbrFormat' })
export class ProNbrFormatPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return proNumberFormatter(value);
  }
}
