import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import {
  Unsubscriber,
  XpoLtlAppSwitcherService,
  XpoLtlFeedbackService,
  XpoLtlLoggedInUserService,
  XpoLtlReleaseNotesService,
} from '@xpo-ltl/ngx-ltl';
import {
  XpoAccountPopoverConfig,
  XpoAppSwitcherApplication,
  XpoFeedback,
  XpoNotificationTemplate,
  XpoShellRoute,
  XpoSnackBar,
} from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { LinehaulOperationsApiService, ListLoadRequestReasonCodesResp } from '@xpo-ltl/sdk-linehauloperations';
import { XpoAuthenticationService } from '@xpo/ngx-auth';
import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';
import { interval, Observable, of, zip } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  skipWhile,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { IconsKeys } from './shared/enums/icons.enum';
import { DevEnvGuard } from './shared/guards/development-only/development-only.guard';
import { AlertsMessagingService } from './shared/services/alerts-messaging.service';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader/dynamic-script-loader.service';
import { InteractionService } from './shared/services/interaction.service';
import { TimeUtilsService } from './shared/services/time-utils.service';
import { UserRoleService } from './shared/services/user-role/user-role.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-Root' },
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private xpoAuthService: XpoAuthenticationService,
    private configManagerService: ConfigManagerService,
    public feedbackService: XpoLtlFeedbackService,
    private appSwitcherService: XpoLtlAppSwitcherService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    public loggedInUserService: XpoLtlLoggedInUserService,
    private dialog: MatDialog,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private userRoleService: UserRoleService,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private interactionService: InteractionService,
    private timeUtilsService: TimeUtilsService,
    private router: Router,
    private location: Location,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    private developmentOnlyService: DevEnvGuard,
    private xpoLtlAuthService: XpoLtlAuthenticationService,
    private xpoSnackBar: XpoSnackBar,
    private alertsMessagingService: AlertsMessagingService
  ) {
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.xpoLtlAuthService
      .initAuthSetup$(this.region)
      .pipe(take(1))
      .subscribe();
    this.loggedInUserFunc();
    this.loggedInUserService.userLoggedIn$.subscribe(() => {
      console.log('loggedInUserService.userLoggedIn$ emitted event');
    });
    this.setIconsRegistry();
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.trainingResources = configManagerService.getSetting<string>(ConfigManagerProperties.trainingResourcesURL);
    this.releaseNotes = configManagerService.getSetting<string>(ConfigManagerProperties.releaseNotesURL);
  }

  region: string = '';

  private unsubscriber = new Unsubscriber();
  build: string;
  trainingResources: string;
  releaseNotes: string;
  loginSuccessful: boolean = false;
  apps$: Observable<XpoAppSwitcherApplication[]>;
  routes: XpoShellRoute[];
  pomaRoutes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  user: User;
  selectedTab: number = 0;
  selectedTab$: Observable<number>;
  routeSelected: string;
  AppRoutesEnum = AppRoutes;
  isAllowedToShowLinks: boolean;
  menuItems: { disabled: boolean; label: string; url: string }[] = [
    {
      disabled: false,
      label: 'Door Plan',
      url: AppRoutes.DOOR_PLAN,
    },
    {
      disabled: false,
      label: 'Lane Summary',
      url: AppRoutes.LOAD_LANE_SUMMARY,
    },
    {
      disabled: false,
      label: 'Linehaul Authorized Misloads',
      url: AppRoutes.DIVERSION_LANE,
    },
  ];

  navLinks = [
    { label: 'Dashboard', route: AppRoutes.PERFORMANCE_DASHBOARD },
    { label: 'Loads', route: AppRoutes.LOAD_MANAGEMENT },
    { label: 'User Metrics', route: AppRoutes.USER_METRICS },
    { label: 'Drivers', route: AppRoutes.DRIVERS },
  ];

  snackbarRef: MatSnackBarRef<XpoNotificationTemplate>;

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit(): void {
    this.getUrl();
    this.setLatestDate();
    this.selectedTab$ = this.interactionService.selectedTab$;
    this.interactionService.getRoute().subscribe((route) => {
      this.routeSelected = route as AppRoutes;
    });
    this.isAllowedToShowLinks = this.developmentOnlyService.canShowLinks();
    this.alertsMessagingService.elementsUpdated$
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((elementUpdated) => {
        if (elementUpdated && !this.snackbarRef) {
          this.snackbarRef = this.xpoSnackBar.open({
            message: 'Exceptions/Alerts Updated, click refresh to update.',
            status: 'info',
            matConfig: {
              duration: 0,
              panelClass: 'pushFeedSnackbar',
            },
            linkAction: {
              message: 'Refresh',
              function: () => {
                this.interactionService.refreshData(true);
                this.snackbarRef.dismiss();
              },
            },
          });

          this.snackbarRef
            .afterDismissed()
            .pipe(delay(120000), take(1))
            .subscribe(() => {
              this.snackbarRef = undefined;
            });
        }
      });
  }

  getUrl(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.router.url === '/performance-dashboard') {
          this.interactionService.setRoute(AppRoutes.PERFORMANCE_DASHBOARD);
          this.interactionService.setSelectedTab(0);
        } else if (this.router.url === '/load-management') {
          this.interactionService.setRoute(AppRoutes.LOAD_MANAGEMENT);
          this.interactionService.setSelectedTab(1);
        } else if (this.router.url === '/user-metrics') {
          this.interactionService.setRoute(AppRoutes.USER_METRICS);
          this.interactionService.setSelectedTab(3); // Changed from 5 to 3 when hiding Drivers and Schedules
        } else if (this.router.url === '/door-plan') {
          this.interactionService.setRoute(AppRoutes.DOOR_PLAN);
          this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
        } else if (this.router.url === '/load-lane-summary') {
          this.interactionService.setRoute(AppRoutes.LOAD_LANE_SUMMARY);
          this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
        } else if (this.router.url === '/diversion-lane') {
          this.interactionService.setRoute(AppRoutes.DIVERSION_LANE);
          this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
          /*         } else if (this.router.url === '/drivers') {
          this.interactionService.setRoute(AppRoutes.DRIVERS);
          this.interactionService.setSelectedTab(2);
        } else if (this.router.url === '/schedules') {
          this.interactionService.setRoute(AppRoutes.SCHEDULES);
          this.interactionService.setSelectedTab(3); */
        } else if (this.router.url === '/new-dashboard') {
          this.interactionService.setRoute(AppRoutes.NEW_DASHBOARD);
        } else if (this.router.url === '/loads-and-schedules') {
          this.interactionService.setRoute(AppRoutes.LOADS_AND_SCHEDULES);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private initializeDynatrace(user: User): void {
    // Based on region we need to load the proper dynatrace script and once it is
    // loaded attempt to set the current user through dtrum variable.
    const region = `${_toLower(this.configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;
    this.dynamicScriptLoader.load({ name: 'dynatrace', src: `./assets/dyna_${_toLower(region)}.js` }).subscribe(() => {
      const setIdentity = (u: User) => {
        _invoke(window['dtrum'], 'identifyUser', !_isEmpty(u.emailAddress) ? u.emailAddress : u.userId);
      };

      if (window['dtrum']) {
        setIdentity(user);
      } else {
        interval(100)
          .pipe(
            skipWhile(() => !window['dtrum']),
            take(1)
          )
          .subscribe(() => {
            setIdentity(user);
          });
      }
    });
  }

  changeUrl(route: string): void {
    const parts = window.location.pathname.split('/');
    parts[1] = route;
    this.location.replaceState(parts.join('/'));
  }

  routingClick(event): void {
    const tab = typeof event === 'object' ? event.srcElement.innerText : event;
    if (tab === 'Dashboard') {
      this.router.navigateByUrl(`/${AppRoutes.PERFORMANCE_DASHBOARD}`);
      this.changeUrl(AppRoutes.PERFORMANCE_DASHBOARD);
      this.interactionService.setRoute(AppRoutes.PERFORMANCE_DASHBOARD);
      this.interactionService.setSelectedTab(0);
    } else if (tab === 'Loads') {
      this.changeUrl(AppRoutes.LOAD_MANAGEMENT);
      this.interactionService.setRoute(AppRoutes.LOAD_MANAGEMENT);
      this.interactionService.setSelectedTab(1);
    } else if (tab === 'User Metrics') {
      this.changeUrl(AppRoutes.USER_METRICS);
      this.interactionService.setRoute(AppRoutes.USER_METRICS);
      this.interactionService.setSelectedTab(3); // Changed from 5 to 3 when hiding Drivers and Schedules
    } else if (tab === 'door-plan') {
      this.changeUrl(AppRoutes.DOOR_PLAN);
      this.interactionService.setRoute(AppRoutes.DOOR_PLAN);
      this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
    } else if (tab === 'load-lane-summary') {
      this.changeUrl(AppRoutes.LOAD_LANE_SUMMARY);
      this.interactionService.setRoute(AppRoutes.LOAD_LANE_SUMMARY);
      this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
    } else if (tab === 'diversion-lane') {
      this.changeUrl(AppRoutes.DIVERSION_LANE);
      this.interactionService.setRoute(AppRoutes.DIVERSION_LANE);
      this.interactionService.setSelectedTab(2); // Changed from 4 to 2 when hiding Drivers and Schedules
    } /*  else if (tab === 'Drivers') {
      this.changeUrl(AppRoutes.DRIVERS);
      this.interactionService.setRoute(AppRoutes.DRIVERS);
      this.interactionService.setSelectedTab(2);
      // this.router.navigateByUrl(`/${AppRoutes.DRIVERS}`);
    } else if (tab === 'Schedules') {
      this.changeUrl(AppRoutes.SCHEDULES);
      this.interactionService.setRoute(AppRoutes.SCHEDULES);
      this.interactionService.setSelectedTab(3);
    } */
  }

  private getLoadRequestReasonCodes(): void {
    this.linehaulOperationsApiService
      .listLoadRequestReasonCodes()
      .subscribe((listReasons: ListLoadRequestReasonCodesResp) => {
        this.interactionService.setLoadRequestReasonCodes(listReasons.loadRequestReasonCodes);
      });
  }

  handleFeedbackClick(): void {
    this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
      this.dialog.open(XpoFeedback, { data: config });
    });
  }

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => {});
  }

  private setLatestDate(): void {
    this.interactionService.setLatestRefreshDate(this.timeUtilsService.getCurrentDateTime());
  }

  private populateAppSwitcher(): void {
    this.apps$ = this.appSwitcherService.getAppList();
  }

  private setIconsRegistry(): void {
    Object.values(IconsKeys).forEach((icon) => {
      this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/images/${icon}.svg`));
    });
  }

  private populateAccountPopover(user: User): void {
    const fullName = `${user.givenName} ${user.lastName}`;

    this.accountPopoverConfig = {
      imageUri: AppComponent.getProfilePictureUrl(user.emailAddress),
      name: fullName,
      onSignOutCallback: (): void => {
        // this.signOut();
      },
      links: [
        { title: 'My Account', url: AccountUrls.myAccount },
        { title: 'Help', url: AccountUrls.help },
        { title: 'Privacy Policy', url: AccountUrls.privacyPolicy },
      ],
    };
  }

  private loggedInUserFunc(): void {
    // NEED TO WAIT UNTIL AUTH SERVICE HAS COMPLETED SSO LOGIN BEFORE ANY ADDITIONAL CALLS ARE MADE
    this.xpoAuthService
      .isLoggedIn$()
      .pipe(
        distinctUntilChanged(),
        delay(100),
        switchMap((loggedIn: boolean) => {
          return !loggedIn
            ? of(undefined)
            : this.loggedInUserService
                .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
                .pipe(
                  catchError((error) => {
                    console.log('loggedInUserFunc ERROR:', error);
                    return of(undefined);
                  })
                );
        }),
        takeUntil(this.unsubscriber.done)
      )
      .subscribe((user: User) => {
        if (user) {
          this.userRoleService.setUser(user);
          this.initializeDynatrace(user);
          this.populateAccountPopover(user);
          this.populateAppSwitcher();
          this.getLoadRequestReasonCodes();
          this.loginSuccessful = true;
        }
      });
  }
}
