<div class="al-component">
  <xpo-board [dataSource]="datasource"
      [viewTemplates]="viewTemplates"
      [views]="views"
      [boardOptions]="{ suppressViewSwitcher: true, suppressGridSettingsPopover: true }">
    <xpo-board-actions>
      <xpo-button-group>
        <app-allowable-loads-buttons [active]="AppRoutes.LOADS_VIEW"></app-allowable-loads-buttons>
      </xpo-button-group>
    </xpo-board-actions>
    <xpo-empty-state>
      <xpo-empty-state-header>
        <h3>No Results Found</h3>
      </xpo-empty-state-header>
    </xpo-empty-state>
    <xpo-ag-grid-board [customGridOptions]="gridOptions"
        rowModelType="client-side"
        rowSelection="single"
        enableMovableColumns="true">
    </xpo-ag-grid-board>
  </xpo-board>
</div>
