import { Component, Input, OnInit } from '@angular/core';
import { GetShipmentQuery, GetShipmentResp, ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { ExceptionStatusCd, ShipmentDetailCd, ShipmentId } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import { ListOdsShipmentHistTraceEventsRqst } from '@xpo-ltl/sdk-shipmentods';
import { ProNbrFormatPipe } from '../../../../formatters/proNbr-pipe';
import { AuthService } from '../../../../services/auth.service';
@Component({
  selector: 'shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
})
export class ShipmentDetailsComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  histReq: ListOdsShipmentHistTraceEventsRqst = new ListOdsShipmentHistTraceEventsRqst();
  showHistory: boolean = false;
  showShipment: boolean = false;
  statusCheck: boolean = false;
  proNbrPipe: ProNbrFormatPipe;
  showAck: boolean = false;
  shipmentData: any;

  constructor(private shipmentApiService: ShipmentApiService, private authService: AuthService) {}

  ngOnInit() {
    this.statusCheck = true;
    this.showAck = (this.isAllowedAck || this.isAllowedToFalsePositive) && this.checkStatus();
    this.proNbrPipe = new ProNbrFormatPipe();
    this.searchDetails(this.alert.alert);
  }

  get isAllowedAck(): boolean {
    return true;
  }

  get isAllowedToFalsePositive(): boolean {
    return this.authService.isAllowedToFalsePositive();
  }

  private checkStatus(): boolean {
    return (
      this.status !== ExceptionStatusCd.CLOSED &&
      this.status !== ExceptionStatusCd.RESOLVED &&
      this.status !== ExceptionStatusCd.EXPIRED
    );
  }

  private searchDetails(alert: Exception) {
    const getShipmentQuery = new GetShipmentQuery();

    getShipmentQuery.proNbr =
      '0' + alert.operationException.proNbr.substring(0, 3) + '0' + alert.operationException.proNbr.substring(3);

    getShipmentQuery.shipmentDetailCd = [
      ShipmentDetailCd.LINEHAUL_DIMENSIONS,
      ShipmentDetailCd.SHIPMENT_PARTIES,
      ShipmentDetailCd.COMMODITY,
    ];
    this.shipmentApiService.getShipment(getShipmentQuery).subscribe((data: GetShipmentResp) => {
      if (data) {
        this.shipmentData = data;
        // //Use when hist-trace 2.0 is ready
        // const shipId = new ShipmentId;
        // shipId.shipmentInstId = data.shipment.shipmentInstId.toString();
        // shipId.proNumber = data.shipment.proNbr;
        // shipId.pickupDate = new Date(data.shipment.pickupDate);
        // this.histReq.shipmentId = shipId;

        this.histReq.odsShipmentId = {
          shipmentInstId: data.shipment.shipmentInstId,
          proNbr: data.shipment.proNbr,
          pickupDate: `${data.shipment.pickupDate.valueOf()}`,
        };
        this.showHistory = true;
        this.showShipment = true;
      } else {
        this.showShipment = false;
      }
    });
  }
}
