import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DsrLicenseCd } from '@xpo-ltl/sdk-common';
import {
  SpecialServiceIconName,
  SpecialServiceIconStatus,
} from '../special-service-icon/special-service-icon.component';

export interface SpecialServiceGroupCredential {
  credName: SpecialServiceIconName;
  status: DsrLicenseCd;
}

@Component({
  selector: 'app-special-service-group',
  templateUrl: './special-service-group.component.html',
  styleUrls: ['./special-service-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialServiceGroupComponent {
  tooltipMap: Record<DsrLicenseCd, string> = {
    [DsrLicenseCd.EXPIRED]: 'Expired',
    [DsrLicenseCd.ACTIVE]: 'Active',
    [DsrLicenseCd.ACTIVE_BUT_EXPIRES_WITHIN_30_DAYS]: 'Expiring Soon',
  };
  statusMap: Record<DsrLicenseCd, SpecialServiceIconStatus> = {
    [DsrLicenseCd.ACTIVE]: 'success',
    [DsrLicenseCd.ACTIVE_BUT_EXPIRES_WITHIN_30_DAYS]: 'warning',
    [DsrLicenseCd.EXPIRED]: 'error',
  };

  @Input() credentials: SpecialServiceGroupCredential[];
}
