import { ConnectedPosition } from '@angular/cdk/overlay';
import { ViewportRuler } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { XpoAdvancedSelectComponentOption } from '@xpo-ltl/ngx-ltl-board';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'xpo-advanced-select',
  templateUrl: './advanced-select.component.html',
  styleUrls: ['./advanced-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => XpoAdvancedSelectComponent), multi: true }],
  host: { class: 'xpo-AdvancedSelect' },
})
export class XpoAdvancedSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  searchInputValue: string = '';

  disabled = false; // TODO

  /** Whether or not the overlay panel is open. */
  panelOpen = false;

  /**
   * This position config ensures that the top "start" corner of the overlay
   * is aligned with with the top "start" of the origin by default (overlapping
   * the trigger completely). If the panel cannot fit below the trigger, it
   * will fall back to a position above the trigger.
   */
  _positions: ConnectedPosition[] = [
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'top' },
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
  ];

  optionsInputWidth: number = 0;

  /** Emits whenever the component is destroyed. */
  private readonly destroy$ = new Subject<void>();

  @Input() options: XpoAdvancedSelectComponentOption[] = [];

  @Input() placeholder: string = 'Search';

  @Input() selectedOptions: string[] = [];

  @Output() selectedOptionsChange = new EventEmitter<string[]>();

  @ViewChild('optionsInput', { static: true }) optionsInput: ElementRef<HTMLInputElement>;

  constructor(private _viewportRuler: ViewportRuler, private cd: ChangeDetectorRef) {}

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when select has been touched` */
  _onTouched = () => {};

  ngOnInit(): void {
    this._viewportRuler
      .change()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.panelOpen) {
          this.updatePanelWidth();
          this.cd.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
    this.destroy$.next();
  }

  writeValue(obj: any): void {
    this.selectedOptions = obj;
  }
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  remove(option: string): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.emitChange();
      this.cd.markForCheck();
    }
  }

  handleSelectionChange(selection: string[]): void {
    this.selectedOptions = selection;
    this.optionsInput.nativeElement.focus();

    this.emitChange();
    this.cd.markForCheck();
  }

  /** Opens the overlay panel. */
  open(): void {
    if (this.disabled || !this.options?.length || this.panelOpen) {
      return;
    }

    this.updatePanelWidth();

    this.panelOpen = true;

    this.cd.markForCheck();
  }

  /** Closes the overlay panel and focuses the host element. */
  close(): void {
    if (this.panelOpen) {
      this.panelOpen = false;
      this.searchInputValue = '';
      this.optionsInput.nativeElement.value = '';
      this.cd.markForCheck();
      this._onTouched();
    }
  }

  /** Toggles the overlay panel open or closed. */
  toggle(): void {
    this.panelOpen ? this.close() : this.open();
  }

  private emitChange(): void {
    this.selectedOptionsChange.emit(this.selectedOptions);
    this._onChange(this.selectedOptions);
  }

  private updatePanelWidth(): void {
    this.optionsInputWidth = this.optionsInput.nativeElement.getBoundingClientRect().width;
  }
}
