import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-plan-date-filter',
  templateUrl: './plan-date-filter.component.html',
  styleUrls: ['./plan-date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDateFilterComponent {
  maxDate = new Date();

  @Input() planDate: Date = new Date();

  @Output() valueChange = new EventEmitter<Date>();

  valueChanged($event: MatDatepickerInputEvent<Date>): void {
    this.valueChange.emit($event.value);
  }
}
