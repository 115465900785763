import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { HierarchyRegion } from '../../performance-dashboard/models/hierarchy-region';

export interface LinehaulState {
  dashboardSelectedHierarchy: HierarchyRegion;
  selectedPeriod: Date;
  selectedLocations: string[];
  // selectedSic: any;
  // selectedShift: any;
}

@Injectable({ providedIn: 'root' })
export class LinehaulStateService {
  private static readonly InitialState: LinehaulState = {
    dashboardSelectedHierarchy: HierarchyRegion.OPERATIONAL_REGION,
    selectedPeriod: new Date(),
    selectedLocations: [],
    // selectedSic: null,
    // selectedShift: null,
  };
  private readonly linehaulStateSource = new BehaviorSubject<LinehaulState>(LinehaulStateService.InitialState);
  private readonly refreshSource = new Subject<void>();

  readonly linehaulState$ = this.linehaulStateSource.asObservable();
  readonly refresh$ = this.refreshSource.asObservable();

  get linehaulState(): LinehaulState {
    return this.linehaulStateSource.getValue();
  }

  setState(state: Partial<LinehaulState>): void {
    // TODO deep clone state
    this.linehaulStateSource.next(Object.assign(this.linehaulState, state));
  }

  listen<T extends keyof LinehaulState>(key: T): Observable<LinehaulState[T]> {
    return this.linehaulState$.pipe(
      map((state) => state[key]),
      distinctUntilChanged()
    );
  }

  resetState(): void {
    this.linehaulStateSource.next({ ...LinehaulStateService.InitialState });
  }

  refreshData(): void {
    this.refreshSource.next();
  }
}
