import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { LinehaulStateService } from '../../services/linehaul-state.service';

@Component({
  selector: 'app-period-filter',
  templateUrl: './period-filter.component.html',
  styleUrls: ['./period-filter.component.scss'],
  host: { class: 'app-PeriodFilter' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PeriodFilterComponent {
  maxDate = new Date();
  period: Date = new Date();

  constructor(private linehaulStateService: LinehaulStateService) {}

  handlePeriodChange(v: Date): void {
    this.period = v;
    this.linehaulStateService.setState({
      selectedPeriod: v,
    });
  }
}
