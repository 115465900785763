<div class="shipment-details-dimensions">
  <div class="shipment-details-dimensions__table">
    <table mat-table [dataSource]="dataSource">
      <ng-container *ngFor="let column of columns" [matColumnDef]="column.id">
        <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
        <td mat-cell *matCellDef="let row">
          <span>{{ row[column.id] }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
    </table>
  </div>
  <div class="shipment-details-dimensions__row" *ngIf="noDimensions === true">
    No data available
  </div>
</div>
