<div *ngIf="show" class="content">
  <h1>Approve/Decline Load</h1>
  <form [formGroup]="form">
    <div class="action">
      <mat-radio-group class="tp-radio-group" [ngModelOptions]="{ standalone: true }" [(ngModel)]="selectedAction">
        <mat-radio-button
          class="tp-radio-button"
          (change)="blankOnSelection()"
          *ngFor="let action of actionTypes"
          [value]="action"
        >
          {{ action }}
        </mat-radio-button>
      </mat-radio-group>
      <div *ngIf="selectedAction === 'Approve'">
        <mat-form-field class="short">
          <input
            matInput
            [disabled]="readOnlyRoles"
            placeholder="Load Quantity"
            formControlName="loadQuantity"
            type="number"
            [value]="loadsQty"
            min="0"
          />
          <mat-error *ngIf="hasError('required', form, formNames.LoadQty)"> Number of Loads is required. </mat-error>
          <mat-error *ngIf="hasError('quantityOverflow', form, formNames.LoadQty)">
            The quantity exceeds the maximum.
          </mat-error>
        </mat-form-field>

        <div class="reason-row">
          <mat-form-field class="short">
            <mat-select
              [disabled]="readOnlyRoles"
              placeholder="Approval Compliance Type"
              formControlName="approvalType"
              disableOptionCentering
            >
              <mat-option *ngFor="let at of approvalType" [value]="at"> {{ at }} </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('required', form, formNames.ApprovalType)">
              Approval Compliance field is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="selectedAction === 'Decline'" class="warning-decline">
        <span class="warning">ATTENTION</span> All requested loads will decline.
      </div>
      <mat-form-field class="long" *ngIf="selectedAction === 'Decline'">
        <textarea
          [rows]="3"
          type="text"
          [readonly]="readOnlyRoles"
          [disabled]="readOnlyRoles"
          matInput
          placeholder="Comment *"
          maxlength="100"
          formControlName="declineReason"
          value="{{ info.decisionComments }}"
          min="0"
        ></textarea>
        <mat-hint class="hint" align="end"> {{ declineReasonFormControl?.value?.length }} / 100 Chars</mat-hint>
        <mat-error *ngIf="hasError('required', form, 'declineReason')"> Comment field is required. </mat-error>
      </mat-form-field>

      <mat-form-field class="long" *ngIf="selectedAction === 'Approve'">
        <textarea
          [rows]="3"
          type="text"
          [readonly]="readOnlyRoles"
          [disabled]="readOnlyRoles"
          matInput
          placeholder="Comment"
          maxlength="100"
          formControlName="approveReason"
          value="{{ info.decisionComments }}"
        ></textarea>
        <mat-hint class="hint" align="end"> {{ approveReasonFormControl?.value?.length }} / 100 Chars</mat-hint>
      </mat-form-field>

      <div class="button-action-row-1" *ngIf="!isExpiredByShift">
        <button
          mat-flat-button
          xpoSmallButton
          *ngIf="!readOnlyRoles && selectedAction === 'Approve'"
          [disabled]="hasError('quantityOverflow', form, formNames.LoadQty)"
          (click)="approve()"
        >
          APPROVE
        </button>
        <button
          mat-flat-button
          xpoSmallButton
          *ngIf="!readOnlyRoles && selectedAction === 'Decline'"
          [disabled]="hasError('quantityOverflow', form, formNames.LoadQty)"
          (click)="decline()"
        >
          DECLINE
        </button>
      </div>
    </div>
  </form>
</div>
