import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core';
import { SpecialServiceIconModule } from '../special-service-icon/special-service-icon.module';
import { SpecialServiceGroupComponent } from './special-service-group.component';

@NgModule({
  imports: [CommonModule, SpecialServiceIconModule, XpoTooltipModule],
  declarations: [SpecialServiceGroupComponent],
  exports: [SpecialServiceGroupComponent],
})
export class SpecialServiceGroupModule {}
