import { Injectable } from '@angular/core';
import { ExceptionStatusCd, ExceptionSummaryCd, ExceptionSummaryTypeCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import {
  ExceptionAlertCount,
  ExceptionAlertCountByLocation,
  ExceptionManagementApiService,
  ExceptionSummary,
  ListSummarizedExceptionsByLocationQuery,
  ListSummarizedExceptionsByStatusQuery,
  ListSummarizedExceptionsByTypeQuery,
} from '@xpo-ltl/sdk-exceptionmanagement';
import {
  ListTrailerCostOpportunitiesPath,
  ListTrailerCostOpportunitiesResp,
  PerformanceGoalsApiService,
} from '@xpo-ltl/sdk-performancegoals';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ExceptionAlertCountCustom, ExceptionSummaryCustom } from '../../shared/models/exception-summary-custom';
import { AuthService } from '../../shared/services/auth.service';
@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  HSSException: ExceptionAlertCount;
  NewSicHSS: ExceptionAlertCountCustom;
  currentDate = moment(new Date())
    .format('DD-MMM-YY')
    .toUpperCase();
  constructor(
    private authService: AuthService,
    private exceptionManagementApiService: ExceptionManagementApiService,
    private performanceGoal: PerformanceGoalsApiService
  ) {}

  getAlertHeaderCounts(
    params: ListSummarizedExceptionsByStatusQuery,
    overlay: boolean
  ): Observable<any[] | ExceptionSummary[]> {
    return this.exceptionManagementApiService
      .listSummarizedExceptionsByStatus(params, { loadingOverlayEnabled: overlay })
      .pipe(
        shareReplay(1),
        map((data) => {
          this.exceptionSummaryMapper(<any>data.exceptionSummaries);
          return data.exceptionSummaries;
        }),
        catchError((e) => {
          console.error('Performance Service - getAlertHeaderCounts: ', e);
          return of([]);
        })
      );
  }

  getAlertHeaderCountsWithoutPlanDate(
    params: ListSummarizedExceptionsByStatusQuery,
    overlay: boolean
  ): Observable<any[] | ExceptionSummary[]> {
    const newAndNewSICParams = cloneDeep(params);
    this.setExceptionStatusCds(newAndNewSICParams);
    newAndNewSICParams.planDate = '';
    return forkJoin(
      [params, newAndNewSICParams].map((_payload) =>
        this.exceptionManagementApiService.listSummarizedExceptionsByStatus(_payload, {
          loadingOverlayEnabled: overlay,
        })
      )
    ).pipe(
      shareReplay(1),
      map((data) => {
        const planDateSummaries = data[0].exceptionSummaries;
        const newNewSicSummaries = data[1].exceptionSummaries;
        const dataToMap = cloneDeep(this.myNewMapper(planDateSummaries, newNewSicSummaries));
        this.exceptionSummaryMapper(dataToMap);
        return dataToMap;
      }),
      catchError((e) => {
        console.error('Performance Service - getAlertHeaderCounts: ', e);
        return of([]);
      })
    );
  }

  myNewMapper(planDateSummaries: ExceptionSummary[], newNewSicSummaries: ExceptionSummary[]): ExceptionSummary[] {
    const newPlanDateSummaries = cloneDeep(planDateSummaries);
    newPlanDateSummaries.map((groupObject, groupIndex) => {
      Object.keys(groupObject).map((groupKey) => {
        const newSicGroup = newNewSicSummaries[groupIndex][groupKey];
        if (groupObject[groupKey] instanceof Array) {
          if (groupObject[groupKey].length > 0) {
            groupObject[groupKey]
              .slice()
              .reverse()
              .forEach((summary, index, object) => {
                if (summary.type === ExceptionStatusCd.NEW || summary.type === ExceptionStatusCd.NEW_SIC) {
                  groupObject[groupKey].splice(object.length - 1 - index, 1);
                }
                groupObject[groupKey].push(...newSicGroup);
              });
          } else if (newSicGroup && newSicGroup.length > 0) {
            groupObject[groupKey].push(...newSicGroup);
          }
          groupObject[groupKey] = groupObject[groupKey].filter((data, index) => {
            return groupObject[groupKey].indexOf(data) === index;
          });
        }
      });
    });
    return newPlanDateSummaries;
  }

  getAlertCountsByType(payloadByType: ListSummarizedExceptionsByTypeQuery, filterdPlanDate: string): Observable<any> {
    if (
      payloadByType.exceptionStatusCds.some(
        (status) => status === ExceptionStatusCd.NEW || status === ExceptionStatusCd.NEW_SIC
      )
    ) {
      this.setExceptionStatusCds(payloadByType);
    }
    let closedExceptions: boolean = false;
    let respondedExceptions: boolean = false;
    if (
      payloadByType.exceptionSummaryCd === ExceptionSummaryCd.LOAD &&
      payloadByType.exceptionSummaryTypeCd === ExceptionSummaryTypeCd.EXCEPTION
    ) {
      if (payloadByType.exceptionStatusCds.some((status) => status === ExceptionStatusCd.CLOSED)) {
        closedExceptions = true;
        payloadByType.exceptionStatusCds = [
          ExceptionStatusCd.CLOSED,
          ExceptionStatusCd.CANCELLED,
          ExceptionStatusCd.EXPIRED,
          ExceptionStatusCd.RESOLVED,
        ];
      }
      if (payloadByType.exceptionStatusCds.some((status) => status === ExceptionStatusCd.RESPONDED)) {
        respondedExceptions = true;
        payloadByType.exceptionStatusCds = [ExceptionStatusCd.APPROVED, ExceptionStatusCd.DECLINED];
      }
    }

    if (filterdPlanDate === this.currentDate) {
      if (
        payloadByType.exceptionStatusCds.some(
          (status) => status === ExceptionStatusCd.NEW || status === ExceptionStatusCd.NEW_SIC
        )
      ) {
        payloadByType.planDate = '';
      } else {
        payloadByType.planDate = filterdPlanDate;
      }
    }
    return this.exceptionManagementApiService
      .listSummarizedExceptionsByType(payloadByType, { loadingOverlayEnabled: false })
      .pipe(
        map((data: any) => {
          const mappedData = this.exceptionsCountsByTypeMapper(data);
          if (
            payloadByType.exceptionSummaryCd === ExceptionSummaryCd.LOAD &&
            payloadByType.exceptionSummaryTypeCd === ExceptionSummaryTypeCd.EXCEPTION
          ) {
            const statusCd = respondedExceptions ? ExceptionStatusCd.RESPONDED : payloadByType.exceptionStatusCds[0];

            this.alertCountsByTypeMapper(mappedData, statusCd);
          }
          const newResponse = {
            exceptionAlertCountsByStatus: [
              { status: payloadByType.exceptionStatusCds[0], exceptionAlertCounts: mappedData },
            ],
          };
          return newResponse;
        }),
        catchError((e) => {
          return of([]);
        })
      );
  }
  /**
   * Get the Exception status cds
   * @param params
   * @returns
   */

  private setExceptionStatusCds(params: { exceptionStatusCds: ExceptionStatusCd[] }): void {
    params.exceptionStatusCds = [ExceptionStatusCd.NEW, ExceptionStatusCd.NEW_SIC];
  }

  getAlertCountsByLocation(
    payloadByLocation: ListSummarizedExceptionsByLocationQuery,
    filteredPlanDate: string
  ): Observable<any[]> {
    if (
      payloadByLocation.exceptionStatusCds.some(
        (status) => status === ExceptionStatusCd.NEW || status === ExceptionStatusCd.NEW_SIC
      )
    ) {
      this.setExceptionStatusCds(payloadByLocation);
    }
    let isRespondedException = false;
    const isClosedException =
      (payloadByLocation.exceptionTypeCd === ExceptionTypeCd.HSS ||
        payloadByLocation.exceptionTypeCd === ExceptionTypeCd.CUT_LOAD_REQUEST ||
        payloadByLocation.exceptionTypeCd === ExceptionTypeCd.ADD_LOAD_REQUEST) &&
      payloadByLocation.exceptionStatusCds.some((status) => status === ExceptionStatusCd.CLOSED) &&
      payloadByLocation.exceptionSummaryCd === ExceptionSummaryCd.LOAD &&
      payloadByLocation.exceptionSummaryTypeCd === ExceptionSummaryTypeCd.EXCEPTION;

    if (isClosedException) {
      payloadByLocation.exceptionStatusCds = [
        ExceptionStatusCd.CLOSED,
        ExceptionStatusCd.RESOLVED,
        ExceptionStatusCd.EXPIRED,
        ExceptionStatusCd.CANCELLED,
      ];
    }

    /**
     * Check if is a LHCoordinator and if is a HSS exception in Responded status
     * Change the responded request by UnderReviewLH request
     */
    if (payloadByLocation.exceptionStatusCds.some((status) => status === ExceptionStatusCd.RESPONDED)) {
      isRespondedException = true;
      payloadByLocation.exceptionStatusCds = [ExceptionStatusCd.APPROVED, ExceptionStatusCd.DECLINED];
    }

    /**
     * Check if is SicUser and if a HSS exception in New status
     * change new request by newSic request
     *
     */

    if (
      payloadByLocation.exceptionTypeCd === ExceptionTypeCd.HSS &&
      payloadByLocation.exceptionStatusCds.some((status) => status === ExceptionStatusCd.NEW) &&
      this.authService.sicHSSToggle
    ) {
      payloadByLocation.exceptionStatusCds = [ExceptionStatusCd.NEW_SIC];
    }

    if (filteredPlanDate === this.currentDate) {
      if (
        payloadByLocation.exceptionStatusCds.some(
          (status) => status === ExceptionStatusCd.NEW || status === ExceptionStatusCd.NEW_SIC
        )
      ) {
        payloadByLocation.planDate = '';
      } else {
        payloadByLocation.planDate = filteredPlanDate;
      }
    }

    return this.exceptionManagementApiService
      .listSummarizedExceptionsByLocation(payloadByLocation, { loadingOverlayEnabled: false })
      .pipe(
        map((data) => {
          const exceptionSummaries = data.exceptionAlertCountByTypes[0].exceptionAlertCountsByLocation;
          let status = isClosedException ? ExceptionStatusCd.CLOSED : payloadByLocation.exceptionStatusCds[0];

          status = isRespondedException ? ExceptionStatusCd.RESPONDED : status;
          return this.exceptionsCountsByLocationMapper(exceptionSummaries, status);
        }),
        catchError((e) => of([]))
      );
  }

  /**
   * Map exceptions to get hssCount
   */

  private exceptionSummaryMapper(summaries: ExceptionSummaryCustom[]): void {
    summaries.forEach((summary) => {
      if (summary.groupName === ExceptionSummaryCd.LOAD) {
        /**
         * Add HSS to exception object
         */
        if (summary.hssExceptions && summary.hssExceptions.length > 0) {
          if (summary.exceptions.length === 0) {
            summary.exceptions = cloneDeep(summary.hssExceptions);
          } else {
            summary.hssExceptions.forEach((hssEx) => {
              const matchingEx = summary.exceptions.find((ex) => ex.type === hssEx.type);
              if (matchingEx) {
                matchingEx.count += hssEx.count;
                matchingEx.oldestHssDate = hssEx.oldestDate;
              } else {
                summary.exceptions.push(cloneDeep(hssEx));
              }
            });
          }
        }

        const indexUnderReviewLH = summary.exceptions.findIndex((ex) => ex.type === ExceptionStatusCd.UNDER_REVIEW_LH);
        const UnderReviewHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.UNDER_REVIEW_LH);
        const closedEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.CLOSED);
        const newSicEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.NEW_SIC);
        const newSicHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.NEW_SIC);
        const closedExHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.CLOSED);
        const resolvedHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.RESOLVED);
        const expiredHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.EXPIRED);
        const cancelledExHSS = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.CANCELLED);
        const cancelledEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.CANCELLED);
        const expiredEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.EXPIRED);

        /**
         * Responded Exceptions
         */
        const approvedEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.APPROVED);
        const declinedEx = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.DECLINED);
        const approvedExHss = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.APPROVED);
        const declinedExHss = summary.hssExceptions.find((ex) => ex.type === ExceptionStatusCd.DECLINED);

        const approvedCount = (approvedEx && approvedEx.count) || 0;
        const declinedCount = (declinedEx && declinedEx.count) || 0;
        const approvedCountHss = (approvedExHss && approvedExHss.count) || 0;
        const declinedCountHss = (declinedExHss && declinedExHss.count) || 0;

        const respondedExceptions: ExceptionAlertCountCustom = {
          count: approvedCount + declinedCount,
          HSSCount: approvedCountHss + declinedCountHss,
          oldestDate: new Date(),
          type: ExceptionStatusCd.RESPONDED,
        };

        this.HSSException = UnderReviewHSS;

        this.NewSicHSS = newSicHSS;

        /**
         * Closed exceptions
         */
        if (!closedEx) {
          const closedExceptions = summary.exceptions.filter(
            (ex) =>
              ex.type === ExceptionStatusCd.EXPIRED ||
              ex.type === ExceptionStatusCd.RESOLVED ||
              ex.type === ExceptionStatusCd.CANCELLED
          );

          if (closedExceptions.length) {
            const firstIndex = summary.exceptions.findIndex((ex) => ex.type === closedExceptions[0].type);
            const firstIndexHSS = summary.hssExceptions.findIndex((ex) => ex.type === closedExceptions[0].type);

            summary.exceptions[firstIndex].type = ExceptionStatusCd.CLOSED;

            if (summary.hssExceptions[firstIndexHSS]) {
              summary.hssExceptions[firstIndexHSS].type = ExceptionStatusCd.CLOSED;
            }
          }
        }

        summary.exceptions.forEach((exception) => {
          const hssException = summary.hssExceptions
            ? summary.hssExceptions.find((ex) => ex.type === exception.type)
            : false;
          exception.HSSCount = (hssException && hssException.count) || 0;

          if (exception.type === ExceptionStatusCd.NEW) {
            const newSicHSSCount = (newSicHSS && newSicHSS.count) || 0;

            exception.count =
              (this.authService.sicHSSToggle ? -exception.HSSCount : 0) + exception.count + newSicHSSCount;
            exception.HSSCount = (this.authService.sicHSSToggle ? 0 : exception.HSSCount) + newSicHSSCount;
          }

          if (exception.type === ExceptionStatusCd.CLOSED) {
            const resolvedHSSCount = (resolvedHSS && resolvedHSS.count) || 0;
            const expiredHSSCount = (expiredHSS && expiredHSS.count) || 0;
            const cancelledCount = (cancelledEx && cancelledEx.count) || 0;
            const cancelledHSSCount = (cancelledExHSS && cancelledExHSS.count) || 0;
            const closedCount = (closedEx && closedEx.count) || 0;
            const closedHSSCount = (closedExHSS && closedExHSS.count) || 0;
            const expiredCount = (expiredEx && expiredEx.count) || 0;

            exception.HSSCount = resolvedHSSCount + expiredHSSCount + cancelledHSSCount + closedHSSCount;
            exception.count = resolvedHSSCount + expiredCount + cancelledCount + closedCount;
          }
        });

        /**
         * Responded Exceptions
         */
        if (this.authService.sicHSSToggle) {
          if (indexUnderReviewLH >= 0) {
            summary.exceptions.splice(indexUnderReviewLH, 1);
          }

          summary.exceptions.unshift(respondedExceptions);
        }

        const approvedIndex = summary.exceptions.findIndex((ex) => ex.type === ExceptionStatusCd.APPROVED);
        if (approvedIndex >= 0) {
          summary.exceptions.splice(approvedIndex, 1);
        }

        const declinedIndex = summary.exceptions.findIndex((ex) => ex.type === ExceptionStatusCd.DECLINED);
        if (declinedIndex >= 0) {
          summary.exceptions.splice(declinedIndex, 1);
        }

        if (newSicEx) {
          const newAvail = summary.exceptions.find((ex) => ex.type === ExceptionStatusCd.NEW);
          const newSicIndex = summary.exceptions.findIndex((ex) => ex.type === ExceptionStatusCd.NEW_SIC);

          if (!newAvail && this.authService.sicHSSToggle) {
            summary.exceptions[newSicIndex].type = ExceptionStatusCd.NEW;
          } else {
            summary.exceptions.splice(newSicIndex, 1);
          }
        }
      }
    });
  }

  /**
   * Map exceptions by type to hide HSS Exceptions
   * or show exceptions for LHUsers
   */
  private alertCountsByTypeMapper(data: ExceptionAlertCount[], statusCd: ExceptionStatusCd): void {
    const HSSAlertIndex = data.findIndex((alert) => alert.type === ExceptionTypeCd.HSS);

    if (statusCd === ExceptionStatusCd.NEW) {
      if (this.authService.sicHSSToggle) {
        if (HSSAlertIndex >= 0) {
          data[HSSAlertIndex].count = this.NewSicHSS?.count || 0;
          data[HSSAlertIndex].oldestDate = this.NewSicHSS && this.NewSicHSS.oldestDate;

          if (data[HSSAlertIndex].count === 0) {
            data.splice(HSSAlertIndex, 1);
          }
        } else if (this.NewSicHSS && this.NewSicHSS.count > 0) {
          this.NewSicHSS.type = ExceptionTypeCd.HSS;
          delete this.NewSicHSS.isHidden;
          data.unshift(this.NewSicHSS);
        }
      }
    }
  }

  /**
   * Map exceptions to get closed by type hssCount
   */
  private exceptionsCountsByTypeMapper(data: any): ExceptionAlertCount[] {
    const exceptionsAlertCounts: any[] = [];

    data.exceptionAlertCountsByStatus.forEach((exceptionsByType) => {
      exceptionsByType.exceptionAlertCounts.forEach((alertCount: any) => {
        const alertCountIndex = exceptionsAlertCounts.findIndex((ex) => ex.type === alertCount.type);

        if (alertCountIndex >= 0) {
          exceptionsAlertCounts[alertCountIndex].count += alertCount.count;
          exceptionsAlertCounts[alertCountIndex].oldestDate = Math.min(
            exceptionsAlertCounts[alertCountIndex].oldestDate,
            alertCount.oldestDate
          );
        } else {
          exceptionsAlertCounts.push(alertCount);
        }
      });
    });

    return exceptionsAlertCounts;
  }

  /**
   * Map exceptions to get closed by location hssCount
   */
  private exceptionsCountsByLocationMapper(
    data,
    statusGroup: string = ExceptionStatusCd.CLOSED
  ): ExceptionAlertCountByLocationCustom[] {
    const resultArr = [];

    data.forEach((exceptionSummary) => {
      const alertCount = { type: statusGroup, count: 0, oldestDate: 0 };

      exceptionSummary.exceptionAlertCounts.forEach((alertByType: any) => {
        alertCount.count += alertByType.count;
        alertCount.oldestDate =
          alertCount.oldestDate === 0
            ? alertByType.oldestDate
            : Math.min(alertCount.oldestDate, alertByType.oldestDate);
      });

      exceptionSummary.exceptionAlertCounts = [alertCount];

      resultArr.push(exceptionSummary);
    });

    return resultArr;
  }

  getCostOpportunities(exceptionInstId: number): Observable<ListTrailerCostOpportunitiesResp> {
    const query = new ListTrailerCostOpportunitiesPath();
    query.exceptionInstId = exceptionInstId;
    return this.performanceGoal
      .listTrailerCostOpportunities(query, { toastOnError: false, loadingOverlayEnabled: false })
      .pipe(
        catchError((err) => {
          // ToastOnError is set to false due to the prod issue and that's why we are console logging the error for reference.
          console.log(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
          return of(new ListTrailerCostOpportunitiesResp());
        })
      );
  }
}

interface ExceptionAlertCountByLocationCustom extends ExceptionAlertCountByLocation {
  multipleCall?: boolean;
}
