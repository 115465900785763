import { DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { maxBy as _maxBy } from 'lodash';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getAge, getShift } from '../../../utils/data-transform-utils';
import { HSSWeightFormatterComponent } from '../../formatters/ag-grid-cell-formatters/hss-weight-formatter';
import { IconsFormatterComponent } from '../../formatters/ag-grid-cell-formatters/icons-formatter';
import { RemainingValueFormatterComponent } from '../../formatters/ag-grid-cell-formatters/remaining-value-formatter';
import { RequesterCommentFormatterComponent } from '../../formatters/ag-grid-cell-formatters/requester-comment-formatter';
import { SidePanelDataStructures } from '../../models/side-panel-data.model';
import { AuthService } from '../../services/auth.service';
import { InteractionService } from '../../services/interaction.service';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
import { TablesDataSourceService } from '../tables-data-source/tables-data-source.service';
import { AlertsType } from './../../enums/alerts.enum';
import { SidePanelVisibilityService } from './../../services/side-panel-visibility.service';
import { HssTableHeaderComponent } from './hss-table-header/hss-table-header.component';
import { HssUnderweightBoardTemplate } from './hss-underweight-view-board-template';

@Component({
  selector: 'app-hss-underweight-table',
  templateUrl: './hss-underweight-table.component.html',
  styleUrls: ['./hss-underweight-table.component.scss'],
})
export class HssUnderweightTableComponent implements OnInit, OnDestroy {
  @Input('dataSource')
  set dataSource(value: Exception[]) {
    this.tablesSourceService.dataSource = value;
  }
  @Input() tableTab: ExceptionStatusCd[];
  @Input() actions: boolean;
  @Input() tableType = ExceptionTypeCd;
  viewTemplates: HssUnderweightBoardTemplate[];
  views: XpoBoardView[];
  loadsCollection: any = [];
  sizeToFit: boolean = true;
  gridOptions: GridOptions = {
    suppressHorizontalScroll: false,
    suppressDragLeaveHidesColumns: true,
    frameworkComponents: {
      RemainingValueFormatterComponent: RemainingValueFormatterComponent,
      HSSWeightFormatterComponent: HSSWeightFormatterComponent,
      IconsFormatterComponent: IconsFormatterComponent,
      RequesterCommentFormatterComponent: RequesterCommentFormatterComponent,
      hssWeightTableHeader: HssTableHeaderComponent,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      suppressMenu: true,
    },
    groupHeaderHeight: 40,
    headerHeight: 35,
    rowHeight: 30,
    onColumnResized: (event) => {
      if (event.source === 'uiColumnDragged' && event.finished) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    onFilterChanged: (event) => {
      this.gridRowsCount = event.api.getDisplayedRowCount();
    },
  };
  getAge = getAge;
  getShift = getShift;
  sidePanelDisplay: boolean = false;
  sideData: any;
  sidePanelOpts: SidePanelOpts;
  interactionSubscription;
  private gridApi: GridApi;
  private colApi: ColumnApi;
  routeSubscription: Subscription;
  currentRoute: String;
  tablesSource: any;
  hssDate: any;
  private visibleColumns: { [key: string]: string[] } = {};
  gridRowsCount: number;
  private unsubscriber = new Unsubscriber();

  constructor(
    private tablesSourceService: TablesDataSourceService,
    private timeService: XpoLtlTimeService,
    private decimalPipe: DecimalPipe,
    private authService: AuthService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private interactionService: InteractionService
  ) {
    this.tablesSource = this.tablesSourceService;
  }

  ngOnInit() {
    this.visibleColumns[ExceptionStatusCd.ACKNOWLEDGED] = ['age'];
    this.visibleColumns[ExceptionStatusCd.NEW] = ['age'];
    this.visibleColumns[ExceptionStatusCd.RESPONDED] = ['statusCd', 'controlNumber'];
    this.visibleColumns[ExceptionStatusCd.UNDER_REVIEW_LH] = ['statusCd', 'controlNumber'];
    this.visibleColumns[ExceptionStatusCd.EXPIRED] = ['statusCd', 'controlNumber', 'res_exp_text'];
    this.visibleColumns[ExceptionStatusCd.CLOSED] = ['statusCd', 'controlNumber', 'res_exp_text'];
    this.visibleColumns[ExceptionStatusCd.RESOLVED] = ['statusCd', 'controlNumber', 'res_exp_text'];
    this.visibleColumns[ExceptionStatusCd.NEW].push('action');
    this.visibleColumns[ExceptionStatusCd.ACKNOWLEDGED].push('action');

    this.gridOptions.onCellClicked = this.handleClick.bind(this);
    this.clearLaneData();
    setInterval(() => {
      if (this.gridApi) {
        this.gridApi.refreshCells();
      }
    }, 60000);
    this.viewTemplates = [new HssUnderweightBoardTemplate(this.decimalPipe, this.timeService, this.authService)];
    this.interactionService.refreshAggridData.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.tablesSource) {
        this.tablesSource.refresh();
      }
    });
    this.views = [this.viewTemplates[0].createHssUnderweight()];
    this.routeSubscription = this.interactionService.getRoute().subscribe((route) => (this.currentRoute = route));
  }

  ngOnDestroy() {
    this.gridApi.destroy();
    this.routeSubscription.unsubscribe();
  }

  onLoadsGridReady(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
    this.gridRowsCount = gridEvent.api.getDisplayedRowCount();
    this.setColumnVisibility();
    this.colApi.autoSizeAllColumns();

    if (this.tablesSourceService.dataSource.length === 1) {
      this.openPanel(this.tablesSource.dataSource[0]);
    }
  }

  getHighestDetail(detailList) {
    if (detailList.length > 1) {
      return _maxBy(detailList, 'expectationDetailSequenceNbr');
    } else {
      return detailList[0];
    }
  }

  closeSidePanelDisplay() {
    this.clearLaneData();
    this.sidePanelDisplay = false;
  }

  clearLaneData() {
    this.sideData = SidePanelDataStructures.getBlankData();
  }

  handleClick(element) {
    this.interactionService.setRoute(this.currentRoute);
    this.openPanel(element.data);
  }

  openPanel(data) {
    this.sidePanelVisibilityService.openPanel(AlertsType.HSS_UNDER_WGT, data);
  }

  private setColumnVisibility() {
    this.tableTab.forEach((tab) => {
      this.colApi.setColumnsVisible(this.visibleColumns[tab], true);
    });
  }
}
