import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Unsubscriber, XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoPopover } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { isArray as _isArray } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { IconsFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/icons-formatter';
import { RequesterCommentFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/requester-comment-formatter';
import { AlertsType } from '../../enums/alerts.enum';
import { LoadRequestStatusFormatterComponent } from '../../formatters/ag-grid-cell-formatters/load-request-status-formatter';
import { AuthService } from '../../services/auth.service';
import { InteractionService } from '../../services/interaction.service';
import { SidePanelVisibilityService } from '../../services/side-panel-visibility.service';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
import { TablesDataSourceService } from '../tables-data-source/tables-data-source.service';
import { LoadRequestBoardTemplate } from './load-request-view-board-template';

@Component({
  selector: 'load-request-table',
  templateUrl: './load-request-table.component.html',
  styleUrls: ['./load-request-table.component.scss'],
  animations: [
    trigger('expandableRow', [
      state(
        'collapsed, void',
        style({
          display: 'none',
          height: '0px',
          visibility: 'hidden',
        })
      ),
      state(
        'expanded',
        style({
          'min-height': '48px',
          height: '*',
          visibility: 'visible',
        })
      ),
      transition('expanded <=> collapsed, void <=> *', animate('125ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LoadRequestTableComponent implements OnInit, OnDestroy {
  @Input('dataSource')
  set dataSource(value: Exception[]) {
    this.tablesSourceService.dataSource = value;
  }

  @Input() tableTab: ExceptionStatusCd;
  @Input() actions: boolean;
  @Input() tableType: ExceptionTypeCd;

  @ViewChild('popover', { static: false })
  popover: XpoPopover;
  viewTemplates: LoadRequestBoardTemplate[];
  views: XpoBoardView[];

  private gridApi: GridApi;
  private colApi: ColumnApi;

  gridOptions: GridOptions = {
    suppressHorizontalScroll: false,
    suppressDragLeaveHidesColumns: true,
    frameworkComponents: {
      IconsFormatterComponent: IconsFormatterComponent,
      RequesterCommentFormatterComponent: RequesterCommentFormatterComponent,
      LoadRequestStatusFormatterComponent: LoadRequestStatusFormatterComponent,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      suppressMenu: true,
    },
    headerHeight: 40,
    rowHeight: 30,
    onColumnResized: (event) => {
      if (event.source === 'uiColumnDragged' && event.finished) {
        this.gridApi.sizeColumnsToFit();
      }
    },
  };
  tablesSource: any;
  currentRoute: String;
  sidePanelOpts: SidePanelOpts;
  isAllowed: boolean = false;
  private columnVisibility = [];
  private unsubscriber = new Unsubscriber();

  constructor(
    private interactionService: InteractionService,
    private timeService: XpoLtlTimeService,
    private authService: AuthService,
    private tablesSourceService: TablesDataSourceService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private sidePanelVisibilityService: SidePanelVisibilityService
  ) {
    this.tablesSource = this.tablesSourceService;
  }

  ngOnInit(): void {
    this.isAllowed = this.authService.isAllowedToSeeAlert();
    this.setColumnVisibility();
    this.gridOptions.onCellClicked = this.handleClick.bind(this);
    this.generateView();
    this.interactionService.refreshAggridData.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.tablesSource) {
        this.tablesSource.refresh();
      }
    });
    this.interactionService.destroyAggridTable.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.gridApi) {
        this.gridApi.destroy();
      }
    });
    this.interactionService
      .getRoute()
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((route) => (this.currentRoute = route));
  }

  ngOnDestroy(): void {
    this.gridApi.destroy();
    this.unsubscriber.complete();
  }

  generateView(): void {
    this.viewTemplates = [new LoadRequestBoardTemplate(this.tableTab, this.timeService, this.interactionService)];
    this.views = [this.viewTemplates[0].createLoadRequestView()];
  }

  onLoadsGridReady(gridEvent: AgGridEvent): void {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;

    let exceptionState = this.tableTab;

    if (this.isLR(this.tableType) && _isArray(exceptionState)) {
      const closed = exceptionState.some(
        (status) =>
          status === ExceptionStatusCd.CLOSED ||
          status === ExceptionStatusCd.CANCELLED ||
          status === ExceptionStatusCd.EXPIRED ||
          status === ExceptionStatusCd.RESOLVED
      );
      exceptionState = !!closed ? ExceptionStatusCd.CLOSED : exceptionState[0];
    }

    const showColumns = this.columnVisibility[exceptionState];

    if (showColumns) {
      this.colApi.setColumnsVisible(showColumns, true);
      if (this.isAllowed || this.tableTab !== ExceptionStatusCd.NEW) {
        this.colApi.setColumnVisible('actions', false);
      }
    }
    if (this.isAllowed || this.tableTab !== ExceptionStatusCd.NEW) {
      this.colApi.setColumnVisible('actions', false);
    }

    if (this.tablesSourceService.dataSource.length === 1) {
      this.openPanel(this.tablesSource.dataSource[0]);
    }
    if (
      (this.isAllowed && this.tableTab === ExceptionStatusCd.CLOSED) ||
      (this.isAllowed && this.tableTab === ExceptionStatusCd.EXPIRED)
    ) {
      this.colApi.setColumnVisible('actions', false);
    }
  }

  handleClick(element): void {
    this.interactionService.setRoute(this.currentRoute);
    this.openPanel(element.data);
  }

  openPanel(data): void {
    this.interactionService.setSelectedTrailer(data.operationException.moveToSicCode);
    this.sidePanelVisibilityService.openPanel(AlertsType.LOAD_REQUEST, data);
  }

  private setColumnVisibility(): void {
    this.columnVisibility[ExceptionStatusCd.NEW] = [
      'age',
      'lane',
      'shift',
      'loads',
      'dock',
      'requestedby',
      'reason',
      'comment',
      'requested',
    ];
    this.columnVisibility[ExceptionStatusCd.RESPONDED] = [
      'age',
      'lane',
      'shift',
      'loads',
      'dock',
      'requestedby',
      'reason',
      'comment',
      'requested',
      'statusApproved',
      'statusCanceled',
      'decisionReason',
    ];

    const closedColumns = [
      'lane',
      'shift',
      'loads',
      'dock',
      'requestedby',
      'reason',
      'comment',
      'requested',
      'status',
      'decisionReason',
    ];

    this.columnVisibility[ExceptionStatusCd.CLOSED] = closedColumns;
    this.columnVisibility[ExceptionStatusCd.CANCELLED] = closedColumns;
    this.columnVisibility[ExceptionStatusCd.EXPIRED] = closedColumns;
    this.columnVisibility[ExceptionStatusCd.APPROVED] = this.columnVisibility[ExceptionStatusCd.RESPONDED];
    this.columnVisibility[ExceptionStatusCd.DECLINED] = this.columnVisibility[ExceptionStatusCd.RESPONDED];
  }

  private isLR(exceptionAlert: ExceptionTypeCd): boolean {
    return (
      exceptionAlert === ExceptionTypeCd.LOAD_REQUEST ||
      exceptionAlert === ExceptionTypeCd.ADD_LOAD_REQUEST ||
      exceptionAlert === ExceptionTypeCd.CUT_LOAD_REQUEST
    );
  }
}
