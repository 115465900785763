import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DriversService } from '../../../drivers/services/drivers.service';

@Component({
  selector: 'app-driver-icon-renderer',
  templateUrl: './driver-icon-renderer.component.html',
  styleUrls: ['./driver-icon-renderer.component.scss'],
})
export class DriverIconRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: any;
  forward: boolean = false;
  alert: boolean = false;
  nbsp: boolean = false;
  current_sic: string;
  unsubscribe = new Subject<void>();

  constructor(private driversService: DriversService, private timeService: XpoLtlTimeService) {
    this.driversService.sic$.pipe(takeUntil(this.unsubscribe)).subscribe((newSic: string) => {
      this.current_sic = newSic;
    });
  }

  agInit(params: any): void {
    this.params = params;

    if (params.data) {
      if (params.data.status) {
        if (params.data.status === 'Dispatched') {
          this.forward = true;
        }
      }
      if (params.data.startTmst && params.data.cutTime) {
        const sic_time = this.timeService.formatDate(params.data.cutTime, 'HH:mm', this.current_sic);
        if (params.data.startTmst > sic_time) {
          this.alert = true;
        }
      }
    }

    if (this.forward === false && this.alert === false) {
      this.nbsp = true;
    }
    if (this.forward === true && this.alert === true) {
      this.alert = false;
    }
    if (this.params.data.assignSicCd !== this.current_sic && this.params.data.domicileSicCd !== this.current_sic) {
      this.forward = false;
      this.alert = false;
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
