import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { XpoAdvancedSelectOption, XpoAdvancedSelectOptionFlat } from '../models/index';
import { XpoAdvancedSelectTreeService } from './advanced-select-tree.service';

@Component({
  selector: 'xpo-advanced-select-tree',
  templateUrl: 'advanced-select-tree.component.html',
  styleUrls: ['advanced-select-tree.component.scss'],
  providers: [XpoAdvancedSelectTreeService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AdvancedSelectTree' },
})
export class XpoAdvancedSelectTreeComponent implements OnInit, OnDestroy {
  treeControl: FlatTreeControl<XpoAdvancedSelectOptionFlat>;
  dataSource: MatTreeFlatDataSource<XpoAdvancedSelectOption, XpoAdvancedSelectOptionFlat>;
  checklistSelection: SelectionModel<XpoAdvancedSelectOptionFlat>;
  isSelectAllSelected: boolean = false;

  private treeFlattener: MatTreeFlattener<XpoAdvancedSelectOption, XpoAdvancedSelectOptionFlat>;

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  private nestedNodeMap = new Map<XpoAdvancedSelectOption, XpoAdvancedSelectOptionFlat>();
  private destroyed$ = new Subject<void>();

  @Input() options: XpoAdvancedSelectOption[];

  @Input()
  get suppressIncludeParentCode(): boolean {
    return this.suppressIncludeParentCodeValue;
  }
  set suppressIncludeParentCode(v: boolean) {
    this.suppressIncludeParentCodeValue = coerceBooleanProperty(v);
  }
  private suppressIncludeParentCodeValue: boolean = false;

  @Input()
  get showSelectAll(): boolean {
    return this.showSelectAllValue;
  }
  set showSelectAll(v: boolean) {
    this.showSelectAllValue = coerceBooleanProperty(v);
  }
  private showSelectAllValue: boolean = false;

  @Input()
  get isSingleSelect(): boolean {
    return this.isSingleSelectValue;
  }
  set isSingleSelect(v: boolean) {
    this.isSingleSelectValue = coerceBooleanProperty(v);
  }
  private isSingleSelectValue: boolean;

  @Input()
  get selection(): string | string[] {
    return this.selectionValues;
  }
  set selection(s: string | string[]) {
    if (s !== this.selectionValues) {
      this.selectionValues = s;
    }
  }
  private selectionValues: string | string[];

  /** Filter by parent's component input */
  @Input()
  get searchFilter(): string {
    return this.searchFilterValue;
  }
  set searchFilter(v: string) {
    this.searchFilterValue = v;
    this._database.filter(v);
  }
  searchFilterValue: string;

  @Output() selectionChange = new EventEmitter<string | string[]>();

  @Output() optionsSelected = new EventEmitter<XpoAdvancedSelectOption[]>();

  constructor(private _database: XpoAdvancedSelectTreeService, private cd: ChangeDetectorRef) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<XpoAdvancedSelectOptionFlat>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {
    this._database.filteredOptions$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      if (data) {
        this.dataSource.data = data;
        this.cd.markForCheck();
      }
    });

    this.checklistSelection = new SelectionModel<XpoAdvancedSelectOptionFlat>(!this.isSingleSelect);
    this._database.initialize(this.options);

    if (this.selection) {
      this.setItemSelection(this.selection);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getLevel = (node: XpoAdvancedSelectOptionFlat) => node?.level ?? null;

  isExpandable = (node: XpoAdvancedSelectOptionFlat) => node.expandable;

  getChildren = (node: XpoAdvancedSelectOption): XpoAdvancedSelectOption[] => node.children;

  hasChild = (_num: number, nodeData: XpoAdvancedSelectOptionFlat): boolean => nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: XpoAdvancedSelectOption, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.value === node.value ? existingNode : ({} as XpoAdvancedSelectOptionFlat);
    flatNode.value = node.value;
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: XpoAdvancedSelectOptionFlat): boolean {
    if (this.isSingleSelect) {
      return this.checklistSelection.isSelected(node);
    }

    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));

    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: XpoAdvancedSelectOptionFlat): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) => this.checklistSelection.isSelected(child));

    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: XpoAdvancedSelectOptionFlat): void {
    if (this.isSingleSelect) {
      this.checklistSelection.clear();
      this.checklistSelection.toggle(node);
    } else {
      this.checklistSelection.toggle(node);
      this.nodeSelection(node);
      // Force update for the parent
      this.checkAllParentsSelection(node);
    }
    this.emitSelection(this.checklistSelection.selected);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: XpoAdvancedSelectOptionFlat, emit: boolean = true): void {
    this.checklistSelection.toggle(node);

    if (this.suppressIncludeParentCode) {
      this.nodeSelection(node);
    }
    this.checkAllParentsSelection(node);

    if (emit) {
      this.emitSelection(this.checklistSelection.selected);
    }
  }

  /** Select - Deselect Node */
  nodeSelection(node: XpoAdvancedSelectOptionFlat): void {
    const descendants = this.treeControl.getDescendants(node);
    // Set to passed in value, if not assume we want to toggle the value
    const selectItems = this.checklistSelection.isSelected(node);
    selectItems ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);
  }

  /** Handles Selection for 'All' checkbox */
  handleSelectAllClicked(isChecked: boolean): void {
    this.isSelectAllSelected = !!isChecked;
    this.isSelectAllSelected === true
      ? this.checklistSelection.select(...this.treeControl.dataNodes)
      : this.checklistSelection.deselect(...this.treeControl.dataNodes);
    this.emitSelection(this.checklistSelection.selected);
  }

  private setItemSelection(items: string | string[]): void {
    const normalizedItems = items instanceof Array ? items : [items];

    const nodes = this.dataSource._flattenedData.value.filter((dataNode) => {
      return normalizedItems.some((i) => i === dataNode.value);
    });

    nodes.forEach((n) => {
      this.leafItemSelectionToggle(n, false);
    });
  }

  private emitSelection(selection: XpoAdvancedSelectOptionFlat[]): void {
    let sel: XpoAdvancedSelectOptionFlat[] = selection;

    // If suppressIncludeParentCode is on, then we need to recursively search and compare options and
    // selections to get the parents and find if there are any values outside complete parent selection

    if (this.suppressIncludeParentCode && selection.length) {
      // Get Parents Selected and Level to filter properly their children

      const levelParent = Math.min(...selection.map((sel) => sel.level));
      const parents = selection.filter((sel) => sel.level === levelParent && sel.expandable === true);

      // Get all Children to compare if there's a child from another group

      if (parents.length) {
        const allChildrenSelection = selection.filter((opts) => opts.level === levelParent + 1);
        const optionChildren = this.filterOptionChildren(this.options, parents);

        if (allChildrenSelection.length === optionChildren.reduce((a, e) => a + e.children.length, 0)) {
          // Send Only Parents that are in selection
          sel = parents;
        } else {
          const childrenValues = optionChildren
            .reduce((a, v) => a.concat(v.children), [])
            .map((r) => {
              return r.value;
            });

          const allValues = allChildrenSelection.map((sel) => sel.value);
          const intersection = allValues.filter((x) => !childrenValues.includes(x));
          // Send outsiders + Parents
          sel = allChildrenSelection.filter((sel) => intersection.some((i) => i === sel.value)).concat(parents);
        }
      }
    }

    this.selection = sel.map((s) => s.value);
    this.optionsSelected.emit(selection);
    this.selectionChange.emit(this.selection);
  }

  private filterOptionChildren(
    options: XpoAdvancedSelectOption[],
    parents: XpoAdvancedSelectOption[]
  ): XpoAdvancedSelectOption[] {
    return options.filter((ch) => parents.some((par) => par.value === ch.value)).length === 0
      ? options
          .map((opt) => opt.children.filter((ch) => parents.some((par) => par.value === ch.value)))
          .reduce((acc, val) => acc.concat(val), [])
      : options.filter((ch) => parents.some((par) => par.value === ch.value));
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  private checkAllParentsSelection(node: XpoAdvancedSelectOptionFlat): void {
    let parent: XpoAdvancedSelectOptionFlat | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  private checkRootNodeSelection(node: XpoAdvancedSelectOptionFlat): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every((child) => this.checklistSelection.isSelected(child));

    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  private getParentNode(node: XpoAdvancedSelectOptionFlat): XpoAdvancedSelectOptionFlat | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }
}
