<div class="cost-details">
  <div class="cost-details__header">
    <h2 class="cost-details__title">Cost Details</h2>
    <p class="cost-details__message"
        *ngIf="showAvgMessage">
      This trailer couldn't be matched to a scheduled pull, so average HSS cost per mile was used.
    </p>
  </div>
  <div class="cost-details__opportunities">
    <div class="opp-underutilized">
      <p class="opp__title">UNDERUTILIZED</p>
      <div class="opp__data">
        <p class="opp__amount">
          {{ costOpportunity?.fullOpportunityAmount | currency: '$ ':'symbol':'1.0-0' | dashFormatter }}
        </p>
        <p class="opp__percent"
            *ngIf="showFormatForCube">( {{ underUtilizedAmount }} {{ underUtilizedText }} )</p>
        <p class="opp__percent"
            *ngIf="!showFormatForCube">
          ( {{ underUtilizedAmount | number: '1.0-0':'en-US' }} {{ underUtilizedText }} )
        </p>
      </div>
    </div>
    <div class="opp-prosRemaining">
      <p class="opp__title">PROS REMAINING</p>
      <div class="opp__data">
        <p class="opp__amount">
          {{ costOpportunity?.availableOpportunityAmount | currency: '$ ':'symbol':'1.0-0' | dashFormatter }}
        </p>
        <p class="opp__percent"
            *ngIf="showFormatForCube">({{ proRemAmount }} {{ prosRemText }})</p>
        <p class="opp__percent"
            *ngIf="!showFormatForCube">
          ({{ proRemAmount | number: '1.0-0':'en-US' }} {{ prosRemText }})
        </p>
      </div>
    </div>
    <div class="opp-prosLoaded">
      <div class="opp-prosLoaded__data">
        <p class="opp__title">PROS LOADED</p>
        <div class="opp__data">
          <p class="opp__amount">
            {{ costOpportunity?.realizedOpportunityAmount | currency: '$ ':'symbol':'1.0-0' | dashFormatter }}
          </p>
        </div>
      </div>
      <div class="opp__indicators"
          *ngIf="costOpportunity?.realizedOpportunityAmount">
        <div class="opp__ind-data">
          <mat-icon [ngClass]="{
              'up-arrow': addedWeightAmount > 0,
              'down-arrow': addedWeightAmount < 0,
              'no-arrow': addedWeightAmount === 0
            }">trending_flat</mat-icon>
          <p [ngClass]="{
              'up-Ind': addedWeightAmount > 0,
              'down-Ind': addedWeightAmount < 0,
              'flat-Ind': addedWeightAmount === 0
            }">
            {{ addedWeightAmount | number: '1.0-0':'en-US' }} lbs
          </p>
        </div>
        <div class="opp__ind-data">
          <mat-icon [ngClass]="{
              'up-arrow': addedCubeAmount > 0,
              'down-arrow': addedCubeAmount < 0,
              'no-arrow': addedCubeAmount === 0
            }">trending_flat</mat-icon>
          <p [ngClass]="{
              'up-Ind': addedCubeAmount > 0,
              'down-Ind': addedCubeAmount < 0,
              'flat-Ind': addedCubeAmount === 0
            }">
            {{ addedCubeAmount }}% Cube
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
