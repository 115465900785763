import { Component, OnDestroy, OnInit } from '@angular/core';
import { XpoBoardOptions, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { NumericEditorComponent, XpoAgGridColumns, XpoInlineEditingBase } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EquipmentService } from '../services/equipment.service';
import { DolliesTemplate } from './dollies-template';
import { StatusEnum } from './models/status.enum';
import { DolliesDataSourceService } from './services/dollies-data-source.service';

export interface TotalDRS {
  am: number;
  pm: number;
}

@Component({
  selector: 'app-dollies',
  templateUrl: './dollies.component.html',
  styleUrls: ['./dollies.component.scss'],
  providers: [DolliesDataSourceService],
})
export class DolliesComponent extends XpoInlineEditingBase implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();
  gridApi: GridApi;
  columnApi: ColumnApi;
  gridData: any;
  keyField: string;
  viewTemplates: DolliesTemplate[];
  gridOptions: GridOptions;
  pmColumnDefs: any[];
  columnDefs: any[];
  views: XpoBoardView[];
  isEditing: boolean;
  available_count: number = 0;
  city_count: number = 0;
  out_count: number = 0;
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
  };

  constructor(public dataSource: DolliesDataSourceService, private equipmentService: EquipmentService) {
    super();
    this.equipmentService.title = 'Dollies';
    this.isEditing = false;
    this.keyField = 'dollyNum';

    this.dataSource.pageSize = 10000;

    this.dataSource
      .globalFilterChanges()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    this.dataSource.publicState$.pipe(takeUntil(this.unsubscribe)).subscribe((newState) => {
      if (newState.data && newState.data.consumerData) {
        this.gridData = newState.data.consumerData;
        const count = this.gridData.reduce(
          (counter, { status }) => {
            counter[status] += 1;
            return counter;
          },
          { AVAL: 0, CITY: 0 }
        );
        this.available_count = count.AVAL;
        this.city_count = count.CITY;
      }
    });

    this.columnDefs = [
      XpoAgGridColumns.RowIndex,
      {
        headerName: 'Dolly',
        field: 'dollyNum',
        maxWidth: 180,
        suppressMenu: true,
        cellStyle: { color: '#2196F3' },
      },
      {
        headerName: 'Domicile SIC',
        field: 'domicileSic',
        maxWidth: 200,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.domicileSic && params.data.domicileSic.trim() !== '') {
            return params.data.domicileSic;
          }
          return '&nbsp';
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        maxWidth: 170,
        suppressMenu: true,
        valueGetter: (params) => {
          if (params.data) {
            return StatusEnum[params.data.status];
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Maintenance',
        field: 'maintenance',
        maxWidth: 170,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.maintenance === 0) {
            return '0';
          }
          return (params.data && params.data.maintenance) || '&nbsp;';
        },
      },
    ];

    this.gridOptions = {
      floatingFilter: false,
      enableRangeSelection: true,
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditorComponent,
      },
      pinnedBottomRowData: [],
    };
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'dollies',
        name: 'Dollies',
        allowAdditional: true,
        keyField: 'dollyNum',
        availableColumns: this.columnDefs,
      }),
    ];
  }

  ngOnInit() {
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = [
      this.viewTemplates[0].createView({
        closeable: false,
        criteria: {},
        id: 'dollies',
        name: 'Dollies',
      }) as any,
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onGridReady(params: AgGridEvent) {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.refreshCells();
    this.columnApi = params.columnApi;
  }
}
