<div class="app-DialList-wrapper"
    *ngIf="dialList">
  <button *ngFor="let dial of dialList"
      class="app-DialList-dial"
      [class.app-DialList-dial--selected]="dial.alertName === selectedDial"
      (click)="handleDialClick(dial)">
    <h2>{{dial.alertNumber}}</h2>
    <p>{{dial.alertName}}</p>
  </button>
</div>
