import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { LoadedTrailerSummary } from '../../../../../load-management/allowable-loads/trailer-details-inside/selected-trailer-info/selected-trailer-info.model';
import { ProNbrFormatPipe } from '../../../../formatters/proNbr-pipe';
import { ImageGalleryService } from '../image-gallery-container-service.component';
import { SpecialServiceFormatterComponent } from './../formatters/special-service-formatter';

@Component({
  selector: 'app-related-pros',
  templateUrl: './related-pros.component.html',
  styleUrls: ['./related-pros.component.scss'],
})
export class RelatedProsComponent implements OnInit, OnDestroy {
  @Input() relatedProsList: any;
  @Input() containerData: LoadedTrailerSummary;
  private gridApi: GridApi;
  private colApi: ColumnApi;
  sizeToFit: boolean = true;
  imageSubscription: Subscription;
  private proNbrPipe: ProNbrFormatPipe;

  frameworkComponents = {
    SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
  };

  gridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    suppressDragLeaveHidesColumns: true,
    frameworkComponents: this.frameworkComponents,
    headerHeight: 40,
  };

  columnDefs = [
    {
      headerName: 'Pro #',
      field: 'proNbr',
      width: 110,
      minWidth: 110,
      suppressSizeToFit: false,
      valueGetter: (params) => {
        const proNbrWithSpace = this.proNbrPipe.transform(params.data.proNbr);
        return proNbrWithSpace;
      },
      suppressMenu: true,
    },
    {
      headerName: 'Origin',
      field: 'originSic',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Destination',
      field: 'destSic',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'MM',
      field: 'motorMoves',
      width: 100,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Weight',
      field: 'weight',
      width: 115,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Util.(%)',
      field: 'utilization',
      width: 110,
      suppressSizeToFit: false,
      suppressMenu: true,
    },
    {
      headerName: 'Date/Time',
      field: 'loadedTimestamp',
      width: 150,
      minWidth: 150,
      suppressSizeToFit: false,
      suppressMenu: true,
      valueGetter: (params) => {
        const date = moment(params.data.loadedTimestamp).format('YYYY-MM-DD HH:mm');
        const localToSicDate = this.formatDate(date, this.containerData.loadingSic);
        return params.data.loadedTimestamp ? localToSicDate : '';
      },
    },
    {
      headerName: 'Type',
      field: 'utilization',
      width: 120,
      valueGetter: (params) => {
        const { guaranteedInd, frzblInd, hazmatInd, misloadInd } = params.data;
        return { guaranteedInd, frzblInd, hazmatInd, misloadInd };
      },
      cellRenderer: 'SpecialServiceFormatterComponent',
      suppressSizeToFit: false,
      suppressMenu: true,
    },
  ];

  constructor(private imageGalleryService: ImageGalleryService, private timeService: XpoLtlTimeService) {}

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  ngOnInit() {
    this.proNbrPipe = new ProNbrFormatPipe();
    this.imageSubscription = this.imageGalleryService.sidePanelStatus$.subscribe(() => {
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 0);
    });
  }

  @HostListener('window:resize', ['$event'])
  sizeChange() {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onGridReady(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
    this.sizeChange();
  }
  // Format the date and time local to the SIC
  formatDate(date, sic) {
    return this.timeService.formatDate(date, 'YYYY-MM-DD HH:mm', sic);
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }
}
