import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface ClickableCellRendererParams extends ICellRendererParams {
  handleClick: (data: string) => void;
}
@Component({
  selector: 'app-clickable-cell-renderer',
  templateUrl: './clickable-cell-renderer.component.html',
  styleUrls: ['./clickable-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickableCellRendererComponent implements ICellRendererAngularComp {
  value: string | number;

  handleClick: (data: string | number) => void;

  refresh(params: ClickableCellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  agInit(params: ClickableCellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ClickableCellRendererParams): void {
    this.value = params.value;
    this.handleClick = params.handleClick;
  }
}
