<div class="preview-image-container">
  <h1 mat-dialog-title>
    <div class="title">
      <mat-icon class="closeDialogArrow" (click)="closeDialog()" data-test="closePreviewButton"
        >keyboard_arrow_left
      </mat-icon>
      <span data-test="previewTrailerNbrLabel">Trailer #{{ trailerData.trailerNbr }} Photos</span>
    </div>
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <ngx-gallery
    (change)="onChangeImage($event)"
    [options]="galleryOptions"
    [images]="dmsGalleryImages"
    (imagesReady)="imagesReady()"
    #ngxGallery
  ></ngx-gallery>
  <div class="donwloadAll" *ngIf="panelOpts.allowDownloadAll">
    <xpo-download-button (click)="downloadAll()" data-test="previewDownloadAllButton">
      <mat-icon class="downloadIcon">vertical_align_bottom</mat-icon>Download All Photos
    </xpo-download-button>
  </div>

  <div class="buttonsContainer">
    <div class="loadPhoto">
      <span class="photoType" data-test="previewPhotoTypeLabel"> {{ photoType }} </span>
      <span class="photoHourDate" data-test="previewPhotoDescLabel"> {{ photoDesc }} </span>
      <span class="trailerWeight" data-test="previewWeightLabel"><b>Weight:</b> {{ trailerData.weight }} lbs</span>
      <span class="trailerCube" data-test="previewCubeLabel"><b>Cube:</b> {{ trailerData.cubeUtilizationPct }}%</span>
    </div>

    <div class="imageDescButtons">
      <div class="proNbrs" *ngIf="panelOpts.allowProsList">
        <span data-test="previewProsLabel">PRO #'s</span>
        <mat-icon>list_alt</mat-icon>
        <div class="proNbrsToolTip">
          <ul>
            <li *ngFor="let toolItms of toolTipPros">{{ toolItms.proNbr }}</li>
          </ul>
        </div>
      </div>
      <div
        class="sendEmail"
        (click)="handleEmailClick()"
        data-test="previewEmailButton"
        *ngIf="panelOpts.allowSendEmail"
      >
        <span data-test="previewEmailLabel">Email</span>
        <mat-icon>email</mat-icon>
      </div>
      <xpo-download-button (click)="downloadThis()" data-test="previewDownloadButton" *ngIf="panelOpts.allowDownload">
        Download
        <mat-icon class="downloadIcon"> vertical_align_bottom</mat-icon>
      </xpo-download-button>
    </div>
  </div>
  <div class="hiddenDiv" #hiddenDivPrev cdkDrag (cdkDragEnded)="onDragEnded($event)">
    <img id="hiddenImg" width="100%" height="100%" #hiddenImgPrev />
  </div>
  <div *ngIf="loadingDMSImages" class="app-Notification">
    <div class="app-Notification-content">
      <mat-progress-spinner mode="indeterminate" [strokeWidth]="5" [diameter]="60" class="app-Notification-spinner">
      </mat-progress-spinner>
      <div class="app-Notification-message">Loading...</div>
    </div>
  </div>
</div>
