import { Directive, ElementRef, HostListener } from '@angular/core';
import { TimeInputParserHelper } from './time-input-parser-helper';

@Directive({ selector: 'input[timeInputFormatter]' })
export class TimeInputFormatterDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('input', ['$event'])
  handleChange(value: InputEvent): void {
    if (value.inputType !== 'deleteContentBackward') {
      // only allow numbers and 1 colon
      let newValue = (<HTMLInputElement>value.target).value.replace(/[^0-9:]/g, '').replace(/:.*?(:)/g, ':');
      const valueWithOutColon = newValue.replace(':', '');

      if (
        valueWithOutColon?.length >= 4 ||
        (newValue && !['0', '1', '2'].some((v) => v === newValue[0]) && valueWithOutColon.length >= 3)
      ) {
        newValue = TimeInputParserHelper.parseTime(newValue, true);
      }

      this.el.value = newValue;
    }
  }
}
