import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InteractionService } from '../shared/services/interaction.service';

@Component({
  selector: 'xpo-footer',
  templateUrl: './xpo-footer.component.html',
  styleUrls: ['./xpo-footer.component.scss'],
})
export class XpoFooterComponent implements OnInit, OnDestroy {
  readonly defaultMailLink = 'mailto:edge.linehaul@xpo.com?subject=Edge Linehaul Feedback';
  mailToLink: string;

  @Input() buildVersion: string;
  @Input() trainingResourcesLink: string;
  @Input() releaseNotesLink: string;

  private unsubscriber: Unsubscriber = new Unsubscriber();

  constructor(private interactionService: InteractionService) {}

  ngOnInit() {
    this.mailToLink = this.defaultMailLink;
    this.filterWatcher();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  private filterWatcher() {
    combineLatest([this.interactionService.sic, this.interactionService.shift]) // replace with linehaul state service when ready
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe(([sic, shift]) => {
        this.mailToLink = this.defaultMailLink;
        if (typeof sic === 'string') {
          this.mailToLink += ` - ${sic || ''}`;
        }
        if (typeof shift === 'string') {
          this.mailToLink += ` - ${shift || ''}`;
        }
      });
  }
}
