<ngx-gallery
  (change)="onChangeImage($event)"
  [options]="galleryOptions"
  [images]="dmsGalleryImages"
  (imagesReady)="imagesReady()"
  #ngxGallery
></ngx-gallery>
<div class="trailerDataSection loadPhotosResultSection">
  <div class="trailerDataSectionContainer">
    <xpo-card-content>
      <div>
        <div class="trailerDataResults">
          <div>
            <p class="labelResult" data-test="fullGalleryLoadingLabel">{{ ImageSearchRecordLabels.Loading }}</p>
            <p class="dataResult">{{ containerData.loadingSic }}</p>
          </div>
          <div>
            <p class="labelResult" data-test="fullGalleryDestSicLabel">
              {{ ImageSearchRecordLabels.LoadDestinationSic }}
            </p>
            <p class="dataResult">{{ containerData.loadDestinationSic }}</p>
          </div>
          <div>
            <p class="labelResult" data-test="fullGalleryShiftLabel">{{ ImageSearchRecordLabels.Shift }}</p>
            <p class="dataResult">{{ containerData.shift }}</p>
          </div>
        </div>

        <div class="trailerDataResults">
          <div>
            <p class="labelResult" data-test="fullGalleryDoorLabel">{{ ImageSearchRecordLabels.Door }}</p>
            <p class="dataResult">{{ containerData.door }}</p>
          </div>
          <div>
            <p class="labelResult" data-test="fullGalleryStatusLabel">{{ ImageSearchRecordLabels.Status }}</p>
            <p class="dataResult">{{ trailerStatus }}</p>
          </div>
          <div>
            <p class="labelResult" data-test="fullGalleryShipmentsLabel">{{ ImageSearchRecordLabels.Shipments }}</p>
            <p class="dataResult">{{ totalShipmentsCount }}</p>
          </div>
        </div>
      </div>
      <mat-grid-list class="summaryMetrics" cols="2" rowHeight="43px">
        <mat-grid-tile colspan="1" rowspan="4">
          <div class="circle-info">
            <div class="circle-text">
              <p class="circle-text-number">{{ containerData.cubeUtilizationPct | number: '.0-2' }}%</p>
              <p class="circle-text-title" data-test="fullGalleryUtilizationLabel">
                {{ ImageSearchRecordLabels.Utilization }} %
              </p>
              <p class="circle-text-unit">({{ ImageSearchRecordLabels.UtilizationUnit }})</p>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="4">
          <div class="circle-info borderXpoBlue">
            <div class="circle-text">
              <p class="circle-text-number">{{ containerData.weight | number: '.0-3' }}</p>
              <p class="circle-text-title" data-test="fullGallerySWeightLabel">{{ ImageSearchRecordLabels.Weight }}</p>
              <p class="circle-text-unit">({{ ImageSearchRecordLabels.WeightUnit }})</p>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </xpo-card-content>

    <div class="closedBy" *ngIf="trailerStatus === 'Close For Linehaul'">
      <div class="employeePic">
        <mat-icon>supervised_user_circle</mat-icon>
      </div>
      <div class="employeeName">
        <p class="labelResult" data-test="fullGalleryClosedLabel">{{ ImageSearchRecordLabels.ClosedBy }}</p>
        <p class="dataResult">{{ closedByEmployeeId || 'Not Available' }}</p>
      </div>
    </div>
    <div class="loadedBy" *ngIf="selectedImage.imageType === PhotoTypeCd.LOAD_PHOTO">
      <div class="employeePic">
        <mat-icon>supervised_user_circle</mat-icon>
      </div>
      <div class="employeeName">
        <p class="labelResult" data-test="loadedLabel">{{ ImageSearchRecordLabels.LoadedBy }}</p>
        <p class="dataResult">{{ employeeName || 'Not Available' }}</p>
      </div>
    </div>
  </div>
  <app-related-pros
    class="relatedPros"
    [relatedProsList]="relatedProsList"
    *ngIf="panelOpts.displayRelatedProsTable"
  ></app-related-pros>
</div>

<div class="buttonsContainer">
  <div class="loadPhoto">
    <span class="photoHourDate" data-test="fullGalleryPhotoDescLabel"> {{ photoDesc }} </span>
    {{ photoType }}
  </div>
  <div class="imageDescButtons">
    <div
      class="sendEmail"
      (click)="handleEmailClick()"
      data-test="fullGalleryEmailButton"
      *ngIf="panelOpts.allowSendEmail"
    >
      <span data-test="fullGalleryEmailLabel">Email</span>
      <mat-icon>email</mat-icon>
    </div>

    <xpo-download-button (click)="downloadThis()" data-test="fullGalleryDownloadButton" *ngIf="panelOpts.allowDownload">
      Download
      <mat-icon class="downloadIcon"> vertical_align_bottom</mat-icon>
    </xpo-download-button>
  </div>
</div>

<div class="hiddenDiv" #hiddenDiv cdkDrag (cdkDragEnded)="onDragEnded($event)">
  <img id="hiddenImg" width="100%" height="100%" #hiddenImg />
</div>

<div *ngIf="loadingDMSImages" class="app-Notification">
  <div class="app-Notification-content">
    <mat-progress-spinner mode="indeterminate" [strokeWidth]="5" [diameter]="60" class="app-Notification-spinner">
    </mat-progress-spinner>
    <div class="app-Notification-message">Loading...</div>
  </div>
</div>
