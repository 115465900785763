<div class="notAlternate">
  <div *ngIf="showShipment" class="shipment">
    <shipment-details-overview
      [hist]="histReq"
      [shipment]="shipmentData"
      [alert]="alert.alert"
      [lineItemsAlert]="alert"
    ></shipment-details-overview>
  </div>
  <div class="no-shipment" *ngIf="!showShipment && statusCheck">
    No shipment found
  </div>
  <history [exception]="alert"></history>
  <acknowledge-tab
    *ngIf="showAck"
    [allowFalsePositive]="isAllowedToFalsePositive && !isAllowedAck"
    [status]="status"
    [type]="type"
    [alert]="alert"
  >
  </acknowledge-tab>
</div>
