<div class="filter-wrap">
  <label for="shift">{{ label }}</label>
  <mat-select id="shift"
      data-test="shiftFilter"
      #shiftVal
      [ngModel]="shift$ | async"
      (ngModelChange)="changeShift(shiftVal.value)">
    <mat-option value="Outbound"> Outbound</mat-option>
    <mat-option value="FAC"
        disabled>FAC</mat-option>
  </mat-select>
</div>
