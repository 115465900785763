import { DecimalPipe } from '@angular/common';
import { map as _map } from 'lodash';
import { IconsKeys } from './../../../shared/enums/icons.enum';
import { AllowableLoadsTableEnum } from './../enums/allowable-loads-tables.enum';

export function loadsTableAvailableColumns(loadsViewBoardTemplate, decimalPipe: DecimalPipe) {
  return [
    {
      headerClass: 'centerHeader',
      valueGetter: (params) => {
        return !!params.data.equipmentInstId && params.data.loadedWeight > 0 ? IconsKeys.description : null;
      },
      sortable: false,
      cellRenderer: 'IconsFormatterComponent',
      cellClass: 'iconColumn',
      maxWidth: 50,
    },
    {
      headerClass: 'centerHeader',
      cellClass: 'iconColumn',
      valueGetter: function getPhotosData() {
        return IconsKeys.photoCamera;
      },
      sortable: false,
      cellRenderer: 'IconsFormatterComponent',
      maxWidth: 50,
      // Cell event triggers and provides a new value to the Behavior Subject
      onCellClicked: (params) => {
        loadsViewBoardTemplate._clickSubject.next(params.data);
      },
    },
    {
      headerName: AllowableLoadsTableEnum.Lane,
      field: 'lane',
      minWidth: 90,
    },
    {
      headerName: AllowableLoadsTableEnum.CloseTo,
      field: 'closeToSicCd',
      minWidth: 90,
    },
    {
      headerName: AllowableLoadsTableEnum.SpecialService,
      sortable: false,
      minWidth: 120,
      valueGetter: (params) => {
        const { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd } = params.data;
        return { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd };
      },
      cellRenderer: 'SpecialServiceFormatterComponent',
    },
    {
      headerName: AllowableLoadsTableEnum.Load,
      field: 'load',
      type: 'numericColumn',
      minWidth: 110,
    },
    {
      headerName: AllowableLoadsTableEnum.Bypass,
      // waiting for the field to be added to the SDK
      field: 'bypassInd',
      maxWidth: 100,
      cellClass: 'bypassCell',
      valueGetter: (params) => {
        return params.data.bypassInd ? IconsKeys.bypass : params.data.ptlTrlrInd ? IconsKeys.ptl : '';
      },
      cellRenderer: 'IconsFormatterComponent',
    },
    {
      headerName: AllowableLoadsTableEnum.DoorNbr,
      field: 'eventDoor',
      type: 'numericColumn',
      maxWidth: 100,
      valueGetter: (params) => {
        const convert = parseInt(params.data.eventDoor, 10);
        return !isNaN(convert) ? convert : '';
      },
    },
    {
      headerName: AllowableLoadsTableEnum.LoadedWeight,
      minWidth: 175,
      field: 'loadedWeight',
      type: 'numericColumn',
      cellRenderer: 'LoadedWeightFormatterComponent',
      valueGetter: (params) => {
        return params.data.loadedWeight;
      },
    },
    {
      headerName: AllowableLoadsTableEnum.LoadedCube,
      minWidth: 155,
      field: 'loadedCube',
      valueGetter: (params) => {
        return decimalPipe.transform(params.data.loadedCube, '.0-2');
      },
      type: 'numericColumn',
    },
    {
      headerName: AllowableLoadsTableEnum.Source,
      field: 'sourceCd',
      minWidth: 100,
    },
    {
      headerName: AllowableLoadsTableEnum.Status,
      field: 'statusCd',
      cellRenderer: 'StatusFormatterComponent',
      cellClass: 'statusColumn',
      minWidth: 200,
    },
  ];
}
