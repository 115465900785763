import { Component, EventEmitter, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { EquipmentService } from '../../services/equipment.service';

@Component({
  selector: 'app-sic-filter',
  templateUrl: './sic-filter.component.html',
  styleUrls: ['./sic-filter.component.scss'],
})
export class SicFilterComponent {
  label = 'SERVICE CENTER';
  sics = [];
  @Output() sicSelected = new EventEmitter<string>();

  constructor(private equipmentService: EquipmentService) {
    this.equipmentService.getAllSICs().subscribe((res) => (this.sics = res.serviceCenters));
  }

  onSelect(event: MatSelectChange): void {
    this.sicSelected.emit(event.value);
  }
}
