<div class="cls-container">
  <div class="cls-subcontainer">
    <div class="cls-subtitle">
      <b>Close comment by requestor:</b>
    </div>
    <mat-form-field *ngIf="comment">
      <input matInput placeholder="{{ comment }}" disabled />
    </mat-form-field>
  </div>
  <div class="mat-dialog-actions">
    <button mat-flat-button (click)="close()">Close</button>
  </div>
</div>
