import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashFormatterPipe } from './dash-formatter.pipe';
import { Sign } from './sign.pipe';

const formatters = [DashFormatterPipe, Sign];

@NgModule({
  declarations: [...formatters],
  imports: [CommonModule],
  exports: [...formatters],
  providers: [Sign],
})
export class PipesModule {}
