<div *ngIf="modelData.results">
  <div class="count-table-container"
      *ngIf="modelData.instructions">
    <table mat-table
        [dataSource]="data"
        class="app-LaneBreakdown--fullWidthTable">
      <!-- Category Column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell
            *matHeaderCellDef
            class="xpo-MatTable--borderRight">CATEGORY</th>
        <td mat-cell
            *matCellDef="let element"
            class="xpo-MatTable--borderRight app-LaneBreakdown--boldCell">{{
          element.category }}</td>
      </ng-container>

      <!-- Planned Column -->
      <ng-container matColumnDef="planned">
        <th mat-header-cell
            *matHeaderCellDef
            class="xpo-MatTable-cell--textAlignRight xpo-MatTable--borderRight">
          PLANNED</th>
        <td mat-cell
            *matCellDef="let element"
            class="xpo-MatTable-cell--textAlignRight xpo-MatTable--borderRight">{{
          element.planned }}</td>
      </ng-container>

      <!-- Loading/Actual Column -->
      <ng-container matColumnDef="loadingactual">
        <th mat-header-cell
            *matHeaderCellDef
            class="xpo-MatTable-cell--textAlignRight xpo-MatTable--borderRight">
          LOADING/ACTUAL</th>
        <td mat-cell
            *matCellDef="let element"
            class="xpo-MatTable-cell--textAlignRight xpo-MatTable--borderRight">{{
          element.loadingactual }}</td>
      </ng-container>

      <!-- Remaining Column -->
      <ng-container matColumnDef="remaining">
        <th mat-header-cell
            *matHeaderCellDef
            class="xpo-MatTable-cell--textAlignRight">REMAINING</th>
        <td mat-cell
            [class.app-LaneBreakdown--warningCell]="element.isWarning"
            *matCellDef="let element"
            class="xpo-MatTable-cell--textAlignRight"> {{ element.remaining }}</td>
      </ng-container>

      <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <h1 class="app-LaneBreakdown-trailersbylane__title">Trailers & Photos</h1>
  <trailers-by-lane class="app-LaneBreakdown-trailersbylane__table"
      *ngIf="modelData.laneData"
      [modelData]="modelData"
      [type]="type"
      [alert]="alert"></trailers-by-lane>
</div>
<div class="app-LaneBreakdown-searching"
    *ngIf="modelData.searching">
  <mat-progress-spinner mode="indeterminate"
      [strokeWidth]="5"
      [diameter]="60"
      class="app-Notification-spinner">
  </mat-progress-spinner>
  <div class="app-Notification-message">Loading...</div>
</div>
