<app-alert-and-exception-header [alertsAndExceptions]="alertsAndExceptions$ | async"
    [hssChecked]="includeHSS$ | async"
    [showAllAlerts]="showAllAlerts$ | async"
    [roleView]="roleView$ |async"
    (typeSelected)="handleTypeSelection($event)"
    (hssCheckedChange)="includeHSS$.next($event)"
    (showAllAlertsChange)="showAllAlerts$.next($event)"
    (roleViewChange)="roleView$.next($event)">
</app-alert-and-exception-header>
<app-dial-list [dialList]="dialList$ | async"
    [selectedDial]="selectedDial"
    (selectedDialChange)="dialSelect($event)"></app-dial-list>
<app-alert-grid *ngIf="boardQuery$ | async as boardQuery"
    [query]="boardQuery"></app-alert-grid>
<side-panel-wrapper> </side-panel-wrapper>
