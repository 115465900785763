import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';

@Component({
  selector: 'app-schedule-cell-renderer',
  templateUrl: './schedule-cell-renderer.component.html',
  styleUrls: ['./schedule-cell-renderer.component.scss'],
})
export class ScheduleCellRendererComponent implements ICellRendererAngularComp {
  driverName: string;
  cutTime: string;
  params: ICellRendererParams;

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.params = params;
    const { value, data } = this.params;
    this.driverName = value;
    this.cutTime = data?.cutTime && format(data?.cutTime, 'H:mm');
  }
}
