<div *ngIf="pageTitle || filters?.length"
    class="app-GlobalHeader-contentWrapper">
  <h1>{{ pageTitle }}</h1>
  <ng-container *ngFor="let filter of filters">
    <ng-container [ngSwitch]="filter">
      <app-period-filter *ngSwitchCase="'period'">
      </app-period-filter>
      <app-hierarchy-filter *ngSwitchCase="'hierarchy'"
          class="app-GlobalHeader-hierarchyFilter">
      </app-hierarchy-filter>
      <xpo-advanced-select *ngSwitchCase="'level'"
          placeholder="Location"
          [options]="locations$ | async"
          [selectedOptions]="defaultLocation$ | async"
          (selectedOptionsChange)="selectedLocations($event)">
      </xpo-advanced-select>
    </ng-container>
  </ng-container>
</div>
