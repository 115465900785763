import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import moment from 'moment';
import { LoadedTrailerSummary } from '../load-management/allowable-loads/trailer-details-inside/selected-trailer-info/selected-trailer-info.model';
import { ImageCarousel } from './image-carousel.model';

const imagesArray: ImageCarousel[] = [
  {
    id: 1,
    source:
      'https://images.unsplash.com/photo-1604432459508-cace733220cf?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max',
    name: 'Photo 2',
  },
];

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit {
  @Input() containerData: LoadedTrailerSummary;
  @Input('data') images: ImageCarousel[] = imagesArray;
  @Output() imageChange = new EventEmitter<number>();
  selectedImage: ImageCarousel;
  selectedIndex: number;
  cssAnimation = '';
  selectedImageDate: any;
  constructor(private timeService: XpoLtlTimeService) {}

  ngOnInit() {
    if (this.images.length) {
      this.selectImage(0);
    }
  }

  /**
   * Select image to show on the viewer
   */
  selectImage(index: number) {
    if (this.images[index]) {
      this.selectedIndex = index;
      this.selectedImage = this.images[index];
      const date = moment(this.selectedImage.date).format('YYYY-MM-DD HH:mm');

      this.selectedImageDate = this.formatDate(date, this.containerData.loadingSic);
      this.imageChange.emit(this.selectedImage.id);
    }
  }

  // Format the date and time local to the SIC
  formatDate(date, sic) {
    return this.timeService.formatDate(date, 'YYYY-MM-DD HH:mm', sic);
  }

  /**
   * Select next image
   */
  selectNext() {
    const nextIndex = this.selectedIndex + 1;

    if (this.images[nextIndex]) {
      this.selectImage(nextIndex);
    } else {
      this.selectImage(0);
    }

    this.setAnimation('slideRight');
  }

  /**
   * Select previous image
   */
  selectPrevious() {
    const previousIndex = this.selectedIndex - 1;

    if (previousIndex >= 0) {
      this.selectImage(previousIndex);
    } else {
      this.selectImage(this.images.length - 1);
    }

    this.setAnimation('slideLeft');
  }

  /**
   * Set the animation to the image
   */
  setAnimation(animation: string) {
    this.cssAnimation = animation;

    setTimeout(() => {
      this.cssAnimation = '';
    }, 1000);
  }
}
