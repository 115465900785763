import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TrailerLoadHist } from '@xpo-ltl/sdk-linehauloperations';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'special-services',
  templateUrl: './special-services.component.html',
  styleUrls: ['./special-services.component.scss'],
})
export class SpecialServicesComponent implements OnInit, OnChanges {
  @Input() trailerHist: TrailerLoadHist;
  indicators: IconsKeys[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.setIndicators();
  }

  private setIndicators() {
    const { guaranteedServiceInd, hazmatInd, frzblInd } = this.trailerHist;
    // tslint:disable-next-line:no-unused-expression
    guaranteedServiceInd && this.indicators.push(IconsKeys.guaranteed);
    // tslint:disable-next-line:no-unused-expression
    hazmatInd && this.indicators.push(IconsKeys.hazmat);
    // tslint:disable-next-line:no-unused-expression
    frzblInd && this.indicators.push(IconsKeys.freezable);
  }
}
