import { ChangeDetectionStrategy, Component, Injector, OnDestroy } from '@angular/core';

import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DrawerLauncherService } from '../../../core/drawer-launcher/drawer-launcher.service';
import { SchedulesGridItem, ViaDetails } from '../../schedules-grid.model';
import { ViaDetailsDrawerComponent, ViaDetailsDrawerData } from '../../via-details-drawer/via-details-drawer.component';

export interface ViaDetailsCellRendererParams extends ICellRendererParams {
  isOutbound?: boolean;
}
@Component({
  selector: 'app-via-details-cell-renderer',
  templateUrl: './via-details-cell-renderer.component.html',
  styleUrls: ['./via-details-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaDetailsCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  sicCd: string;
  sicDescription: string;
  viaCount: number;
  viaDetails: ViaDetails;
  isOutbound: boolean = false;

  private unsubscribe$: Unsubscriber = new Unsubscriber();

  constructor(private drawerLauncherService: DrawerLauncherService, private injector: Injector) {}

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  refresh(params: ViaDetailsCellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);

    return true;
  }
  agInit(params: ViaDetailsCellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  launchDialog(): void {
    this.drawerLauncherService.launch({
      component: ViaDetailsDrawerComponent,
      destroyDrawer$: this.unsubscribe$.done$,
      injector: this.injector,
      data: <ViaDetailsDrawerData>{
        sicCd: this.sicCd,
        sicDescription: this.sicDescription,
        viaDetails: this.viaDetails,
        selectedTab: this.isOutbound ? 'outbound' : 'inbound',
      },
    });
  }

  private mapParamsToTemplateValues(params: ViaDetailsCellRendererParams): void {
    const data: SchedulesGridItem = params.data;

    this.viaCount = params.value;
    this.sicCd = data.sicCd;
    this.viaDetails = data.viaDetails;
    this.sicDescription = data.sicCdDescription;
    this.isOutbound = params.isOutbound;
  }
}
