<div class="vertical-separator"></div>
<form class="sic-filter">
  <mat-form-field floatLabel="never">
    <div class="selected-options"></div>
    <input (keyup.enter)="onEnter()"
        type="text"
        placeholder="Select Sic"
        aria-label="Number"
        matInput
        [formControl]="myControl"
        [matAutocomplete]="auto"
        spellcheck="false"
        data-test="sic-filter"
        maxlength="3" />
    <mat-icon matSuffix
        (click)="closeAutocomplete($event)">arrow_drop_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete"
        (optionSelected)="setInputAction()">
      <mat-optgroup *ngFor="let option of filteredOptions | async">
        <mat-option *ngFor="let sic of option.sicList"
            [value]="sic.sicShowed">
          {{ sic.sicShowed }}
        </mat-option>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</form>
