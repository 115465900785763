import { Component, OnInit } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';
import { InteractionService } from '../../../services/interaction.service';

@Component({
  selector: 'app-hss-table-header',
  templateUrl: './hss-table-header.component.html',
  styleUrls: ['./hss-table-header.component.scss'],
})
export class HssTableHeaderComponent implements OnInit, IHeaderGroupAngularComp {
  params: IHeaderGroupParams;
  cubeFilterThreshold: string = '80';
  toogleBypassButton: boolean = false;
  constructor(private interactionService: InteractionService) {}

  agInit(params: IHeaderGroupParams): void {
    this.params = params;
  }

  ngOnInit() {
    this.interactionService.setHideCubeAbove80(this.toogleBypassButton);
  }

  showOnlyBypass() {
    this.toogleBypassButton = !this.toogleBypassButton;
    if (this.toogleBypassButton) {
      this.setCubeFilter();
    } else {
      this.clearFilters();
    }
    this.interactionService.setHideCubeAbove80(this.toogleBypassButton);
  }

  setCubeFilter() {
    this.params.api.setFilterModel({
      cube: {
        type: 'lessThan',
        filter: this.cubeFilterThreshold,
      },
    });
  }

  clearFilters() {
    this.params.api.setFilterModel(null);
  }
}
