<div class="side-panel">
  <ngx-gallery #ngxGallery
      [options]="galleryOptions"
      [images]="galleryImages"
      (imagesReady)="afterImagesReady()"
      (change)="imageChange($event)">
  </ngx-gallery>
  <p class="imageType">{{ photoStatus }}</p>
  <mat-divider class="divider top-spacing"></mat-divider>
  <div class="trailerDataResults">
    <div>
      <p class="labelResult"
          data-test="loadingLabel">{{ ImageSearchRecordLabels.Loading }}</p>
      <p class="dataResult">{{ containerData.loadingSic }}</p>
    </div>
    <div>
      <p class="labelResult"
          data-test="destSicLabel">{{ ImageSearchRecordLabels.LoadDestinationSic }}</p>
      <p class="dataResult">{{ containerData.loadDestinationSic }}</p>
    </div>
    <div>
      <p class="labelResult"
          data-test="shiftLabel">{{ ImageSearchRecordLabels.Shift }}</p>
      <p class="dataResult">{{ containerData.shift }}</p>
    </div>
    <div>
      <p class="labelResult"
          data-test="doorLabel">{{ ImageSearchRecordLabels.Door }}</p>
      <p class="dataResult">{{ containerData.door }}</p>
    </div>
    <div>
      <p class="labelResult"
          data-test="statusLabel">{{ ImageSearchRecordLabels.Status }}</p>
      <p class="dataResult">{{ trailerStatus === 'Close For Linehaul' ? 'Closed' : trailerStatus }}</p>
    </div>
    <div>
      <p class="labelResult"
          data-test="shipmentsLabel">{{ ImageSearchRecordLabels.Shipments }}</p>
      <p class="dataResult">{{ totalShipmentsCount }}</p>
    </div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <div class="summaryMetrics">
    <div class="circle-info">
      <div class="circle-text">
        <p class="circle-text-number">{{ containerData.cubeUtilizationPct | number: '.0-2' }}%</p>
        <p class="circle-text-title"
            data-test="utilizationLabel">
          {{ ImageSearchRecordLabels.Utilization }} % ({{ ImageSearchRecordLabels.UtilizationUnit }})
        </p>
      </div>
    </div>
    <div class="circle-info borderXpoBlue">
      <div class="circle-text">
        <p class="circle-text-number">{{ containerData.weight | number: '.0-3' }}</p>
        <p class="circle-text-title"
            data-test="weightLabel">
          {{ ImageSearchRecordLabels.Weight }} ({{ ImageSearchRecordLabels.WeightUnit }})
        </p>
      </div>
    </div>
  </div>
  <div class="closedBy"
      *ngIf="trailerStatus === 'Close For Linehaul'">
    <div class="employeePic">
      <mat-icon>supervised_user_circle</mat-icon>
    </div>
    <div class="employeeName">
      <p class="labelResult"
          data-test="closedLabel">{{ ImageSearchRecordLabels.ClosedBy }}</p>
      <p class="dataResult">{{ closedByEmployeeId || 'Not Available' }}</p>
    </div>
  </div>
  <div class="loadedBy"
      *ngIf="selectedImage.imageType === PhotoTypeCd.LOAD_PHOTO">
    <div class="employeePic">
      <mat-icon>supervised_user_circle</mat-icon>
    </div>
    <div class="employeeName">
      <p class="labelResult"
          data-test="loadedLabel">{{ ImageSearchRecordLabels.LoadedBy }}</p>
      <p class="dataResult">{{ employeeName || 'Not Available' }}</p>
    </div>
  </div>

  <app-related-pros [relatedProsList]="relatedProsList"
      [containerData]="this.containerData"
      *ngIf="panelOpts.displayRelatedProsTable"></app-related-pros>
</div>
