import { ChangeDetectorRef, Directive, InjectionToken, Injector, OnDestroy, OnInit, Type } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LauncherLaunchData } from './launcher-data';
import { LauncherServiceBase } from './launcher-service-base';

export const LAUNCH_DATA = new InjectionToken<LauncherLaunchData>('LHOLaunchData');

@Directive()
export abstract class LauncherComponentBase implements OnInit, OnDestroy {
  launchedComponent: Type<any>;
  launchedComponentDataInjector: Injector;
  launchedData: LauncherLaunchData;

  private closeSubscription: Subscription;
  private componentDestroy$ = new Subject<void>();

  constructor(
    private launcherService: LauncherServiceBase,
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private launchDataToken: InjectionToken<any>,
    private launcherComponentType: Type<LauncherComponentBase>
  ) {}

  protected abstract handleOpen(): void;

  protected abstract handleClose(): void;

  ngOnInit(): void {
    this.launcherService.launchComponent$.pipe(takeUntil(this.componentDestroy$)).subscribe((x) => {
      if (x) {
        this.launchedData = x;
        this.launchedComponent = x.component;
        this.launchedComponentDataInjector = Injector.create({
          providers: [
            { provide: this.launchDataToken, useValue: x.data },
            // used to provide the launched component to a reference to the host component. they can call
            // launcher.close() from the component that was launched. (similar to MatDialogRef)
            { provide: this.launcherComponentType, useValue: this },
          ],
          parent: x.injector ?? this.injector,
        });
        this.startCloseRequestSubscription(x.destroyDrawer$);
        this.open();
      } else {
        this.close();
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.componentDestroy$.next();
    this.componentDestroy$.complete();

    this.destroyCloseSubscription();
  }

  open(): void {
    // Wanted to have a similar api to the close function, for now this function just calls handleOpen
    this.handleOpen();
  }

  close(results?: any): void {
    this.launchedData?.afterClosed?.(results);
    this.destroyCloseSubscription();
    this.launchedComponent = undefined;
    this.launchedComponentDataInjector = undefined;
    this.launchedData = undefined;

    this.handleClose();
  }

  private startCloseRequestSubscription(closeRequest$: Observable<any> | null): void {
    this.destroyCloseSubscription();

    if (closeRequest$) {
      this.closeSubscription = closeRequest$.pipe(take(1)).subscribe((x) => this.close());
    }
  }

  private destroyCloseSubscription(): void {
    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
    }
  }
}
