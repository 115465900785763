import { Injectable } from '@angular/core';
import { District, ListOperationalRegionsQuery, LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { UserRoleCd } from '@xpo-ltl/sdk-common';
import {
  EmployeeRole,
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { forEach as _forEach, isArray as _isArray } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import userPermissionList from '../../../assets/auth-users.json';
import { environment } from '../../../environments/environment';
import { ConfigManagerProperties } from '../enums/config-manager-properties.enum';
import { InteractionService } from './interaction.service';

@Injectable()
export class AuthService {
  perms;
  roles: Array<string> = [];
  cancelFac = userPermissionList['loadRequest'].cancelFac;
  hideHSSAlertsRoles = userPermissionList['hssToggle'].allowedRoles;
  lrActionRolesResponded = userPermissionList['loadRequest'].actionRolesResponded;
  ackAlertsActionRoles = userPermissionList['ackAlerts'].otherRoles;
  lhCoordinatorsRoles = userPermissionList['ackAlerts'].lhCoordinators;
  hssAlertsActionRoles = userPermissionList['ackAlerts'].hssRoles;
  progLoadActionRoles = userPermissionList['ackAlerts'].progLoadRoles;
  gMisloadActionRoles = userPermissionList['ackAlerts'].gMisloadRoles;
  loadRequestActionRoles = userPermissionList['loadRequest'].actionRoles;
  loadRequestShowRoles = userPermissionList['loadRequest'].showRoles;
  plannedReleasedRoles = userPermissionList['planned'].releasedRoles;
  plannedAlwaysRoles = userPermissionList['planned'].alwaysRoles;
  allow = { schedules: false, metrics: false, alerts: false };
  addLoadRequestRoles = userPermissionList['loadRequest'].addLoadRoles;
  disableSelectMultipleLocation = userPermissionList['performanceDashboard'].disableSelectMultipleLocation;
  superUserRoles = userPermissionList['performanceDashboard'].superUserRoles;
  authUserRoles = userPermissionList['performanceDashboard'].authorizedUser;
  searchToUseDashboard = userPermissionList['performanceDashboard'].searchToUseDashboard;
  sicUserRoles = userPermissionList['performanceDashboard'].sicUser;
  allowedToResubmitHss = userPermissionList['performanceDashboard'].allowedToResubmitHss;
  ITUserRoles = userPermissionList['performanceDashboard'].ITUserRoles;
  csrUserRoles = userPermissionList['performanceDashboard'].CSRUserRoles;
  lhUserRoles = userPermissionList['performanceDashboard'].lhUser;
  lhCoordinatorRol = userPermissionList['performanceDashboard'].lhCoordinator;
  viewJspBids = userPermissionList['jspBids'].view;
  editLocalJspBids = userPermissionList['jspBids'].editLocal;
  editDistrictJspBids = userPermissionList['jspBids'].editDistrict;
  editAnyJspBids = userPermissionList['jspBids'].editAny;
  editPlanningRoles = userPermissionList['planningSchedules'].edit;
  allowedToAssignManually = userPermissionList['schedules'].allowAssigning;
  employeeDetails;
  build: string;
  sicHSSToggle: boolean;
  private _sicHSSToggleSubject = new BehaviorSubject<boolean>(false);
  sicHSSToggle$ = this._sicHSSToggleSubject.asObservable();
  results: any;
  dnmRol = userPermissionList['performanceDashboard'].dnmUser;
  sic: string;
  domSic: String;

  constructor(
    private humanResourceApiService: HumanResourceApiService,
    private locationApiService: LocationApiService,
    private loggingApiService: LoggingApiService,
    private configManagerService: ConfigManagerService,
    private interactionService: InteractionService
  ) {
    this.interactionService.getDomSic().subscribe((sic) => {
      this.domSic = sic;
    });
  }

  setPerms(empId: string, userId: string, path?: string): Observable<any> {
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;

    return this.humanResourceApiService
      .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
      .pipe(
        tap((resp: GetEmployeeDetailsByEmpIdResp) => {
          if (resp && resp.employee) {
            this.setUserPermissions(resp, userId, path);
          }
        }),
        catchError((e) => {
          console.log(e);
          return of(e);
        })
      );
  }

  private logUserInfo(userId: string, path: string, sic: string): void {
    this.loggingApiService.info(
      `${userId} logged into Linehaul Op./${path} ${this.build} with roles: ${this.roles}`,
      sic,
      'Log In',
      'Loading User Info',
      'GET'
    );
  }

  /**
   * Hide HSS exceptions
   */
  isAllowedToHideHSS(): boolean {
    const intersection = this.hideHSSAlertsRoles.filter((element) => this.roles.includes(element));

    return !!intersection && !!intersection.length;
  }

  isAllowedToApprove(): boolean {
    const intersection = this.loadRequestActionRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToCloseLr(): boolean {
    const intersection = this.lrActionRolesResponded.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToSeeAlert(): boolean {
    const intersection = this.loadRequestShowRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToAddLoadRequest(): boolean {
    const intersection = this.addLoadRequestRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToAckHss(): boolean {
    const intersection = this.hssAlertsActionRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToAckProgLoad(): boolean {
    const dnmUser = this.isDnmUser();
    const allowedToAckProgLoad = this.progLoadActionRoles.some((element) => this.roles.includes(element));
    const ITUser = this.ITUserRoles.some((element) => this.roles.includes(element));
    return allowedToAckProgLoad || ITUser || dnmUser;
  }

  isAllowedToAckGmisload(): boolean {
    const dnmUser = this.isDnmUser();
    const allowedToAckGmisload = this.gMisloadActionRoles.some((element) => this.roles.includes(element));
    const ITUser = this.ITUserRoles.some((element) => this.roles.includes(element));
    return allowedToAckGmisload || ITUser || dnmUser;
  }

  isAllowedToApproveDeclineHss(): boolean {
    const lhCoordinator = this.lhCoordinatorRol.some((element) => this.roles.includes(element));
    const ITUser = this.ITUserRoles.some((element) => this.roles.includes(element));
    return lhCoordinator || ITUser;
  }

  isAllowedToResubmitCloseHss(): boolean {
    const dnmUser = this.isDnmUser();
    const allowedToResubmitHss = this.allowedToResubmitHss.some((element) => this.roles.includes(element));
    const ITUser = this.ITUserRoles.some((element) => this.roles.includes(element));
    return allowedToResubmitHss || ITUser || dnmUser;
  }

  isAllowedToAck(): boolean {
    const intersection = this.ackAlertsActionRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToFalsePositive(): boolean {
    const intersection = this.lhCoordinatorsRoles.filter((element) => this.roles.includes(element));
    return !!intersection && !!intersection.length;
  }

  isAllowedToSelectMultipleLocations(): boolean {
    const intersection = this.disableSelectMultipleLocation.filter((element) => this.roles.includes(element));
    return !intersection && !!intersection.length;
  }

  isSuperUser(): boolean {
    const intersection = this.superUserRoles.filter((element) => this.roles.includes(element));

    return !!intersection && !!intersection.length;
  }

  isAuthorizedUser(): boolean {
    return this.authUserRoles.some((element) => this.roles.includes(element));
  }

  isAllowToSeeEntireDashboard(): boolean {
    const intersection = this.searchToUseDashboard.filter((element) => this.roles.includes(element));
    return !intersection || !intersection.length;
  }

  //#region JSP Bids

  isAllowedToViewJspBids(): boolean {
    return this.viewJspBids.some((role) => this.roles.includes(role));
  }

  isAllowedToEditJspBids(localSic: string): Observable<boolean> {
    if (this.canEditAnyJspBids() || this.canEditLocalJspBids(localSic)) {
      return of(true);
    }

    return this.canEditDistrictJspBids(localSic);
  }

  isAllowedToEditPlanningSchedules(): boolean {
    return this.roles.some((role) => this.editPlanningRoles.includes(role));
  }

  private canEditAnyJspBids(): boolean {
    return this.roles.some((role) => this.editAnyJspBids.includes(role));
  }

  private canEditLocalJspBids(localSic: string): boolean {
    const hasRole = this.roles.some((role) => this.editLocalJspBids.includes(role));

    return hasRole && this.employeeDetails.basicInfo.deptSic === localSic;
  }

  private canEditDistrictJspBids(localSic: string): Observable<boolean> {
    const hasRole = this.roles.some((role) => this.editDistrictJspBids.includes(role));

    return hasRole
      ? this.locationApiService.listOperationalRegions(new ListOperationalRegionsQuery()).pipe(
          shareReplay(1),
          map((resp) => resp['operationalRegions'].some((r) => this.isSameDistrict(localSic, r.districts)))
        )
      : of(false);
  }

  private isSameDistrict(localSic: string, district: District[]): boolean {
    const userDistrict = district.find((d) => d.sicCds.some((s) => s === this.employeeDetails.basicInfo.deptSic));
    return userDistrict && userDistrict.sicCds.includes(localSic);
  }

  //#endregion

  allowedToSeePlannedAlways(): boolean {
    return this.plannedAlwaysRoles.some((element) => this.roles.includes(element));
  }

  allowedToSeePlannedOnRelease(): boolean {
    return this.plannedReleasedRoles.some((element) => this.roles.includes(element));
  }

  /**
   * Is allowed to Request Approval for NewSic HSS exception
   */
  allowedToRqsApprovalHSS(): boolean {
    const dnmUser = this.isDnmUser();
    const isItUser = this.ITUserRoles.some((element) => this.roles.includes(element)) && this.sicHSSToggle;
    const sicUser = this.sicUserRoles.some((element) => this.roles.includes(element));

    return sicUser || isItUser || dnmUser;
  }

  /** Return employeeId */
  getEmployeeId(): any {
    return this.employeeDetails && this.employeeDetails.basicInfo && this.employeeDetails.basicInfo.employeeId;
  }

  getEmployeeSicAndRoles(): any {
    const employeeSicAndRoles = { locationSic: this.employeeDetails.basicInfo.deptSic, roles: this.roles };
    return employeeSicAndRoles;
  }

  getEmployeeSic(): string {
    return this.employeeDetails && this.employeeDetails.basicInfo && this.employeeDetails.basicInfo.deptSic;
  }

  getUserRoleCd(): UserRoleCd {
    const roleCodes = [];
    roleCodes['59'] = UserRoleCd.FOM;
    roleCodes['165'] = UserRoleCd.CDS;
    roleCodes['60'] = UserRoleCd.FOS;
    roleCodes['91'] = UserRoleCd.SCM;
    roleCodes['509'] = UserRoleCd.DNM;
    roleCodes['291'] = UserRoleCd.FAC;
    roleCodes['88'] = UserRoleCd.RM;

    const userValidRoles = this.roles.filter((userRol) =>
      ['59', '60', '291', '91', '509', '165', '88'].includes(userRol)
    );

    let roleCd;
    for (let i = 0; i < this.roles.length && !roleCd; i++) {
      const role = userValidRoles[i];
      roleCd = roleCodes[role];
    }

    if (!roleCd) {
      roleCd = null;
    }

    return roleCd;
  }

  isAllowedToCancelLR(): boolean {
    const dnmUser = this.isDnmUser();
    const sicUser = this.sicUserRoles.some((element) => this.roles.includes(element));
    const ITUser = this.ITUserRoles.some((element) => this.roles.includes(element));
    const csrUser = this.csrUserRoles.some((element) => this.roles.includes(element));
    const facUser = this.cancelFac.some((element) => this.roles.includes(element));

    return sicUser || csrUser || ITUser || facUser || dnmUser;
  }

  isDnmUser(): boolean {
    return this.dnmRol.some((element) => this.roles.includes(element));
  }

  isLhUSerOrSicUser(): 'lh' | 'sic' {
    const lhUser = this.lhUserRoles.some((rol) => this.roles.includes(rol));
    const superUser = this.superUserRoles.some((rol) => this.roles.includes(rol));

    return lhUser || superUser ? 'lh' : 'sic';
  }

  isItOrSuperUser(): boolean {
    const itUser = this.ITUserRoles.some((rol) => this.roles.includes(rol));
    const superUser = this.superUserRoles.some((rol) => this.roles.includes(rol));

    return itUser || superUser ? true : false;
  }

  isAllowedToAssignSchedulesManually(): boolean {
    return this.allowedToAssignManually.some((rol) => this.roles.includes(rol));
  }

  private setUserPermissions(resp: GetEmployeeDetailsByEmpIdResp, userId: string, path: string): void {
    const mockRoles = sessionStorage.getItem('userRolesMock');

    if (mockRoles && !environment.production) {
      this.roles = mockRoles.split(',');
    } else {
      this.roles = resp.employee.roles
        ? resp.employee.roles
            .filter((role: EmployeeRole) => !role.expirationDate || moment().diff(role.expirationDate) < 0)
            .map((role: EmployeeRole) => role.role.roleId)
        : [];
      sessionStorage.setItem('userRolesMock', this.roles.join());
    }

    this.employeeDetails = resp.employee;
    this.sic = this.employeeDetails && this.employeeDetails.basicInfo && this.employeeDetails.basicInfo.deptSic;
    this.logUserInfo(userId, path, this.sic);
    console.log(
      `User ID: ${this.employeeDetails.basicInfo.employeeId} | User Roles: ${this.roles} | User SIC: ${this.employeeDetails.basicInfo.deptSic}`
    );

    this.sicHSSToggle = this.isLhUSerOrSicUser() === 'sic';
    this._sicHSSToggleSubject.next(this.sicHSSToggle);
  }
}
