import { EventEmitter, inject, Injectable, InjectFlags, Type } from '@angular/core';
import { LoadRequestReasonCode } from '@xpo-ltl/sdk-linehauloperations';
import { AsyncSubject, BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchyRegion } from '../../performance-dashboard/models/hierarchy-region';
import { ItemFlatNode } from '../../performance-dashboard/performance-dashboard-header/level-filter/level-filter.component';
import { ComponentsEnum } from '../enums/components.enum';
import { InteractionEventsEnum } from '../enums/interaction-events.enum';
import { GlobalFilters } from '../models/global-filters';
import {
  IInteractionServiceResp,
  InteractionServiceResp,
  LoadRequestPanelData,
} from '../models/interaction-service-response.model';
import { PerformanceDashboardFilters } from '../models/performance-dashboard-filter.model';

export class RootInjectorGuard {
  constructor(type: Type<any>) {
    /* tslint:disable:no-bitwise */
    const parent = inject(type, InjectFlags.Optional | InjectFlags.SkipSelf);
    /* tslint:enable:no-bitwise */
    if (parent) {
      throw Error(`[${type}]: trying to create multiple instances,
      but this service should be a singleton.`);
    }
  }
}
@Injectable({
  providedIn: 'root',
})
export class InteractionService extends RootInjectorGuard {
  constructor() {
    super(InteractionService);
  }
  refresh$ = new EventEmitter<any>();
  amount = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  globalFilters = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertFilters = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  metrics = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alerts = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  region = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  sic = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  planDate = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  shift = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  sidePanel = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  loadPanel = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertsList = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  alertLane = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  typeCd = new BehaviorSubject<String>('');
  loadRequestPanel = new BehaviorSubject<LoadRequestPanelData>(new LoadRequestPanelData());
  refresh = new BehaviorSubject<boolean>(false);
  sidePanelResult = new BehaviorSubject<boolean>(false);
  selectedTrailer = new BehaviorSubject<string>('');
  refreshAggridData = new BehaviorSubject<boolean>(false);
  destroyAggridTable = new BehaviorSubject<boolean>(false);
  updatedAlert = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  loadRequestReasonCodes = new BehaviorSubject<LoadRequestReasonCode[]>([new LoadRequestReasonCode()]);
  latestDate = new BehaviorSubject<String>('');
  route = new BehaviorSubject<String>('');
  routeHistory = new BehaviorSubject<String[]>(['']);
  domSic = new AsyncSubject<String>();
  all$;
  selectedAlertParentPro;
  showHSSExceptions$ = new BehaviorSubject<boolean>(true);
  hideCubeAbove80 = new BehaviorSubject<boolean>(false);
  selectedTab$ = new BehaviorSubject<number>(0);
  private performanceDashboardFilters = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  private hierarchyFilter$ = new BehaviorSubject<IInteractionServiceResp>(
    new InteractionServiceResp(InteractionEventsEnum.HIERARCHY_UPDATED, {
      hierarchy: HierarchyRegion.OPERATIONAL_REGION,
    })
  );
  private operationalLevelFilter$ = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  private locationFilter$ = new BehaviorSubject<IInteractionServiceResp>(new InteractionServiceResp());
  HssRegionSics$ = new BehaviorSubject<{}>({});
  showAllAlerts$ = new BehaviorSubject<boolean>(false);

  subscribeToComponent(component: ComponentsEnum): Observable<any> {
    switch (component) {
      case ComponentsEnum.PERFORMANCE_DASHBOARD:
        return this.performanceDashboardFilters.asObservable();
      case ComponentsEnum.REFRESH_DATA:
        return this.refresh.asObservable();
      case ComponentsEnum.REFRESH_AGGRID_DATA:
        return this.refreshAggridData.asObservable();
      case ComponentsEnum.DESTROY_AGGRID_TABLE:
        return this.destroyAggridTable.asObservable();
      case ComponentsEnum.SHIFT:
        return this.shift.asObservable();
      case ComponentsEnum.SIC:
        return this.sic.asObservable();
      case ComponentsEnum.REGION:
        return this.region.asObservable();
      case ComponentsEnum.PLAN_DATE:
        return this.planDate.asObservable();
      case ComponentsEnum.METRICS:
        return this.metrics.asObservable();
      case ComponentsEnum.GLOBAL_FILTERS:
        return this.globalFilters.asObservable();
      case ComponentsEnum.ALERT_FILTERS:
        return this.alertFilters.asObservable();
      case ComponentsEnum.SIDE_PANEL:
        return this.sidePanel.asObservable();
      case ComponentsEnum.LOAD_PANEL:
        return this.loadPanel.asObservable();
      case ComponentsEnum.AMOUNT:
        return this.amount.asObservable();
      case ComponentsEnum.ALERTS:
        return this.alertsList.asObservable();
      case ComponentsEnum.ALERT_LANE:
        return this.alertLane.asObservable();
      case ComponentsEnum.OPEN_PANEL:
        return this.loadRequestPanel.asObservable();
      case ComponentsEnum.LATEST_DATE:
        return this.latestDate.asObservable();
      case ComponentsEnum.SPANEL_RESULT:
        return this.sidePanelResult.asObservable();
      case ComponentsEnum.SELECTED_TRAILER:
        return this.selectedTrailer.asObservable();
      case ComponentsEnum.HIDE_CUBE_ABOVE_80:
        return this.hideCubeAbove80.asObservable();
      case ComponentsEnum.HIERARCHY_FILTER:
        return this.hierarchyFilter$.asObservable();
      case ComponentsEnum.OPERATIONAL_LEVEL_FILTER:
        return this.operationalLevelFilter$.asObservable();
      case ComponentsEnum.LOCATION_FILTER:
        return this.locationFilter$.asObservable();
      case ComponentsEnum.HSS_REGION_SICS:
        return this.HssRegionSics$.asObservable();
      case ComponentsEnum.SHOW_ALL_ALERTS:
        return this.showAllAlerts$.asObservable();

      case ComponentsEnum.ALL:
        this.all$ = combineLatest([this.metrics, this.alerts, this.globalFilters]);
        return this.all$;
    }
  }

  updateShowAlerts(showAlerts: boolean): void {
    this.showAllAlerts$.next(showAlerts);
  }

  sendPerformanceDashboardFilter(performanceDashboardFilters: PerformanceDashboardFilters, reason: string): void {
    const performanceDashboardFiltersUpdate = new InteractionServiceResp(reason, performanceDashboardFilters);
    this.performanceDashboardFilters.next(performanceDashboardFiltersUpdate);
  }

  sendHierarchyFilter(hierarchy: HierarchyRegion, reason: string): void {
    const hierarchyFilterUpdate = new InteractionServiceResp(reason, { hierarchy: hierarchy });
    this.hierarchyFilter$.next(hierarchyFilterUpdate);
  }

  sendLocationFilters(locationFilters: any[], reason: string): void {
    const locationFilterUpdate = new InteractionServiceResp(reason, { locationFilters: locationFilters });
    this.locationFilter$.next(locationFilterUpdate);
  }

  sendOpLevelFilter(opLevelFilters: ItemFlatNode[], reason: string): void {
    const operationalLevelFilterUpdate = new InteractionServiceResp(reason, { opLevelFilters: opLevelFilters });
    this.operationalLevelFilter$.next(operationalLevelFilterUpdate);
  }

  setHssRegionSics(hssRegionSics): void {
    this.HssRegionSics$.next(hssRegionSics);
  }

  sendSidePanelResult(sidePanelResult: boolean): void {
    this.sidePanelResult.next(sidePanelResult);
  }

  sendUpdatedAlert(updatedAlert: any): void {
    const alertUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERT_UPDATED, { updatedAlert });
    this.updatedAlert.next(alertUpdate);
  }

  refreshAGgridData(refresh: boolean): void {
    this.refreshAggridData.next(refresh);
  }

  destroyAGgridTable(refresh: boolean): void {
    this.destroyAggridTable.next(refresh);
  }

  refreshData(refresh: boolean): void {
    this.refresh.next(refresh);
  }

  sendDataSic(sic: string): void {
    const sicUpdate = new InteractionServiceResp(InteractionEventsEnum.SIC_UPDATED, { sic });
    this.sic.next(sicUpdate);
  }
  sendAlertLane(alertLane: string): void {
    const AlertLaneUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERT_LANE, { alertLane });
    this.alertLane.next(AlertLaneUpdate);
  }
  sendAlerts(alerts: any): void {
    const alertsUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERTS_LIST, { alerts });
    this.alertsList.next(alertsUpdate);
  }

  sendDataRegion(region: string): void {
    const regionUpdate = new InteractionServiceResp(InteractionEventsEnum.REGION_UPDATED, { region });
    // tslint:disable-next-line: no-unused-expression
    region && this.region.next(regionUpdate);
  }

  sendDataMetrics(metrics: string): void {
    const metricsUpdate = new InteractionServiceResp(InteractionEventsEnum.METRICS_UPDATED, { metrics });
    this.metrics.next(metricsUpdate);
  }

  sendDataShift(shift: string): void {
    const shiftUpdate = new InteractionServiceResp(InteractionEventsEnum.SHIFT_UPDATED, { shift });
    this.shift.next(shiftUpdate);
  }

  sendDataSidePanel(sidePanel: any): void {
    sidePanel.filterSic = this.sic.value;
    const sidePanelUpdate = new InteractionServiceResp(InteractionEventsEnum.SIDE_PANEL_UPDATED, { sidePanel });
    this.sidePanel.next(sidePanelUpdate);
  }

  sendDataLoadRequest(loadPanel: any, status: string): void {
    const loadPanelUpdate = new InteractionServiceResp(InteractionEventsEnum.LOAD_PANEL_UPDATED, { status, loadPanel });
    this.loadPanel.next(loadPanelUpdate);
  }

  closeLoadRequest(): void {
    const loadPanelUpdate = new InteractionServiceResp(InteractionEventsEnum.LOAD_PANEL_UPDATED, { status: 'CLOSE' });
    this.loadPanel.next(loadPanelUpdate);
  }

  setGlobalFilters(filters: GlobalFilters): void {
    const filtersUpdate = new InteractionServiceResp(InteractionEventsEnum.GLOBAL_FILTERS_UPDATED, filters);
    this.globalFilters.next(filtersUpdate);
    this.updateGlobalFilters(filters);
  }

  updateGlobalFilters(filters: GlobalFilters): void {
    this.sic.next(filters.sic as IInteractionServiceResp);
    this.shift.next(filters.shift as IInteractionServiceResp);
    this.region.next(filters.region as IInteractionServiceResp);
    this.planDate.next(filters.planDate as IInteractionServiceResp);
  }

  setAlertFilters(alert: any): void {
    const filtersUpdate = new InteractionServiceResp(InteractionEventsEnum.ALERT_FILTERS_UPDATED, alert);
    this.alertFilters.next(filtersUpdate);
  }

  setALAmount(amount: number, type): void {
    const lengthAmount = new InteractionServiceResp(type, { amount });
    this.amount.next(lengthAmount);
  }

  getGlobalFilters(): Observable<IInteractionServiceResp> {
    return this.globalFilters.asObservable();
  }

  getDomSic(): Observable<String> {
    return this.domSic.asObservable();
  }

  setDomSic(sic: String): void {
    this.domSic.next(sic);
    this.domSic.complete();
  }

  setStatusLoadRequestPanel(status: boolean): void {
    this.loadRequestPanel.next({ ...this.loadRequestPanel.value, statusPanel: status });
  }

  setLoadRequestPanel(status: boolean, action: string = 'approve', loadRequestData, alertData): void {
    this.loadRequestPanel.next({
      ...this.loadRequestPanel.value,
      statusPanel: status,
      action: action,
      loadReqInfo: loadRequestData,
      alertInfo: alertData,
    });
  }

  setLoadRequestReasonCodes(listOfReasons: LoadRequestReasonCode[]): void {
    this.loadRequestReasonCodes.next(listOfReasons);
  }

  getLoadRequestReasonCodes(reasonTypeCd: string = 'REASON_CD'): Observable<LoadRequestReasonCode[]> {
    if (reasonTypeCd !== 'ALL') {
      return this.loadRequestReasonCodes.asObservable().pipe(
        map((resp: LoadRequestReasonCode[]) => {
          return resp.filter((reasonCode) => reasonCode.reasonTypeCd === reasonTypeCd);
        })
      );
    } else {
      return this.loadRequestReasonCodes.asObservable().pipe(
        map((resp: LoadRequestReasonCode[]) => {
          return resp;
        })
      );
    }
  }

  setLatestRefreshDate(latestDate: string): void {
    this.latestDate.next(latestDate);
  }

  setSelectedTrailer(selectedTrailer: string): void {
    this.selectedTrailer.next(selectedTrailer);
  }
  setHideCubeAbove80(hideCubeAbove80: boolean): void {
    this.hideCubeAbove80.next(hideCubeAbove80);
  }

  setTypeCd(typeCd: string): void {
    this.typeCd.next(typeCd);
  }

  getLatestRefreshDate(): Observable<String> {
    return this.latestDate.asObservable();
  }

  setParentPro(parentPro): void {
    this.selectedAlertParentPro = parentPro;
  }

  getParentPro(): any {
    return this.selectedAlertParentPro;
  }

  getShowHSSExceptionsParam(): Observable<boolean> {
    return this.showHSSExceptions$.asObservable();
  }

  setShowHSSExceptionsParam(showHSSException: boolean): void {
    this.showHSSExceptions$.next(showHSSException);
  }

  setRoute(route: String): void {
    const previousRoute = this.route.getValue();
    this.routeHistory.next([previousRoute, route]);
    this.route.next(route);
  }

  setSelectedTab(tab: number): void {
    this.selectedTab$.next(tab);
  }

  getRoute(): Observable<String> {
    return this.route.asObservable();
  }

  getRouteHistory(): Observable<String[]> {
    return this.routeHistory.asObservable();
  }
}
