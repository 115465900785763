import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConditioningService } from '@xpo-ltl/common-services';
import { PhotoTypeCd } from '@xpo-ltl/sdk-common';
import {
  LoadedTrailerImageDetail,
  LoadedTrailerProDetail,
  LoadedTrailerSearchRecord,
  SearchLoadedTrailerImagesResp,
  SearchLoadedTrailerImagesRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import { NgxGalleryAnimation, NgxGalleryComponent, NgxGalleryImageSize, NgxGalleryOptions } from 'ngx-gallery-9';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SidePanelOpts } from '../enums/options.model';
import { EnumUtil } from './../enums/enum-utils';
import { IconsKeys } from './../enums/icons-keys.enum';
import { ImageSearchRecordLabels } from './../enums/image-search-record-labels.enum';
import { ImageGalleryService } from './../image-gallery-container-service.component';
import { PreviewImage } from './../preview-image/preview-image.component';

@Component({
  selector: 'app-image-side-panel',
  templateUrl: './image-side-panel.component.html',
  styleUrls: ['./image-side-panel.component.scss'],
})
export class ImageSidePanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeSidePanel: EventEmitter<any> = new EventEmitter<any>();
  @Input() params: object;
  @Input() trailerImages: [];
  @Input() trailerStatus: string;
  @Input() containerData: LoadedTrailerSearchRecord;
  @Input() selectedImage: LoadedTrailerImageDetail;
  @Input() galleryImages: any[];
  @Input() totalShipmentsCount: number;
  @Input() employeeName: string;
  @Input() closedByEmployeeId: string;
  @Input() panelOpts: SidePanelOpts;
  readonly ImageSearchRecordLabels = ImageSearchRecordLabels;
  readonly PhotoTypeCd = PhotoTypeCd;
  readonly IconKeys = IconsKeys;
  @ViewChild('ngxGallery') galleryComponent: NgxGalleryComponent;
  @ViewChild('ngxGallery', { read: ElementRef }) galleryElement: ElementRef;
  galleryOptions: NgxGalleryOptions[];
  searchLoadedTrailerImagesRqst: SearchLoadedTrailerImagesRqst = new SearchLoadedTrailerImagesRqst();
  searchLoadedTrailerImagesResp: SearchLoadedTrailerImagesResp = new SearchLoadedTrailerImagesResp();
  searchingImages = false;
  searchRan = false;
  unsubscribe: Subject<void> = new Subject();
  searchKeyactual: string = '';
  sicCdTimeZone: string = '';
  tablePROsCollection: LoadedTrailerProDetail[];
  tablePROsNbrs: any = [];
  pinnedBottomRowData: LoadedTrailerProDetail[];
  selectedIndex: number;
  relatedProsList = [];
  imageType: string;
  photoStatus: string;
  imageSubscription: Subscription;

  constructor(
    private renderer: Renderer2,
    private dialog: MatDialog,
    private conditioningService: ConditioningService,
    private imageGalleryService: ImageGalleryService
  ) {
    this.imageSubscription = this.imageGalleryService
      .subscribeToIndex()
      .pipe(take(1))
      .subscribe((index) => {
        this.selectedIndex = index;
      });
  }

  ngOnInit() {
    this.setInitialValues();
  }

  ngAfterViewInit(): void {
    const galleryClick = this.galleryElement.nativeElement.querySelectorAll('.ngx-gallery-image-wrapper')[0];
    this.renderer.listen(galleryClick, 'click', () => {
      this.openPreview();
    });
  }

  setInitialValues() {
    this.galleryOptions = [
      {
        startIndex: this.selectedIndex,
        width: '100%',
        height: '370px',
        thumbnailsRows: 1,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageDescription: true,
        imagePercent: 100,
        thumbnailsPercent: 9,
        thumbnailsMargin: 1,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        preview: false,
        imageSize: NgxGalleryImageSize.Contain,
      },
    ];
    this.searchLoadedTrailerImagesResp.result = [];
    this.imageType = this.trailerImages[this.selectedIndex]['imageType'];
    this.photoStatus = this.getPhotoType(this.trailerImages[this.selectedIndex]['imageType']);
  }

  getTrailerStatus(status: string) {
    return EnumUtil.getTrailerStatus(status);
  }

  afterImagesReady() {
    this.relatedProsList = this.galleryImages[0].pros;
  }

  openPreview(): void {
    if (this.panelOpts.allowPreview) {
      this.setPROsData(this.selectedImage.pros);
      this.dialog.open(PreviewImage, {
        width: '100vw',
        data: {
          trailerData: this.containerData,
          selectedImgInd: this.containerData.images.indexOf(this.selectedImage),
          trailerPros: this.tablePROsNbrs,
          galleryImages: this.galleryImages,
          selectedImage: this.selectedImage,
          panelOpts: this.panelOpts,
        },
      });
    }
  }

  setPROsData(pros: LoadedTrailerProDetail[]) {
    this.tablePROsNbrs = [];
    if (pros) {
      pros.forEach((x) => {
        x.proNbr = this.conditioningService.conditionProNumber(x.proNbr, 10);
        this.tablePROsNbrs.push({ proNbr: x.proNbr, loadedTimestamp: x.loadedTimestamp.toString() });
      });
      this.pinnedBottomRowData = this.getTotals(pros);
    }
    this.tablePROsCollection = pros;
  }

  getTotals(pros: LoadedTrailerProDetail[]): LoadedTrailerProDetail[] {
    const totalResult = new LoadedTrailerProDetail();
    totalResult.proNbr = `Totals: ${pros.length} PRO#'s`;
    totalResult['proLast'] = true;
    let totalMotorMoves = 0;
    let totalUtilization = 0;
    let totalWeight = 0;

    pros.forEach((pro) => {
      totalMotorMoves += pro.motorMoves || 0;
      totalUtilization += pro.utilization || 0;
      totalWeight += pro.weight || 0;
    });
    totalResult.motorMoves = totalMotorMoves;
    totalResult.utilization = totalUtilization;
    totalResult.weight = totalWeight;
    return [totalResult];
  }

  imageChange(event) {
    this.relatedProsList = event.image.pros;
    this.imageGalleryService.setIndex(event.index);
    this.imageType = this.trailerImages[event.index]['imageType'];
    this.photoStatus = this.getPhotoType(this.trailerImages[event.index]['imageType']);
  }

  getPhotoType(imageType: string) {
    return EnumUtil.getPhotoType(imageType);
  }

  getPhotoStatus(status: string) {
    return EnumUtil.getPhotoStatus(status);
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }
}
