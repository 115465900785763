<div class="image-carousel">
  <div class="image-carousel-viewer">
    <div class="image-label">
      {{ selectedImage.name }}<span class="padd">{{ selectedImageDate }} </span>
    </div>

    <div class="image-container">
      <img [src]="selectedImage.source" alt="" class="image animated" [ngClass]="cssAnimation" />
    </div>

    <div class="arrow-control left" (click)="selectPrevious()">
      <img src="./../../assets/images/arrow-left.svg" alt="" />
    </div>
    <div class="arrow-control right" (click)="selectNext()">
      <img src="./../../assets/images/arrow-right.svg" alt="" />
    </div>
  </div>
  <div class="image-carousel-thumbnails">
    <div
      class="image-item"
      [ngClass]="{ active: index == selectedIndex }"
      *ngFor="let image of images; let index = index"
      (click)="selectImage(index)"
    >
      <img [src]="image.source" [alt]="image.name" class="image" />
    </div>
  </div>
</div>
