import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { XpoLtlConditioningService } from '@xpo-ltl/ngx-ltl';
import { AdvanceBeyondTypeCd, MatchedPartyTypeCd, MonetaryAmount } from '@xpo-ltl/sdk-common';
import { AsMatchedParty } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, get as _get, size as _size, slice as _slice } from 'lodash';
import { ProNbrFormatPipe } from '../../../../../formatters/proNbr-pipe';
import { InteractionService } from '../../../../../services/interaction.service';
@Component({
  selector: 'shipment-details-overview',
  templateUrl: './shipment-details-overview.component.html',
  styleUrls: ['./shipment-details-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShipmentDetailsOverviewComponent implements OnInit, OnDestroy {
  @Input() shipment: any;
  @Input() lineItemsAlert: any;
  @Input() alert: any;
  @Input() hist: any;
  data: OverviewData;
  proNbrPipe: ProNbrFormatPipe;

  constructor(private conditioningService: XpoLtlConditioningService, private interactionService: InteractionService) {}

  ngOnInit() {
    this.proNbrPipe = new ProNbrFormatPipe();
    this.updateFormFromShipment(this.shipment);
  }

  ngOnDestroy() {}

  get trailerNbr(): string {
    return this.alert.operationException.trailerNbr.replace('00', '');
  }

  get plannedMoveTo(): string {
    const plannedMoveTo = this.alert.operationException.value.split(',')[3];
    return plannedMoveTo ? plannedMoveTo : 'Unknown';
  }

  get misloadedTo(): string {
    const plannedMoveTo = this.alert.operationException.value.split(',')[0];
    return plannedMoveTo ? plannedMoveTo : 'Unknown';
  }

  get plannedDoor(): string {
    const plannedDoor = this.alert.operationException.value.split(',')[4];
    return plannedDoor ? plannedDoor.replaceAll('00', '').replaceAll(' ', ', ') : 'N/A';
  }

  get doorNbr(): string {
    return this.alert.operationException.doorNbr.replace('00', '');
  }

  get serviceDate(): string {
    return this.alert.operationException.value.split(',')[1];
  }

  get deliveryDate(): string {
    return this.alert.operationException.value.split(',')[2];
  }

  private updateFormFromShipment(shipment) {
    if (!shipment) {
      this.data = null;
      return;
    }

    const getParty = (matchedParties, typeCd, typeCd2?) => {
      return _find(matchedParties, (party) => !!party.typeCd && (party.typeCd === typeCd || party.typeCd === typeCd2));
    };

    // Some pricing agreements are being returned with 4 digits in the last section. Need to convert to
    // 3 digits if this occurs
    const transformCustPricingAgreementId = (id) => {
      if (!id) {
        return;
      }

      const idArray = id.split('-');
      idArray[2] = idArray[2].length === 4 ? _slice(idArray[2], 1).join('') : idArray[2];

      return idArray.join('-');
    };

    const getRev = (advanceBeyondCarrier, typeCd) => {
      if (_size(advanceBeyondCarrier) > 0) {
        return _find(advanceBeyondCarrier, (li) => li.typeCd === typeCd)
          .chain()
          .thru((val) =>
            val
              ? {
                  amt: val.chargeAmount,
                  currencyCd: 'USD', // always in US$
                }
              : undefined
          )
          ._value();
      } else {
        return 0;
      }
    };

    // extract the payment summary data from shipment and populate the this.summary with it
    const overview = {
      pro: this.proNbrPipe.transform(this.conditioningService.conditionProNumber(shipment.shipment.proNbr, 11)),
      invoice: this.conditioningService.conditionProNumber(shipment.shipment.proNbr, 10),
      origin: shipment.shipment.originTerminalSicCd,
      destination: shipment.shipment.destinationTerminalSicCd,
      parentProNbr: _get(shipment.parentShipmentId, 'proNbr'),
      pickupDate: new Date(shipment.shipment.pickupDate),
      deliveryDate: new Date(shipment.shipment.estimatedDeliveryDate),
      appliedRuleset: shipment.shipment.priceRulesetNbr,
      priceAgreementId: shipment.shipment.priceAgreementId,
      custPriceAgreement: shipment.custPricingAgreementId,
      billClass: shipment.shipment.billClassCd,
      xpoRev: { amt: shipment.xpoRevenueAmt, currencyCd: 'USD' },
      advRev: getRev(shipment.advanceBeyondCarrier, AdvanceBeyondTypeCd.ADV_CARR),
      bydRev: getRev(shipment.advanceBeyondCarrier, AdvanceBeyondTypeCd.BYD_CARR),
      spotQuote: shipment.shipment.spotQuoteId,
      shipperLoadAndCount: shipment.shipperLoadAndCountInd,
      shipperPONumber: {
        shipperNumber: _get(
          _find(shipment.suppRefNbr, (srn) => srn.typeCd === 'SN#'),
          'refNbr'
        ),
        poNumber: _get(
          _find(shipment.suppRefNbr, (srn) => srn.typeCd === 'PO#'),
          'refNbr'
        ),
      },
      deliveryQualifierCd: shipment.shipment.deliveryQualifierCd,
      shipperAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.SHPR),
      consigneeAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.CONS),
      billToAddress: getParty(shipment.asMatchedParty, MatchedPartyTypeCd.BILL_TO_OTB, MatchedPartyTypeCd.BILL_TO_INB),
    };

    this.data = overview;
    this.interactionService.setParentPro(overview.parentProNbr);
  }
}

interface OverviewData {
  pro: string;
  invoice: string;
  origin: string;
  destination: string;
  parentProNbr: string;
  pickupDate: Date;
  deliveryDate: Date;
  appliedRuleset: string;
  priceAgreementId: number;
  custPriceAgreement: string;
  billClass: string;
  xpoRev: MonetaryAmount;
  advRev: MonetaryAmount;
  bydRev: MonetaryAmount;
  spotQuote: number;
  shipperLoadAndCount: boolean;
  deliveryQualifierCd: string;

  shipperPONumber: { shipperNumber: string; poNumber: string };
  shipperAddress: AsMatchedParty;
  consigneeAddress: AsMatchedParty;
  billToAddress: AsMatchedParty;
}
