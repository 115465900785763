<div class="header"
    *ngIf="headerCount">
  <div>
    <label>BYPASS LOADS</label>
    <span>{{ headerCount.bypassLoadCount }}</span>
  </div>
  <div>
    <label>PLANNED EMPTIES</label>
    <span>{{ headerCount.plannedEmpties }}</span>
  </div>
  <div>
    <label>REMANING CUBE</label>
    <span>{{ headerCount.remainingCube }}</span>
  </div>
  <div>
    <label>SPECIAL SERVICES</label>
    <mat-icon *ngFor="let ind of headerCount.specialServices"
        [svgIcon]="ind"
        [class]="ind"></mat-icon>
    <span *ngIf="!headerCount.specialServices.length">
      NONE
    </span>
  </div>
</div>

<lane-detail [alert]="alert"
    (editedReason)="editReason($event)"
    [canEdit]="showReason"></lane-detail>

<mat-expansion-panel expanded="false"
    hideToggle="true"
    class="move-lane-breakdown--panel">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h2>
        Move Lane Breakdown
      </h2>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <lane-breakdown *ngIf="modelData.topTable"
      [modelData]="modelData"
      [type]="type"
      [alert]="alert"></lane-breakdown>
</mat-expansion-panel>
<mat-expansion-panel expanded="false"
    hideToggle="true"
    class="trailers-by-lane--panel">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <h2>Trailers & Photos</h2>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <trailers-by-lane class="app-LaneBreakdown-trailersbylane"
      *ngIf="modelData.laneData"
      [modelData]="modelData"
      [type]="type"
      [alert]="alert"></trailers-by-lane>
</mat-expansion-panel>
<approve-decline class="section"
    *ngIf="canApprove && !statusNotNew"
    [type]="type"
    [status]="status"
    [alert]="alert"
    [editedReason]="editedReason">
</approve-decline>
<cancel-request [loadRequestData]="alert.alert"
    *ngIf="canCancelRequest()"> </cancel-request>
<div class="row">
  <acknowledge-tab [status]="status"
      [alert]="alert"
      *ngIf="canCloseRequest()"
      [type]="type"></acknowledge-tab>
  <history [exception]="alert"></history>
</div>
