import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class TimeUtilsService {
  moment = moment;

  constructor() {}

  getCurrentDateTime(): string {
    const timeZone = new Date().toLocaleDateString('en', { timeZoneName: 'short' }).split(',')[1];
    const timeString = this.moment().format('MMMM Do YYYY, [at] h:mm:ss A zz') + timeZone;
    return timeString;
  }
}
