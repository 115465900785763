import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnalyticsApiService } from '@xpo-ltl-2.0/sdk-analytics';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule } from '@xpo-ltl/ngx-auth';
import {
  XpoLtlReleaseNotesComponent,
  XpoLtlReleaseNotesModule,
  XpoLtlRoleSwitcherComponent,
  XpoLtlRoleSwitcherModule,
  XpoLtlSicSwitcherModule,
} from '@xpo-ltl/ngx-ltl';
import { XpoBoardModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoButtonModule,
  XpoCardModule,
  XpoConfirmDialogModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoPageNotFoundRoutingModule,
  XpoPopoverModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { ExceptionManagementApiService } from '@xpo-ltl/sdk-exceptionmanagement';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { SdkInfrastructureModule } from '@xpo-ltl/sdk-infrastructure';
import { SdkLocationModule } from '@xpo-ltl/sdk-location';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';
import { PerformanceGoalsApiService } from '@xpo-ltl/sdk-performancegoals';
import { SdkUserPreferenceModule } from '@xpo-ltl/sdk-userpreference';
import { WorkForceMgmtApiService } from '@xpo-ltl/sdk-workforcemgmt';
import { XpoAuthConfig, XpoAuthEventHandler, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';

import { environment } from '../environments/environment';
import { NotificationComponent } from './app-notification.component';
import { AppRoutingModule, PermsResolver } from './app-routing.module';
import { AppComponent } from './app.component';
import { DrawerLauncherModule } from './core/drawer-launcher/drawer-launcher.module';
import { DialogModule } from './dialogs/dialog.module';
import { DriversModule } from './drivers/drivers.module';
import { EquipmentModule } from './equipment/equipment.module';
import { FiltersModule } from './filters/filters.module';
import { ImageCarouselModule } from './image-carousel/image-carousel.module';
import { GlobalHeaderModule } from './new-dashboard/global-header/global-header.module';
import { NewDashboardModule } from './new-dashboard/new-dashboard.module';
import { RefreshDataComponent } from './performance-dashboard/refresh-data/refresh-data.component';
import { AppAuthEventHandler } from './shared/app-auth-event-handler.class';
import { SidePanelContainerModule } from './shared/components/side-panel-container/side-panel-container.module';
import { AuthService } from './shared/services/auth.service';
import { LaneDataTypesService } from './shared/services/lane-data-types.service';
import { ShowAlertsService } from './shared/services/show-alerts.service';
import { SharedModule } from './shared/shared.module';
import { SidePanelModule } from './shared/side-panel/side-panel.module';
import { UserMetricsModule } from './user-metrics/user-metrics.module';
import { XpoFooterModule } from './xpo-footer/xpo-footer.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SunsetInterceptor } from './shared/interceptors/sunset.interceptor';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent, NotificationComponent, RefreshDataComponent],
  imports: [
    XpoAgGridModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    XpoPopoverModule,
    XpoFiltersModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule,
    DialogModule,
    SharedModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    XpoAccountPopoverModule,
    XpoAppSwitcherPopoverModule,
    XpoLtlReleaseNotesModule,
    XpoLtlSicSwitcherModule,
    XpoLtlRoleSwitcherModule,
    XpoButtonModule,
    XpoCardModule,
    XpoDialogModule,
    XpoShellModule,
    XpoFeedbackModule,
    XpoSnackBarModule,
    XpoTabsModule,
    SdkAppMetadataModule,
    SdkLocationModule,
    SdkUserPreferenceModule,
    SdkInfrastructureModule,
    SdkLoggingModule,
    FiltersModule,
    UserMetricsModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    MatSlideToggleModule,
    XpoFooterModule,
    XpoConfirmDialogModule,
    ImageCarouselModule,
    SidePanelModule,
    MatMenuModule,
    AppRoutingModule,
    XpoPageNotFoundRoutingModule,
    EquipmentModule,
    DriversModule,
    AgGridModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    DrawerLauncherModule,
    GlobalHeaderModule,
    NewDashboardModule,
  ],
  providers: [
    {
      provide: XpoAuthEventHandler,
      useClass: AppAuthEventHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SunsetInterceptor,
      multi: true
    },
    AnalyticsApiService,
    DataApiService,
    LocationApiService,
    NotificationService,
    XrtFireMessagingService,
    XrtFireMessagingTokenService,
    SidePanelContainerModule,
    LaneDataTypesService,
    AuthService,
    HumanResourceApiService,
    PermsResolver,
    ExceptionManagementApiService,
    PerformanceGoalsApiService,
    ShowAlertsService,
    WorkForceMgmtApiService,
  ],
  entryComponents: [XpoLtlReleaseNotesComponent, XpoLtlRoleSwitcherComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
