import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LanesViewDataSourceService } from '../../../../../load-management/allowable-loads/lanes-view/lanes-view-data-source.service';
import { AllowableLoadsService } from '../../../../../load-management/allowable-loads/services/allowable-loads.service';
import { AllowableLoadsTabsEnum } from '../../../../enums/allowable-loads-tabs.enum';
import { InteractionService } from '../../../../services/interaction.service';
import { ComponentsEnum } from './../../../../enums/components.enum';

@Injectable({
  providedIn: 'root',
})
export class LoadRequestDataSourceService extends LanesViewDataSourceService {
  constructor(
    private allowableLoadsServiceExtended: AllowableLoadsService,
    private interactionServiceExtended: InteractionService
  ) {
    super(allowableLoadsServiceExtended, interactionServiceExtended);
  }

  /** This is the function that the board calls when it wants data, the state includes
  the current state of the board which includes the criteria, current view, selected rows etc.*/
  fetchData(state: XpoBoardState): Observable<any> {
    return this.interactionServiceExtended.subscribeToComponent(ComponentsEnum.ALERT_LANE).pipe(
      switchMap(({ data: { alertLane } }) => {
        const emptyBoard = new XpoBoardData(state, [], 0, 0);
        const response = alertLane
          ? this.allowableLoadsServiceExtended.getListModelInstructions(
              state,
              AllowableLoadsTabsEnum.LANES,
              alertLane,
              false
            )
          : of(emptyBoard);

        return of(response);
      })
    );
  }
}
