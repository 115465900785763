<nav mat-tab-nav-bar>
  <a mat-tab-link
      *ngFor="let link of tabList"
      [active]="link === activeLink"
      (click)="handleTabSelect(link)">
    {{link}}
  </a>
</nav>
<div class="app-LoadsAndSchedules-content">
  <router-outlet></router-outlet>
</div>
