import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
export interface DialInfo {
  exceptionTypeCd: ExceptionTypeCd;
  exceptionStatusCd: ExceptionStatusCd;
  alertName: string;
  alertNumber: number;
}

@Component({
  selector: 'app-dial-list',
  templateUrl: './dial-list.component.html',
  styleUrls: ['./dial-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialListComponent {
  @Input() selectedDial: string;
  @Input() dialList: DialInfo[];

  @Output() selectedDialChange = new EventEmitter<DialInfo>();

  handleDialClick(dial: DialInfo): void {
    this.selectedDial = dial.alertName;
    this.selectedDialChange.emit(dial);
  }
}
