import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ListLocationsResp } from '@xpo-ltl-2.0/sdk-location';
import { XpoBoardOptions, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { Envelope } from '@xpo-ltl/sdk-common';
import { DsrLicense, PlanningDriver, PlanningDriverSummary } from '@xpo-ltl/sdk-linehauloperations';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';

import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { DRAWER_DATA } from '../../core/drawer-launcher/drawer.component';
import { linehaulFrameworkComponents } from '../../shared/components/frameworkComponents';
import { SpecialServiceGroupCredential } from '../../shared/components/special-service-group/special-service-group.component';
import { DriverHelper } from '../../shared/helpers/drivers.helper';
import { DriversTemplate } from '../drivers-template';
import { DriversService } from '../services/drivers.service';
import { DetailDataSourceService } from './detail-data-source.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  providers: [DetailDataSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailComponent implements OnInit, OnDestroy {
  driverInfo$: Observable<PlanningDriverSummary>;
  domicileSicInfo$: Observable<any>;
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
  };
  views: XpoBoardView[];
  viewTemplates: DriversTemplate[];
  columnDefs: any[];
  gridOptions: GridOptions;
  // activeTab: string = 'Bids';
  unsubscribe = new Subject<void>();
  credentials: SpecialServiceGroupCredential[] = [];
  phoneNumber: string;
  disableDriverStatusChange: boolean = false;

  gridApi: GridApi;
  columnApi: ColumnApi;
  gridData: any;

  keyField: string;
  isVisibleCityDriverCheckbox: boolean;
  isCityDriver: boolean;
  isUnavailable: boolean;
  empId: string;

  get isSearchingPlanningDrivers(): Observable<boolean> {
    return this.driversService.isSearchingPlanningDrivers$;
  }

  // get showTimeline(): boolean {
  //   return this.activeTab === 'Timeline';
  // }

  constructor(
    public dataSource: DetailDataSourceService,
    private driversService: DriversService,
    @Inject(DRAWER_DATA) private drawerData: { driver: PlanningDriver }
  ) {
    this.keyField = 'bid';
    this.dataSource.pageSize = 10000;

    this.dataSource.publicState$.pipe(takeUntil(this.unsubscribe)).subscribe((newState) => {
      if (newState.data && newState.data.consumerData) {
        this.gridData = newState.data.consumerData;
      }
    });
    this.columnDefs = [
      {
        headerName: 'Destination',
        field: 'destination',
        width: 435,
        maxWidth: 435,
        suppressMenu: true,
      },
      {
        headerName: 'Bid',
        field: 'bid',
        width: 120,
        maxWidth: 120,
        suppressMenu: true,
      },
      {
        headerName: 'Miles',
        field: 'miles',
        width: 120,
        maxWidth: 120,
        suppressMenu: true,
      },
    ];

    this.gridOptions = {
      frameworkComponents: linehaulFrameworkComponents,
      floatingFilter: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false, // TODO for editing, make this true
      },
      pinnedBottomRowData: [],
    };
  }

  ngOnInit(): void {
    this.driverInfo$ = this.driversService.getPlanningDrivers(this.drawerData.driver.employeeId).pipe(
      tap((newState) => {
        const driverInfo = newState.planningDriver;
        this.empId = driverInfo.employeeId;
        this.isUnavailable = this.isDriverUnavailable(driverInfo.statusCd);
        this.isVisibleCityDriverCheckbox = driverInfo.availableInd;
        this.isCityDriver = driverInfo.turnTypeCd === 'CityTurn';
        this.disableDriverStatusChange =
          driverInfo.statusCd.toUpperCase() === 'ASSIGNED' || driverInfo.statusCd.toUpperCase() === 'DISPATCHED';
      }),
      shareReplay()
    );
    this.domicileSicInfo$ = this.driverInfo$.pipe(
      switchMap((driverInfo) => this.getDomicileSic(driverInfo.planningDriver.domicileSicCd))
    );

    this.viewTemplates = [
      new XpoAgGridBoardViewTemplate({
        id: 'bids',
        name: 'Bids',
        allowAdditional: false,
        keyField: this.keyField,
        availableColumns: this.columnDefs,
      }),
    ];

    this.views = [
      this.viewTemplates[0].createView({
        closeable: false,
        criteria: {},
        id: 'bids',
        name: 'Bids',
      }),
    ] as any;

    this.credentials = this.driversService.driver.allCredentials;
    this.phoneNumber = DriverHelper.phoneNumberFormatter(this.driversService.driver.dsrCellPhone);
  }

  ngOnDestroy(): void {
    this.driversService.changeTurnType(this.empId, this.isCityDriver);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  // onValChange(value: string): void {
  //   this.activeTab = this.showTimeline ? 'Bids' : 'Timeline';
  // }

  onGridReady($event: GridReadyEvent): void {
    this.gridApi = $event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.refreshCells();
    this.columnApi = $event.columnApi;
  }

  getStatus(driverInfo: PlanningDriver): string {
    return driverInfo.statusCd;
  }

  getDomicileSic(sic: string): Observable<Envelope<ListLocationsResp>> {
    return this.driversService.getDomicileSic(sic).pipe(take(1), shareReplay());
  }

  showCheckboxForUnavailable(statusCd: string): boolean {
    return statusCd === 'Available' || statusCd === 'Assigned' || statusCd === 'Unavailable';
  }

  showCheckboxForTurnDriver(statusCd: string): boolean {
    return statusCd === 'Assigned';
  }

  isDriverUnavailable(statusCd: string): boolean {
    return statusCd === 'Unavailable';
  }

  onChange(ob: MatCheckboxChange, employeeId: string): void {
    this.driversService.changeDriverAvailability(!ob.checked, employeeId);
  }

  // showInputsTimes(statusCd: string): boolean {
  //   return statusCd === 'Assigned' || statusCd === 'Dispatched' || statusCd === 'Enroute' || statusCd === 'Arrived';
  // }
}
