import { Component, Input, OnInit } from '@angular/core';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { ActionService } from '../../../../services/action.service';

@Component({
  selector: 'close-tab',
  templateUrl: './close-tab.component.html',
  styleUrls: ['./close-tab.component.scss'],
})
export class ClsTabComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  comment: string;

  constructor(private actionService: ActionService) {}

  ngOnInit() {
    this.comment = this.alert.alert.resolution || null;
  }

  close(): void {
    this.actionService.updateException(this.alert.alert, ExceptionStatusCd.CLOSED).subscribe();
  }
}
