import { Component } from '@angular/core';

@Component({
  selector: 'app-hss-weight-formatter-cell',
  template: `
    <span>{{ value | number: '2.' }}</span>
  `,
})
export class HSSWeightFormatterComponent {
  value: number;

  agInit(params: any): void {
    this.value = params.value;
  }
}
