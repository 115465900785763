<xpo-board [boardOptions]="boardOptions"
    [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [views]="views">
  <xpo-board-actions filterActions>
    <xpo-button-group>
      <div class="grid-header"
          *ngIf="dataSource.isPilotSic$ | async">
        <div class="section">
          <span *ngIf="!post_model"><span class="bold">{{ dsrRequiredCount }}</span> Est. Required &nbsp;</span>
          <span *ngIf="post_model"><span class="bold">{{ dsrNeededCount }}</span> Model Required &nbsp;</span>
          <span class="bold"
              [ngClass]="dsrColorClass">{{ dsrCount }}</span>
          {{ current_sic }} Drivers
        </div>
        <div class="vl"></div>
        <div class="section">
          <span class="bold">{{ dsrAssignedCount }}</span> Assigned &nbsp;
          <span class="bold">{{ dsrUnassignedCount }}</span> Unassigned &nbsp;
          <span class="bold">{{ dsrDispatchedCount }}</span> Dispatched
        </div>
        <div class="vl"></div>
        <div class="section">
          <button mat-button
              [disabled]="!current_sic || differentDate"
              (click)="handleAddDriver($event)"
              xpoSmallButton>
            <mat-icon>add_circle</mat-icon> Add Driver
          </button>
        </div>
        <div class="section">
          <div class="switch-checkbox-control switch-checkbox-short"
              [class.disabled]=""
              (click)="toggleUnavailable($event)">
            <input [checked]="show_unavailable"
                type="checkbox" />
            <span class="slider round"></span>
          </div>
          <span class="toggle-info">Show Unavailable Drivers</span>
          <div class="switch-checkbox-control switch-checkbox-short"
              [class.disabled]=""
              (click)="toggleDispatched($event)">
            <input [checked]="show_dispatched"
                type="checkbox" />
            <span class="slider round"></span>
          </div>
          <span class="toggle-info">Show Dispatched Drivers</span>
        </div>
      </div>
    </xpo-button-group>
  </xpo-board-actions>

  <xpo-ag-grid-board (gridReady)="onGridReady($event)"
      [customGridOptions]="gridOptions"
      rowModelType="client-side">
  </xpo-ag-grid-board>

  <xpo-empty-state>
    <xpo-empty-state-header>
      <h3>{{noResultMessage$ | async}}</h3>
    </xpo-empty-state-header>
  </xpo-empty-state>

</xpo-board>
