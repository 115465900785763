import { Component, OnDestroy, OnInit } from '@angular/core';
import { XpoBoardOptions, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { NumericEditorComponent, XpoAgGridColumns, XpoInlineEditingBase } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EquipmentService } from '../services/equipment.service';
import { TrailersDataSourceService } from './services/trailers-data-source.service';
import { TrailersTemplate } from './trailers-template';

export interface TotalDRS {
  am: number;
  pm: number;
}
@Component({
  selector: 'app-trailers',
  templateUrl: './trailers.component.html',
  styleUrls: ['./trailers.component.scss'],
  providers: [TrailersDataSourceService],
})
export class TrailersComponent extends XpoInlineEditingBase implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();
  gridApi: GridApi;
  columnApi: ColumnApi;
  gridData: any;
  keyField: string;
  viewTemplates: TrailersTemplate[];
  gridOptions: GridOptions;
  pmColumnDefs: any[];
  columnDefs: any[];
  views: XpoBoardView[];
  isEditing: boolean;
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
  };

  constructor(public dataSource: TrailersDataSourceService, private equipmentService: EquipmentService) {
    super();
    this.equipmentService.title = 'Trailers';
    this.isEditing = false;
    this.keyField = 'trailerNum';

    this.dataSource.pageSize = 10000;

    this.dataSource
      .globalFilterChanges()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();

    this.dataSource.publicState$.pipe(takeUntil(this.unsubscribe)).subscribe((newState) => {
      if (newState.data && newState.data.consumerData) {
        this.gridData = newState.data.consumerData;
      }
    });

    this.columnDefs = [
      XpoAgGridColumns.RowIndex,
      {
        headerName: 'Trailer',
        field: 'trailerNum',
        maxWidth: 180,
        suppressMenu: true,
        cellStyle: { color: '#2196F3' },
      },
      {
        headerName: 'Load Destination',
        field: 'loadDest',
        maxWidth: 200,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.loadDest === 0) {
            return '0';
          }
          return (params.data && params.data.loadDest) || '&nbsp;';
        },
      },
      {
        headerName: 'Length',
        field: 'length',
        cellStyle: { textAlign: 'right' },
        maxWidth: 120,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.length === 0) {
            return '0';
          }
          return (params.data && params.data.length) || '&nbsp;';
        },
      },
      {
        headerName: 'WT. Loaded (Lbs.)',
        field: 'weight',
        cellStyle: { textAlign: 'right' },
        maxWidth: 150,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.weight === 0) {
            return '0';
          }
          return (params.data && params.data.weight) || '&nbsp;';
        },
      },
      {
        headerName: 'Cube Loaded (%)',
        field: 'cube',
        cellStyle: { textAlign: 'right' },
        editable: () => this.isEditing,
        maxWidth: 150,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.cube === 0) {
            return '0';
          }
          return (params.data && params.data.cube) || '&nbsp;';
        },
      },
      {
        headerName: 'Cur. Location',
        field: 'location',
        maxWidth: 150,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.location === 0) {
            return '0';
          }
          return (params.data && params.data.location) || '&nbsp;';
        },
      },
      {
        headerName: 'Status',
        field: 'loadStatus',
        maxWidth: 170,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.loadStatus === 0) {
            return '0';
          }
          return (params.data && params.data.loadStatus) || '&nbsp;';
        },
      },
      {
        headerName: 'Maintenance',
        field: 'maintenance',
        maxWidth: 170,
        suppressMenu: true,
        cellRenderer: (params) => {
          if (params.data && params.data.maintenance === 0) {
            return '0';
          }
          return (params.data && params.data.maintenance) || '&nbsp;';
        },
      },
    ];

    this.gridOptions = {
      floatingFilter: false,
      enableRangeSelection: true,
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditorComponent,
      },
      pinnedBottomRowData: [],
    };
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'trailers',
        name: 'Trailers',
        allowAdditional: true,
        keyField: 'trailerNum',
        availableColumns: this.columnDefs,
      }),
    ];
  }

  ngOnInit() {
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = [
      this.viewTemplates[0].createView({
        closeable: false,
        criteria: {},
        id: 'trailers',
        name: 'Trailers',
      }) as any,
    ];
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onGridReady(params: AgGridEvent) {
    this.gridApi = params.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.refreshCells();
    this.columnApi = params.columnApi;
  }
}
