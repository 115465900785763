<div class="general-container">
  <div class="ack-container"
      *ngIf="!statusAckOrResponded">
    <h1 mat-dialog-title>
      Acknowledge or Close Alert
    </h1>
    <mat-radio-group class="tp-radio-group"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="selectedAction">
      <mat-radio-button class="tp-radio-button"
          *ngFor="let action of actionTypes"
          [value]="action">
        {{ action }}
      </mat-radio-button>
    </mat-radio-group>
    <div class="ack-subcontainer">
      <form class="example-form"
          [formGroup]="form"
          *ngIf="!!form">
        <span>Comment *</span>
        <mat-form-field *ngIf="!disabled">
          <textarea matInput
              maxlength="50"
              formControlName="comment"></textarea>
        </mat-form-field>
        <mat-form-field *ngIf="disabled">
          <input matInput
              placeholder="{{ resolution }}"
              disabled />
        </mat-form-field>
      </form>
    </div>
    <div class="mat-dialog-actions">
      <button mat-flat-button
          (click)="save()"
          [disabled]="form.invalid"
          *ngIf="!disabled">SAVE</button>
    </div>
  </div>
  <div class="ack-container"
      *ngIf="statusAckOrResponded && allowedToClose"
      [ngClass]="{ 'box-ack': noComments }">
    <h1 mat-dialog-title>
      Close Alert
    </h1>
    <div class="ack-subcontainer">
      <ng-container *ngIf="!noComments; else explainLabel">
        <form class="example-form"
            [formGroup]="form"
            *ngIf="!!form">
          <mat-form-field *ngIf="!disabled">
            <textarea matInput
                maxlength="50"
                formControlName="comment"></textarea>
          </mat-form-field>
          <mat-form-field *ngIf="disabled">
            <input matInput
                placeholder="{{ resolution }}"
                disabled />
          </mat-form-field>
        </form>
      </ng-container>
      <ng-template #explainLabel>
        <span class="explanation-text">
          Close the Load Request to mark it as completed
        </span>
      </ng-template>
    </div>
    <div class="mat-dialog-actions">
      <button mat-flat-button
          (click)="save()"
          *ngIf="noComments">Close</button>
      <button mat-flat-button
          (click)="save()"
          [disabled]="form.invalid"
          *ngIf="!disabled && !noComments">SAVE</button>
    </div>
  </div>
</div>
