import {
  XpoAccordionGroupCellRendererComponent,
  XpoBooleanFilterComponent,
  XpoDateOnlyFilterComponent,
  XpoDateTimeFilterComponent,
  XpoEnumFilterComponent,
  XpoNumberFilterComponent,
  XpoStringFilterComponent,
  XpoTimeFilterComponent,
} from '@xpo-ltl/ngx-ltl-board';
import { DatepickerEditorComponent, NumericEditorComponent, SelectEditorComponent } from '@xpo-ltl/ngx-ltl-grid';
import { AutocompleteDriverComponent } from '../formatters/ag-grid-cell-formatters/auto-complete-driver.component';
import { PomaTrailerFormatterComponent } from '../formatters/ag-grid-cell-formatters/poma-trailer-formatter';
import { AddDriverRendererComponent } from './add-driver-renderer/add-driver-renderer.component';
import { DriverIconRendererComponent } from './driver-icon-renderer/driver-icon-renderer.component';
import { DriverNameRendererComponent } from './driver-name-renderer/driver-name-renderer.component';
import { DriversDetailsComponent } from './drivers-details/drivers-details.component';
import { GridActionIconComponent } from './grid-action-icon/grid-action-icon.component';

/**
 * Map all of the Angular CellRendererFramework components so they can be referenced by name
 * in column definitions.
 *
 * NOTE: WE MUST DO THIS in order for grid transposing to work!
 */
export const linehaulFrameworkComponents = {
  xpoStringFilterComponent: XpoStringFilterComponent,
  xpoDateTimeFilterComponent: XpoDateTimeFilterComponent,
  xpoTimeFilterComponent: XpoTimeFilterComponent,
  xpoEnumFilterComponent: XpoEnumFilterComponent,
  xpoDateOnlyFilterComponent: XpoDateOnlyFilterComponent,
  xpoNumberFilterComponent: XpoNumberFilterComponent,
  xpoBooleanFilterComponent: XpoBooleanFilterComponent,
  xpoAccordionGroupCellRendererComponent: XpoAccordionGroupCellRendererComponent,
  numericCellEditor: NumericEditorComponent,
  selectEditor: SelectEditorComponent,
  datepicker: DatepickerEditorComponent,
  driversDetailsRendererComponent: DriversDetailsComponent,
  addDriverRendererComponent: AddDriverRendererComponent,
  pomaTrailerFormatterComponent: PomaTrailerFormatterComponent,
  driverNameRendererComponent: DriverNameRendererComponent,
  driverIconRendererComponent: DriverIconRendererComponent,
  autocompleteDriverComponent: AutocompleteDriverComponent,
  GridActionIconComponent: GridActionIconComponent,
};

/**
 * List of Angular components that can be used as CellRenderers and CellEditors.
 * used by AgGridModule.withComponents()
 */
export const linehaulFrameworkComponentsDeclarations = Object.values(linehaulFrameworkComponents);

// NOTE: For AOT builds to work, you must also add these components to
// the entryComponents in the module!
