import { Component, Input } from '@angular/core';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { AppRoutes } from '../../../shared/enums/app-routes.enum';
import { ChangeViewService } from './../services/change-view.service';

@Component({
  selector: 'app-allowable-loads-buttons',
  templateUrl: './allowable-loads-buttons.component.html',
  styleUrls: ['./allowable-loads-buttons.component.scss'],
})
export class AllowableLoadsButtonsComponent {
  @Input('active') activeButton: string;

  readonly AppRoutes = AppRoutes;
  allowableLoadsRoutes: XpoShellRoute[] = [
    { label: 'Lanes', path: AppRoutes.LANES_VIEW },
    { label: 'Loads', path: AppRoutes.LOADS_VIEW },
    { label: 'Changes', path: AppRoutes.CHANGES_VIEW },
  ];

  constructor(private changeViewService: ChangeViewService) {}

  changeView($event) {
    this.changeViewService.changeView($event);
  }
}
