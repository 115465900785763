<input #input
    matInput
    type="text"
    class="app-RichTableSelectEditor-input"
    [formControl]="inputControl"
    [matAutocomplete]="auto" />
<button mat-button
    xpoSmallButton
    [disabled]="isDeleteDisabled"
    (click)="removeValue()">
  <xpo-icon iconName="remove-alt"></xpo-icon>
</button>
<mat-autocomplete class="app-RichTableSelectEditor-autocomplete"
    #auto="matAutocomplete"
    [displayWith]="displayWith"
    panelWidth="{{ panelWidthInPx }}px"
    (optionSelected)="selectEntity($event)"
    (closed)="handlePanelClosed()">
  <div *ngIf="!hideHeader"
      class="app-RichTableSelectEditor-header">
    <span *ngFor="let c of columns"
        [style.width.px]="c.widthInPx || defaultColumnWidth">
      {{ c.headerName }}
    </span>
  </div>

  <div *ngIf="noResults || loading"
      class="app-RichTableSelectEditor-message">
    <!-- Need to have 1 option rendered in order for mat-autocomplete to render panel-->
    <mat-option style="display: none"
        tabindex="-1"></mat-option>
    <span *ngIf="noResults">No results found</span>
    <mat-progress-spinner *ngIf="loading"
        mode="indeterminate"
        [strokeWidth]="5"
        [diameter]="60">
    </mat-progress-spinner>
  </div>

  <div class="app-RichTableSelectEditor-optionContainer">
    <mat-option *ngFor="let v of filteredOptions$ | async"
        [value]="v">
      <span *ngFor="let c of columns"
          [style.width.px]="c.widthInPx || defaultColumnWidth">
        <ng-container *ngIf="!c.cellRendererFramework; else cellRenderer">
          {{ c.valueFormatter ? c.valueFormatter(v[c.field]) : v[c.field] }}
        </ng-container>
        <ng-template #cellRenderer>
          <ng-content *ngComponentOutlet="c.cellRendererFramework; injector: createInjector(v)"></ng-content>
        </ng-template>
      </span>
    </mat-option>
  </div>
</mat-autocomplete>
