import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { XpoLtlSicSwitcherModule } from '@xpo-ltl/ngx-ltl';
import { XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoEmptyStateModule,
  XpoPopoverModule,
  XpoResponsiveLoadingSpinnerModule,
  XpoSlideToggleModule,
  XpoSnackBarModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import { DrawerLauncherModule } from '../core/drawer-launcher/drawer-launcher.module';
import { linehaulFrameworkComponentsDeclarations } from '../shared/components/frameworkComponents';
import { SpecialServiceGroupModule } from '../shared/components/special-service-group/special-service-group.module';
import { SharedModule } from '../shared/shared.module';
import { AddDriversDialogComponent } from './add-drivers-dialog/add-drivers-dialog.component';
import { DetailComponent } from './detail/detail.component';
import { DriversRoutingModule } from './drivers-routing.module';
import { DriversComponent } from './drivers.component';
import { DateFilterComponent } from './filters/date-filter/date-filter.component';
import { ShiftFilterComponent } from './filters/shift-filter/shift-filter.component';
import { DriversService } from './services/drivers.service';

@NgModule({
  declarations: [
    DriversComponent,
    ShiftFilterComponent,
    DateFilterComponent,
    DetailComponent,
    AddDriversDialogComponent,
  ],
  imports: [
    CommonModule,
    XpoPopoverModule,
    XpoTabsModule,
    XpoCardModule,
    XpoDialogModule,
    MatRadioModule,
    XpoFiltersModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    XpoAgGridBoardModule,
    XpoAgGridModule,
    AgGridModule.withComponents(linehaulFrameworkComponentsDeclarations),
    XpoButtonModule,
    DragDropModule,
    DriversRoutingModule,
    MatSelectModule,
    XpoSlideToggleModule,
    MatSlideToggleModule,
    MatIconModule,
    DrawerLauncherModule,
    MatChipsModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    XpoEmptyStateModule,
    XpoSnackBarModule,
    XpoLtlSicSwitcherModule,
    XpoResponsiveLoadingSpinnerModule,
    SpecialServiceGroupModule,
  ],
  exports: [DriversComponent, DetailComponent, AddDriversDialogComponent],
  providers: [DriversService],
  entryComponents: [AddDriversDialogComponent],
})
export class DriversModule {}
