import { SidePanelStatus } from '../enums/side-panel-status';

export interface SidePanelData {
  currentEventTmst: number;
  closeTmst: number;
  statusCd: string;
  sidePanelId: string;
  filterSic: string;
  laneData?: object;
  alert?: object;
  tab?: string;
}

export class SidePanelDataStructures {
  sideData?: any;
  sidePanelOpts?: any;
  sidePanelDisplay?: boolean;
  alert?;
  tab?;

  constructor(sideData: SidePanelData, sidePanelOpts: any, sidePanelDisplay: SidePanelStatus, alert?, tab?) {
    this.sideData = sideData || SidePanelDataStructures.getBlankData();
    this.sidePanelOpts = sidePanelOpts || {};
    this.sidePanelDisplay = sidePanelDisplay === SidePanelStatus.OPENED;
    this.alert = alert;
    this.tab = tab;
  }

  static getBlankData(): SidePanelData {
    const sideData = {
      currentEventTmst: 0,
      closeTmst: 0,
      statusCd: '',
      sidePanelId: '',
      filterSic: '',
      alert: [],
      tab: '',
    };
    return sideData;
  }
}
