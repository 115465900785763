import { Injectable, OnDestroy } from '@angular/core';
import { XpoBoardData, XpoBoardDataFetchState, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { DriverTurnTypeCd, JobSelectionCd } from '@xpo-ltl/sdk-common';
import { ListPlanningDriversResp } from '@xpo-ltl/sdk-linehauloperations';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LocationService } from '../shared/services/location.service';
import { Driver } from './models/drivers.model';
import { DriversService } from './services/drivers.service';

@Injectable()
export class DriversDataSourceService extends XpoBoardDataSource implements OnDestroy {
  publicState$ = this.state$;
  period: string;
  sic: string;
  shift: string;
  isPilotSic$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(private driversService: DriversService, private locationService: LocationService) {
    super();
    this.pageSize = 10000;
    const period$ = this.driversService.period$;
    const shift$ = this.driversService.shift$;
    const sic$ = this.driversService.sic$;
    combineLatest([period$.pipe(map(() => this.driversService.getPlanDate())), shift$, sic$])
      .pipe(
        distinctUntilChanged(),
        filter(() => {
          // Avoiding double call on first load
          return this.stateCache.dataFetchState !== XpoBoardDataFetchState.Loading;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([period, shift, sic]) => {
        this.period = period;
        this.sic = sic;
        this.shift = shift;
        this.refresh();
      });

    this.isPilotSic$ = this.driversService.sic$.pipe(switchMap((sicCd) => this.locationService.isPilotSic(sicCd)));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    this.setState({ dataFetchState: XpoBoardDataFetchState.Loading, source: 'LHOForceLoad' });

    return this.isPilotSic$.pipe(
      switchMap((isPilotSic) => (isPilotSic ? this.driversService.listPlanningDrivers() : of(null))),
      map((resp: ListPlanningDriversResp) => {
        if (!resp?.planningDriverSummaries) {
          return new XpoBoardData(state, [], 0, this.pageSize);
        }

        const allDrivers = resp.planningDriverSummaries.map(({ planningDriver, planningSchedule }) => {
          return { planningDriver, planningSchedule };
        });

        let drivers = allDrivers.map((item: any) => {
          return new Driver(item);
        });

        if (this.shift === 'FAC' || this.shift === 'F') {
          drivers = this.sortItems(drivers, this.sic);
        }

        drivers = this.customSort(drivers);
        return new XpoBoardData(state, drivers, drivers.length, this.pageSize);
      })
    );
  }

  private customSort(drivers: Driver[]): Driver[] {
    if (this.shift?.toUpperCase() === 'OUTBOUND') {
      let dd_night = drivers.filter(
        (d) =>
          !d.assignSicCd &&
          d.domicileSicCd?.toUpperCase() === this.sic?.toUpperCase() &&
          d.jobSelectionCd === JobSelectionCd.NIGHT_LINEHAUL &&
          d.turnTypeCd !== DriverTurnTypeCd.CITY_TURN
      );
      let dd_not_night = drivers.filter(
        (d) =>
          !d.assignSicCd &&
          d.domicileSicCd?.toUpperCase() === this.sic?.toUpperCase() &&
          d.jobSelectionCd !== JobSelectionCd.NIGHT_LINEHAUL &&
          d.turnTypeCd !== DriverTurnTypeCd.CITY_TURN
      );
      let wbt = drivers.filter(
        (d) =>
          d.assignSicCd?.toUpperCase() === this.sic?.toUpperCase() &&
          d.domicileSicCd?.toUpperCase() !== this.sic?.toUpperCase()
      );
      let td = drivers.filter((d) => d.turnTypeCd === DriverTurnTypeCd.CITY_TURN);

      dd_night = dd_night.sort(function(a, b): number {
        return a.seniorityRankNumber > b.seniorityRankNumber ? 1 : -1;
      });

      dd_not_night = dd_not_night.sort(function(a, b): number {
        return a.seniorityRankNumber > b.seniorityRankNumber ? 1 : -1;
      });

      wbt = wbt.sort(function(a, b): number {
        return a.dsrLastName > b.dsrLastName ? 1 : -1;
      });

      td = td.sort(function(a, b): number {
        return a.dsrLastName > b.dsrLastName ? 1 : -1;
      });

      const updatedDrivers = [...dd_night, ...dd_not_night, ...wbt, ...td];
      const missingDrivers = drivers.filter((d) => !updatedDrivers.some((ud) => d.employeeId === ud.employeeId));

      updatedDrivers.push(...missingDrivers);

      // Removing duplicated entries from sort logic above
      return [...new Map(updatedDrivers.map((driver) => [JSON.stringify(driver), driver])).values()];
    }

    if (this.shift === 'FAC' || 'F') {
      let dd = drivers.filter((d) => !d.assignSicCd && d.domicileSicCd === this.sic);
      let wkd = drivers.filter((d) => d.assignSicCd === this.sic && d.domicileSicCd !== this.sic);
      let ndd = drivers.filter((d) => d.assignSicCd !== this.sic && d.domicileSicCd !== this.sic);

      dd = dd.sort(function(a, b): number {
        return a.seniorityRankNumber > b.seniorityRankNumber ? 1 : -1;
      });

      wkd = wkd.sort(function(a, b): number {
        return a.dsrLastName > b.dsrLastName ? 1 : -1;
      });

      ndd = ndd.sort(function(a, b): number {
        return a.dsrLastName > b.dsrLastName ? 1 : -1;
      });

      const updatedDrivers = [...dd, ...wkd, ...ndd];
      const missingDrivers = drivers.filter((d) => !updatedDrivers.some((ud) => d.employeeId === ud.employeeId));

      updatedDrivers.push(...missingDrivers);

      return [...new Map(updatedDrivers.map((driver) => [JSON.stringify(driver), driver])).values()];
    }
  }

  setNewState(incomingState: XpoBoardState): void {
    this.setState(incomingState);
  }

  private sortItems(items: any, sic: string): any[] {
    const orderedItems = [];
    const dd = items.filter((d) => !d.assignSicCd && d.domSic === sic.toUpperCase());
    const wkd = items.filter((d) => d.assignSicCd === sic && d.domSic !== sic.toUpperCase());
    const ndd = items.filter((d) => d.assignSicCd !== sic && d.domSic !== sic.toUpperCase());

    const sortedDomDrivers = dd.sort(function(a, b): number {
      return a.seniorityRankNbr > b.seniorityRankNbr ? 1 : -1;
    });
    sortedDomDrivers.forEach((sd, ind) => {
      sd['dispatchOrder'] = ind + 1;
    });

    const sortedWkabtDrivers = wkd.sort(function(a, b): number {
      return a.dsrName < b.dsrName ? 1 : -1;
    });

    let lastDomDriverDispatchOrder = sortedDomDrivers.length + 1;
    sortedWkabtDrivers.forEach((wd) => {
      wd['dispatchOrder'] = lastDomDriverDispatchOrder;
      lastDomDriverDispatchOrder++;
    });

    const sortedNonDomDrivers = ndd.sort((a, b) => {
      return a.lnhOriginDispatchDateTime > b.lnhOriginDispatchDateTime ? 1 : -1;
    });

    sortedNonDomDrivers.forEach((nd) => {
      nd['dispatchOrder'] = lastDomDriverDispatchOrder;
      lastDomDriverDispatchOrder++;
    });

    orderedItems.push(...sortedNonDomDrivers, ...sortedDomDrivers, ...sortedWkabtDrivers);
    return orderedItems;
  }
}
