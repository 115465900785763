import { Component, Input, OnInit } from '@angular/core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { AuthService } from '../../../../shared/services/auth.service';
import { SidePanelOpts } from '../../../components/side-panel-container/image-gallery-container/enums/options.model';
import { SidePanelData } from '../../../models/side-panel-data.model';
import { UserRoleService } from '../../../services/user-role/user-role.service';
import { SidePanelModelData } from '../../side-panel-model-data';

@Component({
  selector: 'side-panel-content',
  templateUrl: './side-panel-content.component.html',
  styleUrls: ['./side-panel-content.component.scss'],
})
export class SidePanelContentComponent implements OnInit {
  ExceptionTypeCd = ExceptionTypeCd;
  @Input() status: ExceptionStatusCd;
  @Input() alert: any;
  @Input() type: ExceptionTypeCd;
  @Input() modelData: SidePanelModelData;
  sidePanelDisplay: boolean = false;
  sidePanelOpts: SidePanelOpts;
  sidePanelData: SidePanelData;
  tabs = {
    app: false,
    ack: false,
    cls: false,
    pht: false,
    brk: false,
    shipmentDetails: false,
  };

  moveToSic;
  moveToDate;
  moveToShift;
  moveToLane;

  constructor(
    public authService: AuthService,
    private loggingApiService: LoggingApiService,
    private userRoleService: UserRoleService
  ) {}

  ngOnInit() {
    this.loggingApiService.info(
      this.userRoleService.user.userId +
        ' - ' +
        'Type: ' +
        this.type +
        ' ' +
        'Slide Out - Dashboard: ' +
        ' ' +
        'Build: ' +
        this.userRoleService.build +
        ' ' +
        'ExceptionInstanceId: ' +
        this.alert.alert.exceptionInstId +
        ' ' +
        'Status: ' +
        this.status,
      this.authService.getEmployeeSic(),
      this.type,
      this.status,
      'slideOut'
    );
    if (this.status === ExceptionStatusCd.NEW) {
      if (
        this.type === ExceptionTypeCd.LOAD_REQUEST ||
        this.type === ExceptionTypeCd.CUT_LOAD_REQUEST ||
        this.type === ExceptionTypeCd.ADD_LOAD_REQUEST
      ) {
        this.type = ExceptionTypeCd.LOAD_REQUEST;
      } else {
        if (this.type === ExceptionTypeCd.PARTIAL_BYPASS) {
          this.sidePanelDisplay = true;
          this.tabs.pht = true;
          this.sidePanelOpts = {
            isDashboard: true,
          };
          this.sidePanelData = this.alert.sideData;
        }
        if (this.type === ExceptionTypeCd.HSS) {
          this.sidePanelDisplay = true;
          this.tabs.pht = true;
          this.sidePanelOpts = {
            isDashboard: true,
          };
          this.sidePanelData = this.alert.sideData;
          this.tabs.ack = true;
          this.tabs.cls = true;
        } else {
          if (this.type === ExceptionTypeCd.GMISLOAD) {
            this.tabs.shipmentDetails = true;
          }
          this.tabs.ack = true;
          this.tabs.cls = true;
        }
      }
    } else {
      if (this.type === ExceptionTypeCd.HSS) {
        this.tabs.cls = true;
        this.sidePanelDisplay = true;
        this.sidePanelOpts = {
          isDashboard: true,
        };
      } else if (this.type === ExceptionTypeCd.PROGRESSIVE_LOAD) {
        this.sidePanelData = this.alert.sideData;
        this.tabs.cls = true;
      }
      this.sidePanelData = this.alert.sideData;
    }
  }

  closeGallery() {
    this.sidePanelDisplay = false;
  }
}
