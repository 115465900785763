import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InstructionsBoardComponent } from './loads-and-schedules/instructions-board/instructions-board.component';
import { LoadsAndSchedulesComponent } from './loads-and-schedules/loads-and-schedules.component';
import { LoadsBoardComponent } from './loads-and-schedules/loads-board/loads-board.component';
import { UnderConstructionComponent } from './loads-and-schedules/under-construction/under-construction.component';
import { GlobalHeaderData } from './new-dashboard/global-header/global-header.model';
import { NewDashboardComponent } from './new-dashboard/new-dashboard.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { LoginGuard } from './shared/guards/login/login.guard';
import { AuthService } from './shared/services/auth.service';
import { SharedServicesModule } from './shared/shared-services.module';
import { SunsetGuard } from './shared/guards/sunset/sunset.guard';

@Injectable()
export class PermsResolver implements Resolve<boolean> {
  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private authService: AuthService
  ) {}

  // TODO rework this
  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const endpoint = <string>this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot);
    const path = route.data['path'];
    return this.loggedInUserService.getLoggedInUser(endpoint).pipe(
      switchMap((user: User) => {
        return this.authService.setPerms(user.employeeId, user.userId, path);
      })
    );
  }
}

const RouterDefinitions: Routes = [
  {
    path: AppRoutes.PERFORMANCE_DASHBOARD,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.PERFORMANCE_DASHBOARD },
  },
  {
    path: AppRoutes.LOAD_MANAGEMENT,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.LOAD_MANAGEMENT },
  },
  /*   {
    path: AppRoutes.DRIVERS,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.DRIVERS },
  },
  {
    path: AppRoutes.SCHEDULES,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.SCHEDULES },
  }, */
  {
    path: AppRoutes.USER_METRICS,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.USER_METRICS },
  },
  {
    path: AppRoutes.DOOR_PLAN,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.DOOR_PLAN },
  },
  {
    path: AppRoutes.LOAD_LANE_SUMMARY,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.LOAD_LANE_SUMMARY },
  },
  {
    path: AppRoutes.DIVERSION_LANE,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    loadChildren: () =>
      import('./performance-dashboard/performance-dashboard.module').then((m) => m.PerformanceDashboardModule),
    resolve: {
      Perms: PermsResolver,
    },
    data: { path: AppRoutes.DIVERSION_LANE },
  },
  {
    path: AppRoutes.NEW_DASHBOARD,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    component: NewDashboardComponent,
    resolve: {
      Perms: PermsResolver,
    },
    data: {
      globalHeaderData: <GlobalHeaderData>{
        pageName: 'Dashboard',
        filters: ['period', 'hierarchy', 'level', 'location'],
      },
    },
  },
  {
    path: AppRoutes.LOADS_AND_SCHEDULES,
    canActivate: [XpoAuthenticationGuard, LoginGuard],
    component: LoadsAndSchedulesComponent,
    resolve: {
      Perms: PermsResolver,
    },
    data: {
      globalHeaderData: <GlobalHeaderData>{
        pageName: 'Loads & Schedules',
        filters: ['period', 'hierarchy', 'location'],
      },
    },
    children: [
      {
        path: '',
        redirectTo: AppRoutes.LOADS_VIEW,
        pathMatch: 'full',
      },
      {
        path: AppRoutes.LOADS_VIEW,
        component: LoadsBoardComponent,
        data: { path: AppRoutes.LOADS_VIEW },
      },
      {
        path: AppRoutes.SCHEDULES,
        component: SchedulesComponent,
        data: { path: AppRoutes.SCHEDULES },
      },
      {
        path: AppRoutes.DOOR_PLAN,
        component: UnderConstructionComponent,
        data: { path: AppRoutes.DOOR_PLAN },
      },
      {
        path: AppRoutes.SAAG,
        component: UnderConstructionComponent,
        data: { path: AppRoutes.SAAG },
      },
      {
        path: AppRoutes.INSTRUCTIONS,
        component: InstructionsBoardComponent,
        data: { path: AppRoutes.INSTRUCTIONS },
      },
    ],
  },
  {
    path: '**',
    redirectTo: `/${AppRoutes.PERFORMANCE_DASHBOARD}`,
  },
];

function addSunsetGuardToRoutes(routes: Routes): Routes {
  return routes.map(route => ({
    ...route,
    canActivate: route.canActivate ? [...route.canActivate, SunsetGuard] : [SunsetGuard],
  }));
}

@NgModule({
  imports: [RouterModule.forRoot(addSunsetGuardToRoutes(RouterDefinitions)), SharedServicesModule.forRoot()],
  exports: [RouterModule],
})
export class AppRoutingModule {}
