import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EquipmentService } from '../../services/equipment.service';
import { Dolly } from '../models/dolly.model';

@Injectable({ providedIn: 'root' })
export class DolliesDataSourceService extends XpoBoardDataSource {
  publicState$ = this.state$;
  period: string;
  sic: string;
  shift: string;
  view: string;
  filtersReady: boolean;

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  globalFilterChanges(): Observable<any> {
    const period$ = this.equipmentService.period$;
    const view$ = this.equipmentService.view$;
    const shift$ = this.equipmentService.shift$;
    const sic$ = this.equipmentService.sic$;

    return combineLatest([period$, view$, shift$, sic$]).pipe(
      tap(([period, view, shift, sic]) => {
        this.period = period.toString();
        this.sic = sic;
        this.view = view;
        this.shift = shift;
        this.forceRefresh();
      })
    );
  }

  forceRefresh() {
    this.setState({ pageNumber: 1, source: 'ADD-NEW-VIEW' });
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData<Dolly[]>> {
    return this.equipmentService.listDolliesBySic(state).pipe(
      map((resp: XpoBoardData) => {
        return {
          ...resp,
          consumerData: resp.consumerData,
        };
      })
    );
  }
}
