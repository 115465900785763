import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Via } from '@xpo-ltl/sdk-linehauloperations';
import { DRAWER_DATA } from '../../core/drawer-launcher/drawer.component';
import { ViaDetails } from '../schedules-grid.model';

export interface ViaDetailsDrawerData {
  viaDetails: ViaDetails;
  sicCd: string;
  sicDescription: string;
  selectedTab?: 'inbound' | 'outbound';
}
export interface ViaDetailsDrawerTableContext {
  displayedColumns: Array<keyof Via>;
  tableColumns: { field: keyof Via; headerName: string; type: number | string }[];
  dataSource: MatTableDataSource<Via>;
}
@Component({
  selector: 'app-via-details-drawer',
  templateUrl: './via-details-drawer.component.html',
  styleUrls: ['./via-details-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaDetailsDrawerComponent implements AfterViewInit {
  laneData: ViaDetails;
  sicCd: string;
  sicDescription: string;
  incomingViasContext: ViaDetailsDrawerTableContext;
  outgoingViasContext: ViaDetailsDrawerTableContext;
  selectedTabIndex: number = 0;
  @ViewChild(MatSort) sort: MatSort;

  constructor(@Inject(DRAWER_DATA) drawerData: ViaDetailsDrawerData) {
    this.laneData = drawerData.viaDetails;
    this.sicCd = drawerData.sicCd;
    this.sicDescription = drawerData.sicDescription;
    this.incomingViasContext = {
      displayedColumns: ['currSic', 'viaDropCount', 'viaPickCount'],
      tableColumns: [
        { field: 'currSic', headerName: 'Origin SIC', type: 'string' },
        { field: 'viaDropCount', headerName: 'Drops', type: 'number' },
        { field: 'viaPickCount', headerName: 'Picks', type: 'number' },
      ],
      dataSource: new MatTableDataSource(drawerData.viaDetails?.incomingVias),
    };

    this.outgoingViasContext = {
      displayedColumns: ['viaSic', 'viaDropCount', 'viaPickCount'],
      tableColumns: [
        { field: 'viaSic', headerName: 'VIA SIC', type: 'string' },
        { field: 'viaDropCount', headerName: 'Drops', type: 'number' },
        { field: 'viaPickCount', headerName: 'Picks', type: 'number' },
      ],
      dataSource: new MatTableDataSource(drawerData.viaDetails?.outgoingVias),
    };
    this.selectedTabIndex = drawerData.selectedTab && drawerData.selectedTab === 'outbound' ? 1 : 0;
  }

  ngAfterViewInit(): void {
    this.incomingViasContext.dataSource.sort = this.sort;
    this.outgoingViasContext.dataSource.sort = this.sort;
  }

  isNumber(column): boolean {
    return column?.type === 'number';
  }
}
