export enum ComponentsEnum {
  GLOBAL_FILTERS = 'globalFilters',
  ALERT_FILTERS = 'alertFilters',
  METRICS = 'metrics',
  ALL = 'all',
  REGION = 'region',
  SIC = 'sic',
  SHIFT = 'shift',
  PLAN_DATE = 'planDate',
  SIDE_PANEL = 'sidePanel',
  LOAD_PANEL = 'loadPanel',
  AMOUNT = 'amount',
  ALERTS = 'alerts',
  ALERT_LANE = 'alertLane',
  OPEN_PANEL = 'openPanel',
  LATEST_DATE = 'latestDate',
  REFRESH_DATA = 'refreshData',
  UPDATED_ALERT = 'updatedAlert',
  REFRESH_AGGRID_DATA = 'refreshAggridData',
  SPANEL_RESULT = 'sidePanelResult',
  DESTROY_AGGRID_TABLE = 'destroyAggridTable',
  PERFORMANCE_DASHBOARD = 'performanceDashboard',
  SELECTED_TRAILER = 'selectedTrailer',
  ROUTE = 'route',
  HIDE_CUBE_ABOVE_80 = 'hideCubeAbove80',
  CLOSE_SIDE_PANEL = 'closeSidePanel',
  HIERARCHY_FILTER = 'hierarchyFilter',
  OPERATIONAL_LEVEL_FILTER = 'operationalLevelFilter',
  LOCATION_FILTER = 'locationFilter',
  HSS_REGION_SICS = 'HssRegionSics',
  SHOW_ALL_ALERTS = 'showAllAlerts',
}
