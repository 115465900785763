import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotAuthorizedComponent } from '../../../dialogs/not-authorized/not-authorized.component';
import { UserLoggedService } from '../../services/user-logged/user-logged.service';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate, CanLoad {
  constructor(private dialog: MatDialog, private userLoggedService: UserLoggedService) {}

  canActivate(): Observable<boolean> {
    return this.isAuthorized();
  }

  canLoad(): Observable<boolean> {
    return this.isAuthorized();
  }

  private isAuthorized(): Observable<boolean> {
    return this.userLoggedService.authenticateUser().pipe(
      map((userAuth: boolean) => {
        if (userAuth) {
          return true;
        } else {
          this.showNotAuthorizedDialog();
          return false;
        }
      })
    );
  }

  private showNotAuthorizedDialog() {
    this.dialog.open(NotAuthorizedComponent, { disableClose: true });
  }
}
