import { Attachment } from '@xpo-ltl/sdk-common';
import { FeedbackConfig } from './feedback-config';

export class EmailConfig extends FeedbackConfig {
  hasFromEmailInput: boolean;
  disableFromEmailInput: boolean;
  hasToEmailInput: boolean;
  disableToEmailInput: boolean;
  attachment: Attachment;
  trailerLink: string;
  trailerNbr: string;
  beginDate: Date;
  endDate: Date;
}
