import { XpoLtlServiceCentersService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { MoveModeCd } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
export class LanesViewBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = _.uniqueId('LanesView_');
  static _clickSubject = new Subject<any>();
  clickSubject$ = LanesViewBoardTemplate._clickSubject.asObservable();

  constructor(private serviceCentersService: XpoLtlServiceCentersService) {
    super({
      id: LanesViewBoardTemplate.templateId,
      name: 'Lanes',

      keyField: 'id',
      allowAdditional: false,
      availableFilters: [
        /* new XpoCheckboxFilter('isGreaterThan550', '> 550'),
        new XpoCheckboxFilter('bypassLoadCount', 'Bypass'),
        new LaneTypeFilter('laneType'),
        new XpoTextInputFilter('loadedWeight', 'Weight (lbs) : 0-10000, 15000'),
        new XpoTextInputFilter('loadedCube', 'Utilization % : 0-50, 80'), */
      ],
      availableColumns: [],
    });
  }

  createLanesView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'LanesView',
      name: 'Lanes View',
    });
  }

  getMoveModeCD(params: any) {
    const total = {
      exclusiveInd: false,
      supplementalInd: false,
      label: params.lane,
    };

    if (params.children) {
      _.forEach(params.children, (children) => {
        total.exclusiveInd = total.exclusiveInd || children.moveMode === MoveModeCd.EXCLUSIVE;
        total.supplementalInd = total.supplementalInd || children.moveMode === MoveModeCd.SUPPLEMENTAL;
      });
    }
    return total;
  }

  getSpecialServices(params: any) {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      _.forEach(params.children, (children2) => {
        _.forEach(children2.children, (children3) => {
          _.forEach(total, (value, key) => {
            if (!!children3[key]) {
              total[key] = children3[key];
            }
          });
        });
      });
    }
    return total;
  }

  formatDecimal(number) {
    return number.value
      ? Math.floor(number.value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '';
  }
}
