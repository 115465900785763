<div class="related-pros">
  <xpo-ag-grid [sizeColumnsToFit]="sizeToFit" style="height: 235px;">
    <ag-grid-angular
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [suppressHorizontalScroll]="true"
      [rowData]="relatedProsList"
    >
    </ag-grid-angular>
  </xpo-ag-grid>
</div>
