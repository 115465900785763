<mat-checkbox *ngIf="showSelectAll && !isSingleSelect && !searchFilterValue"
    class="xpo-AdvancedSelectTree-selectAll"
    [ngModel]="isSelectAllSelected"
    (ngModelChange)="handleSelectAllClicked($event)">
  Select All
</mat-checkbox>
<mat-tree [dataSource]="dataSource"
    [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node"
      matTreeNodeToggle
      matTreeNodePadding
      matTreeNodePaddingIndent="40">
    <mat-checkbox class="checklist-leaf-node"
        [checked]="checklistSelection.isSelected(node)"
        (change)="leafItemSelectionToggle(node, true)">{{ node.label }}</mat-checkbox>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
      matTreeNodePaddingIndent="16">
    <button mat-icon-button
        xpoSmallButton
        matTreeNodeToggle
        [attr.aria-label]="'toggle ' + node.label">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
    </button>
    <mat-checkbox [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="itemSelectionToggle(node)">{{ node.label }}</mat-checkbox>
  </mat-tree-node>
</mat-tree>
