import { CdkDragEnd, DragRef } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { LoadedTrailerSearchRecord } from '@xpo-ltl/sdk-linehauloperations';
import { NgxGalleryComponent, NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery-9';
import { Subscription } from 'rxjs';
import { SortHelper } from '../../../../services/sort-helper';
import { SidePanelConstants } from '../../side-panel-container-constants.component';
import { SidePanelOpts } from './../enums/options.model';
import { ImageGalleryService } from './../image-gallery-container-service.component';

@Component({
  selector: 'dialog-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.scss'],
})
export class PreviewImage implements OnInit, OnDestroy {
  @ViewChild('ngxGallery') galleryComponent: NgxGalleryComponent;
  @ViewChild('ngxGallery', { read: ElementRef }) galleryElement: ElementRef;
  @ViewChild('hiddenDivPrev', { read: ElementRef }) hiddenDiv: ElementRef;
  @ViewChild('hiddenImgPrev', { read: ElementRef }) hiddenImg: ElementRef;
  panelOpts: SidePanelOpts;
  galleryImages: NgxGalleryImage[] = [];
  dmsGalleryImages: NgxGalleryImage[];
  galleryOptions: NgxGalleryOptions[];
  dmsService: any;
  selectedImage: any = [];
  downloadingImages = false;
  zoomed: boolean = false;
  trailerData: LoadedTrailerSearchRecord;
  dragRef: DragRef;
  showGallery: boolean = false;
  sicCdTimeZone: any;
  loadingDMSImages: boolean = true;
  toolTipPros: any = [];
  selectedIndex: number;
  photoDesc: string;
  photoType: string;
  count: number = 1;
  imageActions;
  readonly dataUriRegex = new RegExp('data:image/(jpeg|png|tiff);base64');
  imageSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PreviewImage>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private timeService: XpoLtlTimeService,
    private sidePanelConstants: SidePanelConstants,
    private imageGalleryService: ImageGalleryService
  ) {
    this.imageSubscription = this.imageGalleryService
      .subscribeToIndex()
      .pipe()
      .subscribe((index) => {
        this.selectedIndex = index;
      });
  }

  ngOnInit() {
    this.imageGalleryService.resetZoom();
    this.dragRef?.reset();
    this.trailerData = this.data.trailerData;
    this.panelOpts = this.data.panelOpts;
    const [gallery_options] = this.sidePanelConstants.preview_image_gallery_options;
    if (this.panelOpts.allowZoomAndRotate) {
      this.imageActions = [
        { icon: 'fa fa-search-plus', onClick: this.zoomIn.bind(this), titleText: 'Zoom in' },
        { icon: 'fa fa-search-minus', onClick: this.zoomOut.bind(this), titleText: 'Zoom out' },
        { icon: 'fa fa-undo', onClick: this.rotateLeft.bind(this), titleText: 'Rotate left' },
        { icon: 'fa fa-repeat', onClick: this.rotateRight.bind(this), titleText: 'Rotate right' },
      ];
    }
    this.galleryOptions = [
      {
        ...gallery_options,
        startIndex: this.selectedIndex,
        imageActions: this.imageActions,
      },
    ];
    const dmsIdImageTypes: string[] = [];
    const dmsIdImages: string[] = [];
    const images = this.trailerData.images;

    images.forEach((img) => {
      dmsIdImages.push(img.fullPhotoDocumentId.toString());
      dmsIdImageTypes.push(
        img.fullPhotoDocumentType
          .toString()
          .replace('Optional[', '')
          .replace(']', '')
      );
    });

    this.timeService.timezoneForSicCd$(this.trailerData.loadingSic).subscribe((timestamp) => {
      this.sicCdTimeZone = timestamp;
      this.imageGalleryService
        .loadImagesfromDMS(
          dmsIdImages,
          dmsIdImageTypes,
          this.galleryImages,
          this.selectedIndex,
          this.trailerData,
          this.sicCdTimeZone
        )
        .then((resp) => {
          if (resp) {
            this.dmsGalleryImages = resp;
          }
        });
    });
    this.toolTipPros = SortHelper.sort(this.data.trailerPros, 'asc', 'loadedTimestamp');
    this.setKeyDownEvents();
  }

  imagesReady() {
    if (this.count === this.trailerData.images.length) {
      this.photoType = this.dmsGalleryImages[this.selectedIndex].label;
      this.photoDesc = this.dmsGalleryImages[this.selectedIndex].description;
      this.galleryComponent.show(this.selectedIndex);
      this.loadingDMSImages = false;
    }
    this.count++;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onDragEnded(event: CdkDragEnd): void {
    this.dragRef = event.source._dragRef;
  }

  setKeyDownEvents(): void {
    this.dialogRef.keydownEvents().subscribe((event) => {
      switch (event.keyCode) {
        case 39:
          this.galleryComponent.showNext();
          break;
        case 37:
          this.galleryComponent.showPrev();
          break;
      }
    });
  }

  private zoomIn() {
    this.zoomed = true;
    this.imageGalleryService.zoomImg(
      this.renderer,
      this.hiddenImg.nativeElement,
      this.galleryElement.nativeElement,
      this.hiddenDiv.nativeElement,
      'in',
      this.selectedIndex
    );
  }

  private zoomOut() {
    this.zoomed = true;
    this.imageGalleryService.zoomImg(
      this.renderer,
      this.hiddenImg.nativeElement,
      this.galleryElement.nativeElement,
      this.hiddenDiv.nativeElement,
      'out',
      this.selectedIndex
    );
  }

  private rotateLeft() {
    const deg = this.imageGalleryService.getRotate(this.hiddenImg.nativeElement) - 90;
    const rotate = 'rotate(' + deg + 'deg)';
    this.renderer.setAttribute(
      this.hiddenImg.nativeElement,
      'src',
      this.imageGalleryService.getBackground(
        this.galleryElement.nativeElement,
        this.renderer,
        this.hiddenDiv.nativeElement,
        this.selectedIndex
      )
    );
    this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', rotate);
  }

  private rotateRight() {
    const deg = this.imageGalleryService.getRotate(this.hiddenImg.nativeElement) + 90;
    const rotate = 'rotate(' + deg + 'deg)';
    this.renderer.setAttribute(
      this.hiddenImg.nativeElement,
      'src',
      this.imageGalleryService.getBackground(
        this.galleryElement.nativeElement,
        this.renderer,
        this.hiddenDiv.nativeElement,
        this.selectedIndex
      )
    );
    this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', rotate);
  }

  downloadAll() {
    this.downloadingImages = true;
    this.imageGalleryService.downloadAll(this.galleryImages);
  }

  downloadThis() {
    if (this.selectedImage.length) {
      this.imageGalleryService.downloadThis(this.selectedImage, 'preview');
    } else {
      this.imageGalleryService.downloadThis(
        {
          image: this.dmsGalleryImages[0],
          imageType: this.trailerData.images[this.selectedIndex].imageType,
          index: this.selectedIndex,
        },
        'preview'
      );
    }
  }
  /**
   * Set current selected image.
   * @param selectedImage
   */
  onChangeImage(selectedImage: any) {
    this.selectedIndex = selectedImage.index;
    this.imageGalleryService.setIndex(selectedImage.index);
    this.selectedImage = { ...selectedImage, imageType: this.trailerData.images[this.selectedIndex].imageType };
    if (this.zoomed) {
      this.renderer.setStyle(
        this.imageGalleryService.getCurrentImgRef(this.galleryElement.nativeElement, this.selectedIndex),
        'background-size',
        'contain'
      );
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'display', 'none');
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'transform', 'none');
      this.renderer.setStyle(this.hiddenDiv.nativeElement, 'width', '500px');
      this.renderer.setStyle(this.hiddenImg.nativeElement, 'transform', 'none');
    }
    this.dragRef?.reset();
    this.imageGalleryService.resetZoom();

    this.photoType = this.dmsGalleryImages[selectedImage.index].label;
    this.photoDesc = this.dmsGalleryImages[selectedImage.index].description;
  }

  handleEmailClick() {
    this.imageGalleryService.handleEmail(this.trailerData, this.selectedIndex);
  }

  ngOnDestroy() {
    this.imageSubscription.unsubscribe();
  }
}
