import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpecialServiceIconComponent } from './special-service-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SpecialServiceIconComponent],
  exports: [SpecialServiceIconComponent],
})
export class SpecialServiceIconModule {}
