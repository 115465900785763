import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, map, retryWhen, take, tap } from 'rxjs/operators';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { UserRoleService } from '../../services/user-role/user-role.service';

@Injectable({ providedIn: 'root' })
export class UserLoggedService {
  user: BehaviorSubject<User> = new BehaviorSubject<User>(new User());

  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private config: ConfigManagerService,
    private userRoleService: UserRoleService
  ) {}

  authenticateUser(): Observable<boolean> {
    // console.log('authenticateUser:', this.config.getSetting(ConfigManagerProperties.loggedInUserRoot));
    return this.loggedInUserService
      .getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        retryWhen((errors) => errors.pipe(delay(1000), take(5))),
        map((user: User) => {
          if (this.userRoleService.isAuthorizedUser(user)) {
            this.setUser(user);
            return true;
          } else {
            this.userRoleService.user = undefined;
            return false;
          }
        }),
        catchError((error) => {
          this.userRoleService.user = undefined;
          return of(false);
        })
      );
  }
  private setUser(user: User) {
    this.user.next(user);
  }
  getUser(): Observable<User> {
    return this.user;
  }
}
