<div class="al-component">
  <xpo-board [dataSource]="datasource"
      [viewTemplates]="viewTemplates"
      [views]="views"
      [boardOptions]="{ suppressViewSwitcher: true, suppressGridSettingsPopover: true }">
    <xpo-board-actions>
      <app-allowable-loads-buttons [active]="AppRoutes.CHANGES_VIEW"></app-allowable-loads-buttons>
    </xpo-board-actions>
    <xpo-empty-state>
      <xpo-empty-state-header>
        <h3>No Results Found</h3>
      </xpo-empty-state-header>
    </xpo-empty-state>
    <xpo-ag-grid-board (gridReady)="onChangesGridReady($event)"
        [sizeColumnsToFit]="sizeToFit"
        [customGridOptions]="gridOptions"
        enableSorting="true"
        rowModelType="client-side"
        rowSelection="single"
        enableMovableColumns="true">
    </xpo-ag-grid-board>
  </xpo-board>
</div>
