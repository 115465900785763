<h1 mat-dialog-title>
  Send Email
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>
<form [formGroup]="userEmailFormGroup" class="email-container">
  <mat-dialog-content>
    <div class="email-container-content" fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field *ngIf="emailConfig.hasToEmailInput">
        <input
          matInput
          type="text"
          placeholder="From"
          maxlength="50"
          autocomplete="off"
          [formControlName]="emailFormFields.FROM_EMAIL"
        />
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', emailFormFields.FROM_EMAIL)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="To"
          maxlength="50"
          autocomplete="off"
          [formControlName]="emailFormFields.TO_EMAIL"
        />
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', emailFormFields.TO_EMAIL)">Required</div>
          <div class="mat-error" *ngIf="hasError('pattern', emailFormFields.TO_EMAIL)">
            The email is invalid (example@xpo.com)
          </div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Subject"
          maxlength="100"
          autocomplete="off"
          [formControlName]="emailFormFields.SUBJECT"
        />
        <mat-error>
          <div class="mat-error" *ngIf="hasError('required', emailFormFields.SUBJECT)">Required</div>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <textarea
          matInput
          maxTextareaAutosize
          placeholder="Message"
          autocomplete="off"
          [formControlName]="emailFormFields.MESSAGE"
        ></textarea>
      </mat-form-field>
    </div>
    <mat-chip-list class="attachment">
      <mat-chip>
        <mat-icon>attach_file</mat-icon>
        {{ emailConfig.attachment.fileName }}
      </mat-chip>
    </mat-chip-list>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="emailSent" class="success-message">
    <p>Email sent.</p>
  </mat-dialog-content>
  <mat-dialog-content *ngIf="sendError" class="error-message">
    <p>An error occurred sending email.</p>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="!emailSent" class="email-container-actions">
    <button class="cancelButton" mat-flat-button (click)="cancelClicked()" tabindex="-1">Cancel</button>
    <button
      class="sendButton"
      mat-flat-button
      (click)="sendClicked()"
      tabindex="-1"
      [disabled]="!userEmailFormGroup.valid || sendingEmail"
    >
      <i *ngIf="sendingEmail" class="fa fa-spinner fa-pulse"></i>
      <span *ngIf="!sendingEmail">Send</span>
    </button>
  </mat-dialog-actions>
</form>
