import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ServiceCenter } from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlSicSwitcherComponent } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ltl-grid';
import { JobSelectionCd, JobSelectionCdHelper } from '@xpo-ltl/sdk-common';
import { PlanningDriverSummary } from '@xpo-ltl/sdk-linehauloperations';
import { GridApi, GridOptions, RowDataChangedEvent, RowNode } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { linehaulFrameworkComponents } from '../../shared/components/frameworkComponents';
import { Driver } from '../models/drivers.model';
import { TypeEnum } from '../models/type.enum';
import { DriversService } from '../services/drivers.service';

export enum SearchType {
  SEARCH_BY_SIC = 'searchBySic',
  SEARCH_BY_EMP_ID = 'searchByEmpId',
}

@Component({
  selector: 'app-add-drivers-dialog',
  templateUrl: './add-drivers-dialog.component.html',
  styleUrls: ['./add-drivers-dialog.component.scss'],
})
export class AddDriversDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  private dialogRef;
  rowData: any;
  gridOptions: GridOptions = {
    columnDefs: [
      {
        ...XpoAgGridColumns.RowIndex,
        cellStyle: { textAlign: 'center', paddingLeft: '14px' },
        getQuickFilterText: (params) => {
          return undefined;
        },
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        sortable: false,
        pinned: 'left',
        lockPosition: true,
        suppressSizeToFit: true,
      },
      {
        headerName: ' ',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellClass: 'xpo-AgGrid-checkboxColumn',
        headerClass: 'xpo-AgGrid-checkboxColumn',
        cellStyle: { paddingLeft: '10px' },
        lockPosition: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        sortable: false,
        pinned: 'left',
        suppressSizeToFit: true,
      },
      {
        headerName: 'Driver',
        field: 'dsrName',
        cellRenderer: 'driverNameRendererComponent',
        cellRendererParams: {
          getSicCode: () => this.driversService.sic,
        },
        getQuickFilterText: (params) => {
          if (params.data.domicileSicCd && params.data?.assignSicCd === this.planningSic) {
            return params.data.dsrName + ' ' + params.data.type;
          } else {
            return params.data.dsrName + ' ' + TypeEnum[params.data.jobSelectionCd];
          }
        },
        flex: 2,
      },
      {
        headerName: 'Employee ID',
        field: 'employeeId',
        flex: 1,
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
      {
        headerName: 'Domicile SIC',
        field: 'domicileSicCd',
        flex: 1,
        getQuickFilterText: (params) => {
          return params.value;
        },
      },
      {
        headerName: 'Status',
        field: 'availableStatusCd',
        flex: 1,
      },
      {
        headerName: 'Rem. Drive Hrs.',
        field: '',
        hide: true,
      },
      {
        headerName: 'Job Selection',
        field: 'jobSelectionCd',
        hide: true,
      },
    ],
    rowSelection: 'multiple',
    onSelectionChanged: this.onSelectionChange.bind(this),
    onGridReady: (params) => {
      this.gridApi = params.api;
      if (this.searchType === SearchType.SEARCH_BY_SIC) {
        this.toggleAvailableDrivers(false);
      }
    },
    defaultColDef: {
      resizable: true,
      filter: false,
      suppressMenu: true,
      sortable: true,
    },
    rowHeight: 32,
    headerHeight: 32,
    frameworkComponents: linehaulFrameworkComponents,
    isExternalFilterPresent: () => true,
    doesExternalFilterPass: (v) => this.doesExternalFilterPass(v),
  };
  showCityDrivers: boolean = true;
  showFlexDrivers: boolean = true;
  gridApi: GridApi;
  isDriverSelected: boolean = false;
  private gridData: any[];
  searchType: SearchType = SearchType.SEARCH_BY_SIC;
  empIdForSearch: FormControl;
  private currEmpIds: Set<any>;
  private defaultRowData: Driver[];
  selectedSic: string;
  private showSpinnerSubject = new BehaviorSubject<boolean>(true);
  readonly showSpinner$ = this.showSpinnerSubject.asObservable();

  private destroyed$ = new Subject<void>();

  readonly jobSelectionCodes = JobSelectionCdHelper.values();
  readonly cityDrivers = [JobSelectionCd.CITY];
  readonly flexDrivers = [JobSelectionCd.AM_FLEXIBLE, JobSelectionCd.ANYTIME_FLEXIBLE, JobSelectionCd.PM_FLEXIBLE];

  @ViewChild('xpoSicSwitcher', { static: true }) xpoSicSwitcher: XpoLtlSicSwitcherComponent;
  showUnavailableDrivers: boolean = false;
  planningSic: string;
  overlayNoRowsTemplate: string;

  constructor(
    dialogRef: MatDialogRef<AddDriversDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private driversService: DriversService,
    private cdRef: ChangeDetectorRef,
    private snackbar: XpoSnackBar
  ) {
    this.planningSic = this.driversService.sic;
    this.dialogRef = dialogRef;
    this.gridData = data.gridData;
    if (!this.driversService.shift || !this.planningSic || !this.driversService.period) {
      this.rowData = [];
    } else {
      this.selectedSic = this.driversService.sic;
      this.initSubscriptions();
    }
    this.overlayNoRowsTemplate = `No Drivers Found`;
  }

  ngOnInit(): void {
    this.empIdForSearch = new FormControl('');
  }

  ngAfterViewInit(): void {
    this.empIdForSearch.valueChanges.subscribe((val) => {
      if (val && val.length === 5) {
        this.showSpinnerSubject.next(true);
        this.driversService.getPlanningDrivers(val).subscribe(
          (resp: PlanningDriverSummary) => {
            this.showSpinnerSubject.next(false);
            if (resp && resp.planningDriver.employeeId) {
              if (!this.currEmpIds.has(resp.planningDriver.employeeId)) {
                this.rowData = [new Driver(resp)];
              }
            } else {
              this.rowData = [];
            }
          },
          (error) => {
            this.showSpinnerSubject.next(false);
          }
        );
      }
    });
    this.xpoSicSwitcher.focus();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initSubscriptions(): void {
    this.driversService.listPlanningDrivers$.pipe(takeUntil(this.destroyed$)).subscribe((resp) => {
      this.showSpinnerSubject.next(false);
      this.updateRowData(resp);
    });

    this.driversService.listPlanningDriversForAddDrivers$.pipe(takeUntil(this.destroyed$)).subscribe((resp) => {
      this.showSpinnerSubject.next(false);
      this.updateRowData(resp);
    });
  }

  updateRowData(resp): void {
    if (resp && resp.planningDriverSummaries) {
      const allDrivers = resp.planningDriverSummaries;
      const filteredDrivers = [];
      allDrivers.forEach((row: PlanningDriverSummary) => {
        if (
          row.planningDriver.assignedSicCd === this.planningSic ||
          row.planningDriver.domicileSicCd === this.planningSic ||
          this.selectedSic
        ) {
          filteredDrivers.push(row.planningDriver);
          this.currEmpIds = new Set(this.gridData.map(({ employeeId }) => employeeId));
          const newEmpIds = filteredDrivers.filter(({ employeeId }) => !this.currEmpIds.has(employeeId));
          this.rowData = this.defaultRowData = newEmpIds.map((item: any) => new Driver(item));
        }
      });
    } else {
      this.rowData = [];
    }
  }

  toggleFlexDrivers(v: MatSlideToggleChange): void {
    this.showFlexDrivers = v.checked;
    this.gridApi.onFilterChanged();
  }

  toggleCityDrivers(v: MatSlideToggleChange): void {
    this.showCityDrivers = v.checked;
    this.gridApi.onFilterChanged();
  }

  doesExternalFilterPass(node): boolean {
    if (node.data.domicileSicCd !== this.planningSic && node.data?.assignSicCd === this.planningSic) {
      return true;
    }

    const jobCodesToFilterOut = [];

    if (!this.showFlexDrivers) {
      jobCodesToFilterOut.push(...this.flexDrivers);
    }

    if (!this.showCityDrivers) {
      jobCodesToFilterOut.push(...this.cityDrivers);
    }

    const codes = this.jobSelectionCodes.filter((code) => !jobCodesToFilterOut.some((v) => v === code));

    return codes.some((d) => !node.data.jobSelectionCd || d === node.data.jobSelectionCd);
  }

  toggleAvailableDrivers(isChecked: boolean): void {
    this.showUnavailableDrivers = isChecked;
    let filter = {};
    const dsrAvailableFilter = this.gridApi.getFilterInstance('availableStatusCd');
    if (isChecked) {
      filter = { values: ['Available', 'Unavailable'] };
      dsrAvailableFilter.setModel(filter);
    } else {
      filter = { values: ['Available'] };
      dsrAvailableFilter.setModel(filter);
    }
    this.gridApi.onFilterChanged();
  }

  onSelectionChange(selection): void {
    const s = selection.api.getSelectedNodes();
    this.isDriverSelected = !!s.length;
  }

  onAdd($event: MouseEvent): void {
    const rowNodes = this.gridApi.getSelectedNodes();
    const driversAdded = [];
    const empIds = [];
    rowNodes.forEach((rowNode: RowNode) => {
      empIds.push(rowNode?.data?.employeeId);
      driversAdded.push(rowNode.data);
    });

    this.driversService
      .upsertLinehaulDrivers(this.selectedSic, rowNodes)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.dialogRef.close(driversAdded);
        },
        (error) => {
          this.snackbar.open({
            message: error.error.message,
            status: 'error',
            matConfig: {
              verticalPosition: 'bottom',
            },
          });
        }
      );
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onFilterTextBoxChanged(event?): void {
    this.gridApi.setQuickFilter(event.target.value);
  }

  onSicChange(serviceCenter: ServiceCenter): void {
    this.rowData = [];
    this.showSpinnerSubject.next(true);
    this.selectedSic = serviceCenter.sicCd;
    if (serviceCenter && serviceCenter.sicCd) {
      this.showSpinnerSubject.next(true);
      this.driversService
        .listPlanningDriversForAddDriver(serviceCenter.sicCd)
        .pipe(take(1))
        .subscribe();
    }
  }

  searchTypeChange($event: any): void {
    if ($event === SearchType.SEARCH_BY_EMP_ID) {
      this.rowData = [];
    } else {
      this.empIdForSearch.reset('', { onlySelf: true, emitEvent: true });
      this.rowData = this.defaultRowData;
    }
  }

  rowDataChanged(rowDataChangedEvent: RowDataChangedEvent): void {
    if (this.rowData && this.rowData.length === 1) {
      const row = rowDataChangedEvent.api?.getRowNode('0');
      row.setSelected(true, true);
    } else {
      rowDataChangedEvent.api?.deselectAll();
    }
  }
}
