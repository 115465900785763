import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SpecialServiceIndicators } from '../../../shared/enums/special-service-indicatiors.enum';

enum SpecialServiceToSVG {
  'Freezable' = 'ss_freezable',
  'Guaranteed' = 'ss_guaranteed',
  'Hazmat' = 'ss_hazmat',
  'Headload' = 'ss_headload',
}
@Component({
  selector: 'app-loads-cell-renderer',
  templateUrl: './loads-cell-renderer.component.html',
  styleUrls: ['./loads-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadsCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  equipmentId: string;
  indicators: SpecialServiceIndicators[];

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.params = params;
    const { value, data } = this.params;
    this.equipmentId = value;
    this.indicators = data?.specialServiceIndicators.map((ind) => SpecialServiceToSVG[ind]);
  }
}
