<app-drawer-wrapper>
  <span appDrawerTitle
      class="app-ViaDetailDrawer-header">
    {{ sicCd }}
    <span class="app-ViaDetailDrawer-headerInfo">{{ sicDescription }}</span>
  </span>
  <div class="app-ViaDetailDrawer-metricsWrapper">
    <!--Drivers-->
    <div class="app-ViaDetailDrawer-metricGroup">
      <div class="app-ViaDetailDrawer-metricsWrapper-header">Drivers</div>
      <div class="app-ViaDetailDrawer-metricGroup-flex">
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{ laneData.plannedDrivers }}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Planned</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{ laneData.dispatchedDrivers  }}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Dispatched</span>
        </span>
      </div>
    </div>

    <!--Equipment-->
    <div class="app-ViaDetailDrawer-metricGroup">
      <div class="app-ViaDetailDrawer-metricsWrapper-header">Equipment</div>
      <div class="app-ViaDetailDrawer-metricGroup-flex">
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.singlePupCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Single Pups</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.dblTurnCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Double Turns</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.singleVanCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Single Vans</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.rmdCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">RMDs</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.tripleCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Triples</span>
        </span>
        <span>
          <div class="app-ViaDetailDrawer-metricGroup-value">{{laneData.setCount}}
          </div>
          <span class="app-ViaDetailDrawer-metricTitle">Sets</span>
        </span>
      </div>
    </div>

    <!--HSS Pulls-->
    <!-- <div class="app-ViaDetailDrawer-metricGroup">
      <div>
        <div class="app-ViaDetailDrawer-metricsWrapper-header">HSS PULLS</div>
        <div class="app-ViaDetailDrawer-metricGroup-flex">
          <span>
            <div class="app-ViaDetailDrawer-metricGroup-value">
            </div>
            <span class="app-ViaDetailDrawer-metricTitle">Planned</span>
          </span>
          <span>
            <div class="app-ViaDetailDrawer-metricGroup-value">
            </div>
            <span class="app-ViaDetailDrawer-metricTitle">Dispatched</span>
          </span>
        </div>
      </div>
    </div> -->
  </div>
  <mat-tab-group xpoAlternateTabs
      class="app-ViaDetailDrawer-tabs"
      [selectedIndex]="selectedTabIndex">
    <!--Incoming VIAs-->
    <mat-tab label="Incoming VIAS">
      <ng-container *ngTemplateOutlet="viaTable; context: incomingViasContext"></ng-container>
    </mat-tab>

    <!--Outgoing VIAs-->
    <mat-tab label="Outgoing VIAS">
      <ng-container *ngTemplateOutlet="viaTable; context: outgoingViasContext"></ng-container>
    </mat-tab>
  </mat-tab-group>
</app-drawer-wrapper>

<ng-template #viaTable
    let-displayedColumns="displayedColumns"
    let-tableColumns="tableColumns"
    let-dataSource="dataSource">
  <table mat-table
      matSort
      class="app-ViaDetailDrawer-tabs-table"
      [dataSource]="dataSource">
    <ng-container *ngFor="let col of tableColumns"
        [matColumnDef]="col.field">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [arrowPosition]="isNumber(col) ? 'before' : 'after'"
          [ngClass]="{'xpo-MatTable-cell--textAlignRight': isNumber(col)}"
          class="xpo-MatTable--borderLeft">{{ col.headerName }}</th>
      <td mat-cell
          *matCellDef="let row"
          [ngClass]="{'xpo-MatTable-cell--textAlignRight': isNumber(col)}"
          class="xpo-MatTable--borderLeft"> {{row[col.field]}} </td>
    </ng-container>
    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <xpo-empty-state *ngIf="!dataSource.data?.length">
    <xpo-empty-state-header>
      <h3>No VIAs Found</h3>
    </xpo-empty-state-header>
  </xpo-empty-state>
</ng-template>
