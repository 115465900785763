import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { GridOptions } from 'ag-grid-community';
import { ClickableCellRendererComponent } from '../cell-renderers/clickable-cell-renderer/clickable-cell-renderer.component';
import { LoadsCellRendererComponent } from '../cell-renderers/loads-cell-renderer/loads-cell-renderer.component';
import { ScheduleCellRendererComponent } from '../cell-renderers/schedule-cell-renderer/schedule-cell-renderer.component';
import { LoadsBoardDataSourceService } from './loads-board-data-source.service';

@Component({
  selector: 'app-loads-board',
  templateUrl: './loads-board.component.html',
  providers: [LoadsBoardDataSourceService],
  styleUrls: ['./loads-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadsBoardComponent implements OnInit {
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
    suppressGridSettingsPopover: true,
  };
  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  gridOptions: GridOptions = {};

  constructor(public dataSource: LoadsBoardDataSourceService) {}

  ngOnInit(): void {
    this.viewTemplates = this.getViewTemplates();
    this.views = this.getViews(this.viewTemplates[0]);
  }

  private getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'loadsBoardViewTemplate',
        name: 'loadsBoardViewTemplate',
        availableColumns: [
          {
            headerName: 'Load',
            field: 'equipmentId',
            cellRendererFramework: LoadsCellRendererComponent,
          },
          {
            headerName: 'Status',
            field: 'currentStatus',
          },
          {
            headerName: 'Schedule',
            field: 'driverName',
            cellRendererFramework: ScheduleCellRendererComponent,
          },
          {
            headerName: 'Move-to',
            field: 'moveTo',
            cellRendererFramework: ClickableCellRendererComponent,
            cellRendererParams: {
              handleClick: (data: string) => alert(data),
            },
          },
          {
            headerName: 'Close-to',
            field: 'closeTo',
          },
          {
            headerName: 'Weight',
            field: 'weightLoaded',
          },
          {
            headerName: 'Cube',
            field: 'cubePercent',
          },
          {
            headerName: 'Door',
            field: 'assignedDoor',
          },
        ],
      }),
    ];
  }

  private getViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [viewTemplate.createView({ id: 'loadsBoardViewTemplate', name: 'loadsBoardViewTemplate' })];
  }
}
