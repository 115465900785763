import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ExceptionLocationTypeCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { DriversService } from '../drivers/services/drivers.service';
import { SchedulesService } from '../schedules/schedules.service';
import { SidePanelType } from '../shared/enums/side-panel-type.enum';
import { GlobalFilters } from '../shared/models/global-filters';
import { RegionWithSics } from '../shared/models/region-with-sics.model';
import { ShiftOption } from '../shared/models/shift-options';
import { AuthService } from '../shared/services/auth.service';
import { InteractionService } from '../shared/services/interaction.service';
import { SidePanelVisibilityService } from '../shared/services/side-panel-visibility.service';
import { LinehaulService } from './../shared/services/linehaul/linehaul.service';
import { LocationService } from './../shared/services/location.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() route: string;
  globalFilters = new GlobalFilters();
  showDashboard: boolean = false;
  regionOptions$: Observable<RegionWithSics[]>;
  shiftOptions: ShiftOption[] = [
    { title: 'FAC', code: 'F' },
    { title: 'Outbound', code: 'O' },
  ];

  filtersForm = new FormGroup({
    shift: new FormControl(this.shiftOptions[1].code),
    planDate: new FormControl(new Date()),
    sic: new FormControl(''),
    region: new FormControl(''),
  });
  maxDate = new Date();
  domSic: String;
  showOptions: boolean;
  private unsubscriber = new Unsubscriber();

  constructor(
    private linehaulService: LinehaulService,
    public authService: AuthService,
    private locationService: LocationService,
    private interactionService: InteractionService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private schedulesService: SchedulesService,
    private driversService: DriversService
  ) {}

  ngOnInit() {
    this.sidePanelVisibilityService.subscribeToComponent(SidePanelType.showSidePanel).subscribe((show) => {
      this.showDashboard = show;
    });
    this.interactionService.getDomSic().subscribe((sic) => {
      this.authService.isItOrSuperUser() ? (this.domSic = '') : (this.domSic = sic);
    });

    if (!this.showDashboard) {
      this.locationService.filterLocationData.pipe(take(1)).subscribe((resp) => {
        const region = this.getRegion(resp[ExceptionLocationTypeCd.LINEHAUL_REGION]);

        this.filtersForm.setValue({
          shift: 'O',
          planDate: new Date(),
          sic: this.domSic,
          region: this.domSic === 'UPO' ? 'LH11' : region,
        });
      });
    } else {
      this.interactionService.globalFilters.subscribe((res) => {
        if (res) {
          this.globalFilters = res.data;
          const formatDate =
            this.globalFilters.planDate instanceof Date
              ? new Date()
              : this.globalFilters.planDate.replace(/-/g, '/').replace(/T.+/, '');
          this.filtersForm.setValue({
            shift: this.globalFilters.shift,
            planDate: new Date(formatDate),
            sic: this.globalFilters.sic,
            region: this.globalFilters.region,
          });
        }
      });
    }

    this.regionOptions$ = this.locationService.getLomaRegions().pipe(
      // startWith([]),
      switchMap((lomaRegions) => {
        const regionSort = (a, b) => (a.region < b.region ? -1 : a.region > b.region ? 1 : 0);

        return lomaRegions
          ? of(lomaRegions.sort(regionSort))
          : this.linehaulService.getRegionsWithSicsList().pipe(map((resp: any) => resp?.sort(regionSort)));
      }),
      takeUntil(this.unsubscriber.done$),
      shareReplay()
    );
  }

  get shift() {
    return this.filtersForm.get('shift');
  }

  getRegion(regionList: any[]): string {
    const region = regionList && regionList.filter((reg) => reg.sicList.some((sic) => sic.sicCd === this.domSic));

    return (region && region[0] && region[0].region) || '';
  }

  changeDate(date) {
    this.globalFilters.planDate = date;
    this.interactionService.setGlobalFilters(this.filtersForm.value);
    this.schedulesService.period = date;
    this.driversService.period = date;
  }

  changeShift(shift) {
    this.globalFilters.shift = shift;
    this.shift.patchValue(shift);
    this.interactionService.setGlobalFilters(this.filtersForm.value);
    this.schedulesService.shift = shift;
    this.driversService.shift = shift;
    this.closeSelectOptions();
  }

  handleSicChange(sicCdValue: string): void {
    const sicCd = sicCdValue?.toUpperCase();

    this.regionOptions$.pipe(take(1)).subscribe((regions) => {
      if (sicCd) {
        const regionWithSics = regions.find((r) => r.sicList.some((s) => sicCd === s.sicCd?.toUpperCase()));
        const sic = regionWithSics?.sicList?.find((s) => sicCd === s.sicCd?.toUpperCase());

        if (sic && sic.isFac !== sic.isOb) {
          this.globalFilters.shift = sic.isFac ? 'F' : 'O';
        }

        this.filtersForm.setValue({
          sic: sicCd,
          planDate: this.filtersForm.value.planDate,
          shift: this.filtersForm.value.shift,
          region: regionWithSics.region,
        });
      } else {
        this.filtersForm.controls['sic'].setValue('');
      }
      this.interactionService.setGlobalFilters(this.filtersForm.value);
      this.schedulesService.sicCd = sicCd;
      this.driversService.sic = sicCd;
    });
  }

  openSelectOptions() {
    this.showOptions = !this.showOptions;
  }

  closeSelectOptions() {
    this.showOptions = false;
  }
}
