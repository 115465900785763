<div class="details-general-info" *ngIf="data">
  <div class="item-row">
    <div class="item">
      <p class="title">PRO</p>
      <p class="data">{{ (data.proNbr | proNbrFormat) || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">ORIGIN</p>
      <p class="data">{{ data.origin || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">DESTINATION</p>
      <p class="data">{{ data.destination || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">DELIVERY DATE</p>
      <p class="data">{{ data.deliveryDate | date: 'MM/dd/y' || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">PICKUP DATE</p>
      <p class="data">{{ data.pickupDate | date: 'MM/dd/y' || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">LINEAL FEET</p>
      <p class="data">{{ data.linealFeet || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">WEIGHT(LBS)</p>
      <p class="data">{{ data.totalWeight || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">PIECE COUNT</p>
      <p class="data">{{ data.pieceCount || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">PARENT PRO</p>
      <p class="data">{{ data.parentPro || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">COD AMOUNT</p>
      <p class="data">{{ data.codAmount || '-' }}</p>
    </div>
    <div class="item">
      <p class="title">PALLET COUNT</p>
      <p class="data">{{ data.palletCount || '-' }}</p>
    </div>
    <div class="item">
      <p class="title"></p>
      <p class="data"></p>
    </div>
  </div>
</div>
