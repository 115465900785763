import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';

import {
  XpoButtonModule,
  XpoFormFieldModule,
  XpoIconModule,
  XpoResponsiveLoadingSpinnerModule,
} from '@xpo-ltl/ngx-ltl-core';

import { XpoAdvancedSelectPanelComponent } from './advanced-select-panel/advanced-select-panel.component';
import { XpoAdvancedSelectTreeComponent } from './advanced-select-tree/advanced-select-tree.component';
import { XpoAdvancedSelectComponent } from './advanced-select.component';

@NgModule({
  declarations: [XpoAdvancedSelectPanelComponent, XpoAdvancedSelectTreeComponent, XpoAdvancedSelectComponent],
  imports: [
    CommonModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
    XpoIconModule,
    MatChipsModule,
    XpoButtonModule,
    XpoFormFieldModule,
    XpoResponsiveLoadingSpinnerModule,
    MatTreeModule,
  ],
  exports: [XpoAdvancedSelectPanelComponent, XpoAdvancedSelectTreeComponent, XpoAdvancedSelectComponent],
})
export class XpoAdvancedSelectModule {}
