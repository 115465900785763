import { Injectable } from '@angular/core';
import { ActiveSic } from '@xpo-ltl-2.0/sdk-location';
import { ListAllActiveSicsResp, LocationApiService } from '@xpo-ltl/sdk-location';
import { AsyncSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { RegionWithSics } from '../../models/region-with-sics.model';

@Injectable({
  providedIn: 'root',
})
export class LinehaulService {
  private _allActiveSicsSubject = new AsyncSubject<any>();
  allActiveSics$ = this._allActiveSicsSubject.asObservable();

  constructor(private locationApiService: LocationApiService) {}

  getRegionsWithSicsList(): Observable<RegionWithSics[]> {
    return this.locationApiService.listAllActiveSics().pipe(
      shareReplay(1),
      map((resp: ListAllActiveSicsResp) => {
        this.shareAllActiveSics(resp.activeSics as ActiveSic[]);
        const tempRegionWithSics: RegionWithSics[] = [];
        if (!!resp && typeof resp.activeSics !== 'undefined') {
          const tempRegions = [];
          resp.activeSics.forEach((sicInfo) => {
            if (tempRegions.indexOf(sicInfo.region) === -1) {
              tempRegions.push(sicInfo.region);
              tempRegionWithSics.push({ region: sicInfo.region, sicList: [] });
            }
          });
          resp.activeSics.forEach((info) => {
            tempRegionWithSics.forEach((regionSic) => {
              if (info.region === regionSic.region) {
                regionSic.sicList.push({
                  sicCd: info.sicCd,
                  city: info.city,
                  state: info.state,
                  isOb: info.isOb,
                  isFac: info.isFac,
                  sicShowed: `${info.sicCd}-${info.sicDescription}, ${info.state}`,
                  sicDescription: info.sicDescription,
                  serviceCenterOffset: info.serviceCenterOffset,
                });
              }
            });
          });
          tempRegionWithSics.unshift({ region: '', sicList: [] });
        }

        tempRegionWithSics.sort((a, b) => {
          return a.region < b.region ? -1 : a.region > b.region ? 1 : 0;
        });
        return tempRegionWithSics;
      })
    );
  }

  shareAllActiveSics(allActiveSics: ActiveSic[]) {
    this._allActiveSicsSubject.next(allActiveSics);
    this._allActiveSicsSubject.complete();
  }
}
