import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

import { ImageSearchRecordLabels } from '../../../../shared/components/side-panel-container/image-gallery-container/enums/image-search-record-labels.enum';
import { LoadedTrailerSummary } from './selected-trailer-info.model';

@Component({
  selector: 'app-selected-trailer-info',
  templateUrl: './selected-trailer-info.component.html',
  styleUrls: ['./selected-trailer-info.component.scss'],
})
export class SelectedTrailerInfoComponent implements OnInit {
  @Input() containerData: LoadedTrailerSummary;
  @Input() exceptionAlertData: any;

  readonly ImageSearchRecordLabels = ImageSearchRecordLabels;

  constructor() {}

  ngOnInit() {}
}
