<div class="container"
    *ngIf="notNewSIC && atLeastOneTransition">
  <h1>History</h1>
  <div *ngIf="!loading">
    <ng-container *ngFor="let status of statusResp; let i = index">
      <mat-expansion-panel class="panel"
          (opened)="panelOpenState[i] = true"
          (closed)="panelOpenState[i] = false"
          *ngIf="!status.statusCd.toLowerCase().includes('sic') && status.statusCd !== ExceptionStatusCd.NEW"
          hideToggle>
        <mat-expansion-panel-header collapsedHeight="48px"
            expandedHeight="48px">
          <div class="panel-container">
            <span class="name-section">
              <span class="name"> {{ status.auditInfo.updatedName }}</span>
              <span class="role"> {{ status.auditInfo.roleName }}</span>
            </span>
            <span class="date">{{ status.auditInfo.updatedTimestamp | date: 'MM/d/yy' }}</span>
            <span class="chip-section"
                *ngIf="i < statusResp.length - 1">
              <xpo-status-indicator [statusText]="statusResp[i + 1].statusCd"
                  class="history-status"
                  isLabel="true">
                {{ statusResp[i + 1].statusCd | uppercase }}
              </xpo-status-indicator>
              <mat-icon>keyboard_arrow_right</mat-icon>
              <xpo-status-indicator [statusText]="status.statusCd"
                  class="history-status"
                  isLabel="true">
                {{ status.statusCd | uppercase }}
              </xpo-status-indicator>
            </span>
            <span class="chip-section"
                *ngIf="i === statusResp.length - 1">
              <xpo-status-indicator [statusText]="status.statusCd"
                  class="history-status"
                  isLabel="true">
                {{ status.statusCd | uppercase }}
              </xpo-status-indicator>
            </span>
          </div>
        </mat-expansion-panel-header>
        <p class="comment">{{ status.statusUpdateComment || 'No comments saved.' }}</p>
      </mat-expansion-panel>
    </ng-container>
  </div>
</div>
