import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { SidePanelModelData } from '../../../side-panel-model-data';

export interface LaneBreakdownMoveLane {
  category: string;
  planned: number;
  loadingactual: number;
  remaining: number | string;
  isWarning: boolean;
}

@Component({
  selector: 'lane-breakdown',
  templateUrl: './lane-breakdown.component.html',
  styleUrls: ['./lane-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LaneBreakdownComponent implements OnInit {

  /**
   * Returns remaining weight from XCP table for PL alerts
   */
  get remainingWeight(): number {
    return this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
      ? this.alert?.alert?.operationException?.value?.split('/')[2]
      : this.modelData.topTable?.weight.remaining;
  }

  /**
   * Returns remaining cube from XCP table for PL alerts
   */
  get remainingCube(): number {
    return this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
      ? this.alert?.alert?.operationException?.value?.split('/')[1]
      : this.modelData.topTable?.cube.remaining;
  }
  @Input() alert: any;
  @Input() modelData: SidePanelModelData;
  @Input() type: ExceptionTypeCd;
  data: LaneBreakdownMoveLane[];

  displayedColumns: string[] = ['category', 'planned', 'loadingactual', 'remaining'];

  ngOnInit() {
    this.data = [
      {
        category: 'Loads',
        planned: this.modelData.topTable?.loads.planned,
        loadingactual: this.modelData.topTable?.loads.loaded,
        remaining: this.getLoadsRemaining() || 'N/A',
        isWarning: this.checkRemainingLoads(),
      },
      {
        category: 'Weight',
        planned: this.modelData.topTable?.weight.planned,
        loadingactual: this.modelData.topTable?.weight.loaded,
        remaining: this.remainingWeight,
        isWarning: this.checkRemainingWeight(),
      },
      {
        category: 'Cube',
        planned: this.modelData.topTable?.cube.planned,
        loadingactual: this.modelData.topTable?.cube.loaded,
        remaining: this.remainingCube,
        isWarning: this.checkRemainingCube(),
      },
      {
        category: 'Load Average (Lbs.)',
        planned: this.modelData.topTable?.loadAverage.planned,
        loadingactual: this.modelData.topTable?.loadAverage.loaded,
        remaining: this.modelData.topTable?.loadAverage.remaining,
        isWarning: false,
      },
      {
        category: 'TUC',
        planned: this.modelData.topTable?.tuc.planned,
        loadingactual: this.modelData.topTable?.tuc.loaded,
        remaining: this.modelData.topTable?.tuc.remaining ?? 'N/A',
        isWarning: false,
      },
      {
        category: 'HSS',
        planned: this.modelData.topTable?.hss.planned,
        loadingactual: this.modelData.topTable?.hss.loaded,
        remaining: this.modelData.topTable?.hss.remaining ?? 'N/A',
        isWarning: false,
      },
    ];
  }

  getLoadsRemaining(): number {
    return this.modelData.topTable
      ? this.modelData.topTable.loads.planned - this.modelData.topTable.loads.loaded
      : null;
  }

  checkRemainingWeight(): boolean {
    return this.remainingWeight / 21150 > this.getLoadsRemaining() && this.type === ExceptionTypeCd.PROGRESSIVE_LOAD;
  }

  checkRemainingLoads(): boolean {
    return this.getLoadsRemaining() > 0 && this.type === ExceptionTypeCd.PROGRESSIVE_LOAD;
  }

  checkRemainingCube(): boolean {
    return this.remainingCube / 100 > this.getLoadsRemaining() && this.type === ExceptionTypeCd.PROGRESSIVE_LOAD;
  }
}
