import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { EquipmentStatusCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { DsrLicense } from '@xpo-ltl/sdk-linehauloperations';
import { AppRoutes } from '../shared/enums/app-routes.enum';
export interface LoadsAndSchedulesGridData {
  equipmentId: string;
  specialServiceIndicators: ShipmentSpecialServiceCd[]; // G, HM, FRZ, EMTY, HL, //string currenly/
  currentStatus: EquipmentStatusCd; // (currently its a string)
  driverName: string;
  driverEmployeeId: string;
  driverLicenseIndicators: DsrLicense[];
  eslDriverName?: string;
  eslDriverEmployeeId?: string;
  eslDriverLicenseIndicators?: DsrLicense[];
  cutTime: Date;
  plannedDispatchTime: Date;
  actualDispatchTime: Date;
  moveTo: string;
  closeTo: string;
  weightLoaded: number;
  cubePercent: number;
  assignedDoor: number;
  sectorCode: string;
  isHSS: boolean;
}

@Component({
  selector: 'app-loads-and-schedules',
  templateUrl: './loads-and-schedules.component.html',
  styleUrls: ['./loads-and-schedules.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadsAndSchedulesComponent implements OnInit {
  tabList: string[] = ['Loads', 'Schedules', 'Door Plan', 'SAAG', 'Instructions'];
  activeLink: string;
  AppRouteList = AppRoutes;
  tabToRoute: any = {
    Loads: AppRoutes.LOADS_VIEW,
    Schedules: AppRoutes.SCHEDULES,
    'Door Plan': AppRoutes.DOOR_PLAN,
    SAAG: AppRoutes.SAAG,
    Instructions: AppRoutes.INSTRUCTIONS,
  };

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.activeLink = Object.keys(this.tabToRoute).find(
      (key) => this.tabToRoute[key] === this.router.url.split('/')[2]
    );
  }

  handleTabSelect(tab: string): void {
    this.activeLink = tab;
    this.router.navigateByUrl(AppRoutes.LOADS_AND_SCHEDULES + '/' + this.tabToRoute[tab]);
  }
}
