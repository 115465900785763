import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { XpoAdvancedSelectModule } from '../../shared/components/advanced-select';
import { GlobalHeaderComponent } from './global-header.component';
import { HierarchyFilterComponent } from './hierarchy-filter/hierarchy-filter.component';
import { PeriodFilterComponent } from './period-filter/period-filter.component';

@NgModule({
  declarations: [GlobalHeaderComponent, PeriodFilterComponent, HierarchyFilterComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    XpoAdvancedSelectModule,
  ],
  exports: [GlobalHeaderComponent],
})
export class GlobalHeaderModule {}
