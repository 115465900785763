import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { CarrierManagementApiService, ListTrailerPullsQuery } from '@xpo-ltl/sdk-carriermanagement';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { OperationException } from '@xpo-ltl/sdk-exceptionmanagement';
import { GetTrailerContentsQuery, LinehaulOperationsApiService } from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { tap } from 'rxjs/operators';
import { ExceptionAlertAction } from '../../../../enums/exception-alert-action.enum';
import { ActionService } from '../../../../services/action.service';
import { AuthService } from '../../../../services/auth.service';
import { InteractionService } from '../../../../services/interaction.service';
import { SidePanelVisibilityService } from '../../../../services/side-panel-visibility.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';
@Component({
  selector: 'hss-actions',
  templateUrl: './hss-actions.component.html',
  styleUrls: ['./hss-actions.component.scss'],
})
export class HssActionsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private actionService: ActionService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private loggingApiService: LoggingApiService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private xpoSnackBar: XpoSnackBar,
    private carrierManagementApiService: CarrierManagementApiService,
    private linehaulOpsService: LinehaulOperationsApiService,
    private userRoleService: UserRoleService
  ) {}

  @Input() status: ExceptionStatusCd;
  @Input() alert: any;
  @Input() type: ExceptionTypeCd;
  actionUiConfig: ActionUiConfig;
  actions: string[];
  buttonText: string = '';
  configByType;
  controlLabels = {};
  controlsConfig;
  defaultAction: string = '';
  ExceptionAlertAction = ExceptionAlertAction;
  form: FormGroup;
  isActionAllowed = false;
  selectedAction: string = '';
  statusForActions = {
    [ExceptionAlertAction.APPROVE]: ExceptionStatusCd.APPROVED,
    [ExceptionAlertAction.DECLINE]: ExceptionStatusCd.DECLINED,
    [ExceptionAlertAction.RESUBMIT]: ExceptionStatusCd.UNDER_REVIEW_LH,
    [ExceptionAlertAction.CLOSE]: ExceptionStatusCd.CLOSED,
    [ExceptionAlertAction.CLOSE_REQUEST]: ExceptionStatusCd.CLOSED,
  };
  title: string;
  bookingNumber: string = '';
  pullId: string = '';
  noPullIdsFound: boolean = false;
  isDeclined: boolean = false;
  private formSubmitAttempt: boolean = false;

  exceptionProps = [
    {
      type: ExceptionTypeCd.HSS,
      props: [
        {
          status: ExceptionStatusCd.NEW,
          title: 'Approve/Decline Request',
          isActionAllowed: this.authService.isAllowedToApproveDeclineHss(),
          actions: [
            {
              action: ExceptionAlertAction.APPROVE,
              buttonText: 'Approve',
              controls: [
                {
                  name: 'controlNumber',
                  label: 'Control Number *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                    Validators.maxLength(50),
                  ],
                },
                {
                  name: 'comment',
                  label: 'Comment',
                  defaultValue: '',
                  validators: [Validators.maxLength(100)],
                },
              ],
            },
            {
              action: ExceptionAlertAction.DECLINE,
              buttonText: 'Decline',
              controls: [
                {
                  name: 'comment',
                  label: 'Comment *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                  ],
                },
              ],
            },
          ],
        },
        {
          status: ExceptionStatusCd.NEW_SIC,
          title: 'Approve/Decline Request',
          isActionAllowed: this.authService.isAllowedToApproveDeclineHss(),
          actions: [
            {
              action: ExceptionAlertAction.APPROVE,
              buttonText: 'Approve',
              controls: [
                {
                  name: 'controlNumber',
                  label: 'Control Number *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                    Validators.maxLength(50),
                  ],
                },
                {
                  name: 'comment',
                  label: 'Comment',
                  defaultValue: '',
                  validators: [Validators.maxLength(100)],
                },
              ],
            },
            {
              action: ExceptionAlertAction.DECLINE,
              buttonText: 'Decline',
              controls: [
                {
                  name: 'comment',
                  label: 'Comment *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                  ],
                },
              ],
            },
          ],
        },
        {
          status: ExceptionStatusCd.APPROVED,
          title: 'Close',
          isActionAllowed: this.authService.isAllowedToResubmitCloseHss(),
          actions: [
            {
              action: ExceptionAlertAction.CLOSE,
              buttonText: 'Close',
              controls: [],
            },
          ],
        },
        {
          status: ExceptionStatusCd.DECLINED,
          title: 'Resubmit or Close',
          isActionAllowed: this.authService.isAllowedToResubmitCloseHss(),
          actions: [
            {
              action: ExceptionAlertAction.RESUBMIT,
              buttonText: 'Resubmit',
              controls: [
                {
                  name: 'comment',
                  label: 'Comment *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                  ],
                },
              ],
            },
            {
              action: ExceptionAlertAction.CLOSE_REQUEST,
              buttonText: 'Close',
              controls: [
                {
                  name: 'comment',
                  label: 'Comment',
                  defaultValue: '',
                  validators: [Validators.maxLength(100)],
                },
              ],
            },
          ],
        },
        {
          status: ExceptionStatusCd.UNDER_REVIEW_LH,
          title: 'Approve/Decline Request',
          isActionAllowed: this.authService.isAllowedToApproveDeclineHss(),
          actions: [
            {
              action: ExceptionAlertAction.APPROVE,

              buttonText: 'Approve',
              controls: [
                {
                  name: 'controlNumber',
                  label: 'Control Number *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                    Validators.maxLength(50),
                  ],
                },
                {
                  name: 'comment',
                  label: 'Comment',
                  defaultValue: '',
                  validators: [Validators.maxLength(100)],
                },
              ],
            },
            {
              action: ExceptionAlertAction.DECLINE,
              buttonText: 'Decline',
              controls: [
                {
                  name: 'comment',
                  label: 'Comment *',
                  defaultValue: '',
                  validators: [
                    Validators.required,
                    Validators.maxLength(100),
                    Validators.pattern('(.|\\s)*\\S(.|\\s)*'),
                  ],
                },
              ],
            },
          ],
        },
        {
          status: ExceptionStatusCd.CLOSED,
          title: 'Close',
          isActionAllowed: false,
          actions: [
            {
              action: ExceptionAlertAction.CLOSE,
              buttonText: 'Close',
              controls: [],
            },
          ],
        },
      ],
    },
  ];

  ngOnInit() {
    this.getConfigByType();
    if (!!this.configByType) {
      this.actionUiConfig = {
        isActionAllowed: this.configByType.isActionAllowed,
        title: this.configByType.title,

        status: this.configByType.status,
        actions: this.configByType.actions.map((actionConfig) => actionConfig.action),
      };
    }

    this.isAllowedToTakeAction();
    this.initForm();
    this.checkBookingAndPullId();
  }

  get comment() {
    return this.form.get('comment');
  }

  get commentInvalid() {
    return (
      (this.comment.invalid && this.comment.touched && !this.comment.value) ||
      (this.comment.untouched && this.formSubmitAttempt)
    );
  }

  get commentTooLong() {
    return (
      (this.comment.invalid && this.comment.hasError('maxlength')) ||
      (this.comment.untouched && this.comment.dirty && this.comment.invalid)
    );
  }

  get controlNumber() {
    return this.form.get('controlNumber');
  }

  get controlNumberInvalid() {
    return this.controlNumber.invalid && this.controlNumber.touched;
  }

  get isHss() {
    return this.type === ExceptionTypeCd.HSS;
  }

  getConfigByType() {
    this.configByType = this.exceptionProps
      .find((config) => {
        return config.type === this.type;
      })
      .props.filter((config) => config.status === this.status)[0];
  }

  getFormGroup(action: string) {
    const formGroup = {};

    this.controlsConfig = this.configByType.actions.find((actionConfig) => actionConfig.action === action)['controls'];
    this.controlsConfig.forEach((control) => {
      formGroup[control.name] = [control.defaultValue, control.validators];
    });

    return formGroup;
  }

  getUserInitials() {
    const firstName = this.authService.employeeDetails.basicInfo.firstName;
    const lasttName = this.authService.employeeDetails.basicInfo.lastName;
    const initials = firstName.charAt(0) + lasttName.charAt(0);
    return initials;
  }

  getDefaultAction() {
    return this.actionUiConfig && this.actionUiConfig.actions && this.actionUiConfig.actions[0];
  }

  getNewStatusForAction = (action) => {
    return this.statusForActions[action];
  }

  setDefaultControlNumber() {
    const initials = this.getUserInitials();
    const date = this.datePipe.transform(new Date(), 'MMddyyyy');
    const ctrlNumber = date + ' ' + initials;
    this.controlNumber.patchValue(ctrlNumber);
  }

  initForm() {
    this.defaultAction = this.getDefaultAction();

    this.form = this.fb.group({});

    if (this.defaultAction) {
      this.actionChange(this.defaultAction);
    }
  }

  isAllowedToTakeAction() {
    this.isActionAllowed = (this.actionUiConfig && this.actionUiConfig.isActionAllowed) || false;
  }

  updateForm(action: string) {
    const formGroup = this.getFormGroup(action);

    this.form = this.fb.group(formGroup);
    this.updateControlLabels();
    this.updateButtonLabel(action);
  }

  updateControlLabels() {
    this.controlsConfig.forEach((control) => {
      this.controlLabels[control.name] = control.label;
    });
  }

  updateButtonLabel(action: string) {
    this.buttonText = this.configByType.actions.find((actionConfig) => actionConfig.action === action)['buttonText'];
  }

  actionChange(action: string) {
    if (this.isHss) {
      this.selectedAction = action;
      this.updateForm(this.selectedAction);
      if (this.selectedAction === ExceptionAlertAction.APPROVE) {
        this.setDefaultControlNumber();
      } else {
        this.isDeclined = true;
      }
    }
  }

  updateHss() {
    if (this.isActionAllowed) {
      const newStatus = this.getNewStatusForAction(this.selectedAction || this.defaultAction);
      this.formSubmitAttempt = true;
      if (this.form.valid) {
        const resolution = this.comment ? this.comment.value : '';
        const controlNumber = this.controlNumber ? this.controlNumber.value : '';
        const bookingNumber = this.bookingNumber;
        const pullId = this.pullId;
        this.actionService
          .updateHSSException(this.alert.alert, newStatus, controlNumber, bookingNumber, pullId, resolution)
          .subscribe((resp) => {
            if (resp.code === '200') {
              this.loggingApiService.info(
                this.userRoleService.user.userId +
                  ' - UpdateException: ' +
                  this.userRoleService.build +
                  ' - NewStatus: ' +
                  newStatus +
                  'Alert: ' +
                  this.alert &&
                  this.alert.alert &&
                  this.alert.alert.exceptionInstId,
                this.authService.getEmployeeSic(),
                'Update Status of HSS Exception from Performance Dashboard',
                'UPDATE',
                ExceptionTypeCd.HSS
              );
              this.closePanel();
            }
          });
      } else {
        this.validateAllFormFields(this.form);
        this.xpoSnackBar.error('Please fix the errors below before sending request');
      }
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  private closePanel() {
    this.sidePanelVisibilityService.closePanels();
  }

  getCommentErrorMessage(): string {
    if (this.commentTooLong) {
      return 'Comment field is too long.';
    } else {
      return 'Add a comment above to Decline this HSS Exception.';
    }
  }

  hasError(error: string, formGrp?: FormGroup, field?: string): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  checkBookingAndPullId() {
    const opEx = this.alert.alert.operationException as OperationException;
    if (opEx.bookingNumber && opEx.pullId) {
      // If Booking Number and Pull Id present in listLinehaulExceptions call, use those values
      this.bookingNumber = opEx.bookingNumber;
      this.pullId = opEx.pullId;
    } else {
      // If No booking nbr and pull id present in call,
      // use getTrailerContents and listTrailerPulls to search them
      const trailerRqst = new GetTrailerContentsQuery();
      trailerRqst.trailerNumber = opEx.trailerNbr;
      this.linehaulOpsService
        .getTrailerContents(trailerRqst, { toastOnError: false, loadingOverlayEnabled: false })
        .pipe(
          tap((trailerResp) => {
            if (trailerResp) {
              const pullRqst = new ListTrailerPullsQuery();
              pullRqst.scac = trailerResp.foreignScacCd;
              pullRqst.trailer = parseInt(trailerResp.trailerNumber.substr(5), 10);
              pullRqst.originSic = trailerResp.origSic;
              pullRqst.destSic = trailerResp.loadDestination;
              pullRqst.statusIds = [2, 3, 4]; // As requested in DLC-4779 and DLC-5017
              this.carrierManagementApiService
                .listTrailerPulls(pullRqst)
                .pipe(
                  tap((resp) => {
                    if (resp && resp.pulls.length === 1) {
                      // If just one booking number and pull id, use those values
                      this.bookingNumber = resp.pulls[0].booking.toString();
                      this.pullId = resp.pulls[0].pullId.toString();
                    } else if (resp && resp.pulls.length === 0) {
                      // If no response at all, just updateException
                      this.noPullIdsFound = true;
                    } else if (resp && resp.pulls.length > 1) {
                      // If multiple values, just update Exception
                      // (This will change in future releases)
                      this.bookingNumber = null;
                      this.pullId = null;
                    }
                  })
                )
                .subscribe();
            }
          })
        )
        .subscribe();
    }
  }
}

interface ActionUiConfig {
  actions?: string[];
  status?: string;
  title?: string;
  isActionAllowed?: boolean;
}
