<div class="add-driver_container">
  <h1 mat-dialog-title>
    Add Driver
    <xpo-dialog-close-button></xpo-dialog-close-button>
  </h1>
  <div class="add-driver-dialog-container">
    <div class="add-driver-dialog-content">
      <xpo-filter-bar class="xpo-filter-bar"
          xpo-filter-host>
        <div class="xpo-filter-bar-wrapper">
          <div>
            <mat-radio-group [(ngModel)]="searchType"
                (ngModelChange)="searchTypeChange($event)">
              <mat-radio-button value="searchBySic">Search by SIC</mat-radio-button>
              <mat-radio-button value="searchByEmpId"
                  class="xpo-filter-bar-wrapper-last-button">
                Search by Employee ID
              </mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="xpo-filter-bar-filters">
            <xpo-ltl-sic-switcher #xpoSicSwitcher
                [sicCd]="selectedSic"
                class="xpo-filter-bar-filters-sic-filter"
                [hidden]="searchType !== 'searchBySic'"
                (sicChange)="onSicChange($event)">
            </xpo-ltl-sic-switcher>
            <mat-form-field class="xpo-filter-bar-filters-employee-filter"
                floatLabel="never"
                *ngIf="searchType === 'searchByEmpId'">
              <input matInput
                  placeholder="Employee ID"
                  [formControl]="empIdForSearch" />
              <mat-hint>Must be 5 characters! </mat-hint>
            </mat-form-field>
            <mat-form-field *ngIf="searchType === 'searchBySic'"
                class="xpo-filter-bar-filters-quick-filter"
                floatLabel="never">
              <mat-label class="xpo-filter-bar-filters-quick-filter-label">Filter Drivers</mat-label>
              <input matInput
                  type="text"
                  id="filter-text-box"
                  (input)="onFilterTextBoxChanged($event)" />
            </mat-form-field>
          </div>
        </div>
      </xpo-filter-bar>
      <div class="grid-bar-container">
        <xpo-grid-bar class="grid-bar"
            *ngIf="searchType !== 'searchByEmpId'">
          <mat-slide-toggle *ngIf="selectedSic === planningSic"
              xpoSlideToggleSmall
              class="grid-bar-first-button"
              [checked]="showFlexDrivers"
              (change)="toggleFlexDrivers($event)">
            Show Flex Drivers
          </mat-slide-toggle>
          <mat-slide-toggle *ngIf="selectedSic === planningSic"
              xpoSlideToggleSmall
              class="grid-bar-second-button"
              [checked]="showCityDrivers"
              (change)="toggleCityDrivers($event)">
            Show City Drivers
          </mat-slide-toggle>
          <mat-slide-toggle [checked]="showUnavailableDrivers"
              xpoSlideTloggleSmall
              (change)="toggleAvailableDrivers($event.checked)">
            Show Unavailable Drivers
          </mat-slide-toggle>
        </xpo-grid-bar>
      </div>
      <div class="add-driver_container-on-loading"
          *ngIf="showSpinner$ | async">
        <mat-progress-spinner [mode]="'indeterminate'"
            [strokeWidth]="5"
            [diameter]="60"
            class="add-driver_container-on-loading-spinner"></mat-progress-spinner>
      </div>

      <xpo-ag-grid *ngIf="!(showSpinner$ | async)"
          class="add-driver-grid">
        <ag-grid-angular #gridAngular
            rowSelection="multiple"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            (rowDataChanged)="rowDataChanged($event)">
        </ag-grid-angular>
      </xpo-ag-grid>
    </div>
  </div>
  <div mat-dialog-actions
      class="add-driver-dialog-actions">
    <button [disabled]="!isDriverSelected"
        (click)="onAdd($event)"
        mat-flat-button>Add</button>
    <button (click)="cancel()"
        mat-stroked-button>Cancel</button>
  </div>
</div>
