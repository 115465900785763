import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { getAge } from '../../../utils/data-transform-utils';
import { EnumUtil } from '../../../utils/enum-utils';
import { ProgressiveTable } from '../../enums/alerts-dashboard-tables.enum';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';

@Injectable()
export class ProgressiveLoadBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'Progressive Load';
  static _clickSubject = new BehaviorSubject<any>(0);
  clickSubject$ = ProgressiveLoadBoardTemplate._clickSubject.asObservable();
  sidePanelOpts: SidePanelOpts;

  constructor(
    private decimalPipe: DecimalPipe,
    private timeService: XpoLtlTimeService,
    public alertStatus: ExceptionStatusCd
  ) {
    super({
      id: ProgressiveLoadBoardTemplate.templateId,
      name: 'Progressive Load',
      keyField: 'progressiveLoad',
      allowAdditional: false,
      availableColumns: [
        {
          headerName: 'Actions',
          field: 'action',
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          cellRenderer: 'IconsFormatterComponent',
          cellClass: 'iconColumn',
          valueGetter: () => {
            return 'visibility';
          },
          hide: true,
        },
        {
          headerName: ProgressiveTable.AGE,
          field: 'age',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatAge(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          hide: true,
          minWidth: 140,
        },
        {
          headerName: ProgressiveTable.LANE,
          field: 'lane',
          valueGetter: (params) => {
            return params.data.operationException.destinationSicCode;
          },
          minWidth: 75,
        },

        {
          headerName: ProgressiveTable.SHIFT,
          valueGetter: (params) => {
            return EnumUtil.toShiftCodeValue(params.data.operationException.shiftCd);
          },
          field: 'shift',
          minWidth: 75,
        },
        {
          headerName: ProgressiveTable.R_LOADS,
          field: 'r_loads',
          valueGetter: (params) => {
            return this.remainingValue(params.data.operationException.value, 'loads');
          },
          minWidth: 150,
          cellClass: 'ag-right-aligned-cell',
        },
        {
          headerName: ProgressiveTable.R_WEIGHT,
          field: 'r_weight',
          cellRenderer: 'RemainingValueFormatterComponent',
          valueGetter: (params) => {
            return +this.remainingValue(params.data.operationException.value, 'weight');
          },
          minWidth: 150,
          cellClass: 'ag-right-aligned-cell',
        },
        {
          headerName: ProgressiveTable.R_CUBE,
          field: 'r_cube',
          cellRenderer: 'RemainingValueFormatterComponent',
          valueGetter: (params) => {
            return +this.remainingValue(params.data.operationException.value, 'cube');
          },
          minWidth: 150,
          cellClass: 'ag-right-aligned-cell',
        },
        {
          headerName: ProgressiveTable.TIME_DATE,
          field: 'time_date',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatDate(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          minWidth: 150,
        },
        {
          headerName: ProgressiveTable.RESOLUTION_TEXT,
          field: 'resolution_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: ProgressiveTable.COMMENTS_TEXT,
          field: 'comment_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: ProgressiveTable.EXPIRY_TEXT,
          field: 'expiry_text',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
      ],
    });
  }

  createProgressiveLoad(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'ProgressiveLoad',
      name: 'Progressive Load',
    });
  }

  formatDate(date, sic) {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  formatAge(date, sic) {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return getAge(timeDate, timeDateNow);
  }

  remainingValue(remVal, type) {
    const retValue = remVal.split('/');
    switch (type) {
      case 'loads':
        const splitValue: string[] = retValue[0].split('-');
        const remLoads =
          splitValue.length - 1 === 1
            ? `${splitValue[splitValue.length - 1]} ${'Over'}`
            : `${splitValue[splitValue.length - 1]}`;
        return remLoads;
      case 'cube':
        return retValue[1];
      case 'weight':
        return retValue[2];
    }
  }
}
