import { Injectable } from '@angular/core';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { ListEmployeesBySicPath } from '@xpo-ltl/sdk-humanresource';
import {
  LinehaulOperationsApiService,
  ListEquipmentQuery,
  ListEquipmentResp,
  ListTrailersQuery,
  ListTrailersResp,
  TrailerInfo,
} from '@xpo-ltl/sdk-linehauloperations';

import { Dolly } from '../dollies/models/dolly.model';
import { Tractor } from '../tractors/models/tractor.model';
import { Trailer } from '../trailers/models/trailer.model';

import { Equipment } from '@xpo-ltl/sdk-linehauloperations';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EquipmentFilters } from '../models/equipment-filters.model';

@Injectable()
export class EquipmentService {
  private readonly _titleSubject = new BehaviorSubject<string>('');
  private readonly _periodSubject = new BehaviorSubject<Date>(new Date());
  private readonly _viewSubject = new BehaviorSubject<string>('');
  private readonly _shiftSubject = new BehaviorSubject<string>('');
  private readonly _sicSubject = new BehaviorSubject<string>('');

  equipmentFilters = new EquipmentFilters();

  title$ = this._titleSubject.asObservable();
  period$ = this._periodSubject.asObservable();
  view$ = this._viewSubject.asObservable();
  shift$ = this._shiftSubject.asObservable();
  sic$ = this._sicSubject.asObservable();

  constructor(private linehaulService: LinehaulOperationsApiService, private locationApiService: LocationApiService) {}

  set title(value: string) {
    this._titleSubject.next(value);
  }

  getTitle(): Observable<string> {
    return this.title$;
  }

  set period(value: string) {
    this.equipmentFilters.period = value;
    this._periodSubject.next(new Date(value));
  }

  set view(value: string) {
    this.equipmentFilters.view = value;
    this._viewSubject.next(value);
  }

  set shift(value: string) {
    this.equipmentFilters.shift = value;
    this._shiftSubject.next(value);
  }

  set sic(value: string) {
    this.equipmentFilters.sic = value;
    this._sicSubject.next(value);
  }

  get sic() {
    return this._sicSubject.value;
  }

  getAllSICs(): Observable<any> {
    return this.locationApiService.listOperationalServiceCenters(<any>{ listInfo: null });
  }

  listTrailersBySic(state: XpoBoardState): Observable<XpoBoardData<Trailer[]>> {
    const pathParamsAux = new ListEmployeesBySicPath();
    pathParamsAux.sicCd = this._sicSubject.value || 'XMJ';
    return this.linehaulService.listTrailers(<ListTrailersQuery>pathParamsAux, { loadingOverlayEnabled: false }).pipe(
      catchError(() => of({ trailers: [] } as ListTrailersResp)),
      map((listTrailersResp: ListTrailersResp) => {
        const trailers = listTrailersResp.trailers.map((item: TrailerInfo) => new Trailer(item));
        const newBoardData = new XpoBoardData(state, trailers, trailers.length, 10);
        return newBoardData;
      })
    );
  }

  listDolliesBySic(state: XpoBoardState): Observable<XpoBoardData<Dolly[]>> {
    const pathParamsAux = new ListEmployeesBySicPath();
    pathParamsAux.sicCd = this._sicSubject.value || 'XMJ';
    const equipmentQuery = new ListEquipmentQuery();
    equipmentQuery.equipmentTypeCds = ['D'];
    return this.linehaulService.listEquipment(pathParamsAux, equipmentQuery, { loadingOverlayEnabled: false }).pipe(
      catchError(() => of(({ equipment: [] } as unknown) as ListEquipmentResp)),
      map((listDolliesResp: any) => {
        const dollies = listDolliesResp.equipmentSummaries.map((item) => new Dolly(item.equipment));
        const newBoardData = new XpoBoardData(state, dollies, dollies.length, 10);
        return newBoardData;
      })
    );
  }

  listTractorsBySic(state: XpoBoardState): Observable<XpoBoardData<Dolly[]>> {
    const pathParamsAux = new ListEmployeesBySicPath();
    pathParamsAux.sicCd = this._sicSubject.value || 'XMJ';
    const equipmentQuery = new ListEquipmentQuery();
    equipmentQuery.equipmentTypeCds = ['P', 'Z'];
    return this.linehaulService.listEquipment(pathParamsAux, equipmentQuery, { loadingOverlayEnabled: false }).pipe(
      catchError(() => of(({ equipment: [] } as unknown) as ListEquipmentResp)),
      map((listEquipmentResponse: any) => {
        const tractors = listEquipmentResponse.equipmentSummaries.map((item) => item.equipment);
        const newBoardData = new XpoBoardData(state, tractors, tractors.length, 10);
        return newBoardData;
      })
    );
  }
}
