import { forEach as _forEach } from 'lodash';
import { AlertsType } from '../shared/enums/alerts.enum';

export function getAlertType(alert: string) {
  return AlertsType[alert];
}

export function getAge(time, timeDateNow) {
  const delta = Math.abs(timeDateNow - time) / 1000;
  const hour = Math.floor(delta / 3600);
  const minutes = Math.floor(delta / 60) % 60;
  return `${hour < 10 ? `0${hour}` : hour}:${minutes < 10 ? `0${minutes}` : minutes}`;
}

export function getShift(shift: string): string {
  switch (shift) {
    case 'O':
      return 'Outbound';
    case 'I':
      return 'Inbound';
    case 'F':
      return 'FAC';
    case 'D':
      return 'Day';
  }
}
export function getEstimated(date) {
  const estDate = date.split(',');
  return estDate[1];
}
export function getCalculated(date) {
  const delDate = date.split(',');
  return delDate[2];
}

export function setChartColor(seriesValue: number) {
  if (seriesValue <= 3) {
    return '#4caf50';
  } else if (seriesValue <= 6) {
    return '#FFD600';
  } else if (seriesValue > 6) {
    return '#d32f2f';
  }
}

export function orderAlerts(alerts) {
  const orderedAlerts = {
    LOAD_REQUEST: [],
    MISLOAD_GUR: [],
    HSS_UNDER_WGT: [],
    PROG_LOAD: [],
  };
  _forEach(alerts, (alert, name) => {
    if (name === 'LOAD_REQUEST') {
      orderedAlerts.LOAD_REQUEST = alert;
    }
    if (name === 'MISLOAD_GUR') {
      orderedAlerts.MISLOAD_GUR = alert;
    }
    if (name === 'HSS_UNDER_WGT') {
      orderedAlerts.HSS_UNDER_WGT = alert;
    }
    if (name === 'PROG_LOAD') {
      orderedAlerts.PROG_LOAD = alert;
    }
  });
  for (const name in orderedAlerts) {
    if (orderedAlerts[name].length === 0) {
      delete orderedAlerts[name];
    }
  }
  return orderedAlerts;
}
