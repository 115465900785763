import { Component, Input, OnInit } from '@angular/core';
import { ExceptionStatusCd, XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import { TrailerDetailsData } from '../../../../../load-management/allowable-loads/models/trailer-details-data.model';
import { AuthService } from './../../../../services/auth.service';

@Component({
  selector: 'hss-panel',
  templateUrl: './hss-panel.component.html',
  styleUrls: ['./hss-panel.component.scss'],
})
export class HssPanelComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() sidePanelData: any;

  trailerDetailsData: TrailerDetailsData;
  actionSelected: string = 'ack';
  alertData: any;
  showAck: boolean = false;
  showRqsApprovalPanel: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.trailerDetailsData = {
      loadedTimestamp: this.sidePanelData.loadedTimestamp,
      closeTmst: this.sidePanelData.closeTmst,
      statusCd: this.sidePanelData.statusCd,
      load: this.sidePanelData.sidePanelId,
      filterSic: this.sidePanelData.filterSic,
      currentEventTmst: this.sidePanelData.currentEventTmst,
    };

    this.alertData = {
      destination: this.alert.alert.operationException.destinationSicCode,
      closed: this.alert.alert.operationException.employeeName,
      shipments: this.alert.alert.operationException.itemInstIds.length,
      weight: this.alert.alert.operationException.value.split('/')[0],
      door: this.alert.alert.operationException.doorNbr,
      status: this.alert.alert.statusCd,
      cube: this.alert.alert.operationException.value.split('/')[1],
      booking: this.alert.alert.operationException.bookingNumber,
      trailer: this.alert.alert.operationException.trailerNbr,
      exceptionInstId: this.alert.alert.exceptionInstId,
      type: this.type,
    };

    this.showAck = this.checkStatus() && this.actionSelected === 'ack';
    this.canRequestApproval();
  }

  canRequestApproval(): void {
    this.showRqsApprovalPanel = this.authService.allowedToRqsApprovalHSS() && this.status === ExceptionStatusCd.NEW_SIC;
  }

  private checkStatus(): boolean {
    return (
      this.status !== ExceptionStatusCd.CLOSED &&
      this.status !== ExceptionStatusCd.RESOLVED &&
      this.status !== ExceptionStatusCd.EXPIRED
    );
  }
}
