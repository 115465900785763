import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { XpoAdvancedSelectOption } from '../models/index';

@Injectable()
export class XpoAdvancedSelectTreeService {
  filteredOptions$ = new BehaviorSubject<XpoAdvancedSelectOption[]>([]);
  options: XpoAdvancedSelectOption[];

  initialize(initData: XpoAdvancedSelectOption[]): void {
    this.options = initData;
    this.filteredOptions$.next(this.options);
  }

  filter(filterText: string): void {
    if (filterText) {
      const filteredOptions: XpoAdvancedSelectOption[] = [];

      this.options.map((node) => {
        this.filterCallback(filterText, node, filteredOptions);
      });
      this.filteredOptions$.next(filteredOptions);
    } else {
      this.filteredOptions$.next(this.options);
    }
  }

  private filterCallback(
    filterText: string,
    node: XpoAdvancedSelectOption,
    filteredOptions: XpoAdvancedSelectOption[]
  ): void {
    if (node.label?.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1) {
      filteredOptions.push(node);
    } else if (node.children?.length) {
      node.children.map((n) => {
        this.filterCallback(filterText, n, filteredOptions);
      });
    }
  }
}
