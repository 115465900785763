import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoChipsModule,
  XpoDialogModule,
  XpoDownloadButtonModule,
  XpoFeedbackModule,
  XpoPopoverModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { LinehaulOperationsApiService } from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { AgGridModule } from 'ag-grid-angular';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { SpecialServiceFormatterComponent } from './image-gallery-container/formatters/special-service-formatter';
import { SubstringPipe } from './image-gallery-container/formatters/substring-pipe';
import { FullScreenComponent } from './image-gallery-container/full-screen/full-screen.component';
import { ImageGalleryService } from './image-gallery-container/image-gallery-container-service.component';
import { ImageGalleryContainerComponent } from './image-gallery-container/image-gallery-container.component';
import { ImageSidePanelComponent } from './image-gallery-container/image-side-panel/image-side-panel.component';
import { PreviewImage } from './image-gallery-container/preview-image/preview-image.component';
import { RelatedProsComponent } from './image-gallery-container/related-pros/related-pros.component';
import { SendEmailComponent } from './image-gallery-container/utils/send-email/send-email.component';
import { SidePanelConstants } from './side-panel-container-constants.component';
import { SidePanelContainerComponent } from './side-panel-container.component';

const agGridFormatters = [SpecialServiceFormatterComponent];
const dialogs = [SendEmailComponent];
@NgModule({
  declarations: [
    SidePanelContainerComponent,
    ImageGalleryContainerComponent,
    ImageSidePanelComponent,
    FullScreenComponent,
    PreviewImage,
    RelatedProsComponent,
    ...agGridFormatters,
    ...dialogs,
    SubstringPipe,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgxGalleryModule,
    MatDividerModule,
    MatIconModule,
    MatGridListModule,
    XpoCardModule,
    XpoDialogModule,
    MatDialogModule,
    XpoButtonModule,
    XpoDownloadButtonModule,
    MatProgressSpinnerModule,
    XpoAgGridModule,
    XpoChipsModule,
    XpoFeedbackModule,
    AgGridModule,
    MatInputModule,
    XpoPopoverModule,
    XpoFiltersModule,
    AgGridModule.withComponents([...agGridFormatters]),
  ],
  exports: [
    ImageGalleryContainerComponent,
    ImageSidePanelComponent,
    FullScreenComponent,
    PreviewImage,
    ...dialogs,
    RelatedProsComponent,
    SidePanelContainerComponent,
    SubstringPipe,
  ],
  providers: [
    HumanResourceApiService,
    LinehaulOperationsApiService,
    LoggingApiService,
    SidePanelConstants,
    DocumentManagementApiService,
    ImageGalleryService,
    ...dialogs,
    SubstringPipe,
  ],
  entryComponents: [PreviewImage, ...dialogs],
})
export class SidePanelContainerModule {}
