import { Component } from '@angular/core';

@Component({
  selector: 'app-remaining-value-formatter-cell',
  template: `
    <span *ngIf="isNumber">{{ value | number: '1.' }}</span>
    <span *ngIf="!isNumber">{{ value }}</span>
  `,
})
export class RemainingValueFormatterComponent {
  value: number;
  isNumber: boolean = false;

  agInit(params: any): void {
    if (typeof params.value === 'number') {
      this.isNumber = true;
    }
    this.value = params.value;
  }
}
