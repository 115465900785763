export class TabTitles {
  static getTabTitle(title: string) {
    const enums = {
      AddLoadRequest: 'Add Load Request',
      CutLoadRequest: 'Cut Load Request',
      GMisload: 'Misloaded Guaranteed',
      HSS: 'HSS Underweight',
      LoadRequest: 'Load Request',
      PartialBypass: 'Partial Bypass',
      ProgressiveLoad: 'Progressive Load',
    };
    return enums[title];
  }
}
