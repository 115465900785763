import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { maxBy as _maxBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { getAge, getShift } from '../../../utils/data-transform-utils';
import { HSSTable } from '../../enums/alerts-dashboard-tables.enum';
import { AuthService } from '../../services/auth.service';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
@Injectable()
export class HssUnderweightBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'HSS Underweight';
  static _clickSubject = new BehaviorSubject<any>(0);
  clickSubject$ = HssUnderweightBoardTemplate._clickSubject.asObservable();
  getAge = getAge;
  getShift = getShift;
  sidePanelOpts: SidePanelOpts;
  isAllowed: boolean = false;
  constructor(
    private decimalPipe: DecimalPipe,
    private timeService: XpoLtlTimeService,
    private authService: AuthService
  ) {
    super({
      id: HssUnderweightBoardTemplate.templateId,
      name: 'HSS Underweight',
      keyField: 'hssunderweight',
      allowAdditional: false,
      availableColumns: [
        {
          headerName: 'HSS Underweight',
          headerGroupComponent: 'hssWeightTableHeader',
          children: [
            {
              headerName: 'Actions',
              field: 'action',
              minWidth: 60,
              width: 60,
              maxWidth: 60,
              cellRenderer: 'IconsFormatterComponent',
              cellClass: 'iconColumn',
              valueGetter: () => {
                return 'visibility';
              },
              hide: true,
            },
            {
              headerName: HSSTable.AGE,
              field: 'age',
              valueGetter: (params) => {
                return params.data.operationException.auditInfo.createdTimestamp;
              },
              valueFormatter: (params) => {
                return this.formatAge(
                  params.data.operationException.auditInfo.createdTimestamp,
                  params.data.operationException.loggedSic
                );
              },
              hide: true,
              minWidth: 100,
            },
            {
              headerName: HSSTable.LOGGED_SIC,
              field: 'logged_sic',
              valueGetter: (params) => {
                return params.data.operationException.loggedSic;
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.DEST_SIC,
              field: 'dest_sic',
              valueGetter: (params) => {
                return params.data.operationException.destinationSicCode;
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.TRAILER,
              field: 'trailer',
              valueGetter: (params) => {
                return params.data.operationException.trailerNbr;
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.DOOR,
              field: 'door',
              valueGetter: (params) => {
                const doorNbr = params.data.operationException.doorNbr;
                if (!doorNbr) {
                  return '-';
                } else {
                  return doorNbr === 'YARD' ? doorNbr : doorNbr.replace('00', '');
                }
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.WEIGHT,
              field: 'weight',
              cellRenderer: 'HSSWeightFormatterComponent',
              valueGetter: (params) => {
                return parseFloat(params.data.operationException.value.split('/')[0]);
              },
              valueFormatter: (params) => {
                return params.data.operationException.value.split('/')[0];
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.CLOSED_BY,
              field: 'closed_by',
              valueGetter: (params) => params.data.operationException.employeeName,
              minWidth: 180,
            },
            {
              headerName: HSSTable.CUBE,
              field: 'cube',
              filter: 'agNumberColumnFilter',
              filterParams: {
                includeBlanksInLessThan: true,
              },
              valueGetter: (params) => {
                const split = params.data.operationException.value.split('/');
                const value = split.length > 1 ? split[1] : '-';
                return value;
              },
              minWidth: 80,
            },
            {
              headerName: HSSTable.CONTROL_NUMBER,
              field: 'controlNumber',
              valueGetter: (params) => {
                return params.data.operationException.controlNumber;
              },
              minWidth: 100,
              hide: true,
            },
            {
              headerName: HSSTable.STATUS,
              field: 'statusCd',
              minWidth: 80,
              hide: true,
            },
            {
              headerName: HSSTable.TIME_DATE,
              field: 'time_date',
              valueGetter: (params) => {
                return params.data.operationException.auditInfo.createdTimestamp;
              },
              valueFormatter: (params) => {
                return this.formatDate(
                  params.data.operationException.auditInfo.createdTimestamp,
                  params.data.operationException.loggedSic
                );
              },
              minWidth: 100,
            },
            {
              headerName: HSSTable.EXPIRY_TEXT,
              field: 'expiry_text',
              valueGetter: (params) => params.data.resolution,
              hide: true,
              minWidth: 200,
            },
            {
              headerName: HSSTable.RESOLUTION_TEXT,
              field: 'resolution_text',
              cellRenderer: 'RequesterCommentFormatterComponent',
              valueGetter: (params) => params.data.resolution,
              hide: true,
              minWidth: 200,
            },
            {
              headerName: HSSTable.COMMENTS_TEXT,
              cellRenderer: 'RequesterCommentFormatterComponent',
              field: 'comment_text',
              valueGetter: (params) => params.data.resolution,
              hide: true,
              minWidth: 200,
            },
            {
              headerName: HSSTable.RES_EXP_TEXT,
              cellRenderer: 'RequesterCommentFormatterComponent',
              field: 'res_exp_text',
              valueGetter: (params) => params.data.resolution,
              hide: true,
              minWidth: 200,
            },
          ],
        },
      ],
    });
    this.isAllowed = true;
  }

  createHssUnderweight(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'hssunderweight',
      name: 'HSS Underweight',
    });
  }

  getHighestDetail(detailList) {
    if (detailList.length > 1) {
      return _maxBy(detailList, 'expectationDetailSequenceNbr');
    } else {
      return detailList[0];
    }
  }

  formatDate(date, sic) {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  formatAge(date, sic) {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return this.getAge(timeDate, timeDateNow);
  }
}
