<div class="app-AlertAndExceptionHeader-actions">
  <mat-button-toggle-group xpoSmallButton
      class="app-AlertAndExceptionHeader-roleViewToggle"
      (change)="handleRoleViewChange($event.value)">
    <mat-button-toggle value="sic"
        [checked]="roleView === 'sic'">SIC View</mat-button-toggle>
    <mat-button-toggle value="lh"
        [checked]="roleView === 'lh'">LH View</mat-button-toggle>
  </mat-button-toggle-group>

  <mat-slide-toggle [checked]="hssChecked"
      (change)="handleToggle($event, hssChecked, hssCheckedChange)">
    Include HSS
  </mat-slide-toggle>

  <mat-slide-toggle [checked]="showAllAlerts"
      (change)="handleToggle($event, showAllAlerts, showAllAlertsChange)">
    Show All Statuses
  </mat-slide-toggle>
</div>

<div class="app-AlertAndExceptionHeader-tablesWrapper">
  <mat-table *ngFor="let table of alertsAndExceptions"
      [dataSource]="table.values"
      class="app-AlertAndExceptionHeader-table xpo-MatTable--withColumnGroup"
      [class.app-AlertAndExceptionHeader-table--allAlerts]="showAllAlerts">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef
          class="app-AlertAndExceptionHeader-table-groupName">
        {{table.group}}
      </mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="exceptions">
      <mat-header-cell *matHeaderCellDef
          class="app-AlertAndExceptionHeader-table-subGroup xpo-MatTable--borderRight">
        Exceptions
      </mat-header-cell>
    </ng-container>
    <ng-container matColumnDef="alerts">
      <mat-header-cell *matHeaderCellDef
          class="app-AlertAndExceptionHeader-table-subGroup">
        Alerts
      </mat-header-cell>
    </ng-container>

    <ng-container matColumnDef="exceptionNew">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('exceptionNew', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="loadExceptionHasValue(data)">
          {{computeLoadException(data)}}
        </div>
        <div>New</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="exceptionUnderReview">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('exceptionUnderReview', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="(data.exceptionUnderReviewLH > 0 || hssChecked && data.hssUnderReviewLH > 0)">
          {{hssChecked ? ((data.exceptionUnderReviewLH || 0) + (data.hssUnderReviewLH || 0)) : 0}}
        </div>
        <div>Reviewed</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="exceptionClosed">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('exceptionClosed', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="(hssChecked && (data.hssClosed > 0 || data.exceptionClosed > 0))">
          {{hssChecked ? ((data.exceptionClosed || 0) + (data.hssClosed || 0)) : 0}}
        </div>
        <div>Closed</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alertNew">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('alertNew', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="data.alertNew > 0">
          {{data.alertNew || 0}}
        </div>
        <div>New</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alertAcknowledged">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('alertAcknowledged', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField"
          [ngClass]="{'xpo-MatTable--borderRight': showAllAlerts }">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="data.alertAcknowledged > 0">
          {{data.alertAcknowledged || 0}}
        </div>
        <div>Acknowledged</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alertClosed">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('alertClosed', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="data.alertClosed > 0">
          {{data.alertClosed || 0}}
        </div>
        <div>Closed</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alertResolved">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('alertResolved', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField xpo-MatTable--borderRight">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="data.alertResolved > 0">
          {{data.alertResolved || 0}}
        </div>
        <div>Resolved</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="alertExpired">
      <mat-cell *matCellDef="let data"
          (click)="handleCellClicked('alertExpired', table.group, data)"
          class="app-AlertAndExceptionHeader-table-valueField">
        <div class="app-AlertAndExceptionHeader-table-count"
            [class.app-AlertAndExceptionHeader-table-count--nonZero]="data.alertExpired > 0">
          {{data.alertExpired || 0}}
        </div>
        <div>Expired</div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['title']"></mat-header-row>
    <mat-header-row *matHeaderRowDef="['exceptions', 'alerts']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
  </mat-table>
</div>
