<div class="history-container">
  <table mat-table [dataSource]="dataSource" class="history-table">
    <ng-container matColumnDef="occurredTmst">
      <th mat-header-cell *matHeaderCellDef>Date/Time</th>
      <td mat-cell *matCellDef="let element">
        {{ ocurredTime(element.occurredTmst) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="elapsedTm">
      <th mat-header-cell *matHeaderCellDef>ET.</th>
      <td mat-cell *matCellDef="let element">
        {{ elapsedTime(element.elapsedTm) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="occurredSic">
      <th mat-header-cell *matHeaderCellDef>CUR. SIC</th>
      <td mat-cell *matCellDef="let element">
        {{ element.occurredSic }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">
        {{ element.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="originDest">
      <th mat-header-cell *matHeaderCellDef>ORI. SIC</th>
      <td mat-cell *matCellDef="let element">{{ setOriDes(element.originSic, element.destinationSic) }}</td>
    </ng-container>
    <ng-container matColumnDef="loadReleaseNbr">
      <th mat-header-cell *matHeaderCellDef>LD. REL.</th>
      <td mat-cell *matCellDef="let element">
        {{ element.loadReleaseNbr }}
      </td>
    </ng-container>
    <ng-container matColumnDef="loadDestinationSic">
      <th mat-header-cell *matHeaderCellDef>LD. DES.</th>
      <td mat-cell *matCellDef="let element">
        {{ element.loadDestinationSic }}
      </td>
    </ng-container>
    <ng-container matColumnDef="trailerNbr">
      <th mat-header-cell *matHeaderCellDef>Trailer</th>
      <td mat-cell *matCellDef="let element">
        {{ element.trailerNbr }}
      </td>
    </ng-container>
    <ng-container matColumnDef="weightCubeBls">
      <th mat-header-cell *matHeaderCellDef>WT./CU./BL</th>
      <td mat-cell *matCellDef="let element">
        {{ setWCL(element.trailerWeightLbs, element.trailerCubePct, element.shipmentsOnTrailerCnt) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="updtBy">
      <th mat-header-cell *matHeaderCellDef>Updated By</th>
      <td mat-cell *matCellDef="let element">
        {{ userName(element.updtBy) | async }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols"></tr>
  </table>
</div>
