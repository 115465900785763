<mat-form-field #origin="cdkOverlayOrigin"
    class="xpo-AdvancedSelect-formField"
    cdk-overlay-origin
    [floatLabel]="'always'"
    (keyup)="searchInputValue = $event.target.value">
  <mat-chip-list #chipList>
    <div class="xpo-AdvancedSelect-selected-options">
      <mat-chip *ngFor="let opt of selectedOptions | slice:0:6"
          [removable]="true"
          (removed)="remove(opt)">
        {{ opt }}
        <button mat-icon-button
            matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <span class="xpo-AdvancedSelect-ellipsis"
          *ngIf="selectedOptions?.length > 5">...</span>
    </div>

    <input #optionsInput
        matInput
        autocomplete="off"
        type="text"
        [placeholder]="searchInputValue ? '' : placeholder"
        (focus)="open()"
        [matChipInputFor]="chipList">

    <mat-icon matSuffix
        class="xpo-AdvancedSelect-arrowIcon">arrow_drop_down</mat-icon>
  </mat-chip-list>
</mat-form-field>

<ng-template cdk-connected-overlay
    cdkConnectedOverlayLockPosition
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="panelOpen"
    [cdkConnectedOverlayPositions]="_positions"
    [cdkConnectedOverlayOffsetY]="40"
    [cdkConnectedOverlayMinWidth]="optionsInputWidth"
    (backdropClick)="close()"
    (detach)="close()">
  <xpo-advanced-select-panel class="xpo-AdvancedSelect-panel"
      searchBarVisibility="hidden"
      [showSelectAll]="true"
      [searchValue]="searchInputValue"
      [options]="options"
      [selection]="selectedOptions"
      [useExternalSearch]="true"
      [suppressIncludeParentCode]="true"
      (selectionChange)="handleSelectionChange($event)">
  </xpo-advanced-select-panel>
</ng-template>
