import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoadedTrailerImageDetail } from '@xpo-ltl/sdk-linehauloperations';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryImageSize, NgxGalleryOptions } from 'ngx-gallery-9';

@Component({
  selector: 'image-slideshow',
  templateUrl: './image-slideshow.component.html',
  styleUrls: ['./image-slideshow.component.scss'],
})
export class ImageSlideshowComponent implements OnInit, OnChanges {
  @Input() images: LoadedTrailerImageDetail[];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() {}

  ngOnInit() {
    this.galleryOptions = [
      {
        startIndex: 0,
        width: '100%',
        height: '350px',
        thumbnailsRows: 1,
        imageAnimation: NgxGalleryAnimation.Slide,
        imagePercent: 85,
        thumbnailsMargin: 10,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        preview: false,
        imageSize: NgxGalleryImageSize.Contain,
        thumbnailSize: NgxGalleryImageSize.Contain,
        imageArrows: true,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.images) {
      this.transformToImageGalleryArray(this.images);
    }
  }

  private transformToImageGalleryArray(images: LoadedTrailerImageDetail[]) {
    this.galleryImages = [];
    images.forEach((img) => {
      // const tmst = this.timeService.formatDate(
      //   img.imageTakenTimestamp,
      //   'YYYY-MM-DD HH:mm',
      //   this.loadedTrailerSearchRecord.loadingSic
      // );
      const imgValue = 'data:image/jpeg;base64,' + img.thumbnail.replace(/[\r\n]/g, '');
      this.galleryImages.push({
        // description: tmst,
        small: imgValue,
        medium: imgValue,
        big: imgValue,
      });
    });
  }
}
