import { ModelGroupCd } from '@xpo-ltl/sdk-common';

export function getGroupCodeFromTimezone(timezone: string): ModelGroupCd {
  const modelGroups = {
    PST: ModelGroupCd.WESTERN,
    CST: ModelGroupCd.CENTRAL,
    EST: ModelGroupCd.EASTERN,
  };
  return modelGroups[timezone];
}
