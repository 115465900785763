<div class="general-container"
    *ngIf="isActionAllowed">
  <h1 mat-dialog-title>
    {{ actionUiConfig.title }}
  </h1>
  <div class="form-subcontainer">
    <ng-container *ngIf="controlsConfig.length > 0; else explainLabel">
      <mat-radio-group class="tp-radio-group"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="selectedAction"
          (change)="actionChange($event.source.value)">
        <ng-container *ngIf="actionUiConfig.actions.length > 0">
          <mat-radio-button class="tp-radio-button"
              *ngFor="let action of actionUiConfig.actions"
              [value]="action">
            {{ action }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <form class="form"
          [formGroup]="form"
          *ngIf="!!form">
        <ng-container *ngIf="form.controls['controlNumber'] && selectedAction === ExceptionAlertAction.APPROVE">
          <span>{{ controlLabels.controlNumber }}</span>
          <mat-form-field>
            <input matInput
                formControlName="controlNumber"
                type="string"
                [value]="selectedAction === ExceptionAlertAction.APPROVE ? controlNumber.value : ''" />
            <mat-error *ngIf="form.get('controlNumber').hasError('required')">
              <mat-icon>warning</mat-icon>
              Control number field is required.
            </mat-error>
            <mat-error *ngIf="hasError('maxlength', form, 'controlNumber')">
              <mat-icon>warning</mat-icon>
              Control number is too long.
            </mat-error>
          </mat-form-field>
        </ng-container>
        <ng-container *ngIf="form.controls['comment']">
          <span>{{ controlLabels.comment }}</span>
          <mat-form-field>
            <textarea matInput
                formControlName="comment"></textarea>
            <mat-hint align="end">{{ comment.value.length }} / 100 Chars</mat-hint>
            <mat-error *ngIf="commentInvalid">
              <mat-icon>warning</mat-icon>
              {{ getCommentErrorMessage() }}
            </mat-error>
            <mat-error *ngIf="commentTooLong">
              <mat-icon>warning</mat-icon>
              {{ getCommentErrorMessage() }}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </form>
    </ng-container>
    <ng-template #explainLabel>
      <span class="explanation-text">
        Close the HSS exception to mark it as completed
      </span>
    </ng-template>
  </div>

  <ng-container>
    <div class="mat-dialog-actions"
        *ngIf="selectedAction === ExceptionAlertAction.APPROVE && !noPullIdsFound">
      <button mat-flat-button
          (click)="updateHss()">
        {{ buttonText | uppercase }}
      </button>
    </div>
    <div class="mat-dialog-actions"
        *ngIf="selectedAction === ExceptionAlertAction.APPROVE && noPullIdsFound">
      <button mat-flat-button
          disabled="true">
        {{ buttonText | uppercase }}
      </button>
      <mat-error> No pull ids found to approve this HSS Exception.</mat-error>
    </div>
    <div class="mat-dialog-actions"
        *ngIf="selectedAction !== ExceptionAlertAction.APPROVE">
      <button mat-flat-button
          (click)="updateHss()">
        {{ buttonText | uppercase }}
      </button>
    </div>
  </ng-container>
</div>
