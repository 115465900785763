<div>
  <h1>
    Move Lane Details
  </h1>
  <div class="details">
    <div class="details-data"
        [ngClass]="{ fullwidth: !canEdit }">
      <div class="details-data-item">
        <label>MOVE TO LANE</label>
        <span>{{ destinationSicCode }}</span>
      </div>
      <div class="details-data-item">
        <label>LOADS OFF DOCK</label>
        <span>{{ loadsOffDock }}</span>
      </div>
      <div class="details-data-item">
        <label>LOADS</label>
        <span>{{ loadRequestedQuantity }}</span>
      </div>
      <div class="details-data-item"
          *ngIf="!canEdit">
        <label>REASON CODE</label>
        <span>{{ requestReason }}</span>
      </div>
    </div>
    <div class="details-reason"
        *ngIf="canEdit">
      <mat-form-field class="details-reason-select">
        <mat-select disableOptionCentering
            placeholder="Reason Code"
            [(value)]="requestReasonCd"
            (selectionChange)="selectedReason($event)"
            panelClass="side-panel-edit-reason">
          <mat-option *ngFor="let reason of loadRequestReasonCodes"
              [value]="reason.reasonCd">
            {{ reason.reasonDescription }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="details-comment">
      <label>Comment</label>
      <textarea name="comment"
          disabled>{{ comments }}</textarea>
    </div>
  </div>
</div>
