import { TrailerInfo } from '@xpo-ltl/sdk-linehauloperations';

export class Trailer {
  trailerNum: string;
  loadDest: string;
  door: string;
  loadStatus: string;
  weight: number;
  cube: number;
  length: number;

  constructor(trailer: TrailerInfo) {
    this.trailerNum = `${trailer.equipmentIdPrefix.replace(/^0/, '')}-${String(trailer.equipmentIdSuffixNbr).padStart(
      4,
      '0'
    )}`;
    this.loadDest = trailer.loadDestSic;
    this.door = trailer.evntDoor;
    this.loadStatus = trailer.currentStatus;
    this.cube = trailer.loadedCubePct;
    this.weight = trailer.loadedWgtLbs;
    this.length = trailer.trailerLengthFeet;
  }
}
