import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TablesDataSourceService extends XpoBoardDataSource {
  dataSource: any;
  constructor() {
    super();
  }

  fetchData(state: XpoBoardState): Observable<any> {
    if (this.dataSource.length) {
      return of(new XpoBoardData(state, this.dataSource, this.dataSource.length, 0));
    } else {
      return of(new XpoBoardData(state, [], 0, 0));
    }
  }
}
