<div class="image-gallery-container">
  <div class="slim-version" *ngIf="!fullscreen && !noResults">
    <app-image-side-panel
      [selectedImage]="selectedImage"
      [galleryImages]="galleryImages"
      [containerData]="loadedTrailerSearchRecord"
      [totalShipmentsCount]="totalShipmentsCount"
      [trailerStatus]="trailerStatus"
      [trailerImages]="trailerImages"
      [employeeName]="employeeName"
      [closedByEmployeeId]="closedByEmployeeId"
      [panelOpts]="panelOpts"
      *ngIf="!searchingImages"
    >
    </app-image-side-panel>
    <mat-progress-spinner class="gallery-spinner" mode="indeterminate" *ngIf="searchingImages && !noResults">
    </mat-progress-spinner>
  </div>
  <div class="full-version" *ngIf="fullscreen && !noResults">
    <app-full-screen
      [selectedImage]="selectedImage"
      [containerData]="loadedTrailerSearchRecord"
      [totalShipmentsCount]="totalShipmentsCount"
      [trailerStatus]="trailerStatus"
      [relatedProsByImage]="galleryImages"
      [employeeName]="employeeName"
      [closedByEmployeeId]="closedByEmployeeId"
      [panelOpts]="panelOpts"
    >
    </app-full-screen>
  </div>
  <div class="no-results" *ngIf="noResults" [ngClass]="{ noResultsAL: !panelOpts.isDashboard }">
    No Trailer Photos Found
  </div>
</div>
