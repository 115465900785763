import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { interval, Subscription } from 'rxjs';
import { ConfigManagerPropertiesEnum } from '../side-panel-container/image-gallery-container/enums/config-manager-properties.enum';

@Component({
  selector: 'app-meter',
  templateUrl: './meter.component.html',
  styleUrls: ['./meter.component.scss'],
})
export class MeterComponent implements OnInit, OnDestroy, OnChanges {
  @Input() timeValue;
  @Input() amountValue;
  @Input() label: string;
  @Input() diameter: number = 120;
  @Input() selected: boolean = false;
  @Input() aging: boolean = true;

  timer: number;
  percentValue: number = 0;
  focusDiameter: number = 150;
  colorClass: string;
  isDeterminate: boolean = true;
  spinnerStyle: {};

  private meterTimer: Subscription;
  private hover = false;
  private timerBorder = [];
  private percentsValues = [0, 25, 50, 75, 100];

  constructor(private configManagerService: ConfigManagerService) {
    // const timersValues = this.configManagerService.getSetting<Array<number>>(ConfigManagerPropertiesEnum.timersValues);
    const timersValues = [5, 10, 15, 20];
    this.timerBorder = timersValues.map((value: number) => {
      return value * 60;
    });
    this.timerBorder.unshift(0);
  }

  ngOnInit() {
    if (typeof this.timeValue === 'object') {
      this.timeValue = this.timeValue.oldestDate;
    }
    if (typeof this.amountValue === 'object') {
      this.amountValue = this.amountValue.count;
    }
    this.focusDiameter = Math.floor(this.diameter * 1.25);
  }

  private getAge() {
    /*TODO there is no need to convert timeValue as a Date when we will point to BE*/
    const timestamp = new Date(this.timeValue).valueOf();
    return Math.abs((new Date().valueOf() - timestamp) / 1000 / 60);
  }

  ngOnDestroy() {
    this.cleanTimer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.amountValue) {
      this.unsubscribeTimer();

      if (this.aging) {
        this.createTimer();
      }
    }
    if (changes.diameter) {
      this.focusDiameter = Math.floor(this.diameter * 1.25);
    }

    if (changes.aging) {
      if (this.aging) {
        if (!this.meterTimer) {
          this.createTimer();
        }
      } else {
        if (this.meterTimer) {
          this.cleanTimer();
        }
        this.spinnerStyle = { color: '#bdbdbd' };
        this.percentValue = 100;
      }
    }
  }

  private createTimer() {
    this.timer = this.getAge();
    this.startTimer(this.timer);
  }

  private unsubscribeTimer() {
    if (this.meterTimer) {
      this.meterTimer.unsubscribe();
    }
  }

  private cleanTimer() {
    this.percentValue = 0;
    this.timer = 0;
    this.setSpinnerColor(0);
    this.unsubscribeTimer();
  }

  private setSpinnerColor(group: number) {
    const colors = ['transparent', '#333333', '#ffd600', '#fe913f', '#d32f2f'];

    this.colorClass = `group-${group}`;
    this.spinnerStyle = { color: colors[group] };
  }

  private setPercent(seconds: number, fraction: number, base: number) {
    this.percentValue = (seconds * fraction) / base;
    if (this.percentValue > 100) {
      this.unsubscribeTimer();
    }
  }

  private startTimer(timer) {
    if (this.amountValue === 0) {
      this.percentValue = 0;
      this.setSpinnerColor(0);
      return;
    }
    let secondsValue = this.timeToSeconds(timer);
    this.getTimersGroup(secondsValue);

    const timer$ = interval(1000);
    this.meterTimer = timer$.subscribe(() => {
      this.getTimersGroup(secondsValue);
      secondsValue++;
    });
  }

  private getTimersGroup(seconds: number) {
    const defaultGroup = this.timerBorder.length - 1;
    let actualGroup = defaultGroup;
    for (let i = 0; i < this.timerBorder.length && actualGroup === defaultGroup; i++) {
      if (this.timerBorder[i] >= seconds) {
        actualGroup = i;
      }
    }
    this.setSpinnerColor(actualGroup);
    this.setPercent(seconds, this.percentsValues[actualGroup], this.timerBorder[actualGroup]);
  }

  private timeToSeconds(minutes: number): number {
    return minutes * 60;
  }

  setHover(value: boolean) {
    this.hover = value;
  }

  getDiameter(): number {
    return this.selected ? this.focusDiameter : this.diameter;
  }
}
