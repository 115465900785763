<lane-breakdown *ngIf="modelData.topTable"
    class="section"
    [modelData]="modelData"
    [type]="type"
    [alert]="alert"></lane-breakdown>
<history [exception]="alert"
    [externalUser]="isExternalUser"></history>
<acknowledge-tab *ngIf="showAck"
    [status]="status"
    [type]="type"
    [alert]="alert"></acknowledge-tab>
