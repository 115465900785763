import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActiveSic } from '@xpo-ltl-2.0/sdk-location';
import { XpoBoardData, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ModelGroupCd, UserRoleCd } from '@xpo-ltl/sdk-common';
import { GetEmployeeDetailsByEmpIdPath, HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import {
  ChangesContent,
  LinehaulOperationsApiService,
  ListChangesForLanesPath,
  ListChangesForLanesResp,
  ListLoadRequestsResp,
  ListModelInstructionsPath,
  ListModelInstructionsQuery,
  ListModelInstructionsResp,
  LoadLaneInstructionSummary,
  LoadRequest,
  MoveLaneInstructionSummary,
  TrailerLoadInfo,
  UpsertLoadRequestRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import { GetServiceCenterDetailsBySicPath, ListLocationGroupCodesResp, LocationGroupCode } from '@xpo-ltl/sdk-location';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, share, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { PDChangesFeedHandlerService } from '../../../performance-dashboard/services/pd-changes-feed-handler.service';
import { AllowableLoadsTabsEnum } from '../../../shared/enums/allowable-loads-tabs.enum';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { getGroupCodeFromTimezone } from '../../../shared/functions/get-group-code-from-timezone';
import { InteractionServiceResp } from '../../../shared/models/interaction-service-response.model';
import { LaneDataType } from '../../../shared/models/lane-data-type';
import { AuthService } from '../../../shared/services/auth.service';
import { InteractionService } from '../../../shared/services/interaction.service';
import { LaneDataTypesService } from '../../../shared/services/lane-data-types.service';
import { LinehaulService } from '../../../shared/services/linehaul/linehaul.service';
import { LocationService } from '../../../shared/services/location.service';
import { UserRoleService } from '../../../shared/services/user-role/user-role.service';
import { EnumUtil } from '../../../utils/enum-utils';
import { ListModelInstructionsResponse } from '../models/list-model-instruction-resp.model';
import { ChangeViewService } from './../services/change-view.service';

@Injectable()
export class AllowableLoadsService implements OnDestroy {
  private planDate: any;
  private sic: string;
  private shift: string;
  private boardDataAux: XpoBoardData;
  private namesList: { [key: string]: string } = {};
  private interactionSubscription: Subscription;
  private requestFullName: { [key: string]: Observable<string> } = {};
  private testUsers: string[] = ['LTLAPP_USER'];
  private route: String;
  region: string;
  nameByID: Observable<string>[] = [];
  lastPlanDate: string;
  lastShift: string = '';
  isBypassModel: boolean = false;
  releaseStatus: boolean = false;
  releaseStatusEnum = { RELEASED: 'Released' };
  view;
  sicOffset = new Subject<string>();
  allActiveSics: ActiveSic[];

  constructor(
    private pDChangesFeedHandlerService: PDChangesFeedHandlerService,
    private linehaulOpApiService: LinehaulOperationsApiService,
    private locationService: LocationService,
    private interactionService: InteractionService,
    private humanResourceApiService: HumanResourceApiService,
    private laneDataTypesService: LaneDataTypesService,
    private loggingApiService: LoggingApiService,
    private userRoleService: UserRoleService,
    private xpoSnackBar: XpoSnackBar,
    datePipe: DatePipe,
    private changeViewService: ChangeViewService,
    private linehaulService: LinehaulService,
    private authService: AuthService
  ) {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(
        filter((resp: InteractionServiceResp) => resp.data && Object.keys(resp.data).length > 0),
        switchMap((resp) => of(resp))
      )
      .subscribe((resp: InteractionServiceResp) => {
        this.sic = resp.data.sic;
        this.planDate = datePipe.transform(resp.data.planDate, 'yyyy-MM-dd');
        this.region = resp.data.region;
        this.shift = resp.data.shift ? resp.data.shift.substring(0, 1) : 'O';
      });
    this.interactionService.getRoute().subscribe((route) => {
      this.route = route;
    });
    this.changeViewService.getCurrentView().subscribe((view) => {
      this.view = view;
    });
    this.linehaulService.allActiveSics$.subscribe((activeSics) => (this.allActiveSics = activeSics));
  }

  getListChangesForLanes(state: XpoBoardState): Observable<XpoBoardData> {
    const emptyBoard = new XpoBoardData(state, [], 0, 0);
    const queryParams: ListChangesForLanesPath = {
      currentSicCd: this.sic,
      currentShiftCd: this.shift,
      runDate: this.planDate as any,
    };
    if (this.sic) {
      return this.linehaulOpApiService
        .listChangesForLanes(queryParams, { toastOnError: false, loadingOverlayEnabled: false })
        .pipe(
          map((resp: ListChangesForLanesResp) => {
            if (resp.changesContents && resp.changesContents.length) {
              resp.changesContents.forEach((value) => {
                const userId = value.modifiedBy;
                this.getEmployeeName(value.modifiedBy, userId, value);
              });
              return new XpoBoardData(state, resp.changesContents, resp.changesContents.length, 50);
            } else {
              return emptyBoard;
            }
          }),
          catchError((err) => {
            console.log(err);
            return of(emptyBoard);
          })
        );
    } else {
      return of(emptyBoard);
    }
  }

  getListModelInstructions(
    state: XpoBoardState,
    type: string,
    lane: XpoBoardData,
    refreshing: boolean,
    sic?: string
  ): Observable<XpoBoardData> {
    const emptyBoard = new XpoBoardData(state, [], 0, 0);
    if (sic) {
      this.sic = sic;
    }

    // apply filters on existing data if any - doing this on the frontend for now for loads
    if (state.criteria && Object.values(state.criteria).some((v) => v)) {
      // Unreachable code - begin
      const filteredData = this.filterBoardData(state, this.boardDataAux);
      return filteredData.length ? of(new XpoBoardData(state, filteredData, filteredData.length, 50)) : of(emptyBoard);
      // Unreachable code - end
    }
    if (this.sic && this.route === 'load-management') {
      if (
        (refreshing && type === 'Lanes' && this.view === 'lanes-view') ||
        (refreshing && type === 'Loads' && this.view === 'loads-view')
      ) {
        this.lastPlanDate = this.planDate;
        this.lastShift = this.shift;
        return this.generateBoard(emptyBoard, state, lane, type);
      } else {
        this.lastPlanDate = this.planDate;
        this.lastShift = this.shift;
        return this.generateBoard(emptyBoard, state, lane, type);
      }
    } else {
      return of(emptyBoard);
    }
  }

  upsertLoadRequest(loadRequestData: any, laneData): Observable<boolean> {
    const upsertLoadRequest = new UpsertLoadRequestRqst();
    const loadRequest = new LoadRequest();
    loadRequest.requestType = loadRequestData.requestType;
    loadRequest.requestQuantity = loadRequestData.numberOfLoads;
    loadRequest.reasonCd = loadRequestData.reason;
    loadRequest.requestorComments = loadRequestData.comment;
    loadRequest.loadToSicCode = loadRequestData.closeTo;
    loadRequest.moveToSicCode = laneData.lane;
    loadRequest.planDate = this.planDate;
    loadRequest.planShiftCd = this.shift;
    loadRequest.originSicCode = this.sic;
    loadRequest.requestorName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
    loadRequest.requestorEmplid = this.userRoleService.user.employeeId;
    loadRequest.regionCd = this.allActiveSics.find((sic) => sic.sicCd === this.sic).region;

    upsertLoadRequest.loadRequest = loadRequest;

    // REFACTOR review this
    return this.linehaulOpApiService.upsertLoadRequest(upsertLoadRequest).pipe(
      catchError((err) => {
        this.loggingApiService.error(
          this.userRoleService.user.userId +
            'UpsertLoadRequest in Load Management: ' +
            this.userRoleService.build +
            '- SIC:' +
            this.sic +
            '- Lane: ' +
            laneData.lane +
            '- Loads: ' +
            loadRequestData.loadsNeeded +
            '- Shift: ' +
            EnumUtil.toShiftCodeValue(this.shift) +
            '- Additonal Loads Requested : ' +
            loadRequestData.loadsNeeded,
          this.authService.getEmployeeSic(),
          'Creation of Load Request from LoMa',
          'PUT',
          'LoMa'
        );
        this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message}`);
        return of(false);
      }),
      map((response: ListLoadRequestsResp) => {
        if (response) {
          const userSic = this.authService.getEmployeeSic();
          this.loggingApiService.info(
            this.userRoleService.user.userId +
              'UpsertLoadRequest in Load Management: ' +
              this.userRoleService.build +
              '- SIC:' +
              this.sic +
              '- Lane: ' +
              laneData.lane +
              '- Loads: ' +
              loadRequestData.loadsNeeded +
              '- Shift: ' +
              EnumUtil.toShiftCodeValue(this.shift) +
              '- Additonal Loads Requested : ' +
              loadRequestData.loadsNeeded,
            userSic,
            'Creation of Load Request from LoMa',
            'PUT',
            'LoMa'
          );
          this.pDChangesFeedHandlerService.headerTrigger$.next(false);
          return true;
        } else {
          return false;
        }
      })
    );
  }

  private getEmployeeName(empId: string, userId: string, row: ChangesContent): void {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;

    if (this.testUsers.includes(empId || userId)) {
      this.namesList[userId] = userId;
      row['modifiedByName'] = '';
    } else if (this.requestFullName[userId]) {
      this.requestFullName[userId].subscribe((fullName: string) => {
        this.namesList[userId] = fullName;
        row['modifiedByName'] = fullName;
      });
    } else {
      this.requestFullName[userId] = this.humanResourceApiService
        .getEmployeeDetailsByEmpId(pathParams, null, { toastOnError: false, loadingOverlayEnabled: false })
        .pipe(
          take(1),
          shareReplay(1),
          map((res) => {
            return `${res.employee.basicInfo.firstName} ${res.employee.basicInfo.lastName}`;
          })
        );

      this.requestFullName[userId].subscribe((fullName) => {
        this.namesList[userId] = fullName;
        row['modifiedByName'] = fullName;
      });
    }
  }

  private generateBoard(
    emptyBoard: XpoBoardData,
    state: XpoBoardState,
    lane: XpoBoardData,
    type: string
  ): Observable<XpoBoardData> {
    return this.locationService.getModelGroupCode(this.sic).pipe(
      map((resp: ListLocationGroupCodesResp) => resp.locationGroupCodes),
      mergeMap((modelGroupCode: LocationGroupCode[]) => {
        const modelGroupCd = getGroupCodeFromTimezone(modelGroupCode[0].regionCd);
        if (this.sic) {
          return this.getLaneInstructions(this.sic, this.planDate, this.shift, modelGroupCd).pipe(
            map((instr: ListModelInstructionsResponse) => {
              if (instr) {
                this.releaseStatus = instr.releaseStatus === this.releaseStatusEnum.RELEASED;
                const flattenedData = this.transformApiData(instr.moveLaneInstructionSummary);
                const transformedLaneData = this.transformLaneInstructionsData(instr.moveLaneInstructionSummary);

                const isBypassmodel = instr.moveLaneInstructionSummary.some(
                  (instrr) =>
                    !!instrr.instructionSummary.sourceCd &&
                    (instrr.instructionSummary.sourceCd.toUpperCase() === 'B' ||
                      instrr.instructionSummary.sourceCd.toUpperCase() === 'E')
                );
                this.isBypassModel = isBypassmodel;

                this.interactionService.setALAmount(transformedLaneData.length, AllowableLoadsTabsEnum.LANES);
                return type === AllowableLoadsTabsEnum.LANES
                  ? this.setDataType(transformedLaneData, state, lane)
                  : this.setDataType(flattenedData, state, lane);
              } else {
                return emptyBoard;
              }
            }),
            catchError((err) => {
              console.log(err);
              return of(emptyBoard);
            })
          );
        } else {
          return of(emptyBoard);
        }
      })
    );
  }

  private setDataType(tableData, state: XpoBoardState, lane?: XpoBoardData): XpoBoardData {
    const orderedData = tableData.length ? this.orderData(tableData, lane) : tableData;

    if (state.viewId === 'LanesView') {
      orderedData.forEach((dataPoint, index) => {
        dataPoint.id = index;
        dataPoint.children.forEach((dataChild) => {
          dataChild.groupId = index;
        });
      });
    }

    const boardDataAux = new XpoBoardData(state, orderedData, orderedData.length, 50);
    return boardDataAux;
  }

  private orderData(data, lane?: XpoBoardData): any {
    return lane
      ? data.filter((l) => l.lane === lane)
      : data.filter((l) => l.lane === this.sic).concat(data.filter((l) => l.lane !== this.sic));
  }

  // Unreachable code - begin
  private filterBoardData(state: XpoBoardState, boardData: XpoBoardData): any {
    const filteredBoardData = [];

    Object.values(boardData.consumerData).forEach((data) => {
      let same = true;
      for (const comp in state.criteria) {
        if (state.criteria[comp].includes('-')) {
          if (same) {
            const twoNumbers = state.criteria[comp].split('-');
            same = Number(data[comp]) >= Number(twoNumbers[0]) && Number(data[comp]) <= Number(twoNumbers[1]);
          }
        } else {
          if (same) {
            same = String(data[comp]).includes(comp.toUpperCase());
          }
        }
      }
      if (same) {
        filteredBoardData.push(data);
      }
    });
    return filteredBoardData;
  }
  // Unreachable code - end

  private transformLaneInstructionsData(apiData: MoveLaneInstructionSummary[]): any {
    const apiTransformedData = apiData
      ? apiData.map((data: MoveLaneInstructionSummary) => {
          const laneData = data.instructionSummary; // lane
          const loadData = data.loadLaneInstructionSummary; // loads for the above lane
          const closeToSicList = [];
          const closeToSicsWithPlannedLoadCount = [];
          const loadsList = loadData.map((load: LoadLaneInstructionSummary) => {
            let childTrailers: LaneDataType[] = [];
            const loadInstr = load.loadLaneInstructionSummary;
            closeToSicList.push(load.loadLaneInstructionSummary.closeToSicCd);
            // https://xpo.atlassian.net/browse/EFOS-1445
            if (!!load.loadLaneInstructionSummary.planned) {
              const closeTo = load.loadLaneInstructionSummary.closeToSicCd;
              const plannedForCloseTo = load.loadLaneInstructionSummary.planned.loadCount;
              closeToSicsWithPlannedLoadCount.push({ closeTo: closeTo, plannedCount: plannedForCloseTo });
            }

            if (load.trailerLoadInfo) {
              load.trailerLoadInfo.forEach((trailer: TrailerLoadInfo) => {
                const tra: LaneDataType = {
                  filterSic: this.sic,
                  lane: load.loadLaneInstructionSummary.lane,
                  closeToSicCd: load.loadLaneInstructionSummary.closeToSicCd,
                  load: `${trailer.trailerLoadHist.equipmentIdPrefix.replace(/^0/, '')}-${String(
                    trailer.trailerLoadHist.equipmentIdSuffixNbr
                  ).padStart(4, '0')}`,
                  equipmentInstId: trailer.trailerLoadHist.equipmentId,
                  guaranteedInd: trailer.trailerLoadHist.guaranteedServiceInd,
                  hazmatInd: trailer.trailerLoadHist.hazmatInd,
                  frzblInd: trailer.trailerLoadHist.frzblInd,
                  ptlTrlrInd: trailer.ptlTrlrInd,
                  bypassInd: trailer.bypassTrlrInd,
                  loadedTimestamp: trailer.trailerLoadHist.loadedDateTime,
                  // update after api's has them
                  hssLoadCount: laneData['hssLoadCount'],
                  bypassLoadCount: laneData.bypassLoadCount,
                  loadedWeight: trailer.trailerLoadHist.loadedWeight,
                  loadedCube: trailer.trailerLoadHist.loadedCbePercentage,
                  exception: trailer.exceptionInd,
                  sourceCd: trailer.source,
                  statusCd: trailer.trailerLoadHist.currentStatus,
                  headLoadInd: !!trailer.headLoadDetail && !!Object.entries(trailer.headLoadDetail).length,
                  headLoadDetail: trailer.headLoadDetail,
                  loadedShipment: trailer.loadedShipment,
                  misloadInd: trailer.loadedShipment ? !!trailer.loadedShipment.find((shp) => shp.misloadInd) : false,
                  eventDoor: trailer.trailerLoadHist.eventDoor,
                  originSic: trailer.trailerLoadHist.originSic,
                  currentEventTmst: trailer.trailerLoadHist.currentEventDateTime,
                  closeTmst: trailer.trailerLoadHist.closeDateTime,
                };
                childTrailers.push(tra);
              });
            }
            if (childTrailers.length) {
              const trailersFiltered = [
                ...this.filterByCondition('Loading', childTrailers),
                ...this.filterByCondition('Closed', childTrailers),
                ...this.filterByCondition('Overhead', childTrailers),
                ...this.filterByCondition('Enroute', childTrailers),
              ];
              childTrailers = trailersFiltered;
            }
            if (!childTrailers.length) {
              const emptyData = this.laneDataTypesService.getNoDataStructure();
              childTrailers.push(emptyData);
            }
            return { ...loadInstr, children: childTrailers };
          });
          laneData.closeToSicCd = closeToSicList.toLocaleString();
          laneData['closeToSicsWithPlannedLoadCount'] = closeToSicsWithPlannedLoadCount;
          return { ...laneData, children: loadsList };
        })
      : [];
    // move 'unknown' lanes to the last
    return apiTransformedData
      .filter((lane) => lane.lane.toUpperCase() !== 'UNKNOWN')
      .concat(apiTransformedData.filter((lane) => lane.lane.toUpperCase() === 'UNKNOWN'));
  }

  private filterByCondition(status: string, childTrailers: LaneDataType[]): LaneDataType[] {
    return childTrailers.filter((trailer) => trailer.statusCd === status);
  }

  private getLaneInstructions(
    sicCd: string,
    plannedDate: Date,
    shift: string,
    modelGroupCd: ModelGroupCd
  ): Observable<ListModelInstructionsResp> {
    const laneRequest = new ListModelInstructionsQuery();
    const sicPath = new GetServiceCenterDetailsBySicPath();
    sicPath.sicCd = sicCd;
    const queryPath = new ListModelInstructionsPath();
    const userRoleCd: UserRoleCd = this.authService.isSuperUser()
      ? UserRoleCd.MANAGER_SUPERVISOR_LINEHAUL
      : this.authService.getUserRoleCd();
    // REFACTOR laneRequest.sector = sector; variable sector was removed because it was always undefined

    queryPath.sicCd = sicCd;
    queryPath.plannedDate = plannedDate;
    queryPath.shiftCd = shift.slice(0, 1);
    queryPath.modelGroupCd = modelGroupCd;
    // TODO remove 'toastOnError after demo
    return this.linehaulService.allActiveSics$.pipe(
      switchMap((activeSics: ActiveSic[]) => {
        this.allActiveSics = activeSics;
        laneRequest.serviceCenterTimezoneOffset = this.getOffset(sicCd);
        if (userRoleCd) {
          laneRequest.userRoleCd = userRoleCd;
        }

        return this.linehaulOpApiService
          .listModelInstructions(queryPath, laneRequest, { toastOnError: false, loadingOverlayEnabled: false })
          .pipe(
            share(),
            tap(() => {
              const userSic = this.authService.getEmployeeSic();

              this.loggingApiService.info(
                this.userRoleService.user.userId +
                  'ListModelInstructions in Load Management: ' +
                  this.userRoleService.build +
                  '- SIC:' +
                  sicCd +
                  '- Date: ' +
                  plannedDate +
                  '- Shift: ' +
                  shift +
                  '- ModelGroup : ' +
                  modelGroupCd,
                userSic,
                'Get info from ListModelInstructions in LoMa',
                'GET',
                'LoMa'
              );
            }),
            catchError((err) => of(undefined))
          );
      })
    );
  }

  private getOffset(requestedSic: string): string {
    this.sic = requestedSic;
    const sicInfo = this.allActiveSics.find((sic) => sic.sicCd === requestedSic);
    return sicInfo.serviceCenterOffset;
  }

  private transformApiData(apiData: MoveLaneInstructionSummary[]): LaneDataType[] {
    return apiData
      .map((data: MoveLaneInstructionSummary) => {
        const laneData = data.instructionSummary; // lane
        const loadData = data.loadLaneInstructionSummary; // loads for the above lane

        delete laneData.closeToSicCd; // remove from lane level and use list of close to
        // transformedData.push(laneData);

        return loadData
          .filter((load: LoadLaneInstructionSummary) => load.trailerLoadInfo)
          .map((load: LoadLaneInstructionSummary) => {
            const loadLaneInstrSumm = load.loadLaneInstructionSummary;
            loadLaneInstrSumm['lane'] = laneData.lane;
            // transformedData.push(loadLaneInstrSumm);
            return load.trailerLoadInfo.map((trailer) => {
              return {
                filterSic: this.sic,
                lane: load.loadLaneInstructionSummary.lane,
                closeToSicCd: load.loadLaneInstructionSummary.closeToSicCd,
                load: `${trailer.trailerLoadHist.equipmentIdPrefix.replace(/^0/, '')}-${
                  trailer.trailerLoadHist.equipmentIdSuffixNbr
                }`,
                equipmentInstId: trailer.trailerLoadHist.equipmentId,
                guaranteedInd: trailer.trailerLoadHist.guaranteedServiceInd,
                hazmatInd: trailer.trailerLoadHist.hazmatInd,
                frzblInd: trailer.trailerLoadHist.frzblInd,
                // update after api's has them
                hssLoadCount: laneData['hssLoadCount'],
                bypassLoadCount: laneData.bypassLoadCount,
                ptlTrlrInd: trailer.ptlTrlrInd,
                bypassInd: trailer.bypassTrlrInd,
                loadedWeight: trailer.trailerLoadHist.loadedWeight,
                loadedCube: trailer.trailerLoadHist.loadedCbePercentage,
                exception: trailer.exceptionInd,
                sourceCd: trailer.source,
                statusCd: trailer.trailerLoadHist.currentStatus,
                headLoadInd: !!trailer.headLoadDetail && !!Object.entries(trailer.headLoadDetail).length,
                headLoadDetail: trailer.headLoadDetail,
                loadedShipment: trailer.loadedShipment,
                misloadInd: trailer.loadedShipment ? !!trailer.loadedShipment.find((shp) => shp.misloadInd) : false,
                eventDoor: trailer.trailerLoadHist.eventDoor,
                currentEventTmst: trailer.trailerLoadHist.currentEventDateTime,
                closeTmst: trailer.trailerLoadHist.closeDateTime,
                originSic: trailer.trailerLoadHist.originSic,
                loadedTimestamp: trailer.trailerLoadHist.loadedDateTime,
              } as LaneDataType;
            });
          })
          .reduce((previous: LaneDataType[], current: LaneDataType[]) => previous.concat(current), []);
      })
      .reduce((previous: LaneDataType[], current: LaneDataType[]) => previous.concat(current), []);
  }

  ngOnDestroy(): void {
    this.interactionSubscription.unsubscribe();
  }
}
