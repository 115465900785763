import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { ComponentsEnum } from '../../enums/components.enum';
import { InteractionServiceResp } from '../../models/interaction-service-response.model';
import { RegionWithSics } from '../../models/region-with-sics.model';
import { SicInfo } from '../../models/sic-info.model';
import { InteractionService } from '../../services/interaction.service';

export const _filter = (opt: SicInfo[], value: string): SicInfo[] => {
  const filterValue = value ? value.toLowerCase() : '';

  return opt.filter(
    ({ sicShowed, sicDescription }) =>
      (sicShowed && sicShowed.toLowerCase().includes(filterValue)) ||
      (sicDescription && sicDescription.toLowerCase().includes(filterValue))
  );
};

@Component({
  selector: 'sic-filter',
  templateUrl: 'sic-filter.component.html',
  styleUrls: ['sic-filter.component.scss'],
})
export class SicFilterComponent implements OnInit, OnDestroy {
  private _options: RegionWithSics[] = [];
  private unsubscriber = new Unsubscriber();

  @ViewChild(MatAutocompleteTrigger, { static: false }) autocomplete: MatAutocompleteTrigger;
  @Output() valueChange = new EventEmitter();
  @Input() isDisabled = null;
  @Input() set regionListWithSics(value: RegionWithSics[]) {
    if (value) {
      value.forEach((region) => {
        if (region.sicList) {
          region.sicList.forEach((sic) => {
            if (!this.allSics.find((sicObj) => sicObj.sicCd === sic.sicCd)) {
              this.allSics.push(sic);
            }
          });
        }
      });

      this.options = [{ region: '', sicList: this.allSics }];
    }
  }

  subscriptionGlobalFilters: Subscription;
  subscriptionSic: Subscription;
  myControl = new FormControl({ value: '', disabled: false });
  filteredOptions: Observable<RegionWithSics[]>;
  sicFound = true;
  otherSic: SicInfo[] = [];
  allSics: SicInfo[] = [];
  selectedRegion: RegionWithSics;
  restSics: RegionWithSics[];
  shiftSelected: boolean = false;
  sicsLoaded = false;
  lastRegion = '';
  firstLoad = true;
  openedByFirstTime: boolean = true;

  constructor(private interactionService: InteractionService) {}

  ngOnInit(): void {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.PERFORMANCE_DASHBOARD)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((resp: InteractionServiceResp) => {
        if (
          resp.data.location &&
          resp.data.location.length === 1 &&
          resp.data.location[0].label &&
          resp.data.location[0].level?.toLowerCase().includes('sic')
        ) {
          this.myControl.setValue(resp.data.location[0].code);
          this.setInputAction();
        }
      });

    this.subscriptionGlobalFilters = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(take(1))
      .subscribe(({ data: { sic } }) => {
        if (sic) {
          this.myControl.setValue(this.allSics.find((sicDesc) => sicDesc.sicCd === sic).sicShowed, {
            emitEvent: false,
          });
        }

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(sic || ''),
          map((value) => {
            const formatedValue = value.toUpperCase();

            if (this.firstLoad) {
              if (sic) {
                this.setInputAction();
              }

              this.firstLoad = false;
            } else if (value === '') {
              this.valueChange.emit('');
            }

            return this._filter(formatedValue);
          })
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  get options(): RegionWithSics[] {
    return this._options;
  }

  set options(options: RegionWithSics[]) {
    this._options = options;
  }

  setInputAction(): void {
    const sicValue = this.myControl.value;

    if (sicValue.length >= 3) {
      this.valueChange.emit(sicValue.substring(0, 3));
    }
  }

  onEnter(): void {
    const sicFilter = this.myControl.value.toUpperCase();

    this.sicFound = this.options
      .map((group) => ({ region: group.region, sicList: _filter(group.sicList, sicFilter) }))
      .some((group) => group.sicList.length > 0);

    if (this.sicFound) {
      const sicToShow = this.allSics.find((sic) => sic.sicCd === sicFilter);
      setTimeout(() => {
        if (sicToShow) {
          this.myControl.setValue(sicToShow.sicShowed, { emitEvent: false });
        }
      }, 0);

      if (this.myControl.value) {
        this.setInputAction();
      }
    }
    if (this.autocomplete) {
      this.autocomplete.closePanel();
    }
  }

  private _filter(value: string): RegionWithSics[] {
    if (value) {
      return this.options
        .map((group) => ({ region: group.region, sicList: _filter(group.sicList, value.toUpperCase()) }))
        .filter((group) => group.sicList.length > 0);
    }

    return this.options;
  }

  closeAutocomplete(event: Event): void {
    if (this.autocomplete.panelOpen === true) {
      event.stopPropagation();
      this.autocomplete.closePanel();
    }
  }
}
