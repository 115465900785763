<app-drawer-wrapper
    *ngIf="!(isSearchingPlanningDrivers | async) && (driverInfo$ | async) as planningDriverSummary; else loader">


  <span appDrawerTitle>
    Details: {{ planningDriverSummary.planningDriver.firstName }} {{ planningDriverSummary.planningDriver.lastName }}
  </span>
  <!-- <p>Arriving at <b>XCM - Columbus Indiana, IN.</b> on <b>TUE, 11/03 at 16:28</b></p> -->

  <mat-tab-group xpoAlternateTabs>
    <mat-tab label="DRIVER INFO">
      <div class="app-Details-driverInfoWrapper">
        <div class="basic_info">
          <div class="driver_info">
            <div class="driver_header">
              <div class="img-container"
                  [ngStyle]="{ 'background-image': 'url(./assets/images/account_circle.png)' }"></div>
              <span
                  class="status {{ planningDriverSummary.planningDriver.statusCd }}">{{ planningDriverSummary.planningDriver.statusCd }}</span>
              <h2>{{ planningDriverSummary.planningDriver.firstName }}
                {{ planningDriverSummary.planningDriver.lastName }}
              </h2>
              <ng-container *ngIf="(domicileSicInfo$ | async) as domicileSicInfo; else loading">
                <!-- It might be a bad data thing, but all drivers should have a domicile sic-->
                <span class="driver-data">Driver, {{ domicileSicInfo?.locationSic[0]?.sicDescription }},
                  {{ domicileSicInfo?.locationSic[0]?.state }}</span>
              </ng-container>
              <div>
                <app-special-service-group [credentials]="credentials"></app-special-service-group>
              </div>
              <ng-template #loading>-</ng-template>
              <ul>
                <li><span class="title">Emp ID</span>{{ empId }}</li>
                <li><span class="title">Contact</span>{{ phoneNumber }}</li>
                <li><span class="title">Overall Rank</span>{{ planningDriverSummary.planningDriver.seniorityRankNbr }}
                </li>
                <li><span class="title">Seniority
                    Date</span>{{ planningDriverSummary.planningDriver.seniorityDate | date: 'M/d/yyyy' }}</li>
                <li><span class="title">Disp. Order</span>{{planningDriverSummary.planningDriver.laneRankNbr}}</li>
                <li *ngIf="planningDriverSummary.planningDriver.assignedLane">
                  <span class="title">Assigned Lane</span>{{ planningDriverSummary.planningDriver.assignedLane }}
                </li>
                <li *ngIf="planningDriverSummary?.planningSchedule?.viaSic">
                  <span class="title">Via</span>{{ planningDriverSummary.planningSchedule.viaSic }}
                </li>
                <!-- <li><span class="title">Rem. Drive Hrs.</span>11H 15M</li> -->
                <mat-divider></mat-divider>
                <!-- <ng-container *ngIf="showInputsTimes(planningDriverSummary.planningDriver.statusCd)">
                  <li>
                    <span class="title">Earliest Start</span>
                    18:00
                  </li>
                  <li class="shift-start">
                    <span class="title">SHIFT START</span>
                    <input />
                  </li>
                  <li class="cut-time">
                    <span class="title">CUT TIME</span>
                    <input />
                  </li>
                </ng-container> -->
                <li *ngIf="isVisibleCityDriverCheckbox">
                  <mat-checkbox [(ngModel)]="isCityDriver"
                      [disabled]="isUnavailable || disableDriverStatusChange">Make this a City Turn Driver
                  </mat-checkbox>
                </li>
                <li class="driver-unavailable"
                    *ngIf="showCheckboxForUnavailable(planningDriverSummary.planningDriver.statusCd)">
                  <mat-checkbox [(ngModel)]="isUnavailable"
                      [disabled]="isCityDriver || disableDriverStatusChange"
                      (change)="onChange($event, planningDriverSummary.planningDriver.employeeId)">Make this Driver
                    Unavailable</mat-checkbox>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="driver_tracking">
          <!-- <div class="button-toggle">
                <mat-button-toggle-group aria-label="Font Style">
                  <mat-button-toggle (change)="onValChange($event.value)" checked value="Timeline">
                    TIMELINE
                  </mat-button-toggle>
                  <mat-button-toggle (change)="onValChange($event.value)" checked value="Bids">
                    BIDS
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
          <ng-container *ngIf="showTimeline; else bids">
            TODO show Timeline
          </ng-container>
          -->
          <ng-container *ngTemplateOutlet="bids"></ng-container>
        </div>
      </div>

    </mat-tab>
    <!-- <mat-tab label="TRAILERS">
        <div class="basic_info"></div>
      </mat-tab> -->
  </mat-tab-group>

  <ng-template #bids>
    <xpo-board [boardOptions]="boardOptions"
        [dataSource]="dataSource"
        [viewTemplates]="viewTemplates"
        [views]="views">
      <xpo-ag-grid-board (gridReady)="onGridReady($event)"
          [customGridOptions]="gridOptions"
          rowModelType="client-side">
      </xpo-ag-grid-board>
    </xpo-board>
  </ng-template>
</app-drawer-wrapper>
<ng-template #loader>
  <xpo-responsive-loading-spinner></xpo-responsive-loading-spinner>
</ng-template>
