import { Injectable } from '@angular/core';
import {
  Exception,
  ExceptionManagementApiService,
  ExceptionSummary,
  ListLinehaulExceptionsQuery,
  ListLinehaulExceptionsResp,
  ListSummarizedExceptionsByStatusQuery,
  ListSummarizedExceptionsByTypeQuery,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExceptionAndAlertService {
  constructor(private exceptionManagementApiService: ExceptionManagementApiService) {}

  getAlertHeaderCounts(
    params: ListSummarizedExceptionsByStatusQuery,
    overlay: boolean
  ): Observable<any[] | ExceptionSummary[]> {
    return this.exceptionManagementApiService
      .listSummarizedExceptionsByStatus(params, { loadingOverlayEnabled: overlay })
      .pipe(
        map((data) => {
          return data.exceptionSummaries;
        }),
        catchError((e) => {
          console.error('Exception and Alert Service - getAlertHeaderCounts: ', e);
          return of([]);
        })
      );
  }

  getAlertCountsByType(params: ListSummarizedExceptionsByTypeQuery, overlay: boolean): Observable<any> {
    return this.exceptionManagementApiService
      .listSummarizedExceptionsByType(params, { loadingOverlayEnabled: overlay })
      .pipe(
        map((data) => {
          return data.exceptionAlertCountsByStatus;
        }),
        catchError((e) => {
          console.error('Exception and Alert Service - getAlertCountsByType', e);
          return of([]);
        })
      );
  }

  getItem(id: XrtChangedDocuments): Observable<Exception> {
    const listExceptionsQuery = new ListLinehaulExceptionsQuery();
    // Object is weird
    listExceptionsQuery.exceptionInstId = JSON.parse(id.documents.toString())[0].DocumentKey;
    return this.exceptionManagementApiService
      .listLinehaulExceptions(listExceptionsQuery, { loadingOverlayEnabled: false })
      .pipe(
        map((data: ListLinehaulExceptionsResp) => {
          // TODO: look into why its sending back 2 exceptions;
          return data.exceptions[0];
        })
      );
  }
}
