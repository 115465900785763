import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule, XpoCardModule, XpoPopoverModule, XpoTabsModule } from '@xpo-ltl/ngx-ltl-core';
import { AgGridModule } from 'ag-grid-angular';
import { AppRoutingModule } from '../app-routing.module';
// import { PerformanceDashboardModule } from '../performance-dashboard/performance-dashboard.module';
import { linehaulFrameworkComponentsDeclarations } from '../shared/components/frameworkComponents';
import { SidePanelContainerModule } from '../shared/components/side-panel-container/side-panel-container.module';
import { SharedModule } from '../shared/shared.module';
import { DolliesComponent } from './dollies/dollies.component';
import { EquipmentComponent } from './equipment.component';
import { EquipmentRoutingModule } from './equipment.routing.module';
import { SicFilterComponent } from './filters/sic-filter/sic-filter.component';
import { EquipmentService } from './services/equipment.service';
import { TractorsComponent } from './tractors/tractors.component';
import { TrailersComponent } from './trailers/trailers.component';

@NgModule({
  declarations: [EquipmentComponent, TrailersComponent, SicFilterComponent, DolliesComponent, TractorsComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    XpoPopoverModule,
    MatIconModule,
    MatChipsModule,
    XpoTabsModule,
    MatTabsModule,
    XpoCardModule,
    SharedModule,
    SidePanelContainerModule,
    // PerformanceDashboardModule,
    MatDatepickerModule,
    FormsModule,
    MatTreeModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    XpoAgGridBoardModule,
    AgGridModule.withComponents(linehaulFrameworkComponentsDeclarations),
    XpoButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    DragDropModule,
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatDividerModule,
    EquipmentRoutingModule,
  ],
  exports: [EquipmentComponent],
  providers: [EquipmentService, DatePipe],
  entryComponents: [],
})
export class EquipmentModule {}
