<div *ngIf="isSearchBarVisible"
    class="xpo-AdvancedSelectPanel-searchContainer">
  <mat-form-field class="xpo-AdvancedSelectPanel-search ngx-FormField--inline"
      floatLabel="never">
    <input autocomplete="off"
        #searchBox
        matInput
        type="text"
        placeholder="Search"
        [(ngModel)]="searchInputValue"
        (keyup)="filterOptions($event.target.value)">
    <xpo-icon matSuffix
        class="xpo-AdvancedSelectPanel-search-icon"
        iconName="search"></xpo-icon>
  </mat-form-field>
</div>

<xpo-responsive-loading-spinner *ngIf="!options"></xpo-responsive-loading-spinner>

<div class="xpo-AdvancedSelectPanel-optionsContainer">
  <xpo-advanced-select-tree xpoLabelWrap
      [suppressIncludeParentCode]="suppressIncludeParentCode"
      [options]="options"
      [searchFilter]="searchInputValue"
      [isSingleSelect]="isSingleSelect"
      [selection]="selection"
      [showSelectAll]="showSelectAll"
      (selectionChange)="handleSelectionChange($event)"
      (optionsSelected)="handleOptionsSelected($event)">
  </xpo-advanced-select-tree>
</div>
