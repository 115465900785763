import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnalyticsApiService } from '@xpo-ltl-2.0/sdk-analytics';
import { SharedModule } from '../shared/shared.module';
import { UserMetricsComponent } from './user-metrics.component';
@NgModule({
  declarations: [UserMetricsComponent],
  imports: [CommonModule, SharedModule],
  providers: [AnalyticsApiService],
  exports: [UserMetricsComponent],
})
export class UserMetricsModule {}
