// Each IconKey in this enum will be searched as svg in /assets/images to be loaded in iconRegistry
export enum IconsKeys {
  empty = 'empty',
  hazmat = 'SS_Hazmat',
  guaranteed = 'SS_G',
  G = 'SS_G',
  guaranteed2 = 'SS_G_2', // without the box
  a = 'SS_A',
  driverCollect = 'SS_DriverCollect',
  liftgate = 'SS_Liftgate',
  NOA = 'SS_NOA',
  TDC = 'SS_TDC',
  freezable = 'SS_Freezable',
  headLoad = 'SS_Headload',
  HSSWeight = 'HSS_Weight_icon',
  addLoad = 'AddLoad_icon',
  photoCamera = 'photo-camera',
  description = 'description',
  circleFilled = 'circle-filled',
  error = 'error',
  arrowUp = 'icon_arrowUp',
  bell = 'icon_bell',
  onTimeDepart = 'icon_OnTimeDepart',
  progLoad = 'icon_ProgLoad',
  misload = 'SS_Misload',
  bypass = 'SS_Bypass',
  addCircle = 'add-circle',
  dots = 'dots',
  visibility = 'visibility',
  picture = 'picture',
  iconPlus = 'icon_plus',
  iconMinus = 'icon_minus',
  refresh = 'replay',
  ptl = 'SS_Ptl',
  redFlag = 'red-flag',
  arrowBack = 'arrow-back',
  asc = 'asc',
  desc = 'desc',
  sort = 'sort',
  person = 'person',
}
