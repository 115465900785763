import { ExceptionStatusCd, ExceptionSummaryCd, ExceptionSummaryTypeCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';

export class PDState {
  planDate: string;
  alertStatus: ExceptionStatusCd;
  summaryType: ExceptionSummaryTypeCd;
  summary: ExceptionSummaryCd;
  alertType: ExceptionTypeCd;
  operationalRegionCd: string;
  linehaulRegionCd: String;
  districtCd: string;
  sicCd: string;
}
