<!-- <div class="content">{{ data | json }}</div> -->
<div class="content">
  <label class="header">Load Request</label>

  <div class="container-column">
    <div class="container-row">
      <div class="item">
        <label class="title">Requesting</label>
        <label class="sic">{{ info.requesting }}</label>
      </div>
      <div class="item">
        <label class="title">Requested</label>
        <label class="info">{{ getFormattedDate(info.requested) }}</label>
      </div>
      <div class="item">
        <label class="title">Lane</label>
        <label class="info">{{ info.lane }}</label>
      </div>
      <div class="item">
        <label class="title">Loads Off Dock</label>
        <label class="info">{{ info.closeTo }}</label>
      </div>
      <div class="item">
        <label class="title"># of Loads</label>
        <label class="info">{{ info.nOfLoads }}</label>
      </div>
      <div class="item requested">
        <label class="title">Requested by</label>
        <label class="info">{{ info.requestedBy }}</label>
      </div>
      <div class="item reason">
        <label class="title">Reason</label>
        <label class="info">{{ info.reason }}</label>
      </div>
      <div class="item">
        <label class="title">Comments</label>
        <label class="info">{{ info.comments }}</label>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="action">
      <mat-form-field class="long">
        <mat-select
            [disabled]="readOnlyRoles || readOnlyRange"
            placeholder="Load Quantity"
            formControlName="loadQuantity"
            disableOptionCentering>
          <mat-option *ngFor="let lq of loadQuantityArray" [value]="lq"> {{ lq }} </mat-option>
        </mat-select>
        <mat-error *ngIf="hasError('required', form, formNames.LoadQty)"> Number of Loads is required. </mat-error>
      </mat-form-field>

      <div class="reason-row">
        <mat-form-field class="long">
          <mat-select
              [disabled]="readOnlyRoles || readOnlyRange"
              placeholder="Approval Compliance Type"
              formControlName="approvalType"
              disableOptionCentering>
            <mat-option *ngFor="let at of approvalType" [value]="at"> {{ at }} </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('required', form, formNames.ApprovalType)">
            Approval Compliance field is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field class="long">
          <textarea
              [rows]="3"
              type="text"
              [readonly]="readOnlyRoles || readOnlyRange"
              [disabled]="readOnlyRoles || readOnlyRange"
              matInput
              placeholder="Comment *"
              maxlength="100"
              formControlName="declineReason"
              value="{{ info.decisionComments }}"></textarea>
          <mat-hint class="hint"> {{ declineReasonFormControl?.value?.length }} / 100 Chars</mat-hint>
          <mat-error *ngIf="hasError('required', form, 'declineReason')"> Comment field is required. </mat-error>
        </mat-form-field>
      </div>

      <div class="button-action-row-1" *ngIf="!readOnlyRange">
        <button mat-flat-button xpoSmallButton *ngIf="!readOnlyRoles" (click)="approve()">
          APPROVE {{ loadQuantityFormControl.value }} LOADS
        </button>
        <button mat-flat-button xpoSmallButton *ngIf="!readOnlyRoles" (click)="decline()">
          DECLINE ALL
        </button>
        <button mat-stroked-button xpoSmallButton *ngIf="!readOnlyRoles" (click)="onCancel()">CANCEL</button>
      </div>
    </div>
  </form>
</div>
