import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoutes } from '../../enums/app-routes.enum';

@Injectable({ providedIn: 'root' })
export class DevEnvGuard implements CanActivate {
  isAllowedToShowLinks: boolean;

  private allowedRoutes = [
    'localhost', // local env
    'lnh-ops-i.dev-ltl-xpo.com', // dev env
    'lnh-ops-5.dev-ltl-xpo.com', // qa env
  ];
  constructor(private router: Router) {}

  canShowLinks(): boolean {
    return (this.isAllowedToShowLinks = this.allowedRoutes.some((route) => location.hostname.includes(route)));
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.isAllowedToShowLinks = this.allowedRoutes.some((route) => location.hostname.includes(route));
    if (!this.isAllowedToShowLinks) {
      this.router.navigateByUrl(`/${AppRoutes.PERFORMANCE_DASHBOARD}`);
    }
    return this.isAllowedToShowLinks;
  }
}
