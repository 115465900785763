import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LinehaulFormatters } from '../../../shared/formatters/formatters';

@Component({
  selector: 'app-tractor-cell-renderer',
  templateUrl: './tractor-cell-renderer.component.html',
  styleUrls: ['./tractor-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TractorCellRendererComponent implements ICellRendererAngularComp {
  tractor: string = '';

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.tractor = LinehaulFormatters.EquipmentName(params.value?.name);
  }
}
