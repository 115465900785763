import { Injectable } from '@angular/core';
import {
  FeatureSetting,
  ListLocationFeaturesQuery,
  ListLocationGroupCodesQuery,
  ListLocationGroupCodesResp,
  ListOperationalRegionsQuery,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { ExceptionLocationTypeCd, HssRegionCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, delay, map, retryWhen, share, shareReplay, take } from 'rxjs/operators';
import { LinehaulService } from './linehaul/linehaul.service';

export interface LocationData {
  [ExceptionLocationTypeCd.HSS_REGION]: any[];
  [ExceptionLocationTypeCd.LINEHAUL_REGION]: any[];
  [ExceptionLocationTypeCd.OPERATIONAL_REGION]: any[];
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  filterLocationData = new BehaviorSubject<LocationData>(null);
  lomaRegions = new BehaviorSubject<any>('');
  domSic: String;

  private readonly pilotSics$: Observable<string[] | null>;

  constructor(private linehaulService: LinehaulService, private locationApiService: LocationApiService) {
    this.setFilterLocationData().subscribe((data) => {
      const lnhAndOptRegions: LocationData = {
        HSSRegion: [
          { level: ExceptionLocationTypeCd.HSS_REGION, code: HssRegionCd.HSSU, label: 'HSSU Region' },
          { level: ExceptionLocationTypeCd.HSS_REGION, code: HssRegionCd.HSSX, label: 'HSSX Region' },
          { level: ExceptionLocationTypeCd.HSS_REGION, code: HssRegionCd.HSSLN, label: 'HSSLN Region' },
        ],
        LinehaulRegion: data[0],
        OperationalRegion: data[1].operationalRegions,
      };
      this.filterLocationData.next(lnhAndOptRegions);
    });
    const query: ListLocationFeaturesQuery = new ListLocationFeaturesQuery();
    query.featureCd = 'POMA';
    query.settingValue = 'Y';

    this.pilotSics$ = this.locationApiService.listLocationFeatures(query).pipe(
      catchError(() => {
        // If api fails, lets catch and just give them the feature.
        return of(null);
      }),
      map((resp: any) => {
        return resp?.locationFeatures?.map((locationFeatures: FeatureSetting) => locationFeatures.sicCd);
      }),
      shareReplay()
    );
  }

  private setFilterLocationData(): Observable<any[]> {
    return forkJoin([
      this.getAllLinehaulRegionsWithSics().pipe((regionWithSics) => regionWithSics),
      this.getAllOperationalRegions().pipe((operationalRegions) => operationalRegions),
    ]);
  }

  private getAllOperationalRegions(): Observable<any> {
    const listOperationalRegionsQuery = new ListOperationalRegionsQuery();

    return this.locationApiService.listOperationalRegions(listOperationalRegionsQuery).pipe(
      map((listOperationalRegions: any) => {
        listOperationalRegions.operationalRegions.map((op) => {
          const regionNameWithoutRegion = op.regionName.split('REGION')[0].trim();
          op.regionName = regionNameWithoutRegion;
        });
        return listOperationalRegions;
      })
    );
  }

  private getAllLinehaulRegionsWithSics(): Observable<any> {
    return this.linehaulService.getRegionsWithSicsList().pipe(
      retryWhen((errors) => errors.pipe(delay(1000), take(5))),
      map((resp) => {
        this.lomaRegions.next(resp);
        return resp;
      })
    );
  }

  getLomaRegions(): Observable<any> {
    return this.lomaRegions;
  }

  getModelGroupCode(sic: string): Observable<ListLocationGroupCodesResp> {
    const getModelGroupCodePath = new ListLocationGroupCodesQuery();
    getModelGroupCodePath.sicCd = sic;
    getModelGroupCodePath.groupCategoryCd = 'LHO_MODEL_GRP';

    // TODO: need to fix the BE model to not be an envelope
    return <Observable<ListLocationGroupCodesResp>>(
      this.locationApiService.listLocationGroupCodes(getModelGroupCodePath, { toastOnError: false }).pipe(share())
    );
  }

  isPilotSic(sic: string): Observable<boolean> {
    if (!sic) {
      return of(false);
    }

    return this.pilotSics$.pipe(
      map((sics) => {
        return sics && sics.length
          ? sics.some(
              (locationSic) => locationSic?.toUpperCase() === 'ALL' || locationSic?.toUpperCase() === sic.toUpperCase()
            )
          : true;
      })
    );
  }
}
