<xpo-board [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [views]="views"
    [boardOptions]="boardOptions">

  <xpo-board-grid-toolbar>
    <xpo-board-toolbar-toggle-buttons-group
        *ngIf="(dataSource.isPilotSic$ | async) && schedulesService.schedulesMetrics$ | async as schedulesMetrics">
      <div class="app-Schedules-schedulesMetrics">
        <span>Drivers <strong>{{ schedulesMetrics.dsrsAvailable || 0 }}</strong></span>
        <span>Planned <strong>{{ schedulesMetrics?.dsrsRequired || 0 }}</strong></span>
        <span>Dispatched <strong>{{ schedulesMetrics?.dsrsDispatched || 0 }}</strong></span>
      </div>
      <button *ngIf="schedulesService.isToday()"
          mat-button
          xpoSmallButton
          [disabled]="reassignLanesDisabled$ | async"
          (click)="handleReassignLanes()">
        <xpo-icon iconName="loop"></xpo-icon>
        Reassign Lanes
        <mat-progress-spinner *ngIf="reassignLanesLoading$ | async"
            class="app-Schedules-schedulesMetrics-reassignLaneSpinner"
            mode="indeterminate"
            [diameter]="16">
        </mat-progress-spinner>
      </button>
    </xpo-board-toolbar-toggle-buttons-group>
  </xpo-board-grid-toolbar>
  <xpo-ag-grid-board class="app-Schedules-agGridBoard"
      rowModelType="client-side"
      selectionMode="cell"
      [customGridOptions]="customGridOptions"
      (gridBoardReady)="handleGridBoardReady($event)">
  </xpo-ag-grid-board>
  <xpo-empty-state>
    <xpo-empty-state-header>
      <h3>
        {{noResultMessage$ | async}}
      </h3>
    </xpo-empty-state-header>
  </xpo-empty-state>
</xpo-board>
