import { Injectable } from '@angular/core';
import {
  Exception,
  ExceptionManagementApiService,
  ListLinehaulExceptionsQuery,
  ListLinehaulExceptionsResp,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { NEVER, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AlertsMessagingService } from './alerts-messaging.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeFeedService {
  constructor(
    private alertsMessagingService: AlertsMessagingService,
    private exceptionService: ExceptionManagementApiService
  ) {}

  subscribeToChangeFeed(): Observable<Exception> {
    const listExceptionsQuery = new ListLinehaulExceptionsQuery();

    return this.alertsMessagingService.elementsUpdated$.pipe(
      switchMap((elementUpdated) => {
        if (elementUpdated) {
          listExceptionsQuery.exceptionInstId = elementUpdated[0].DocumentKey;
          return this.exceptionService
            .listLinehaulExceptions(listExceptionsQuery, { loadingOverlayEnabled: false })
            .pipe(
              map((data: ListLinehaulExceptionsResp) => {
                return data.exceptions[0];
              })
            );
        } else {
          return NEVER;
        }
      })
    );
  }
}
