import { Component } from '@angular/core';
import { PlanningDriver } from '@xpo-ltl/sdk-linehauloperations';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-driver-renderer',
  templateUrl: './add-driver-renderer.component.html',
  styleUrls: ['./add-driver-renderer.component.scss'],
})
export class AddDriverRendererComponent implements ICellRendererAngularComp {
  private params: any;
  canAdd: boolean;

  constructor() {}

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    if (!params.data.drivers && !params.data.destinationSicCode) {
      this.canAdd = true;
    } else {
      this.canAdd = false;
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick($event: MouseEvent) {
    console.log($event);
    const tempParams = _.clone(this.params);
    tempParams.data['drivers'] = [{ firstName: 'bob' }, { firstName: 'tom' }];
    this.params.node.setDataValue('driver', tempParams);
    /*this.params.api.refreshClientSideRowModel('group');
    this.params.api.refreshCells();*/
    console.log(this.params.rowNode);
  }

  onClickkkk($event) {
    const oldData = this.params.node.data;
    const oldDriver = ({
      firstName: oldData.firstName,
      middleName: oldData.middleName,
      lastName: oldData.lastName,
      dsrAvailableInd: oldData.dsrAvailableInd,
      dsrAssignedInd: oldData.dsrAssignedInd,
      employeeId: oldData.employeeId,
      equipmentId: oldData.equipmentId,
      equipmentPrefix: oldData.equipmentPrefix,
      equipmentSuffixNbr: oldData.equipmentSuffixNbr,
      assignmentSicCd: oldData.assignmentSicCd,
      dsrCellPhone: oldData.dsrCellPhone,
      seniorityDate: oldData.seniorityDate,
      assignedLane: oldData.assignedLane,
      laneRankNbr: oldData.laneRankNbr,
      // startTmst: oldData.startTmst,
      dsrEarliestStartDateTime: oldData.dsrEarliestStartDateTime,
      dsrRemainingHrs: oldData.dsrRemainingHrs,
      dsrOnDutyRemainingHrs: oldData.dsrOnDutyRemainingHrs,
      originDispatchDateTime: oldData.originDispatchDateTime,
      etaDateTime: oldData.etaDateTime,
      assignmentCd: oldData.assignmentCd,
      reasonCd: oldData.reasonCd,
    } as unknown) as PlanningDriver;
    const oldDrivers = [oldDriver];
    const newDrivers = oldDrivers.slice(0);
    const driverObj = new PlanningDriver();
    driverObj['manuallyAdded'] = true;
    newDrivers.push(driverObj);

    oldData['drivers'] = newDrivers;
    const newData = _.clone(oldData);
    this.params.api.applyTransaction({ update: [newData] });
    this.params.node.setExpanded(true);
  }
}
