<div *ngIf="showSidePanel"
    class="side-panel-container"
    [ngClass]="changeWidth ? 'expanded-width' : ''">
  <mat-chip *ngIf="type === ExceptionTypeCd.HSS && status === ExceptionStatusCd.APPROVED"
      xpoChipSmall>CONTROL NUMBER
    {{ controlNumber }}
  </mat-chip>
  <div class="side-panel-header"
      [ngClass]="{ moreheight: subTitle }">
    <label class="side-panel-label"
        data-test="sidePanelLabel"> {{ title }}</label>
    <mat-icon class="close-button-panel"
        (click)="closePanel()"
        data-test="closePanelButton">clear</mat-icon>
  </div>
  <div class="side-panel-actions">
    <mat-tab-group mat-align-tabs="end"
        (selectedTabChange)="changeSidePanelWidth()"
        *ngIf="type === ExceptionTypeCd.LOAD_REQUEST; else sidePanelContent">
      <mat-tab label="Lane Info">
        <div class="side-panel-body">
          <side-panel-content [type]="type"
              [status]="status"
              [alert]="sidePanelData"
              [modelData]="modelData"
              *ngIf="!reset">
          </side-panel-content>
        </div>
      </mat-tab>
      <!--
    <mat-tab label="All lanes">
      <h1>Move Lane Details</h1>
      <app-allowable-loads [readOnlyMode]="true"></app-allowable-loads>
    </mat-tab>
    -->
    </mat-tab-group>

    <ng-template #sidePanelContent>
      <side-panel-content [type]="type"
          [status]="status"
          [alert]="sidePanelData"
          [modelData]="modelData"
          *ngIf="!reset">
      </side-panel-content>
    </ng-template>
  </div>
</div>
