export class EquipmentFilters {
  period: any = '';
  view: string = '';
  shift: string = '';
  sic: string = '';

  constructor(plannedDate?: string, view?: string, shift?: string, sic?: string) {
    this.period = plannedDate;
    this.shift = shift;
    this.view = view;
    this.sic = sic;
  }
}
