<h1 mat-dialog-title>
  Load Request at {{ globalSic }} in {{ sic }} lane
  <xpo-dialog-close-button></xpo-dialog-close-button>
</h1>
<div mat-dialog-content class="dialog-content">
  <form [formGroup]="loadRequestFormGroup">
    <div class="load-closeTo-container">
      <mat-form-field floatLabel="always" class="closeTo">
        <mat-select placeholder="Request Type" formControlName="requestType" disableOptionCentering>
          <mat-option value="">- Select -</mat-option>
          <mat-option *ngFor="let type of requestTypes" [value]="type">{{ type }}</mat-option>
        </mat-select>
        <mat-error *ngIf="requestTypeFormControl.invalid">Required</mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always" class="loads">
        <mat-label>Number of Loads</mat-label>
        <input matInput formControlName="numberOfLoads" type="number" min="1" />
        <mat-error *ngIf="numberOfLoadsFormControl.hasError('required')">Required</mat-error>
        <mat-error *ngIf="numberOfLoadsFormControl.hasError('min')">Must atleast 1</mat-error>
        <mat-error *ngIf="numberOfLoadsFormControl.hasError('max')"
          >Max for 'Add' is 99 and Max for 'Cut' is Planned Load Count: {{ plannedLoads }}</mat-error
        >
      </mat-form-field>

      <mat-form-field floatLabel="always" class="closeTo">
        <mat-select placeholder="Loads Off Dock" formControlName="closeTo" disableOptionCentering>
          <mat-option *ngFor="let closeToSic of closeToSicCodes" [value]="closeToSic">{{ closeToSic }}</mat-option>
        </mat-select>
        <mat-error *ngIf="closeToFormControl.invalid">Required</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="reason" floatLabel="always">
        <mat-select placeholder="Reason" formControlName="reason" disableOptionCentering>
          <mat-option value="">- Select -</mat-option>
          <mat-option *ngFor="let reasonCode of reasonCodes" [value]="reasonCode.reasonCd">
            {{ reasonCode?.reasonDescription }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="reasonFormControl.invalid">Required</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field floatLabel="always" class="comment">
        <mat-label>Comment</mat-label>
        <textarea class="comment-text-box" matInput formControlName="comment" maxlength="100"></textarea>
        <mat-hint> {{ 100 - getCommentLength() }} Characters</mat-hint>
        <mat-error *ngIf="commentFormControl.invalid">Must be 100 characters or less!</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="actions-container">
  <xpo-button-group>
    <button
      mat-flat-button
      [disabled]="loadRequestFormGroup.invalid || hasDisabledSubmit"
      (click)="submitRequestLoad(loadRequestFormGroup)"
    >
      Submit
    </button>
    <button mat-stroked-button (click)="handleCancel()">Cancel</button>
  </xpo-button-group>
</div>
