import { Component, Input, OnInit } from '@angular/core';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';

import { PerformanceService } from '../../../../../performance-dashboard/services/performance.service';
import { AuthService } from '../../../../services/auth.service';
import { SidePanelModelData } from '../../../side-panel-model-data';

// TODO: Commented out items are for the V2 iteration of prog loads
@Component({
  selector: 'prog-load-panel',
  templateUrl: './prog-load-panel.component.html',
  styleUrls: ['./prog-load-panel.component.scss'],
})
export class ProgLoadPanelComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() modelData: SidePanelModelData;
  @Input() sidePanelData: any;
  showAck: boolean = false;
  isExternalUser: boolean;
  // private fullAmount: number[];
  // private availableAmount: number[];
  // private _headerCount = null;
  private searching: boolean = true;
  private satellite: boolean = false;
  // private scheduleDetails: ListAllowableSchedulesResp;
  // private via: any;
  // private viaHelp: any;
  // displayedColumns: string[] = ['SIC', 'VIA TYPE', 'DROPS', 'PICKS'];
  // private sic: string;

  constructor(
    private authService: AuthService,
    private performanceService: PerformanceService // private scheduleService: SchedulesService
  ) {}

  ngOnInit(): void {
    // const sourceId = this.alert.alert.exceptionInstId;
    this.isExternalUser = !this.authService.isAuthorizedUser();
    this.showAck = this.checkStatus() && this.authService.isAuthorizedUser();
    // this.getCostOpportunities(sourceId);
    if (!this.modelData) {
      this.searching = false;
      this.satellite = true;
    }
    // this.getSchedules();
  }

  private checkStatus(): boolean {
    return (
      this.status !== ExceptionStatusCd.CLOSED &&
      this.status !== ExceptionStatusCd.RESOLVED &&
      this.status !== ExceptionStatusCd.EXPIRED
    );
  }

  // get headerCount() {
  //   return this._headerCount;
  // }

  // set headerCount(val) {
  //   this._headerCount =
  //     this.modelData.topTable.planned && this.modelData.topTable.planned.empties
  //       ? this.modelData.topTable.planned.empties
  //       : 'NONE';
  // }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.modelData) {
  //     this.setHeaderCount();
  //     this.searching = this.modelData.searching;
  //   }
  // }
  // private setHeaderCount() {
  //   if (!this.modelData.topTable) {
  //     return;
  //   }
  // }

  // getCostOpportunities(exceptionInstId: number) {
  //   this.performanceService.getCostOpportunities(exceptionInstId).subscribe(
  //     (resp: ListTrailerCostOpportunitiesResp) => {

  //       this.availableAmount = resp.costOpportunities?.map(
  //         (resp) => resp.availableOpportunityAmount
  //       );
  //       //TODO This variable will be used as part of V2 Change(when adding cost opportunity to the template)
  //       this.fullAmount = resp.costOpportunities?.map((resp) => resp.fullOpportunityAmount);
  //     },
  //     (error) => {
  //       // As requested in ticket DLC-5003 should not show up this toast message for the user.
  //       // this.xpoSnackBar.error('ERROR: Cannot get cost opportunity details!');
  //     }
  //   );
  // }

  // getSchedules() {
  //   const val = this.scheduleService.getModelGroupCode(this.alert.sideData.filterSic).subscribe((resp: any) => {
  //     const tempModel = this.scheduleService.getGroupCodeFromTimezone(resp.locationGroupCodes[0]?.regionCd);
  //     const payload = {
  //       sicCd: this.alert.sideData.filterSic,
  //       shiftCd: this.alert.alert.operationException.shiftCd,
  //       plannedDate: this.alert.alert.operationException.planDate,
  //       modelGroupCd: tempModel,
  //     };
  //     this.scheduleService.updateSchedules(payload);
  //   });

  //   this.scheduleService.listAllowableSchedulesResp$.subscribe((resp) => {
  //     console.log(resp);
  //     this.scheduleDetails = resp;
  //     this.sic = this.scheduleDetails?.allowableSchedulesSummary
  //       ?.map((summary) => summary.laneSchedulesSummary)
  //       .find((lane) => {
  //         return lane.laneSic === this.alert.alert.operationException.destinationSicCode;
  //       }).laneSic;

  //     this.viaHelp = this.scheduleDetails?.allowableSchedulesSummary
  //       ?.map((summary) => summary.laneSchedulesSummary)
  //       .find((lane) => {
  //         return lane.laneSic === this.alert.alert.operationException.destinationSicCode;
  //       }).viaHelp;
  //     this.via = this.scheduleDetails?.allowableSchedulesSummary
  //       ?.map((summary) => summary.laneSchedulesSummary)
  //       .find((lane) => {
  //         return lane.laneSic === this.alert.alert.operationException.destinationSicCode;
  //       }).via;
  //   });
  // }
}
