import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { countBy as _countBy, forEach as _forEach, uniq as _uniq } from 'lodash';
import { timestamp } from 'rxjs/operators';
export class ChangesViewBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'ChangesView';

  constructor(private timeService: XpoLtlTimeService, private loadSic: string) {
    super({
      id: ChangesViewBoardTemplate.templateId,
      name: 'Changes',
      keyField: 'id',
      allowAdditional: false,
      availableColumns: [
        {
          maxWidth: 50,
          headerName: '',
          showRowGroup: 'lane',
          cellRenderer: 'agGroupCellRenderer',
          cellClass: ['arrowColumn', 'chevronMargin'],
          sortable: false,
          cellRendererParams: {
            suppressCount: true,
          },
        },
        {
          maxWidth: 35,
          headerName: '',
          showRowGroup: 'closeTo',
          cellRenderer: 'agGroupCellRenderer',
          cellClass: ['arrowColumn'],
          sortable: false,
          cellRendererParams: {
            suppressCount: true,
          },
        },

        {
          maxWidth: 150,
          headerName: 'Move To',
          showRowGroup: 'lane',
          cellRenderer: 'agGroupCellRenderer',
          cellClass: ['laneColumn'],
          cellRendererParams: {
            suppressCount: true,
          },
        },

        {
          maxWidth: 150,
          headerName: 'Close To',
          showRowGroup: 'closeTo',
          cellRenderer: 'agGroupCellRenderer',
          cellClass: ['closeToColumnChanges'],
          cellRendererParams: {
            suppressCount: true,
          },
          valueGetter: (params) => {
            const {
              node: { allLeafChildren = [] },
            } = params;
            if (allLeafChildren.length) {
              const closeToArr: string[] = _uniq(allLeafChildren.map(({ data: { closeTo } }) => closeTo));
              return closeToArr.length > 1 ? closeToArr.length : closeToArr;
            } else {
              return params.data.closeTo;
            }
          },
        },
        {
          field: 'lane',
          rowGroup: true,
          hide: true,
        },
        {
          field: 'closeTo',
          rowGroup: false,
          hide: true,
        },
        {
          headerName: 'Changes Description',
          cellRenderer: 'StatusChangeFormatterComponent',
          minWidth: 450,
          cellClass: 'statusColumn',
          field: 'changeDescription',
          valueGetter: (params) => {
            const {
              node: { allLeafChildren = [] },
            } = params;
            if (allLeafChildren.length) {
              const { Added, Changed, Deleted } = _countBy(
                allLeafChildren.map(({ data: { changeType } }) => {
                  return { changeType };
                }),
                'changeType'
              );
              let phrase = [];
              if (Added) {
                phrase = [...phrase, `${Added} Added`];
              }
              if (Changed) {
                phrase = [...phrase, `${Changed} Changed`];
              }
              if (Deleted) {
                phrase = [...phrase, `${Deleted} Deleted`];
              }
              return { param: phrase.join(', '), hasIcon: false };
            } else {
              return { param: params.data.changeType, hasIcon: true, changeDescription: params.data.changeDescription };
            }
          },
        },
        {
          headerName: 'Modified By',
          field: 'modifiedBy',
          cellRenderer: 'ModifiedByFormatterComponent',
          valueGetter: (params) => {
            const {
              node: { allLeafChildren = [] },
            } = params;
            if (allLeafChildren.length) {
              const modifiedByArr: string[] = _uniq(allLeafChildren.map(({ data: { modifiedBy } }) => modifiedBy));
              const modifiedByNameArr: string[] = _uniq(
                allLeafChildren.map(({ data: { modifiedByName } }) => modifiedByName)
              );
              const modifiedByFull = `${modifiedByArr} ${modifiedByNameArr}`;
              return modifiedByArr.length > 1 ? 'Multiple' : modifiedByFull;
            } else {
              const modifiedByFull = `${params.data.modifiedBy} ${params.data.modifiedByName}`;
              return modifiedByFull;
            }
          },
        },
        {
          headerName: 'Modified',
          field: 'modifiedTmst',
          valueGetter: (params) => {
            const {
              node: { allLeafChildren = [] },
            } = params;
            if (allLeafChildren.length) {
              return allLeafChildren.length
                ? this.timeToSic(allLeafChildren[allLeafChildren.length - 1].data.modifiedTmst)
                : '';
            } else {
              return this.timeToSic(params.data.modifiedTmst);
            }
          },
        },
      ],
    });
  }

  timeToSic(date) {
    const timeToSic = this.timeService.formatDate(date, 'MM/DD/YYYY HH:mm', this.loadSic);
    return timeToSic;
  }

  createChangesView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'ChangesView',
      name: 'Changes View',
    });
  }
}
