import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EquipmentFilters } from './models/equipment-filters.model';
import { EquipmentService } from './services/equipment.service';
@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
})
export class EquipmentComponent implements OnInit {
  equipmentFilters: EquipmentFilters;
  title: Observable<string>;

  constructor(private dateTransform: DatePipe, private equipmentService: EquipmentService) {}

  ngOnInit() {
    this.title = this.equipmentService.getTitle();
  }

  handlePeriodChange($event) {
    this.equipmentService.period = `${this.dateTransform.transform($event, 'y-MM-dd')}`;
  }

  handleViewChange($event) {
    this.equipmentService.view = $event;
  }

  handleShiftChange($event) {
    this.equipmentService.shift = $event;
  }

  handleSicSelected($event) {
    this.equipmentService.sic = $event;
  }
}
