import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ComponentsEnum } from '../../enums/components.enum';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { Regions } from '../../enums/regions.enum';
import { InteractionService } from '../../services/interaction.service';

@Component({
  selector: 'xpo-ltl-shell-banner',
  templateUrl: './shell-banner.component.html',
  styleUrls: ['./shell-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'xpo-shellBanner',
    '[class.xpo-shellBanner--warn]': 'level === "warn"',
    '[class.xpo-shellBanner--critical]': 'level === "critical"',
  },
})
export class ShellBannerComponent implements OnDestroy {
  showBanner = true;
  private unsubscribe$ = new Subject<void>();

  @Input()
  level: 'warn' | 'critical' | '' = 'warn';

  get fosUrl() {
    return this.configManagerService.getSetting<string>(ConfigManagerProperties.fosProd);
  }

  constructor(private configManagerService: ConfigManagerService, private interactionService: InteractionService) {}

  toggleBanner() {
    this.showBanner = !this.showBanner;
  }

  getFosUrl(): Observable<string> {
    const globalSic$ = this.interactionService.subscribeToComponent(ComponentsEnum.SIC);
    const globalShift$ = this.interactionService.subscribeToComponent(ComponentsEnum.SHIFT);

    return combineLatest([globalSic$, globalShift$]).pipe(
      takeUntil(this.unsubscribe$),
      map(([sic, shift]) => {
        let lFosUrl = this.fosUrl;

        if (sic && shift && typeof sic === 'string' && typeof shift === 'string') {
          lFosUrl += `?sicCode=${sic}&shiftCode=${shift}`;
        }

        return lFosUrl;
      })
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
