<app-trailer-details-inside
  [trailerDetailsData]="trailerDetailsData"
  [alertData]="alertData"
></app-trailer-details-inside>
<history [exception]="alert"></history>

<!-- Approve/Decline Request LH Users -->
<hss-actions [status]="status" [alert]="alert" [type]="type"></hss-actions>

<!-- Request Approval  SIC users NewSic status -->
<request-approval [alert]="alert" *ngIf="showRqsApprovalPanel"></request-approval>
