export enum ImageSearchRecordLabels {
  Trailer = 'Trailer',
  ProNbr = 'PRO#',
  Origin = 'Origin',
  Loading = 'Close SIC',
  LoadingSic = 'Loading SIC',
  LoadDestinationSic = 'Load Dest',
  Dest = 'Dest.',
  SummaryMetrics = 'Summary Metrics',
  Utilization = 'Utilization',
  UtilizationUnit = 'Cube%',
  Weight = 'Weight(Lbs)',
  WeightUnit = 'Lbs',
  Shift = 'Close Shift',
  Door = 'Door',
  ClosedBy = 'Closed By',
  LoadedBy = 'Loaded By',
  MotorMoves = 'MM',
  FreightType = 'Freight Type',
  DateTime = 'Date / Time',
  Shipments = 'Shipments',
  Status = 'Status',
}
