<!-- <mat-progress-spinner [mode]="isDeterminate ? 'determinate' : 'indeterminate'" [value]="percentValue"
  [ngStyle]="spinnerStyle" diameter="100">
</mat-progress-spinner>
<div class="spinner-val-text">
  <h1>{{ amountValue }}</h1>
  <span>{{ label }}</span>
</div> -->
<div
  class="full-circle"
  [ngClass]="{ selected: selected, 'not-aging': !aging }"
  [ngStyle]="{ width: focusDiameter + 'px', height: focusDiameter + 'px' }"
  (mouseenter)="setHover(true)"
  (mouseleave)="setHover(false)"
>
  <div class="progress-circle" [ngStyle]="{ width: diameter + 'px', height: diameter + 'px' }" [ngClass]="colorClass">
    <span class="center"> </span>
    <span class="outer">
      <span class="value">{{ amountValue }}</span>
      <span class="label">{{ label }}</span>
    </span>
    <span class="inner"></span>

    <mat-progress-spinner
      [mode]="isDeterminate ? 'determinate' : 'indeterminate'"
      [value]="percentValue"
      [ngStyle]="spinnerStyle"
      [strokeWidth]="8"
      diameter="{{ getDiameter() }}"
    >
    </mat-progress-spinner>
  </div>
</div>
