<xpo-board [dataSource]="datasource" [viewTemplates]="viewTemplates" [views]="views" suppressViewSwitcher="true">
  <div class="lr-container">
    <xpo-ag-grid-board
      (gridReady)="onLanesGridLoad($event)"
      [customGridOptions]="gridOptions"
      enableSorting="true"
      rowModelType="client-side"
      rowSelection="single"
      enableMovableColumns="true"
    >
      <ag-grid-angular
        style="width: 100%"
        [frameworkComponents]="frameworkComponents"
        [aggFuncs]="aggFunctions"
        [groupHideOpenParents]="true"
      >
      </ag-grid-angular>
    </xpo-ag-grid-board>
  </div>
</xpo-board>
