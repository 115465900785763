<div class="details-overview" *ngIf="data">
  <div class="header">
    <div>
      <label>PLANNED MOVE TO</label>
      <span>{{ plannedMoveTo }}</span>
    </div>
    <div>
      <label>PLANNED DOOR</label>
      <span>{{ plannedDoor }}</span>
    </div>
    <div>
      <label>MISLOADED TO</label>
      <span>{{ misloadedTo }}</span>
    </div>
    <div>
      <label>TRAILER</label>
      <span>{{ trailerNbr }}</span>
    </div>
    <div>
      <label>DOOR</label>
      <span>{{ doorNbr }}</span>
    </div>
    <div>
      <label>SERVICE DATE</label>
      <span>{{ serviceDate | date: 'MM/dd/y' }}</span>
    </div>
    <div>
      <label>CALC. DEL. DATE</label>
      <span>{{ deliveryDate | date: 'MM/dd/y' }}</span>
    </div>
  </div>
  <mat-expansion-panel expanded="true" hideToggle="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h1 class="m-2 mb-2">General Details</h1>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="general-info">
      <shipment-details-general-info [shipment]="shipment"></shipment-details-general-info>
    </div>
    <div class="row first-wrapper">
      <div class="item">
        <h2 class="title">Shipper</h2>
        <div *ngIf="data.shipperAddress">
          <p>
            <b>{{ data.shipperAddress?.name1 }}</b>
          </p>
          <p>{{ data.shipperAddress?.address }}</p>
          <p>
            {{ data.shipperAddress?.city }}, {{ data.shipperAddress?.stateCd }} {{ data.shipperAddress?.zip6 }}-{{
              data.shipperAddress?.zip4RestUs
            }}
            {{ data.shipperAddress?.countryCd }}
          </p>
        </div>
        <div *ngIf="!data.shipperAddress">
          <p>-</p>
        </div>
      </div>
      <div class="item">
        <h2>Consignee</h2>
        <div *ngIf="data.consigneeAddress">
          <p>
            <b>{{ data.consigneeAddress?.name1 }}</b>
          </p>
          <p>{{ data.consigneeAddress?.address }}</p>
          <p>
            {{ data.consigneeAddress?.city }}, {{ data.consigneeAddress?.stateCd }} {{ data.consigneeAddress?.zip6 }}-{{
              data.consigneeAddress?.zip4RestUs
            }}
            {{ data.consigneeAddress?.countryCd }}
          </p>
        </div>
        <div *ngIf="!data.consigneeAddress">
          <p>-</p>
        </div>
      </div>
      <div class="item">
        <h2>Bill to</h2>
        <div *ngIf="data.billToAddress">
          <p>
            <b>{{ data.billToAddress?.name1 }}</b>
          </p>
          <p>{{ data.billToAddress?.address }}</p>
          <p>
            {{ data.billToAddress?.city }}, {{ data.billToAddress?.stateCd }} {{ data.billToAddress?.zip6 }}-{{
              data.billToAddress?.zip4RestUs
            }}
            {{ data.billToAddress?.countryCd }}
          </p>
        </div>
        <div *ngIf="!data.billToAddress">
          <p>-</p>
        </div>
      </div>
    </div>
    <shipment-details-remarks [shipment]="shipment"></shipment-details-remarks>
    <shipment-details-line-items [alert]="lineItemsAlert" [shipment]="shipment" [hist]="hist">
    </shipment-details-line-items>
  </mat-expansion-panel>
</div>
