import { Component } from '@angular/core';
@Component({
  selector: 'app-grid-action-icon',
  templateUrl: './grid-action-icon.component.html',
  styleUrls: ['./grid-action-icon.component.scss'],
})
export class GridActionIconComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
    console.log(this.params);
  }
}
