import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'app-closed-reason',
  styles: ['mat-icon { margin-left: 15px; margin-top: 3px }'],
  template: `
    <mat-icon
      *ngIf="isFalsePositive"
      matTooltip="{{ params.value }}"
      matTooltipPosition="above"
      [svgIcon]="IconsKeys.redFlag"
    >
    </mat-icon>
  `,
})
export class ClosedReasonFormatterComponent implements ICellRendererAngularComp {
  params: any;
  readonly IconsKeys = IconsKeys;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: any): void {
    console.log(params);
    this.params = params;
  }

  get isFalsePositive(): boolean {
    return this.params.value === 'False Positive';
  }
}
