import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sign' })
export class Sign implements PipeTransform {
  transform(num: number, args?: any): string {
    if (num === 0) {
      return '0';
    } else {
      return `${num < 0 ? '🡡' : '🡣'} ${Math.abs(num)}`;
    }
  }
}
