import { Injectable } from '@angular/core';
import {
  ExceptionLocationTypeCd,
  ExceptionStatusCd,
  ExceptionSummaryCd,
  ExceptionSummaryTypeCd,
  ExceptionTypeCd,
} from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentsEnum } from '../../shared/enums/components.enum';
import { InteractionService } from '../../shared/services/interaction.service';
import { PDState } from '../models/pd-state';
import { ChangeFeedService } from './../../shared/services/change-feed.service';

@Injectable({
  providedIn: 'root',
})
export class PDChangesFeedHandlerService {
  private openState: PDState = new PDState();
  headerTrigger$ = new BehaviorSubject<boolean>(true);
  constructor(private changeFeedService: ChangeFeedService, private interactionService: InteractionService) {}

  subscribeToChangesFeedHandler(): Observable<string[]> {
    return this.changeFeedService.subscribeToChangeFeed().pipe(
      map((exception: Exception) => {
        if (!this.openState || !exception) {
          return [];
        }
        const operationExcept = exception.operationException;
        const planDate = operationExcept.planDate;
        const levelsForUpdate: string[] = [];

        if (moment(planDate).isSame(this.openState.planDate)) {
          this.headerTrigger$.next(false);
          if (this.openState.summary && this.openState.summaryType) {
            levelsForUpdate.push('initial');

            if (operationExcept.exceptionTypeCd === this.openState.alertType) {
              levelsForUpdate.push('alert-type');
            }

            if (operationExcept.linehaulRegion === this.openState.linehaulRegionCd) {
              levelsForUpdate.push(ExceptionLocationTypeCd.LINEHAUL_REGION);
              if (operationExcept.loggedSic === this.openState.sicCd) {
                levelsForUpdate.push(ExceptionLocationTypeCd.LINEHAUL_SIC);
              }
            }
            if (operationExcept.operationalRegion === this.openState.operationalRegionCd) {
              levelsForUpdate.push(ExceptionLocationTypeCd.OPERATIONAL_REGION);
              if (operationExcept.district === this.openState.districtCd) {
                levelsForUpdate.push(ExceptionLocationTypeCd.DISTRICT);
                if (operationExcept.loggedSic === this.openState.sicCd) {
                  levelsForUpdate.push(ExceptionLocationTypeCd.OPERATION_SIC);
                }
              }
            }
          }
        }

        return levelsForUpdate;
      })
    );
  }

  subscribeToRefresh(): Observable<string[]> {
    return this.interactionService.subscribeToComponent(ComponentsEnum.REFRESH_DATA).pipe(
      map(() => {
        if (!this.openState) {
          return [];
        }
        const levelsForUpdate: string[] = [];
        this.headerTrigger$.next(true); // Sending true cause we want to show loading overlay
        if (this.openState.summary && this.openState.summaryType) {
          levelsForUpdate.push('initial');

          if (this.openState.alertType) {
            levelsForUpdate.push('alert-type');
          }

          if (this.openState.linehaulRegionCd) {
            levelsForUpdate.push(ExceptionLocationTypeCd.LINEHAUL_REGION);
            if (this.openState.sicCd) {
              levelsForUpdate.push(ExceptionLocationTypeCd.LINEHAUL_SIC);
            }
          }
          if (this.openState.operationalRegionCd) {
            levelsForUpdate.push(ExceptionLocationTypeCd.OPERATIONAL_REGION);
            if (this.openState.districtCd) {
              levelsForUpdate.push(ExceptionLocationTypeCd.DISTRICT);
              if (this.openState.sicCd) {
                levelsForUpdate.push(ExceptionLocationTypeCd.OPERATION_SIC);
              }
            }
          }
        }
        return levelsForUpdate;
      })
    );
  }

  updateHeader() {
    this.headerTrigger$.next(false);
  }

  setHeaderState(alertStatus: ExceptionStatusCd, summaryType: ExceptionSummaryTypeCd, summary: ExceptionSummaryCd) {
    this.clearState();
    this.openState.alertStatus = alertStatus;
    this.openState.summary = summary;
    this.openState.summaryType = summaryType;
  }

  private clearState() {
    this.openState.alertStatus = null;
    this.openState.summary = null;
    this.openState.summaryType = null;
    this.openState.alertType = null;
    this.openState.operationalRegionCd = null;
    this.openState.linehaulRegionCd = null;
    this.openState.districtCd = null;
    this.openState.sicCd = null;
  }

  setDate(planDate: string) {
    this.openState.planDate = planDate;
  }

  setAlertType(type: ExceptionTypeCd) {
    this.openState.alertType = type;
    this.openState.operationalRegionCd = null;
    this.openState.linehaulRegionCd = null;
    this.openState.districtCd = null;
    this.openState.sicCd = null;
  }

  setLevelState(level: ExceptionLocationTypeCd, value: string) {
    switch (level) {
      case ExceptionLocationTypeCd.LINEHAUL_REGION:
        this.openState.linehaulRegionCd = value;
        this.openState.operationalRegionCd = null;
        this.openState.districtCd = null;
        this.openState.sicCd = null;
        break;
      case ExceptionLocationTypeCd.OPERATIONAL_REGION:
        this.openState.operationalRegionCd = value;
        this.openState.linehaulRegionCd = null;
        this.openState.districtCd = null;
        this.openState.sicCd = null;
        break;
      case ExceptionLocationTypeCd.DISTRICT:
        this.openState.districtCd = value;
        this.openState.sicCd = null;
        break;
      case ExceptionLocationTypeCd.OPERATION_SIC || ExceptionLocationTypeCd.LINEHAUL_SIC:
        this.openState.sicCd = value;
        break;
    }
  }
}
