<ng-container *ngIf="alertType === 'GMisload'; then gAlert"></ng-container>
<ng-container *ngIf="alertType === 'HSS'; then underweightAlert"></ng-container>
<ng-container
  *ngIf="
    alertType === 'AddLoadRequest' || alertType === 'CutLoadRequest' || alertType === 'LoadRequest';
    then loadRequestAlert
  "
></ng-container>
<ng-container *ngIf="alertType === 'ProgressiveLoad'; then progressiveLoadAlert"></ng-container>
<ng-container *ngIf="alertType === 'PartialBypass'; then bufiAlert"></ng-container>

<ng-template #loadRequestAlert>
  <load-request-table [actions]="actions" [dataSource]="dataSource" [tableTab]="alertStatus" [tableType]="alertType">
  </load-request-table>
</ng-template>

<ng-template #gAlert>
  <app-g-misload-table [actions]="actions" [dataSource]="dataSource" [tableTab]="alertStatus" [tableType]="alertType">
  </app-g-misload-table>
</ng-template>

<ng-template #underweightAlert>
  <app-hss-underweight-table
    [actions]="actions"
    [dataSource]="dataSource"
    [tableTab]="alertStatus"
    [tableType]="alertType"
    #hssTable
  ></app-hss-underweight-table>
</ng-template>

<ng-template #progressiveLoadAlert>
  <progressive-load-table
    [actions]="actions"
    [dataSource]="dataSource"
    [tableTab]="alertStatus"
    [tableType]="alertType"
  >
  </progressive-load-table>
</ng-template>

<ng-template #bufiAlert>
  <app-bufi-alert-table [actions]="actions" [dataSource]="dataSource" [tableTab]="alertStatus" [tableType]="alertType">
  </app-bufi-alert-table>
</ng-template>
