<div class="details-line-items">
  <mat-tab-group>
    <mat-tab label="">
      <ng-template mat-tab-label>
        Line Items
        <span class="item-badge">
          {{ lineItemsCount }}
        </span>
      </ng-template>
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.description || '-' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Pieces">
          <mat-header-cell *matHeaderCellDef> Quantity </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.pieces || '-' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Class">
          <mat-header-cell *matHeaderCellDef> Class </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.classType ? commodityClassCdPipe.transform(element.classType) : '-' }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Rate Class">
          <mat-header-cell *matHeaderCellDef> Rate </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.asRatedClassCd ? commodityClassCdPipe.transform(element.asRatedClassCd) : '-' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="NMFC">
          <mat-header-cell *matHeaderCellDef> NMFC </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.nmfc || '-' }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Weight">
          <mat-header-cell *matHeaderCellDef> Weight </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.weight || '-' }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        History
        <span class="item-badge">{{ dataSourceHistory.length }}</span>
      </ng-template>
      <shipment-details-events [dataSource]="dataSourceHistory" *ngIf="showHistory"></shipment-details-events>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        Dimensions
        <span class="item-badge">{{ dimensions && dimensions.length ? dimensions.length : 0 }}</span>
      </ng-template>
      <div class="shipment-details-dimensions">
        <div class="shipment-details-dimensions__table">
          <table mat-table [dataSource]="dimensions">
            <ng-container *ngFor="let column of dimensionsColumns" [matColumnDef]="column.id">
              <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
              <td mat-cell *matCellDef="let row">
                <span>{{ row[column.id] }}</span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
          </table>
        </div>
        <div class="no-data" *ngIf="noDimensions === true">
          No data available
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
