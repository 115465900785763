import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import {
  CarrierManagementApiService,
  ListTrailerPullsQuery,
  ListTrailerPullsResp,
  Pull,
} from '@xpo-ltl/sdk-carriermanagement';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { OperationException } from '@xpo-ltl/sdk-exceptionmanagement';
import {
  CalculateTrailerCostOpportunityPath,
  CalculateTrailerCostOpportunityRqst,
  GetTrailerContentsQuery,
  GetTrailerContentsResp,
  LinehaulOperationsApiService,
} from '@xpo-ltl/sdk-linehauloperations';
import { ActionService } from '../../../../services/action.service';
import { SidePanelVisibilityService } from '../../../../services/side-panel-visibility.service';

@Component({
  selector: 'request-approval',
  templateUrl: './request-approval.component.html',
  styleUrls: ['./request-approval.component.scss'],
})
export class RequestApprovalComponent implements OnInit {
  @Input() alert: any;

  form: FormGroup;
  comment: AbstractControl = new FormControl();
  bookingControl: AbstractControl = new FormControl();
  pulls: Pull[] = [];
  filteredPulls: Observable<any[]>;
  trailerContent: GetTrailerContentsResp;

  constructor(
    private fb: FormBuilder,
    private actionService: ActionService,
    private linehaulOpsService: LinehaulOperationsApiService,
    private carrierManagementApiService: CarrierManagementApiService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private xpoSnackBar: XpoSnackBar
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.getBookingNumbers();
  }

  getBookingNumbers() {
    const opEx = this.alert.alert.operationException as OperationException;

    if (opEx.bookingNumber && opEx.pullId) {
      // Do not show request approval panel
    } else {
      const trailerRqst = new GetTrailerContentsQuery();
      trailerRqst.trailerNumber = opEx.trailerNbr;

      this.linehaulOpsService
        .getTrailerContents(trailerRqst)
        .pipe(
          tap((trailerResp) => {
            this.trailerContent = trailerResp;
            if (trailerResp) {
              const pullRqst = new ListTrailerPullsQuery();

              pullRqst.scac = this.trailerContent.foreignScacCd;
              pullRqst.trailer = parseInt(this.trailerContent.trailerNumber.substr(5), 10);
              pullRqst.originSic = this.trailerContent.origSic;
              pullRqst.destSic = this.trailerContent.loadDestination;
              pullRqst.statusIds = [2]; // As requested in DLC-4779

              this.carrierManagementApiService
                .listTrailerPulls(pullRqst)
                .pipe(
                  tap((resp: ListTrailerPullsResp) => {
                    this.pulls = resp.pulls.sort((a, b) => (a.booking > b.booking ? 1 : -1));
                    this.initAutoComplete();
                  })
                )
                .subscribe();
            }
          })
        )
        .subscribe();
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      comment: [''],
      bookingNumber: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    });

    this.comment = this.form.get('comment');
    this.bookingControl = this.form.get('bookingNumber');
  }

  async sendRequestApproval() {
    if (this.form.valid) {
      const opEx = this.alert.alert.operationException as OperationException;
      const bookingNumber = this.bookingControl.value;
      const pull = await this.getPullId(parseInt(bookingNumber, 10));
      const costOppotunityRqst = new CalculateTrailerCostOpportunityRqst();
      const costOpportunityPathParams = new CalculateTrailerCostOpportunityPath();

      costOppotunityRqst.sourceInstId = this.alert.alert.exceptionInstId;
      costOppotunityRqst.scheduledDepartDate = opEx.planDate;
      costOppotunityRqst.sicCd = this.trailerContent.sicCd;
      costOppotunityRqst.loadDestinationSicCd = this.trailerContent.loadDestination;
      costOpportunityPathParams.shiftCd = this.getShiftCd(this.trailerContent.trailerShiftCd);
      costOpportunityPathParams.trailerNbr = opEx.trailerNbr;
      pull.trailer = parseInt(opEx.trailerNbr.split('-')[1], 10);

      if (pull && pull.pullId) {
        const status = pull.companyBusiness === 'Y' ? ExceptionStatusCd.CANCELLED : ExceptionStatusCd.NEW;
        const comment = (this.comment && this.comment.value) || '';

        this.actionService
          .updateHSSException(this.alert.alert, status, '', bookingNumber, pull.pullId.toString(), comment, pull)
          .subscribe(
            (resp: any) => {
              if (resp) {
                this.linehaulOpsService
                  .calculateTrailerCostOpportunity(costOppotunityRqst, costOpportunityPathParams)
                  .subscribe((costOptResp) => {
                    console.log(costOptResp);
                  });
                this.closePanel();
              }
            },
            (err) => {
              console.error('Request Approval - sendRequestApproval:', err);
            }
          );
      }
    }
  }

  private closePanel() {
    this.sidePanelVisibilityService.closePanels();
  }

  /**
   * search booking from the pull List or call CMS to validate booking number entered
   */
  async getPullId(booking: number) {
    let pull = this.pulls.find((p: any) => p.booking === booking);

    if (!pull) {
      try {
        const pullRqst = new ListTrailerPullsQuery();
        pullRqst.scac = this.trailerContent.foreignScacCd;
        pullRqst.booking = booking;
        pullRqst.statusIds = [2, 3, 4]; // waiting to depart, depart via, depart
        pullRqst.originSic = this.trailerContent.origSic;

        pull = await this.carrierManagementApiService
          .listTrailerPulls(pullRqst, { toastOnError: false })
          .pipe(
            map((resp) => {
              if (resp && resp.pulls && resp.pulls.length) {
                return resp.pulls[resp.pulls.length - 1];
              }
            })
          )
          .toPromise();
      } catch (error) {
        this.xpoSnackBar.error('Booking Number entered does not exist in CMS.');
      }
    }

    return pull;
  }

  initAutoComplete() {
    this.filteredPulls = this.bookingControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterBooking(value))
    );
  }

  filterBooking(value: string) {
    const filterValue = value.toString().toLowerCase();

    return this.pulls.filter(
      (pull) =>
        pull.booking
          .toString()
          .toLowerCase()
          .indexOf(filterValue) === 0
    );
  }

  getShiftCd(code: string) {
    const values = {
      D: 'DayReship',
      I: 'Inbound',
      N: 'NightFAC',
      F: 'NightFAC',
      O: 'Outbound',
    };

    return values[code];
  }

  hasError(error: string, formGrp?: FormGroup, field?: string): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }
}
