import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-requester-comment-formatter-cell',
  template: `
    <span matTooltip="{{ params.value }}" matTooltipPosition="above">{{ params.value }}</span>
  `,
})
export class RequesterCommentFormatterComponent implements ICellRendererAngularComp {
  params: any;

  refresh(params: any): boolean {
    return false;
  }

  agInit(params: any): void {
    this.params = params;
  }
}
