import { Injectable } from '@angular/core';
import { XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable } from 'rxjs';
import { DriversService } from '../services/drivers.service';

@Injectable({
  providedIn: 'root',
})
export class DetailDataSourceService extends XpoBoardDataSource {
  publicState$ = this.state$;

  constructor(private driversService: DriversService) {
    super();
  }

  fetchData(state: XpoBoardState): Observable<any> {
    return this.driversService.getBidsDetails(state);
  }
}
