import { Component } from '@angular/core';
import { DriverTurnTypeCd, JobSelectionCd } from '@xpo-ltl/sdk-common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TypeEnum } from '../../../drivers/models/type.enum';
import { SpecialServiceGroupCredential } from '../special-service-group/special-service-group.component';

export interface DriverNameRendererParams extends ICellRendererParams {
  credentialGetter: (params: ICellRendererParams) => SpecialServiceGroupCredential[];
  getSicCode: () => string;
}

@Component({
  selector: 'app-driver-name-renderer',
  templateUrl: './driver-name-renderer.component.html',
  styleUrls: ['./driver-name-renderer.component.scss'],
})
export class DriverNameRendererComponent implements ICellRendererAngularComp {
  params: any;
  current_sic: string;
  dsrName: string;
  empty: boolean = false;
  domicileSic: string = '';
  jspShift: string = '';
  type: string = ' ';
  credentials: SpecialServiceGroupCredential[] = [];

  agInit(params: DriverNameRendererParams): void {
    this.handleParamsUpdate(params);
  }

  handleParamsUpdate(params: DriverNameRendererParams): void {
    this.params = params;
    this.current_sic = params.getSicCode()?.toUpperCase();

    if (params.data && params.data.dsrName !== ' ') {
      this.dsrName = params.data.dsrName;

      if (params.data.domicileSicCd || params.data.assignSicCd) {
        if (params.data.domicileSicCd !== this.current_sic) {
          if (params.data.assignSicCd === this.current_sic) {
            this.domicileSic = '';
            this.type = `(${params.data.domicileSicCd || ''} Walkabout)`;
          }
        } else {
          const jobSelectionCd = params.data.jobSelectionCd;

          if (jobSelectionCd && jobSelectionCd !== JobSelectionCd.NIGHT_LINEHAUL) {
            this.jspShift = ' (' + TypeEnum[jobSelectionCd] + ')';
          }

          if (!jobSelectionCd) {
            this.jspShift = ' (Incomplete JSP)';
          }

          if (params.data.turnTypeCd) {
            this.jspShift = '';
            if (params.data.turnTypeCd === DriverTurnTypeCd.CITY_TURN) {
              this.type = '(City Turn)';
            }
          }
        }
      }
      params.data['type'] = this.type;
    } else {
      this.empty = true;
    }
    this.credentials = params.credentialGetter?.(params) ?? params.data.shownCredentials;
  }

  refresh(params: any): boolean {
    this.handleParamsUpdate(params);
    return true;
  }
}
