import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { LoadRequestReasonCode, UpsertLoadRequestRqst } from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { combineLatest, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ComponentsEnum } from '../../../../enums/components.enum';
import { ActionService } from '../../../../services/action.service';
import { AuthService } from '../../../../services/auth.service';
import { InteractionService } from '../../../../services/interaction.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';
import { SidePanelVisibilityService } from './../../../../services/side-panel-visibility.service';

@Component({
  selector: 'approve-decline',
  templateUrl: './approve-decline.component.html',
  styleUrls: ['./approve-decline.component.scss'],
})
export class ApproveDeclineComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() editedReason: string;
  show = false;
  action: string;
  loadRequestData;
  loadsQty = '';
  formNames = {
    LoadQty: 'loadQuantity',
    ApprovalType: 'approvalType',
    DeclineReason: 'declineReason',
    ApproveReason: 'approveReason',
  };
  form: FormGroup;
  loadQuantityFormControl: AbstractControl = new FormControl();
  approvalTypeFormControl: AbstractControl = new FormControl();
  declineReasonFormControl: AbstractControl = new FormControl();
  approveReasonFormControl: AbstractControl = new FormControl();
  approvalType = ['Eligible', 'Ineligible'];
  readOnlyRoles: boolean = false;
  info = {
    requesting: '',
    requested: '',
    lane: '',
    nOfLoads: '',
    requestedBy: '',
    reason: '',
    comments: '',
    closeTo: '',
    decisionComments: '',
    isExpired: '',
  };
  isExpired;
  performanceFiltersDate: Date;
  actionTypes: string[] = ['Approve', 'Decline'];
  selectedAction: string;
  exceptionTypeCd: string;

  constructor(
    private timeService: XpoLtlTimeService,
    private fb: FormBuilder,
    private interactionService: InteractionService,
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private xpoSnackBar: XpoSnackBar,
    private actionService: ActionService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private loggingApiService: LoggingApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    if (this.statusCheck()) {
      this.show = true;
      this.initializeForm();
      this.loadInfo();
      this.selectedAction = 'Approve';
      if (this.action === 'disabled') {
        this.readOnlyRoles = true;
      }
    }
    combineLatest(this.performanceFilters$)
      .subscribe(([performanceFilters]) => {
        this.performanceFiltersDate = performanceFilters.data.date;
      })
      .unsubscribe();
  }

  get isExpiredByShift(): boolean {
    const { alert } = this.alert;
    return this.checkExpiredByShift(
      alert.auditInfo.createdTimestamp,
      alert.operationException.loggedSic,
      alert.operationException.shiftCd
    );
  }

  get performanceFilters$() {
    return this.interactionService.subscribeToComponent(ComponentsEnum.PERFORMANCE_DASHBOARD);
  }

  private statusCheck(): boolean {
    return this.status === ExceptionStatusCd.NEW;
  }

  blankOnSelection(): void {
    this.form.controls.declineReason.setValue('');
    this.form.controls.approveReason.setValue('');
  }

  initializeForm() {
    this.form = this.fb.group({
      loadQuantity: [
        '',
        [
          Validators.required,
          Validators.max(Math.abs(this.alert.alert.operationException.loadRequestedQuantity)),
          this.checkMaxQuantity.bind(this),
        ],
      ],
      approvalType: ['', Validators.required],
      declineReason: [],
      approveReason: [],
    });
    this.loadQuantityFormControl = this.form.get('loadQuantity');
    this.approvalTypeFormControl = this.form.get('approvalType');
    this.declineReasonFormControl = this.form.get('declineReason');
    this.approveReasonFormControl = this.form.get('approveReason');
  }

  checkMaxQuantity() {
    if (this.loadQuantityFormControl.value > Math.abs(this.alert.alert.operationException.loadRequestedQuantity)) {
      return { quantityOverflow: true };
    }
  }

  loadInfo() {
    let createdDate;
    this.loadRequestData = this.alert.alert;
    if (this.loadRequestData) {
      this.info.requesting = this.loadRequestData.operationException.loggedSic;
      this.info.requested = this.loadRequestData.auditInfo.createdTimestamp;
      this.info.lane = this.loadRequestData.operationException.moveToSicCode;
      this.info.nOfLoads = this.loadRequestData.operationException.loadRequestedQuantity.toLocaleString();
      this.info.closeTo = this.loadRequestData.operationException.loadToSicCode;
      this.loadsQty = Math.abs(this.loadRequestData.operationException.loadRequestedQuantity).toString();
      this.info.requestedBy = this.loadRequestData.operationException.employeeName || '';
      this.info.comments = this.loadRequestData.operationException.actualValue || '';
      this.info.reason = this.loadRequestData.operationException.requestReason;
      this.info.decisionComments = this.loadRequestData.operationException.linehaulComments || '';
      // this.approvalTypeFormControl.setValue(this.loadRequestData.approveTypeCd);
      this.isExpired = this.loadRequestData.isExpired;
      createdDate = new Date(this.loadRequestData.auditInfo.createdTimestamp);
      this.setLoadQuantityArray();

      const a = this.loadRequestData.operationException.reasonCd;
      this.interactionService
        .getLoadRequestReasonCodes()
        .subscribe((loadRequestReasonCodes: LoadRequestReasonCode[]) => {
          if (loadRequestReasonCodes && loadRequestReasonCodes.length > 0) {
            loadRequestReasonCodes.map((x) => {
              if (x.reasonCd === this.loadRequestData.reasonCd) {
                this.info.reason = x.reasonDescription;
              }
            });
          }
        });
    }
  }

  setLoadQuantityArray() {
    const {
      approvedQuantity,
      declinedQuantity,
      loadRequestedQuantity,
      exceptionTypeCd,
    } = this.loadRequestData.operationException;
    this.exceptionTypeCd = exceptionTypeCd;
    if (approvedQuantity) {
      this.loadQuantityFormControl.setValue(approvedQuantity);
    } else {
      declinedQuantity
        ? this.loadQuantityFormControl.setValue(declinedQuantity)
        : this.loadQuantityFormControl.setValue(loadRequestedQuantity);
    }

    this.form.updateValueAndValidity();
  }

  getFormattedDate(date) {
    const timeToSic = this.timeService.formatDate(
      this.loadRequestData.auditInfo.createdTimestamp,
      ' HH:mm, MM/DD/YYYY',
      this.loadRequestData.operationException.loggedSic
    );
    return timeToSic;
  }

  hasError(error: string, formGrp?: FormGroup, field?: string): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  approve() {
    if (this.isAllowedToApproveOrDecline()) {
      const declinedLoads =
        this.exceptionTypeCd === 'CutLoadRequest'
          ? this.loadRequestData.operationException.loadRequestedQuantity +
            Math.abs(this.form.get('loadQuantity').value)
          : this.loadRequestData.operationException.loadRequestedQuantity -
            Math.abs(this.form.get('loadQuantity').value);
      if (!this.approvalTypeFormControl.value) {
        this.approvalTypeFormControl.setErrors({ required: true });
      } else {
        this.approvalTypeFormControl.setErrors(null);
      }
      /*
      if (declinedLoads > 0 && !this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
      } else {
        this.declineReasonFormControl.setErrors(null);
      }
      */
      if (!this.form.get('loadQuantity').value || this.form.get('loadQuantity').value === 0) {
        this.loadQuantityFormControl.setErrors({ required: true });
      } else {
        this.loadQuantityFormControl.setErrors(null);
      }
      this.form.markAllAsTouched();
      const request = new UpsertLoadRequestRqst();

      if (this.form.valid) {
        request.loadRequest = {
          runId: null,
          declinedQuantity: Math.abs(declinedLoads) || null,
          approveDeclineDateTime: new Date() || null,
          requestorName: this.loadRequestData.operationException.employeeName || null,
          linehaulName: `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}` || null,
          approveReasonCd: this.editedReason || this.loadRequestData.operationException.requestReasonCd || null,
          declineReasonCd: null,
          linehaulComments: this.form.get('approveReason').value || null,
          approveTypeCd: this.form.get('approvalType').value || null,
          approvedQuantity: Math.abs(this.form.get('loadQuantity').value) || null,
          lhoLoadRequestId: this.loadRequestData.operationException.value || null,
          originSicCode: this.loadRequestData.operationException.loggedSic || null,
          moveToSicCode: this.loadRequestData.operationException.moveToSicCode || null,
          loadToSicCode: this.loadRequestData.operationException.loadToSicCode || null,
          reasonCd: this.editedReason || this.loadRequestData.operationException.requestReasonCd || null,
          requestQuantity: this.loadRequestData.operationException.loadRequestedQuantity || null,
          requestorEmplid: this.loadRequestData.auditInfo.createdById || null,
          requestorComments: this.loadRequestData.operationException.actualValue || null,
          linehaulEmplid: this.userRoleService.user.employeeId || null,
          regionCd: this.loadRequestData.operationException.linehaulRegion || null,
          planDate: this.loadRequestData.operationException.planDate || null,
          planShiftCd: this.loadRequestData.operationException.shiftCd || null,
          auditInfo: this.loadRequestData.auditInfo || null,
          requestType: this.loadRequestData.operationException.exceptionTypeCd || null,
          eligibleFromDateTime: null,
          eligibleToDateTime: null,
          statusCd: ExceptionStatusCd.APPROVED,
        };
        this.actionService.upsertLoadRequest(request, ExceptionStatusCd.APPROVED).subscribe(
          () => {
            this.loggingApiService.info(
              this.userRoleService.user.userId +
                ' - upsertLoadRequest ' +
                this.userRoleService.build +
                ' - Request: ' +
                (this.alert && this.alert.alert && this.alert.alert.exceptionInstId),
              this.authService.getEmployeeSic(),
              'Approval of Load Request from Performance Dashboard',
              'PUT',
              ExceptionTypeCd.LOAD_REQUEST
            );
            this.closePanel();
          },
          (err) => {
            this.xpoSnackBar.error(err.error.moreInfo[0].location);
          }
        );
      } else {
        this.xpoSnackBar.error('Please fix the errors below before Approving');
      }
    }
  }

  decline() {
    if (this.isAllowedToApproveOrDecline()) {
      this.loadQuantityFormControl.setValue(this.loadRequestData.operationException.loadRequestedQuantity);
      this.approvalTypeFormControl.setErrors(null);

      if (!this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
      } else {
        this.declineReasonFormControl.setErrors(null);
      }
      if (!this.form.get('loadQuantity').value || this.form.get('loadQuantity').value === 0) {
        this.loadQuantityFormControl.setErrors({ required: true });
      } else {
        this.loadQuantityFormControl.setErrors(null);
      }
      this.form.markAllAsTouched();
      const request = new UpsertLoadRequestRqst();
      if (this.form.valid) {
        request.loadRequest = {
          runId: null,
          declinedQuantity: Math.abs(this.form.get('loadQuantity').value) || null,
          approveDeclineDateTime: new Date() || null,
          requestorName: this.loadRequestData.operationException.employeeName || null,
          linehaulName: `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}` || null,
          approveReasonCd: null,
          declineReasonCd: this.editedReason || this.loadRequestData.operationException.requestReasonCd || null,
          linehaulComments: this.form.get('declineReason').value || null,
          approveTypeCd: this.form.get('approvalType').value || null,
          approvedQuantity: null,
          lhoLoadRequestId: this.loadRequestData.operationException.value || null,
          originSicCode: this.loadRequestData.operationException.loggedSic || null,
          moveToSicCode: this.loadRequestData.operationException.moveToSicCode || null,
          loadToSicCode: this.loadRequestData.operationException.loadToSicCode || null,
          reasonCd: this.editedReason || this.loadRequestData.operationException.requestReasonCd || null,
          requestQuantity: this.loadRequestData.operationException.loadRequestedQuantity || null,
          requestorEmplid: this.loadRequestData.auditInfo.createdById || null,
          requestorComments: this.loadRequestData.operationException.actualValue || null,
          linehaulEmplid: this.userRoleService.user.employeeId || null,
          regionCd: this.loadRequestData.operationException.linehaulRegion || null,
          planDate: this.loadRequestData.operationException.planDate || null,
          planShiftCd: this.loadRequestData.operationException.shiftCd || null,
          auditInfo: this.loadRequestData.auditInfo || null,
          requestType: this.loadRequestData.operationException.exceptionTypeCd || null,
          eligibleFromDateTime: null,
          eligibleToDateTime: null,
          statusCd: ExceptionStatusCd.DECLINED,
        };

        this.actionService.upsertLoadRequest(request, ExceptionStatusCd.DECLINED).subscribe(
          () => {
            this.loggingApiService.info(
              this.userRoleService.user.userId +
                ' - upsertLoadRequest ' +
                this.userRoleService.build +
                ' - Request: ' +
                (this.alert && this.alert.alert && this.alert.alert.exceptionInstId),
              this.authService.getEmployeeSic(),
              'Declination of Load Request from Performance Dashboard',
              'PUT',
              ExceptionTypeCd.LOAD_REQUEST
            );

            this.closePanel();
          },
          (err) => {
            this.xpoSnackBar.error(err.error.moreInfo[0].location);
          }
        );
      } else {
        this.xpoSnackBar.error('Please fix the errors below before Declining');
      }
    }
  }

  isAllowedToApproveOrDecline(): boolean {
    return this.authService.isAllowedToApprove();
  }

  private closePanel() {
    this.sidePanelVisibilityService.closePanels();
  }

  private checkExpiredByShift(date: string, sic: string, shift: string): boolean {
    // Sets variables to get proper ranges according to SIC
    const time = new Date(date);
    const now = new Date(this.timeService.formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss', sic));
    const created = new Date(this.timeService.formatDate(time, 'YYYY-MM-DDTHH:mm:ss', sic));
    const night = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T22:00:00'));
    const morning = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T10:00:00'));
    // Check if it's O created in O shift
    if (created > morning && created < night && shift === 'O') {
      // If outbound, false if now(to sic) is between shift
      return now > morning && now > night;
      // Check if it's an F created in O shift
    } else if (created > morning && created < night && shift === 'F') {
      // If O time, but F shift, false if now(to sic) is between shift (and morning's next day)
      return now < new Date(morning.setDate(morning.getDate() + 1)) ? false : true;
    } else {
      // If F, handle next day limit or current day
      if (created < morning) {
        // Current day, false if now(to sic) is after 10am limit
        return now < morning ? false : true;
      } else {
        // Next day, false if now(to sic) is after next day morning
        return now < new Date(morning.setDate(morning.getDate() + 1)) ? false : true;
      }
    }
  }
}
