import { Component, Input, OnInit } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import {
  ExceptionManagementApiService,
  ListStatusLogsForItemQuery,
  ListStatusLogsForItemResp,
  StatusLog,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ActionService } from '../../../../services/action.service';

@Component({
  selector: 'history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  @Input() exception: any;
  @Input() externalUser: boolean;
  ExceptionStatusCd = ExceptionStatusCd;
  loading = true;
  panelOpenState = [];
  statusResp: any;
  notNewSIC = false;
  notNewHss = false;
  statusCd = false;
  atLeastOneTransition = false;

  constructor(
    private exceptionService: ExceptionManagementApiService,
    private actionService: ActionService,
    private xpoSnackBar: XpoSnackBar
  ) {}

  ngOnInit() {
    this.notNewSIC = this.exception.alert.statusCd !== ExceptionStatusCd.NEW_SIC;

    if (!this.notNewSIC) {
      return;
    }
    const itemInstId = this.exception.alert.operationException.itemInstIds[0];

    this.getStatusLogs(itemInstId).subscribe((resp) => {
      this.setPanelOpenState(resp.length);
      this.statusResp = resp;
      if (this.statusResp.length < 2) {
        this.loading = false;
        return;
      } else {
        this.atLeastOneTransition = true;
      }
      if (
        this.statusResp.length >= 2 &&
        this.statusResp[this.statusResp.length - 1].statusCd === 'NewSIC' &&
        this.statusResp[this.statusResp.length - 2].statusCd === 'New'
      ) {
        this.statusResp[this.statusResp.length - 1].statusCd = 'New(SIC)';
        this.statusResp[this.statusResp.length - 2].statusCd = 'New (LH)';
      }
      this.loading = false;
    });
  }

  private setPanelOpenState(n: number) {
    for (let i = 0; i < n; i++) {
      this.panelOpenState[i] = false;
    }
  }

  private getStatusLogs(itemInstId: number): Observable<StatusLog[]> {
    const statusQuery: ListStatusLogsForItemQuery = {
      itemInstId: itemInstId,
      listInfo: null,
    };
    let statusLogs;
    return this.exceptionService.listStatusLogsForItem(statusQuery, { loadingOverlayEnabled: false }).pipe(
      switchMap((value: ListStatusLogsForItemResp) => {
        statusLogs = value.statusLogs;
        const observables = statusLogs.map((e: StatusLog) => {
          const id = e.auditInfo.updateById;
          if (
            id !== 'LTLAPP_USER' &&
            id !== 'Automation' &&
            id !== 'LTLAPPUSER' &&
            id !== 'LinehaulOperati' &&
            id !== 'DefaultApplicat'
          ) {
            return this.actionService.getInfoById(id, true);
          } else {
            return this.actionService.getInfoById(id, false);
          }
        });
        return forkJoin(observables);
      }),
      map((info: any) => {
        statusLogs.forEach((e, index) => {
          e.auditInfo['updatedName'] = info[index].name || 'SYSTEM';
          e.auditInfo['roleName'] = info[index].role || 'SYSTEM';
        });
        return statusLogs;
      }),
      catchError((err) => {
        this.xpoSnackBar.error(err);
        return of(undefined);
      })
    );
  }
}
