import { orderBy } from 'lodash';

export class SortHelper {
  static sort(value: any[], order: 'asc' | 'desc' | '', column: string = ''): any[] {
    if (!value || order === '' || !order) {
      return value;
    }
    if (value.length <= 1) {
      return value;
    }
    if (!column || column === '') {
      if (order === 'asc') {
        return value.sort();
      } else {
        return value.sort().reverse();
      }
    }
    return orderBy(value, [column], [order]);
  }
}
