<xpo-board
  [boardOptions]="boardOptions"
  [dataSource]="dataSource"
  [viewTemplates]="viewTemplates"
  [views]="views"
  [ngClass]="{ editionMode: isEditing }"
>
  <xpo-board-actions filterActions>
    <xpo-button-group>
      <p>
        <span>{{ available_count }}</span> - Available<span>{{ city_count }}</span> - City<span>{{ out_count }}</span> -
        Out of Service
      </p>
      <div class="summary"></div>
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="Trailer" routerLink="/equipment">
          <img src="/assets/images/button_trailer.svg" alt="" />
        </mat-button-toggle>
        <mat-button-toggle value="Tractor" routerLink="/equipment/tractors">
          <img src="/assets/images/button_tractor.svg" alt="" />
        </mat-button-toggle>
        <mat-button-toggle checked value="Dollies" routerLink="/equipment/dollies">
          <img src="/assets/images/button_dolly_selected.svg" alt="" style="max-height: 100%;" />
        </mat-button-toggle>
      </mat-button-toggle-group>
    </xpo-button-group>
  </xpo-board-actions>

  <xpo-ag-grid-board
    (gridReady)="onGridReady($event)"
    [customGridOptions]="gridOptions"
    enableSorting="true"
    rowModelType="client-side"
  >
  </xpo-ag-grid-board>
</xpo-board>
