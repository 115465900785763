import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardReadyEvent, XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoConfirmDialog, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ActionCd } from '@xpo-ltl/sdk-common';
import {
  LinehaulOperationsApiService,
  ListRecommendedDriversQuery,
  ListRecommendedTractorsQuery,
  ListRecommendedTrailersQuery,
  LnhSchedule,
  UpdateScheduleRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import {
  CellClassParams,
  ColDef,
  EditableCallbackParams,
  GridApi,
  GridOptions,
  ValueSetterParams,
} from 'ag-grid-community';
import { differenceInMinutes, isAfter, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { BehaviorSubject, combineLatest, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  RichTableSelectEditorComponent,
  RichTableSelectEditorParams,
} from '../shared/components/rich-table-select-editor/rich-table-select-editor.component';
import { TimeEditorComponent } from '../shared/components/time-editor/time-editor.component';
import { LinehaulFormatters } from '../shared/formatters/formatters';
import { DriverNameCellRendererComponent } from './cell-renderers/driver-name-cell-renderer/driver-name-cell-renderer.component';
import { MoveToCellRendererComponent } from './cell-renderers/move-to-cell-renderer/move-to-cell-renderer.component';
import { ScheduleStatusCellRendererComponent } from './cell-renderers/schedule-status-cell-renderer/schedule-status-cell-renderer.component';
import { TractorCellRendererComponent } from './cell-renderers/tractor-cell-renderer/tractor-cell-renderer.component';
import { TrailerCellRendererComponent } from './cell-renderers/trailer-cell-renderer/trailer-cell-renderer.component';
import {
  ViaDetailsCellRendererComponent,
  ViaDetailsCellRendererParams,
} from './cell-renderers/via-details-cell-renderer/via-details-cell-renderer.component';
import {
  SchedulesGridItemDetail,
  SchedulesGridItemDetailDriver,
  SchedulesGridItemDetailEstAndActual,
  SchedulesGridItemDetailTractor,
  SchedulesGridItemDetailTrailer,
  SchedulesGridItemDetailTrailerCurrentStatus,
} from './schedules-grid.model';
import { SchedulesService } from './schedules.service';
import { SchedulesDataSourceService } from './shedules-data-source.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
  providers: [SchedulesDataSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-Schedules' },
})
export class SchedulesComponent implements OnInit, OnDestroy {
  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  boardOptions: XpoBoardOptions = { suppressViewSwitcher: true, suppressGridSettingsPopover: true };
  noResultMessage$: Observable<string>;
  detailGridOptions: GridOptions = {
    getRowNodeId: (data) => data.lnhScheduleId,
    columnDefs: [
      { field: 'status', headerName: '', width: 50, cellRendererFramework: ScheduleStatusCellRendererComponent },
      { field: 'driver1', colId: 'driver1.id', ...this.getDriverColumnPartial() },
      { field: 'driver2', colId: 'driver2.id', hide: true, ...this.getDriverColumnPartial() },
      {
        field: 'tractor',
        colId: 'tractor.name',
        headerName: 'Tractor',
        cellRendererFramework: TractorCellRendererComponent,
        cellEditorFramework: RichTableSelectEditorComponent,
        cellEditorParams: <RichTableSelectEditorParams>{
          columns: [
            {
              field: 'name',
              headerName: 'Number',
              widthInPx: 150,
              valueFormatter: (value) => LinehaulFormatters.EquipmentName(value),
            },
            { field: 'status', headerName: 'Status', widthInPx: 100 },
            { field: 'maintenance', headerName: 'Maintenance', widthInPx: 100 },
            { field: 'domSic', headerName: 'Domicile SIC', widthInPx: 150 },
          ],
          getValues: (params) => this.getTractorEditOptions(params),
        },
        ...this.getEditableColumPartial((params) => this.updateTractor(params)),
      },
      { field: 'trailer1', colId: 'trailer1.name', ...this.getTrailerColumnPartial() },
      { field: 'trailer2', colId: 'trailer2.name', ...this.getTrailerColumnPartial() },
      { field: 'trailer3', colId: 'trailer3.name', hide: true, ...this.getTrailerColumnPartial() },
      {
        field: 'viaSic',
        headerName: 'VIA',
        cellEditorFramework: RichTableSelectEditorComponent,
        cellEditorParams: <RichTableSelectEditorParams>{
          columns: [{ field: 'viaSic' }],
          getValues: (params) => params.data.vias,
          hideHeader: true,
          formatValueBeforeSetting: (data) => data?.viaSic,
        },
        valueFormatter: (params) => {
          return params.data.isDoubleTurn ? 'Double Turn' : params.value || 'N/A';
        },
        width: 95,
        ...this.getEditableColumPartial((params) => this.updateVia(params)),
      },
      {
        field: 'cutTime',
        headerName: 'Cut Time',
        width: 120,
        valueFormatter: (params) => {
          return params.value ? this.timeService.formatDate(params.value, 'HH:mm', this.schedulesService.sicCd) : 'N/A';
        },
      },
      {
        field: 'departureTime',
        headerName: 'Departure',
        width: 120,
        cellEditorFramework: TimeEditorComponent,
        valueGetter: (params) => {
          const data = this.getActualOrEstimate(params.data[params.colDef.field]);
          return data ? this.timeService.formatDate(data, 'HH:mm', this.schedulesService.sicCd) : null;
        },
        valueFormatter: (params) => {
          return params.value ?? 'N/A';
        },
        ...this.getEditableColumPartial(
          (params) => this.updateEstimateDepartTime(params),
          (params) => {
            const data: SchedulesGridItemDetail = params.data;

            if (!data.cutTime) {
              return false;
            }

            const cutTime = this.getDateBasedOnCurrentSicDateTime(data.cutTime);
            const isDepartTimeGreaterThanCutTime = isAfter(parse(params.value, 'HH:mm', new Date()), cutTime);
            const isCutTimeLessThan30MinsAway =
              differenceInMinutes(cutTime, this.getDateBasedOnCurrentSicDateTime()) <= 30;
            const isTrailerLoading = (trailer: SchedulesGridItemDetailTrailer) =>
              trailer?.currentStatus === SchedulesGridItemDetailTrailerCurrentStatus.LDDK;
            const areTrailersLoading =
              isTrailerLoading(data.trailer1) || isTrailerLoading(data.trailer2) || isTrailerLoading(data.trailer3);

            return isDepartTimeGreaterThanCutTime || (isCutTimeLessThan30MinsAway && areTrailersLoading);
          }
        ),
      },
      {
        field: 'arrivalTime',
        headerName: 'Arrival',
        width: 120,
        valueGetter: (params) => this.getActualOrEstimate(params.data[params.colDef.field]),
        valueFormatter: (params) => {
          return params.value ? this.timeService.formatDate(params.value, 'HH:mm', this.schedulesService.sicCd) : 'N/A';
        },
      },
    ],
    rowHeight: 60, // TODO: this should be in core for all detail grid options
    headerHeight: 33, // TODO: this should be in core for all detail grid options
    defaultColDef: { suppressMenu: true },
    onRowDataChanged: (event) => {
      let showDriver2 = false;
      let showTrailer3 = false;

      event.api.forEachNode((rowNode) => {
        if (rowNode.data.driver2) {
          showDriver2 = true;
        }

        if (rowNode.data.trailer3 || rowNode.data.isTripleEnabled) {
          showTrailer3 = true;
        }
      });

      event.columnApi.setColumnVisible('driver2.name', showDriver2);
      event.columnApi.setColumnVisible('trailer3.equipmentId', showTrailer3);
    },
    getRowStyle: (params) => {
      if (params.data.isDispatched) {
        return { 'background-color': '#F6F6F6' };
      }

      return null;
    },
  };
  customGridOptions: GridOptions = {
    unSortIcon: true,
    icons: {
      groupExpanded: `<mat-icon class="material-icons">remove</mat-icon>`,
      groupContracted: `<mat-icon class="material-icons">add</mat-icon>`,
    },
    rowClass: 'xpo-AgGrid-accordion',
    masterDetail: true,
    detailRowAutoHeight: true,
    detailCellRendererParams: {
      detailGridOptions: this.detailGridOptions,
      getDetailRowData: (params) => {
        return params.successCallback(params.data.loadDetails);
      },
    },
    defaultColDef: { suppressMenu: true },
    isRowMaster: (data) => {
      return data ? data.loadDetails.length > 0 : false;
    },
    onRowGroupOpened: (event) => {
      const index = this.openGroupIds.indexOf(event.node.id);

      if (event.expanded) {
        if (index === -1) {
          this.openGroupIds.push(event.node.id);
        }
      } else {
        if (index !== -1) {
          this.openGroupIds.splice(this.openGroupIds.indexOf(event.node.id), 1);
        }
      }
    },
  };
  private gridApi: GridApi;

  readonly reassignLanesDisabled$: Observable<boolean>;
  readonly reassignLanesLoading$ = new BehaviorSubject<boolean>(false);

  private openGroupIds: string[] = [];
  private unsubscribe$ = new Subject<void>();

  constructor(
    public dataSource: SchedulesDataSourceService,
    public schedulesService: SchedulesService,
    private confirmDialog: XpoConfirmDialog,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private timeService: XpoLtlTimeService,
    private snackbar: XpoSnackBar
  ) {
    this.reassignLanesDisabled$ = combineLatest([
      this.schedulesService.schedulesMetrics$.pipe(
        map((metrics) => +metrics?.runId <= 0),
        distinctUntilChanged()
      ),
      this.reassignLanesLoading$,
    ]).pipe(map((results: boolean[]) => results.some((v) => !!v)));
  }

  ngOnInit(): void {
    this.viewTemplates = this.getViewTemplates();
    this.views = this.getViews(this.viewTemplates[0]);
    // When data is updated, we need to re-open already opened groups
    this.dataSource
      .dataUpdated$()
      .pipe(
        takeUntil(this.unsubscribe$),
        // adding a debounce time to ensure the grid is loaded before trying to expand, acts like a tick(1)
        debounceTime(0)
      )
      .subscribe(() => {
        if (this.gridApi) {
          this.openGroupIds.forEach((id) => {
            this.gridApi.forEachNode((node) => {
              if (node.id === id) {
                node.setExpanded(true);
              }
            });
          });
        }
      });

    this.noResultMessage$ = combineLatest([
      this.schedulesService.sicCd$,
      this.dataSource.isPilotSic$,
      this.schedulesService.schedulesMetrics$,
    ]).pipe(
      map(([sicCd, isPilotSic, schedulesMetrics]) => {
        if (!sicCd) {
          return 'Please Select a SIC';
        }

        if (!isPilotSic) {
          return 'Feature not enabled for this SIC';
        }

        if (!schedulesMetrics) {
          return 'Model instructions have not yet been released, please check back later.';
        }

        return 'No results found';
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleGridBoardReady(readyEvent: XpoAgGridBoardReadyEvent): void {
    this.gridApi = readyEvent.agGridApi;
  }

  handleReassignLanes(): void {
    this.confirmDialog
      .confirm('Lane assignments may change for drivers not yet dispatched.', 'Do you want to continue?', {
        icon: 'warning',
      })
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.reassignLanesLoading$.next(true);
          this.schedulesService
            .reassignLanes$()
            .pipe(
              take(1),
              finalize(() => this.reassignLanesLoading$.next(false))
            )
            .subscribe(() => {
              this.dataSource.refresh();
            });
        }
      });
  }

  private getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'schedulesBoardViewTemplate',
        name: 'schedulesBoardViewTemplate',
        availableColumns: [
          {
            cellRenderer: 'agGroupCellRenderer',
            headerName: 'Move-to Lane',
            field: 'sicCd',
            cellRendererParams: {
              innerRendererFramework: MoveToCellRendererComponent,
            },
            width: 400,
            cellStyle: { border: 'none' },
          },
          {
            headerName: 'DSRs Planned',
            field: 'dsrPlanned',
            width: 120,
            type: 'numericColumn',
            cellStyle: { border: 'none' },
          },
          {
            headerName: 'Empties Planned',
            field: 'emptiesPlanned',
            width: 120,
            type: 'numericColumn',
            cellStyle: { border: 'none' },
          },
          {
            headerName: 'Overage Planned',
            field: 'overagePlanned',
            width: 120,
            type: 'numericColumn',
            cellStyle: { border: 'none' },
          },

          {
            headerName: 'VIAs Incoming',
            colId: 'incomingVia',
            field: 'incomingViaCount',
            sortable: true,
            width: 220,
            type: 'numericColumn',
            cellRendererFramework: ViaDetailsCellRendererComponent,
            cellStyle: { border: 'none' },
          },
          {
            headerName: 'VIAs Outgoing',
            colId: 'OutgoingVia',
            field: 'outgoingViaCount',
            sortable: true,
            width: 220,
            type: 'numericColumn',
            cellRendererFramework: ViaDetailsCellRendererComponent,
            cellRendererParams: <ViaDetailsCellRendererParams>{ isOutbound: true },
          },
        ],
      }),
    ];
  }

  private getViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [viewTemplate.createView({ id: 'schedulesBoardView', name: 'schedulesBoardView' })];
  }

  private getDriverEditOptions(params: RichTableSelectEditorParams): Observable<any[]> {
    const query = new ListRecommendedDriversQuery();
    query.lnhScheduleId = params.data.lnhScheduleId;

    return this.linehaulOperationsApiService.listRecommendedDrivers(query, { loadingOverlayEnabled: false }).pipe(
      map((resp) => {
        return (resp?.recommendedDrivers ?? []).map((resp) => {
          const driver = resp.planningDriver;
          return {
            id: driver.employeeId,
            credentials: driver.licenseInds,
            phoneNumber: driver.dsrCellPhone,
            status: driver.statusCd,
            assignedLane: driver.assignedLane || 'N/A',
            name: `${driver.firstName ?? ''} ${driver.lastName ?? ''}`,
          } as SchedulesGridItemDetailDriver;
        });
      })
    );
  }

  private getTractorEditOptions(params: RichTableSelectEditorParams): Observable<any[]> {
    const query = new ListRecommendedTractorsQuery();
    query.lnhScheduleId = params.data.lnhScheduleId;

    return this.linehaulOperationsApiService.listRecommendedTractors(query, { loadingOverlayEnabled: false }).pipe(
      map((resp) => {
        return (resp?.recommendedTractors ?? []).map((resp) => {
          return {
            name: resp.equipmentNbr,
            status: resp.statusCd,
            domSic: resp.equipment.dmclSicCd || 'N/A',
            equipmentId: resp.equipment.equipmentId,
          } as SchedulesGridItemDetailTractor;
        });
      })
    );
  }

  private getTrailerEditOptions(params: RichTableSelectEditorParams): Observable<any[]> {
    const query = new ListRecommendedTrailersQuery();
    query.lnhScheduleId = params.data.lnhScheduleId;

    return this.linehaulOperationsApiService.listRecommendedTrailers(query, { loadingOverlayEnabled: false }).pipe(
      map((resp) => {
        return (resp?.recommendedTrailers ?? []).map((resp) => {
          return {
            equipmentId: resp.equipmentId,
            name: `${resp.equipmentIdPrefix}-${resp.equipmentIdSuffixNbr}`,
            // moveTo: trailer.moveTo, // TODO
            closeTo: resp.loadDestSic || 'N/A',
            location: resp.evntDoor,
            currentStatus: resp.currentStatus,
            loadedWgtLbs: resp.loadedWgtLbs,
            loadedCubePct: resp.loadedCubePct,
            indicators: resp.indicators,
            status: resp.statusCd,
          } as SchedulesGridItemDetailTrailer;
        });
      })
    );
  }

  private getDriverColumnPartial(): ColDef {
    return {
      headerName: 'Driver',
      cellRendererFramework: DriverNameCellRendererComponent,
      cellEditorFramework: RichTableSelectEditorComponent,
      cellEditorParams: <RichTableSelectEditorParams>{
        columns: [
          { field: 'name', headerName: 'Name', widthInPx: 200 },
          { field: 'status', headerName: 'Status', widthInPx: 100 },
          { field: 'assignedLane', headerName: 'Assigned To', widthInPx: 150 },
        ],
        getValues: (params) => this.getDriverEditOptions(params),
      },
      width: 300,
      ...this.getEditableColumPartial((params) => this.updateDriver(params)),
    };
  }

  private getTrailerColumnPartial(): ColDef {
    return {
      headerName: 'Trailer',
      cellRendererFramework: TrailerCellRendererComponent,
      cellEditorFramework: RichTableSelectEditorComponent,
      cellEditorParams: <RichTableSelectEditorParams>{
        columns: [
          {
            field: 'name',
            headerName: 'Name',
            widthInPx: 200,
            valueFormatter: (value) => LinehaulFormatters.EquipmentName(value),
          },
          { field: 'loadedWgtLbs', headerName: 'Weight', widthInPx: 100 },
          { field: 'closeTo', headerName: 'Close To', widthInPx: 150 },
          // { field: 'moveTo', headerName: 'Move To', widthInPx: 150 },
        ],
        getValues: (params) => this.getTrailerEditOptions(params),
      },
      width: 250,
      ...this.getEditableColumPartial(
        (params) => this.updateTrailer(params),
        (params) => {
          const isCutTimeLessThanAnHourAway =
            !!params.data.cutTime &&
            differenceInMinutes(
              this.getDateBasedOnCurrentSicDateTime(params.data.cutTime),
              this.getDateBasedOnCurrentSicDateTime()
            ) <= 60;

          return this.schedulesService.isToday() && isCutTimeLessThanAnHourAway;
        }
      ),
    };
  }

  private getEditableColumPartial(
    apiToCall: (params) => Observable<any>,
    isError?: (params: CellClassParams) => boolean
  ): ColDef {
    const isEditable = (params: { data: SchedulesGridItemDetail; colDef: ColDef }) => {
      return params.data.editableFields.includes(params.colDef.field as keyof SchedulesGridItemDetail);
    };

    return {
      valueSetter: (params) =>
        this.valueSetterUpsertOnSubmit(params, apiToCall(params).pipe(tap((res) => this.handleAssignment(res)))),
      editable: (params: EditableCallbackParams) => isEditable(params),
      cellClassRules: {
        'app-Schedules-agGridBoard--editableCell': (params: CellClassParams) => isEditable(params),
        'app-Schedules-agGridBoard--errorCell': (params) => (isError ? isError(params) : false),
      },
    };
  }

  private updateDriver(valueSetterParams: ValueSetterParams): Observable<any> {
    const isDelete = !valueSetterParams.newValue;
    const rowValue: SchedulesGridItemDetail = valueSetterParams.data;
    const newValue: SchedulesGridItemDetailDriver = valueSetterParams.newValue;
    const currentValue: SchedulesGridItemDetailDriver = valueSetterParams.oldValue;

    return this.linehaulOperationsApiService.upsertDriverAssignment(
      {
        actionCd: isDelete ? ActionCd.DELETE : currentValue?.seqNumber ? ActionCd.UPDATE : ActionCd.ADD,
        scheduledDriverSequenceNbr: currentValue?.seqNumber ?? 0,
        lnhScheduleId: rowValue.lnhScheduleId,
        dsrEmployeeId: isDelete ? currentValue.id : newValue.id,
      },
      { loadingOverlayEnabled: false }
    );
  }

  private updateTrailer(valueSetterParams: ValueSetterParams): Observable<any> {
    const isDelete = !valueSetterParams.newValue;
    const rowValue: SchedulesGridItemDetail = valueSetterParams.data;
    const newValue: SchedulesGridItemDetailTrailer = valueSetterParams.newValue;
    const currentValue: SchedulesGridItemDetailTrailer = valueSetterParams.oldValue;

    return this.linehaulOperationsApiService.upsertTrailerAssignment(
      {
        actionCd: isDelete ? ActionCd.DELETE : currentValue?.seqNumber ? ActionCd.UPDATE : ActionCd.ADD,
        scheduledEquipmentSequenceNbr: currentValue?.seqNumber ?? 0,
        lnhScheduleId: rowValue.lnhScheduleId,
        equipmentInstanceId: isDelete ? currentValue.equipmentId : newValue.equipmentId,
      },
      { loadingOverlayEnabled: false }
    );
  }

  private updateTractor(valueSetterParams: ValueSetterParams): Observable<any> {
    const isDelete = !valueSetterParams.newValue;
    const rowValue: SchedulesGridItemDetail = valueSetterParams.data;
    const newValue: SchedulesGridItemDetailTractor = valueSetterParams.newValue;
    const currentValue: SchedulesGridItemDetailTractor = valueSetterParams.oldValue;

    return this.linehaulOperationsApiService.upsertTractorAssignment(
      // We dont use seq number for tractors since there will only be 1,
      {
        actionCd: isDelete ? ActionCd.DELETE : currentValue?.equipmentId ? ActionCd.UPDATE : ActionCd.ADD,
        lnhScheduleId: rowValue.lnhScheduleId,
        equipmentInstanceId: isDelete ? currentValue.equipmentId : newValue.equipmentId,
      },
      { loadingOverlayEnabled: false }
    );
  }

  private updateEstimateDepartTime(valueSetterParams: ValueSetterParams): Observable<any> {
    return this.timeService.timezoneForSicCd$(this.schedulesService.sicCd).pipe(
      switchMap((timeZone) => {
        const req = new UpdateScheduleRqst();
        req.lnhSchedule = new LnhSchedule();

        req.lnhSchedule.estimatedDepartDateTime = valueSetterParams.newValue
          ? zonedTimeToUtc(parse(valueSetterParams.newValue, 'HH:mm', new Date()), timeZone)
          : new Date('0001-01-01T01:01:01.100Z');

        return this.linehaulOperationsApiService.updateSchedule(
          req,
          { lnhScheduleId: valueSetterParams.data.lnhScheduleId },
          { loadingOverlayEnabled: false }
        );
      })
    );
  }

  private updateVia(valueSetterParams: ValueSetterParams): Observable<any> {
    const req = new UpdateScheduleRqst();
    req.lnhSchedule = new LnhSchedule();
    req.lnhSchedule.viaSicCode = valueSetterParams.newValue ?? '';

    return this.linehaulOperationsApiService.updateSchedule(
      req,
      { lnhScheduleId: valueSetterParams.data.lnhScheduleId },
      { loadingOverlayEnabled: false }
    );
  }

  private handleAssignment(res: any): void {
    if (res) {
      this.dataSource.refresh();
    }
  }

  private valueSetterUpsertOnSubmit(params: ValueSetterParams, apiToCall: Observable<any>): boolean {
    const field = params.colDef.field;
    const data = { ...params.data, [field]: params.newValue };

    params.api.applyTransaction({ update: [data] });

    apiToCall
      .pipe(
        take(1),
        catchError((err) => {
          const oldData = { ...params.data, [field]: params.oldValue };
          this.snackbar.error(err?.error?.message ?? 'An Error Occurred');

          params.api.applyTransaction({ update: [oldData] });
          return throwError(err);
        })
      )
      .subscribe((resp) => {
        // TODO: ideally the resp should send us the newly created entity,
      });

    return true;
  }

  private getActualOrEstimate(value: SchedulesGridItemDetailEstAndActual): Date | null {
    return value.actual ?? value.estimate;
  }

  private getDateBasedOnCurrentSicDateTime(date: Date | number | string = new Date()): Date {
    return new Date(this.timeService.formatDate(new Date(date), undefined, this.schedulesService.sicCd));
  }
}
