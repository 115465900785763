import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LauncherLaunchData } from './launcher-data';

@Injectable()
export abstract class LauncherServiceBase {
  readonly launchComponent$: Observable<LauncherLaunchData>;

  private launchedComponentSource$ = new BehaviorSubject<LauncherLaunchData>(null);

  constructor() {
    this.launchComponent$ = this.launchedComponentSource$.asObservable();
  }

  launch<D = any>(data: LauncherLaunchData<D>): void {
    this.launchedComponentSource$.next(data);
  }
}
