import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { XpoBoardModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoChipsModule, XpoTabsModule } from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { AllowableLoadsModule } from '../../load-management/allowable-loads/allowable-loads.module';
import { SidePanelContainerModule } from '../../shared/components/side-panel-container/side-panel-container.module';
import { AddLoadFormatter } from '../formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../formatters/ag-grid-cell-formatters/closeto-formatter';
import { DispatchHeaderFormatterComponent } from '../formatters/ag-grid-cell-formatters/dispatch-header-formatter';
import { ExceptionFormatterComponent } from '../formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { ModifiedByFormatterComponent } from '../formatters/ag-grid-cell-formatters/modified-by-formatter';
import { MoveToFormatterComponent } from '../formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusChangeFormatterComponent } from '../formatters/ag-grid-cell-formatters/status-chage-formatter';
import { StatusFormatterComponent } from '../formatters/ag-grid-cell-formatters/status-formatter';
import { PipesModule } from '../formatters/pipes.module';
import { SharedModule } from '../shared.module';
import { DateFormatterComponent } from './../formatters/date-formatter';
import { ImageSlideshowComponent } from './image-slideshow/image-slideshow.component';
import { SidePanelContentComponent } from './side-panel-wrapper/side-panel-content/side-panel-content.component';
import { SidePanelWrapperComponent } from './side-panel-wrapper/side-panel-wrapper.component';
import { AckTabComponent } from './side-panel-wrapper/tabs/acknowledge-tab/acknowledge-tab.component';
import { ApproveDeclineComponent } from './side-panel-wrapper/tabs/approve-decline/approve-decline.component';
import { BufiLoadPanelComponent } from './side-panel-wrapper/tabs/bufi-load-panel/bufi-load-panel.component';
import { CancelRequestComponent } from './side-panel-wrapper/tabs/cancel-request/cancel-request.component';
import { ClsTabComponent } from './side-panel-wrapper/tabs/close-tab/close-tab.component';
import { HistoryComponent } from './side-panel-wrapper/tabs/history/history.component';
import { HssActionsComponent } from './side-panel-wrapper/tabs/hss-actions/hss-actions.component';
import { HssPanelComponent } from './side-panel-wrapper/tabs/hss-panel/hss-panel.component';
import { LaneBreakdownComponent } from './side-panel-wrapper/tabs/lane-breakdown/lane-breakdown.component';
import { TrailersByLaneComponent } from './side-panel-wrapper/tabs/lane-breakdown/trailers-by-lane/trailers-by-lane.component';
import { LaneDetailComponent } from './side-panel-wrapper/tabs/lane-detail/lane-detail.component';
import { LrPanelComponent } from './side-panel-wrapper/tabs/lr-panel/lr-panel.component';
import { ProgLoadPanelComponent } from './side-panel-wrapper/tabs/prog-load-panel/prog-load-panel.component';
import { RequestApprovalComponent } from './side-panel-wrapper/tabs/request-approval/request-approval.component';
import { DataDisplayGroupComponent } from './side-panel-wrapper/tabs/shipment-details/data-display-group/data-display-group.component';
import { ShipmentDimensionsComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-dimensions/shipment-details-dimensions.component';
import { ShipmentDetailsEventsComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-events/shipment-details-events.component';
import { ShipmentDetailsGeneralInfoComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-general-info/shipment-details-general-info.component';
import { ShipmentDetailsLineItemsComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-line-items/shipment-details-line-items.component';
import { ShipmentDetailsOverviewComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-overview/shipment-details-overview.component';
import { ShipmentDetailsRemarksComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details-remarks/shipment-details-remarks.component';
import { ShipmentDetailsComponent } from './side-panel-wrapper/tabs/shipment-details/shipment-details.component';
import { SpecialServicesComponent } from './special-services/special-services.component';

const agGridFormatters = [
  IconsFormatterComponent,
  SpecialServiceFormatterComponent,
  StatusFormatterComponent,
  ModifiedByFormatterComponent,
  BypassFormatterComponent,
  StatusChangeFormatterComponent,
  DateFormatterComponent,
  LoadedWeightFormatterComponent,
  ExceptionFormatterComponent,
  CloseToFormatterComponent,
  MoveToFormatterComponent,
  AddLoadFormatter,
  DispatchHeaderFormatterComponent,
];

@NgModule({
  declarations: [
    SidePanelWrapperComponent,
    SidePanelContentComponent,
    AckTabComponent,
    ClsTabComponent,
    ApproveDeclineComponent,
    LaneBreakdownComponent,
    ShipmentDetailsComponent,
    ShipmentDetailsLineItemsComponent,
    ShipmentDetailsRemarksComponent,
    ShipmentDetailsGeneralInfoComponent,
    ShipmentDetailsOverviewComponent,
    ShipmentDetailsEventsComponent,
    ShipmentDimensionsComponent,
    DataDisplayGroupComponent,
    ProgLoadPanelComponent,
    TrailersByLaneComponent,
    LrPanelComponent,
    HssPanelComponent,
    HssActionsComponent,
    ImageSlideshowComponent,
    SpecialServicesComponent,
    HistoryComponent,
    LaneDetailComponent,
    RequestApprovalComponent,
    CancelRequestComponent,
    BufiLoadPanelComponent,
  ],
  imports: [
    PipesModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatTableModule,
    CommonModule,
    MatIconModule,
    XpoTabsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    SidePanelContainerModule,
    MatSelectModule,
    XpoAgGridModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoFiltersModule,
    NgxGalleryModule,
    MatExpansionModule,
    XpoChipsModule,
    AgGridModule.withComponents([...agGridFormatters]),
    SharedModule,
    AllowableLoadsModule,
    RouterModule,
    MatAutocompleteModule,
  ],
  exports: [SidePanelWrapperComponent, SidePanelContentComponent],
})
export class SidePanelModule {}
