import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommodityClassCdPipe, CurrencyCodePipe } from '@xpo-ltl/ngx-ltl';
import { MiscLineItemCd } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import { ShipmentOdsApiService } from '@xpo-ltl/sdk-shipmentods';
import { defaultTo as _defaultTo, get as _get, map as _map, reduce as _reduce } from 'lodash';
import { ProNbrFormatPipe } from '../../../../../formatters/proNbr-pipe';
interface LineItemData {
  pieces: number;
  description: string;
  classType: string;
  asRatedClassCd: string;
  nmfc: string;
  weight: number;
  rate: number;
  charges: number;
}

@Component({
  selector: 'shipment-details-line-items',
  templateUrl: './shipment-details-line-items.component.html',
  styleUrls: ['./shipment-details-line-items.component.scss'],
})
export class ShipmentDetailsLineItemsComponent implements OnInit, OnDestroy {
  @Input() alert: any;
  @Input() shipment: any;
  @Input() hist: any;
  columns = [
    { id: 'pieces', label: 'Pieces' },
    { id: 'description', label: 'Description' },
    { id: 'classType', label: 'Class', renderer: (row) => this.commodityClassCdPipe.transform(row.classType) },
    {
      id: 'asRatedClassCd',
      label: 'Rate Class',
      renderer: (row) => this.commodityClassCdPipe.transform(row.asRatedClassCd),
    },
    { id: 'nmfc', label: 'NMFC' },
    { id: 'weight', label: 'Weight' },
  ];

  dimensionsColumns = [
    { id: 'piecesCount', label: 'Quantity' },
    { id: 'lengthNbr', label: 'Length' },
    { id: 'widthNbr', label: 'Width' },
    { id: 'heightNbr', label: 'Height' },
  ];
  columnsToDisplay = _map(this.dimensionsColumns, (c) => c.id);
  displayedColumns: string[] = ['Description', 'Pieces', 'Class', 'Rate Class', 'NMFC', 'Weight'];
  noDimensions: boolean;
  dimensions: any;
  dataSource: MatTableDataSource<LineItemData> = new MatTableDataSource([]);
  showHistory: boolean = false;
  dataSourceHistory = [];
  showShipment: boolean = false;
  proNbrPipe: ProNbrFormatPipe;
  lineItemsCount = 0;

  constructor(
    private currencyCdPipe: CurrencyCodePipe,
    public commodityClassCdPipe: CommodityClassCdPipe,
    private shipmentOdsApiService: ShipmentOdsApiService
  ) {}

  ngOnInit() {
    this.proNbrPipe = new ProNbrFormatPipe();
    this.updateFormFromShipment(this.shipment);
    const proNbr = this.getProNbr(this.alert.alert);
  }

  ngOnDestroy() {}

  private updateFormFromShipment(shipment) {
    if (!shipment) {
      return;
    }

    let data;
    data = _map(shipment.commodity, (commodity) => {
      return {
        pieces: commodity.piecesCount,
        description: commodity.description,
        classType: commodity.classType,
        asRatedClassCd: commodity.asRatedClassCd,
        nmfc: commodity.nmfcItemCd,
        weight: commodity.weightLbs,
        rate: commodity.tariffsRate,
        charges: commodity.amount,
        currency: null,
      };
    });

    const accessorials = _map(shipment.accessorialService, (accessorial) => {
      return {
        description: accessorial.description,
        rate: accessorial.tariffsRate,
        charges: accessorial.amount,
        currency: null,
      };
    });

    const miscLineItems = _map(shipment.miscLineItem, (miscLineItem) => {
      return {
        description: miscLineItem.description,
        rate: miscLineItem.tariffsRate,
        charges: miscLineItem.amount,
        currency: null,
        lineTypeCd: miscLineItem.lineTypeCd,
      };
    });

    data.push(...accessorials, ...miscLineItems);

    // calculate total and add to end of list
    const totals = _reduce(
      data,
      (total, value) => {
        const lineTypeCd = _get(value, 'lineTypeCd', undefined);
        const hasDiscount = !!lineTypeCd && lineTypeCd === MiscLineItemCd.DISC_LN;

        return {
          pieces: total.pieces + _defaultTo(value.pieces, 0),
          charges: total.charges + _defaultTo(hasDiscount ? -value.charges : value.charges, 0),
        };
      },
      { pieces: 0, charges: 0 }
    );

    data.push({
      pieces: totals.pieces,
      description: 'TOTAL',
      classType: undefined,
      asRatedClassCd: undefined,
      nmfc: undefined,
      weight: undefined,
      rate: undefined,
      charges: totals.charges,
      currency: this.currencyCdPipe.transform(shipment.shipment.invoicingCurrencyCd),
    });

    // update the datasource with the data
    this.dataSource = data;
    this.lineItemsCount = data.length;

    // Fill history dataSource
    this.shipmentOdsApiService.listOdsShipmentHistTraceEvents(this.hist).subscribe((result: any) => {
      this.dataSourceHistory = result.shipmentTraceHistoryEvents;
      this.showHistory = true;
    });

    if (this.shipment.linehaulDimensions.length) {
      this.noDimensions = false;
      this.dimensions = this.shipment.linehaulDimensions;
    } else {
      this.noDimensions = true;
    }
  }

  private getProNbr(alert: Exception) {
    const proNbrWithSpace = this.proNbrPipe.transform(alert.operationException.proNbr);
    return proNbrWithSpace;
  }
}
