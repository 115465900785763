<div class="filter-container"
    [formGroup]="filtersForm">
  <div class="filter-wrap">
    <label for="planDate">PERIOD</label>
    <input class="no-border"
        data-test="planDateFilter"
        [max]="maxDate"
        matInput
        [matDatepicker]="picker"
        formControlName="planDate"
        placeholder=""
        (dateInput)="changeDate($event.value)" />
    <mat-icon (click)="picker.open()">calendar_today</mat-icon>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  <div class="filter-wrap"
      (clickOutside)="closeSelectOptions()">
    <label for="shift"
        data-test="regionLabel">Shift</label>
    <select id="shift"
        data-test="shiftFilter"
        formControlName="shift">
      <option *ngFor="let shift of shiftOptions"
          [value]="shift.code">
        {{ shift.title }}
      </option>
    </select>
    <ng-container>
      <mat-icon (click)="openSelectOptions()"
          class="arrow_drop_down">arrow_drop_down</mat-icon>
      <div class="custom-select-menu"
          [ngClass]="{ showOptions: showOptions }">
        <p (click)="changeShift(shift.code)"
            *ngFor="let shift of shiftOptions">{{ shift.title }}</p>
      </div>
    </ng-container>
  </div>
  <sic-filter *ngIf="regionOptions$ | async as regionOptions"
      class="filter-wrap"
      [regionListWithSics]="regionOptions"
      (valueChange)="handleSicChange($event)"></sic-filter>
</div>
