<div class="content">
  <h1>Cancel Request</h1>
  <form [formGroup]="form">
    <div class="action">
      <div>
        <div class="reason-row">
          <mat-form-field class="short">
            <mat-select placeholder="Reason code"
                formControlName="cancelReasonCode"
                disableOptionCentering>
              <mat-option *ngFor="let reason of loadRequestReasonCodes"
                  [value]="reason.reasonCd">
                {{ reason.reasonDescription }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="true">
              Reason code field is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="button-action-row-1"
          *ngIf="!disabled">
        <button mat-flat-button
            xpoSmallButton
            (click)="cancelRequest()">
          CANCEL
        </button>
      </div>
    </div>
  </form>
</div>
