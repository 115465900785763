import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { HierarchyRegion } from '../../performance-dashboard/models/hierarchy-region';
import { XpoAdvancedSelectOption } from '../../shared/components/advanced-select';
import { AuthService } from '../../shared/services/auth.service';
import { LinehaulStateService } from '../services/linehaul-state.service';
import { LocationService } from '../services/location.service';
import { GlobalHeaderFilter } from './global-header.model';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-GlobalHeader' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalHeaderComponent implements OnInit {
  pageTitle: string;
  filters: GlobalHeaderFilter[];
  locations$: Observable<XpoAdvancedSelectOption[]>;
  hierarchy: HierarchyRegion;
  defaultLocation$: Observable<string[]>;

  private destroyed$ = new Subject<void>();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private linehaulStateService: LinehaulStateService,
    private locationService: LocationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe((data: ActivationEnd) => {
        this.filters = data.snapshot.data.globalHeaderData?.filters;
        this.pageTitle = data.snapshot.data.globalHeaderData?.pageName;
        this.cd.markForCheck();
      });

    this.locations$ = combineLatest([
      this.locationService.allOperationalLocations$,
      this.locationService.allLinehaulLocations$,
      this.locationService.allHSSLocations$,
      this.linehaulStateService.listen('dashboardSelectedHierarchy'),
    ]).pipe(
      map(([allOperationalLocations, allLinehaulLocations, allHSSLocations, hierarchy]) => {
        this.hierarchy = hierarchy;
        switch (hierarchy) {
          case HierarchyRegion.OPERATIONAL_REGION:
            return allOperationalLocations;
          case HierarchyRegion.LINEHAUL_REGION:
            return allLinehaulLocations;
          case HierarchyRegion.HSS_REGION:
            return allHSSLocations;
        }
      })
    );
    this.defaultLocation$ = this.locations$.pipe(
      take(1),
      map((locations) => this.getDefaultLocation(locations)),
      tap((locations) => {
        if (locations?.length) {
          this.selectedLocations(locations);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  selectedLocations(selection: string[]): void {
    this.linehaulStateService.setState({ selectedLocations: selection.length ? selection : null });
  }

  private getDefaultLocation(locations: XpoAdvancedSelectOption[]): string[] {
    // If sic in locations, default input
    let defaultLocations: string[] = [];
    let sicFound: boolean = false;
    const domSic = this.authService.sic;

    if (this.hierarchy === HierarchyRegion.OPERATIONAL_REGION) {
      sicFound = locations.some((loc) => {
        return loc.children.some((l) => {
          return l.value === domSic || l.children.some((child) => child.value === domSic);
        });
      });
    } else if (this.hierarchy === HierarchyRegion.LINEHAUL_REGION) {
      sicFound = locations.some((loc) => {
        return loc.value === domSic || loc.children.some((child) => child.value === domSic);
      });
    }

    if (sicFound) {
      defaultLocations = [domSic];
    }

    return defaultLocations;
  }
}
