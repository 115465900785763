import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { DriversService } from '../../services/drivers.service';

@Component({
  selector: 'app-driver-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  maxDate = new Date();
  period$: Observable<Date>;

  @Output() dateSelected = new EventEmitter<any>();

  constructor(private driversService: DriversService) {}

  ngOnInit() {
    this.period$ = this.driversService.period$.pipe(distinctUntilChanged());
  }

  valueChanged(event) {
    this.driversService.period = event.value;
  }
}
