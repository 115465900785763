import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ExceptionTypeCd, XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  LinehaulOperationsApiService,
  LoadedTrailerImageDetail,
  LoadedTrailerSearchFilter,
  LoadedTrailerSearchRecord,
  SearchLoadedTrailerImagesRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import moment from 'moment';
import { ActionService } from '../../../../../services/action.service';
import { SidePanelModelData } from '../../../../side-panel-model-data';

@Component({
  selector: 'trailers-by-lane',
  templateUrl: './trailers-by-lane.component.html',
  styleUrls: ['./trailers-by-lane.component.scss'],
})
export class TrailersByLaneComponent implements OnInit, OnChanges {
  ExceptionTypeCd = ExceptionTypeCd;
  @Input() type: ExceptionTypeCd;
  @Input() modelData: SidePanelModelData;
  filteredLaneData: any;
  cols: string[] = ['trailer', 'weight', 'status', 'special'];
  expandedItem: boolean[] = [];
  selectedTrailer = null;
  toggleBypassButton: boolean = false;
  toggleUnderUtilizedButton: boolean = false;
  closeTimestamp: any;
  notImagesFound: boolean;
  loadedTrailerImages: LoadedTrailerImageDetail[] = [];
  laneDataMapper: any[];
  constructor(
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private actionService: ActionService,
    private timeService: XpoLtlTimeService
  ) {}

  ngOnInit(): void {
    this.laneDataMapper = this.modelData.laneData.map((laneInfo) => {
      laneInfo.trailerLoadInfo =
        this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
          ? laneInfo.trailerLoadInfo?.filter(
              (trailer) =>
                trailer.trailerLoadHist.currentStatus === 'Closed' &&
                trailer.trailerLoadHist.trailerLoadedRemarks &&
                trailer.trailerLoadHist.trailerLoadedRemarks !== 'Bypass' &&
                trailer.trailerLoadHist.trailerLoadedRemarks !== 'Dispatched'
            )
          : laneInfo.trailerLoadInfo?.filter(
              (trailer) =>
                trailer.trailerLoadHist.trailerLoadedRemarks &&
                trailer.trailerLoadHist.trailerLoadedRemarks !== 'Dispatched'
            );
      return {
        loadLaneInstructionSummary: laneInfo.loadLaneInstructionSummary,
        trailerLoadInfo: laneInfo.trailerLoadInfo,
        showWarning: laneInfo.trailerLoadInfo?.some(
          (trailer) => this.checkLoadedWeight(trailer) || this.checkLoadedCube(trailer)
        ),
      };
    });
    this.filteredLaneData = this.laneDataMapper;
  }

  ngOnChanges(): void {
    this.resetExpandedItem();
  }

  private resetExpandedItem(): void {
    this.modelData.laneData.forEach((value, index) => {
      this.expandedItem[index] = false;
    });
  }

  toogleItem(index: number): void {
    if (!this.expandedItem[index]) {
      this.expandedItem[index] = true;
    } else {
      this.expandedItem[index] = false;
    }
  }

  showTrailer(closeTosic, trailer): void {
    this.selectedTrailer = trailer.trailerLoadHist;
    const closeDateTime = this.selectedTrailer.closeDateTime;
    this.notImagesFound = false;
    this.searchImages();
    this.closeTimestamp = this.formatDate(closeDateTime, closeTosic);
  }

  getPrefix(equipmentIdPrefix: string): string {
    return /^[a-zA-Z]*$/.test(equipmentIdPrefix) ? equipmentIdPrefix : equipmentIdPrefix.substr(1);
  }

  formatDate(date: Date, sic: string): string {
    return this.timeService.formatDate(date, 'MM/DD/YYYY HH:mm', sic);
  }

  showOnlyBypass(): void {
    this.toggleBypassButton = !this.toggleBypassButton;
    this.filterLaneData();
  }

  showOnlyUndeutilizedTrailers(): void {
    this.toggleUnderUtilizedButton = !this.toggleUnderUtilizedButton;
    this.filterLaneData();
  }

  checkLoadedWeight(trailer): boolean {
    return (
      trailer.trailerLoadHist.loadedWeight < this.modelData.topTable.loadAverage?.planned &&
      this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
    );
  }

  checkLoadedCube(trailer): boolean {
    return (
      trailer.trailerLoadHist.loadedCbePercentage < this.modelData.topTable.tuc?.planned &&
      this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
    );
  }

  filterLaneData(): void {
    if (this.toggleBypassButton || this.toggleUnderUtilizedButton) {
      const filteredLaneData = [];
      if (this.type === ExceptionTypeCd.LOAD_REQUEST) {
        this.laneDataMapper.forEach((laneInfo) => {
          filteredLaneData.push({
            loadLaneInstructionSummary: laneInfo.loadLaneInstructionSummary,
            trailerLoadInfo: laneInfo.trailerLoadInfo.filter((trailer) => !!trailer.bypassTrlrInd),
          });
        });
      } else if (this.type === ExceptionTypeCd.PROGRESSIVE_LOAD) {
        this.laneDataMapper.forEach((laneInfo) => {
          const trailerLoadInfo = laneInfo.trailerLoadInfo?.filter(
            (trailer) => this.checkLoadedWeight(trailer) || this.checkLoadedCube(trailer)
          );
          filteredLaneData.push({
            loadLaneInstructionSummary: laneInfo.loadLaneInstructionSummary,
            trailerLoadInfo: trailerLoadInfo,
            showWarning: !!trailerLoadInfo?.length,
          });
        });
      }
      this.filteredLaneData = filteredLaneData;
    } else {
      this.filteredLaneData = this.laneDataMapper;
    }
  }

  private searchImages(): void {
    const loadedTimestamp = this.selectedTrailer.loadedDateTime;
    const closeUTCTmst = this.selectedTrailer.closeDateTime;
    const status = this.selectedTrailer.currentStatus;
    const loadingDate = this.getMinAndMax(status, closeUTCTmst, loadedTimestamp);
    const filter = new LoadedTrailerSearchFilter();
    filter.q = `${this.selectedTrailer.equipmentIdPrefix.substr(1)}-${this.selectedTrailer.equipmentIdSuffixNbr}`;
    filter.loadingDate = loadingDate;
    const request = new SearchLoadedTrailerImagesRqst();
    request.filter = filter;
    this.getImages(request);
  }

  private getImages(request: SearchLoadedTrailerImagesRqst): void {
    this.linehaulOperationsApiService.searchLoadedTrailerImages(request).subscribe((searchResponse) => {
      if (searchResponse.result.length) {
        const loadedTrailerSearchRecord = searchResponse.result[0];
        this.completeSelectedTrailerInfo(loadedTrailerSearchRecord);
        this.loadedTrailerImages = loadedTrailerSearchRecord.images;
      } else {
        this.notImagesFound = true;
      }
    });
  }

  private completeSelectedTrailerInfo(searchRecord: LoadedTrailerSearchRecord): void {
    this.selectedTrailer.door = searchRecord.door;
    this.actionService
      .getNameById(searchRecord.closedByEmployeeId)
      .subscribe((resp) => (this.selectedTrailer.closedBy = resp));
    this.selectedTrailer.shipments = this.totalShipments(searchRecord.images);
  }

  private totalShipments(images): number {
    const totalClosedShipments = images.filter((image) => image.imageType === 'ClosePhoto');
    if (totalClosedShipments && totalClosedShipments.length === 0) {
      const lastImg = images[images.length - 1];
      return lastImg && lastImg.pros ? lastImg.pros.length : 0;
    } else {
      return totalClosedShipments[totalClosedShipments.length - 1].pros.length;
    }
  }

  private getMinAndMax(status, closeUTCTmst, loadedTimestamp): XrtAttributeFilter {
    const date = new XrtAttributeFilter();
    if (status === 'Closed' || status === 'Overhead' || status === 'Enroute') {
      date.min = moment(closeUTCTmst)
        .utc()
        .add(-1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
      date.max = moment(closeUTCTmst)
        .utc()
        .add(1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
    } else {
      if (status === 'Exception') {
        date.min = moment(closeUTCTmst)
          .utc()
          .add(-2, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(closeUTCTmst)
          .utc()
          .add(4, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      } else {
        date.min = moment(loadedTimestamp)
          .utc()
          .add(-1, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(loadedTimestamp)
          .utc()
          .add(6, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      }
    }
    return date;
  }
}
