<div class="trailerDataResults">
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Loading }}</span>
    <span class="field-value">{{ containerData.loadingSic || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.LoadDestinationSic }}</span>
    <span class="field-value">{{ exceptionAlertData.destination || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Shift }}</span>
    <span class="field-value">{{ containerData.shift || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Door }}</span>
    <span class="field-value">{{ containerData.door || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Shipments }}</span>
    <span class="field-value">{{ containerData.totalShipmentsCount || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Utilization }}</span>
    <span class="field-value">{{ containerData.cubeUtilizationPct | number: '.0-2' || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.Weight }}</span>
    <span class="field-value">{{ containerData.weight | number: '.0-3' || '-' }}</span>
  </span>
  <span class="field">
    <span class="field-title">{{ ImageSearchRecordLabels.ClosedBy }}</span>
    <span class="field-value"> {{ containerData.closedByEmployeeName | async }}</span>
  </span>
</div>
