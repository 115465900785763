import { Component, Input } from '@angular/core';
import { ListOdsShipmentHistTraceEventsRqst } from '@xpo-ltl/sdk-shipmentods';
import { get as _get, map as _map } from 'lodash';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ActionService } from '../../../../../services/action.service';

@Component({
  selector: 'shipment-details-events',
  templateUrl: './shipment-details-events.component.html',
  styleUrls: ['./shipment-details-events.component.scss'],
})
export class ShipmentDetailsEventsComponent {
  @Input() req: ListOdsShipmentHistTraceEventsRqst;
  @Input() dataSource: any;
  cols: string[] = [
    'occurredTmst',
    'elapsedTm',
    'occurredSic',
    'description',
    'originDest',
    'loadReleaseNbr',
    'loadDestinationSic',
    'trailerNbr',
    'weightCubeBls',
    'updtBy',
  ];

  constructor(private actionService: ActionService) {}

  ocurredTime(time): string {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }

  elapsedTime(time): string {
    return moment(time).format('HH:mm');
  }

  userName(employeeId: string): Observable<string> {
    if (employeeId.includes('/')) {
      return of(employeeId.substring(4));
    } else {
      this.actionService.getNameById(employeeId).pipe(
        mapTo((resp: string) => {
          return resp === '' ? employeeId : resp;
        })
      );
    }
  }

  setWCL(w, c, t) {
    if (w || c || t) {
      return `${w} / ${c} / ${t}`;
    } else {
      return '';
    }
  }

  setOriDes(o, d) {
    if (o || d) {
      return `${o} / ${d}`;
    } else {
      return '';
    }
  }
}
