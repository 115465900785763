import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { LoadRequestReasonCode } from '@xpo-ltl/sdk-linehauloperations';
import { InteractionService } from '../../../../services/interaction.service';

@Component({
  selector: 'lane-detail',
  templateUrl: './lane-detail.component.html',
  styleUrls: ['./lane-detail.component.scss'],
})
export class LaneDetailComponent implements OnInit {
  @Input() alert: any;
  @Output() editedReason = new EventEmitter<string>();
  @Input() canEdit: boolean = false;
  loadRequestDetails: any;
  destinationSicCode: any;
  requestReason: any;
  requestReasonCd: any;
  loadRequestReasonCodes: LoadRequestReasonCode[];
  loadRequestedQuantity: any;
  comments: any;
  loadsOffDock: any;

  constructor(private interactionService: InteractionService) {}

  ngOnInit() {
    this.loadInfo();
  }

  loadInfo() {
    this.destinationSicCode = this.alert.alert.operationException.destinationSicCode;
    this.requestReason = this.alert.alert.operationException.requestReason;
    this.requestReasonCd = this.alert.alert.operationException.requestReasonCd;
    this.loadRequestedQuantity = this.alert.alert.operationException.loadRequestedQuantity;
    this.loadsOffDock = this.alert.alert.operationException.loadToSicCode;
    this.comments = this.alert.sideData.comments;
    this.interactionService.getLoadRequestReasonCodes().subscribe((loadRequestReasonCodes: LoadRequestReasonCode[]) => {
      if (loadRequestReasonCodes && loadRequestReasonCodes.length > 0) {
        this.loadRequestReasonCodes = loadRequestReasonCodes;
      }
    });
  }

  selectedReason(event: MatSelectChange) {
    this.editedReason.emit(event.value);
  }
}
