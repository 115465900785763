import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ManifestComponent } from '../../../load-management/allowable-loads/dialogs/manifest/manifest.component';
import { ComponentsEnum } from '../../enums/components.enum';
import { GlobalFilters } from '../../models/global-filters';
import { InteractionServiceResp } from '../../models/interaction-service-response.model';
import { InteractionService } from '../../services/interaction.service';

@Component({
  selector: 'app-icons-formatter-cell',
  styleUrls: ['./icons-formatter.scss'],
  template: `
    <mat-icon *ngIf="!flag" svgIcon="{{ params.value }}" class="{{ params.value }}" (click)="clickManifestIcon(params)">
    </mat-icon>
    <div *ngIf="flag">
      <p>{{ message }}</p>
    </div>
  `,
})
export class IconsFormatterComponent implements OnDestroy {
  params: any;
  message: any;
  flag: any;
  planDate: any;
  currDate: any;
  interactionSubscription: Subscription;

  constructor(private interactionService: InteractionService, private dialog: MatDialog) {
    this.interactionSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .subscribe(({ data: { planDate } }) => {
        this.planDate = moment(planDate).format('YYYY-MM-DD');
        this.currDate = moment(new Date()).format('YYYY-MM-DD');
      });
  }

  agInit(params: any): void {
    this.params = params;
    this.flag = this.params.value === 'description' && this.currDate !== this.planDate;
  }

  clickManifestIcon(params) {
    if (!!params.data.equipmentInstId && params.value === 'description') {
      this.dialog.open(ManifestComponent, { minWidth: '1000px', data: params.data });
    }
  }

  ngOnDestroy() {
    this.interactionSubscription.unsubscribe();
  }
}
