import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AllowableLoadsTabsEnum } from '../../../shared/enums/allowable-loads-tabs.enum';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { InteractionServiceResp } from '../../../shared/models/interaction-service-response.model';
import { InteractionService } from '../../../shared/services/interaction.service';
import { AllowableLoadsService } from '../services/allowable-loads.service';

@Injectable()
export class LoadsViewDataSourceService extends XpoBoardDataSource implements OnDestroy {
  private unsubscriber = new Unsubscriber();
  planDate: string;
  sic: string;
  shift: string;
  region: string;
  sector: string;
  state: XpoBoardState;
  constructor(private allowableLoadsService: AllowableLoadsService, private interactionService: InteractionService) {
    super();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  initSubscriptions(): void {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((resp: InteractionServiceResp) => {
        this.region = resp.data.region;
        if (this.sic !== resp.data.sic || this.planDate !== resp.data.planDate || this.shift !== resp.data.shift) {
          this.sic = resp.data.sic;
          this.planDate = resp.data.planDate;
          this.shift = resp.data.shift;
          if (resp.data.sic && resp.data.shift && resp.data.planDate) {
            this.refresh();
          }
        }
      });
  }

  /** This is the function that the board calls when it wants data, the state includes
      the current state of the board which includes the criteria, current view, selected rows etc.*/
  fetchData(state: XpoBoardState): Observable<any> {
    const isRefreshing = state.source === 'DATA SOURCE REFRESH' ? true : false;
    return this.allowableLoadsService.getListModelInstructions(state, AllowableLoadsTabsEnum.LOADS, null, isRefreshing);
  }
}
