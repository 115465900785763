import { DsrLicenseCd } from '@xpo-ltl/sdk-common';
import { DsrLicense } from '@xpo-ltl/sdk-linehauloperations';
import { SpecialServiceGroupCredential } from '../components/special-service-group/special-service-group.component';
import { SpecialServiceIconName } from '../components/special-service-icon/special-service-icon.component';

export class DriverHelper {
  // Generates all credentials and their status
  static getAllCredentials(licenses: DsrLicense[]): SpecialServiceGroupCredential[] {
    const temp = [];

    if (licenses) {
      for (let i = 0; i < licenses.length; i++) {
        const licence = licenses[i];
        if (licence.licenseInd.toUpperCase() === 'HAZM') {
          temp.push({ credName: 'hazm', status: licence.licenseCd });
        }
        if (licence.licenseInd.toUpperCase() === 'FASTID') {
          temp.push({ credName: 'fast_id', status: licence.licenseCd });
        }
        if (licence.licenseInd.toUpperCase() === 'CDL') {
          temp.push({ credName: 'cdl', status: licence.licenseCd });
        }
        if (licence.licenseInd.toUpperCase() === 'TANK') {
          temp.push({ credName: 'tank', status: licence.licenseCd });
        }
        // TODO: TWIC is only needed in P&D. uncomment and implement when driver detail is shared with that project
        // if (licence.licenseInd.toUpperCase()  === 'TWIC') {
        //   temp.push({ credName: 'twic', status: licence.licenseCd });
        // }
      }
    }

    return temp;
  }

  // Generates only the important credentials depending on their status (used on grids)
  static getShownCredentials(licenses: DsrLicense[]): SpecialServiceGroupCredential[] {
    const allCredentials = this.getAllCredentials(licenses);
    const hiddenExpiredCredentials: SpecialServiceIconName[] = ['fast_id'];
    const hiddenActiveCredentials: SpecialServiceIconName[] = ['cdl', 'hazm', 'tank'];

    return allCredentials.filter((c) => {
      return (
        (c.status === DsrLicenseCd.EXPIRED && !hiddenExpiredCredentials.includes(c.credName)) ||
        (c.status === DsrLicenseCd.ACTIVE && !hiddenActiveCredentials.includes(c.credName)) ||
        c.status === DsrLicenseCd.ACTIVE_BUT_EXPIRES_WITHIN_30_DAYS
      );
    });
  }

  static phoneNumberFormatter(number: string): string {
    return number?.replace('/', '-');
  }
}
