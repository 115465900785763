<div class="filter-wrap">
  <label for="Period">PERIOD</label>
  <input [max]="maxDate"
      matInput
      [matDatepicker]="picker"
      placeholder=""
      (dateChange)="valueChanged($event)"
      [ngModel]="period$ | async"
      style="font-weight: 500;" />
  <mat-icon (click)="picker.open()">calendar_today</mat-icon>
  <mat-datepicker #picker></mat-datepicker>
</div>
