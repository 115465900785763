import { Injectable, OnDestroy } from '@angular/core';
import {
  ExceptionManagementApiService,
  ExpectationSearchFilter,
  RegisterFilterExpectationUnfilteredRqst,
  UnregisterFilterExpectationUnfilteredRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import { Observable, of } from 'rxjs';
import { debounceTime, shareReplay, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class PushNotificationService implements OnDestroy {
  private currentFilterHash: string = null;
  private currentFilterParams: ExpectationSearchFilter;
  private readonly token$: Observable<string>;
  private readonly documentKeys$: Observable<XrtChangedDocuments>;

  constructor(
    private messagingTokenService: XrtFireMessagingTokenService,
    private messagingService: XrtFireMessagingService,
    private exceptionManagementApiService: ExceptionManagementApiService
  ) {
    this.token$ = this.messagingTokenService.getToken$().pipe(shareReplay());
    this.documentKeys$ = this.messagingService.changedDocument$().pipe(debounceTime(3000), shareReplay());
  }

  ngOnDestroy(): void {
    this.unregisterCurrentFilter$()
      .pipe(take(1))
      .subscribe();
  }

  registerFilter(filterParams: ExpectationSearchFilter): void {
    // Prevents from re-register if app is refreshed
    if (JSON.stringify(filterParams) !== JSON.stringify(this.currentFilterParams)) {
      this.unregisterCurrentFilter$()
        .pipe(
          switchMap(() => this.token$),
          switchMap((token) => {
            this.currentFilterParams = filterParams;
            const request = new RegisterFilterExpectationUnfilteredRqst();
            request.connectionToken = token;
            // request.userId = ; // TODO
            request.filter = filterParams;

            return this.exceptionManagementApiService.registerFilterExpectationUnfiltered(request, {
              toastOnError: false,
            });
          }),
          tap((resp) => (this.currentFilterHash = resp.filterHash)),
          take(1)
        )
        .subscribe();
    }
  }

  unregisterCurrentFilter$(): Observable<any> {
    if (!this.currentFilterHash) {
      return of(null);
    } else {
      return this.token$.pipe(
        switchMap((token) => {
          const request = new UnregisterFilterExpectationUnfilteredRqst();

          request.connectionToken = token;
          request.filterHash = this.currentFilterHash;

          return this.exceptionManagementApiService.unregisterFilterExpectationUnfiltered(request, {
            toastOnError: false,
          });
        })
      );
    }
  }

  changedDocuments$(): Observable<XrtChangedDocuments> {
    return this.documentKeys$;
  }
}
