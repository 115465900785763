import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { maxBy as _maxBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { getAge, getCalculated, getEstimated, getShift } from '../../../utils/data-transform-utils';
import { GMisloadTable } from '../../enums/alerts-dashboard-tables.enum';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
import { ProNbrFormatPipe } from './../../formatters/proNbr-pipe';

@Injectable()
export class GMisloadBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'G Misload';
  static _clickSubject = new BehaviorSubject<any>(0);
  clickSubject$ = GMisloadBoardTemplate._clickSubject.asObservable();
  getAge = getAge;
  getShift = getShift;
  getEstimated = getEstimated;
  getCalculated = getCalculated;
  sidePanelOpts: SidePanelOpts;
  constructor(private timeService: XpoLtlTimeService, public status: ExceptionStatusCd) {
    super({
      id: GMisloadBoardTemplate.templateId,
      name: 'G Misload',
      keyField: 'gmisload',
      allowAdditional: false,
      availableColumns: [
        {
          headerName: 'Actions',
          field: 'action',
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          cellRenderer: 'IconsFormatterComponent',
          cellClass: 'iconColumn',
          valueGetter: () => {
            return 'visibility';
          },
          hide: true,
        },
        {
          headerName: GMisloadTable.TYPE,
          field: 'type',
          cellClass: 'iconColumn',
          cellRenderer: 'ClosedReasonFormatterComponent',
          valueGetter: (params) => {
            return params.data.operationException.cancelReasonCd;
          },
          minWidth: 60,
          maxWidth: 60,
          hide: true,
        },
        {
          headerName: GMisloadTable.AGE,
          field: 'age',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatAge(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          minWidth: 150,
          hide: true,
        },
        {
          headerName: GMisloadTable.MISLOADED_AT,
          valueGetter: (params) => {
            return params.data.operationException.loggedSic;
          },
          field: 'misloaded_at',
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.DEST_SIC,
          field: 'dest_nic',
          valueGetter: (params) => {
            return params.data.operationException.destinationSicCode;
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.MISLOADED_TO,
          field: 'misloaded_to',
          valueGetter: (params) => {
            return this.getMisloadedTo(params.data.operationException.value);
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.PRO_NBR,
          field: 'pro_nbr',
          valueGetter: (params) => {
            const proNbrWithSpace = new ProNbrFormatPipe().transform(params.data.operationException.proNbr);
            return proNbrWithSpace;
          },
          minWidth: 100,
          cellClass: 'proNbr',
        },
        {
          headerName: GMisloadTable.DOOR,
          field: 'door',
          valueGetter: (params) => {
            return params.data.operationException.doorNbr;
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.TRAILER_NBR,
          field: 'trailer_nbr',
          valueGetter: (params) => {
            return params.data.operationException.trailerNbr;
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.SERVICE_DATE,
          field: 'service_date',
          valueGetter: (params) => {
            return this.getEstimated(params.data.operationException.value);
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.CALC_DELIVERY,
          field: 'calc_delivery',
          valueGetter: (params) => {
            return this.getCalculated(params.data.operationException.value);
          },
          minWidth: 100,
        },
        {
          headerName: GMisloadTable.TIME_DATE,
          field: 'time_date',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatDate(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          minWidth: 150,
        },
        {
          headerName: GMisloadTable.RESOLUTION_TEXT,
          field: 'resolution_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          minWidth: 150,
          hide: true,
        },
        {
          headerName: GMisloadTable.COMMENTS,
          field: 'comment_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          minWidth: 150,
          hide: true,
        },
        {
          headerName: GMisloadTable.EXPIRY_TEXT,
          field: 'expiry_text',
          valueGetter: (params) => params.data.resolution,
          hide: true,
          minWidth: 200,
        },
      ],
    });
  }

  createGMisload(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'gmisload',
      name: 'G Misload',
    });
  }

  getMisloadedTo(values) {
    const [misloadedTo] = values.split(',');
    return misloadedTo;
  }

  getHighestDetail(detailList) {
    if (detailList.length > 1) {
      return _maxBy(detailList, 'expectationDetailSequenceNbr');
    } else {
      return detailList[0];
    }
  }

  formatDate(date, sic) {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  formatAge(date, sic) {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return getAge(timeDate, timeDateNow);
  }
}
