import { Component, Input, OnInit } from '@angular/core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';

@Component({
  selector: 'app-tables-wrapper',
  templateUrl: './tables-wrapper.component.html',
  styleUrls: ['./tables-wrapper.component.scss'],
})
export class TablesWrapperComponent implements OnInit {
  @Input() alertType: ExceptionTypeCd;
  @Input() alertStatus: ExceptionStatusCd;
  @Input() dataSource: Exception[];
  @Input() actions: boolean;

  constructor() {}

  ngOnInit() {}
}
