import { formatDate } from '@angular/common';

export class LinehaulFormatters {
  static EquipmentName(value: string | null): string {
    if (!value) {
      return '';
    }

    let [prefix, suffix] = value.split('-');

    prefix = prefix.replace(/^0/, '');
    suffix = suffix.padStart(4, '0');

    return `${prefix}-${suffix}`;
  }
}
