import { Injectable, OnDestroy } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { EquipmentStatusCd, ShipmentSpecialServiceCd } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { LoadsAndSchedulesGridData } from '../loads-and-schedules.component';

@Injectable()
export class LoadsBoardDataSourceService extends XpoBoardDataSource {
  data: LoadsAndSchedulesGridData[] = [
    {
      equipmentId: '123456',
      specialServiceIndicators: [ShipmentSpecialServiceCd.GUARANTEED],
      currentStatus: EquipmentStatusCd.LOADING_AT_DOCK,
      driverName: 'Joe Henry',
      driverEmployeeId: '987654',
      driverLicenseIndicators: [],
      eslDriverName: 'Joe Henry',
      eslDriverEmployeeId: '123456',
      eslDriverLicenseIndicators: [],
      cutTime: new Date(),
      plannedDispatchTime: new Date(),
      actualDispatchTime: new Date(),
      moveTo: 'UPO',
      closeTo: 'NCH',
      weightLoaded: 123,
      cubePercent: 61,
      assignedDoor: 3,
      sectorCode: '678',
      isHSS: false,
    },
    {
      equipmentId: '989898',
      specialServiceIndicators: [ShipmentSpecialServiceCd.HAZMAT, ShipmentSpecialServiceCd.FREEZABLE],
      currentStatus: EquipmentStatusCd.LOADING_AT_CUSTOMER,
      driverName: 'Max Maximus',
      driverEmployeeId: '421746',
      driverLicenseIndicators: [],
      eslDriverName: 'Max Maximus',
      eslDriverEmployeeId: '421746',
      eslDriverLicenseIndicators: [],
      cutTime: new Date(),
      plannedDispatchTime: new Date(),
      actualDispatchTime: new Date(),
      moveTo: 'UPO',
      closeTo: 'NCH',
      weightLoaded: 785,
      cubePercent: 78,
      assignedDoor: 2,
      sectorCode: '678',
      isHSS: false,
    },
  ];

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return of(new XpoBoardData(state, this.data, this.data.length, 50)).pipe(delay(1000));
  }
}
