export enum ConfigManagerProperties {
  buildVersion = 'buildVersion',
  production = 'production',
  apiUrl = 'apiUrl',
  scopeOptions = 'scopeOptions',
  secretToken = 'secretToken',
  appName = 'appName',
  region = 'region',
  environment = 'environment',
  fosProd = 'fosProd',
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  appMetadataEndpoint = 'appMetadataEndpoint',
  appMetadataAppName = 'appMetadataAppName',
  bolApiEndpoint = 'bolApiEndpoint',
  customerLocationApiEndpoint = 'customerLocationApiEndpoint',
  elasticSearchApiEndpoint = 'elasticSearchApiEndpoint',
  freightFlowApiEndpoint = 'freightFlowApiEndpoint',
  infrastructureApiEndpoint = 'infrastructureApiEndpoint',
  invoiceApiEndpoint = 'invoiceApiEndpoint',
  locationApiEndpoint = 'locationApiEndpoint',
  loggingApiEndpoint = 'loggingApiEndpoint',
  membershipApiEndpoint = 'membershipApiEndpoint',
  pricingApiEndpoint = 'pricingApiEndpoint',
  shipmentApiEndpoint = 'shipmentApiEndpoint',
  userpreferenceApiEndpoint = 'userpreferenceApiEndpoint',
  errorToastDuration = 'errorToastDuration',
  successToastDuration = 'successToastDuration',
  feedbackToAddress = 'feedbackToAddress',
  feedbackSubject = 'feedbackSubject',
  trainingResourcesURL = 'trainingResourcesURL',
  releaseNotesURL = 'releaseNotesURL',
  userMetricsLookerURL = 'userMetricsLookerURL',
  idleTimeoutInSeconds = 'idleTimeoutInSeconds',
  lookerDev = 'lookerDev',
  lookerStaging = 'lookerStaging',
  lookerMaster = 'lookerMaster',
  doorPlanDev = 'doorPlanDev',
  doorPlanStaging = 'doorPlanStaging',
  doorPlanMaster = 'doorPlanMaster',
}
