<div class="app-DrawerWrapper-header"
    fxLayout="row"
    fxLayoutAlign="space-between">
  <h2>
    <ng-content select="[appDrawerTitle]"></ng-content>
  </h2>
  <button mat-icon-button
      (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<ng-content></ng-content>
