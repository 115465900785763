import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { XpoBoardOptions, XpoFilter, XpoSelectFilter } from '@xpo-ltl/ngx-ltl-board';
import {
  XpoAgGridBoardData,
  XpoAgGridBoardReadyEvent,
  XpoAgGridBoardView,
  XpoAgGridBoardViewTemplate,
} from '@xpo-ltl/ngx-ltl-board-grid';
import { ListLinehaulExceptionsQuery } from '@xpo-ltl/sdk-exceptionmanagement';
import { GridOptions } from 'ag-grid-community';
import _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertsType } from '../../shared/enums/alerts.enum';
import { SidePanelVisibilityService } from '../../shared/services/side-panel-visibility.service';
import { AlertGridDataSourceService } from './alert-grid-data-source.service';
import { AlertGridXrtInMemoryService } from './alert-grid-xrt.service';

@Component({
  selector: 'app-alert-grid',
  templateUrl: './alert-grid.component.html',
  styleUrls: ['./alert-grid.component.scss'],
  providers: [AlertGridDataSourceService, AlertGridXrtInMemoryService],
})
export class AlertGridComponent implements OnInit, OnDestroy {
  private readonly exceptionTypeCdToAlertsType = {
    GMisload: AlertsType.MISLOAD_GUR,
    HSS: AlertsType.HSS_UNDER_WGT,
    LoadRequest: AlertsType.LOAD_REQUEST,
    PartialBypass: AlertsType.PARTIAL_BYPASS,
    ProgressiveLoad: AlertsType.PROG_LOAD,
  };

  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];
  boardData: XpoAgGridBoardData;
  boardOptions: XpoBoardOptions = { suppressViewSwitcher: true, suppressGridSettingsPopover: true };
  gridOptions: GridOptions = {
    onCellClicked: this.handleCellClick.bind(this),
    defaultColDef: { suppressMenu: true, sortable: true },
  };
  columnFilters: XpoFilter[];
  loggedSics$: BehaviorSubject<{ code: string; title: string }[]> = new BehaviorSubject([]);
  districts$: BehaviorSubject<{ code: string; title: string }[]> = new BehaviorSubject([]);
  regions$: BehaviorSubject<{ code: string; title: string }[]> = new BehaviorSubject([]);

  private initialized: boolean = false;
  private unsubscribe = new Subject<void>();

  @Input()
  get query(): ListLinehaulExceptionsQuery {
    return this.queryValue;
  }
  set query(q: ListLinehaulExceptionsQuery) {
    this.queryValue = q;
    this.dataSource.query = q;

    if (this.initialized) {
      this.dataSource.refresh();
    }
  }
  private queryValue: ListLinehaulExceptionsQuery;

  constructor(
    public dataSource: AlertGridDataSourceService,
    private xrtService: AlertGridXrtInMemoryService,
    private sidePanelVisibilityService: SidePanelVisibilityService
  ) {}

  ngOnInit(): void {
    this.viewTemplates = this.getViewTemplates();
    this.views = this.getViews(this.viewTemplates[0]);
    this.initializeBoardFilters();
    this.initialized = true;
  }

  handleCellClick(element): void {
    const alertType = this.exceptionTypeCdToAlertsType[element.data.exceptionRaw.operationException.exceptionTypeCd];
    this.sidePanelVisibilityService.openPanel(alertType, element.data.exceptionRaw);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  buildFilter(data: any[], field: string): { code: string; title: string }[] {
    const filterList = [];
    data
      .reduce(
        (filter, exception) =>
          filter.set(exception[field], filter.get(exception[field]) >= 0 ? filter.get(exception[field]) + 1 : 0),
        new Map()
      )
      .forEach((value, key) => {
        filterList.push({ code: key, title: `${key} (${value})` });
      });
    return filterList;
  }

  handleGridBoardReady(event: XpoAgGridBoardReadyEvent): void {
    this.dataSource.dataUpdated$().subscribe((data) => {
      if (data.focusedRecord || data.selection?.length) {
        // Taken from Board Library, the focus row function didn't work

        const viewTemplate = <XpoAgGridBoardViewTemplate>data.template;
        const viewTemplateKey = viewTemplate ? viewTemplate.keyField : null;
        if (!viewTemplateKey) {
          // TODO: this should throw an error, but we will need to call the error callback for ag-grid
          // if you throw an error here, pagingation is broken
          console.error(
            `View template ${viewTemplate.name} has a null keyField value.
            Please provide the key of the field that uniquely identifies each row for this viewTemplate
            in order for some functionality of the ag-grid-board to work`
          );
          return;
        }
        const rowSelection = data.focusedRecord ?? data.selection[0];
        event.agGridApi.clearFocusedCell();

        event.agGridApi.forEachNode((node) => {
          if (node.data && rowSelection[viewTemplateKey] === node.data[viewTemplateKey]) {
            event.agGridApi.ensureNodeVisible(node, 'middle');
            node.setSelected(true);
            return;
          }
        });
      }
    });
  }

  private initializeBoardFilters() {
    this.xrtService.cachedData
      .cachedResponse$(this.query)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        this.loggedSics$.next(this.buildFilter(data, 'loggedSic'));
        this.regions$.next(this.buildFilter(data, 'linehaulRegion'));
        this.districts$.next(this.buildFilter(data, 'district'));
      });
  }

  private getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'exceptionsBoardView',
        name: 'Alerts View',
        availableFilters: this.getColumnFilters(),
        keyField: 'exceptionInstId',
      }),
    ];
  }

  private getViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [viewTemplate.createView({ id: 'exceptionsBoardView', name: 'Exceptions' })];
  }

  private getColumnFilters(): XpoFilter[] {
    return [
      new XpoSelectFilter({ field: 'linehaulRegion', label: 'Region', options: this.regions$ }),
      new XpoSelectFilter({ field: 'district', label: 'District', options: this.districts$ }),
      new XpoSelectFilter({ field: 'loggedSic', label: 'Logged Sic', options: this.loggedSics$ }),
    ];
  }
}
