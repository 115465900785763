import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { distinctUntilChanged } from 'rxjs/operators';
import { HierarchyRegion } from '../../../performance-dashboard/models/hierarchy-region';
import { LocationLabel } from '../../../performance-dashboard/models/location-label';
import { LinehaulStateService } from '../../services/linehaul-state.service';

@Component({
  selector: 'app-hierarchy-filter',
  templateUrl: './hierarchy-filter.component.html',
  styleUrls: ['./hierarchy-filter.component.scss'],
  host: { class: 'app-HierarchyFilter' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HierarchyFilterComponent {
  hierarchyOptions: HierarchyRegion[] = [
    HierarchyRegion.OPERATIONAL_REGION,
    HierarchyRegion.LINEHAUL_REGION,
    HierarchyRegion.HSS_REGION,
  ];
  locationLabel = LocationLabel;
  hierarchy: HierarchyRegion;

  @ViewChild(MatSelect, { read: ViewContainerRef }) hierarchySelect: ViewContainerRef;

  constructor(private linehaulStateService: LinehaulStateService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.linehaulStateService
      .listen('dashboardSelectedHierarchy')
      .pipe(distinctUntilChanged())
      .subscribe((h) => {
        this.hierarchy = h;

        setTimeout(() => this.hierarchySelect.element.nativeElement.focus(), 0); // Fix for not auto-focusing after external change
        this.cd.markForCheck();
      });
  }

  handleHierarchyChange(hierarchy: HierarchyRegion): void {
    this.hierarchy = hierarchy;
    // If there's a change of hierarchy, remove location too // TODO: empty Location object
    this.linehaulStateService.setState({
      dashboardSelectedHierarchy: hierarchy,
      selectedLocations: [],
    });
  }
}
