import { Component, Input, OnInit } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import moment from 'moment';
import { TrailerDetailsData } from '../../../../../load-management/allowable-loads/models/trailer-details-data.model';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-bufi-load-panel',
  templateUrl: './bufi-load-panel.component.html',
  styleUrls: ['./bufi-load-panel.component.scss'],
})
export class BufiLoadPanelComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() sidePanelData: any;
  alertData: any;
  trailerDetailsData: TrailerDetailsData;
  showAck: boolean = false;

  constructor(private authService: AuthService, private timeService: XpoLtlTimeService) {}

  ngOnInit(): void {
    this.trailerDetailsData = {
      loadedTimestamp: this.sidePanelData.loadedTimestamp,
      closeTmst: this.sidePanelData.closeTmst,
      statusCd: this.sidePanelData.statusCd,
      load: this.sidePanelData.sidePanelId,
      filterSic: this.sidePanelData.filterSic,
      currentEventTmst: this.sidePanelData.currentEventTmst,
    };

    this.alertData = {
      destination: this.alert.alert.operationException.destinationSicCode,
      closed: this.alert.alert.operationException.employeeName,
      shipments: this.alert.alert.operationException.itemInstIds.length,
      weight: this.alert.alert.operationException.value.split(',')[2],
      door: this.alert.alert.operationException.doorNbr,
      status: this.alert.alert.statusCd,
      cube: this.alert.alert.operationException.value.split(',')[1],
      booking: this.alert.alert.operationException.bookingNumber,
      trailer: this.alert.alert.operationException.trailerNbr,
      origin: this.alert.alert.operationException.value.split(',')[0],
      eta: this.formatEta(),
      exceptionInstId: this.alert.alert.exceptionInstId,
      type: this.type,
    };

    this.showAck = this.checkStatus();
  }

  private checkStatus(): boolean {
    return (
      this.status !== ExceptionStatusCd.CLOSED &&
      this.status !== ExceptionStatusCd.RESOLVED &&
      this.status !== ExceptionStatusCd.EXPIRED
    );
  }

  private formatEta(): any {
    const eta = this.alert.alert.operationException.value.split(',')[3];
    if (eta !== 'N/A' && eta !== 'VIA') {
      const loggedSic = this.alert.alert.operationException.loggedSic;
      const ts = moment(eta, 'YYYY/MM/DD-HH:mm:ss').valueOf();
      return this.timeService.formatDate(ts, 'HH:mm, MM/DD/YYYY', loggedSic);
    } else {
      return eta;
    }
  }
}
