<div *ngIf="showTable">
  <h1 mat-dialog-title class="xpo-dialog-title">
    Manifest {{ data.load }}
    <xpo-dialog-close-button class="xpo-dialog-close"></xpo-dialog-close-button>
  </h1>

  <div mat-dialog-content>
    <div class="prosTableContainer">
      <xpo-ag-grid>
        <ag-grid-angular
          [gridOptions]="gridOptions"
          [columnDefs]="columnDefs"
          [enableFilter]="false"
          [rowData]="shipmentsCollection"
        >
        </ag-grid-angular>
      </xpo-ag-grid>
      <div class="no-shipmentsCollection" *ngIf="!shipmentsCollection.length">
        <label for="no-data">No data to show.</label>
      </div>
    </div>
  </div>
</div>
