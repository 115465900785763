import { Injectable, Injector } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlIdleTimeoutService } from '@xpo-ltl/ngx-ltl';
import { XpoAuthenticationService, XpoAuthEventHandler } from '@xpo/ngx-auth';
import { User } from 'oidc-client';
import { Observable, of } from 'rxjs';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';

/**
 * Handle SSO Authentication events
 */
@Injectable({ providedIn: 'root' })
export class AppAuthEventHandler implements XpoAuthEventHandler {
  constructor(
    private idleService: XpoLtlIdleTimeoutService,
    private injector: Injector,
    private configService: ConfigManagerService
  ) {}

  preSignIn(): Observable<any> {
    console.log('AppAuthEventHandler.preSignIn()');
    return of(null);
  }
  postSignIn(user: User): Observable<any> {
    // Disabling idle timeout until we get the redirect link corrected...
    const idleTimeout = this.configService.getSetting<number>(ConfigManagerProperties.idleTimeoutInSeconds);
    this.idleService.start({ timeout: idleTimeout });

    // Logout when the idleTimeout expires
    const subscriber = this.idleService.idleTimeout$.subscribe(() => {
      console.log(`User has been idle for ${idleTimeout}s. Logging out.`);
      this.idleService.stop();
      subscriber.unsubscribe();

      // NOTE: We need to get the service from the injector because it
      // isn't available when the class is constructed
      const authService = this.injector.get(XpoAuthenticationService);
      authService.signOut$().subscribe(() => {});
    });

    return of(null);
  }
  preSignOut(user: User): Observable<any> {
    console.log('AppAuthEventHandler.preSignOut() - ', user);
    return of(null);
  }
  postSignOut(): Observable<any> {
    console.log('AppAuthEventHandler.postSignOut()');
    return of(null);
  }
  preRefresh(user: User): Observable<any> {
    console.log('AppAuthEventHandler.preRefresh() - ', user);
    return of(null);
  }
  postRefresh(user: User): Observable<any> {
    console.log('AppAuthEventHandler.postRefresh() - ', user);
    return of(null);
  }
}
