import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import {
  Exception,
  ExceptionManagementApiService,
  UpdateExceptionPath,
  UpdateExceptionRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';
import {
  GetLaneRemainingLoadInfoPath,
  LinehaulOperationsApiService,
  LoadRequestReasonCode,
  UpsertLoadRequestRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ComponentsEnum } from '../../../../enums/components.enum';
import { AuthService } from '../../../../services/auth.service';
import { InteractionService } from '../../../../services/interaction.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';

@Component({
  selector: 'load-request-details',
  templateUrl: './load-request-details.component.html',
  styleUrls: ['./load-request-details.component.scss'],
})
export class LoadRequestDetails implements OnInit {
  action: string;
  loadRequestData;
  dataAlert;
  loadsQty = '';
  formNames = {
    LoadQty: 'loadQuantity',
    ApprovalType: 'approvalType',
    DeclineReason: 'declineReason',
  };
  form: FormGroup;
  loadQuantityFormControl: AbstractControl = new FormControl();
  approvalTypeFormControl: AbstractControl = new FormControl();
  declineReasonFormControl: AbstractControl = new FormControl();
  approvalType = ['Eligible', 'Ineligible'];

  readOnlyRoles: boolean = false;
  info = {
    requesting: '',
    requested: '',
    lane: '',
    nOfLoads: '',
    requestedBy: '',
    reason: '',
    comments: '',
    closeTo: '',
    decisionComments: '',
    isExpired: '',
  };
  isExpired;
  loadQuantityArray: any[];

  constructor(
    private interactionService: InteractionService,
    private fb: FormBuilder,
    private timeService: XpoLtlTimeService,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private authService: AuthService,
    private userRoleService: UserRoleService,
    private loggingApiService: LoggingApiService,
    private xpoSnackBar: XpoSnackBar,
    private exceptionApi: ExceptionManagementApiService
  ) {}

  ngOnInit(): void {
    this.interactionService.subscribeToComponent(ComponentsEnum.OPEN_PANEL).subscribe((loadRequestPanel) => {
      const { action, loadReqInfo, alertInfo } = loadRequestPanel;
      this.loadRequestData = loadReqInfo;
      this.dataAlert = alertInfo;
      this.initializeForm();
      this.loadInfo();
      this.action = action;
      if (this.action === 'disabled') {
        this.readOnlyRoles = true;
      }
    });
  }

  get readOnlyRange() {
    const now = new Date();
    const timeCheck = this.loadRequestData.expirationDateFrom < now && this.loadRequestData.expirationDateTo > now;
    return !timeCheck || this.form.controls.declineReason.disabled || this.approvalTypeFormControl.disabled;
  }

  initializeForm() {
    this.form = this.fb.group({
      loadQuantity: ['', Validators.required],
      approvalType: ['', Validators.required],
      declineReason: ['' /*Validators.required*/],
    });
    this.loadQuantityFormControl = this.form.get('loadQuantity');
    this.approvalTypeFormControl = this.form.get('approvalType');
    this.declineReasonFormControl = this.form.get('declineReason');
  }

  loadInfo() {
    let createdDate;
    if (this.loadRequestData) {
      this.info.requesting = this.loadRequestData.originSicCode;
      this.info.requested = this.loadRequestData.planDate;
      this.info.lane = this.loadRequestData.moveToSicCode;
      this.info.nOfLoads = this.loadRequestData.requestQuantity.toLocaleString();
      this.info.closeTo = this.loadRequestData.loadToSicCode;
      this.loadsQty = this.loadRequestData.requestQuantity.toString();
      this.info.requestedBy = this.loadRequestData.employeeName;
      this.info.comments = this.loadRequestData.requestorComments;
      this.info.decisionComments = this.loadRequestData.linehaulComments;
      this.approvalTypeFormControl.setValue(this.loadRequestData.approveTypeCd);
      this.isExpired = this.loadRequestData.isExpired;
      createdDate = new Date(this.loadRequestData.auditInfo.createdTimestamp);
      this.setLoadQuantityArray();

      const a = this.loadRequestData.reasonCd;
      this.interactionService
        .getLoadRequestReasonCodes()
        .subscribe((loadRequestReasonCodes: LoadRequestReasonCode[]) => {
          if (loadRequestReasonCodes && loadRequestReasonCodes.length > 0) {
            loadRequestReasonCodes.map((x) => {
              if (x.reasonCd === this.loadRequestData.reasonCd) {
                this.info.reason = x.reasonDescription;
              }
            });
          }
        });
    }
  }

  getFormattedDate(date) {
    const timeToSic = this.timeService.formatDate(
      this.loadRequestData.auditInfo.createdTimestamp,
      ' HH:mm, MM/DD/YYYY',
      this.loadRequestData.originSicCode
    );
    return timeToSic;
  }

  hasError(error: string, formGrp?: FormGroup, field?: string): boolean {
    if (formGrp && formGrp.hasError(error)) {
      return formGrp.hasError(error) && formGrp.touched;
    } else if (formGrp && field) {
      return formGrp.get(field).hasError(error) && formGrp.get(field).touched;
    }
  }

  setLoadQuantityArray() {
    this.loadQuantityArray = [];
    for (let i = this.loadRequestData.requestQuantity; i > 0; i--) {
      this.loadQuantityArray.push(i);
    }

    const { approvedQuantity, declinedQuantity, requestQuantity } = this.loadRequestData;

    if (approvedQuantity) {
      this.loadQuantityFormControl.setValue(approvedQuantity);
    } else {
      declinedQuantity
        ? this.loadQuantityFormControl.setValue(declinedQuantity)
        : this.loadQuantityFormControl.setValue(requestQuantity);
    }
  }

  setApprovalType() {
    if (this.dataAlert['approveTypeCd']) {
      this.approvalTypeFormControl.setValue(this.loadRequestData.approveTypeCd);
    }
  }

  setComments() {
    if (this.loadRequestData && this.loadRequestData.linehaulComments) {
      this.declineReasonFormControl.setValue(this.loadRequestData.linehaulComments);
    }
  }

  approve(): void {
    if (this.isAllowedToApproveOrDecline()) {
      const declinedLoads = this.loadRequestData.requestQuantity - this.form.get('loadQuantity').value;
      if (!this.approvalTypeFormControl.value) {
        this.approvalTypeFormControl.setErrors({ required: true });
      } else {
        this.approvalTypeFormControl.setErrors(null);
      }
      if (declinedLoads > 0 && !this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
      } else {
        this.declineReasonFormControl.setErrors(null);
      }
      if (!this.form.get('loadQuantity').value || this.form.get('loadQuantity').value === 0) {
        this.loadQuantityFormControl.setErrors({ required: true });
      } else {
        this.loadQuantityFormControl.setErrors(null);
      }
      this.form.markAllAsTouched();
      const request = new UpsertLoadRequestRqst();
      request.loadRequest = { ...this.loadRequestData };

      if (this.form.valid) {
        request.loadRequest.approvedQuantity = this.form.get('loadQuantity').value;
        request.loadRequest.declinedQuantity = declinedLoads;
        request.loadRequest.approveTypeCd = this.form.get('approvalType').value;
        request.loadRequest.declineReasonCd = this.form.get('approvalType').value;
        request.loadRequest.approveDeclineDateTime = new Date();
        request.loadRequest.approveReasonCd = this.form.get('approvalType').value;
        request.loadRequest.linehaulComments = this.form.get('declineReason').value;
        request.loadRequest.linehaulName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
        request.loadRequest.linehaulEmplid = this.userRoleService.user.employeeId;
        request.loadRequest.planShiftCd = this.loadRequestData.planShiftCd;
        request.loadRequest.regionCd = this.loadRequestData.regionCd;
        request.loadRequest.requestorEmplid = this.userRoleService.user.employeeId;
        request.loadRequest.requestType = 'ADD';
        delete request.loadRequest['exception'];
        delete request.loadRequest['expectationHeader'];
        delete request.loadRequest['isExpired'];

        this.linehaulOperationsApiService.upsertLoadRequest(request).subscribe((response) => {
          if (response) {
            const loadRequest = request && request.loadRequest;

            this.loggingApiService.info(
              this.userRoleService.user.userId +
                ' - upsertLoadRequest ' +
                this.userRoleService.build +
                ' - For SIC: ' +
                (loadRequest && loadRequest.originSicCode) +
                ' - Lane: ' +
                (loadRequest && loadRequest.moveToSicCode) +
                ' - Approved Loads : ' +
                (loadRequest && loadRequest.approvedQuantity) +
                ' Out Of ' +
                (loadRequest && loadRequest.requestQuantity),
              this.authService.getEmployeeSic(),
              'Approval of Load Request from LoMa',
              'PUT',
              ExceptionTypeCd.LOAD_REQUEST
            );

            this.xpoSnackBar.success('SUCCESS: Load Request Approved!');
            this.updateException();
            this.closePanel();
          } else {
            this.xpoSnackBar.error('ERROR: Load request Update failed!');
          }
        });
      } else {
        this.xpoSnackBar.error('Please fix the errors below before Approving');
      }
    }
  }

  decline() {
    if (this.isAllowedToApproveOrDecline()) {
      if (!this.declineReasonFormControl.value) {
        this.declineReasonFormControl.setErrors({ required: true });
        this.approvalTypeFormControl.setErrors(null);
      }
      if (this.form.valid) {
        const request = new UpsertLoadRequestRqst();
        request.loadRequest = this.loadRequestData;
        request.loadRequest.declinedQuantity = this.info.nOfLoads ? Number(this.info.nOfLoads) : null;
        request.loadRequest.approvedQuantity = null;
        request.loadRequest.approveTypeCd = null;
        request.loadRequest.declineReasonCd = this.form.get('approvalType').value;
        request.loadRequest.approveDeclineDateTime = new Date();
        request.loadRequest.approveReasonCd = this.form.get('approvalType').value;
        request.loadRequest.linehaulComments = this.form.get('declineReason').value;
        request.loadRequest.linehaulName = `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`;
        request.loadRequest.linehaulEmplid = this.userRoleService.user.employeeId;
        request.loadRequest.planShiftCd = this.loadRequestData.planShiftCd;
        request.loadRequest.regionCd = this.loadRequestData.regionCd;
        request.loadRequest.requestorEmplid = this.userRoleService.user.employeeId;
        request.loadRequest.requestType = 'ADD';
        this.linehaulOperationsApiService.upsertLoadRequest(request).subscribe((response) => {
          if (response) {
            const loadRequest = request && request.loadRequest;

            this.loggingApiService.info(
              this.userRoleService.user.userId +
                ' - upsertLoadRequest ' +
                this.userRoleService.build +
                ' - For SIC: ' +
                (loadRequest && loadRequest.originSicCode) +
                ' - Lane: ' +
                (loadRequest && loadRequest.moveToSicCode) +
                ' - Declined Loads: ' +
                this.form.get('loadQuantity').value,
              this.authService.getEmployeeSic(),
              'Declination of Load Request from LoMa',
              'PUT',
              ExceptionTypeCd.LOAD_REQUEST
            );
            this.xpoSnackBar.success('SUCCESS: Load Request Declined!');
            this.updateException();
            this.closePanel();
          } else {
            this.xpoSnackBar.error('ERROR: Load request Update failed!');
          }
        });
      } else {
        this.xpoSnackBar.error('Please fix the errors below before Declining');
      }
    }
  }

  updateException() {
    const updatePath = new UpdateExceptionPath();
    const request = new UpdateExceptionRqst();
    const exception = this.dataAlert && this.dataAlert.exception;

    updatePath.exceptionInstId = this.dataAlert.exception.exceptionInstId;
    request.exception = this.dataAlert.exception;
    request.exception.statusCd = 'Completed';
    request.exception.exceptionInstId = this.dataAlert.exception.exceptionInstId;
    request.operationName = 'Completed';

    this.exceptionApi.updateException(request, updatePath).subscribe(
      (response) => {
        this.loggingApiService.info(
          this.userRoleService.user.userId +
            ' - UpdateException: Completed ' +
            this.userRoleService.build +
            ' - Alert: ' +
            exception,
          this.authService.getEmployeeSic(),
          'Update Status of Load Request from LoMa',
          'UPDATE',
          ExceptionTypeCd.LOAD_REQUEST
        );
      },
      catchError((error) => {
        this.loggingApiService.error(
          this.userRoleService.user.userId +
            ' - UpdateException: error:  ' +
            error +
            this.userRoleService.build +
            ' - Alert: ' +
            exception,
          this.authService.getEmployeeSic(),
          'Update Status of Load Request from LoMa',
          'UPDATE',
          ExceptionTypeCd.LOAD_REQUEST
        );

        return of(false);
      })
    );
  }

  isAllowedToApproveOrDecline(): boolean {
    return this.authService.isAllowedToApprove();
  }

  closePanel() {
    this.interactionService.setStatusLoadRequestPanel(false);
  }

  onCancel() {
    this.cleanControls();
    this.closePanel();
  }

  cleanControls() {
    this.approvalTypeFormControl.reset();
    this.declineReasonFormControl.reset();
    this.declineReasonFormControl.setErrors(null);
  }
}
