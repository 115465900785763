<div class="container">
  <div class="actions-container">
    <div class="show-container"
        *ngIf="isPerformanceDashboard">
      <span class="toggle-info">Show all alerts</span>
      <div class="switch-checkbox-control"
          (click)="changeShowAlerts()">
        <input [checked]="showAlerts"
            type="checkbox" />
        <span class="slider round"></span>
      </div>
    </div>
    <div class="show-container"
        [ngClass]="
        route === AppRoutes.PERFORMANCE_DASHBOARD || route === AppRoutes.LOAD_MANAGEMENT
          ? 'showSicViewToogle'
          : 'hideSicViewToogle'
      ">
      <span class="toggle-info"
          *ngIf="isPerformanceDashboard">{{ isSicUser ? 'LH View' : 'SIC View' }}</span>
      <div class="switch-checkbox-control"
          (click)="sicQueueToggle()"
          *ngIf="isPerformanceDashboard">
        <input [checked]="isSicUser ? !sicQueueSelected : sicQueueSelected"
            type="checkbox" />
        <span class="slider round"></span>
      </div>
    </div>
    <button mat-button
        xpoSmallButton
        class="icon-container"
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadge]="counter"
        [matBadgeHidden]="!counter || route !== AppRoutes.PERFORMANCE_DASHBOARD"
        (click)="refresh()">
      <mat-icon class="refresh-icon">replay</mat-icon> <span>Refresh</span>
    </button>
  </div>
</div>
