import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { ActionService } from '../../../../services/action.service';
import { AuthService } from '../../../../services/auth.service';
import { SidePanelVisibilityService } from '../../../../services/side-panel-visibility.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';

@Component({
  selector: 'acknowledge-tab',
  templateUrl: './acknowledge-tab.component.html',
  styleUrls: ['./acknowledge-tab.component.scss'],
})
export class AckTabComponent implements OnInit {
  @Input() status: string;
  @Input() alert: any;
  @Input() type: string;
  @Input() allowFalsePositive: boolean = false;

  disabled: boolean;
  form: FormGroup;
  resolution: string;
  commentFormControl: AbstractControl = new FormControl();
  actionTypes: string[] = ['Acknowledge', 'Close'];
  selectedAction: string = 'Acknowledge';
  falsePositive: string = 'Closed as False Positive';
  statusAckOrResponded: boolean = false;
  noComments: boolean = false;
  allowedToClose: boolean = true;

  constructor(
    private fb: FormBuilder,
    private xpoSnackBar: XpoSnackBar,
    private actionService: ActionService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private loggingApiService: LoggingApiService,
    private authService: AuthService,
    private userRoleService: UserRoleService
  ) {}

  ngOnInit() {
    if (
      this.status === ExceptionStatusCd.ACKNOWLEDGED ||
      this.status === ExceptionStatusCd.APPROVED ||
      this.status === ExceptionStatusCd.DECLINED
    ) {
      this.statusAckOrResponded = true;
      this.selectedAction = 'Close';
    }
    if (this.statusAckOrResponded && this.isLoadRequest(this.type)) {
      this.noComments = true;
      if (!this.authService.isAllowedToCloseLr()) {
        this.allowedToClose = false;
      }
    } else {
      this.noComments = false;
    }
    if (this.isGMisload) {
      if (this.allowFalsePositive) {
        this.actionTypes = [];
      }
      this.actionTypes.push(this.falsePositive);
      this.noComments = false;
    }
    this.resolution = this.alert.alert.resolution || '';
    this.alert = this.alert;
    this.type = this.type;
    this.initForm();
  }

  get isGMisload(): boolean {
    return this.type === ExceptionTypeCd.GMISLOAD;
  }

  private isLoadRequest(type): boolean {
    return (
      type === ExceptionTypeCd.ADD_LOAD_REQUEST ||
      type === ExceptionTypeCd.CUT_LOAD_REQUEST ||
      type === ExceptionTypeCd.LOAD_REQUEST
    );
  }

  initForm() {
    if (this.noComments) {
      this.form = this.fb.group({
        comment: ['', [Validators.maxLength(100)]],
      });
    } else {
      this.form = this.fb.group({
        comment: ['', [Validators.required, Validators.maxLength(100)]],
      });
    }
    this.commentFormControl = this.form.get('comment');
    this.commentFormControl.markAsTouched();
  }

  save(): void {
    const action =
      this.selectedAction === 'Close'
        ? ExceptionStatusCd.CLOSED
        : this.selectedAction === this.falsePositive
        ? 'False Positive'
        : ExceptionStatusCd.ACKNOWLEDGED;
    if (this.form.valid) {
      this.resolution = this.form.get('comment').value;
      this.actionService.updateException(this.alert.alert, action, this.resolution).subscribe((resp) => {
        if (resp.code === '200') {
          this.loggingApiService.info(
            this.userRoleService.user.userId +
              'UpdateException: ' +
              this.userRoleService.build +
              ' - Action: ' +
              action +
              ' - Alert: ' +
              this.alert.alert,
            this.authService.getEmployeeSic(),
            'Update Status of Alert/Exception from Performance Dashboard',
            'UPDATE',
            this.type
          );
          this.disabled = true;
          this.closePanel();
        }
      });
    } else {
      this.xpoSnackBar.error('ERROR: Acknowledgement failed!');
    }
  }

  private closePanel() {
    this.sidePanelVisibilityService.closePanels();
  }
}
