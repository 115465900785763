import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ShowAlertsService {
  private showAlerts = new BehaviorSubject<boolean>(false);

  get showAlerts$(): Observable<boolean> {
    return this.showAlerts.asObservable();
  }

  updateShowAlerts(status: boolean): void {
    this.showAlerts.next(status);
  }
}
