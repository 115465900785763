import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

export type SpecialServiceIconName = 'cdl' | 'tank' | 'hazm' | 'fast_id';

export type SpecialServiceIconStatus = 'success' | 'warning' | 'error' | null;

@Component({
  selector: 'app-special-service-icon',
  templateUrl: './special-service-icon.component.html',
  styleUrls: ['./special-service-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'app-SpecialServiceIcon' },
})
export class SpecialServiceIconComponent {
  @Input() name: SpecialServiceIconName;
  @Input() status: SpecialServiceIconStatus;
}
