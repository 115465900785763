import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { XpoButtonModule, XpoIconModule } from '@xpo-ltl/ngx-ltl-core';
import { DrawerWrapperComponent } from './drawer-wrapper/drawer-wrapper.component';
import { DrawerComponent } from './drawer.component';

@NgModule({
  declarations: [DrawerComponent, DrawerWrapperComponent],
  imports: [CommonModule, XpoIconModule, XpoButtonModule, FlexLayoutModule],
  exports: [DrawerComponent, DrawerWrapperComponent],
})
export class DrawerLauncherModule {}
