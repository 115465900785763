import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GetOdsShipmentResp } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, reduce as _reduce } from 'lodash';
import { findIndex as _findIndex, forEach as _forEach, get as _get, map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DataDisplayGroupType } from '../data-display-group/data-display-group.component';
interface DimensionsData {
  Quantity: number;
  Length: number;
  Width: number;
  Height: number;
}
@Component({
  selector: 'shipment-details-dimensions',
  templateUrl: './shipment-details-dimensions.component.html',
  styleUrls: ['./shipment-details-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentDimensionsComponent implements OnInit, OnDestroy {
  packagesDimensionList: any[];
  dataSource: any[];
  noDimensions: boolean;
  showDimensions = false;
  @Input() shipment: any;
  columns = [
    { id: 'quantity', label: 'Quantity' },
    { id: 'lengthNbr', label: 'Length' },
    { id: 'widthNbr', label: 'Width' },
    { id: 'heightNbr', label: 'Height' },
  ];
  columnsToDisplay = _map(this.columns, (c) => c.id);
  private dimensionsSubject = new BehaviorSubject<DimensionsData>({
    Quantity: undefined,
    Length: undefined,
    Width: undefined,
    Height: undefined,
  });
  readonly dimensions$ = this.dimensionsSubject.asObservable();
  readonly DataDisplayGroupType = DataDisplayGroupType;
  constructor() {}
  ngOnInit() {
    this.updateFormFromShipment(this.shipment);
  }
  ngOnDestroy() {}

  private setPackageQuantity(dimensionsArray) {
    const strArray = [];
    const resultArray = [];
    _forEach(dimensionsArray, function(packageDimension) {
      packageDimension.quantity = 1;
      packageDimension = JSON.stringify(packageDimension);
      const indexPackageInStrArray = _findIndex(strArray, (o) => o === packageDimension);
      if (indexPackageInStrArray === -1) {
        strArray.push(packageDimension);
        resultArray.push(JSON.parse(packageDimension));
      } else {
        resultArray[indexPackageInStrArray].quantity += 1;
      }
    });
    return resultArray;
  }
  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    const dimensions = _get(shipment, 'linehaulDimensions');
    this.packagesDimensionList = this.setPackageQuantity(dimensions);
    this.dataSource = this.packagesDimensionList;
    if (!shipment) {
      return;
    }
    this.noDimensions = !dimensions || !dimensions.length;
  }
}
