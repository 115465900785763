import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ExceptionStatusCd, ExceptionSummaryCd, ExceptionSummaryTypeCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

export interface SummarySelection {
  exceptionSummaryCd: ExceptionSummaryCd;
  exceptionSummaryTypeCd: ExceptionSummaryTypeCd;
  exceptionStatusCd: ExceptionStatusCd[];
}

export type RoleView = 'lh' | 'sic';

@Component({
  selector: 'app-alert-and-exception-header',
  templateUrl: './alert-and-exception-header.component.html',
  styleUrls: ['./alert-and-exception-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-AlertAndExceptionHeader' },
  encapsulation: ViewEncapsulation.None,
})
export class AlertAndExceptionHeaderComponent {
  displayedColumns$: Observable<string[]>;

  private summaryCodeLookup: any = {
    Loads: ExceptionSummaryCd.LOAD,
    Shipment: ExceptionSummaryCd.SHIPMENT,
    'Power Management': ExceptionSummaryCd.POWER_MANAGEMENT,
  };
  private summaryTypeCodeLookup: any = {
    exceptionNew: {
      exceptionStatusCd: [ExceptionStatusCd.NEW],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.EXCEPTION,
    },
    exceptionUnderReview: {
      exceptionStatusCd: [ExceptionStatusCd.UNDER_REVIEW_LH],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.EXCEPTION,
    },
    exceptionClosed: {
      exceptionStatusCd: [ExceptionStatusCd.CLOSED],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.EXCEPTION,
    },
    alertNew: { exceptionStatusCd: [ExceptionStatusCd.NEW], exceptionSummaryTypeCd: ExceptionSummaryTypeCd.ALERT },
    alertAcknowledged: {
      exceptionStatusCd: [ExceptionStatusCd.ACKNOWLEDGED],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.ALERT,
    },
    alertClosed: {
      exceptionStatusCd: [ExceptionStatusCd.CLOSED],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.ALERT,
    },
    alertResolved: {
      exceptionStatusCd: [ExceptionStatusCd.RESOLVED],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.ALERT,
    },
    alertExpired: {
      exceptionStatusCd: [ExceptionStatusCd.EXPIRED],
      exceptionSummaryTypeCd: ExceptionSummaryTypeCd.ALERT,
    },
  };

  private readonly exceptionList = ['exceptionNew', 'exceptionUnderReview', 'exceptionClosed'];

  @Input() alertsAndExceptions: any[];
  @Input() roleView: RoleView = 'sic';

  @Input()
  get hssChecked(): boolean {
    return this.hssCheckedValue;
  }
  set hssChecked(v: boolean) {
    this.hssCheckedValue = coerceBooleanProperty(v);
  }
  private hssCheckedValue = false;

  @Input()
  get showAllAlerts(): boolean {
    return this.showAllAlertsValue;
  }
  set showAllAlerts(v: boolean) {
    this.showAllAlertsValue = coerceBooleanProperty(v);
  }
  private showAllAlertsValue = false;

  @Output() typeSelected = new EventEmitter<SummarySelection>();
  @Output() hssCheckedChange = new EventEmitter<boolean>();
  @Output() showAllAlertsChange = new EventEmitter<boolean>();
  @Output() roleViewChange = new EventEmitter<RoleView>();

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns$ = this.showAllAlertsChange.pipe(
      startWith(this.showAllAlerts),
      map((showAllAlerts) =>
        showAllAlerts
          ? [
              'exceptionNew',
              'exceptionUnderReview',
              'exceptionClosed',
              'alertNew',
              'alertAcknowledged',
              'alertClosed',
              'alertResolved',
              'alertExpired',
            ]
          : ['exceptionNew', 'exceptionUnderReview', 'alertNew', 'alertAcknowledged']
      ),
      shareReplay()
    );
  }

  handleCellClicked(type: string, group: string, data: any): void {
    if (this.exceptionList.includes(type) && this.hssChecked) {
      if (type === 'exceptionNew') {
        this.typeSelected.emit({
          exceptionStatusCd: [ExceptionStatusCd.NEW, ExceptionStatusCd.NEW_SIC],
          exceptionSummaryTypeCd: ExceptionSummaryTypeCd.EXCEPTION,
          exceptionSummaryCd: this.summaryCodeLookup[group],
        });
      } else {
        this.typeSelected.emit({
          ...this.summaryTypeCodeLookup[type],
          exceptionSummaryCd: this.summaryCodeLookup[group],
        });
      }
    } else {
      if (data[type]) {
        this.typeSelected.emit({
          ...this.summaryTypeCodeLookup[type],
          exceptionSummaryCd: this.summaryCodeLookup[group],
        });
      }
    }
  }

  handleToggle(event: MatSlideToggleChange, sourceValue: boolean, changeEmitter: EventEmitter<boolean>): void {
    sourceValue = event.checked;
    changeEmitter.emit(event.checked);
  }

  loadExceptionHasValue(data: any): boolean {
    return data.exceptionNew > 0 || (this.hssChecked && data?.hssNew > 0) || (this.hssChecked && data?.hssNewSIC > 0);
  }

  computeLoadException(data: any): number {
    return this.hssChecked ? (data.exceptionNew || 0) + (data.hssNew || 0) + (data.hssNewSIC || 0) : 0;
  }

  handleRoleViewChange(data: RoleView): void {
    this.roleView = data;
    this.roleViewChange.emit(data);
  }
}
