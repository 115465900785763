<xpo-board id="exceptionsBoardView"
    [dataSource]="dataSource"
    [viewTemplates]="viewTemplates"
    [views]="views"
    [boardOptions]="boardOptions">
  <xpo-ag-grid-board rowModelType="client-side"
      (gridBoardReady)="handleGridBoardReady($event)"
      [customGridOptions]="gridOptions">
  </xpo-ag-grid-board>
</xpo-board>
