import { PlanningDriver } from '@xpo-ltl/sdk-linehauloperations';
import { SpecialServiceGroupCredential } from '../../shared/components/special-service-group';
import { DriverHelper } from '../../shared/helpers/drivers.helper';

export class BidDetails {
  destination: string;
  bid: number;
  miles: number;

  constructor(bidDetails: any) {
    this.destination = `${bidDetails.laneSicCode} - ${bidDetails.sicDescription}`;
    this.bid = bidDetails.lanePriorityNbr;
    this.miles = bidDetails.laneMiles;
  }
}
export class Driver {
  dsrName: string;
  dsrLastName: string;
  dsrCellPhone: string;
  employeeId: string;
  seniorityRankNumber: number;
  jobSelectionCd: string;
  startTmst: string;
  cutTime: string;
  assignedLane: string;
  laneRankNbr: string;
  status: string;
  domicileSicCd: string;
  assignSicCd: string;
  dsrAvailableInd: boolean;
  lnhOriginDispatchDateTime: Date;
  turnTypeCd: string;
  availableStatusCd: string;
  destinationSicCd: string;
  allCredentials: SpecialServiceGroupCredential[] = [];
  shownCredentials: SpecialServiceGroupCredential[] = [];

  constructor(driver: any) {
    const planningDriver: PlanningDriver = driver && driver.planningDriver ? driver.planningDriver : driver;

    this.dsrName = (planningDriver.firstName || '') + ' ' + (planningDriver.lastName || '');
    this.dsrLastName = planningDriver.lastName;
    this.dsrCellPhone = planningDriver.dsrCellPhone;
    this.employeeId = planningDriver.employeeId;
    this.seniorityRankNumber = +planningDriver.seniorityRankNbr;
    this.jobSelectionCd = planningDriver.jobSelectionCd;
    if (planningDriver.shiftStartTimeLocal) {
      const tempTime = planningDriver.shiftStartTimeLocal.split(':');
      this.startTmst = tempTime[0] + ':' + tempTime[1];
    }
    this.availableStatusCd = planningDriver.availableStatusCd;
    this.laneRankNbr = planningDriver.laneRankNbr;
    this.assignSicCd = planningDriver.assignedSicCd?.toUpperCase();
    this.turnTypeCd = planningDriver.turnTypeCd;
    this.dsrAvailableInd = planningDriver.availableInd;
    this.lnhOriginDispatchDateTime = planningDriver.lnhOriginDispatchDateTime;
    if (driver.planningSchedule) {
      this.cutTime = driver.planningSchedule.estimatedDepartDateTime;
      this.assignedLane = driver.planningSchedule.destinationSicCode;
    }
    this.allCredentials = DriverHelper.getAllCredentials(planningDriver.licenseInds);
    this.shownCredentials = DriverHelper.getShownCredentials(planningDriver.licenseInds);

    this.domicileSicCd = planningDriver.domicileSicCd?.toUpperCase();
    this.status = planningDriver.statusCd ?? 'Unassigned';
    this.destinationSicCd = driver.planningSchedule?.destinationSicCode?.toUpperCase();
  }
}
