/**
 *  The purpose of this module is to be used in unit tests
 * */
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  imports: [MatButtonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule],
  exports: [MatButtonModule, MatMenuModule, MatToolbarModule, MatIconModule, MatCardModule],
})
export class MaterialModule {}
