<div
  class="container"
  [ngClass]="[
    showCarrousel && !notImagesFound ? 'all-images' : 'single-image',
    notImagesFound ? 'notImagesFound' : 'imagesFound'
  ]"
>
  <div class="header">
    <span class="title">Trailer Load Detail</span>
  </div>
  <div class="navigate" *ngIf="showCarrousel">
    <a (click)="toggleView()">Load data</a>

    <mat-icon>chevron_right</mat-icon>

    <a class="view-all">VIEW ALL PHOTOS</a>
  </div>
  <div class="container__topItems" *ngIf="showCarrousel && showGallery">
    <app-selected-trailer-info
      class="trailerInfo"
      [containerData]="loadedTrailerSummary"
      [exceptionAlertData]="this.alertData"
    >
    </app-selected-trailer-info>
    <div class="carousel">
      <app-image-carousel
        [containerData]="loadedTrailerSummary"
        [data]="imagesGallery"
        (imageChange)="selectPros($event)"
      ></app-image-carousel>
    </div>
  </div>
  <div class="alert-data" *ngIf="!showCarrousel">
    <div class="thumbnail" *ngIf="showGallery; else noImagesfound">
      <p>{{ imagesGallery[0].name }}</p>

      <img [src]="imagesGallery[0].source" />
      <a (click)="toggleView()">VIEW ALL PHOTOS</a>
    </div>
    <div class="alert-info-container" *ngIf="alertSidePanel$ | async as alertSidePanel">
      <div class="alert-info" *ngIf="alertData?.type === 'HSS'">
        <p class="item-title">Close SIC</p>
        <p class="item-content">
          {{ loadedTrailerSummary?.loadingSic || alertSidePanel?.alert?.operationException?.loggedSic || '-' }}
        </p>
      </div>
      <div class="alert-info" *ngIf="alertData?.type === 'PartialBypass'">
        <p class="item-title">Origin Sic</p>
        <p class="item-content">
          {{ alertData?.origin }}
        </p>
      </div>

      <div class="alert-info">
        <p class="item-title">Destination SIC</p>
        <p class="item-content">{{ alertData?.destination }}</p>
      </div>
      <div class="alert-info">
        <p class="item-title">Closed By</p>
        <p class="item-content">{{ loadedTrailerSummary?.closedByEmployeeName | async }}</p>
      </div>
      <div class="alert-info">
        <p class="item-title">Shipments</p>
        <p class="item-content">{{ loadedTrailerSummary?.totalShipmentsCount || alertData?.shipments }}</p>
      </div>
      <div class="alert-info">
        <p class="item-title">Cube(%)</p>
        <p class="item-content">
          {{ loadedTrailerSummary?.cubeUtilizationPct || alertData?.cube || '-' }}
        </p>
      </div>
      <div class="alert-info">
        <p class="item-title">Weight(Lbs)</p>
        <p class="item-content">{{ loadedTrailerSummary?.weight || alertData?.weight }}</p>
      </div>
      <div class="alert-info" *ngIf="alertData?.type === 'HSS'">
        <p class="item-title">Close Shift</p>
        <p class="item-content">
          {{ loadedTrailerSummary?.shift || alertSidePanel?.alert?.operationException?.shiftCd || '-' }}
        </p>
      </div>
      <div class="alert-info" *ngIf="alertData?.type === 'PartialBypass'">
        <p class="item-title">ETA</p>
        <p class="item-content">
          {{ alertData?.eta }}
        </p>
      </div>

      <div class="alert-info" *ngIf="alertData?.type === 'HSS'">
        <p class=" item-title">Door</p>
        <p class="item-content">{{ loadedTrailerSummary?.door || alertData?.door }}</p>
      </div>

      <div class="alert-info" *ngIf="alertData?.type === 'HSS' && this.trailerDetailsData.statusCd !== 'NewSIC'">
        <p class="item-title">Booking</p>
        <p class="item-content">{{ alertData?.booking || '-' }}</p>
      </div>
    </div>
  </div>
  <cost-details
    *ngIf="showCost && alertData?.type === 'HSS'"
    class="cost-details"
    [data]="costOpportunityDetails"
    [id]="sourceId"
  ></cost-details>
  <div class="prosTable" *ngIf="showGallery">
    <app-related-pros class="relatedPros" [relatedProsList]="relatedProsList" [containerData]="loadedTrailerSummary">
    </app-related-pros>
  </div>
</div>

<ng-template #noImagesfound>
  <div class="noImagesMsg" *ngIf="notImagesFound">Images couldn't be found.</div>
</ng-template>
