export interface IInteractionServiceResp {
  name?: string;
  data?: any;
  timestamp?: Date;
}

export class InteractionServiceResp {
  name?: string;
  data?: any;
  timestamp: Date;
  constructor(name?: string, data?: { [key: string]: any }) {
    this.name = name;
    this.data = data || {};
    this.timestamp = new Date();
  }
}

export class LoadRequestPanelData {
  alertInfo: any;
  loadReqInfo: any;
  action: any;
  statusPanel: boolean = false;
}
