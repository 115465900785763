import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  CalculateScheduleDriverSequenceResp,
  CalculateScheduleDriverSequenceRqst,
  LinehaulOperationsApiService,
  SchedulesMetricsSummary,
} from '@xpo-ltl/sdk-linehauloperations';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// TODO: should be provided at the module level, but the way the app is set up currently, needs to be at root.
@Injectable({ providedIn: 'root' })
export class SchedulesService {
  private readonly periodSubject = new BehaviorSubject<Date>(new Date());
  private readonly shiftSubject = new BehaviorSubject<string>('O');
  private readonly sicCdSubject = new BehaviorSubject<string>(null);
  private readonly schedulesMetricsSubject = new BehaviorSubject<SchedulesMetricsSummary>(null);

  readonly period$ = this.periodSubject.asObservable().pipe(distinctUntilChanged());
  readonly shift$ = this.shiftSubject.asObservable().pipe(distinctUntilChanged());
  readonly sicCd$ = this.sicCdSubject.asObservable().pipe(distinctUntilChanged());
  readonly schedulesMetrics$ = this.schedulesMetricsSubject.asObservable().pipe(distinctUntilChanged());

  get sicCd(): string {
    return this.sicCdSubject.value;
  }
  set sicCd(value: string) {
    this.sicCdSubject.next(value.toUpperCase());
  }

  get period(): Date {
    return this.periodSubject.value;
  }
  set period(value: Date) {
    this.periodSubject.next(value);
  }
  get periodAsDateString(): string {
    return formatDate(this.period, 'yyyy-MM-dd', 'en-US');
  }

  get shift(): string {
    return this.shiftSubject.value;
  }
  set shift(value: string) {
    this.shiftSubject.next(value);
  }

  get schedulesMetrics(): SchedulesMetricsSummary {
    return this.schedulesMetricsSubject.value;
  }
  set schedulesMetrics(value: SchedulesMetricsSummary) {
    this.schedulesMetricsSubject.next(value);
  }

  constructor(private linehaulOperationsApiService: LinehaulOperationsApiService) {}

  reassignLanes$(): Observable<CalculateScheduleDriverSequenceResp> {
    const query = new CalculateScheduleDriverSequenceRqst();
    query.sicCd = this.sicCd;
    query.shiftCd = this.shift;
    query.plannedDate = this.periodAsDateString;
    query.runId = +this.schedulesMetrics?.runId;
    query.assignScheduleDriverSequenceInd = true;

    return this.linehaulOperationsApiService.calculateScheduleDriverSequence(query);
  }

  isToday(): boolean {
    const today = new Date(); // TODO: account for sic date.
    const selected = this.periodSubject.value;
    return (
      today.getDate() === selected.getDate() &&
      today.getMonth() === selected.getMonth() &&
      today.getFullYear() === selected.getFullYear()
    );
  }
}
