import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ComponentsEnum } from '../../enums/components.enum';
import { InteractionService } from '../../services/interaction.service';

@Component({
  selector: 'load-request-panel',
  templateUrl: './load-request-panel.component.html',
  styleUrls: ['./load-request-panel.component.scss'],
})
export class LoadRequestPanel implements OnInit {
  statusPanel: boolean = true;

  constructor(private interactionService: InteractionService) {}

  ngOnInit(): void {}

  closePanel() {
    this.interactionService.setStatusLoadRequestPanel(false);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closePanel();
    }
  }
}
