import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { RICH_TABLE_SELECT_EDITOR_DATA } from './rich-table-select-editor-data-token';

export interface RichTableSelectEditorColumn {
  field: string;
  headerName: string;
  cellRendererFramework?: Type<any>;
  align?: 'start' | 'end';
  widthInPx?: number;
  valueFormatter?: (value: any) => string;
}
export interface RichTableSelectEditorParams extends ICellEditorParams {
  columns: RichTableSelectEditorColumn[];
  values?: Observable<any[]> | any[];
  getValues?: (params: RichTableSelectEditorParams) => Observable<any[]> | any[];
  displayWith: ((value: any) => string) | null;
  searchField?: string;
  hideHeader?: boolean;
  formatValueBeforeSetting?: (value: any | null) => any;
}

@Component({
  selector: 'app-rich-table-select-editor',
  templateUrl: './rich-table-select-editor.component.html',
  styleUrls: ['./rich-table-select-editor.component.scss'],
  host: { class: 'app-RichTableSelectEditor' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RichTableSelectEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  readonly defaultColumnWidth = 100;
  readonly panelPadding = 32;

  inputControl = new FormControl();
  filteredOptions$: Observable<any[]>;
  values: any[];
  params: RichTableSelectEditorParams;
  loading: boolean = true;
  noResults: boolean = false;
  panelWidthInPx: number = 500;
  columns: RichTableSelectEditorColumn[] = [];
  displayWith: ((value: any) => string) | null;
  isDeleteDisabled = false;
  hideHeader = false;

  private searchField: string;
  private options$: Observable<any[]>;
  private selectedValue: any;
  private deletePressed = false;
  private unsubscribe$ = new Subject<void>();

  @ViewChild('input', { read: ViewContainerRef }) input: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef, private injector: Injector) {}

  ngAfterViewInit() {
    setTimeout(() => this.input.element.nativeElement.focus(), 0);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  agInit(params: RichTableSelectEditorParams): void {
    this.params = params;
    this.isDeleteDisabled = !this.params.value;
    this.panelWidthInPx = this.params.columns
      .map((c) => c.widthInPx ?? this.defaultColumnWidth)
      .reduce((p, c) => p + c, this.panelPadding);
    this.columns = this.params.columns;
    this.hideHeader = !!this.params.hideHeader;

    const firstColumnField = this.columns[0].field;
    const values = this.params.values ?? this.params.getValues(params);

    this.displayWith = this.params.displayWith ?? ((value) => (value ? value[firstColumnField] : ''));
    this.searchField = this.params.searchField ?? firstColumnField;
    this.options$ = (values instanceof Observable ? values : of(values ?? [])).pipe(
      tap((v) => {
        this.loading = false;
        this.values = v;
        this.noResults = !v?.length;
        this.cd.markForCheck();
      }),
      shareReplay()
    );

    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      switchMap((search: string) => {
        return this.options$.pipe(
          map((options) => {
            return search && search !== '' && typeof search === 'string'
              ? options.filter((o) => (<string>o[this.searchField]).toLowerCase().includes(search?.toLowerCase()))
              : options;
          })
        );
      }),
      tap((res) => (this.noResults = !res?.length))
    );
  }

  isCancelBeforeStart(): boolean {
    // Sending up false because we always want to show the component on edit
    return false;
  }

  isCancelAfterEnd(): boolean {
    // We are only going to update the cell when the user selects an option or deletes, ignore updating if the user
    // clicks outside of the cell
    return !(this.selectedValue || this.deletePressed);
  }

  removeValue(): void {
    this.deletePressed = true;
    this.selectedValue = null;
    this.params.stopEditing();
  }

  getValue(): any {
    const value = this.values?.find((el) => el === this.selectedValue);

    return this.params.formatValueBeforeSetting ? this.params.formatValueBeforeSetting(value) : value;
  }

  createInjector(data: any): Injector {
    return Injector.create({
      providers: [{ provide: RICH_TABLE_SELECT_EDITOR_DATA, useValue: data }],
      parent: this.injector,
    });
  }

  selectEntity(event: MatAutocompleteSelectedEvent): void {
    this.selectedValue = event.option.value;
    this.params.stopEditing();
  }

  handlePanelClosed(): void {
    this.params.stopEditing();
  }
}
