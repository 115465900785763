<div class="alert-tabs-container">
  <ng-container *ngIf="type === ExceptionTypeCd.LOAD_REQUEST">
    <lr-panel [type]="type"
        [status]="status"
        [alert]="alert"
        [modelData]="modelData"></lr-panel>
  </ng-container>
  <ng-container *ngIf="type === ExceptionTypeCd.GMISLOAD">
    <shipment-details [type]="type"
        [status]="status"
        [alert]="alert"></shipment-details>
  </ng-container>
  <ng-container *ngIf="type === ExceptionTypeCd.PROGRESSIVE_LOAD">
    <prog-load-panel [type]="type"
        [status]="status"
        [alert]="alert"
        [modelData]="modelData"></prog-load-panel>
  </ng-container>
  <ng-container *ngIf="type === ExceptionTypeCd.HSS">
    <hss-panel [type]="type"
        [status]="status"
        [alert]="alert"
        [sidePanelData]="sidePanelData"></hss-panel>
  </ng-container>
  <ng-container *ngIf="type === ExceptionTypeCd.PARTIAL_BYPASS">
    <app-bufi-load-panel [type]="type"
        [status]="status"
        [alert]="alert"
        [sidePanelData]="sidePanelData">
    </app-bufi-load-panel>
  </ng-container>
</div>
