export enum AppRoutes {
  ALERTS_DASHBOARD = 'alerts-dashboard',
  LOAD_MANAGEMENT = 'load-management',
  FLIPS = 'flips',
  NOT_AUTHORIZED_PAGE = 'not-authorized',
  LANES_VIEW = 'lanes-view',
  LOADS_VIEW = 'loads-view',
  CHANGES_VIEW = 'changes-view',
  PERFORMANCE_DASHBOARD = 'performance-dashboard',
  USER_METRICS = 'user-metrics',
  POWER_MANAGEMENT = 'power-management',
  JSP_BIDS = 'jsp-bids',
  DSR_MANAGER = 'dsr-manager',
  EQUIPMENT = 'equipment',
  TRAILERS = 'trailers',
  DOLLIES = 'dollies',
  TRACTORS = 'tractors',
  DRIVERS = 'drivers',
  SCHEDULES = 'schedules',
  DOOR_PLAN = 'door-plan',
  DIVERSION_LANE = 'diversion-lane',
  LOAD_LANE_SUMMARY = 'load-lane-summary',
  NEW_DASHBOARD = 'new-dashboard',
  LOADS_AND_SCHEDULES = 'loads-and-schedules',
  SAAG = 'saag-view',
  INSTRUCTIONS = 'instructions-view',
}
