<div class="side-panel-container" [ngClass]="{ fullscreen: fullscreen }" *ngIf="!panelOptions.isDashboard">
  <div class="side-panel-header">
    <label class="side-panel-label" data-test="sidePanelLabel">Trailer #{{ sidePanelData.sidePanelId }} Photos</label>
    <mat-icon
      class="fullscreen-icon"
      (click)="changeStatus(true)"
      *ngIf="!fullscreen && panelOptions.allowFullScreen"
      data-test="fullscreenButton"
    >
      fullscreen</mat-icon
    >
    <mat-icon class="fullscreen-icon" (click)="changeStatus(false)" *ngIf="fullscreen" data-test="exitFullscreenButton">
      fullscreen_exit</mat-icon
    >
    <mat-icon class="close-button-panel" (click)="closePanel()" data-test="closePanelButton">clear</mat-icon>
  </div>
  <div class="side-panel-body">
    <app-image-gallery-container
      [panelOpts]="panelOptions"
      class="image-gallery-container"
      [sidePanelData]="sidePanelData"
      [fullscreen]="fullscreen"
    >
    </app-image-gallery-container>
  </div>
</div>

<div class="dashboard-container" [ngClass]="{ fullscreen: fullscreen }" *ngIf="panelOptions.isDashboard">
  <div class="dashboard-body">
    <app-image-gallery-container
      [panelOpts]="panelOptions"
      class="image-gallery-container"
      [sidePanelData]="sidePanelData"
      [fullscreen]="fullscreen"
    >
    </app-image-gallery-container>
  </div>
</div>
