import { Component } from '@angular/core';

@Component({
  selector: 'app-status-formatter-cell',
  styleUrls: ['./load-request-status-formatter.scss'],
  template: `
    <div *ngIf="params.value > 0" class="status">
      <mat-icon svgIcon="circle-filled" class="status-icon" [ngClass]="params.status"></mat-icon>
      <span>{{ params.value }}</span>
    </div>
  `,
})
export class LoadRequestStatusFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params.value;
  }
}
