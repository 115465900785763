/* tslint:disable: max-line-length */
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  GetTrailerContentsQuery,
  GetTrailerContentsResp,
  LinehaulOperationsApiService,
} from '@xpo-ltl/sdk-linehauloperations';
import { GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { SpecialServiceFormatterComponent } from '../../../../shared/formatters/ag-grid-cell-formatters/special-service-formatter';
import { ManifestTableLabels } from './../../enums/manifest-table.enum';

@Component({
  selector: 'app-manifest',
  templateUrl: './manifest.component.html',
  styleUrls: ['./manifest.component.scss'],
})
export class ManifestComponent implements OnInit, OnDestroy {
  columnDefs = null;
  shipmentsCollection: any[] = [];
  sizeToFit: boolean = true;
  gridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    frameworkComponents: {
      SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      suppressMenu: true,
    },
    headerHeight: 40,
    rowHeight: 30,
    onGridReady: function(event) {
      this.grid.api.sizeColumnsToFit();
    },
  };
  showTable = false;
  linehaulSubscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private decimalPipe: DecimalPipe,
    private datePipe: DatePipe,
    private linehaulOpApiService: LinehaulOperationsApiService
  ) {}

  ngOnInit() {
    this.columnDefs = this.manifest_table_columns();
    const trailerContentRq = new GetTrailerContentsQuery();
    trailerContentRq.equipmentInstId = this.data.equipmentInstId;
    this.linehaulSubscription = this.linehaulOpApiService
      .getTrailerContents(trailerContentRq)
      .subscribe((resp: GetTrailerContentsResp) => {
        if (resp.loadedTrailerContents) {
          resp.loadedTrailerContents.forEach((load) => {
            this.shipmentsCollection.push(load.shipment);
          });
        } else {
          this.shipmentsCollection = [];
        }
        this.showTable = true;
      });
  }

  manifest_table_columns(): any[] {
    const {
      ProNbr,
      Origin,
      Dest,
      Weight,
      Utilization,
      MotorMoves,
      LoosePieces,
      FreightType,
      ServiceDate,
    } = ManifestTableLabels;
    return [
      {
        headerName: ProNbr,
        field: 'proNbr',
        width: 100,
        valueGetter: (params) => {
          const eraseFirstCero = params.data.proNbr ? params.data.proNbr.replace(/^0/, '') : '';
          const proNbrWithSpace = !!params
            ? `${eraseFirstCero.slice(0, 3)}-${eraseFirstCero.slice(3).replace(/^0/, '')}`
            : '';
          return proNbrWithSpace;
        },
      },
      {
        headerName: Origin,
        field: 'originTerminalSicCd',
        width: 100,
      },
      {
        headerName: Dest,
        field: 'destinationTerminalSicCd',
        width: 100,
      },
      {
        headerName: Weight,
        field: 'totalWeightLbs',
        type: 'numericColumn',
        width: 100,
      },
      {
        headerName: Utilization,
        field: 'pupVolumePercentage',
        valueFormatter: (params) => {
          return (this.decimalPipe.transform(params.data.pupVolumePercentage, '.0-2') || '') + ' %';
        },
        type: 'numericColumn',
        width: 100,
      },
      {
        headerName: MotorMoves,
        field: 'motorizedPiecesCount',
        type: 'numericColumn',
        width: 100,
      },
      {
        headerName: LoosePieces,
        field: 'loosePiecesCount',
        type: 'numericColumn',
        width: 100,
      },
      {
        headerName: FreightType,
        sortable: false,
        valueGetter: (params) => {
          const {
            guaranteedInd,
            freezableInd: frzblInd,
            hazmatInd,
            headLoadInd,
            headLoadDetail,
            misloadInd,
          } = params.data;
          return { guaranteedInd, frzblInd, hazmatInd, headLoadInd, headLoadDetail, misloadInd };
        },
        cellRenderer: 'SpecialServiceFormatterComponent',
        cellClass: 'iconColumn',
        width: 100,
      },
      {
        headerName: ServiceDate,
        field: 'estimatedDeliveryDate',
        valueGetter: (params) => {
          return this.datePipe.transform(params.data.estimatedDeliveryDate, 'MM/dd/yyyy');
        },
        cellClass: 'loadedTimestampColumn',
        type: 'numericColumn',
        width: 100,
      },
    ];
  }

  ngOnDestroy() {
    this.linehaulSubscription.unsubscribe();
  }
}
