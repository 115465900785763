import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardOptions, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoAgGridColumns, XpoInlineEditingBase } from '@xpo-ltl/ngx-ltl-grid';
import { DriverTurnTypeCd } from '@xpo-ltl/sdk-common';
import { GetPlanningDriverMetricsResp } from '@xpo-ltl/sdk-linehauloperations';
import { ColDef, ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { DrawerLauncherService } from '../core/drawer-launcher/drawer-launcher.service';
import { linehaulFrameworkComponents } from '../shared/components/frameworkComponents';
import { ComponentsEnum } from '../shared/enums/components.enum';
import { InteractionService } from '../shared/services/interaction.service';
import { AddDriversDialogComponent } from './add-drivers-dialog/add-drivers-dialog.component';
import { DetailComponent } from './detail/detail.component';
import { DriversDataSourceService } from './drivers-data-source.service';
import { DriversTemplate } from './drivers-template';
import { Driver } from './models/drivers.model';
import { DriversService } from './services/drivers.service';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  providers: [DriversDataSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversComponent extends XpoInlineEditingBase implements OnInit, OnDestroy {
  keyField: string = 'employeeId';
  current_sic: any;
  dsrCount: number;
  dsrRequiredCount: number;
  dsrNeededCount: number;
  dsrDispatchedCount: number;
  dsrAssignedCount: number;
  dsrUnassignedCount: number;
  post_model: boolean;
  dsrColorClass: string;
  show_dispatched: boolean = true;
  show_unavailable: boolean = true;
  gridApi: GridApi;
  gridData: any;
  isEditing: boolean;
  viewTemplates: DriversTemplate[];
  views: XpoBoardView[];
  columnDefs: ColDef[];
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
  };
  unsubscribe = new Subject<void>();
  gridOptions: GridOptions;
  columnApi: ColumnApi;
  differentDate: boolean;
  noResultMessage$: Observable<string>;
  private current_shift: any;

  constructor(
    public dataSource: DriversDataSourceService,
    private driversService: DriversService,
    private dialog: MatDialog,
    private drawerLauncherService: DrawerLauncherService,
    private injector: Injector,
    private timeService: XpoLtlTimeService,
    private interactionService: InteractionService,
    private cd: ChangeDetectorRef
  ) {
    super();
    this.isEditing = false;

    this.dataSource.publicState$.pipe(takeUntil(this.unsubscribe)).subscribe((newState) => {
      const period = new Date(this.dataSource.period).toISOString().split('T')[0];
      const now = new Date().toISOString().split('T')[0];
      this.differentDate = period < now;

      if (newState.data?.consumerData && newState.changes.includes('data')) {
        this.gridData = newState.data.consumerData;

        this.driversService
          .getHeaderDetails()
          .pipe(take(1))
          .subscribe((resp: GetPlanningDriverMetricsResp) => {
            if (
              resp.dsrScheduleSummary?.runId &&
              resp.dsrScheduleSummary?.releaseStatus?.toUpperCase() === 'RELEASED'
            ) {
              this.post_model = true;
              this.dsrNeededCount = resp.dsrScheduleSummary?.dsrNeededCount;
            } else {
              this.post_model = false;
              this.dsrRequiredCount = resp.dsrScheduleSummary?.dsrRequiredCount;
            }
            this.dsrColorClass = this.getDsrColor(this.post_model);
            this.cd.markForCheck();
          });

        this.dsrCount = 0;
        this.dsrDispatchedCount = 0;
        this.dsrAssignedCount = 0;
        this.dsrUnassignedCount = 0;

        for (let i = 0; i < this.gridData.length; i++) {
          const item: Driver = this.gridData[i];

          const isDomAndNotCityTurn: boolean =
            item.assignSicCd === this.current_sic ||
            (item.domicileSicCd === this.current_sic &&
              !item.assignSicCd &&
              item.turnTypeCd !== DriverTurnTypeCd.CITY_TURN);

          if (isDomAndNotCityTurn && item.dsrAvailableInd === true) {
            this.dsrCount = this.dsrCount + 1;
          }

          const driverStatus = item.status.toUpperCase();

          if (
            (driverStatus === 'DISPATCHED' || driverStatus === 'ARRIVED' || driverStatus === 'COMPLETED') &&
            isDomAndNotCityTurn
          ) {
            this.dsrDispatchedCount = this.dsrDispatchedCount + 1;
          }
          if (driverStatus === 'ASSIGNED' && isDomAndNotCityTurn) {
            this.dsrAssignedCount = this.dsrAssignedCount + 1;
          }
          if (item.dsrAvailableInd && isDomAndNotCityTurn && driverStatus === 'AVAILABLE') {
            this.dsrUnassignedCount = this.dsrUnassignedCount + 1;
          }
        }
      }
      this.cd.markForCheck();
    });

    this.columnDefs = [
      {
        ...XpoAgGridColumns.RowIndex,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        resizable: false,
        sortable: false,
        suppressMenu: true,
        pinned: 'left',
        lockPosition: true,
      },
      {
        headerName: '',
        width: 50,
        resizable: false,
        sortable: false,
        suppressMenu: true,
        pinned: 'left',
        lockPosition: true,
        cellRendererSelector: (params) => {
          const icon = {
            component: 'driverIconRendererComponent',
            params: params,
            field: 'statusCd',
          };
          return icon;
        },
      },
      {
        headerName: 'Driver',
        field: 'dsrName',
        width: 400,
        maxWidth: 400,
        suppressMenu: true,
        cellStyle: { color: '#2196F3', cursor: 'pointer' },
        onCellClicked: (params) => {
          this.onCellClicked(params.data);
        },
        cellRenderer: 'driverNameRendererComponent',
        cellRendererParams: {
          getSicCode: () => this.driversService.sic,
        },
      },
      {
        headerName: 'Overall Rank',
        field: 'seniorityRankNumber',
        maxWidth: 180,
        suppressMenu: true,
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        type: 'numericColumn',
        cellRenderer: (params) => {
          if (params.data && params.data.seniorityRankNumber) {
            if (params.data.domicileSicCd === this.current_sic) {
              return params.data.seniorityRankNumber;
            }
          }
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          const isNodeAWkbt =
            nodeA.data.domicileSicCd !== this.current_sic && nodeA.data?.assignSicCd === this.current_sic;
          const isNodeBWkbt =
            nodeB.data.domicileSicCd !== this.current_sic && nodeB.data?.assignSicCd === this.current_sic;

          if (isNodeAWkbt && isNodeBWkbt) {
            return nodeA.data?.dsrName > nodeB.data?.dsrName ? 1 : -1;
          }
          if (isNodeAWkbt || isNodeBWkbt) {
            return 0;
          }
          if (valueA === valueB) {
            return 0;
          }
          return valueA > valueB ? 1 : -1;
        },
      },
      {
        headerName: 'Shift Start Time',
        field: 'startTmst',
        maxWidth: 180,
        suppressMenu: true,
        type: 'numericColumn',
        cellStyle: (params) => {
          if (params.data && params.data.startTmst && params.data.cutTime) {
            if (
              params.data.startTmst > params.data.cutTime &&
              params.data.status.toUpperCase() !== 'DISPATCHED' &&
              (params.data.assignSicCd === this.current_sic || params.data.domicileSicCd === this.current_sic)
            ) {
              return { color: 'red', display: 'flex', justifyContent: 'flex-end' };
            }
          }
          return { color: '#000' };
        },
        cellRenderer: (params) => {
          if (params.data && params.data.startTmst) {
            return params.data.startTmst;
          }
        },
      },
      {
        headerName: 'Cut Time',
        field: 'cutTime',
        maxWidth: 180,
        suppressMenu: true,
        type: 'numericColumn',
        cellStyle: { display: 'flex', justifyContent: 'flex-end' },
        cellRenderer: (params) => {
          if (params.data && params.data.cutTime) {
            return this.timeService.formatDate(params.data.cutTime, 'HH:mm', this.current_sic);
          }
        },
      },
      {
        headerName: 'Dispatch Order',
        field: 'laneRankNbr',
        maxWidth: 180,
        suppressMenu: true,
        type: 'rightAligned',
        cellRenderer: (params) => {
          if (this.current_shift === 'FAC') {
            if (params.data.dispatchOrder && params.data.assignedLane) {
              return params.data.dispatchOrder;
            }
          }
          if (params.data && params.data.laneRankNbr) {
            return params.data.laneRankNbr;
          }
          if (params.data.laneRankNbr === 0) {
            return 0;
          }
        },
      },
      {
        headerName: 'Assigned Lane',
        field: 'assignedLane',
        width: 200,
        maxWidth: 200,
        suppressMenu: true,
        cellRenderer: (params) => {
          return params.data?.destinationSicCd
            ? params.data?.destinationSicCd
            : params.data?.status.toUpperCase() === 'DISPATCHED'
            ? params.data?.assignedLane
            : null;
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        maxWidth: 200,
        suppressMenu: true,
        valueGetter: (params) => {
          if (this.current_shift === 'FAC' && params.data) {
            if (
              params.data.assignSicCd !== this.current_sic &&
              params.data.domicileSicCd !== this.current_sic &&
              !params.data.assignedLane
            ) {
              return 'Enroute';
            }
          }
          if (params.data && params.data.status) {
            if (params.data.status.toUpperCase() === 'ASSIGNED') {
              return 'Assigned';
            } else if (params.data.status.toUpperCase() === 'DISPATCHED') {
              return 'Dispatched';
            } else if (params.data.status.toUpperCase() === 'UNAVAILABLE') {
              return 'Unavailable';
            } else if (params.data.status.toUpperCase() === 'AVAILABLE') {
              return 'Available';
            }
          }
        },
      },
    ];

    this.gridOptions = {
      frameworkComponents: linehaulFrameworkComponents,
      floatingFilter: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false, // TODO for editing, make this true
      },
      pinnedBottomRowData: [],
      getRowStyle: (params) => {
        if (params.data.status.toUpperCase() === 'DISPATCHED' || params.data.status.toUpperCase() === 'UNAVAILABLE') {
          return { 'background-color': '#F6F6F6' };
        }
        return null;
      },
    };

    this.driversService.sic$.pipe(takeUntil(this.unsubscribe)).subscribe((newSic: string) => {
      this.current_sic = newSic;
      this.cd.markForCheck();
    });

    this.driversService.shift$.pipe(takeUntil(this.unsubscribe)).subscribe((newShift) => {
      this.current_shift = newShift;
      this.cd.markForCheck();
    });
  }

  ngOnInit(): void {
    this.interactionService
      .subscribeToComponent(ComponentsEnum.REFRESH_DATA)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((refresh: boolean) => {
        if (refresh) {
          this.dataSource.refresh();
          this.cd.markForCheck();
        }
      });
    this.viewTemplates = [
      new XpoAgGridBoardViewTemplate({
        id: 'drivers',
        name: 'Drivers',
        allowAdditional: false,
        keyField: this.keyField,
        availableColumns: this.columnDefs,
      }),
    ];

    this.views = [
      this.viewTemplates[0].createView({
        closeable: false,
        criteria: {},
        id: 'drivers',
        name: 'Drivers',
      }),
    ] as any;

    this.noResultMessage$ = combineLatest([this.driversService.sic$, this.dataSource.isPilotSic$]).pipe(
      map(([sicCd, isPilotSic]) => {
        if (!sicCd) {
          return 'Please Select a SIC';
        }
        if (!isPilotSic) {
          return 'Feature not enabled for this SIC';
        }
        return 'No results found';
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onCellClicked(driver: any): void {
    this.driversService.driver = driver;
    this.drawerLauncherService.launch({
      component: DetailComponent,
      destroyDrawer$: this.unsubscribe,
      injector: this.injector,
      data: { driver },
      afterClosed: (result) => {
        this.dataSource.refresh();
      },
    });
  }

  onGridReady($event: GridReadyEvent): void {
    this.gridApi = $event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.refreshCells();
    this.columnApi = $event.columnApi;
  }

  toggleUnavailable(event): void {
    if (this.show_unavailable === true) {
      this.show_unavailable = false;
      if (this.show_dispatched === true) {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Dispatched', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      } else {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      }
    } else if (this.show_unavailable === false) {
      this.show_unavailable = true;
      if (this.show_dispatched === true) {
        this.gridApi.setFilterModel(null);
      } else {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Unavailable', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      }
    }
    this.cd.markForCheck();
  }

  toggleDispatched(event): void {
    if (this.show_dispatched === true) {
      this.show_dispatched = false;
      if (this.show_unavailable === true) {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Unavailable', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      } else {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      }
    } else if (this.show_dispatched === false) {
      this.show_dispatched = true;
      if (this.show_unavailable === true) {
        this.gridApi.setFilterModel(null);
      } else {
        const filter = {
          status: { type: 'set', values: ['Available', 'Assigned', 'Dispatched', 'Unassigned'] },
        };
        this.gridApi.setFilterModel(filter);
      }
    }
  }

  handleAddDriver($event: MouseEvent): void {
    const dialogRef = this.dialog.open(AddDriversDialogComponent, {
      data: { gridData: this.gridData },
      disableClose: false,
      width: '90vw',
      height: '90vh',
      maxWidth: '1100px',
      maxHeight: '650px',
    });

    dialogRef.afterClosed().subscribe((resp: any[]) => {
      if (resp && resp.length) {
        this.dataSource.refresh();
        this.cd.markForCheck();
      }
    });
  }

  private getDsrColor(showNeeded: boolean): string {
    return this.dsrCount < (showNeeded ? this.dsrNeededCount : this.dsrRequiredCount) ? 'red' : 'green';
  }
}
