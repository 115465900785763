import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { LoadRequestReasonCode } from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { ActionService } from '../../../../services/action.service';
import { AuthService } from '../../../../services/auth.service';
import { InteractionService } from '../../../../services/interaction.service';
import { SidePanelVisibilityService } from '../../../../services/side-panel-visibility.service';
import { UserRoleService } from '../../../../services/user-role/user-role.service';

@Component({
  selector: 'cancel-request',
  templateUrl: './cancel-request.component.html',
  styleUrls: ['./cancel-request.component.scss'],
})
export class CancelRequestComponent implements OnInit {
  @Input() loadRequestData;
  form: FormGroup;
  cancelReasonCodeFormControl: AbstractControl = new FormControl();
  loadRequestReasonCodes: LoadRequestReasonCode[];
  disabled: boolean = false;

  constructor(
    private actionService: ActionService,
    private fb: FormBuilder,
    private interactionService: InteractionService,
    private loggingApiService: LoggingApiService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private userRoleService: UserRoleService,
    private xpoSnackBar: XpoSnackBar,
    private timeService: XpoLtlTimeService,
    private datePipe: DatePipe,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.initializeForm();
    this.disabled = this.checkExpiredByShift(
      this.loadRequestData.auditInfo.createdTimestamp,
      this.loadRequestData.operationException.loggedSic,
      this.loadRequestData.operationException.shiftCd
    );
    this.interactionService
      .getLoadRequestReasonCodes('CANCEL_CD')
      .subscribe((loadRequestReasonCodes: LoadRequestReasonCode[]) => {
        if (loadRequestReasonCodes && loadRequestReasonCodes.length > 0) {
          this.loadRequestReasonCodes = loadRequestReasonCodes;
        }
      });
  }

  initializeForm() {
    this.form = this.fb.group({
      cancelReasonCode: ['', Validators.required],
    });

    this.cancelReasonCodeFormControl = this.form.get('cancelReasonCode');
  }

  cancelRequest() {
    if (this.form.valid) {
      const request = {
        loadRequest: {
          runId: null,
          reasonCd: this.loadRequestData.operationException.requestReasonCd || null,
          declinedQuantity: null,
          requestorName: `${this.userRoleService.user.givenName} ${this.userRoleService.user.lastName}`,
          declineReasonCd: this.cancelReasonCodeFormControl.value || null,
          linehaulComments: null,
          approveTypeCd: null,
          approvedQuantity: null,
          lhoLoadRequestId: this.loadRequestData.operationException.value || null,
          originSicCode: this.loadRequestData.operationException.loggedSic || null,
          moveToSicCode: this.loadRequestData.operationException.moveToSicCode || null,
          loadToSicCode: this.loadRequestData.operationException.loadToSicCode || null,
          requestQuantity: this.loadRequestData.operationException.loadRequestedQuantity || null,
          requestorEmplid: this.loadRequestData.auditInfo.createdById || null,
          requestorComments: this.loadRequestData.operationException.actualValue || null,
          regionCd: this.loadRequestData.operationException.linehaulRegion || null,
          planDate: this.loadRequestData.operationException.planDate || null,
          planShiftCd: this.loadRequestData.operationException.shiftCd || null,
          auditInfo: this.loadRequestData.auditInfo || null,
          requestType: this.loadRequestData.operationException.exceptionTypeCd || null,
          eligibleFromDateTime: null,
          eligibleToDateTime: null,
          statusCd: ExceptionStatusCd.CANCELLED,
        },
      };

      this.actionService.upsertLoadRequest(request, ExceptionStatusCd.CANCELLED).subscribe(
        () => {
          this.loggingApiService.info(
            this.userRoleService.user.userId +
              ' - upsertLoadRequest ' +
              this.userRoleService.build +
              ' - Request: ' +
              this.loadRequestData.exceptionInstId,
            this.authService.getEmployeeSic(),
            'Cancel of Load Request from Performance Dashboard',
            'PUT',
            ExceptionTypeCd.LOAD_REQUEST
          );
          this.closePanel();
        },
        (err) => {
          this.xpoSnackBar.error(err.error.moreInfo[0].location);
        }
      );
    } else {
      this.xpoSnackBar.error('Please select a reason code');
    }
  }

  private closePanel() {
    this.sidePanelVisibilityService.closePanels();
  }

  private checkExpiredByShift(date: string, sic: string, shift: string): boolean {
    // Sets variables to get proper ranges according to SIC
    const time = new Date(date);
    const now = new Date(this.timeService.formatDate(new Date(), 'YYYY-MM-DDTHH:mm:ss', sic));
    const created = new Date(this.timeService.formatDate(time, 'YYYY-MM-DDTHH:mm:ss', sic));
    const night = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T22:00:00'));
    const morning = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T10:00:00'));
    // Check if it's O created in O shift
    if (created > morning && created < night && shift === 'O') {
      // If outbound, false if now(to sic) is between shift
      return now > morning && now > night;
      // Check if it's an F created in O shift
    } else if (created > morning && created < night && shift === 'F') {
      // If O time, but F shift, false if now(to sic) is between shift (and morning's next day)
      return now < new Date(morning.setDate(morning.getDate() + 1)) ? false : true;
    } else {
      // If F, handle next day limit or current day
      if (created < morning) {
        // Current day, false if now(to sic) is after 10am limit
        return now < morning ? false : true;
      } else {
        // Next day, false if now(to sic) is after next day morning
        return now < new Date(morning.setDate(morning.getDate() + 1)) ? false : true;
      }
    }
  }
}
