import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { XpoAgGridColumns } from '@xpo-ltl/ngx-ltl-grid';
import { PlanningDriver } from '@xpo-ltl/sdk-linehauloperations';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { commonGridVariables } from '../../enums/common-grid-variables';

@Component({
  selector: 'ag-grid-auto-complete',
  encapsulation: ViewEncapsulation.None,
  host: {
    style: `position: absolute;
					left: 0px;
					top: 0px;
					background-color: transparent;
					`,
  },
  template: `
    <input
      #input
      [(ngModel)]="inputValue"
      (ngModelChange)="processDataInput($event)"
      style=" height: 28px; font-weight: 400; font-size: 12px;"
      [style.width]="params.column.actualWidth + 'px'"
    />
    <ag-grid-angular
      style="font-weight: 150;"
      [style.height]="gridHeight + 'px'"
      [style.max-width]="gridWidth + 'px'"
      class="ag-theme-balham"
      [rowData]="rowData"
      [gridOptions]="gridOptions"
      [rowSelection]="rowSelection"
      (gridReady)="onGridReady($event)"
      (rowClicked)="rowClicked($event)"
    >
    </ag-grid-angular>
  `,
})
export class AutocompleteDriverComponent implements ICellEditorAngularComp, ICellRendererAngularComp {
  // variables for grid
  params: any;
  gridApi: any;
  rowData: Partial<PlanningDriver>[];
  rowSelection: string = 'single';
  columnFilter: any;
  // variables for component
  autocompleteDriver = new FormControl('');
  returnObject: boolean;
  cellValue: string;
  inputValue: string;
  gridHeight: number = 175;
  gridWidth: number = 375;
  useApi: boolean;
  propertyName: string;
  isCanceled: boolean = true;
  selectedObject: any = {};

  @ViewChild('input') input: ElementRef;

  gridOptions: GridOptions = {
    columnDefs: [
      XpoAgGridColumns.RowIndex,
      { headerName: 'Name', field: 'firstName' },
      { headerName: 'Domicilie Sic', field: 'assignedSicCd' },
    ],
    rowSelection: 'multiple',
    // onSelectionChanged: this.onSelectionChange.bind(this),
    onGridReady: (params) => {
      this.gridApi = params.api;
    },
    defaultColDef: {
      suppressMenu: true,
      resizable: true,
    },
    rowHeight: commonGridVariables.rowHeight,
    headerHeight: commonGridVariables.headerHeight,
  };

  constructor() {}

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  agInit(params: any): void {
    this.params = params;
    this.rowData = params.rowData;
    if (params.gridHeight) {
      this.gridHeight = params.gridHeight;
    }
    if (params.gridWidth) {
      this.gridWidth = params.gridWidth;
    }
    this.propertyName = params.propertyRendered;
    this.cellValue = params.value[this.propertyName];
    this.returnObject = params.returnObject;

    if (!params.charPress) {
      if (this.cellValue) {
        this.inputValue = this.cellValue;
      }
    } else {
      this.inputValue = params.charPress;
    }
  }

  getValue(): any {
    if (!this.returnObject) {
      return this.selectedObject[this.propertyName];
    }
    return this.selectedObject;
  }

  isPopup(): boolean {
    return true;
  }

  isCancelAfterEnd(): boolean {
    return this.isCanceled;
  }

  // ag-Grid functions
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.columnFilter = this.gridApi.getFilterInstance(this.propertyName);
  }

  // component functions
  rowClicked(params) {
    this.selectedObject = params.data;
    this.isCanceled = false;
    this.params.api.stopEditing();
  }

  rowConfirmed() {
    if (this.gridApi.getSelectedRows()[0]) {
      this.selectedObject = this.gridApi.getSelectedRows()[0];
      this.isCanceled = false;
    }
    this.params.api.stopEditing();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    event.stopPropagation();
    if (event.key === 'Escape') {
      this.params.api.stopEditing();
      return false;
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.rowConfirmed();
      return false;
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      this.navigateGrid();
      return false;
    }
  }

  processDataInput(event) {
    this.updateFilter();
  }

  updateFilter() {
    const filterInstance = this.gridApi.getFilterInstance(this.propertyName);
    const arrayFiltered = this.rowData
      .filter((item) => item.firstName.toLocaleLowerCase().includes(this.inputValue.toLowerCase()))
      .map((item) => item.firstName);
    filterInstance.setModel({
      values: arrayFiltered,
    });

    filterInstance.applyModel();
    this.gridApi.onFilterChanged();
  }

  navigateGrid() {
    if (
      this.gridApi.getFocusedCell() == null ||
      this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex) == null
    ) {
      // check if no cell has focus, or if focused cell is filtered
      this.gridApi.setFocusedCell(this.gridApi.getDisplayedRowAtIndex(0).rowIndex, this.propertyName);
      this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
    } else {
      this.gridApi.setFocusedCell(this.gridApi.getFocusedCell().rowIndex, this.propertyName);
      this.gridApi.getDisplayedRowAtIndex(this.gridApi.getFocusedCell().rowIndex).setSelected(true);
    }
  }
}
