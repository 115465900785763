import { Injectable } from '@angular/core';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { CarrierManagementApiService, UpdatePullCommentRqst } from '@xpo-ltl/sdk-carriermanagement';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import {
  Exception,
  ExceptionManagementApiService,
  ItemQueueDetail,
  ListItemsByApplicationIdQuery,
  ListItemsByApplicationIdResp,
  OperationException,
  UpdateExceptionPath,
  UpdateExceptionRqst,
  UpdateItemStatusResp,
  UpdateItemStatusRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';
import {
  GetEmployeeDetailsByEmpIdPath,
  HumanResourceApiService,
  ListJobCodeRoleAssignmentsQuery,
} from '@xpo-ltl/sdk-humanresource';
import {
  LinehaulOperationsApiService,
  ListLoadRequestReasonCodesResp,
  LoadRequestReasonCode,
} from '@xpo-ltl/sdk-linehauloperations';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { UserRoleService } from '../services/user-role/user-role.service';
import { AuthService } from './auth.service';

const FalsePositive = 'False Positive';

@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private employeeNamesCache: Array<Observable<string>> = [];
  private employeeInfoCache: Array<Observable<any>> = [];
  private listLRReasonCdCache: Observable<LoadRequestReasonCode[]>;
  constructor(
    private exceptionApi: ExceptionManagementApiService,
    private loggingApiService: LoggingApiService,
    private xpoSnackBar: XpoSnackBar,
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private humanResourceApiService: HumanResourceApiService,
    private carrierManagementApiService: CarrierManagementApiService,
    private userRoleService: UserRoleService,
    private authService: AuthService
  ) {}

  getRequestActionByCd(cd: string): Observable<string> {
    if (!this.listLRReasonCdCache) {
      this.listLRReasonCdCache = this.linehaulOperationsApiService
        .listLoadRequestReasonCodes({ loadingOverlayEnabled: false })
        .pipe(
          take(1),
          shareReplay(1),
          map((res: ListLoadRequestReasonCodesResp) => {
            return res.loadRequestReasonCodes;
          })
        );
    }

    return this.listLRReasonCdCache.pipe(
      map((res: LoadRequestReasonCode[]) => {
        const match: LoadRequestReasonCode[] = res.filter((rCd) => rCd.reasonCd === cd);
        return match.length > 0 ? match[0].reasonDescription : '';
      })
    );
  }

  getNameById(id: string): Observable<string> {
    if (!this.employeeNamesCache[id]) {
      const pathParams = new GetEmployeeDetailsByEmpIdPath();
      pathParams.employeeId = id;
      this.employeeNamesCache[id] = this.humanResourceApiService
        .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
        .pipe(
          shareReplay(1),
          map((res) => {
            const fullName =
              res && res.employee && res.employee.basicInfo
                ? `${res.employee.basicInfo.firstName} ${res.employee.basicInfo.lastName}`
                : '';
            return fullName;
          }),
          catchError((err) => {
            return of('-');
          })
        );
    }

    return this.employeeNamesCache[id];
  }

  getInfoById(id: string, hasId: boolean): Observable<any> {
    if (hasId) {
      if (!this.employeeInfoCache[id]) {
        const pathParams = new GetEmployeeDetailsByEmpIdPath();
        pathParams.employeeId = id;
        this.employeeInfoCache[id] = this.humanResourceApiService
          .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: true })
          .pipe(
            map((res) => {
              if (res) {
                const fullName =
                  res && res.employee && res.employee.basicInfo
                    ? `${res.employee.basicInfo.firstName} ${res.employee.basicInfo.lastName}`
                    : '';
                const jobCode =
                  res && res.employee && res.employee.employments
                    ? res.employee.employments[0].jobPositions[0].jobCode
                    : '';
                return { name: fullName, jobCode: jobCode };
              }
            }),
            concatMap((user) => {
              return this.getJobName(user);
            }),
            shareReplay(1),
            catchError((err) => {
              this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
              return of('-');
            })
          );
      }

      return this.employeeInfoCache[id];
    } else {
      return of({ name: 'SYSTEM', role: 'SYSTEM' });
    }
  }

  getJobName(user): Observable<any> {
    const pathParams = new ListJobCodeRoleAssignmentsQuery();
    pathParams.jobCode = user.jobCode;

    return this.humanResourceApiService.listJobCodeRoleAssignments(pathParams).pipe(
      map((res) => {
        if (res) {
          return { name: user.name, role: res.jobCodes[0].role.roleName };
        }
      }),
      shareReplay(1),
      catchError((err) => {
        this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
        return of('-');
      })
    );
  }

  updateException(exception: any, status: string, comment?: string, requestLoadRequest?: any): Observable<any> {
    const updatePath = new UpdateExceptionPath();
    const request = new UpdateExceptionRqst();
    const opExcp = exception.operationException;

    delete opExcp.requestReason;
    updatePath.exceptionInstId = exception.exceptionInstId;
    request.exception = new Exception();
    request.exception.operationException = new OperationException();
    request.exception.operationException = opExcp;
    request.exception.statusCd = status;
    request.exception.exceptionInstId = exception.exceptionInstId;
    request.operationName = status;
    request.statusUpdateComment = comment || '';

    if (requestLoadRequest) {
      request.exception.operationException.loadApprovedQuantity = requestLoadRequest.approvedQuantity || null;
      request.exception.operationException.loadRejectedQuantity = requestLoadRequest.declinedQuantity || null;
      request.exception.operationException.approveReasonCd = requestLoadRequest.approveReasonCd || null;
      request.exception.operationException.cancelReasonCd = requestLoadRequest.declineReasonCd || null;
      request.exception.operationException.approvalTypeCd = requestLoadRequest.approveTypeCd || null;

      if (status !== ExceptionStatusCd.CANCELLED) {
        request.exception.operationException.requestReasonCd = requestLoadRequest.reasonCd || null;
      }
    }

    if (status === FalsePositive) {
      request.operationName = ExceptionStatusCd.CLOSED;
      request.exception.statusCd = ExceptionStatusCd.CLOSED;
      request.exception.operationException.cancelReasonCd = status;
    }

    if (!!comment) {
      request.exception.resolution = comment;
    }

    return this.exceptionApi.updateException(request, updatePath).pipe(
      tap(() => {
        this.loggingApi(
          status,
          exception.exceptionInstId,
          'updateException',
          exception.operationException.exceptionTypeCd,
          'info'
        );
        this.xpoSnackBar.success(`SUCCESS: ${status} submitted!`);
      }),
      catchError((err) => {
        this.loggingApi(
          status,
          exception.exceptionInstId,
          'updateException',
          exception.operationException.exceptionTypeCd,
          'error'
        );
        this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
        return of(false);
      })
    );
  }

  updateHSSException(
    exception: any,
    status: string,
    controlNumber: string,
    bookingNumber: string,
    pullId: string,
    comment?: string,
    pull?: any
  ): Observable<any> {
    const updatePath = new UpdateExceptionPath();
    const request = new UpdateExceptionRqst();
    const opExcp = exception.operationException;
    const updatePullRqst = new UpdatePullCommentRqst();

    delete opExcp.requestReason;
    updatePath.exceptionInstId = exception.exceptionInstId;
    request.exception = new Exception();
    request.exception.operationException = new OperationException();
    request.exception.operationException = opExcp;
    request.exception.statusCd = status;
    request.exception.exceptionInstId = exception.exceptionInstId;
    request.operationName = status;
    request.exception.operationException.controlNumber = controlNumber;
    request.exception.operationException.bookingNumber = bookingNumber;
    request.exception.operationException.pullId = pullId;
    request.exception.resolution = comment || '';
    request.statusUpdateComment = comment || '';

    updatePullRqst.pullId = parseInt(pullId, 10) || null;
    updatePullRqst.booking = parseInt(bookingNumber, 10) || null;
    updatePullRqst.comments = controlNumber || '';

    if ((status === ExceptionStatusCd.APPROVED || status === ExceptionStatusCd.NEW) && bookingNumber) {
      if (status === ExceptionStatusCd.APPROVED) {
        return this.carrierManagementApiService.updatePullComments(updatePullRqst).pipe(
          tap(() => {
            return this.exceptionApi
              .updateException(request, updatePath)
              .pipe(
                tap(() => {
                  this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'info');
                  this.xpoSnackBar.success(`SUCCESS: ${status} submitted!`);
                }),
                catchError((err) => {
                  this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'error');
                  this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
                  return of(false);
                })
              )
              .subscribe();
          }),
          catchError((err) => {
            this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
            return of(false);
          })
        );
      } else {
        return this.carrierManagementApiService.updateTrailerPulls({ pulls: [pull] }).pipe(
          switchMap(() => {
            return this.exceptionApi.updateException(request, updatePath).pipe(
              tap(() => {
                this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'info');
                this.xpoSnackBar.success(`SUCCESS: ${status} submitted!`);
              }),
              catchError((err) => {
                this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'error');
                this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
                return of(false);
              })
            );
          }),
          catchError((err) => {
            this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'error');
            this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
            return of(false);
          })
        );
      }
    } else {
      return this.exceptionApi.updateException(request, updatePath).pipe(
        tap(() => {
          this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'info');
          this.xpoSnackBar.success(`SUCCESS: ${status} submitted!`);
        }),
        catchError((err) => {
          this.loggingApi(status, exception.exceptionInstId, 'updateException', ExceptionTypeCd.HSS, 'error');
          this.xpoSnackBar.error(`ERROR: ${err.error.moreInfo[0].message || err.error.moreInfo[0].location}`);
          return of(false);
        })
      );
    }
  }

  updateItemStatus(status: string, alert: any, comment?: string) {
    const queryParams: ListItemsByApplicationIdQuery = {
      applicationInstId: alert.exceptionInstId.toString(),
      listInfo: null,
      currentStatusCd: null,
      assigneeEmployeeId: null,
      lockedByEmployeeId: null,
      assigneeEmplRoleId: null,
    };
    this.exceptionApi
      .listItemsByApplicationId(queryParams)
      .pipe(
        map((resp: ListItemsByApplicationIdResp) =>
          resp.items.map((itemDetail: ItemQueueDetail) => {
            const { item } = itemDetail;
            const exception: Exception = {
              auditInfo: null,
              operationException: alert.operationException,
              resolvedDateTimeUtc: null,
              nbrOfMinimumBeforeExpiry: null,
              resolution: null,
              listActionCd: null,
              priority: null,
              statusCd: null,
              postedDateTimeUtc: null,
              activeDateTimeUtc: null,
              exceptionInstId: alert.exceptionInstId,
            };
            const updateItemRequest: UpdateItemStatusRqst = {
              itemInstId: item.itemInstId,
              itemStatus: status,
              context: exception,
              statusUpdateComment: comment,
            };
            return this.exceptionApi.updateItemStatus(updateItemRequest);
          })
        ),
        switchMap((requests: Observable<UpdateItemStatusResp>[]) => forkJoin(requests))
      )
      .subscribe();
  }

  loggingApi(status: string, exception: any, action: string, type: string, mode: string) {
    if (mode === 'info') {
      this.loggingApiService.info(
        `${this.userRoleService.user.userId} - ${action}: ${this.userRoleService.build} - status: ${status} - Alert: ${exception}`,
        this.authService.getEmployeeSic(),
        type,
        status,
        action
      );
    } else {
      this.loggingApiService.error(
        `${this.userRoleService.user.userId} - ${action}: ${this.userRoleService.build} - status: ${status} - Alert: ${exception}`,
        this.authService.getEmployeeSic(),
        type,
        status,
        action
      );
    }
  }

  upsertLoadRequest(request: any, status: ExceptionStatusCd): Observable<any> {
    return this.linehaulOperationsApiService.upsertLoadRequest(request).pipe(
      tap((response) => {
        if (response) {
          this.xpoSnackBar.success(`SUCCESS: Load Request ${status}`);
        } else {
          this.xpoSnackBar.error('ERROR: Load request Update failed!');
        }
      })
    );
  }
}
