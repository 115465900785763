import { Equipment } from '@xpo-ltl/sdk-linehauloperations';

export class Dolly {
  dollyNum: string;
  domicileSic: string;
  status: string;

  constructor(equipment: Equipment) {
    this.dollyNum = `${equipment.equipmentIdPrefix.replace(/^0/, '')}-${String(equipment.equipmentIdSuffixNbr).padStart(
      4,
      '0'
    )}`;
    this.status = equipment.currentStatus;
    this.domicileSic = equipment.dmclSicCd;
  }
}
