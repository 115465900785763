import { Component, OnInit } from '@angular/core';
import { XpoBoardOptions, XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { NumericEditorComponent, XpoAgGridColumns, XpoInlineEditingBase } from '@xpo-ltl/ngx-ltl-grid';
import { ColumnApi, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { StatusEnum } from '../dollies/models/status.enum';
import { EquipmentService } from '../services/equipment.service';
import { TractorsDataSourceService } from './services/tractors-data-source.service';
import { TractorsTemplate } from './tractors-template';

@Component({
  selector: 'app-tractors',
  templateUrl: './tractors.component.html',
  styleUrls: ['./tractors.component.scss'],
  providers: [TractorsDataSourceService],
})
export class TractorsComponent extends XpoInlineEditingBase implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridData: any;
  isEditing: boolean;
  keyField: string;
  viewTemplates: TractorsTemplate[];
  columnDefs: any[];
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
  };
  private unsubscribe = new Subject<void>();
  views: XpoBoardView[];
  columnApi: ColumnApi;
  cityCount: any = 0;
  availableCount: any = 0;
  outOfServiceCount: any = 0;

  constructor(public dataSource: TractorsDataSourceService, private equipmentService: EquipmentService) {
    super();
    this.equipmentService.title = 'Tractors';
    this.keyField = 'tractorNum';
    this.dataSource.pageSize = 1000;

    this.dataSource
      .globalFilterChanges()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe();
    this.gridOptions = {
      floatingFilter: false,
      defaultColDef: {
        sortable: true,
        resizable: true,
        editable: false,
      },
      frameworkComponents: {
        numericEditor: NumericEditorComponent,
      },
    };

    this.dataSource.publicState$.pipe(takeUntil(this.unsubscribe)).subscribe((newState) => {
      if (newState.data && newState.data.consumerData) {
        this.gridData = newState.data.consumerData;
        const count = this.gridData.reduce(
          (counter, { currentStatus }) => {
            counter[currentStatus] += 1;
            return counter;
          },
          { AVAL: 0, CITY: 0, OUTOFSERVICE: 0 }
        );
        this.availableCount = count.AVAL;
        this.cityCount = count.CITY;
        this.outOfServiceCount = count.OUTOFSERVICE;
      }
    });

    this.columnDefs = [
      XpoAgGridColumns.RowIndex,
      {
        headerName: 'Tractor',
        maxWidth: 180,
        suppressMenu: true,
        valueGetter: (params) => {
          return params && params.data ? params.data.equipmentIdPrefix + params.data.equipmentIdSuffixNbr : '';
        },
      },
      {
        headerName: 'Manufacture',
        field: '',
        maxWidth: 180,
        suppressMenu: true,
      },
      {
        headerName: 'Year',
        field: 'mfrYr',
        maxWidth: 180,
        suppressMenu: true,
      },
      {
        headerName: 'Miles Odometer',
        field: '',
        maxWidth: 180,
        suppressMenu: true,
      },
      {
        headerName: 'Domicile SIC',
        field: 'dmclSicCd',
        maxWidth: 180,
        suppressMenu: true,
      },
      {
        headerName: 'Status',
        field: 'currentStatus',
        maxWidth: 180,
        suppressMenu: true,
        valueGetter: (params) => {
          if (params && params.data) {
            return StatusEnum[params.data.currentStatus];
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Maintenance',
        field: 'pmDueDate',
        maxWidth: 180,
        suppressMenu: true,
      },
    ];
  }

  ngOnInit() {
    this.viewTemplates = this.getBoardViewTemplates();
    this.views = [
      this.viewTemplates[0].createView({
        closeable: false,
        criteria: {},
        id: 'tractors',
        name: 'Tractors',
      }) as any,
    ];
  }

  private getBoardViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'tractors',
        name: 'Tractors',
        allowAdditional: false,
        keyField: 'tractorNum',
        availableColumns: this.columnDefs,
      }),
    ];
  }

  onGridReady($event: GridReadyEvent) {
    this.gridApi = $event.api;
    this.gridApi.setColumnDefs(this.columnDefs);
    this.gridApi.refreshCells();
    this.columnApi = $event.columnApi;
  }
}
