import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalFilters } from '../../models/global-filters';
import { RegionWithSics } from '../../models/region-with-sics.model';

@Component({
  selector: 'app-region-filter',
  templateUrl: './region-filter.component.html',
  styleUrls: ['./region-filter.component.scss'],
})
export class RegionFilterComponent implements OnInit {
  @Input() regionsListWithSics: RegionWithSics[] = [];
  @Input() globalFilters: GlobalFilters;

  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  valueChanged($event) {
    this.valueChange.emit($event);
  }
}
