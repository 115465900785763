<xpo-ltl-shell-banner slot="banner"
    levet="warn"></xpo-ltl-shell-banner>
<xpo-shell class="app-Container"
    [class.no-scroll]="selectedTab$ | async"
    [routes]="routes"
    [drawerClosedByDefault]="true"
    [versionNumber]="build">
  <xpo-header-app-name>Edge</xpo-header-app-name>
  <xpo-header-sub-app-name>Linehaul</xpo-header-sub-app-name>
  <xpo-header-items class="app-Root-headerItems">
    <mat-tab-group [selectedIndex]="selectedTab$ | async"
        (click)="routingClick($event)">
      <mat-tab label="Dashboard">
        <ng-template mat-tab-label>Dashboard</ng-template>
      </mat-tab>
      <mat-tab label="Loads">
        <ng-template mat-tab-label>Loads</ng-template>
      </mat-tab>
      <!--       <mat-tab label="Drivers">
        <ng-template mat-tab-label>Drivers</ng-template>
      </mat-tab>
      <mat-tab label="Schedules">
        <ng-template mat-tab-label>Schedules</ng-template>
      </mat-tab> -->
      <mat-tab label="Door Plan"
          disabled>
        <ng-template mat-tab-label>
          <span [matMenuTriggerFor]="doorplan">Door Plan</span>
          <mat-menu #doorplan="matMenu">
            <button mat-menu-item
                *ngFor="let item of menuItems">
              <span (click)="routingClick(item.url)">{{ item.label }}</span>
            </button>
          </mat-menu>
        </ng-template>
      </mat-tab>
      <mat-tab label="User Metrics">
        <ng-template mat-tab-label>User Metrics</ng-template>
      </mat-tab>
    </mat-tab-group>
    <refresh-data [route]="routeSelected"></refresh-data>
  </xpo-header-items>
  <xpo-header-actions class="app-Root-headerActions">
    <xpo-account-popover *ngIf="accountPopoverConfig"
        [config]="accountPopoverConfig"></xpo-account-popover>
    <xpo-app-switcher-popover [applications]="apps$ | async"></xpo-app-switcher-popover>
  </xpo-header-actions>
  <app-global-header></app-global-header>
  <mat-drawer-container [hasBackdrop]="false"
      class="app-Root-drawerContainer">
    <mat-drawer position="end">
      <app-drawer></app-drawer>
    </mat-drawer>
    <router-outlet></router-outlet>
  </mat-drawer-container>
</xpo-shell>
<xpo-footer [buildVersion]="build"
    [trainingResourcesLink]="trainingResources"
    [releaseNotesLink]="releaseNotes">
</xpo-footer>
<app-notification *ngIf="loginSuccessful"></app-notification>
