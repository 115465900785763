import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SchedulesGridItemDetailStatus } from '../../schedules-grid.model';

@Component({
  selector: 'app-schedule-status-cell-renderer',
  templateUrl: './schedule-status-cell-renderer.component.html',
  styleUrls: ['./schedule-status-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleStatusCellRendererComponent implements ICellRendererAngularComp {
  status: SchedulesGridItemDetailStatus;
  statusMap: Record<SchedulesGridItemDetailStatus, { icon: string; tooltip: string }> = {
    [SchedulesGridItemDetailStatus.ARIV]: { icon: 'flag-race', tooltip: 'Arrived' },
    [SchedulesGridItemDetailStatus.DISP]: { icon: 'people-forward', tooltip: 'Dispatched' },
    [SchedulesGridItemDetailStatus.COMP]: { icon: 'checkmark', tooltip: 'Completed' },
    [SchedulesGridItemDetailStatus.TERM]: { icon: 'people-outline', tooltip: 'Terminated' },
  };

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.status = params.value;
  }
}
