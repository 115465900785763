import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Unsubscriber, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  GetEmployeeDetailsByEmpIdPath,
  GetEmployeeDetailsByEmpIdResp,
  HumanResourceApiService,
} from '@xpo-ltl/sdk-humanresource';
import {
  LinehaulOperationsApiService,
  LoadedTrailerImageDetail,
  LoadedTrailerProDetail,
  LoadedTrailerSearchFilter,
  LoadedTrailerSearchRecord,
  SearchLoadedTrailerImagesResp,
  SearchLoadedTrailerImagesRqst,
} from '@xpo-ltl/sdk-linehauloperations';
import { ListTrailerCostOpportunitiesResp } from '@xpo-ltl/sdk-performancegoals';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { ImageCarousel } from '../../../image-carousel/image-carousel.model';
import { PerformanceService } from '../../../performance-dashboard/services/performance.service';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { SidePanelType } from '../../../shared/enums/side-panel-type.enum';
import { InteractionService } from '../../../shared/services/interaction.service';
import { SidePanelVisibilityService } from '../../../shared/services/side-panel-visibility.service';
import { EnumUtil } from '../../../utils/enum-utils';
import { TrailerDetailsData } from '../models/trailer-details-data.model';
import { LoadedTrailerSummary } from '../trailer-details-inside/selected-trailer-info/selected-trailer-info.model';

@Component({
  selector: 'app-trailer-details-inside',
  templateUrl: './trailer-details-inside.component.html',
  styleUrls: ['./trailer-details-inside.component.scss'],
})
export class TrailerDetailsInsideComponent implements OnInit, OnDestroy {
  @Input() trailerDetailsData: TrailerDetailsData;
  @Input() alertData: any;
  @Output() trailerNumber = new EventEmitter<string>();
  sourceId: number;
  originSic: any;
  filterSic: any;
  showCarrousel: boolean = false;
  relatedProsList: LoadedTrailerProDetail[] = [];
  notImagesFound: boolean = true;
  loadedTrailerImages: LoadedTrailerImageDetail[] = [];
  loadedTrailerSummary: LoadedTrailerSummary;
  imagesGallery: ImageCarousel[];
  showGallery: boolean = false;
  moment = moment;
  sicCdTimeZone = '';
  selectedSic: string = '';
  alertSidePanel$: Observable<any>;
  hideCubeAbove80Sub: Subscription;
  costOpportunityDetails: ListTrailerCostOpportunitiesResp;
  showCost: boolean;
  searchLoadedTrailerImagesResp: SearchLoadedTrailerImagesResp;
  trailerImages = [];
  trailerCloseImages = [];
  value: any = [];
  ldTrailerInstId: any;
  latestCloseImages: any;

  private unsubscriber = new Unsubscriber();

  constructor(
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private timeService: XpoLtlTimeService,
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private interactionService: InteractionService,
    private humanResourceApiService: HumanResourceApiService,
    private performanceService: PerformanceService
  ) {}

  ngOnInit() {
    this.sourceId = this.alertData.exceptionInstId;
    this.originSic = this.alertData.origin;
    this.searchImage();

    this.alertSidePanel$ = this.sidePanelVisibilityService
      .subscribeToComponent(SidePanelType.alertSidePanel)
      .pipe(take(1));

    this.hideCubeAbove80Sub = this.interactionService
      .subscribeToComponent(ComponentsEnum.HIDE_CUBE_ABOVE_80)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((hssAbove80ToggleOn) => {
        const isCubeAbove80: boolean = this.alertData.cube > 80;
        if (hssAbove80ToggleOn && isCubeAbove80) {
          this.sidePanelVisibilityService.closePanels();
        }
      });
    this.getCostOpportunities(this.sourceId);
  }
  ngOnDestroy() {
    this.unsubscriber.complete();
  }

  getCostOpportunities(exceptionInstId: number) {
    this.performanceService.getCostOpportunities(exceptionInstId).subscribe(
      (resp: ListTrailerCostOpportunitiesResp) => {
        this.costOpportunityDetails = resp;
        this.showCost = true;
      },
      (error) => {
        // As requested in ticket DLC-5003 should not show up this toast message for the user.
        // this.xpoSnackBar.error('ERROR: Cannot get cost opportunity details!');
      }
    );
  }

  /**
   * Get image from api
   */
  searchImage(): void {
    const loadedTimestamp = this.trailerDetailsData.loadedTimestamp;
    const closeUTCTmst = this.trailerDetailsData.closeTmst;
    const status = this.trailerDetailsData.statusCd;
    const loadingDate = this.getMinAndMax(status, closeUTCTmst, loadedTimestamp);
    const filter = new LoadedTrailerSearchFilter();
    const request = new SearchLoadedTrailerImagesRqst();
    const loadingSic = { equals: this.trailerDetailsData.filterSic };
    const origin = { equals: this.originSic };

    const date = new XrtAttributeFilter();

    const startDate = this.moment(this.trailerDetailsData.currentEventTmst)
      .subtract(4, 'days')
      .startOf('day')
      .utc()
      .format('MM/DD/YYYY HH:mm:ss');
    const endDates = this.moment(this.trailerDetailsData.currentEventTmst)
      .utc()
      .format('MM/DD/YYYY HH:mm:ss');

    filter.q = this.trailerDetailsData.load;

    filter.loadingDate = loadingDate;
    const reg = /^[0-9_@./#&+-]*$/.test(filter.q);
    filter.loadingSic = reg ? (origin as XrtAttributeFilter) : (loadingSic as XrtAttributeFilter);

    this.filterSic = filter.loadingSic;
    date.min = startDate;
    date.max = endDates;
    filter.loadingDate = date;
    request.filter = filter;
    this.getImages(request);
  }

  toggleView(): void {
    this.showCarrousel = !this.showCarrousel;
  }

  getImages(request: SearchLoadedTrailerImagesRqst): void {
    this.linehaulOperationsApiService.searchLoadedTrailerImages(request).subscribe((searchResponse) => {
      if (searchResponse.result.length) {
        const loadedTrailerSearchRecord = searchResponse.result;
        loadedTrailerSearchRecord.forEach((loadedtrailer) => {
          this.value.push(loadedtrailer.loadedTrailerInstId);
          this.ldTrailerInstId = this.value.reduce((a, b) => (b > a ? b : a));
          if (
            loadedtrailer.loadedTrailerInstId === this.ldTrailerInstId &&
            this.alertData.trailer === loadedtrailer.trailerNbr
          ) {
            this.loadedTrailerSummary = this.trailerSummaryMapper(loadedtrailer);
          }
          const image = loadedtrailer.images;
          image.forEach((img) => {
            if (this.alertData.trailer === loadedtrailer.trailerNbr && img.imageType !== 'OpenPhoto') {
              this.trailerImages.push(img);
            }
          });
          this.trailerNumber.emit(loadedtrailer.trailerNbr);
        });

        // sort the images in descending order based on the fullPhotoDocumentId
        this.trailerImages.sort((a, b) => 0 - (a.fullPhotoDocumentId > b.fullPhotoDocumentId ? 1 : -1));
        this.setPROSTimeByOriginSic(this.trailerImages);
        this.imagesMapper();

        if (!this.showCarrousel) {
          this.selectPros(0);
        }
        this.notImagesFound = false;
      } else {
        this.notImagesFound = true;
        this.trailerNumber.emit('');
      }
    });
  }

  /**
   * Map images to show on image gallery
   */
  imagesMapper() {
    this.imagesGallery = [];
    if (this.loadedTrailerImages.length) {
      this.loadedTrailerImages.forEach((img, index) => {
        const imageGallery: ImageCarousel = {
          id: index,
          name: img.imageType,
          source: 'data:image/jpeg;base64,' + img.thumbnail.replace(/[\r\n]/g, ''),
          date: img.imageTakenTimestamp,
        };
        this.imagesGallery.push(imageGallery);
      });

      this.showGallery = true;
    }
  }

  /**
   * Map data for selected trailer info
   */
  trailerSummaryMapper(loadedTrailerSearchRecord: LoadedTrailerSearchRecord) {
    return {
      loadingSic: loadedTrailerSearchRecord.loadingSic,
      loadDestinationSic: loadedTrailerSearchRecord.loadDestinationSic,
      shift: loadedTrailerSearchRecord.shift,
      door: loadedTrailerSearchRecord.door,
      status: this.getTrailerStatus(loadedTrailerSearchRecord.status),
      totalShipmentsCount: this.totalShipments(loadedTrailerSearchRecord.images),
      cubeUtilizationPct: loadedTrailerSearchRecord.cubeUtilizationPct,
      weight: loadedTrailerSearchRecord.weight,
      closedByEmployeeName: this.getEmployeeName(loadedTrailerSearchRecord.closedByEmployeeId),
    };
  }

  getEmployeeName(empId: string) {
    const pathParams = new GetEmployeeDetailsByEmpIdPath();
    pathParams.employeeId = empId;
    return this.humanResourceApiService
      .getEmployeeDetailsByEmpId(pathParams, null, { loadingOverlayEnabled: false })
      .pipe(
        catchError(() => of('NOT AVAILABLE')),
        take(1),
        map((resp: GetEmployeeDetailsByEmpIdResp) => {
          if (!!resp.employee.basicInfo && !!resp.employee.basicInfo.firstName) {
            return resp.employee.basicInfo.firstName + ' ' + resp.employee.basicInfo.lastName;
          } else {
            return 'NOT AVAILABLE';
          }
        })
      );
  }

  selectPros(selectedIndex: number) {
    this.relatedProsList = this.loadedTrailerImages[selectedIndex].pros;
  }

  getTrailerStatus(status: string) {
    return EnumUtil.getTrailerStatus(status);
  }

  setPROSTimeByOriginSic(loadedTrailerImages) {
    this.timeService.timezoneForSicCd$(this.filterSic).subscribe((timestamp) => {
      this.sicCdTimeZone = timestamp;
      loadedTrailerImages.forEach((image: any) => {
        if (!!image.pros.length) {
          image.pros.map((pro) => {
            const parseSicDate = this.moment(
              this.timeService.formatDate(pro.loadedTimestamp, 'MM-DD-YYYY HH:mm', this.filterSic)
            ).tz(this.sicCdTimeZone);
            pro.loadedTimestamp = parseSicDate._i;
          });
        }
      });
      this.loadedTrailerImages = loadedTrailerImages;
    });
  }

  private totalShipments(images): number {
    const totalClosedShipments = images.filter((image) => image.imageType === 'ClosePhoto');
    if (totalClosedShipments && totalClosedShipments.length === 0) {
      const lastImg = images[images.length - 1];
      return lastImg && lastImg.pros ? lastImg.pros.length : 0;
    } else {
      return totalClosedShipments[totalClosedShipments.length - 1].pros.length;
    }
  }

  /**
   * Set range for load and close datetime
   */
  getMinAndMax(status, closeUTCTmst, loadedTimestamp): XrtAttributeFilter {
    const date = new XrtAttributeFilter();

    if (status === 'Closed' || status === 'Overhead' || status === 'Enroute') {
      date.min = moment(closeUTCTmst)
        .utc()
        .add(-1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
      date.max = moment(closeUTCTmst)
        .utc()
        .add(1, 'hours')
        .format('MM/DD/YYYY HH:mm:ss');
    } else {
      if (status === 'Exception') {
        date.min = moment(closeUTCTmst)
          .utc()
          .add(-2, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(closeUTCTmst)
          .utc()
          .add(4, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      } else {
        date.min = moment(loadedTimestamp)
          .utc()
          .add(-1, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
        date.max = moment(loadedTimestamp)
          .utc()
          .add(6, 'hours')
          .format('MM/DD/YYYY HH:mm:ss');
      }
    }

    return date;
  }
}
