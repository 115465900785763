<div class="app-TrailerNameCellRenderer-dataRow">
  <span>{{name}}</span>
  <xpo-status-indicator *ngIf="currentStatus"
      isLabel="true"
      [statusColor]="currentStatus.color"
      [statusText]="currentStatus.text">
  </xpo-status-indicator>
  <ng-container *ngFor="let ind of indicators">
    <xpo-ssi *ngIf="ind.icon else showText"
        [name]="ind.icon"></xpo-ssi>
    <ng-template #showText><span>{{ ind }}</span></ng-template>
  </ng-container>
</div>
<div class="app-TrailerNameCellRenderer-dataRow">
  <div *ngIf="cuLoaded != null"
      class="app-TrailerNameCellRenderer-metric">
    <span>CB</span><span>{{ cuLoaded }}%</span>
  </div>
  <div *ngIf="loadedWeight != null"
      class="app-TrailerNameCellRenderer-metric">
    <span>WT</span><span>{{ loadedWeight }} lb.</span>
  </div>
</div>
