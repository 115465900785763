import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShiftOption } from '../../models/shift-options';

@Component({
  selector: 'app-shift-filter',
  templateUrl: './shift-filter.component.html',
  styleUrls: ['./shift-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftFilterComponent {
  shiftOptions: ShiftOption[] = [
    { title: 'Outbound', code: 'O' },
    { title: 'FAC', code: 'F', disabled: true },
  ];

  @Input() shift = this.shiftOptions[1].code;

  @Output() valueChange = new EventEmitter();
}
