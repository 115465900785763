import { Component, OnInit } from '@angular/core';
import { XpoBoardOptions } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardView, XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { InstructionsBoardDataSourceService } from './instructions-board-data-source.service';

export interface InstructionsGridData {
  sicCode: string;
  loadsOffDockCount: number;
  bypassLoadsCount: number;
  loadsTotal: number;
  dsrCount: number;
  diversionsPercent: number;
  weightPlanned: number;
  cubePlannedPercent: number;
  loadAveragePlanned: string;
  trailerUtilizationCapabilityPercent: number;
  emptiesCount: number;
  overagesCount: number;
  viasIncoming: string; // TODO: figure out if this is an array or not? could be multiple sics? would overflow the grid
  viasOutgoing: string;
  sectorCode: string; // at this level do we filter on sector?
}

@Component({
  selector: 'app-instructions-board',
  templateUrl: './instructions-board.component.html',
  providers: [InstructionsBoardDataSourceService],
  styleUrls: ['./instructions-board.component.scss'],
})
export class InstructionsBoardComponent implements OnInit {
  boardOptions: XpoBoardOptions = {
    suppressViewSwitcher: true,
    suppressGridSettingsPopover: true,
  };
  viewTemplates: XpoAgGridBoardViewTemplate[];
  views: XpoAgGridBoardView[];

  constructor(public dataSource: InstructionsBoardDataSourceService) {}

  ngOnInit(): void {
    this.viewTemplates = this.getViewTemplates();
    this.views = this.getViews(this.viewTemplates[0]);
  }

  private getViewTemplates(): XpoAgGridBoardViewTemplate[] {
    return [
      new XpoAgGridBoardViewTemplate({
        id: 'instructionsBoardTemplate',
        name: 'instructionsBoardTemplate',
        availableColumns: [
          {
            headerName: 'SIC',
            field: 'sicCode',
          },
          {
            headerName: 'Loads',
            field: 'loadsOffDockCount',
            type: 'numericColumn',
          },
          {
            headerName: 'By Pass',
            field: 'bypassLoadsCount',
            type: 'numericColumn',
          },
          {
            headerName: 'Total',
            field: 'loadsTotal',
            type: 'numericColumn',
          },
          {
            headerName: 'DSRs',
            field: 'dsrCount',
            type: 'numericColumn',
          },
          {
            headerName: 'Diversions',
            field: 'diversionsPercent',
          },
          {
            headerName: 'Weight',
            field: 'weightPlanned',
          },
          {
            headerName: 'Cube',
            field: 'cubePlannedPercent',
          },
          {
            headerName: 'TUC',
            field: 'trailerUtilizationCapabilityPercent',
          },
          {
            headerName: 'Empties',
            field: 'emptiesCount',
            type: 'numericColumn',
          },
          {
            headerName: 'Overages',
            field: 'overagesCount',
            type: 'numericColumn',
          },
          {
            headerName: 'VIAs',
            field: 'viasIncoming',
          },
          {
            headerName: 'VIAs',
            field: 'viasOutgoing',
          },
        ],
      }),
    ];
  }

  private getViews(viewTemplate: XpoAgGridBoardViewTemplate): XpoAgGridBoardView[] {
    return [viewTemplate.createView({ id: 'instructionsBoardTemplate', name: 'instructionsBoardTemplate' })];
  }
}
