import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ExceptionLocationTypeCd,
  ExceptionStatusCd,
  ExceptionSummaryCd,
  ExceptionSummaryTypeCd,
  UserRoleCd,
} from '@xpo-ltl/sdk-common';
import {
  ExpectationSearchFilter,
  ListLinehaulExceptionsQuery,
  ListSummarizedExceptionsByTypeQuery,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { format, isSameDay } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { HierarchyRegion } from '../performance-dashboard/models/hierarchy-region';
import { AuthService } from '../shared/services/auth.service';
import { RoleView, SummarySelection } from './alert-and-exception-header/alert-and-exception-header.component';
import { DialInfo } from './dial-list/dial-list.component';
import { DialNames } from './dial-list/dial-names.enum';
import { ExceptionAndAlertService } from './services/exception-and-alert.service';
import { LinehaulStateService } from './services/linehaul-state.service';
import { PushNotificationService } from './services/push-notification.service';

export interface AlertsAndExceptionData {
  group: 'Loads' | 'Shipment' | 'Power Management';
  values: any[];
}

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.scss'],
  providers: [PushNotificationService],
})
export class NewDashboardComponent implements OnInit, OnDestroy {
  userRoleCd: UserRoleCd;
  dialList$: Observable<DialInfo[]>;
  currentDate = format(new Date(), 'dd-MMM-yy').toLocaleUpperCase();
  alertsAndExceptions$: Observable<AlertsAndExceptionData[]>;
  alertAndExceptionQuery: ListSummarizedExceptionsByTypeQuery = new ListSummarizedExceptionsByTypeQuery();
  boardQuery$: Observable<ListLinehaulExceptionsQuery>;
  includeHSS$ = new BehaviorSubject<boolean>(false);
  showAllAlerts$ = new BehaviorSubject<boolean>(false);
  roleView$ = new ReplaySubject<RoleView>();
  selectedDial: string;

  private destroyed$ = new Subject<void>();

  constructor(
    private exceptionAndAlertService: ExceptionAndAlertService,
    private authService: AuthService,
    private linehaulStateService: LinehaulStateService,
    private pushService: PushNotificationService
  ) {}

  ngOnInit(): void {
    this.roleView$.next(this.authService.isLhUSerOrSicUser());
    this.alertsAndExceptions$ = combineLatest([
      combineLatest([
        this.linehaulStateService.listen('selectedLocations'),
        this.linehaulStateService.listen('selectedPeriod'),
        this.roleView$,
        this.linehaulStateService.listen('dashboardSelectedHierarchy'),
      ]).pipe(tap(() => this.resetContent())),
      this.showAllAlerts$,
      this.linehaulStateService.refresh$.pipe(startWith(true)),
      this.pushService.changedDocuments$().pipe(startWith(true)),
    ]).pipe(
      switchMap(([[locations, period, roleView, hierarchy], showAllAlerts]) => {
        this.userRoleCd = this.authService.isSuperUser()
          ? UserRoleCd.MANAGER_SUPERVISOR_LINEHAUL
          : this.authService.getUserRoleCd();
        this.alertAndExceptionQuery.planDate = this.generatePlanDate(period);
        this.alertAndExceptionQuery.userRoleCd = this.userRoleCd;
        if (hierarchy === HierarchyRegion.HSS_REGION) {
          this.includeHSS$.next(true);
          this.alertAndExceptionQuery.exceptionSummaryCd = ExceptionSummaryCd.LOAD;
          this.alertAndExceptionQuery.exceptionSummaryTypeCd = ExceptionSummaryTypeCd.EXCEPTION;
          this.alertAndExceptionQuery.exceptionStatusCds = [ExceptionStatusCd.NEW, ExceptionStatusCd.NEW_SIC];
          this.alertAndExceptionQuery.exceptionLocationTypeCd = this.getExceptionLocationTypeCd(
            hierarchy,
            locations?.length
          );
        } else {
          this.includeHSS$.next(false);
          this.alertAndExceptionQuery.exceptionStatusCds =
            roleView === 'sic'
              ? [ExceptionStatusCd.NEW_SIC, ExceptionStatusCd.ACKNOWLEDGED, ExceptionStatusCd.UNDER_REVIEW_LH]
              : [
                  ExceptionStatusCd.NEW,
                  ExceptionStatusCd.NEW_SIC,
                  ExceptionStatusCd.ACKNOWLEDGED,
                  ExceptionStatusCd.UNDER_REVIEW_LH,
                ];
        }

        this.alertAndExceptionQuery.exceptionLocationTypeCd = this.getExceptionLocationTypeCd(
          hierarchy,
          locations?.length
        );
        this.alertAndExceptionQuery.locationFilters = locations;

        this.setNotificationFilters(locations);

        if (showAllAlerts) {
          this.alertAndExceptionQuery.exceptionStatusCds.push(
            ExceptionStatusCd.ACKNOWLEDGED,
            ExceptionStatusCd.UNDER_REVIEW_LH,
            ExceptionStatusCd.EXPIRED,
            ExceptionStatusCd.CLOSED,
            ExceptionStatusCd.RESOLVED,
            ExceptionStatusCd.EXPIRED
          );
        }
        return this.exceptionAndAlertService.getAlertHeaderCounts(this.alertAndExceptionQuery, false).pipe(
          map((data) => {
            const alertsAndExceptions: AlertsAndExceptionData[] = [
              { group: 'Loads', values: [] },
              { group: 'Shipment', values: [] },
              { group: 'Power Management', values: [] },
            ];

            data.forEach((group, i) => {
              const values = {};
              group.alerts.map((alert) => {
                values['alert' + alert.type] = alert.count;
              });
              group.exceptions.map((exception) => {
                values['exception' + exception.type] = exception.count;
              });

              group.hssExceptions?.map((hss) => {
                values['hss' + hss.type] = hss.count;
              });

              alertsAndExceptions[i].values = [values];
            });

            return alertsAndExceptions;
          })
        );
      })
    );
  }

  handleTypeSelection(selection: SummarySelection): void {
    this.dialList$ = combineLatest([
      this.linehaulStateService.listen('selectedLocations'),
      this.linehaulStateService.listen('selectedPeriod'),
      this.includeHSS$,
      this.linehaulStateService.listen('dashboardSelectedHierarchy'),
      this.linehaulStateService.refresh$.pipe(startWith(true)),
      this.pushService.changedDocuments$().pipe(startWith(true)),
    ]).pipe(
      switchMap(([locations, period, hss, hierarchy]) => {
        const dialQuery = new ListSummarizedExceptionsByTypeQuery();
        dialQuery.planDate = this.generatePlanDate(period);
        dialQuery.userRoleCd = this.userRoleCd;
        dialQuery.exceptionSummaryCd = selection.exceptionSummaryCd;
        dialQuery.exceptionSummaryTypeCd = selection.exceptionSummaryTypeCd;
        dialQuery.exceptionStatusCds = selection.exceptionStatusCd;
        dialQuery.exceptionLocationTypeCd = this.getExceptionLocationTypeCd(hierarchy, locations?.length);
        dialQuery.locationFilters = locations;

        return this.exceptionAndAlertService.getAlertCountsByType(dialQuery, false).pipe(
          map((data) => {
            if (data[0]?.exceptionAlertCounts.length === 1) {
              this.dialSelect(this.dialFormatter([data[0].exceptionAlertCounts[0]], data[0].status)[0]);
            }
            return this.dialFormatter(data[0]?.exceptionAlertCounts, data[0].status);
          })
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  dialSelect(dial: DialInfo): void {
    this.selectedDial = dial.alertName;
    this.boardQuery$ = combineLatest([
      this.linehaulStateService.listen('selectedLocations'),
      this.linehaulStateService.listen('selectedPeriod'),
      this.linehaulStateService.refresh$.pipe(startWith(true)),
      this.pushService.changedDocuments$().pipe(startWith(true)),
    ]).pipe(
      map(([sics, period]) => {
        const boardQuery = new ListLinehaulExceptionsQuery();
        boardQuery.userRoleCd = this.userRoleCd;
        boardQuery.exceptionTypeCd = dial.exceptionTypeCd;
        boardQuery.exceptionStatusCds = [dial.exceptionStatusCd];
        boardQuery.sicCds = sics;
        boardQuery.planDate = this.generatePlanDate(period);

        return boardQuery;
      })
    );
  }

  private resetContent(): void {
    this.dialList$ = null;
    this.boardQuery$ = null;
  }

  private dialFormatter(data: any[], status: ExceptionStatusCd): DialInfo[] {
    const result: DialInfo[] = [];
    data?.map((dial) => {
      result.push({
        exceptionTypeCd: dial.type,
        exceptionStatusCd: status,
        alertName: DialNames[dial.type],
        alertNumber: dial.count,
      });
    });

    return result;
  }

  private setNotificationFilters(locationFilters?: string[]): void {
    if (locationFilters) {
      const filters = new ExpectationSearchFilter();
      if (locationFilters?.length) {
        filters.expectationSic = <any>locationFilters.map((v) => v);
      } else {
        filters.regionCd = <any>'lnh';
      }
      this.pushService.registerFilter(filters);
    }
  }

  private generatePlanDate(period: Date): string | undefined {
    return !isSameDay(new Date(), period) ? format(period, 'dd-MMM-yy') : undefined;
  }

  private getExceptionLocationTypeCd(hierarchy: string, locations: number): ExceptionLocationTypeCd {
    if (!locations || locations <= 0) {
      return undefined;
    }
    if (hierarchy === HierarchyRegion.OPERATIONAL_REGION) {
      return ExceptionLocationTypeCd.OPERATION_SIC;
    } else if (hierarchy === HierarchyRegion.LINEHAUL_REGION) {
      return ExceptionLocationTypeCd.LINEHAUL_REGION;
    } else if (hierarchy === HierarchyRegion.HSS_REGION) {
      return ExceptionLocationTypeCd.HSS_REGION;
    }
    return undefined;
  }
}
