/* tslint:disable: max-line-length */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { XpoLtlServiceCentersService, XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { AppRoutes } from '../../../shared/enums/app-routes.enum';
import { BypassFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { ModifiedByFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/modified-by-formatter';
import { StatusChangeFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/status-chage-formatter';
import { StatusFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/status-formatter';
import { DateFormatterComponent } from '../../../shared/formatters/date-formatter';
import { ChangeViewService } from './../services/change-view.service';
import { ChangesViewBoardTemplate } from './changes-view-board-template';
import { ChangesViewDataSourceService } from './changes-view-data-source.service';

@Component({
  selector: 'app-changes-view',
  templateUrl: './changes-view.component.html',
  styleUrls: ['./changes-view.component.scss'],
})
export class ChangesViewComponent implements OnInit, OnDestroy {
  viewTemplates: ChangesViewBoardTemplate[];
  aggFunctions: any;
  views: XpoBoardView[];
  sizeToFit: boolean = true;
  gridOptions: GridOptions;
  frameworkComponents = {
    DateFormatterComponent: DateFormatterComponent,
    BypassFormatterComponent: BypassFormatterComponent,
    StatusFormatterComponent: StatusFormatterComponent,
    StatusChangeFormatterComponent: StatusChangeFormatterComponent,
    ModifiedByFormatterComponent: ModifiedByFormatterComponent,
  };
  private gridApi: GridApi;
  private colApi: ColumnApi;

  readonly AppRoutes = AppRoutes;

  constructor(
    public datasource: ChangesViewDataSourceService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private changeViewService: ChangeViewService,
    private timeService: XpoLtlTimeService
  ) {
    this.changeViewService.getCurrentView().subscribe((view) => {
      if (this.gridApi && view === AppRoutes.CHANGES_VIEW) {
        this.gridApi.sizeColumnsToFit();
      }
    });
    this.aggFunctions = {};
    this.gridOptions = {
      groupMultiAutoColumn: true,
      groupSuppressAutoColumn: true,
      // aggFuncs: this.aggFunctions,
      suppressAggFuncInHeader: true,
      frameworkComponents: this.frameworkComponents,
      defaultColDef: {
        suppressMenu: true,
        sortable: true,
        resizable: true,
      },
      /* use only one of the below 3 they are mutually exclusive
      groupRemoveSingleChildren: true,
      groupRemoveLowestSingleChildren: true,
      groupHideOpenParents: true */
      /*groupSuppressBlankHeader: true,*/
    };
  }

  ngOnInit() {
    this.viewTemplates = [new ChangesViewBoardTemplate(this.timeService, this.datasource.sic)];
    this.views = [this.viewTemplates[0].createChangesView()];
  }

  ngOnDestroy() {
    this.gridApi = null;
    this.colApi = null;
  }

  onChangesGridReady(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
  }
}
