<form class="form"
    [formGroup]="form"
    *ngIf="!!form">
  <div class="form-row">
    <span class="label">Booking</span>
    <mat-form-field [floatLabel]="'never'">
      <input #locationInput
          type="text"
          matInput
          formControlName="bookingNumber"
          [matAutocomplete]="autoGroup" />
      <mat-autocomplete class="booking-filter-autocomplete"
          autoActiveFirstOption="true"
          #autoGroup="matAutocomplete">
        <mat-option *ngFor="let pull of filteredPulls | async"
            [value]="pull.booking">
          {{ pull.booking }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="general-container">
    <h1 mat-dialog-title>
      Request Approval
    </h1>
    <div class="form-subcontainer">
      <div>
        <span> Comment </span>
      </div>
      <mat-form-field style="width: 70%;">
        <textarea rows="5"
            matInput
            formControlName="comment"></textarea>
        <mat-hint align="end">{{ comment.value.length }} / 100 Chars</mat-hint>
        <mat-error *ngIf="form.get('comment').hasError('required'); commentInvalid">
          Comment field is required.
        </mat-error>
        <mat-error *ngIf="hasError('maxlength', form, 'comment')"> Comment field is too long. </mat-error>
      </mat-form-field>
    </div>

    <div class="mat-dialog-actions">
      <button mat-flat-button
          [disabled]="!form.get('bookingNumber').valid"
          (click)="sendRequestApproval()">
        REQUEST APPROVAL
      </button>
    </div>
  </div>
</form>
