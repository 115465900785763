import { Injectable } from '@angular/core';
import { ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { SidePanelOpts } from '../components/side-panel-container/image-gallery-container/enums/options.model';
import { AlertsType } from '../enums/alerts.enum';
import { SidePanelStatus } from '../enums/side-panel-status';
import { SidePanelType } from '../enums/side-panel-type.enum';
import { SelectedAlertType } from '../models/selected-alert-type.model';
import { SidePanelDataStructures } from '../models/side-panel-data.model';

@Injectable({
  providedIn: 'root',
})
export class SidePanelVisibilityService {
  lastId = new BehaviorSubject<number>(null);
  sidePanelData = new BehaviorSubject<any>(null);
  sidePanelOpts: SidePanelOpts;

  alertTypes = {
    'G! Misloads': ExceptionTypeCd.GMISLOAD,
    'HSS Underweight': ExceptionTypeCd.HSS,
    'Progressive Loading': ExceptionTypeCd.PROGRESSIVE_LOAD,
    'Load Request': ExceptionTypeCd.LOAD_REQUEST,
    'Partial Bypass': ExceptionTypeCd.PARTIAL_BYPASS,
  };
  showSidePanel = new BehaviorSubject<boolean>(false);

  constructor() {}

  subscribeToComponent(component: SidePanelType): Observable<any> {
    switch (component) {
      case SidePanelType.alertSidePanel:
        return this.sidePanelData.asObservable();
      case SidePanelType.showSidePanel:
        return this.showSidePanel.asObservable();
      case SidePanelType.lastId:
        return this.lastId.asObservable();
    }
  }

  showAlertSidePanel(data) {
    this.sidePanelData.next(data);
    this.showSidePanel.next(true);
  }

  openPanel(fromAlertType: AlertsType, data) {
    const selectedAlert: SelectedAlertType = {
      sidePanelId: fromAlertType === AlertsType.LOAD_REQUEST ? 'none' : data.operationException.trailerNbr,
      filterSic: data.operationException.loggedSic,
      currentEventTmst: data.auditInfo.createdTimestamp,
      closeTmst: data.auditInfo.createdTimestamp,
      statusCd: data.statusCd,
      typeCd: this.alertTypes[fromAlertType],
      hssTakenTmstmp: data.auditInfo.createdTimestamp,
    };
    if (fromAlertType === AlertsType.LOAD_REQUEST) {
      selectedAlert.reason = data.operationException.requestReason;
      selectedAlert.comments = data.operationException.actualValue;
      selectedAlert.requestedBy = data.operationException.employeeName;
    }
    const sidePanelData = new SidePanelDataStructures(
      selectedAlert,
      this.sidePanelOpts,
      SidePanelStatus.OPENED,
      data,
      this.alertTypes[fromAlertType]
    );
    this.showAlertSidePanel(sidePanelData);
  }

  setLastId(id: number) {
    this.lastId.next(id);
  }

  closePanels() {
    this.lastId.next(null);
    this.sidePanelData.next(null);
    this.showSidePanel.next(false);
  }
}
