<mat-form-field>
  <mat-label>Period</mat-label>
  <input matInput
      data-test="periodFilter"
      [(ngModel)]="period"
      [matDatepicker]="picker"
      [max]="maxDate"
      (dateChange)="handlePeriodChange($event.value)" />
  <mat-datepicker-toggle matSuffix
      [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
