import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import * as momentNs from 'moment-timezone';

const moment = momentNs;

export enum DataDisplayGroupType {
  Default = 'default',
  Currency = 'currency',
  Date = 'date',
}

/**
 * Render a value underneath a label
 */
@Component({
  selector: 'xpo-ltl-data-display-group',
  templateUrl: './data-display-group.component.html',
  styleUrls: ['./data-display-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataDisplayGroupComponent {
  @Input()
  label: string;
  @Input()
  value: any;
  @Input()
  asCurrency = false;
  @Input()
  asDate = false;

  readonly DataDisplayGroupType = DataDisplayGroupType;

  constructor(private currencyPipe: CurrencyPipe) {}

  renderer(value): string {
    if (value) {
      if (this.asCurrency) {
        return this.currencyPipe.transform(value.amt, value.currencyCd);
      } else if (this.asDate) {
        return moment(value).format('MM/DD/YYYY');
      } else {
        return value;
      }
    } else {
      return undefined;
    }
  }
}
