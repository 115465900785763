import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MiscLineItemCd, MonetaryAmount } from '@xpo-ltl/sdk-common';
import { GetOdsShipmentResp, MiscLineItem } from '@xpo-ltl/sdk-shipmentods';
import { find as _find, reduce as _reduce } from 'lodash';
import { Subject } from 'rxjs';
import { InteractionService } from '../../../../../services/interaction.service';

interface GeneralInfoData {
  proNbr: string;
  origin: string;
  pickupDate: any;
  deliveryDate: any;
  destination: string;
  codAmount: MonetaryAmount;
  palletCount: number;
  pieceCount: number;
  linealFeet: string;
  totalWeight: number;
  parentPro: string;
}

@Component({
  selector: 'shipment-details-general-info',
  templateUrl: './shipment-details-general-info.component.html',
  styleUrls: ['./shipment-details-general-info.component.scss'],
})
export class ShipmentDetailsGeneralInfoComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject();
  data: GeneralInfoData;
  @Input() shipment: any;

  constructor(private interactionService: InteractionService) {}

  ngOnInit() {
    this.updateFormFromShipment(this.shipment);
  }

  ngOnDestroy() {}

  private updateFormFromShipment(shipment: GetOdsShipmentResp) {
    if (!shipment) {
      return;
    }

    const totalPieces = _reduce(shipment.commodity, (total, commodity) => total + commodity.piecesCount, 0);
    const codItem: MiscLineItem = _find(shipment.miscLineItem, (li) => li.lineTypeCd === MiscLineItemCd.COD_AMT);
    const codAmount = codItem
      ? {
          amt: codItem.amount,
          currencyCd: 'USD', // always in US$
        }
      : undefined;
    this.data = {
      proNbr: shipment.shipment.proNbr,
      origin: shipment.shipment.originTerminalSicCd,
      destination: shipment.shipment.destinationTerminalSicCd,
      pickupDate: shipment.shipment.pickupDate,
      deliveryDate: shipment.shipment.estimatedDeliveryDate,
      codAmount: codAmount,
      palletCount: shipment.shipment.totalPalletsCount,
      pieceCount: totalPieces,
      linealFeet: shipment.shipment.linealFootTotalNbr,
      totalWeight: shipment.shipment.totalWeightLbs,
      parentPro: this.interactionService.getParentPro(),
    };
  }
}
