import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { InteractionService } from './services/interaction.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [],
})
export class SharedServicesModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedServicesModule,
      providers: [InteractionService],
    };
  }
}
