<button *ngIf="!hssInd else noButton"
    mat-button
    color="primary"
    (click)="launchDialog()">
  <b>{{params.value}}</b>
</button>
<ng-template #noButton>
  <b>{{params.value}}</b>
</ng-template>
<span class="app-MoveToCellRenderer-divider">-</span>
{{ sicDescription }}
<b *ngIf="hssInd"> - HSS</b>
