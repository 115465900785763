import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ComponentsEnum } from '../../enums/components.enum';
import { InteractionService } from './../../services/interaction.service';
import { SidePanelOpts } from './image-gallery-container/enums/options.model';
import { SidePanelData } from './image-gallery-container/enums/side-panel-data.model';
import { ImageGalleryService } from './image-gallery-container/image-gallery-container-service.component';

@Component({
  selector: 'app-side-panel-container',
  templateUrl: './side-panel-container.component.html',
  styleUrls: ['./side-panel-container.component.scss'],
})
export class SidePanelContainerComponent implements OnInit, OnDestroy {
  @Input() sidePanelOpts: SidePanelOpts;
  @Input() sidePanelData: SidePanelData;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  url: any;
  fullscreen: boolean = false;
  panelOptions: SidePanelOpts;
  disabled: boolean;
  textAreaContent: string;
  alert;
  type;
  form: FormGroup;
  data;
  tab;
  sideData;
  commentFormControl: AbstractControl = new FormControl();
  sidePanelSubscription: Subscription;
  results: boolean = false;
  isAllowed: boolean = false;

  constructor(private imageGalleryService: ImageGalleryService, private interactionService: InteractionService) {
    this.sidePanelSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.SPANEL_RESULT)
      .subscribe((resp) => {
        if (resp) {
          this.results = resp;
        } else {
          this.results = false;
        }
      });
  }

  ngOnInit() {
    const {
      defaultView,
      allowFullScreen,
      allowPreview,
      displayRelatedProsTable,
      allowZoomAndRotate,
      allowDownload,
      allowDownloadAll,
      allowProsList,
      allowSendEmail,
      isDashboard,
    } = this.sidePanelOpts;
    this.panelOptions = {
      defaultView: this.defaultView(defaultView),
      allowFullScreen: allowFullScreen !== false,
      allowPreview: allowPreview !== false,
      displayRelatedProsTable: displayRelatedProsTable !== false,
      allowZoomAndRotate: allowZoomAndRotate !== false,
      allowDownload: allowDownload !== false,
      allowDownloadAll: allowDownloadAll !== false,
      allowProsList: allowProsList !== false,
      allowSendEmail: allowSendEmail !== false,
      isDashboard: isDashboard !== false,
    };
  }

  defaultView(val) {
    const views = {
      side: 'SIDE',
      full: 'FULL',
    };
    return views[val] || 'SIDE';
  }

  ngOnDestroy() {
    this.sidePanelSubscription.unsubscribe();
    this.sidePanelData = null;
  }

  closePanel() {
    this.imageGalleryService.setIndex(0);
    this.onClose.emit();
  }

  closeOrReducePanel() {
    this.fullscreen ? (this.fullscreen = false) : this.onClose.emit();
  }

  changeStatus(status: boolean) {
    this.fullscreen = status;
    this.imageGalleryService.emitSidePanelStatus(status);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeOrReducePanel();
    }
  }

  cancel(): void {
    this.closePanel();
  }
}
