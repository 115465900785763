import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DrawerComponent } from '../drawer.component';

@Component({
  selector: 'app-drawer-wrapper',
  templateUrl: './drawer-wrapper.component.html',
  styleUrls: ['./drawer-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerWrapperComponent {
  constructor(private parentDrawerComponent: DrawerComponent) {}

  close(): void {
    this.parentDrawerComponent.close();
  }
}
