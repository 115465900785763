import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { XpoAdvancedSelectOption } from '../models/index';

export type XpoAdvancedSelectPanelSearchBarVisibility = 'visible' | 'hidden' | 'auto';

@Component({
  selector: 'xpo-advanced-select-panel',
  templateUrl: './advanced-select-panel.component.html',
  styleUrls: ['./advanced-select-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'xpo-AdvancedSelectPanel' },
})
export class XpoAdvancedSelectPanelComponent implements AfterViewInit {
  isSearchBarVisible: boolean;

  private initialized = false;

  /** Suppress sending parent codes when all children are selected */
  @Input()
  get suppressIncludeParentCode(): boolean {
    return this.suppressIncludeParentCodeValue;
  }
  set suppressIncludeParentCode(v: boolean) {
    this.suppressIncludeParentCodeValue = coerceBooleanProperty(v);
  }
  private suppressIncludeParentCodeValue: boolean = false;

  /** Whether or not only a single option can be selected */
  @Input()
  get isSingleSelect(): boolean {
    return this.isSingleSelectValue;
  }
  set isSingleSelect(v: boolean) {
    this.isSingleSelectValue = coerceBooleanProperty(v);
  }
  private isSingleSelectValue: boolean = false;

  /** Whether or not the select all checkbox is shown */
  @Input()
  get showSelectAll(): boolean {
    return this.showSelectAllValue;
  }
  set showSelectAll(v: boolean) {
    this.showSelectAllValue = coerceBooleanProperty(v);
  }
  private showSelectAllValue: boolean = false;

  /** Options of the select filter */
  @Input()
  get options(): XpoAdvancedSelectOption[] {
    return this.optionsValue;
  }
  set options(v: XpoAdvancedSelectOption[]) {
    this.optionsValue = v ?? null;

    // Decide search bar visibility if mode set to 'auto'
    if (this.searchBarVisibility === 'auto') {
      this.isSearchBarVisible = this.optionsValue?.length > 5;
    }
  }
  private optionsValue: XpoAdvancedSelectOption[];

  @Input()
  get selection(): string | string[] {
    return this.selectionValue;
  }
  set selection(value: string | string[]) {
    if (this.selectionValue !== value) {
      this.selectionValue = value;
    }
  }
  private selectionValue: string | string[];

  @Input()
  get searchBarVisibility(): XpoAdvancedSelectPanelSearchBarVisibility {
    return this.searchBarVisibilityValue;
  }
  set searchBarVisibility(v: XpoAdvancedSelectPanelSearchBarVisibility) {
    this.searchBarVisibilityValue = v;
    // If explicitly set to something else than 'auto' propagate it through
    if (this.searchBarVisibility !== 'auto') {
      this.isSearchBarVisible = this.searchBarVisibility === 'visible';
    }
  }
  private searchBarVisibilityValue: XpoAdvancedSelectPanelSearchBarVisibility = 'auto';

  @Input()
  get useExternalSearch(): boolean {
    return this.useExternalSearchValue;
  }
  set useExternalSearch(v: boolean) {
    this.useExternalSearchValue = coerceBooleanProperty(v);
  }
  private useExternalSearchValue: boolean = false;

  @Input()
  set searchValue(v: string) {
    if (this.searchInputValue != v) {
      this.searchInputValue = v;

      if (this.initialized) {
        this.filterOptions(v);
      }
    }
  }
  searchInputValue: string = '';

  @Output()
  selectionChange = new EventEmitter<string | string[]>();

  @Output()
  selectedOptions = new EventEmitter<XpoAdvancedSelectOption[]>();

  @Output()
  searchChange = new EventEmitter<string>();

  @ViewChild('searchBox', { static: false })
  searchBox: ElementRef;

  ngAfterViewInit(): void {
    if (this.searchBox) {
      this.searchBox.nativeElement.focus();
    }

    if (this.searchInputValue) {
      this.filterOptions(this.searchInputValue);
    }

    this.initialized = true;
  }

  filterOptions(value: string): void {
    if (!this.useExternalSearch) {
      this.searchInputValue = value;
    }

    this.searchChange.emit(value);
  }

  handleSelectionChange(selectedValue: string | string[]): void {
    this.selection = selectedValue;
    this.selectionChange.emit(selectedValue);
  }

  /** Updates the selected criterion of filter */
  handleOptionsSelected(selectedItems: XpoAdvancedSelectOption[]): void {
    this.selectedOptions.emit(selectedItems);
  }
}
