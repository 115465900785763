import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core';
import { SidePanelModule } from '../shared/side-panel/side-panel.module';
import { AlertAndExceptionHeaderComponent } from './alert-and-exception-header/alert-and-exception-header.component';
import { AlertGridComponent } from './alert-grid/alert-grid.component';
import { DialListComponent } from './dial-list/dial-list.component';
import { GlobalHeaderModule } from './global-header/global-header.module';
import { NewDashboardComponent } from './new-dashboard.component';
@NgModule({
  declarations: [NewDashboardComponent, DialListComponent, AlertAndExceptionHeaderComponent, AlertGridComponent],
  imports: [
    CommonModule,
    MatTableModule,
    XpoAgGridBoardModule,
    SidePanelModule,
    GlobalHeaderModule,
    XpoButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatBadgeModule,
  ],
  exports: [NewDashboardComponent],
})
export class NewDashboardModule {}
