import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
// import { PipeModule, XpoDirectivesModule } from '@xpo-ltl/angular-utils';
import { XpoLtlPipeModule } from '@xpo-ltl/ngx-ltl';
import { XpoBoardModule, XpoFiltersModule } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardModule } from '@xpo-ltl/ngx-ltl-board-grid';
import { XpoEmptyStateModule } from '@xpo-ltl/ngx-ltl-core';
import {
  XpoButtonModule,
  XpoCardModule,
  XpoDialogModule,
  XpoIconModule,
  XpoPopoverModule,
  XpoShellModule,
  XpoTabsModule,
} from '@xpo-ltl/ngx-ltl-core';
import { XpoAgGridModule } from '@xpo-ltl/ngx-ltl-grid';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ImageCarouselModule } from '../../image-carousel/image-carousel.module';
import { SidePanelContainerModule } from '../../shared/components/side-panel-container/side-panel-container.module';
import { PipesModule } from '../../shared/formatters/pipes.module';
import { SharedModule } from './../../shared/shared.module';
import { AllowableLoadsButtonsComponent } from './allowable-loads-buttons/allowable-loads-buttons.component';
import { AllowableLoadsComponent } from './allowable-loads.component';
import { ChangesViewComponent } from './changes-view/changes-view.component';
import { ManifestComponent } from './dialogs/manifest/manifest.component';
import { LanesViewComponent } from './lanes-view/lanes-view.component';
import { RequestLoadComponent } from './lanes-view/request-load/request-load.component';
import { LoadsViewComponent } from './loads-view/loads-view.component';
import { CostDetailsComponent } from './trailer-details-inside/cost-details/cost-details.component';
import { SelectedTrailerInfoComponent } from './trailer-details-inside/selected-trailer-info/selected-trailer-info.component';
import { TrailerDetailsInsideComponent } from './trailer-details-inside/trailer-details-inside.component';

const dialogs = [ManifestComponent, RequestLoadComponent];
@NgModule({
  declarations: [
    AllowableLoadsComponent,
    LanesViewComponent,
    LoadsViewComponent,
    ChangesViewComponent,
    AllowableLoadsButtonsComponent,
    ...dialogs,
    RequestLoadComponent,
    TrailerDetailsInsideComponent,
    SelectedTrailerInfoComponent,
    CostDetailsComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    XpoButtonModule,
    XpoAgGridModule,
    XpoBoardModule,
    XpoAgGridBoardModule,
    XpoPopoverModule,
    XpoShellModule,
    XpoDialogModule,
    MatIconModule,
    XpoPopoverModule,
    XpoFiltersModule,
    XpoEmptyStateModule,
    MatDialogModule,
    SharedModule,
    SidePanelContainerModule,
    XpoTabsModule,
    MatTabsModule,
    XpoCardModule,
    HttpClientModule,
    AgGridModule.withComponents([SharedModule]),
    XpoFiltersModule,
    XpoIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    XpoLtlPipeModule,
    PipesModule,
    // XpoDirectivesModule,
    ImageCarouselModule,
    // ImageCarouselModule,
  ],
  exports: [...dialogs, AllowableLoadsComponent, TrailerDetailsInsideComponent, SelectedTrailerInfoComponent],
  entryComponents: [...dialogs],
  providers: [DecimalPipe, DatePipe],
})
export class AllowableLoadsModule {}
