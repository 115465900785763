import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class SunsetInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.url.includes('loc-reference-details')) return next.handle(req); 
    
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        return of(new HttpResponse({ body: { data: [], message: 'Error is handled by sunset guard' } }));
      })
    );
  }
}
