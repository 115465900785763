import { Injectable, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ExceptionLocationTypeCd, User, XrtAttributeFilter } from '@xpo-ltl/sdk-common';
import {
  ExceptionManagementApiService,
  ExpectationSearchFilter,
  ExpectationSearchFilterAnd,
  RegisterFilterExpectationUnfilteredResp,
  RegisterFilterExpectationUnfilteredRqst,
  UnregisterFilterExpectationUnfilteredRqst,
} from '@xpo-ltl/sdk-exceptionmanagement';
import { XrtChangedDocuments } from '@xpo/ngx-xrt';
import { XrtFireMessagingService, XrtFireMessagingTokenService } from '@xpo/ngx-xrt-firebase';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerformanceDashboardFilters } from '../models/performance-dashboard-filter.model';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AlertsMessagingService implements OnDestroy {
  user: User;
  changedDocumentSub: Subscription;
  private currentFilterHash = '';
  private unsubscriber = new Unsubscriber();
  private elementsUpdatedCountSubject = new BehaviorSubject<number>(0);
  get elementsUpdatedCount$(): Observable<number> {
    return this.elementsUpdatedCountSubject.asObservable();
  }
  private elementsUpdatedSubject = new BehaviorSubject(null);
  get elementsUpdated$(): Observable<any> {
    return this.elementsUpdatedSubject.asObservable();
  }

  constructor(
    private messagingTokenService: XrtFireMessagingTokenService,
    private messagingService: XrtFireMessagingService,
    private exceptionManagementService: ExceptionManagementApiService,
    private authService: AuthService
  ) {}

  get isSicUser(): boolean {
    return this.authService.isLhUSerOrSicUser() === 'sic';
  }

  private broadcastElementsUpdated(changedDocuments: XrtChangedDocuments): void {
    if (changedDocuments.filterHash === this.currentFilterHash) {
      const pushFeed = JSON.parse(changedDocuments.documents.toString());
      this.elementsUpdatedSubject.next(pushFeed);
      console.log('push feed received with document key = exception insteance id ' + pushFeed[0].DocumentKey);
    }
  }

  private registerFilter(filterParameters): Promise<string> {
    if (filterParameters) {
      const filters = this.getFilters(filterParameters);
      const searchType = this.getSearchType(filterParameters);
      return new Promise((resolve) => {
        this.messagingTokenService.getToken$().subscribe(
          (token) => {
            const request = new RegisterFilterExpectationUnfilteredRqst();
            request.filter = new ExpectationSearchFilter();
            request.filter.and = [];
            request.connectionToken = token;
            if (searchType === 'region' || searchType === 'district') {
              const aux = new ExpectationSearchFilterAnd();
              aux.regionCd = new XrtAttributeFilter();
              aux.regionCd.equals = 'lnh';
              request.filter.and.push(aux);
            } else if (searchType === 'sic') {
              filters.map((filter) => {
                const aux = new ExpectationSearchFilterAnd();
                aux.expectationSic = new XrtAttributeFilter();
                aux.expectationSic.equals = filter;
                request.filter.and.push(aux);
              });
            } else if (searchType === 'all') {
              const aux = new ExpectationSearchFilterAnd();
              aux.regionCd = new XrtAttributeFilter();
              aux.regionCd.equals = 'lnh';
              request.filter.and.push(aux);
            }
            this.exceptionManagementService
              .registerFilterExpectationUnfiltered(request, { toastOnError: false })
              .subscribe(
                (response: RegisterFilterExpectationUnfilteredResp) => {
                  if (response && response.filterHash) {
                    resolve(response.filterHash);
                  }
                },
                (error1) => {
                  throw new Error(`Error ${error1}, filter not registered for Alerts!\``);
                }
              );
          },
          (error) => {
            throw new Error(`Error ${error.toString()}, Error!}`);
          }
        );
      });
    } else {
      return new Promise(null);
    }
  }

  private getFilters(data): any {
    if (data.location && data.location.length) {
      return data.location.map((loc) => loc.code);
    } else if (data.operationalLevel && data.operationalLevel.length) {
      return data.operationalLevel.map((loc) => loc.code);
    } else {
      return this.authService.getEmployeeSicAndRoles().locationSic === 'ISG'
        ? ['UPO']
        : [this.authService.getEmployeeSicAndRoles().locationSic];
    }
  }

  private getSearchType(filters): 'sic' | 'region' | 'district' | 'all' {
    // return 'all';
    // Until new register for filters is requested
    if (filters.operationalLevel && filters.operationalLevel.length) {
      return this.getLevel(filters.operationalLevel[0].level);
    } else if (filters.location && filters.location.length) {
      return this.getLevel(filters.location[0].level);
    } else if (this.isSicUser) {
      return 'sic';
    } else {
      return 'all';
    }
  }

  private getLevel(level): 'sic' | 'region' | 'district' {
    if (level === ExceptionLocationTypeCd.LINEHAUL_SIC || level === ExceptionLocationTypeCd.OPERATION_SIC) {
      return 'sic';
    } else if (level === ExceptionLocationTypeCd.LINEHAUL_REGION) {
      return 'region';
    } else {
      return 'district';
    }
  }

  private unregisterFilter(filterHash: string): void {
    if (filterHash) {
      this.messagingTokenService.getToken$().subscribe((token) => {
        const request = new UnregisterFilterExpectationUnfilteredRqst();
        request.connectionToken = token;
        request.filterHash = filterHash;

        this.exceptionManagementService
          .unregisterFilterExpectationUnfiltered(request, { toastOnError: false })
          .subscribe();
      });
    }
  }

  updateCurrentFilter(newFilterParams: PerformanceDashboardFilters): void {
    this.unregisterFilter(this.currentFilterHash);
    if (
      (newFilterParams.location && newFilterParams.location.length) ||
      (newFilterParams.operationalLevel && newFilterParams.operationalLevel.length) ||
      !newFilterParams.isFilterEmpty
    ) {
      this.registerFilter(newFilterParams)
        .then(
          (newHash: string | undefined) => {
            if (newHash) {
              this.currentFilterHash = newHash;
              if (this.changedDocumentSub) {
                this.changedDocumentSub.unsubscribe();
                this.changedDocumentSub = undefined;
              }
              this.changedDocumentSub = this.messagingService
                .changedDocument$()
                .pipe(takeUntil(this.unsubscriber.done$))
                .subscribe((changedDocuments: XrtChangedDocuments) => {
                  this.broadcastElementsUpdated(changedDocuments);
                });
            }
          },
          function(error): void {
            console.log(`Register Error! ${error}`);
          }
        )
        .catch((reason) => {
          console.log(`Register Error! ${reason}`);
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber.complete();
  }

  mockTriggerFeed(): void {
    const mockJSON = '';
    this.elementsUpdatedSubject.next([{ DocumentKey: 993373 }]);
  }
}
