<ng-container *ngIf="showBanner">
  <div class="xpo-shellBanner-content">
    <mat-icon class="xpo-shellBanner-content--warningIcon">warning</mat-icon> Edge Linehaul will be retired as of
    September 21st, 2024. <a xpo-link
        class="xpo-shellBanner-url"
        [attr.href]="getFosUrl() | async"
        rel="dns-prefetch">Click here</a>to go to Overview in Edge Freight Management for load board and load requests.
  </div>
  <xpo-icon iconName="close"
      (click)="toggleBanner()"
      class="xpo-shellBanner-closeButon"></xpo-icon>
</ng-container>
