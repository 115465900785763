import { Component, Input, OnInit } from '@angular/core';
import { CostOpportunity, ListTrailerCostOpportunitiesResp } from '@xpo-ltl/sdk-performancegoals';
@Component({
  selector: 'cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.scss'],
})
export class CostDetailsComponent implements OnInit {
  _data: ListTrailerCostOpportunitiesResp;
  costOpportunity: CostOpportunity;
  showAvgMessage: boolean = false;
  underUtilizedAmount: any;
  underUtilizedText: string = '';
  prosRemText: string = '';
  proRemAmount: any;
  addedWeightAmount: number;
  addedCubeAmount: number;
  showFormatForCube: boolean = false;
  @Input() id: number;
  @Input() set data(value: ListTrailerCostOpportunitiesResp) {
    this._data = value;
  }
  defaultCostOpportunity = new CostOpportunity();

  get data(): ListTrailerCostOpportunitiesResp {
    return this._data;
  }

  constructor() {}

  ngOnInit() {
    if (this.data && this.data.costOpportunities !== undefined) {
      this.costOpportunityMapper();
    }
  }

  costOpportunityMapper() {
    this.costOpportunity = this.data.costOpportunities.find((opps) => parseInt(opps.sourceKey, 10) === this.id);
    if (this.costOpportunity) {
      this.showAvgMessage = this.costOpportunity && this.costOpportunity.dataSource === 'AVE' ? true : false;
      this.addedWeightAmount = this.data.addedWeightAmount;
      this.addedCubeAmount = this.data.addedCubeAmount;
      if (this.costOpportunity && this.costOpportunity.opportunityKey === 'CUBE') {
        this.showFormatForCube = true;
        this.underUtilizedText = 'Cube short';
        this.underUtilizedAmount = this.data.underCubeAmount > 0 ? `${this.data.underCubeAmount} %` : '0 %';
        this.prosRemText = 'Cube available';
        this.proRemAmount =
          this.costOpportunity.totalShipmentVolumeCubicFeet > 0
            ? `${this.costOpportunity.totalShipmentVolumeCubicFeet} %`
            : '0 %';
      } else {
        this.showFormatForCube = false;
        this.underUtilizedText = 'lbs short';
        this.underUtilizedAmount = this.data.underWeightAmount;
        this.prosRemText = 'lbs available';
        this.proRemAmount = this.costOpportunity && this.costOpportunity.totalShipmentWeightLbs;
      }
    } else {
      this.costOpportunity = this.defaultCostOpportunity;
      this.showAvgMessage = false;
      this.addedWeightAmount = 0;
      this.addedCubeAmount = 0;
      this.costOpportunity.opportunityKey = 'CUBE';
      this.showFormatForCube = true;
      this.underUtilizedText = '';
      this.underUtilizedAmount = '';
      this.prosRemText = '';
      this.proRemAmount = '';
    }
  }
}
