import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardColumn } from '@xpo-ltl/ngx-ltl-board-grid';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { ListLinehaulExceptionsQuery } from '@xpo-ltl/sdk-exceptionmanagement';
import { ColDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { HSSWeightFormatterComponent } from '../../shared/formatters/ag-grid-cell-formatters/hss-weight-formatter';
import { LoadRequestStatusFormatterComponent } from '../../shared/formatters/ag-grid-cell-formatters/load-request-status-formatter';
import { RemainingValueFormatterComponent } from '../../shared/formatters/ag-grid-cell-formatters/remaining-value-formatter';
import { proNumberFormatter } from '../../shared/formatters/proNbr-pipe';
import { getAge, getCalculated, getEstimated } from '../../utils/data-transform-utils';
import { EnumUtil } from '../../utils/enum-utils';
import { AlertGridXrtInMemoryService } from './alert-grid-xrt.service';

@Injectable()
export class AlertGridDataSourceService extends XpoBoardDataSource {
  query: ListLinehaulExceptionsQuery;
  selectedRow: any;

  private readonly dateTimeColumn = [
    {
      headerName: 'Time, Date',
      field: 'createdTimestamp',
      valueFormatter: (params) => {
        return this.formatDate(params.value, params.data.loggedSic);
      },
    },
  ];

  constructor(
    private alertGridXrtInMemoryService: AlertGridXrtInMemoryService,
    private timeService: XpoLtlTimeService
  ) {
    super();
    this.pageSize = 10000;
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return this.alertGridXrtInMemoryService
      .search(null, { filter: state.criteria, pageNumber: state.pageNumber, pageSize: this.pageSize }, this.query)
      .pipe(
        map((data) => {
          if (data) {
            return new XpoBoardData(
              state,
              { columns: this.generateColumns(this.query.exceptionStatusCds[0]), rows: data.items },
              data.resultCount,
              this.pageSize
            );
          } else {
            return new XpoBoardData(state, [], 0, 0);
          }
        })
      );
  }

  dataUpdated$(): Observable<XpoBoardState> {
    return this.state$.pipe(
      filter((state) => {
        return state.changes.includes('data') && !!state.data?.recordCount;
      })
    );
  }

  generateColumns(statusCode?: ExceptionStatusCd): XpoAgGridBoardColumn[] {
    let columns: XpoAgGridBoardColumn[] = [
      // {
      //   headerName: 'Actions',
      //   minWidth: 60,
      //   width: 60,
      //   maxWidth: 60,
      //   cellRenderer: 'IconsFormatterComponent',
      //   cellClass: 'iconColumn',
      //   valueGetter: () => {
      //     return 'visibility';
      //   },
      // TODO show and hide based on conditions?
      // },
      { headerName: 'Region', field: 'linehaulRegion', width: 80 },
      { headerName: 'District', field: 'district', width: 80 },
      { headerName: 'Logged Sic', field: 'loggedSic', width: 100 },
      {
        headerName: 'Elapsed Time',
        field: 'createdTimestamp',
        valueFormatter: (params) => {
          return this.formatAge(params.value, params.data.loggedSic);
        },
        hide: ![ExceptionStatusCd.ACKNOWLEDGED, ExceptionStatusCd.NEW, ExceptionStatusCd.RESPONDED].includes(
          statusCode
        ),
      },
    ];
    switch (this.query.exceptionTypeCd) {
      case ExceptionTypeCd.PROGRESSIVE_LOAD:
        columns = [
          ...columns,
          {
            headerName: 'Lane',
            field: 'destinationSicCd',
          },
          {
            headerName: 'Shift',
            // add field and change to params.value
            valueGetter: (params) => {
              return EnumUtil.toShiftCodeValue(params.data.shiftCd);
            },
          },
          {
            headerName: 'Remaining Loads',
            valueGetter: (params) => {
              return this.remainingValue(params.data.value, 'loads');
            },
          },
          {
            headerName: 'Remaining Weight (Lbs)',
            valueGetter: (params) => {
              return this.remainingValue(params.data.value, 'weight');
            },
          },
          {
            headerName: 'Remaining Cube',
            valueGetter: (params) => {
              return this.remainingValue(params.data.value, 'cube');
            },
          },
          ...this.dateTimeColumn,
        ];
        break;
      case ExceptionTypeCd.GMISLOAD:
        columns = [
          ...columns,
          {
            headerName: 'Misloaded At',
            field: 'loggedSic',
          },
          {
            headerName: 'Dest. Sic',
            field: 'destinationSicCd',
          },
          {
            headerName: 'Misloaded To',
            valueGetter: (params) => {
              return params.data.value?.split(',')[0];
            },
          },
          {
            headerName: 'PRO',
            valueGetter: (params) => {
              return proNumberFormatter(params.data.proNbr);
            },
          },
          {
            headerName: 'Door',
            field: 'doorNbr',
          },
          {
            headerName: 'Trailer',
            field: 'trailerNbr',
          },
          {
            headerName: 'Service Date',
            valueGetter: (params) => {
              return getEstimated(params.data.value);
            },
          },
          {
            headerName: 'Calc. Delivery',
            valueGetter: (params) => {
              return getCalculated(params.data.value);
            },
          },
          ...this.dateTimeColumn,
        ];
        break;
      case ExceptionTypeCd.HSS:
        columns = [
          ...columns,
          {
            headerName: 'Dest. SIC',
            field: 'destinationSicCd',
            minWidth: 80,
          },
          {
            headerName: 'Trailer',
            field: 'trailerNbr',
            minWidth: 80,
          },
          {
            headerName: 'Door',
            field: 'doorNbr',
            valueFormatter: (params) => {
              const doorNbr = params.data.doorNbr;
              if (!doorNbr) {
                return '-';
              } else {
                return doorNbr === 'YARD' ? doorNbr : doorNbr.replace('00', '');
              }
            },
            minWidth: 80,
          },
          {
            headerName: 'Weight (Lbs)',
            field: 'weight',
            cellRendererFramework: HSSWeightFormatterComponent,
            valueGetter: (params) => {
              return parseFloat(params.data.value?.split('/')[0]);
            },
            valueFormatter: (params) => {
              return params.data.value?.split('/')[0];
            },
            minWidth: 80,
          },
          {
            headerName: 'Closed By',
            field: 'employeeName',
            minWidth: 180,
          },
          {
            // TODO: need to setup filter?
            headerName: 'Cube (%)',
            filter: 'agNumberColumnFilter',
            filterParams: {
              includeBlanksInLessThan: true,
            },
            valueGetter: (params) => {
              const split = params.data.value?.split('/');
              const value = split?.length > 1 ? split[1] : '-';
              return value;
            },
            minWidth: 80,
          },
          {
            headerName: 'Control Number',
            field: 'controlNumber',
            minWidth: 100,
            hide: ![
              ExceptionStatusCd.RESPONDED,
              ExceptionStatusCd.UNDER_REVIEW_LH,
              ExceptionStatusCd.EXPIRED,
              ExceptionStatusCd.CLOSED,
              ExceptionStatusCd.RESOLVED,
            ].includes(statusCode),
          },
          {
            headerName: 'Status',
            field: 'statusCd',
            minWidth: 80,
            hide: ![
              ExceptionStatusCd.RESPONDED,
              ExceptionStatusCd.UNDER_REVIEW_LH,
              ExceptionStatusCd.EXPIRED,
              ExceptionStatusCd.CLOSED,
              ExceptionStatusCd.RESOLVED,
            ].includes(statusCode),
          },
          {
            headerName: 'Time, Date',
            field: 'createdTimestamp',
            valueFormatter: (params) => {
              return this.formatDate(params.value, params.data.loggedSic);
            },
            minWidth: 100,
          },
          {
            headerName: 'Expiry Text',
            field: 'resolution',
            minWidth: 200,
          },
          {
            headerName: 'Resolution/Expiry Text',
            tooltipField: 'resolution',
            field: 'resolution',
            minWidth: 200,
            hide: ![ExceptionStatusCd.EXPIRED, ExceptionStatusCd.CLOSED, ExceptionStatusCd.RESOLVED].includes(
              statusCode
            ),
          },
        ];
        break;
      case ExceptionTypeCd.LOAD_REQUEST:
        columns = [
          ...columns,
          {
            headerName: 'Lane',
            field: 'moveToSicCd',
            minWidth: 75,
          },
          {
            headerName: 'Shift',
            valueGetter: (params) => {
              return EnumUtil.toShiftCodeValue(params.data.shiftCd);
            },
            minWidth: 75,
          },
          {
            headerName: 'Loads',
            valueGetter: (params) => {
              return Math.abs(params.data.loadRequestedQuantity);
            },
            minWidth: 50,
          },
          {
            headerName: 'Loads Off Dock',
            field: 'loadToSicCd',
            minWidth: 75,
          },
          {
            headerName: 'Responded By',
            field: 'employeeName',
            minWidth: 150,
          },
          // TODO: Make this not use the interaction service
          // {
          //   headerName: LoadRequestTable.REASON,
          //   field: 'reason',
          //   cellRenderer: 'RequesterCommentFormatterComponent',
          //   valueGetter: (params) => {
          //     return this.getReasonDesc(params);
          //   },
          //   minWidth: 150,
          // },
          {
            headerName: 'Requester Comment',
            field: 'actualValue',
            tooltipField: 'actualValue',
            minWidth: 150,
          },
          {
            headerName: 'Approved Loads',
            headerClass: 'wrap-text',
            cellRendererFramework: LoadRequestStatusFormatterComponent,
            valueGetter: (params) => {
              const approvedLoads = params.data.loadApprovedQuantity;
              return approvedLoads &&
                (params.data.statusCd === ExceptionStatusCd.RESPONDED ||
                  params.data.statusCd === ExceptionStatusCd.APPROVED)
                ? { value: approvedLoads, status: 'approved' }
                : 0;
            },
            minWidth: 90,
            hide: ![ExceptionStatusCd.RESPONDED].includes(statusCode),
          },
          {
            headerName: 'Declined Loads',
            headerClass: 'wrap-text',
            field: 'statusCanceled',
            cellRendererFramework: LoadRequestStatusFormatterComponent,
            valueGetter: (params) => {
              const rejectedLoads = params.data.loadRejectedQuantity;
              return rejectedLoads &&
                (params.data.statusCd === ExceptionStatusCd.RESPONDED ||
                  params.data.statusCd === ExceptionStatusCd.DECLINED)
                ? { value: rejectedLoads, status: 'declined' }
                : 0;
            },
            minWidth: 90,
            hide: ![ExceptionStatusCd.RESPONDED].includes(statusCode),
          },
          // TODO: Make this not use interaction service
          // {
          //   headerName: LoadRequestTable.DECISION_REASON,
          //   field: 'decisionReason',
          //   cellRenderer: 'RequesterCommentFormatterComponent',
          //   valueGetter: (params) => {
          //     return this.getReasonCodes(params);
          //   },
          //   minWidth: 150,
          //   hide: ![ExceptionStatusCd.RESPONDED, ExceptionStatusCd.CLOSED, ExceptionStatusCd.CANCELLED, ExceptionStatusCd.EXPIRED].includes(statusCode),
          // },
          {
            headerName: 'Status',
            field: 'statusCd',
            tooltipField: 'statusCd',
            minWidth: 150,
            hide: ![ExceptionStatusCd.CLOSED, ExceptionStatusCd.CANCELLED, ExceptionStatusCd.EXPIRED].includes(
              statusCode
            ),
          },
          {
            headerName: 'Requested',
            field: 'createdTimestamp',
            valueFormatter: (params) => {
              return this.formatDate(params.value, params.data.loggedSic);
            },
            minWidth: 150,
          },
        ];
        break;
      case ExceptionTypeCd.PARTIAL_BYPASS:
        columns = [
          ...columns,
          {
            headerName: 'Origin Sic',
            valueGetter: (params) => {
              return params.data.value?.split(',')[0];
            },
            minWidth: 80,
          },
          {
            headerName: 'Dest Sic',
            field: 'destinationSicCd',
            minWidth: 80,
          },
          {
            headerName: 'Trailer',
            field: 'trailerNbr',
            minWidth: 80,
          },
          {
            headerName: 'Cube(%)',
            cellRendererFramework: RemainingValueFormatterComponent,
            valueGetter: (params) => {
              return +this.remainingValue(params.data.value, 'cube');
            },
            minWidth: 80,
            type: 'numericColumn',
          },
          {
            headerName: 'Weight(Lbs)',
            cellRendererFramework: RemainingValueFormatterComponent,
            valueGetter: (params) => {
              return +this.remainingValue(params.data.value, 'weight');
            },
            minWidth: 80,
            type: 'numericColumn',
          },
          {
            headerName: 'Dispatched Time, Date',
            field: 'createdTimestamp',
            valueFormatter: (params) => {
              return this.formatDate(params.value, params.data.loggedSic);
            },
            minWidth: 150,
          },
          {
            headerName: 'ETA',
            field: 'value',
            valueFormatter: (params) => {
              const eta = params.value?.split(',')[3];
              if (eta !== 'N/A' && eta !== 'VIA') {
                return this.formatDate(new Date(eta).getTime(), params.data.loggedSic);
              } else {
                return eta;
              }
            },
            minWidth: 80,
          },
          this.commentColumnHandler(statusCode),
        ];
        break;
    }
    return columns;
  }

  // TODO: copied over, maybe change to Date FNS
  private formatAge(date: Date | number | string, sic: string): string {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return getAge(timeDate, timeDateNow);
  }

  // TODO fixed api should return good value
  private remainingValue(remVal: string, type: string): string {
    const retValue = remVal?.split('/');
    if (!retValue) {
      return '-';
    }

    switch (type) {
      case 'loads':
        const splitValue: string[] = retValue[0].split('-');
        const remLoads =
          splitValue.length - 1 === 1
            ? `${splitValue[splitValue.length - 1]} ${'Over'}`
            : `${splitValue[splitValue.length - 1]}`;
        return remLoads;
      case 'cube':
        return retValue[1];
      case 'weight':
        return retValue[2];
    }
  }

  private formatDate(date: Date | number | string, sic: string): string {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  private commentColumnHandler(statusCd: ExceptionStatusCd): ColDef {
    const column: ColDef = {
      headerName: 'Resolution',
      field: 'resolution',
      tooltipField: 'resolution',
      minWidth: 200,
    };

    if (statusCd === ExceptionStatusCd.RESOLVED) {
      column.headerName = 'Resolution Text';
    } else if (statusCd === ExceptionStatusCd.EXPIRED) {
      column.headerName = 'Expiry Text';
    } else if (statusCd === ExceptionStatusCd.CLOSED) {
      column.headerName = 'Comments';
    }

    return column;
  }
}
