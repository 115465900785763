import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { SpecialServiceGroupCredential } from '../../../shared/components/special-service-group/special-service-group.component';
import { DriverHelper } from '../../../shared/helpers/drivers.helper';
import { SchedulesGridItemDetailDriver } from '../../schedules-grid.model';

@Component({
  selector: 'app-driver-name-cell-renderer',
  templateUrl: './driver-name-cell-renderer.component.html',
  styleUrls: ['./driver-name-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverNameCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  name: string;
  credentials: SpecialServiceGroupCredential[] = [];
  phoneNumber: string;

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.params = params;
    const value: SchedulesGridItemDetailDriver = this.params.value ?? {};
    this.name = value.name;
    this.credentials = DriverHelper.getShownCredentials(value.credentials);
    this.phoneNumber = DriverHelper.phoneNumberFormatter(value.phoneNumber);
  }
}
