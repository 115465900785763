import { Injectable, OnDestroy } from '@angular/core';
import { XpoBoardData, XpoBoardDataFetchState, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { DriverTurnTypeCd, ModelGroupCd } from '@xpo-ltl/sdk-common';
import {
  Equipment,
  LaneSchedulesSummary,
  LinehaulOperationsApiService,
  ListAllowableSchedulesPath,
  ListAllowableSchedulesResp,
  LoadSchedulesSummary,
  PlanningDriver,
  TrailerInfo,
} from '@xpo-ltl/sdk-linehauloperations';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ComponentsEnum } from '../shared/enums/components.enum';
import { getGroupCodeFromTimezone } from '../shared/functions/get-group-code-from-timezone';
import { InteractionService } from '../shared/services/interaction.service';
import { LocationService } from '../shared/services/location.service';
import {
  SchedulesGridItem,
  SchedulesGridItemDetail,
  SchedulesGridItemDetailDriver,
  SchedulesGridItemDetailStatus,
  SchedulesGridItemDetailTractor,
  SchedulesGridItemDetailTrailer,
} from './schedules-grid.model';
import { SchedulesService } from './schedules.service';

@Injectable()
export class SchedulesDataSourceService extends XpoBoardDataSource implements OnDestroy {
  private readonly sicCdAndModelGroupCd$: Observable<{ sicCd: string; modelGroupCd: ModelGroupCd }>;
  private readonly filter$: Observable<{ shiftCd: string; sicCd: string; modelGroupCd: ModelGroupCd; period: string }>;
  private unsubscribe$ = new Subject<void>();

  isPilotSic$: Observable<boolean>;

  constructor(
    private linehaulOperationsApiService: LinehaulOperationsApiService,
    private schedulesService: SchedulesService,
    private locationService: LocationService,
    private interactionService: InteractionService
  ) {
    super();
    this.pageSize = 10000;
    this.sicCdAndModelGroupCd$ = this.schedulesService.sicCd$.pipe(
      switchMap((sicCd) => {
        return !sicCd
          ? of({ sicCd: null, modelGroupCd: null })
          : this.locationService
              .getModelGroupCode(sicCd?.toUpperCase())
              .pipe(
                map((data) => ({ sicCd, modelGroupCd: getGroupCodeFromTimezone(data.locationGroupCodes[0]?.regionCd) }))
              );
      }),
      shareReplay()
    );

    this.isPilotSic$ = this.schedulesService.sicCd$.pipe(
      distinctUntilChanged(),
      switchMap((sicCd) => this.locationService.isPilotSic(sicCd)),
      shareReplay()
    );

    this.filter$ = combineLatest([
      this.schedulesService.shift$,
      this.sicCdAndModelGroupCd$,
      this.schedulesService.period$.pipe(map(() => this.schedulesService.periodAsDateString)),
    ]).pipe(map(([shiftCd, { sicCd, modelGroupCd }, period]) => ({ shiftCd, sicCd, modelGroupCd, period })));

    this.filter$
      .pipe(
        distinctUntilChanged((prev, curr) =>
          ['shiftCd', 'sicCd', 'modelGroupCd', 'period'].every((key) => prev[key] === curr[key])
        ),
        filter(() => {
          // Avoiding double call when first load
          return this.stateCache.dataFetchState !== XpoBoardDataFetchState.Loading;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.refresh();
      });

    this.interactionService
      .subscribeToComponent(ComponentsEnum.REFRESH_DATA)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((refresh: boolean) => {
        if (refresh) {
          this.refresh();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return combineLatest([this.isPilotSic$, this.filter$]).pipe(
      switchMap(([isPilotSic, { shiftCd, sicCd, modelGroupCd, period }]) => {
        if (!isPilotSic || !shiftCd || !sicCd || !period) {
          return of(new XpoBoardData(state, [], 0, this.pageSize));
        }

        this.setState({ dataFetchState: XpoBoardDataFetchState.Loading, source: 'LHOForceLoad' });

        const request = new ListAllowableSchedulesPath();
        request.sicCd = sicCd.toUpperCase();
        request.plannedDate = <any>period;
        request.shiftCd = shiftCd;
        request.modelGroupCd = modelGroupCd;

        return this.linehaulOperationsApiService.listAllowableSchedules(request, { loadingOverlayEnabled: false }).pipe(
          map((resp) => {
            if (resp.schedulesMetricsSummary && resp.schedulesMetricsSummary.releaseStatus === 'Released') {
              this.schedulesService.schedulesMetrics = resp.schedulesMetricsSummary;
              const data = this.mapSchedulesResponseToGridModel(resp, request.sicCd);
              return new XpoBoardData(state, data, data.length, this.pageSize);
            } else {
              this.schedulesService.schedulesMetrics = null;
              return new XpoBoardData(state, [], 0, this.pageSize);
            }
          })
        );
      })
    );
  }

  dataUpdated$(): Observable<boolean> {
    return this.state$.pipe(
      filter((v) => v.changes.includes('data') && v.dataFetchState === XpoBoardDataFetchState.ResultsReturned),
      map(() => true)
    );
  }

  private mapSchedulesResponseToGridModel(resp: ListAllowableSchedulesResp, sic: string): SchedulesGridItem[] {
    const data: SchedulesGridItem[] = resp.allowableSchedulesSummary.map((summary) => {
      const laneSummary = summary.laneSchedulesSummary;
      const loadDetails: SchedulesGridItemDetail[] = this.sortItems(
        summary.loadSchedulesSummary?.map((load) => {
          return <SchedulesGridItemDetail>{
            driver1: this.planningDriverToGridDriver(load.driver1),
            driver2: this.planningDriverToGridDriver(load.driver2),
            trailer1: this.trailerInfoBuilder(load.trailer1),
            trailer2: this.trailerInfoBuilder(load.trailer2),
            trailer3: this.trailerInfoBuilder(load.trailer3),
            tractor: this.tractorInfoBuilder(load.tractor),
            viaSic: load.viaSic,
            cutTime: load.cutDateTime,
            arrivalTime: {
              estimate: load.viaInd ? load.viaEstimatedArrivalDateTime : load.estimatedArrivalDateTime,
              actual: load.viaInd ? load.viaActualArrivalDateTime : load.actualArrivalDateTime,
            },
            departureTime: {
              estimate: load.viaInd ? load.viaEstimatedDepartDateTime : load.estimatedDepartDateTime,
              actual: load.viaInd
                ? load.viaActualDepartDateTime
                : load.actualDepartDateTime ?? load.driver1?.lnhOriginDispatchDateTime,
            },
            lnhScheduleId: load.lnhScheduleId,
            vias: laneSummary.via,
            originSic: load.originSicCd,
            isTripleEnabled: (laneSummary.trpleCount || 0) > 0,
            isDoubleTurn: load.typeCd === DriverTurnTypeCd.DOUBLE_TURN,
            editableFields: this.getEditableFields(load, laneSummary),
            status: load.statusCd,
          };
        }) ?? []
      );

      return {
        sicCd: laneSummary.laneSic,
        sicCdDescription: laneSummary.stateCd
          ? `${laneSummary.sicDescription}, ${laneSummary.stateCd}`
          : `${laneSummary.sicDescription}`,
        dsrPlanned: laneSummary.plannedMetrics?.drvrCount,
        emptiesPlanned: laneSummary.plannedMetrics?.empties,
        overagePlanned: laneSummary.plannedOverages,
        incomingViaCount: laneSummary.viaHelp?.length,
        outgoingViaCount: laneSummary.via?.length,
        hssInd: laneSummary.hssInd,
        viaDetails: {
          incomingVias: laneSummary.viaHelp,
          outgoingVias: laneSummary.via,
          singlePupCount: laneSummary.snglPupCount || 0,
          singleVanCount: laneSummary.snglVanCount || 0,
          rmdCount: laneSummary.rmdCount || 0,
          dblTurnCount: laneSummary.dblTurnCount || 0,
          setCount: laneSummary.setCount || 0,
          tripleCount: laneSummary.trpleCount || 0,
          plannedDrivers: laneSummary.plannedMetrics?.drvrCount || 0,
          dispatchedDrivers: laneSummary.actualMetrics?.drvrCount || 0, // TODO Confirm the mapping
        },
        loadDetails,
      };
    });

    return data;
  }

  private planningDriverToGridDriver(driver: PlanningDriver | undefined): SchedulesGridItemDetailDriver | undefined {
    // TODO: update api not to send us an empty object for a blank driver
    return driver && JSON.stringify(driver) !== '{}'
      ? {
          name: driver.firstName ? `${driver.firstName} ${driver.lastName}` : undefined,
          id: driver.employeeId,
          credentials: driver.licenseInds,
          phoneNumber: driver.dsrCellPhone,
          seqNumber: driver.scheduledDriverSequenceNbr,
          domSic: driver.domicileSicCd,
          assignedLane: driver.assignedLane,
          seniorityRankNbr: driver.seniorityRankNbr,
          lnhOriginDispatchDateTime: driver.lnhOriginDispatchDateTime,
          status: driver.statusCd,
        }
      : undefined;
  }

  private trailerInfoBuilder(trailer: TrailerInfo | undefined): SchedulesGridItemDetailTrailer | undefined {
    return !!trailer
      ? {
          equipmentId: trailer.equipmentId,
          name: `${trailer.equipmentIdPrefix}-${trailer.equipmentIdSuffixNbr}`,
          loadedCubePct: trailer.loadedCubePct,
          currentStatus: trailer.currentStatus,
          indicators: trailer.indicators,
          loadedWgtLbs: trailer.loadedWgtLbs,
          seqNumber: trailer.scheduledEquipmentSequenceNbr,
        }
      : undefined;
  }

  private tractorInfoBuilder(tractor: Equipment | undefined): SchedulesGridItemDetailTractor | undefined {
    return !!tractor
      ? {
          equipmentId: tractor.equipmentId,
          name: `${tractor?.equipmentIdPrefix}-${tractor.equipmentIdSuffixNbr}`,
          domSic: tractor.dmclSicCd,
          status: tractor.currentStatus,
        }
      : undefined;
  }

  private getEditableFields(
    load: LoadSchedulesSummary,
    laneSummary: LaneSchedulesSummary
  ): Array<keyof SchedulesGridItemDetail> {
    const editableFields: Array<keyof SchedulesGridItemDetail> = [];
    const status: SchedulesGridItemDetailStatus = load.statusCd?.toUpperCase() as SchedulesGridItemDetailStatus;
    const isDispatchedCompleteOrTerminated = ['DISP', 'TERM', 'COMP'].some((v) => v === status);

    if (!isDispatchedCompleteOrTerminated && this.schedulesService.isToday()) {
      editableFields.push('tractor', 'trailer1', 'trailer2', 'trailer3', 'departureTime');

      if (load.viaInd || laneSummary.via?.length) {
        editableFields.push('viaSic');
      }

      // When the schedule has a incoming via driver that is assigned to their sic, we want to disable
      // changing the driver, we still want them to be able to change the trailer if it needs maintenance something.
      if (status !== 'ARIV' || (status === 'ARIV' && load.viaSic === this.schedulesService.sicCd)) {
        editableFields.push('driver1', 'driver2');
      }
    }

    return editableFields;
  }
  private sortItems(items: SchedulesGridItemDetail[]): SchedulesGridItemDetail[] {
    return items.sort((a, b) => {
      const aValue = a.departureTime?.actual ?? a.cutTime ?? 0;
      const bValue = b.departureTime?.actual ?? b.cutTime ?? 0;

      return aValue > bValue ? -1 : 1;
    });
  }
}
