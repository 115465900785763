import { Component } from '@angular/core';

@Component({
  selector: 'dispatch-header-formatter-cell',
  styleUrls: ['./dispatch-header-formatter.scss'],
  template: `
    <!-- <mat-icon *ngIf="!!params.param && params.hasIcon" svgIcon="circle-filled" [class]="params.param"> </mat-icon> -->
    <!-- <div class="ag-column-hover"> -->

    <div class="row-container">
      <p class="lane-sic" *ngIf="!!params && params.laneSic">{{ params.laneSic }}</p>
      <!-- <p class="circle" *ngIf="!!params && params.dsrDispatchedCount">{{ params.dsrDispatchedCount }}</p> -->
      <p class="circle">
        <span class="count">{{ params.dsrDispatchedCount }}</span>
      </p>
    </div>
    <!-- <span *ngIf="params.changeDescription" class="change-description">{{ params.changeDescription }}</span> -->
  `,
})
export class DispatchHeaderFormatterComponent {
  params: any;

  agInit(params: any): void {
    this.params = params.value;
  }
}
