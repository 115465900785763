import { Injectable } from '@angular/core';
import {
  ActiveSic,
  ListOperationalRegionsQuery,
  ListOperationalRegionsResp,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { XpoAdvancedSelectOption } from '@xpo-ltl/ngx-ltl-board';
import { HssRegionCd } from '@xpo-ltl/sdk-common';
import { combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LocationService {
  readonly activeSics$: Observable<ActiveSic[]>;
  readonly allOperationalLocations$: Observable<XpoAdvancedSelectOption[]>;
  readonly allLinehaulLocations$: Observable<XpoAdvancedSelectOption[]>;
  readonly allHSSLocations$: Observable<XpoAdvancedSelectOption[]>;

  constructor(locationApiService: LocationApiService) {
    this.activeSics$ = locationApiService.listAllActiveSics().pipe(
      map((data: any) => data.activeSics), // TODO: fix api to send back correct resp
      shareReplay()
    );

    this.allOperationalLocations$ = combineLatest([
      locationApiService.listOperationalRegions(new ListOperationalRegionsQuery()),
      this.activeSics$,
    ]).pipe(
      map(([r, activeSics]) => {
        const resp: ListOperationalRegionsResp = <any>r; // TODO: fix api to send back correct resp
        const groupBySicCdHash = this.groupBy(activeSics, <keyof ActiveSic>'sicCd');

        return resp.operationalRegions.map((operationalRegion) => {
          return <XpoAdvancedSelectOption>{
            label: operationalRegion.regionName,
            value: operationalRegion.regionCd,
            level: 0,
            children: operationalRegion.districts.map((district) => {
              return {
                label: `${district.districtCd} - ${district.districtName}`,
                value: district.districtCd,
                level: 1,
                children: district.sicCds
                  .map((sicCd) => {
                    const activeSicInstance = groupBySicCdHash[sicCd];
                    const activeSicValue = activeSicInstance ? activeSicInstance[0] : null; // 0 because grouping by sic, only going to be 1 entry

                    return activeSicValue
                      ? {
                          label: `${activeSicValue.sicCd} - ${activeSicValue.sicDescription}`,
                          value: sicCd,
                          level: 2,
                        }
                      : null;
                  })
                  .filter((v) => !!v),
              };
            }),
          };
        });
      }),
      shareReplay()
    );

    this.allLinehaulLocations$ = this.activeSics$.pipe(
      map((activeSics) => {
        const groupByRegionHash = this.groupBy(activeSics, <keyof ActiveSic>'region');

        return Object.entries(groupByRegionHash).map(([key, value]) => {
          return <XpoAdvancedSelectOption>{
            value: key,
            label: key,
            level: 0,
            children: value.map((v) => {
              return {
                value: v.sicCd,
                label: `${v.sicCd} - ${v.sicDescription}`,
                level: 1,
              };
            }),
          };
        });
      }),
      shareReplay()
    );

    this.allHSSLocations$ = of([
      { value: HssRegionCd.HSSU, label: 'HSSU Region', level: 'hssRegion' },
      { value: HssRegionCd.HSSX, label: 'HSSX Region', level: 'hssRegion' },
      { value: HssRegionCd.HSSLN, label: 'HSSLN Region', level: 'hssRegion' },
    ]).pipe(shareReplay());
  }

  private groupBy(array: any[], key: string): { [key: string]: ActiveSic[] } {
    return array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}
