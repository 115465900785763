import { Injectable } from '@angular/core';
import { XpoBoardData, XpoBoardDataSource, XpoBoardState } from '@xpo-ltl/ngx-ltl-board';
import { Observable, of } from 'rxjs';
import { InstructionsGridData } from './instructions-board.component';

@Injectable()
export class InstructionsBoardDataSourceService extends XpoBoardDataSource {
  data: InstructionsGridData[] = [
    {
      sicCode: 'UPO',
      loadsOffDockCount: 3,
      bypassLoadsCount: 2,
      loadsTotal: 5,
      dsrCount: 6,
      diversionsPercent: 40,
      weightPlanned: 1540,
      cubePlannedPercent: 78,
      loadAveragePlanned: 'Test',
      trailerUtilizationCapabilityPercent: 11,
      emptiesCount: 1,
      overagesCount: 0,
      viasIncoming: 'NCH',
      viasOutgoing: 'NCH',
      sectorCode: 'ABC',
    },
    {
      sicCode: 'UPO',
      loadsOffDockCount: 3,
      bypassLoadsCount: 2,
      loadsTotal: 5,
      dsrCount: 6,
      diversionsPercent: 40,
      weightPlanned: 1540,
      cubePlannedPercent: 78,
      loadAveragePlanned: 'Test',
      trailerUtilizationCapabilityPercent: 11,
      emptiesCount: 1,
      overagesCount: 0,
      viasIncoming: 'NCH',
      viasOutgoing: 'NCH',
      sectorCode: 'ABC',
    },
  ];

  fetchData(state: XpoBoardState): Observable<XpoBoardData> {
    return of(new XpoBoardData(state, this.data, this.data.length, 50));
  }
}
