import { Component, Injector, OnDestroy } from '@angular/core';
import { Unsubscriber } from '@xpo-ltl/ngx-ltl';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DrawerLauncherService } from '../../../core/drawer-launcher/drawer-launcher.service';
import { ViaDetails } from '../../schedules-grid.model';
import { ViaDetailsDrawerComponent, ViaDetailsDrawerData } from '../../via-details-drawer/via-details-drawer.component';

@Component({
  selector: 'app-move-to-cell-renderer',
  templateUrl: './move-to-cell-renderer.component.html',
  styleUrls: ['./move-to-cell-renderer.component.scss'],
  host: { '[class.app-MoveToCellRenderer--noExpand]': 'disabledExpand' },
})
export class MoveToCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  params: ICellRendererParams;
  disabledExpand: boolean;
  sicCd: string;
  sicDescription: string;
  viaDetails: ViaDetails;
  hssInd: string;

  private unsubscribe$: Unsubscriber = new Unsubscriber();

  constructor(private drawerLauncherService: DrawerLauncherService, private injector: Injector) {}

  ngOnDestroy(): void {
    this.unsubscribe$.complete();
  }

  refresh(params: ICellRendererParams): boolean {
    this.mapParamsToTemplateValues(params);
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.mapParamsToTemplateValues(params);
  }

  launchDialog(): void {
    this.drawerLauncherService.launch({
      component: ViaDetailsDrawerComponent,
      destroyDrawer$: this.unsubscribe$.done$,
      injector: this.injector,
      data: <ViaDetailsDrawerData>{
        sicCd: this.sicCd,
        sicDescription: this.sicDescription,
        viaDetails: this.viaDetails,
      },
    });
  }

  private mapParamsToTemplateValues(params: ICellRendererParams): void {
    this.params = params;
    this.disabledExpand = !params.data.loadDetails.length;
    this.sicCd = params.data.sicCd;
    this.hssInd = params.data.hssInd; // TODO this.hssIndToIcon(params.data.hssInd)
    this.sicDescription = params.data.sicCdDescription;
    this.viaDetails = params.data.viaDetails;
  }

  // TODO
  // hssIndToIcon(hssInd: string): string | null {
  //   if (!hssInd) {
  //     return null;
  //   }

  //   const v = hssInd.toUpperCase();
  //   switch (v) {
  //     case 'EXP':
  //       return 'ss_expedite';
  //     case 'SUP':
  //       return 'ss_supplemental';
  //   }
  // }
}
