import { HorizontalConnectionPos, VerticalConnectionPos } from '@angular/cdk/overlay';
import { Component } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { isEmpty as _isEmpty } from 'lodash';
import { IconsKeys } from '../../enums/icons.enum';

@Component({
  selector: 'poma-trailer-formatter-cell',
  styleUrls: ['./poma-trailer-formatter.scss'],
  template: `
    <div class="trailer-container">
      <div>
        <p *ngIf="!!params && !hasEMPYIndicator()">{{ trailerNum }} {{ loadDestSic }}</p>
        <p *ngIf="!!params && hasEMPYIndicator()">{{ trailerNum }}</p>
        <p *ngIf="!params">-</p>
      </div>
      <mat-icon *ngFor="let ind of indicators" [svgIcon]="ind" [class]="ind"></mat-icon>
    </div>
  `,
})
export class PomaTrailerFormatterComponent {
  params: any;
  readonly IconsKeys = IconsKeys;
  indicators: Array<IconsKeys> = [];
  trailerNum: string;
  loadDestSic: string;
  agInit(params: any): void {
    this.params = params.value;
    if (!!this.params) {
      this.trailerNum = `${this.params.equipmentIdPrefix.replace(/^0/, '')}-${String(
        this.params.equipmentIdSuffixNbr
      ).padStart(4, '0')}`;
      // tslint:disable-next-line:no-unused-expression
      this.params.icons.some((val) => val === 'G') && this.indicators.push(IconsKeys.guaranteed);
      // tslint:disable-next-line:no-unused-expression
      this.params.icons.some((val) => val === 'HZ') && this.indicators.push(IconsKeys.hazmat);
      // tslint:disable-next-line:no-unused-expression
      this.params.icons.some((val) => val === 'FRZ') && this.indicators.push(IconsKeys.freezable);
      // tslint:disable-next-line:no-unused-expression
      this.params.icons.some((val) => val === 'EMTY') && this.indicators.push(IconsKeys.empty);
      this.loadDestSic = !!this.params.loadDestSic ? `(${this.params.loadDestSic})` : '';
    }
  }
  hasEMPYIndicator(): boolean {
    return this.params.icons.some((val) => val === 'EMTY');
  }
}

export interface TrailerFormatterParams {
  equipmentIdPrefix: string;
  equipmentIdSuffixNbr: string;
  loadDestSic: string;
  icons: string[];
}
