import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { map as _map } from 'lodash';
import { maxBy as _maxBy } from 'lodash';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { getAge, getShift } from '../../../utils/data-transform-utils';
import { BufiTable } from '../../enums/alerts-dashboard-tables.enum';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
@Injectable()
export class BufiAlertBoardTemplate extends XpoAgGridBoardViewTemplate {
  static templateId = 'Partial Bypass';
  static _clickSubject = new BehaviorSubject<any>(0);
  clickSubject$ = BufiAlertBoardTemplate._clickSubject.asObservable();
  getAge = getAge;
  getShift = getShift;
  sidePanelOpts: SidePanelOpts;
  isAllowed: boolean = false;

  constructor(
    private decimalPipe: DecimalPipe,
    private timeService: XpoLtlTimeService,
    public alertStatus: ExceptionStatusCd
  ) {
    super({
      id: BufiAlertBoardTemplate.templateId,
      name: 'Partial Bypass',
      keyField: 'partialBypass',
      allowAdditional: false,
      availableColumns: [
        {
          headerName: 'Actions',
          field: 'action',
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          cellRenderer: 'IconsFormatterComponent',
          cellClass: 'iconColumn',
          valueGetter: () => {
            return 'visibility';
          },
          hide: true,
        },
        {
          headerName: BufiTable.AGE,
          field: 'age',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatAge(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          hide: true,
          minWidth: 100,
        },
        {
          headerName: BufiTable.ORIGIN_SIC,
          field: 'origin_sic',
          valueGetter: (params) => {
            return params.data.operationException.value.split(',')[0];
          },
          minWidth: 80,
        },
        {
          headerName: BufiTable.DEST_SIC,
          field: 'dest_sic',
          valueGetter: (params) => {
            return params.data.operationException.destinationSicCode;
          },
          minWidth: 80,
        },
        {
          headerName: BufiTable.TRAILER,
          field: 'trailer',
          valueGetter: (params) => {
            return params.data.operationException.trailerNbr;
          },
          minWidth: 80,
        },
        {
          headerName: BufiTable.CUBE,
          field: 'cube',
          cellRenderer: 'RemainingValueFormatterComponent',
          valueGetter: (params) => {
            return +this.remainingValue(params.data.operationException.value, 'cube');
          },
          minWidth: 80,
          cellClass: 'ag-right-aligned-cell',
        },
        {
          headerName: BufiTable.WEIGHT,
          field: 'weight',
          cellRenderer: 'RemainingValueFormatterComponent',
          valueGetter: (params) => {
            return +this.remainingValue(params.data.operationException.value, 'weight');
          },
          minWidth: 80,
          cellClass: 'ag-right-aligned-cell',
        },
        {
          headerName: BufiTable.TIME_DATE,
          field: 'time_date',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatDate(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          minWidth: 150,
        },
        {
          headerName: BufiTable.ARRIVAL_ETA,
          field: 'arrival_eta',
          valueGetter: (params) => {
            return params.data.operationException.value.split(',')[3];
          },
          valueFormatter: (params) => {
            const eta = params.data.operationException.value.split(',')[3];
            if (eta !== 'N/A' && eta !== 'VIA') {
              const date = moment(eta, 'YYYY/MM/DD-HH:mm:ss').valueOf();
              return this.formatDate(date, params.data.operationException.loggedSic);
            } else {
              return eta;
            }
          },
          minWidth: 80,
        },
        {
          headerName: BufiTable.RESOLUTION_TEXT,
          field: 'resolution_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: BufiTable.COMMENTS_TEXT,
          field: 'comment_text',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: BufiTable.EXPIRY_TEXT,
          field: 'expiry_text',
          valueGetter: (params) => {
            return params.data.resolution;
          },
          hide: true,
          minWidth: 150,
        },
      ],
    });
  }

  createBufiAlert(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'PartialBypass',
      name: 'Partial Bypass',
    });
  }

  formatDate(date, sic): string {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  formatAge(date, sic): string {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return this.getAge(timeDate, timeDateNow);
  }
  remainingValue(remVal, type): any {
    const retValue = remVal.split(',');
    switch (type) {
      case 'loads':
        return retValue[0];
      case 'cube':
        return retValue[1];
      case 'weight':
        return retValue[2];
    }
  }
}
