import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AnalyticsApiService,
  GetLookerAccessTokenResp,
  GetLookerAccessTokenRqst,
  GetLookerSSOUrlResp,
  GetLookerSSOUrlRqst,
} from '@xpo-ltl-2.0/sdk-analytics';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ApiRequest } from '@xpo-ltl/data-api';
import { of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ConfigManagerProperties } from '../shared/enums/config-manager-properties.enum';
@Component({
  selector: 'app-user-metrics',
  templateUrl: './user-metrics.component.html',
  styleUrls: ['./user-metrics.component.scss'],
})
export class UserMetricsComponent implements OnInit, OnDestroy {
  url: string;
  showIframe: boolean = false;
  targetUrl: string;
  lookerReq: GetLookerAccessTokenRqst = {
    payload: '',
  };
  ssoReq: GetLookerSSOUrlRqst = {
    target_url: null,
    session_length: 0,
    force_logout_login: false,
    looker_auth: null,
  };

  constructor(private analyticsService: AnalyticsApiService, private configManagerService: ConfigManagerService) {
    const api = this.configManagerService.getSetting<string>(ConfigManagerProperties.apiUrl);
    if (api === 'https://tctsi-api-gke.ltl.xpo.com' || api === 'https://tctsi-api.ltl.xpo.com') {
      this.targetUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.lookerDev);
    } else if (api === 'https://tcts5-api-gke.ltl.xpo.com' || api === 'https://tcts5-api.ltl.xpo.com') {
      this.targetUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.lookerStaging);
    } else if (api === 'https://apiapp-c.ltl.xpo.com' || api === 'https://tctss-api.ltl.xpo.com') {
      this.targetUrl = this.configManagerService.getSetting<string>(ConfigManagerProperties.lookerMaster);
    }
  }

  ngOnInit() {
    this.getAccessToken();
  }

  private getAccessToken() {
    this.analyticsService
      .getLookerAccessToken(this.lookerReq, ApiRequest.concealedCall)
      .pipe(
        catchError(() => of(undefined)),
        take(1)
      )
      .subscribe((token: GetLookerAccessTokenResp) => {
        if (token) {
          this.ssoReq.looker_auth = token.access_token;
          this.ssoReq.target_url = this.targetUrl;
          this.ssoReq.session_length = token.expires_in;
          this.analyticsService
            .getLookerSSOUrl(this.ssoReq, ApiRequest.concealedCall)
            .pipe(
              catchError(() => of(undefined)),
              take(1)
            )
            .subscribe((urlResp: GetLookerSSOUrlResp) => {
              if (urlResp) {
                this.url = urlResp.url;
                this.showIframe = true;
              }
            });
        }
      });
  }

  ngOnDestroy() {}
}
