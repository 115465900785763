import { DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocationApiService } from '@xpo-ltl-2.0/sdk-location';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { Unsubscriber, XpoLtlServiceCentersService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { ExceptionStatusCd, ExceptionTypeCd } from '@xpo-ltl/sdk-common';
import { Exception } from '@xpo-ltl/sdk-exceptionmanagement';
import { AgGridEvent, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentsEnum } from '../../../shared/enums/components.enum';
import { RemainingValueFormatterComponent } from '../../../shared/formatters/ag-grid-cell-formatters/remaining-value-formatter';
import { InteractionServiceResp } from '../../../shared/models/interaction-service-response.model';
import { getAge } from '../../../utils/data-transform-utils';
import { AlertsType } from '../../enums/alerts.enum';
import { IconsFormatterComponent } from '../../formatters/ag-grid-cell-formatters/icons-formatter';
import { RequesterCommentFormatterComponent } from '../../formatters/ag-grid-cell-formatters/requester-comment-formatter';
import { AuthService } from '../../services/auth.service';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';
import { TablesDataSourceService } from '../tables-data-source/tables-data-source.service';
import { InteractionService } from './../../../shared/services/interaction.service';
import { SidePanelVisibilityService } from './../../services/side-panel-visibility.service';
import { BufiAlertBoardTemplate } from './bufi-alert-view-board-template';

@Component({
  selector: 'app-bufi-alert-table',
  templateUrl: './bufi-alert-table.component.html',
  styleUrls: ['./bufi-alert-table.component.scss'],
})
export class BufiAlertTableComponent implements OnInit, OnDestroy {
  @Input('dataSource')
  set dataSource(value: Exception[]) {
    this.tablesSourceService.dataSource = value;
  }
  @Input() actions: boolean;
  @Input() tableTab: ExceptionStatusCd;
  @Input() tableType = ExceptionTypeCd;

  getAge = getAge;
  isAllowed: boolean = false;
  viewTemplates: BufiAlertBoardTemplate[];
  views: XpoBoardView[];
  loadsCollection: any = [];
  sizeToFit: boolean = true;
  gridOptions: GridOptions = {
    suppressHorizontalScroll: false,
    suppressDragLeaveHidesColumns: true,
    frameworkComponents: {
      RemainingValueFormatterComponent: RemainingValueFormatterComponent,
      IconsFormatterComponent: IconsFormatterComponent,
      RequesterCommentFormatterComponent: RequesterCommentFormatterComponent,
    },
    defaultColDef: {
      resizable: true,
      sortable: true,
      suppressMenu: true,
    },
    headerHeight: 40,
    rowHeight: 30,
    onColumnResized: (event) => {
      if (event.source === 'uiColumnDragged' && event.finished) {
        this.gridApi.sizeColumnsToFit();
      }
    },
  };
  sidePanelOpts: SidePanelOpts;
  showTable = false;
  sidePanelDisplay: boolean = false;
  sidePanelId: string;
  iframeUrl: string;
  gridApi: GridApi;
  private colApi: ColumnApi;
  private unsubscriber = new Unsubscriber();
  selectedSic;
  tablesSource: any;
  subscribeToDestroy: Subscription;
  globalFilterSubscription: Subscription;
  currentRoute: String;
  private visibleColumns: { [key: string]: string[] } = {};

  constructor(
    private dialog: MatDialog,
    private timeService: XpoLtlTimeService,
    private tablesSourceService: TablesDataSourceService,
    private decimalPipe: DecimalPipe,
    public serviceCentersService: XpoLtlServiceCentersService,
    public locationApiService: LocationApiService,
    private interactionService: InteractionService,
    private authService: AuthService,
    private sidePanelVisibilityService: SidePanelVisibilityService
  ) {
    this.tablesSource = this.tablesSourceService;
  }

  ngOnInit(): void {
    this.visibleColumns[ExceptionStatusCd.RESOLVED] = ['resolution_text'];
    this.visibleColumns[ExceptionStatusCd.ACKNOWLEDGED] = ['age'];
    this.visibleColumns[ExceptionStatusCd.NEW] = ['age'];
    this.visibleColumns[ExceptionStatusCd.EXPIRED] = ['expiry_text'];
    this.visibleColumns[ExceptionStatusCd.CLOSED] = ['comment_text'];

    this.visibleColumns[ExceptionStatusCd.NEW].push('action');
    this.visibleColumns[ExceptionStatusCd.ACKNOWLEDGED].push('action');

    this.gridOptions.onCellClicked = this.handleClick.bind(this);
    this.initSubscriptions();
    setInterval(() => {
      if (this.gridApi) {
        this.gridApi.refreshCells();
      }
    }, 60000);

    this.viewTemplates = [new BufiAlertBoardTemplate(this.decimalPipe, this.timeService, this.tableTab)];
    this.views = [this.viewTemplates[0].createBufiAlert()];
  }

  ngOnDestroy(): void {
    this.gridApi.destroy();
    this.unsubscriber.complete();
  }

  initSubscriptions(): void {
    this.globalFilterSubscription = this.interactionService
      .subscribeToComponent(ComponentsEnum.GLOBAL_FILTERS)
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((resp: InteractionServiceResp) => {
        this.sidePanelDisplay = false;
        this.selectedSic = resp.data.sic;
      });

    this.interactionService.refreshAggridData.pipe(takeUntil(this.unsubscriber.done$)).subscribe(() => {
      if (this.tablesSource) {
        this.tablesSource.refresh();
      }
    });
    this.subscribeToDestroy = this.interactionService.destroyAggridTable.subscribe(() => {
      if (this.gridApi) {
        this.gridApi.destroy();
      }
    });
    this.interactionService
      .getRoute()
      .pipe(takeUntil(this.unsubscriber.done$))
      .subscribe((route) => (this.currentRoute = route));
  }

  onLoadsGridReady(gridEvent: AgGridEvent): void {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
    this.setColumnVisibility();

    if (this.tablesSourceService.dataSource.length === 1) {
      this.openPanel(this.tablesSource.dataSource[0]);
    }
  }

  handleClick(element): void {
    this.interactionService.setRoute(this.currentRoute);
    this.openPanel(element.data);
  }

  openPanel(data): void {
    this.sidePanelVisibilityService.openPanel(AlertsType.PARTIAL_BYPASS, data);
  }

  private setColumnVisibility(): void {
    if (this.visibleColumns[this.tableTab]) {
      this.colApi.setColumnsVisible(this.visibleColumns[this.tableTab], true);
    }
  }
}
