<xpo-board [dataSource]="tablesSource"
    [viewTemplates]="viewTemplates"
    [views]="views"
    hideViewSwitcher="true">
  <xpo-ag-grid-board (gridReady)="onLoadsGridReady($event)"
      [customGridOptions]="gridOptions"
      rowModelType="client-side"
      rowSelection="single"
      [sizeColumnsToFit]="true"
      enableMovableColumns="true">
  </xpo-ag-grid-board>
</xpo-board>
