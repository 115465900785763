export enum GMisloadTable {
  AGE = 'Elapsed Time',
  MISLOADED_AT = 'Misloaded At',
  DEST_SIC = 'Dest. SIC',
  MISLOADED_TO = 'Misloaded to',
  PRO_NBR = 'PRO',
  TRAILER_NBR = 'Trailer',
  SERVICE_DATE = 'Service Date',
  CALC_DELIVERY = 'Calc. Delivery',
  SHIFT = 'Shift',
  TIME_DATE = 'Time, Date',
  DOOR = 'Door',
  COMMENTS = 'Comments',
  CLOSED_BY = 'Closed By',
  ACKNOWLEDGED_BY = 'Acknowledged By',
  EXPIRY_TEXT = 'Expiry Text',
  RESOLUTION_TEXT = 'Resolution Text',
  TYPE = 'Type',
}

export enum LoadRequestTable {
  AGE = 'Elapsed Time',
  REQUEST_TYPE = 'Request Type',
  REQUESTING = 'Requesting',
  LANE = 'Lane',
  NBR_LOADS = 'Loads',
  LOADS_DOCK = 'Loads Off Dock',
  REQUESTED_BY = 'Requested By',
  REASON = 'Reason',
  STATUS_APPROVED = 'Approved Loads',
  STATUS_CANCELED = 'Declined Loads',
  REQUESTED = 'Requested',
  ACTION_COMMENT = 'Approve/Decline Comments',
  DECISION_REASON = 'Decision Reason',
  RESPONDED = 'Responded',
  CLOSED = 'Closed',
  RESPONDED_BY = 'Responded By',
  CLOSED_BY = 'Closed By',
  SHIFT = 'Shift',
  COMMENT = 'Requester Comment',
  STATUS = 'Status',
}

export enum HSSTable {
  ACTIONS = 'Actions',
  AGE = 'Elapsed Time',
  SIC = 'SIC',
  LOGGED_SIC = 'SIC',
  DEST_SIC = 'Dest. SIC',
  TRAILER = 'Trailer',
  DOOR = 'Door',
  CLOSED_BY = 'Closed By',
  CONTROL_NUMBER = 'Control Number',
  BOOKING_NBR = 'Booking',
  WEIGHT = 'Weight (Lbs)',
  CUBE = 'Cube (%)',
  SHIFT = 'Shift',
  STATUS = 'Status',
  TIME_DATE = 'Time, Date',
  RESOLUTION_TEXT = 'Resolution Text',
  RES_EXP_TEXT = 'Resolution/Expiry Text',
  EXPIRY_TEXT = 'Expiry Text',
  COMMENTS_TEXT = 'Comments',
  ACKNOWLEDGED_BY = 'Acknowledged By',
  ALERT_CLOSED_BY = 'Alert Closed By',
}

export enum ProgressiveTable {
  AGE = 'Elapsed Time',
  SIC = 'SIC',
  LANE = 'Lane',
  R_LOADS = 'Remaining Loads',
  R_WEIGHT = 'Remaining Weight (Lbs)',
  R_CUBE = 'Remaining Cube',
  TIME_DATE = 'Time, Date',
  SHIFT = 'Shift',
  RESOLUTION_TEXT = 'Resolution Text',
  COMMENTS_TEXT = 'Comments',
  CLOSED_BY = 'Closed By',
  ACKNOWLEDGED_BY = 'Acknowledged By',
  EXPIRY_TEXT = 'Expiry Text',
}

export enum BufiTable {
  AGE = 'Elapsed Time',
  ORIGIN_SIC = 'Origin Sic',
  DEST_SIC = 'Dest Sic',
  TRAILER = 'Trailer',
  CUBE = 'Cube(%)',
  WEIGHT = 'Weight(Lbs)',
  ARRIVAL_ETA = 'ETA',
  TIME_DATE = 'Dispatched Time, Date',
  RESOLUTION_TEXT = 'Resolution Text',
  COMMENTS_TEXT = 'Comments',
  EXPIRY_TEXT = 'Expiry Text',
}
