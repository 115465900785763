import { ChangeDetectionStrategy, ChangeDetectorRef, Component, InjectionToken, Injector } from '@angular/core';
import { MatDrawerContainer } from '@angular/material/sidenav';
import { LauncherComponentBase } from '../launcher/launcher-component-base';
import { DrawerLauncherService } from './drawer-launcher.service';

export const DRAWER_DATA = new InjectionToken<any>('LHODrawerData');

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent extends LauncherComponentBase {
  constructor(
    drawerLauncherService: DrawerLauncherService,
    private matDrawerContainer: MatDrawerContainer,
    injector: Injector,
    cd: ChangeDetectorRef
  ) {
    super(drawerLauncherService, injector, cd, DRAWER_DATA, DrawerComponent);
  }

  protected handleOpen(): void {
    this.matDrawerContainer.open();
  }

  protected handleClose(): void {
    this.matDrawerContainer.close();
  }
}
