import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { ExceptionStatusCd, ExceptionTypeCd, UserRoleCd } from '@xpo-ltl/sdk-common';
import {
  LinehaulOperationsApiService,
  ListModelInstructionsPath,
  ListModelInstructionsQuery,
  ListModelInstructionsResp,
} from '@xpo-ltl/sdk-linehauloperations';
import { ListLocationGroupCodesResp, LocationApiService } from '@xpo-ltl/sdk-location';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import * as moment from 'moment-timezone';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, share, take, tap } from 'rxjs/operators';
import { IconsKeys } from '../../enums/icons.enum';
import { TabTitles } from '../../enums/tab-titles';
import { getGroupCodeFromTimezone } from '../../functions/get-group-code-from-timezone';
import { GlobalFilters } from '../../models/global-filters';
import { SidePanelData } from '../../models/side-panel-data.model';
import { AuthService } from '../../services/auth.service';
import { InteractionService } from '../../services/interaction.service';
import { LocationService } from '../../services/location.service';
import { UserRoleService } from '../../services/user-role/user-role.service';
import { SidePanelModelData } from '../side-panel-model-data';
import { SidePanelType } from './../../enums/side-panel-type.enum';
import { SidePanelVisibilityService } from './../../services/side-panel-visibility.service';
@Component({
  selector: 'side-panel-wrapper',
  templateUrl: './side-panel-wrapper.component.html',
  styleUrls: ['./side-panel-wrapper.component.scss'],
})
export class SidePanelWrapperComponent implements OnInit, OnDestroy {
  ExceptionTypeCd = ExceptionTypeCd;
  ExceptionStatusCd = ExceptionStatusCd;
  sidePanelData: SidePanelData;
  showSidePanel: boolean;
  type: ExceptionTypeCd;
  title: string;
  controlNumber: string;
  subTitle: string = '';
  status: string;
  reset: boolean = false;
  sic: string;
  date: any;
  shift: string;
  lane: any;
  laneData: any;
  moment = moment;
  topTable: any;
  header: boolean = false;
  indicators: IconsKeys[] = [];
  expandedPanel: boolean = false;
  changeWidth: boolean = false;
  modelData: SidePanelModelData = {
    topTable: null,
    laneData: null,
    results: false,
    searching: true,
    instructions: false,
  };

  private lastId: number;
  private loadDataSubscription: Subscription;
  private showSidePanelSubscription: Subscription;

  constructor(
    private sidePanelVisibilityService: SidePanelVisibilityService,
    private linehaulOpApiService: LinehaulOperationsApiService,
    private loggingApiService: LoggingApiService,
    private locationApiService: LocationApiService,
    private locationService: LocationService,
    private datePipe: DatePipe,
    private xpoSnackBar: XpoSnackBar,
    private interactionService: InteractionService,
    private timeService: XpoLtlTimeService,
    private userRoleService: UserRoleService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.showSidePanelSubscription = this.sidePanelVisibilityService
      .subscribeToComponent(SidePanelType.showSidePanel)
      .subscribe((showPanel) => {
        this.showSidePanel = showPanel;
        if (showPanel) {
          this.loadData();
        } else {
          if (this.loadDataSubscription) {
            this.loadDataSubscription.unsubscribe();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.closePanel();
    if (this.showSidePanelSubscription) {
      this.showSidePanelSubscription.unsubscribe();
    }
  }

  private loadData(): void {
    const alertSidePanel$ = this.sidePanelVisibilityService
      .subscribeToComponent(SidePanelType.alertSidePanel)
      .pipe(take(1));

    this.loadDataSubscription = alertSidePanel$.subscribe((data) => {
      if (data) {
        this.status = data.sideData.statusCd;
        this.controlNumber = data.alert.operationException.controlNumber;
        if (data.alert.exceptionInstId !== this.lastId) {
          this.resetPanel();
          this.interactionService.setSelectedTrailer(data.alert.operationException.moveToSicCode);
          this.setLastId(data.alert.exceptionInstId);
          this.showSidePanel = true && data;
          this.sidePanelData = data;
          this.type = data.sideData.typeCd;
          if (this.isLoadRequestOrPL) {
            this.header = true;
            this.title =
              TabTitles.getTabTitle(data.alert.operationException.exceptionTypeCd) +
              ' at ' +
              data.alert.operationException.loggedSic;
            const modelReq = {
              sic: data.alert.operationException.loggedSic,
              date: data.alert.auditInfo.createdTimestamp,
              shift: data.alert.operationException.shiftCd,
              lane: data.alert.operationException.destinationSicCode,
            };
            const filters: GlobalFilters = { sic: 'LAB', shift: 'O', planDate: '', region: '' };
            // this.interactionService.setGlobalFilters(filters);
            this.getModel(modelReq);
          } else {
            this.title = TabTitles.getTabTitle(data.sideData.typeCd);
            if (data.sideData.typeCd === ExceptionTypeCd.GMISLOAD) {
              this.title = `Guaranteed Misloaded at ${data.alert.operationException.loggedSic}`;
              this.expandedPanel = true;
            }
            if (data.sideData.typeCd === ExceptionTypeCd.HSS) {
              this.title =
                this.title +
                ' at ' +
                data.alert.operationException.loggedSic +
                ' for ' +
                data.alert.operationException.trailerNbr;
            } else if (data.sideData.typeCd === ExceptionTypeCd.PARTIAL_BYPASS) {
              this.title = `Bump And Fill : ${data.alert.operationException.trailerNbr}`;
            }
          }
        }
        const globalFilters: GlobalFilters = {
          sic: data.alert.operationException.loggedSic,
          planDate: data.alert.operationException.planDate,
          region: data.alert.operationException.operationalRegion,
          shift: data.alert.operationException.shiftCd,
        };
        this.interactionService.setGlobalFilters(globalFilters);
      }
    });
  }

  private setType(type): any {
    return type === ExceptionTypeCd.ADD_LOAD_REQUEST ? 'Add Load Request' : 'Cut Load Request';
  }

  private setLastId(id): void {
    this.lastId = id;
  }

  closePanel(): void {
    this.resetValues();
    this.sidePanelVisibilityService.closePanels();
  }

  resetPanel(): void {
    this.resetValues();
    setTimeout(() => {
      this.reset = false;
    }, 0);
    this.reset = true;
  }

  resetValues(): void {
    this.sidePanelData = null;
    this.modelData = {
      topTable: null,
      laneData: null,
      results: false,
      searching: true,
      instructions: false,
    };
    this.laneData = null;
    this.header = false;
    this.sic = null;
    this.date = null;
    this.shift = null;
    this.lane = null;
    this.lastId = null;
  }

  getModel({ sic, date, shift, lane }): void {
    this.locationService
      .getModelGroupCode(sic)
      .pipe(
        map((ListLocation: ListLocationGroupCodesResp) => ListLocation.locationGroupCodes[0].regionCd),
        mergeMap((regionCd: string) => {
          const laneInstructions$: Observable<ListModelInstructionsResp> = this.getLaneInstructions(
            sic,
            date,
            shift,
            regionCd,
            lane
          );
          return laneInstructions$;
        })
      )
      .subscribe((laneInstructionsResp: ListModelInstructionsResp) => {
        this.setData(laneInstructionsResp.moveLaneInstructionSummary, lane);
      });
  }

  get isLoadRequestOrPL() {
    return (
      this.type === ExceptionTypeCd.ADD_LOAD_REQUEST ||
      this.type === ExceptionTypeCd.CUT_LOAD_REQUEST ||
      this.type === ExceptionTypeCd.LOAD_REQUEST ||
      this.type === ExceptionTypeCd.PROGRESSIVE_LOAD
    );
  }

  setData(data, lane): void {
    const filteredLane = data.filter(function(lanes): boolean {
      return lanes.instructionSummary.lane === lane;
    });
    if (filteredLane.length) {
      this.topTable = filteredLane[0].instructionSummary || null;
      this.laneData = filteredLane[0].loadLaneInstructionSummary;
      if (this.topTable) {
        const loads = {
          planned: (this.topTable.planned && this.topTable.planned.loadCount) || 'N/A',
          loaded: (this.topTable.loaded && this.topTable.loaded.loadCount) || 'N/A',
          remaining: (this.topTable.remaining && this.topTable.remaining.loadCount) || 'N/A',
        };
        const weight = {
          planned: (this.topTable.planned && this.topTable.planned.weight) || 'N/A',
          loaded: (this.topTable.loaded && this.topTable.loaded.weight) || 'N/A',
          remaining: (this.topTable.remaining && this.topTable.remaining.weight) || 'N/A',
        };
        const cube = {
          planned: (this.topTable.planned && this.topTable.planned.cube) || 'N/A',
          loaded: (this.topTable.loaded && this.topTable.loaded.cube) || 'N/A',
          remaining: (this.topTable.remaining && this.topTable.remaining.cube) || 'N/A',
        };
        const loadAverage = {
          planned: (this.topTable.planned && this.topTable.planned.loadAverage) || 'N/A',
          loaded: (this.topTable.loaded && this.topTable.loaded.loadAverage) || 'N/A',
          remaining: (this.topTable.remaining && this.topTable.remaining.loadAverage) || 'N/A',
        };
        const hss = {
          planned: (this.topTable.plannedHss && this.topTable.plannedHss.loadCount) || 'N/A',
          loaded: (this.topTable.loadedHss && this.topTable.loadedHss.loadCount) || 'N/A',
          remaining: 'N/A', // Not existing in API currently / Not clear from UX for now
        };
        const tuc = {
          planned: this.topTable.trailerUtilizationCapability || 'N/A',
          loaded: this.topTable.closedTrailerUtilization || 'N/A',
          remaining: 'N/A', // Not existing in API currently / Not clear from UX for now
        };
        this.setIndicators(this.topTable);

        const planned = {
          empties: this.topTable.plannedEmpty || 'NONE',
        };
        const topHeader = {
          loads: loads,
          weight: weight,
          cube: cube,
          loadAverage: loadAverage,
          hss: hss,
          tuc: tuc,
          planned: planned,
          remaining: this.topTable.remaining,
          indicators: this.indicators,
          bypassLoadCount: this.topTable.bypassLoadCount,
        };

        this.modelData = {
          topTable: topHeader || null,
          laneData: this.laneData || null,
          results: true,
          searching: false,
          instructions: true,
        };
      }
    } else {
      this.modelData = {
        topTable: null,
        laneData: null,
        results: false,
        searching: false,
        instructions: false,
      };
    }
  }

  getLaneInstructions(
    sicCd: string,
    plannedDate: Date,
    shift: string,
    regionCd: string,
    lane: string
  ): Observable<ListModelInstructionsResp> {
    const laneRequest = new ListModelInstructionsQuery();
    const userRoleCd: UserRoleCd = this.authService.isSuperUser()
      ? UserRoleCd.MANAGER_SUPERVISOR_LINEHAUL
      : this.authService.getUserRoleCd();
    laneRequest.userRoleCd = userRoleCd;
    laneRequest.moveToSicCd = lane;
    laneRequest.serviceCenterTimezoneOffset = this.getOffset(
      this.locationService.filterLocationData.value.LinehaulRegion,
      sicCd
    );
    const queryPath = new ListModelInstructionsPath();
    const shiftCd = shift ? shift.slice(0, 1) : 'O';
    const shiftDate = this.getDateByShiftTiming(plannedDate, sicCd, shiftCd);
    const modelGroupTmz = getGroupCodeFromTimezone(regionCd);
    queryPath.sicCd = sicCd;
    queryPath.shiftCd = shiftCd;
    queryPath.plannedDate = this.moment(shiftDate)
      .utc()
      .format('YYYY-MM-DD');
    queryPath.modelGroupCd = modelGroupTmz;
    return this.linehaulOpApiService
      .listModelInstructions(queryPath, laneRequest, { loadingOverlayEnabled: false, toastOnError: false })
      .pipe(
        share(),
        tap(() =>
          this.loggingApiService.info(
            this.userRoleService.user.userId +
              'ListModelInstructions in Performance Dashboard: ' +
              this.userRoleService.build +
              '- SIC:' +
              sicCd +
              '- Date: ' +
              shiftDate +
              '- Shift: ' +
              shiftCd +
              '- ModelGroup : ' +
              modelGroupTmz,
            this.authService.getEmployeeSic(),
            'Get info from ListModelInstructions in Performance Dashboard',
            'GET',
            'PerformanceDashboard'
          )
        ),
        catchError((err) => {
          this.xpoSnackBar.error(err);
          return of(undefined);
        })
      );
  }

  private getDateByShiftTiming(date: Date, sic: string, shift: string): any {
    const time = new Date(date);
    const created = new Date(this.timeService.formatDate(time, 'YYYY-MM-DDTHH:mm:ss', sic));
    const night = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T22:00:00'));
    const morning = new Date(this.datePipe.transform(time, 'yyyy-MM-dd' + 'T10:00:00'));
    const current = this.datePipe.transform(time, 'yyyy-MM-dd');
    const previous = this.datePipe.transform(new Date(time.setDate(time.getDate() - 1)), 'yyyy-MM-dd');
    if (shift === 'F') {
      return this.datePipe.transform(time, 'yyyy-MM-dd');
    } else {
      return current;
    }
    /*
    if (created > morning && created < night && shift === 'O') {
      //AFTER 10 & BEFORE 22 & SHIFT= O
      return current;
    } else if (created > morning && created < night && shift === 'F') {
      //AFTER 10 & BEFORE 22 & SHIFT= F
      return current;
    } else if (created < morning && created > night && shift === 'O') {
      //BEFORE 10 & AFTER 22 & SHIFT= O
      return current;
    } else if (created < morning && created > night && shift === 'F') {
      //BEFORE 10 & AFTER 22 & SHIFT= F
      return previous;
    } else {
      // If edgy case, send current
      return current;
    }
    */
  }

  private getOffset(regions, lane): string {
    const reg = regions.filter((region) => region.sicList.find((sicList) => sicList.sicCd === lane));
    if (reg[0]) {
      const sics = reg[0].sicList.filter((sicList) => sicList.sicCd === lane);
      return sics[0].serviceCenterOffset || null;
    } else {
      return null;
    }
  }

  private setIndicators(instructionSummary): void {
    this.indicators.length = 0;
    const { guaranteedInd, hazmatInd, freezableInd, headLoadInd } = instructionSummary;
    // tslint:disable-next-line:no-unused-expression
    guaranteedInd && this.indicators.push(IconsKeys.guaranteed);
    // tslint:disable-next-line:no-unused-expression
    hazmatInd && this.indicators.push(IconsKeys.hazmat);
    // tslint:disable-next-line:no-unused-expression
    freezableInd && this.indicators.push(IconsKeys.freezable);
    // tslint:disable-next-line:no-unused-expression
    headLoadInd && this.indicators.push(IconsKeys.headLoad);
  }

  changeSidePanelWidth(): void {
    this.changeWidth = !this.changeWidth;
  }
}
