import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { XpoLtlServiceCentersService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { AgGridEvent, ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { LanesViewBoardTemplate } from '../../../../../load-management/allowable-loads/lanes-view/lanes-view-board-template';
import { AllowableLoadsService } from '../../../../../load-management/allowable-loads/services/allowable-loads.service';
import { ComponentsEnum } from '../../../../enums/components.enum';
import { IconsKeys } from '../../../../enums/icons.enum';
import { AddLoadFormatter } from '../../../../formatters/ag-grid-cell-formatters/add-load-formatter';
import { BypassFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/BypassFormatterComponent';
import { CloseToFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/closeto-formatter';
import { ExceptionFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/exception-formatter';
import { IconsFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/icons-formatter';
import { LoadedWeightFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/loaded-weight-formatter';
import { MoveToFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/move-to-formatter';
import { SpecialServiceFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/special-service-formatter';
import { StatusFormatterComponent } from '../../../../formatters/ag-grid-cell-formatters/status-formatter';
import { InteractionService } from '../../../../services/interaction.service';
import { LoadRequestDataSourceService } from './load-request-panel-table.service';

@Component({
  selector: 'load-request-panel-table',
  templateUrl: './load-request-panel-table.component.html',
  styleUrls: ['./load-request-panel-table.component.scss'],
})
export class LoadRequestPanelTable implements OnInit, OnChanges, OnDestroy {
  lane: string = 'UPO';

  private gridApi: GridApi;
  private colApi: ColumnApi;
  gridOptions: GridOptions;
  aggFunctions;
  viewTemplates: LanesViewBoardTemplate[];
  views: XpoBoardView[];
  initialized = false;

  frameworkComponents = {
    // IconsFormatterComponent: IconsFormatterComponent,
    // SpecialServiceFormatterComponent: SpecialServiceFormatterComponent,
    // StatusFormatterComponent: StatusFormatterComponent,
    // BypassFormatterComponent: BypassFormatterComponent,
    // LoadedWeightFormatterComponent: LoadedWeightFormatterComponent,
    // ExceptionFormatterComponent: ExceptionFormatterComponent,
    // CloseToFormatterComponent: CloseToFormatterComponent,
    // MoveToFormatterComponent: MoveToFormatterComponent,
    // AddLoadFormatter: AddLoadFormatter,
  };
  detailLanesNodes: any[] = [];

  constructor(
    public datasource: LoadRequestDataSourceService,
    private allowableLoadsService: AllowableLoadsService,
    public serviceCentersService: XpoLtlServiceCentersService,
    private interactionService: InteractionService
  ) {}

  onLanesGridLoad(gridEvent: AgGridEvent) {
    this.gridApi = gridEvent.api;
    this.colApi = gridEvent.columnApi;
  }

  // returns the list as a string
  getValuesAsString(values) {
    return values.filter(Boolean).toString();
  }

  // just returns the list of values
  getValues(values) {
    return values;
  }

  getBypAgg(values) {
    return values.filter(Boolean).length;
  }

  getGuaranteedInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.guaranteed : '';
  }

  getExceptionInd(values) {
    const nonEmptyValues = values.filter(Boolean);
    return nonEmptyValues.length ? IconsKeys.circleFilled : '';
  }

  getCountAgg(values) {
    return values.filter(Boolean).length;
  }

  getSumAgg(values) {
    let sum = 0;
    values.forEach((val) => {
      sum += val ? val : 0;
    });
    return sum;
  }

  formatDecimal(number) {
    return number.value
      ? Math.floor(number.value)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '';
  }

  getSpecialServices(params: any) {
    const total = {
      guaranteedInd: false,
      frzblInd: false,
      hazmatInd: false,
      headLoadInd: false,
      headLoadDetail: false,
      misloadInd: false,
    };

    if (params.children) {
      _.forEach(params.children, (children2) => {
        _.forEach(total, (value, key) => {
          if (!!children2[key]) {
            total[key] = children2[key];
          }
        });
      });
    }
    return total;
  }

  ngOnInit() {
    this.initialized = true;
    this.startLaneTable();
    this.generateTemplate();
  }

  ngOnChanges(newinfo: SimpleChanges): void {
    if (this.initialized) {
      this.startLaneTable();
      this.generateTemplate();
    }
  }

  ngOnDestroy() {
    this.gridApi = null;
    this.colApi = null;
  }

  generateTemplate() {
    this.viewTemplates = [new LanesViewBoardTemplate(this.serviceCentersService)];
    this.views = [this.viewTemplates[0].createLanesView()];
    this.interactionService.sendAlertLane(this.lane);
  }

  startLaneTable() {
    this.aggFunctions = {
      getCloseToVal: this.getValuesAsString,
      getByp: this.getBypAgg,
      getGuaranteed: this.getGuaranteedInd,
      getCountAgg: this.getCountAgg,
      getSumAgg: this.getSumAgg,
      getExceptionInd: this.getExceptionInd,
      getValues: this.getValues,
      formatDecimal: this.formatDecimal,
    };
    this.gridOptions = {
      groupMultiAutoColumn: true,
      groupSuppressAutoColumn: true,
      suppressDragLeaveHidesColumns: true,
      aggFuncs: this.aggFunctions,
      suppressAggFuncInHeader: true,
      frameworkComponents: this.frameworkComponents,
      headerHeight: 28,
      icons: {
        groupExpanded: `<img src="./assets/images/expanded.svg"
          style="width: 22px; position: absolute; left: 5px; top: 4px;" data=test"expandButton">`,
        groupContracted: `<img src="./assets/images/contracted.svg"
          style="width: 22px; position: absolute; left: 5px; top: 4px;" data=test"contractButton">`,
      },
      // level 1 grid options
      columnDefs: [
        {
          headerName: ' ',
          headerClass: 'main-table-header',
          children: [
            {
              width: 60,
              maxWidth: 60,
              headerName: '',
              showRowGroup: 'lane',
              field: 'lane',
              cellRenderer: 'agGroupCellRenderer',
              sortable: false,
              resizable: false,
              cellClass: 'expandstyles',
              cellRendererParams: {
                suppressCount: true,
              },
            },
          ],
        },
        {
          headerName: 'Trip Summary',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Move to',
              showRowGroup: 'lane',
              minWidth: 90,
              maxWidth: 90,
              cellRenderer: 'agGroupCellRenderer',
              field: 'lane',
              cellClass: 'laneColumn',
              cellRendererParams: {
                suppressCount: true,
              },
            },
            {
              headerName: 'Close To',
              field: 'closeToSicCd',
              minWidth: 100,
              maxWidth: 120,
              valueGetter: (params) => {
                return params.data.closeToSicCd.slice(0, 12) + '...';
              },
              cellClass: 'ellipsis',
            },
            {
              headerName: 'Special Services',
              minWidth: 100,
              maxWidth: 100,
              valueGetter: (params) => {
                return this.getSpecialServices(params.data);
              },
              aggFunc: 'getValues',
              sortable: false,
              cellRenderer: 'SpecialServiceFormatterComponent',
            },
          ],
        },
        {
          headerName: 'Loads',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Bypass',
              field: 'bypassLoadCount',
              minWidth: 60,
            },
            {
              headerName: 'Planned',
              field: 'planned',
              type: 'numericColumn',
              minWidth: 80,
              cellRenderer: function getPlanned(params) {
                if (params.value) {
                  return params.value.loadCount || '';
                }
                return '';
              },
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              type: 'numericColumn',
              minWidth: 70,
              cellRenderer: (params) => {
                return params.value ? params.value.loadCount : '';
              },
            },
          ],
        },
        {
          headerName: 'Cube',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedCube(params) {
                return params.data && params.data.planned && params.data.planned.cube ? params.data.planned.cube : '';
              },
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedCube(params) {
                let cubeLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (closeTo) => {
                    _.forEach(closeTo.children, (trailer) => {
                      if (!trailer.bypassInd) {
                        cubeLoadSum += trailer.loadedCube;
                      }
                    });
                  });
                }
                return cubeLoadSum || '';
              },
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Remaining',
              valueGetter: function getRemainingCube(params) {
                return params.data && params.data.remaining && params.data.remaining.cube
                  ? params.data.remaining.cube
                  : '';
              },
              type: 'numericColumn',
              minWidth: 90,
            },
          ],
        },
        {
          headerName: 'Weight',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.planned && params.data.planned.weight
                  ? params.data.planned.weight
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Loaded',
              field: 'loaded',
              valueGetter: function getLoadedWeight(params) {
                let weightLoadSum = 0;
                if (params.data) {
                  _.forEach(params.data.children, (closeTo) => {
                    _.forEach(closeTo.children, (trailer) => {
                      if (!trailer.bypassInd) {
                        weightLoadSum += trailer.loadedWeight;
                      }
                    });
                  });
                }
                return weightLoadSum || '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 80,
            },
            {
              headerName: 'Remaining',
              field: 'remaining',
              valueGetter: function getRemainingWeight(params) {
                return params.data && params.data.remaining && params.data.remaining.weight
                  ? params.data.remaining.weight
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 90,
            },
          ],
        },
        {
          headerName: 'Load Average',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'planned',
              valueGetter: function getPlannedLoadAvg(params) {
                return params.data && params.data.planned && params.data.planned.loadAverage
                  ? params.data.planned.loadAverage
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 75,
            },
            {
              headerName: 'Actual',
              field: 'loaded',
              valueGetter: function getPlannedWeight(params) {
                return params.data && params.data.loaded && params.data.loaded.loadAverage
                  ? params.data.loaded.loadAverage
                  : '';
              },
              valueFormatter: this.formatDecimal,
              type: 'numericColumn',
              minWidth: 70,
            },
          ],
        },
        {
          headerName: 'HSS',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'Planned',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data.hssLoadCount || '';
              },
              minWidth: 75,
            },
            {
              headerName: 'Actual',
              field: 'hssLoadCount',
              type: 'numericColumn',
              valueGetter: (params) => {
                return params.data.hssLoadCount || '';
              },
              minWidth: 70,
            },
          ],
        },
        {
          headerName: 'Others',
          headerClass: 'main-table-header',
          children: [
            {
              headerName: 'TUC (%)',
              field: 'trailerUtilizationCapability',
              type: 'numericColumn',
              minWidth: 75,
            },
            {
              headerName: 'TU (%)',
              field: 'closedTrailerUtilization',
              type: 'numericColumn',
              minWidth: 70,
            },
          ],
        },
      ],
      groupDefaultExpanded: 0,
      masterDetail: true,
      getRowHeight: (params) => {
        const isDetailRow = params.node.detail;
        if (isDetailRow) {
          let detailPanelHeight = params.data.children.length * 30 + 3;
          params.data.children.map((node) => {
            detailPanelHeight += node.children.length * 27 + 40;
          });
          // saves the lane node to recalculate its height if necessary
          this.detailLanesNodes.push({ lane: params.data.lane, node: params.node, originalHeight: detailPanelHeight });
          return detailPanelHeight + 2;
        } else {
          return 30;
        }
      },
      onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
        params.api.forEachDetailGridInfo(function(detailGridInfo) {
          detailGridInfo.api.sizeColumnsToFit();
        });
      },
    };
    this.gridOptions.defaultColDef = {
      suppressMenu: true,
      sortable: true,
      resizable: true,
    } as ColDef;
  }
}
