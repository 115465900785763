<div class="xpo-footer">
  <div class="footer-left">
    <span>© 2020 XPO Logistics.</span>
    <span>Version - {{ buildVersion }}</span>
  </div>
  <div class="footer-right">
    <div class="footer-links">
      <a [href]="trainingResourcesLink"
          target="_blank">Training Resources</a>
      <a [href]="mailToLink"
          target="_blank">Feedback</a>
      <a [href]="releaseNotesLink"
          target="_blank">Release Notes</a>
    </div>
  </div>
</div>
