import { Injectable } from '@angular/core';
import { XpoLtlTimeService } from '@xpo-ltl/ngx-ltl';
import { XpoBoardView } from '@xpo-ltl/ngx-ltl-board';
import { XpoAgGridBoardViewTemplate } from '@xpo-ltl/ngx-ltl-board-grid';
import { ExceptionStatusCd } from '@xpo-ltl/sdk-common';
import { LoadRequestReasonCode } from '@xpo-ltl/sdk-linehauloperations';
import { map as _map } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { getAge } from '../../../utils/data-transform-utils';
import { EnumUtil } from '../../../utils/enum-utils';
import { LoadRequestTable } from '../../enums/alerts-dashboard-tables.enum';
import { InteractionService } from '../../services/interaction.service';
import { SidePanelOpts } from '../side-panel-container/image-gallery-container/enums/options.model';

@Injectable()
export class LoadRequestBoardTemplate extends XpoAgGridBoardViewTemplate {
  constructor(
    public statusCd: ExceptionStatusCd,
    private timeService: XpoLtlTimeService,
    private interactionService: InteractionService
  ) {
    super({
      id: LoadRequestBoardTemplate.templateId,
      name: 'Load Request',
      keyField: 'Load Request',
      allowAdditional: false,
      availableColumns: [
        {
          headerName: 'Actions',
          field: 'actions',
          minWidth: 60,
          width: 60,
          maxWidth: 60,
          cellRenderer: 'IconsFormatterComponent',
          cellClass: 'iconColumn',
          valueGetter: () => {
            return 'visibility';
          },
          hide: true,
        },
        {
          headerName: LoadRequestTable.AGE,
          field: 'age',
          valueGetter: (params) => {
            return params.data.operationException.auditInfo.createdTimestamp;
          },
          valueFormatter: (params) => {
            return this.formatAge(
              params.data.operationException.auditInfo.createdTimestamp,
              params.data.operationException.loggedSic
            );
          },
          hide: true,
          minWidth: 140,
        },
        {
          headerName: LoadRequestTable.REQUEST_TYPE,
          field: 'requestype',
          valueGetter: (params) => {
            return params.data.operationException.valueKey.toUpperCase();
          },
          hide: true,
          minWidth: 75,
        },
        {
          headerName: LoadRequestTable.REQUESTING,
          valueGetter: (params) => {
            return params.data.operationException.loggedSic;
          },
          field: 'requesting',
          hide: true,
          minWidth: 75,
        },
        {
          headerName: LoadRequestTable.LANE,
          field: 'lane',
          valueGetter: (params) => {
            return params.data.operationException.moveToSicCode;
          },
          hide: true,
          minWidth: 75,
        },
        {
          headerName: LoadRequestTable.SHIFT,
          field: 'shift',
          valueGetter: (params) => {
            return EnumUtil.toShiftCodeValue(params.data.operationException.shiftCd);
          },
          hide: true,
          minWidth: 75,
        },
        {
          headerName: LoadRequestTable.NBR_LOADS,
          field: 'loads',
          valueGetter: (params) => {
            return Math.abs(params.data.operationException.loadRequestedQuantity);
          },
          hide: true,
          minWidth: 50,
        },
        {
          headerName: LoadRequestTable.LOADS_DOCK,
          field: 'dock',
          valueGetter: (params) => {
            return params.data.operationException.loadToSicCode;
          },
          hide: true,
          minWidth: 75,
        },
        {
          headerName: LoadRequestTable.REQUESTED_BY,
          field: 'requestedby',
          valueGetter: (params) => {
            return params.data.operationException.employeeName;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: LoadRequestTable.REASON,
          field: 'reason',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return this.getReasonDesc(params);
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: LoadRequestTable.COMMENT,
          field: 'comment',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.operationException.actualValue;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: LoadRequestTable.STATUS_APPROVED,
          headerClass: 'wrap-text',
          field: 'statusApproved',
          cellRenderer: 'LoadRequestStatusFormatterComponent',
          valueGetter: (params) => {
            const approvedLoads = params.data.operationException.loadApprovedQuantity;
            return approvedLoads &&
              (params.data.statusCd === ExceptionStatusCd.RESPONDED ||
                params.data.statusCd === ExceptionStatusCd.APPROVED)
              ? { value: approvedLoads, status: 'approved' }
              : 0;
          },
          hide: true,
          minWidth: 90,
        },
        {
          headerName: LoadRequestTable.STATUS_CANCELED,
          headerClass: 'wrap-text',
          field: 'statusCanceled',
          cellRenderer: 'LoadRequestStatusFormatterComponent',
          valueGetter: (params) => {
            const rejectedLoads = params.data.operationException.loadRejectedQuantity;
            return rejectedLoads &&
              (params.data.statusCd === ExceptionStatusCd.RESPONDED ||
                params.data.statusCd === ExceptionStatusCd.DECLINED)
              ? { value: rejectedLoads, status: 'declined' }
              : 0;
          },
          hide: true,
          minWidth: 90,
        },
        {
          headerName: LoadRequestTable.DECISION_REASON,
          field: 'decisionReason',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return this.getReasonCodes(params);
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: LoadRequestTable.STATUS,
          field: 'status',
          cellRenderer: 'RequesterCommentFormatterComponent',
          valueGetter: (params) => {
            return params.data.statusCd;
          },
          hide: true,
          minWidth: 150,
        },
        {
          headerName: LoadRequestTable.REQUESTED,
          field: 'requested',
          valueGetter: (params) => {
            return this.formatDate(params.data.auditInfo.createdTimestamp, params.data.operationException.loggedSic);
          },
          hide: true,
          minWidth: 150,
        },
      ],
      availableFilters: [],
    });
  }

  static templateId = 'Load Request';
  static _clickSubject = new BehaviorSubject<any>(0);

  static reasonCodes: LoadRequestReasonCode[];
  linehaulApiService: any;
  xpoSnackBar: any;
  sidePanelOpts: SidePanelOpts;
  clickSubject$ = LoadRequestBoardTemplate._clickSubject.asObservable();

  createLoadRequestView(): XpoBoardView {
    return this.createView({
      closeable: false,
      criteria: {},
      id: 'LoadRequestView',
      name: 'Load Request',
    });
  }

  formatDate(date: any, sic: any): string {
    return this.timeService.formatDate(date, 'HH:mm, MM/DD/YYYY', sic);
  }

  getReasonCodes(params): any {
    let filteredValue;
    let decisionReason;
    this.interactionService.getLoadRequestReasonCodes('ALL').subscribe((reasonCodes) => {
      if (params.data.statusCd === 'Cancelled') {
        filteredValue = reasonCodes.filter(
          (l) => l.reasonTypeCd === 'CANCEL_CD' && l.reasonCd === params.data.operationException.cancelReasonCd
        );
        decisionReason = filteredValue[0].reasonDescription;
      } else if (params.data.statusCd !== 'Cancelled' && params.data.operationException.approvalTypeCd) {
        decisionReason = params.data.operationException.approvalTypeCd;
      } else {
        filteredValue = reasonCodes.filter(
          (l) => l.reasonTypeCd === 'REASON_CD' && l.reasonCd === params.data.operationException.cancelReasonCd
        );
        decisionReason = filteredValue[0].reasonDescription;
      }
    });
    return decisionReason;
  }
  getReasonDesc(params): any {
    let filteredVal;
    const reasonCd = params.data.operationException.requestReasonCd;
    this.interactionService.getLoadRequestReasonCodes().subscribe((reasonCodes) => {
      filteredVal = reasonCodes.filter((el) => el.reasonCd === reasonCd);
    });

    return filteredVal[0].reasonDescription;
  }

  formatAge(date: any, sic: any): string {
    const dateZone = this.timeService.formatDate(date, 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDate = Date.parse(dateZone);
    const dateZoneNow = this.timeService.formatDate(+new Date(), 'YYYY/MM/DD HH:mm:ss', sic);
    const timeDateNow = Date.parse(dateZoneNow);
    return getAge(timeDate, timeDateNow);
  }

  checkStatus(alert: any, statusCd: ExceptionStatusCd): any {
    let status: any;
    if (statusCd === ExceptionStatusCd.RESPONDED) {
      if (alert.loadApprovedQuantity) {
        if (alert.loadApprovedQuantity > 0) {
          status = alert.loadApprovedQuantity + ' Approved';
        }
      } else if (alert.loadRejectedQuantity > 0) {
        status = alert.loadRejectedQuantity + ' Declined';
      } else {
        status = 'New';
      }
      return status;
    } else {
      return statusCd;
    }
  }
}
