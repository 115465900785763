import { DsrLicense, Via } from '@xpo-ltl/sdk-linehauloperations';

export enum SchedulesGridItemDetailStatus {
  ARIV = 'ARIV',
  DISP = 'DISP',
  TERM = 'TERM',
  COMP = 'COMP',
}

export enum SchedulesGridItemDetailTrailerCurrentStatus {
  CLOS = 'CLOS',
  LDDK = 'LDDK',
  OVHD = 'OVHD',
}
export interface SchedulesGridItem {
  sicCd: string;
  sicCdDescription: string;
  dsrPlanned: number;
  emptiesPlanned: number;
  overagePlanned: number;
  viaDetails: ViaDetails;
  incomingViaCount: number;
  outgoingViaCount: number;
  loadDetails: SchedulesGridItemDetail[];
}

export interface ViaDetails {
  incomingVias: Via[];
  outgoingVias: Via[];
  singlePupCount: number;
  singleVanCount: number;
  rmdCount: number;
  dblTurnCount: number;
  setCount: number;
  tripleCount: number;
  plannedDrivers: number;
  dispatchedDrivers: number;
}

export interface SchedulesGridItemDetail {
  lnhScheduleId: number;
  driver1: SchedulesGridItemDetailDriver;
  driver2: SchedulesGridItemDetailDriver;
  trailer1: SchedulesGridItemDetailTrailer;
  trailer2: SchedulesGridItemDetailTrailer;
  trailer3: SchedulesGridItemDetailTrailer;
  tractor: SchedulesGridItemDetailTractor;
  viaSic: string;
  cutTime: Date;
  departureTime: SchedulesGridItemDetailEstAndActual;
  arrivalTime: SchedulesGridItemDetailEstAndActual;
  isTripleEnabled?: boolean;
  isDoubleTurn?: boolean;
  status: SchedulesGridItemDetailStatus;
  editableFields?: Array<keyof SchedulesGridItemDetail>;
}

export interface SchedulesGridItemDetailDriver {
  name?: string;
  id?: string;
  credentials?: DsrLicense[];
  phoneNumber?: string;
  seqNumber?: number;
  domSic?: string;
  assignedLane?: string;
  seniorityRankNbr?: string;
  lnhOriginDispatchDateTime?: Date;
  status?: string;
}

export interface SchedulesGridItemDetailTrailer {
  equipmentId?: number;
  name?: string;
  loadedCubePct?: number;
  loadedWgtLbs?: number;
  indicators?: string[];
  currentStatus?: string;
  seqNumber?: number;
  closeTo?: string;
}

export interface SchedulesGridItemDetailTractor {
  equipmentId?: number;
  name?: string;
  status?: string;
  domSic?: string;
}

export interface SchedulesGridItemDetailEstAndActual {
  estimate: Date;
  actual: Date;
}
